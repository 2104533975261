import { Button } from '@mui/material';
import React, { useState } from 'react';

function Biometric() {
  const [loader, setLoader] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errmsg, setErrMsg] = useState('');
  const [transactionReq, setTransactionReq] = useState({
    pidData: '',
    BiometricData: '',
  });

  const showAlert = (type, message) => {
    console.log(`${type} alert: ${message}`);
    // Implement your alert logic here
  };

  const getDeviceByDeviceType1 = async (port, details) => {
    setLoader(true);

    try {
      let urlStr = `http://localhost:${port}/rd/capture`;
      let InputXml = '';

      if (!details) {
        setLoader(false);
        showAlert('danger', 'Please Select Device');
        return false;
      }

      if (details === 'STARTEK_PROTOBUF') {
        InputXml =
          "<PidOptions ver=\"1.0\"><Opts env=\"P\" fCount=\"1\" fType=\"2\" iCount=\"0\" format=\"0\" pidVer=\"2.0\" timeout=\"15000\" wadh=\"\" posh=\"UNKNOWN\" /></PidOptions>";
        console.log("STARTEK_PROTOBUF Is Working");
      } else if (details === 'MORPHO_PROTOBUF') {
        console.log("MORPHO_PROTOBUF Is Working");
        InputXml = "<PidOptions> <Opts fCount=\"1\" fType=\"2\" iCount=\"0\" pCount=\"0\" format=\"0\" pidVer=\"2.0\" timeout=\"20000\" otp=\"\" posh=\"UNKNOWN\" env=\"S\" wadh=\"\" /> <Demo></Demo> <CustOpts> <Param name=\"ValidationKey\" value=\"\" /> </CustOpts> </PidOptions>";
      }

      const response = await fetch(urlStr, {
        method: 'CAPTURE',
        headers: {
          'Content-Type': 'application/xml', // Adjust content type if needed
        },
        body: InputXml,
      });

      if (response.ok) {
        const text = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(text, "text/xml");
        const x = xmlDoc.getElementsByTagName("Resp")[0];
        const y = x.getAttributeNode("errCode");
        const txt = y.nodeValue;

        if (txt === '0') {
          setIsDisabled(true);
          setTransactionReq({
            pidData: text,
            BiometricData: text,
          });
        } else {
          setErrMsg(x.getAttributeNode("errInfo").nodeValue);
        }
      } else {
        showAlert('danger', 'Error: Failed to fetch data');
      }
    } catch (error) {
      console.error('Error:', error);
      showAlert('danger', 'An error occurred');
    } finally {
      setLoader(false);
    }
  };

  const callDevice = () => {
    getDeviceByDeviceType1(11105, "MORPHO_PROTOBUF");
  };

  return (
    <div>
      <button onClick={callDevice}>CAPTURE</button>
    </div>
  );
}

export default Biometric;

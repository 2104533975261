import React, { useEffect, useState } from 'react';
import RestService from '../../http';
import { Link, useLocation } from 'react-router-dom';
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';
// import showToast from './APPToast';
import { ToastContainer } from 'react-toastify';


function KycAgentView({ showNextComponent, showPreviousComponent }) {

  const service = new RestService();
  const location = useLocation();
  const stateData = location.state;
  const [data, setData] = useState(location.state);
  const [agentData, setAgentData] = useState([]);
  const [mobile, setMobile] = useState(data.mobile);

  useEffect(() => {
    setData(stateData);
  }, [setData]);

  const handleAgentReport = () => {

    var raw = {
      "mobile": mobile
    };

    service.post("yes/fetchinitiateagent", raw)
      .then(result => {
        console.log(result);
        setAgentData(result.KYCREPORT);
        // if (result.status === service.SUCCESS) {
        //   setAgentData(result.KYCREPORT);
        // } else {
        //   showToast("DANGER", result.message);
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleAgentReport();
  }, [])

  return (
    <>
      <ToastContainer />

      <section className="content">

        <div>
          <div className="card">
            {/* <div className='row' style={{ padding: "10px" }}>

              <div className="col-md-3" data-select2-id="4">
                <label for="service_id">
                  Mobile: <span className="text-danger"> *</span>
                </label>
                <input
                  placeholder="number"
                  className="form-control select2 "
                  required=""
                  id="service_id"
                  data-select2-id="service_id"
                  aria-hidden="true"
                  value={mobile}
                // onChange={(e) => setMobile(e.target.value)}
                />
              </div>

              <div className='col-md-3' style={{ marginTop: "2.5%" }}>
                <button className='btn btn-primary' onClick={handleAgentReport}>
                  View
                </button>
              </div>
            </div> */}

            <div className="card-body">

              <p className="font-weight-bold text-primary m-0">Personal Information</p>
              {agentData.length === 0 ? (
                <p>No data available</p>
                
              ) : agentData.map((agent, index) => (

                <div key={index} className="row">
                  <div className="col-md-4 mt-2">
                    <label className="font-weight-normal">
                      <span style={{ fontWeight: '600' }}> First Name :</span> {agent.firstName}</label>
                  </div>

                  <div className="col-md-4 mt-2">
                    <label className="font-weight-normal">
                      <span style={{ fontWeight: '600' }}>Last Name :</span> {agent.lastName} </label>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label className="font-weight-normal">
                      <span style={{ fontWeight: '600' }}>Shop Name :</span> {agent.soapName}</label>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label className="font-weight-normal">
                      <span style={{ fontWeight: '600' }}>Email :</span> {agent.email}</label>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label className="font-weight-normal">
                      <span style={{ fontWeight: '600' }}>Date Of Birth :</span> {agent.dob}</label>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label className="font-weight-normal">
                      <span style={{ fontWeight: '600' }}>Mobile :</span> {mobile}</label>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label className="font-weight-normal">
                      <span style={{ fontWeight: '600' }}>Agent Code :</span> {agent.agentcode} </label>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label className="font-weight-normal">
                      <span style={{ fontWeight: '600' }}>Aadhar No. :</span> {agent.aadhar}</label>
                  </div>

                  <div className="col-md-4 mt-2">
                    <label className="font-weight-normal">
                      <span style={{ fontWeight: '600' }}>Pan No. :</span> {agent.pan}</label>
                  </div>

                  <div className="col-md-4 mt-2">
                    <label className="font-weight-normal">
                      <span style={{ fontWeight: '600' }}>Address :</span> {agent.address}</label>
                  </div>

                  <div className="col-md-4 mt-2">
                    <label className="font-weight-normal">
                      <span style={{ fontWeight: '600' }}>State. :</span> {agent.state}</label>
                  </div>

                  <div className="col-md-4 mt-2">
                    <label className="font-weight-normal">
                      <span style={{ fontWeight: '600' }}>Pin. :</span> {agent.pin}</label>
                  </div>

                  <div className="col-md-4 mt-2">
                    <label className="font-weight-normal">
                      <span style={{ fontWeight: '600' }}>City :</span> {agent.city}</label>
                  </div>

                  <div className="col-md-4 mt-2">
                    <label className="font-weight-normal">
                      <span style={{ fontWeight: '600' }}>Gender :</span> {agent.gender}</label>
                  </div>

                  <div className="col-md-4 mt-2">
                    <label className="font-weight-normal">

                      <span style={{ fontWeight: '600' }}>Remark : </span>{agentData.remark == 0 ? <kbd className="bg-danger">FAILED</kbd> : <kbd className="bg-success">SUCCESS</kbd>}</label>
                  </div>

                  <div className="mt-4 text-right">
                    <button className="btn" style={{ backgroundColor: "darkblue", color: "white" }} onClick={showPreviousComponent}><FaLongArrowAltLeft /> Previous</button>&nbsp;&nbsp;
                    <button className="btn" style={{ backgroundColor: "red", color: "white" }}>Reject <RxCross2 /></button>&nbsp;&nbsp;
                    <Link className="btn" style={{ backgroundColor: "green", color: "white" }} state={agent} onClick={() => showNextComponent(agent.agentcode)}>Next <FaLongArrowAltRight /></Link>&nbsp;&nbsp;
                  </div><br />

                </div>

              ))}

            </div>
          </div>
        </div>
      </section >
    </>

  )
}

export default KycAgentView
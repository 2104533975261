import React, { useState } from "react";
import { Link } from "react-router-dom";
import RestService from "../../http";
import showToast from "./APPToast";


const KycReportTable = ({ data = [], showNextComponent, showPreviousComponent }) => {

     const service = new RestService();

    return (
        <>
            <table className="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Mobile No.</th>
                        <th>Agent Id</th>
                        <th>Kyc</th>
                        <th>Document</th>
                        <th>Company Verification</th>
                        <th>Status</th>
                        <th>Remark</th>
                        <th>Approver Name</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Upline Id</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length !== 0 ?
                        data.map((option, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{option.mobile}</td>
                                <td>{option.agentId}</td>
                                <td>{option.kyc}</td>
                                <td>{option.document}</td>
                                <td>{option.cvery}</td>
                                <td>{option.status}</td>
                                <td>{option.remark}</td>
                                <td>{option.approvername}</td>
                                <td>{option.date}</td>
                                <td>{option.time}</td>
                                <td>{option.uplineId}</td>
                                <td>
                                    <Link state={option} onClick={() => showNextComponent(option.mobile)} className="btn btn-sm btn-primary btn-block bg-success">
                                        Next
                                    </Link>
                                </td>
                            </tr>
                        ))
                        :
                        <tr >
                            <td colSpan={15} align="center">
                                NO DATA AVAILABLE
                            </td>
                        </tr>
                    }

                </tbody>
            </table>
        </>
    );

};

export default KycReportTable;

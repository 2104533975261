import React from "react";
import { useState } from "react";


const MasterCommTable = ({ data, serviceName, handleUpdate }) => {

    return (
        <>
            <div>
                <h3 className="text-center" style={{ fontWeight: "bold" }}>{serviceName}</h3>
            </div>
            <hr />
            <table className="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Operator Name</th>
                        <th>Operator Incode</th>
                        <th>Id</th>
                        <th>Commission</th>
                        <th>Commission Type</th>
                        <th className="px-1">Slabs</th>
                        <th>Is Charge</th>
                        <th>Package Id</th>
                        <th>Plan Id</th>
                        <th>Service Code</th>
                        {/* <th>Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {/* {data.map((item, index) => ( */}
                    {data.length > 0 ? (
                        data.map((item, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{item.operatorName}</td>
                                <td>{item.incode}</td>
                                <td>{item.id}</td>
                                <td>{item.comm}</td>
                                <td>{item.commType}</td>
                                <td>{`(${item.slab1}-${item.slab2})`}</td>
                                <td>{item.charge}</td>
                                <td>{item.packageId}</td>
                                <td>{item.planId}</td>
                                <td>{item.serviceCode}</td>
                                {/* <td>
                                    <button className="btn bg-green" style={{ border: "none", cursor: "pointer", color: "white" }}
                                        onClick={handleUpdate}
                                    >
                                        Update
                                    </button>
                                </td> */}
                            </tr>
                        ))
                        ) : (
                            <td colSpan={14} align="center">
                                NO DATA AVAILABLE
                            </td>
                        )
                        }
                </tbody>
            </table>
        </>
    );

};

export default MasterCommTable;

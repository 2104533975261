import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import RestService from '../http'


export default function Recharge() {

    const service = new RestService();
    const [serviceList, setServiceList] = useState([]);


    const handleUserCommission = () => {

        var raw = {
            "packageId": "",
            "roleId": "",
            "planId": ""
        }

        service.post("commission/fetchUserCommission", raw).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setServiceList(result.data);

            } else {
                setServiceList([]);
            }
        })
    }

    useEffect(() => {
        handleUserCommission();
    }, [])

    return (
        <>
            <Box style={{ height: "30px" }} />


            <div class="row">
                <div class="col-md-6">
                    <div class="service-panel report-panel dashboard-report-panel">
                        <h4 class="service-title">Resent Recharge Details</h4>
                        <div class="panel-body">
                            <div style={{ padding: "0px 3px 0px" }}>
                                <div id="displaytransaction" style={{ marginTop: "0px" }}>Transaction not found !</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="service-panel report-panel dashboard-report-panel" style={{ overflowY: "scroll" }}>
                        <h4 class="service-title">AEPS COMMISSION</h4>
                        <div class="panel-body">
                            <div class="row">

                                {serviceList.map((item) => (
                                    <div>
                                        <h1 class="default-heading" style={{ fontWeight: "bold" }}>{item.serviceName}</h1>

                                        {item.data.length > 0 ? (
                                            item.data.map((item1, index) => (
                                                <div class="commission-box" key={index}>
                                                    <div class="slno">{index + 1}</div>
                                                    <h4>{item1.operatorName} ({item1.slab1}-{item1.slab2})</h4>
                                                    <p class="status active">&nbsp;</p>
                                                    <div className="commission-amount">
                                                        {item1.commissionType === 'FLAT' ? `₹ ${item1.commission}` : `${item1.commission}%`}
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No Commission Available</p>
                                        )}
                                        <hr />
                                    </div>
                                ))}
                            </div>

                            <div class="row">
                                <div class="service-panel status-sec">
                                    <div class='panel-body' style={{ height: "5%" }}>
                                        <ul>
                                            <li>
                                                <p class="status active">&nbsp;</p> Active Operator
                                            </li>
                                            <li>
                                                <p class="status inactive">&nbsp;</p> Inactive Operator
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

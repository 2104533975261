import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import RestService from "../http";
import "react-datepicker/dist/react-datepicker.css";
import { FaPlus } from 'react-icons/fa6';


export default function MovetobankPermissionTable() {

    const service = new RestService();
    const navigate = useNavigate();

    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();
    const [permissionReport, setPermissionReport] = useState([]);



    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    // const downloadExcel = () => {
    //     // Handle download Excel logic here
    // };

    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);


    useEffect(() => {

        service.post("user/getallmovetobankpermission", null).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                setPermissionReport(result.data);

            } else {
                setPermissionReport([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [])


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Move To Bank User Permission&nbsp;<span>
                                    <Link to="/addbankpermission" className="btn btn-sm btn-primary">
                                        <FaPlus className="fa fa-plus" />NEW</Link></span>&nbsp;
                                </h1>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Permission</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Recharge</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Permission Table</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr className="wrap-text text-center">
                                                <th className="px-1">Id</th>
                                                <th>User Id</th>
                                                <th>Add Permission</th>
                                                <th>Edit Permission</th>
                                                <th className="px-1">Add Date</th>
                                                <th>Edit Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {(permissionReport.length === 0) ? (
                                                <tr>
                                                    <td colSpan="6" className='text-center'>NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                permissionReport.map((item, index) => (
                                                    <tr className='text-center'>
                                                        <td key={index}>{index + 1}</td>
                                                        <td>{item.userId}</td>
                                                        <td>{item.addPermission}</td>
                                                        <td>{item.editPermission}</td>
                                                        <td>{item.addDate}</td>
                                                        <td>{item.editDate}</td>
                                                        <td>
                                                            <Link to="/editpermission" state={item} className='btn btn-success'>Update</Link>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>

                                    <div
                                        className="dataTables_paginate paging_simple_numbers"
                                        id="example1_paginate"
                                    >
                                        <ul className="pagination" style={{ float: "right", display: "flex" }}>
                                            <li
                                                className="paginate_button page-item previous disabled"
                                                id="example1_previous"
                                            >
                                                <a
                                                    href="#4"
                                                    aria-controls="example1"
                                                    data-dt-idx="0"
                                                    tabIndex="0"
                                                    className="page-link"
                                                >
                                                    Previous
                                                </a>
                                            </li>
                                            <li className="paginate_button page-item active">
                                                <a
                                                    href="#5"
                                                    aria-controls="example1"
                                                    data-dt-idx="1"
                                                    tabIndex="0"
                                                    className="page-link"
                                                >
                                                    1
                                                </a>
                                            </li>
                                            <li
                                                className="paginate_button page-item next disabled"
                                                id="example1_next"
                                            >
                                                <a
                                                    href="#6"
                                                    aria-controls="example1"
                                                    data-dt-idx="2"
                                                    tabIndex="0"
                                                    className="page-link"
                                                >
                                                    Next
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <footer className="main-footer" >
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import DatePicker from "react-datepicker";
import RestService from "../http";
import Loader from "./Loader";
import showToast from "./utilityComponents/APPToast";
import { FaFileExcel } from "react-icons/fa";
import * as XLSX from 'xlsx';
import { Link } from "react-router-dom";


export default function SelfEarningReport() {
    const rest = new RestService();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [earningReport, setEarningReport] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 10;


    const formatDate = (date) => {
        const formattedDate = date
            .toLocaleDateString("en-GB", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            })
            .split("/")
            .reverse()
            .join("-");
        return formattedDate;
    };



    const handleEarningReport = () => {
        try {
            let request = {
                startDate: formatDate(startDate),
                endDate: formatDate(endDate),
            };

            setLoading(true);

            rest
                .post(`commission/fetchAdminEarning`, request)
                .then((response) => {
                    setLoading(false);

                    console.log(response);

                    if (response.status === rest.SUCCESS) {
                        setEarningReport(response.data.report);

                    } else if (response.status === rest.EXCEPTIONAL_ERROR) {
                        showToast("WARNING", response.message);
                        setEarningReport([]);

                    } else {
                        showToast("DANGER", response.message);
                        setEarningReport([]);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setEarningReport([]);
                });
        } catch (error) {
            console.log(error);
            setEarningReport([]);
        }
    };

    useEffect(() => {
        handleEarningReport();
    }, [currentPage, pageSize]);


    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
            handleEarningReport();
        }
    };

    const handleNextPage = () => {
        if (earningReport.length >= pageSize) {
            setCurrentPage(currentPage + 1);
            handleEarningReport();
        }

    };

    const getPaginatedData = () => {
        const startIndex = currentPage * pageSize;
        const endIndex = startIndex + pageSize;
        return earningReport.slice(startIndex, endIndex);
    };

    const downloadExcel = () => {
        // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
        // writeFile(wb, 'recharge_report.xlsx');

        const ws = XLSX.utils.json_to_sheet(earningReport);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Self Earning Report');

        XLSX.writeFile(wb, 'selfEarning_report.xlsx');
    };


    return (
        <>
            <Navbar />
            {loading && <Loader />}


            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Admin Earning</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Report</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Admin Earning Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                <label>From Date</label><br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    dropdownMode="select"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={startDate}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setStartDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                <label>To Date</label>
                                                <br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    dropdownMode="select"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={endDate}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setEndDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    style={{ marginTop: "11%" }}
                                                    onClick={handleEarningReport}
                                                >
                                                    <i className="fa fa-search"></i> Search
                                                </button>
                                                &nbsp;

                                                <button type="button" name="" className="btn btn-success" onClick={downloadExcel} style={{marginTop:"10%"}} fdprocessedid="9svl7o">
                                                    <FaFileExcel className="fa fa-file-excel-o" />
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div
                                        id="example1_wrapper"
                                        className="dataTables_wrapper dt-bootstrap4"
                                    >
                                        <table
                                            id="example1"
                                            className="table table-bordered table-striped dataTable"
                                            style={{ width: "100%" }}
                                            role="grid"
                                            aria-describedby="example1_info"
                                        >
                                            <thead>
                                                <tr className="wrap-text" role="row">
                                                    <th
                                                        className="sorting_asc"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-sort="ascending"
                                                        aria-label="#: activate to sort column descending"
                                                        style={{ width: "8.45833px" }}
                                                    >
                                                        #
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Number: activate to sort column ascending"
                                                        style={{ width: "57.2708px" }}
                                                    >
                                                        ORDER ID
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Amount: activate to sort column ascending"
                                                        style={{ width: "56.1354px" }}
                                                    >
                                                        OPERATOR NAME
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Operator: activate to sort column ascending"
                                                        style={{ width: "145.521px" }}
                                                    >
                                                        OUTCODE
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Type: activate to sort column ascending"
                                                        style={{ width: "34.3125px" }}
                                                    >
                                                        SERVICE NAME
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Commission: activate to sort column ascending"
                                                        style={{ width: "86.6562px" }}
                                                    >
                                                        REMARK
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Surcharge: activate to sort column ascending"
                                                        style={{ width: "71.9896px" }}
                                                    >
                                                        COMMISSION
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Surcharge: activate to sort column ascending"
                                                        style={{ width: "71.9896px" }}
                                                    >
                                                        TDS
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Surcharge: activate to sort column ascending"
                                                        style={{ width: "71.9896px" }}
                                                    >
                                                        GST
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="TxnID: activate to sort column ascending"
                                                        style={{ width: "79.4792px" }}
                                                    >
                                                        FINAL AMOUNT
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Date: activate to sort column ascending"
                                                        style={{ width: "135.552px" }}
                                                    >
                                                        CHARGE
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Date: activate to sort column ascending"
                                                        style={{ width: "135.552px" }}
                                                    >
                                                        NARRATION
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Remark: activate to sort column ascending"
                                                        style={{ width: "55.2188px" }}
                                                    >
                                                        DATE
                                                    </th>

                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Remark: activate to sort column ascending"
                                                        style={{ width: "55.2188px" }}
                                                    >
                                                        TIME
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(!earningReport || earningReport.length === 0) ? (
                                                    <tr>
                                                        <td colSpan="14">NO DATA AVAILABLE</td>
                                                    </tr>
                                                ) : (
                                                    getPaginatedData().map((report, index) => (
                                                        <tr
                                                            key={index}
                                                            role="row"
                                                            className={index % 2 === 0 ? "even" : "odd"}
                                                        >
                                                            <td className="sorting_1">{index + 1}</td>
                                                            <td className="sorting_1">{report.orderId}</td>
                                                            <td>{report.operatorName}</td>
                                                            <td>
                                                                {report.outCode}
                                                            </td>
                                                            <td>{report.serviceName}</td>
                                                            <td className="font-weight-bold text-right text-success">
                                                                {report.remark}
                                                            </td>
                                                            <td className="font-weight-bold text-right text-danger">
                                                                {report.commission}
                                                            </td>
                                                            <td className="font-weight-bold text-right text-danger">
                                                                {report.tds}
                                                            </td>
                                                            <td className="font-weight-bold text-right text-success">
                                                                {report.gst}
                                                            </td>
                                                            <td>{report.finalAmount}</td>
                                                            <td>{report.charge}</td>
                                                            <td>{report.narration}</td>
                                                            <td>{report.date}</td>
                                                            <td>{report.time}</td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                            
                                            <tfoot>
                                                <tr>
                                                    <td
                                                        colSpan="6"
                                                        style={{ fontWeight: "bold" }}
                                                        className="text-primary"
                                                        rowSpan="1"
                                                    >
                                                        Row Count {earningReport.length}
                                                    </td>
                                                    <td
                                                        className="text-right font-weight-bold text-success"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                    >
                                                        ₹ {earningReport.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.commission), 0)}
                                                    </td>
                                                    <td
                                                        className="text-right font-weight-bold text-danger"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                    >
                                                        ₹ {earningReport.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.charge), 0)}
                                                    </td>
                                                    <td
                                                        className="text-right font-weight-bold text-danger"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                    >
                                                        ₹ {earningReport.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.tds), 0)}
                                                    </td>
                                                    <td
                                                        className="text-right font-weight-bold text-success"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                    >
                                                        ₹ {earningReport.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.finalAmount), 0)}
                                                    </td>
                                                    <td colSpan="3" rowSpan="1"></td>
                                                </tr>
                                            </tfoot>
                                        </table>

                                        <div
                                            className="dataTables_info"
                                            id="example1_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            Showing entries
                                        </div>

                                        <div
                                            className="dataTables_paginate paging_simple_numbers"
                                            id="example1_paginate"
                                        >
                                            <ul className="pagination" style={{ float: "right", display: "flex" }}>
                                                <li className={`paginate_button page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                    <button onClick={handlePreviousPage} className="page-link">
                                                        Previous
                                                    </button>
                                                </li>
                                                <li className="paginate_button page-item active">
                                                    <span className="page-link">{currentPage}</span>
                                                </li>
                                                <li className="paginate_button page-item">
                                                    <button onClick={handleNextPage} className="page-link">
                                                        Next
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    );
}

import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import { useRef, useEffect } from 'react';
import RestService from '../http';
import showToast from './utilityComponents/APPToast';
import { FaSearch } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import formatDate from './utilityComponents/FormatDate';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import Loader from './Loader';


export default function AssignTicket() {

    const service = new RestService();
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());

    const [selectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropDownRef = useRef();
    const [trackTicket, setTrackTicket] = useState([]);
    const [loading, setLoading] = useState(false);


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false); 
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    const handleViewTicket = () => {

        var raw = {
            "startDate": formatDate(date),
            "endDate": formatDate(date1),
        }

        // setLoading(true);

        service.post("emp/getAllPendingButAssignedTickets", raw).then(result => {
            // setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                setTrackTicket(result.data);

            } else {
                showToast("DANGER", result.message);
                setTrackTicket([]);
            }
        }).catch((error) => {
            // setLoading(false)
            console.log(error);
        })
    }

    useEffect(() => {
        handleViewTicket();
    }, [])


    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "560.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Assign Tickets</h1>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Support</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Ticket</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">


                                <div className="row">

                                    <div className="col-md-3">
                                        <label for="rec_to_load"> Start Date</label><br />
                                        <DatePicker
                                            className="custom-date-picker"
                                            name="string"
                                            fdprocessedid="9n5i4u"
                                            selected={date}
                                            dateFormat={"yyyy-MM-dd"}
                                            onChange={(date) => setDate(date)}
                                            showYearDropdown
                                            showMonthDropdown
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label for="rec_to_load"> End Date</label>
                                        <br />
                                        <DatePicker
                                            className="custom-date-picker"
                                            name="string"
                                            disabledDayAriaLabelPrefix="#$"
                                            fdprocessedid="9n5i4u"
                                            selected={date1}
                                            dateFormat={"yyyy-MM-dd"}
                                            onChange={(date) => setDate1(date)}
                                            showYearDropdown
                                            showMonthDropdown
                                        />
                                    </div>

                                    <div className="col-md-3" style={{ marginTop: "2.6%" }}>
                                        <button
                                            className="btn btn-primary "
                                            fdprocessedid="fl0v7"
                                            onClick={handleViewTicket}
                                        >
                                            <FaSearch className="fas fa-search" /> Search
                                        </button>
                                    </div>
                                </div><hr />

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr className="wrap-text text-center">
                                                <th>#</th>
                                                <th>Ticket Id</th>
                                                <th>Opening Date</th>
                                                {/* <th>Closing Date</th> */}
                                                <th>Reference No.</th>
                                                <th>User Id</th>
                                                <th>Message</th>
                                                {/* <th>Ticket Assignee</th> */}
                                                {/* <th>Is Priority</th> */}
                                                <th>Service</th>
                                                <th>Service Category</th>
                                                {/* <th>Status</th> */}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!trackTicket || trackTicket.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="13">NO PENDING TICKET AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                trackTicket.map((option, index) => (
                                                    <tr className='text-center'>
                                                        <tr>{index + 1}</tr>
                                                        <td>{option.ticketId}</td>
                                                        <td>{option.openingDate}</td>
                                                        {/* <td>{option.closingDate}</td> */}
                                                        <td>{option.refNo}</td>
                                                        <td>{option.userId}</td>
                                                        <td>{option.message}</td>
                                                        {/* <td>{option.ticketAssignee}</td> */}
                                                        {/* <td>{option.isPriority}</td> */}
                                                        <td>{option.service}</td>
                                                        <td>{option.serviceCategory}</td>
                                                        {/* <td>{option.status}</td> */}
                                                        <td>
                                                            <Link to="/detailassignticket" state={option} className="btn btn-sm btn-primary btn-block">
                                                                VIEW
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

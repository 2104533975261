import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

export default function Promocode() {

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Promocodes <span>
                                    <Link to="/createpromocd" className="btn btn-sm btn-primary">
                                    <i className="fa fa-plus"></i> NEW</Link></span></h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Utilities</Link></li>
                                    <li className="breadcrumb-item active">Promocodes</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div id="example1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <div className="dataTables_length" id="example1_length">
                                            <label>Show &nbsp;
                                                <select name="example1_length" aria-controls="example1" className="custom-select custom-select-sm form-control form-control-sm" fdprocessedid="zfm1c8">
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="All">All</option>
                                                </select> &nbsp;
                                                entries
                                            </label>
                                        </div>
                                        <div className="dt-buttons">
                                            <a className="dt-button buttons-copy buttons-html5" tabIndex="0" aria-controls="example1" title="Copy">
                                                <span><i className="fa fa-files-o text-info font-weight-bold"></i></span>
                                            </a>
                                            <a className="dt-button buttons-excel buttons-html5" tabIndex="0" aria-controls="example1" title="Excel">
                                                <span><i className="fa fa-file-excel-o text-success font-weight-bold"></i></span>
                                            </a>
                                            <a className="dt-button buttons-pdf buttons-html5" tabIndex="0" aria-controls="example1" title="PDF">
                                                <span><i className="fa fa-file-pdf-o text-danger font-weight-bold"></i></span>
                                            </a>
                                        </div>
                                        <table id="example1" className="table m-0 table-striped table-bordered dataTable no-footer" role="grid" aria-describedby="example1_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting_asc" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Sr.No: activate to sort column descending" style={{ width: "38.1771px" }}>Sr.No</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Name: activate to sort column ascending" style={{ width: "41.0729px" }}>Name</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Description: activate to sort column ascending" style={{ width: "91.0104px" }}>Description</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Minimum Amt: activate to sort column ascending" style={{ width: "98.9062px" }}>Minimum Amt</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="First Recharge: activate to sort column ascending" style={{ width: "100.865px" }}>First Recharge</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Number Of Time: activate to sort column ascending" style={{ width: "115.385px" }}>Number Of Time</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Status: activate to sort column ascending" style={{ width: "44.7083px" }}>Status</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Cashback: activate to sort column ascending" style={{ width: "67.6354px" }}>Cashback</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Action: activate to sort column ascending" style={{ width: "55.2083px" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td className="sorting_1">1</td>
                                                    <td>FLAT10</td>
                                                    <td>test</td>
                                                    <td>100.00</td>
                                                    <td>YES</td>
                                                    <td>10</td>
                                                    <td style={{ fontWeight: "bold" }}><span className="text-success">ACTIVE</span></td>
                                                    <td>10.00 <span>Rs</span></td>
                                                    <td>
                                                        <Link to="/editpromocd">
                                                            <i className="fa fa-edit text-primary" style={{ padding: "10px", fontSize: "20px" }} aria-hidden="true"></i>
                                                        </Link>
                                                        <Link to="/viewpromocd">
                                                            <i className="fa fa-eye text-success" style={{ padding: "10px", fontSize: "20px" }} aria-hidden="true"></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="even">
                                                    <td className="sorting_1">2</td>
                                                    <td>AITRL499</td>
                                                    <td>EXTRA CASHBACK</td>
                                                    <td>499.00</td>
                                                    <td>NO</td>
                                                    <td>5</td>
                                                    <td style={{ fontWeight: "bold" }}><span className="text-success">ACTIVE</span></td>
                                                    <td>20.00 <span>Rs</span></td>
                                                    <td>
                                                        <Link to="/editpromocd">
                                                            <i className="fa fa-edit text-primary" style={{ padding: "10px", fontSize: "20px" }} aria-hidden="true"></i>
                                                        </Link>
                                                        <Link to="/viewpromocd">
                                                            <i className="fa fa-eye text-success" style={{ padding: "10px", fontSize: "20px" }} aria-hidden="true"></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td className="sorting_1">3</td>
                                                    <td>NAGA111</td>
                                                    <td>ONLY ONCWE</td>
                                                    <td>1000.00</td>
                                                    <td>YES</td>
                                                    <td>1</td>
                                                    <td style={{ fontWeight: "bold" }}><span className="text-success">ACTIVE</span></td>
                                                    <td>10.00 <span>Rs</span></td>
                                                    <td>
                                                        <Link to="/editpromocd">
                                                            <i className="fa fa-edit text-primary" style={{ padding: "10px", fontSize: "20px" }} aria-hidden="true"></i>
                                                        </Link>
                                                        <Link to="/viewpromocd">
                                                            <i className="fa fa-eye text-success" style={{ padding: "10px", fontSize: "20px" }} aria-hidden="true"></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">Showing 1 to 3 of 3 entries</div>
                                        <div className="dataTables_paginate paging_simple_numbers" id="example1_paginate">
                                            <ul className="pagination">
                                                <li className="paginate_button page-item previous disabled" id="example1_previous">
                                                    <a aria-controls="example1" data-dt-idx="0" tabIndex="0" className="page-link">Previous</a>
                                                </li>
                                                <li className="paginate_button page-item active">
                                                    <a aria-controls="example1" data-dt-idx="1" tabIndex="0" className="page-link">1</a>
                                                </li>
                                                <li className="paginate_button page-item next disabled" id="example1_next">
                                                    <a aria-controls="example1" data-dt-idx="2" tabIndex="0" className="page-link">Next</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

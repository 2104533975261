import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import RestService from "../http";
// import swal from 'sweetalert';
import { FaFileExcel } from "react-icons/fa";
import formatDate from "./utilityComponents/FormatDate";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import Pagination2 from './utilityComponents/Pagination2';
import * as XLSX from 'xlsx';
import Select from 'react-select';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';
import { Link } from 'react-router-dom';


export default function Rechargereport() {

    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();
    const [rechargeReport, setRechargeReport] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [log, setLog] = useState('');
    const [showModal2, setShowModal2] = useState(false);
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());
    const [userList, setUserList] = useState([]);
    const [userId, setUserId] = useState("");
    const [roles, setRoles] = useState([]);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };


    const [status, setStatus] = useState('');

    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    const downloadExcel = () => {
        // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
        // writeFile(wb, 'recharge_report.xlsx');

        const ws = XLSX.utils.json_to_sheet(rechargeReport);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Recharge Report');

        XLSX.writeFile(wb, 'recharge_report.xlsx');
    };

    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);



    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const [responseData, setResponseData] = useState(null);

    // const openModal = (data) => {
    //     setIsModalOpen(true);
    //     setResponseData(data);
    // };

    // const closeModal2 = () => {
    //     setIsModalOpen(false);
    //     setResponseData(null);
    // };

    const service = new RestService();


    const handleRechargeReport = () => {
        var raw = {
            "fromDate": formatDate(date),
            "toDate": formatDate(date1),
            "userid": userId,
            "status": status
        };

        setLoading(true);

        service.post("report/fetchRechargeReport", raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                setRechargeReport(result.data);

            } else {
                showToast("DANGER", result.message);
                setRechargeReport([]);
            }

        }).catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        handleRechargeReport();
    }, []);

    useEffect(() => {
        service.get("user/roles").then(result => {
            console.log(result);
            setRoles(result.role);

        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const handleShowModal = (e, data) => {
        setShowModal(true);
        setShowModal2(false);
        setLog(data);
    }

    const handleShowModal2 = (e, data) => {
        setShowModal2(true);
        setShowModal(false);
        setLog(data);
    }


    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCloseModal2 = () => {
        setShowModal2(false);
    };

    const handleCustomerType = (e) => {
        console.log(e.target.value);
        service
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setUserList(result.data);
                } else {
                    setUserList([]);
                }
            })
            .catch((err) => {
                console.error(err);
                setUserList([]);
            });
    };


    const [filteredRechargeReport, setFilteredRechargeReport] = useState([]);
    const [searchInput, setSearchInput] = useState('');


    useEffect(() => {
        const filteredList = rechargeReport.filter(item =>
            item.mobile.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.userId.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.operatorId.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.comm.toString().includes(searchInput.toLowerCase()) ||
            item.tid.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.status.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.source.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.apiId.toString().includes(searchInput.toLowerCase()) ||
            item.wlId.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.date.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredRechargeReport(filteredList);
    }, [rechargeReport, searchInput]);

    const paginatedRechargeReport = filteredRechargeReport.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    const [selectedUserId, setSelectedUserId] = useState(null);

    const options = [
        { value: '', label: 'Select an option' },
        ...userList.map((item) => ({
            value: item.userId,
            label: `${item.name} || ${item.mobile}`,
        }))
    ];

    const handleSelectChange = (selectedUserId) => {
        setSelectedUserId(selectedUserId);
        setUserId(selectedUserId.value);
    };


    const handleStatusCheck = (orderId) => {
        var raw = {
            "orderId": orderId
        }

        service.post("recharge/rechargeStatusUpdate", raw).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);

            } else {
                showToast("DANGER", result.message);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Recharge Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Recharge</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Recharge Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label for="rec_to_load"> Start Date</label><br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label for="rec_to_load"> End Date</label>
                                                <br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    disabledDayAriaLabelPrefix="#$"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date1}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate1(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label for="assign_commission">
                                                    User Type: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleCustomerType}
                                                >
                                                    <option>Select User Type</option>
                                                    {roles.map((item) => (
                                                        item.roleId !== 0 ? <option value={item.roleId}>{item.roleName}</option> : null
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="service_id">
                                                    User Id: <span className="text-danger"> *</span>
                                                </label>
                                                {/* <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setUserId(e.target.value)}
                                                >
                                                    <option value="">Select Id...</option>
                                                    {userList.map((item) => (
                                                        <option value={item.userId}>{item.name}</option>
                                                    ))}
                                                </select> */}

                                                <Select
                                                    className="select-box width100p"
                                                    options={options}
                                                    isSearchable
                                                    value={selectedUserId}
                                                    onChange={handleSelectChange}
                                                />
                                            </div>

                                            <div className="col-md-3 mt-2">
                                                <label>
                                                    Status <span className="text-danger"> *</span>
                                                </label>

                                                <select
                                                    className="custom-date-picker"
                                                    name="customer_type"
                                                    id="customer_type"
                                                    fdprocessedid="g30y5e"
                                                    onChange={(e) => setStatus(e.target.value)}
                                                >
                                                    <option value={""}>SELECT STATUS</option>
                                                    <option value={"SUCCESS"}>SUCCESS</option>
                                                    <option value={"FAILED"}>FAILED</option>
                                                    <option value={"PENDING"}>PENDING</option>
                                                </select>
                                            </div>

                                            <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                                            <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ textAlign: 'center' }}>
                                                <button
                                                    onClick={handleRechargeReport}
                                                    className="btn btn-primary btn-block"
                                                    style={{ marginTop: '37px', color: 'white', fontWeight: 600 }}
                                                    fdprocessedid="qnf2"
                                                >
                                                    Search
                                                </button>

                                            </div>
                                            <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ marginTop: '19px', textAlign: 'center' }}>
                                                <button type="button" name="" className="btn btn-success" style={{ marginTop: '19px' }} onClick={downloadExcel} fdprocessedid="9svl7o">
                                                    <FaFileExcel className="fa fa-file-excel-o" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                <center>
                                    <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                        <div>

                                            <div className="col-md-3" style={{ float: "left" }}>
                                                <div>
                                                    <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                    <input
                                                        className="form-control text-box"
                                                        type="text"
                                                        placeholder="SEARCH..."
                                                        value={searchInput}
                                                        onChange={(e) => setSearchInput(e.target.value)}

                                                    />
                                                </div>
                                            </div><br />

                                            <Pagination2
                                                totalPages={Math.ceil(rechargeReport.length / itemsPerPage)}
                                                currentPage={currentPage}
                                                onChange={handlePageChange}

                                            />
                                        </div>
                                    </div>
                                </center>

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr className="wrap-text text-center">
                                                <th className="px-1">SL.NO</th>
                                                <th>USER ID</th>
                                                <th>MOBILE</th>
                                                <th>Operator Id</th>
                                                <th className="px-1">OP BAL</th>
                                                <th>AMOUNT</th>
                                                <th>CHARGE</th>
                                                <th className="px-1">COMMISSON</th>
                                                <th>CL BAL</th>
                                                <th>TID</th>
                                                <th className="px-1">Status</th>
                                                <th>Source</th>
                                                <th>Api Id</th>
                                                <th>Role Id</th>
                                                <th>Wl Id</th>
                                                <th>Date&Time</th>
                                                <th>IP</th>
                                                <th>Request Log</th>
                                                <th>Response Log</th>
                                                <th>Order Id</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!paginatedRechargeReport || paginatedRechargeReport.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="31">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                paginatedRechargeReport.map((item, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.userId}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{item.operatorId}</td>
                                                        <td>{item.openBal}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{item.charge}</td>
                                                        <td>{item.comm}</td>
                                                        <td>{item.closeBal}</td>
                                                        <td>{item.tid}</td>
                                                        <td style={{ color: item.status === "SUCCESS" ? 'green' : (item.status === "PENDING" ? 'orange' : 'red'), fontWeight: "bold" }}>{item.status}</td>
                                                        <td>{item.source}</td>
                                                        <td>{item.apiId}</td>
                                                        <td>{item.roleId}</td>
                                                        <td>{item.wlId}</td>
                                                        <td>{`${item.date} & ${item.time}`}</td>
                                                        <td>{item.ip}</td>
                                                        <td>
                                                            <button
                                                                className="slab_btn"
                                                                // onClick={() => setShowModal(true)}
                                                                onClick={(e) => handleShowModal(e, item.requestLog)}
                                                            >
                                                                View Request Log
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="slab_btn"
                                                                // onClick={() => setShowModal(true)}
                                                                onClick={(e) => handleShowModal2(e, item.responseLog)}
                                                            >
                                                                View Response Log
                                                            </button>
                                                        </td>
                                                        <td>{item.orderId}</td>
                                                        {(item.status === "FAILED") ?
                                                            <td></td> :
                                                            <td>
                                                                <button className='btn bg-success' onClick={() => handleStatusCheck(item.orderId)}>Status Check</button>
                                                            </td>}
                                                    </tr>

                                                ))
                                            )}
                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <td
                                                    colSpan="4"
                                                    style={{ fontWeight: "bold" }}
                                                    className="text-primary"
                                                    rowSpan="1"
                                                >
                                                    Row Count {rechargeReport.length}
                                                </td>
                                                <td
                                                    className="text-right font-weight-bold text-success"

                                                >

                                                </td>
                                                <td colSpan="3"></td>
                                            </tr>
                                        </tfoot>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {showModal && (
                <div className="modal fade show" style={{ display: 'block' }} aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLongTitle">Request</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='card'>
                                        <div className='card-body'>
                                            {log}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" fdprocessedid="7wkuky" onClick={handleCloseModal}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showModal2 && (
                <div className="modal fade show" style={{ display: 'block' }} aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLongTitle">Response</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal2}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='card'>
                                        <div className='card-body'>
                                            {log}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" fdprocessedid="7wkuky" onClick={handleCloseModal2}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            <footer className="main-footer" >
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function Editapicredential() {

    const [parameters, setParameters] = useState([{ parameter: '', value: '' }]);

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newParameters = [...parameters];
        newParameters[index][name] = value;
        setParameters(newParameters);
    };

    const handleAddParameter = () => {
        setParameters([...parameters, { parameter: '', value: '' }]);
    };

    const handleRemoveParameter = (index) => {
        const newParameters = [...parameters];
        newParameters.splice(index, 1);
        setParameters(newParameters);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const handleSaveChanges = () => {
        console.log('Save Changes button clicked!');
      };

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">API Credential</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Settings</Link></li>
                                    <li className="breadcrumb-item active">Api Credential</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card col-8 m-auto" id="addpack">
                            <div className="card-body">
                                <div>
                                    <form onSubmit={handleSubmit}>
                                        <input type="hidden" name="securityToken" value="adb04c72b8d26840ba808fd10a739df7" />
                                        {parameters.map((parameter, index) => (
                                            <div className="row" key={index}>
                                                <div className="col-6" style={{ marginTop: '10px' }}>
                                                    <label>API Name</label>
                                                    <select
                                                        name="api_id"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="f8qkkc"
                                                        onChange={(e) => handleInputChange(index, e)}
                                                        value={parameter.parameter}
                                                    >
                                                        <option value="">Choose API</option>
                                                        <option value="1">OFFLINE</option>
                                                        <option value="2">PLAN API</option>
                                                    </select>
                                                </div>
                                                <div className="col-5" style={{ marginTop: '10px' }}>
                                                    <label>Parameter</label>
                                                    <input
                                                        type="text"
                                                        name="parameter"
                                                        placeholder="Enter Parameter"
                                                        value={parameter.parameter}
                                                        className="form-control parameter"
                                                        required
                                                        fdprocessedid="z5ado8"
                                                        onChange={(e) => handleInputChange(index, e)}
                                                    />
                                                </div>
                                                <div className="col-5" style={{ marginTop: '10px' }}>
                                                    <label>Value</label>
                                                    <input
                                                        type="text"
                                                        name="value"
                                                        placeholder="Enter Value"
                                                        value={parameter.value}
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="dutb5r"
                                                        onChange={(e) => handleInputChange(index, e)}
                                                    />
                                                </div>
                                                <div className="col-2" style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                                    {index > 0 && (
                                                        <button type="button" className="btn btn-danger" onClick={() => handleRemoveParameter(index)}>
                                                            <FaTrashAlt />
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                        <p className="text-danger error" style={{ fontWeight: 'bold' }}></p>
                                        <div className="text-success btn-hover addcredential" style={{ cursor: 'pointer' }} onClick={handleAddParameter}>
                                            Add +
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12 text-center" style={{ marginTop: '10px' }}>
                                                <input
                                                    type="submit"
                                                    name=""
                                                    value="Save Changes"
                                                    className="btn btn-primary"
                                                    fdprocessedid="ty9jzv"
                                                    onClick={handleSaveChanges}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

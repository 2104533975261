import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';


export default function Editnews() {

    const [status, setStatus] = useState('1');
    const [notificationType, setNotificationType] = useState('1');
    const [newsContent, setNewsContent] = useState('');

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleNotificationTypeChange = (event) => {
        setNotificationType(event.target.value);
    };

    const handleNewsContentChange = (event) => {
        setNewsContent(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        console.log('Status:', status);
        console.log('Notification Type:', notificationType);
        console.log('News Content:', newsContent);
    };

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '528.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">News Panel</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Utility</Link></li>
                                    <li className="breadcrumb-item">News Panel</li>
                                    <li className="breadcrumb-item active">News Panel Edit</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card" id="addpack">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <label>News Active/Deactive</label><span className="text-danger"> *</span><br />
                                            <input type="radio" name="status" value="1" required checked={status === '1'} onChange={handleStatusChange} /> Active
                                            <input type="radio" name="status" value="0" required checked={status === '0'} onChange={handleStatusChange} /> Deactive
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <label>News Active/Deactive</label><span className="text-danger"> *</span><br />
                                            <input type="radio" name="notification_type" value="1" required checked={notificationType === '1'} onChange={handleNotificationTypeChange} /> App
                                            <input type="radio" name="notification_type" value="0" required checked={notificationType === '0'} onChange={handleNotificationTypeChange} /> Web
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <label>News</label><span className="text-danger"> *</span>
                                            <textarea
                                                name="html_code"
                                                value={newsContent}
                                                id="html_code"
                                                className="form-control"
                                                data-sample-short=""
                                                style={{ visibility: 'hidden', display: 'none' }}
                                                onChange={handleNewsContentChange}
                                            />
                                            <input type="hidden" name="securityToken" value="b2881aaea5de2f3b5adad322f0d8f858" />
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                                <input type="submit" value="Add News" className="btn btn-primary" style={{ marginTop: '30px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

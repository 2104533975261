import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import RestService from '../http';
import showToast from './utilityComponents/APPToast';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';


export default function Dispute() {

    const service = new RestService();
    const navigate = useNavigate();
    const [isSessionExpired, setIsSessionExpired] = useState(false);


    const sessionExpiretionPopup = () => {
        if (isSessionExpired) {
            // Show the SweetAlert when the session has expired
            swal({
                title: 'Session Expired',
                text: 'Your login session has expired. Please log in again.',
                icon: 'warning',
                closeOnClickOutside: false,
                buttons: {
                    confirm: {
                        text: 'OK',
                        className: 'btn btn-primary',
                    },
                },
                dangerMode: true,
            }).then((confirmed) => {
                // You can add additional logic here when the user clicks the OK button
                // For example, redirect to the login page or perform other actions
                if (confirmed) {
                    // Redirect to the login page (this is just an example)
                    window.location.href = '/';
                    sessionStorage.removeItem("token");
                    sessionStorage.removeItem("user");
                }
            })
        }
    }
    useEffect(() => {
        // Check session status on component mount (this can be done elsewhere based on your actual implementation)
        sessionExpiretionPopup();
    }, [sessionExpiretionPopup]);


    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropDownRef = useRef();
    const [trackTicket, setTrackTicket] = useState([]);


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    const handleTrackTicket = () => {

        service.post("emp/trackTickets", null).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                setTrackTicket(result.data);

            } else {
                showToast("DANGER", result.message);
                setTrackTicket([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        handleTrackTicket();
    }, [])


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "560.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Dispute</h1>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Support</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Dispute</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr className="wrap-text text-center">
                                                <th>#</th>
                                                <th>User Id</th>
                                                <th>User Message</th>
                                                <th>Emp Remark</th>
                                                <th>Ticket Id</th>
                                                <th>Emp Id</th>
                                                <th>Ticket Status</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!trackTicket || trackTicket.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="7">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                trackTicket.map((option, index) => (
                                                    <tr className='text-center'>
                                                        <tr>{index + 1}</tr>
                                                        <td>{option.userId}</td>
                                                        <td>{option.userMessage}</td>
                                                        <td>{option.empRemark}</td>
                                                        <td>{option.ticketId}</td>
                                                        <td>{option.empId}</td>
                                                        <td>{option.status}</td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { FaExpeditedssl } from 'react-icons/fa';
import { useState } from 'react';
import { Link } from 'react-router-dom';


export default function Updateuser() {

    const [selectAll, setSelectAll] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [apiUsersChecked, setApiUsersChecked] = useState(false);
    const [deleteCredentialsChecked, setDeleteCredentialsChecked] = useState(true);
    const [lapuConfigurationChecked, setLapuConfigurationChecked] = useState(true);
    const [deleteLapuConfigChecked, setDeleteLapuConfigChecked] = useState(true);



    const handleSelectAll = (checked) => {
        setSelectAllChecked(checked);

        const allCheckboxes = document.querySelectorAll('.check_auth');
        allCheckboxes.forEach((checkbox) => {
            checkbox.checked = checked;
        });

    };


    const [openCollapse, setOpenCollapse] = useState(null);

    const handleCollapseToggle = (id) => {
        setOpenCollapse((prevId) => (prevId === id ? null : id));
    };


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '532.667px' }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Authority of User</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">HR Management</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/systemuser">Users</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Update Authority</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header ">
                                <span className="main_title">#1 Sharad</span>
                            </div>

                            <div className='card-body table-responsive'>
                                <form>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div id='accordion' className='custom-accordian mb-4'>
                                                <div className='row'>

                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="1">
                                                                <h5 className="m-0 font-size-15">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 1 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_1"
                                                                        aria-expanded={openCollapse === 1 ? 'true' : 'false'}
                                                                        aria-controls="href_1"
                                                                        onClick={() => handleCollapseToggle(1)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #1 - Dashboard
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 1 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_1"
                                                                className={`collapse ${openCollapse === 1 ? 'show' : ''}`}
                                                                aria-labelledby="1"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Dashboard</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[1]"
                                                                                value="2"
                                                                                className="check_auth all_1"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">Select All</span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_1"
                                                                                data-access="1"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Customer Dashboard</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[93]"
                                                                                value="2"
                                                                                className="check_auth all_1"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="2">
                                                                <h5 className="m-0 font-size-15">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 2 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_2"
                                                                        aria-expanded={openCollapse === 2 ? 'true' : 'false'}
                                                                        aria-controls="href_2"
                                                                        onClick={() => handleCollapseToggle(2)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #2 - Member
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 2 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_2"
                                                                className={`collapse ${openCollapse === 2 ? 'show' : ''}`}
                                                                aria-labelledby="2"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Member</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[2]"
                                                                                value="2"
                                                                                className="check_auth all_2"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">Select All</span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_2"
                                                                                data-access="2"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Manage Member</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[3]"
                                                                                value="2"
                                                                                className="check_auth all_2"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">Login Details</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[4]"
                                                                                value="2"
                                                                                className="check_auth all_2"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Member Commission
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[5]"
                                                                                value="2"
                                                                                className="check_auth all_2" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">
                                                                            Change Sponser
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[6]"
                                                                                value="2"
                                                                                className="check_auth all_2" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">
                                                                            Add Member
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[7]"
                                                                                value="2"
                                                                                className="check_auth all_2" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">
                                                                            edit Member
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[8]"
                                                                                value="2"
                                                                                className="check_auth all_2" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">
                                                                            view Member
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[9]"
                                                                                value="2"
                                                                                className="check_auth all_2" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">
                                                                            view Member Login detail
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[10]"
                                                                                value="2"
                                                                                className="check_auth all_2" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">
                                                                            Deactive Member
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[11]"
                                                                                value="2"
                                                                                className="check_auth all_2" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">
                                                                            Debit AC activation charges
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[12]"
                                                                                value="2"
                                                                                className="check_auth all_2" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">
                                                                            Download Member Excel
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[13]"
                                                                                value="2"
                                                                                className="check_auth all_2" />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="7">
                                                                <h5 className="m-0 font-size-15">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 7 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_7"
                                                                        aria-expanded={openCollapse === 7 ? 'true' : 'false'}
                                                                        aria-controls="href_7"
                                                                        onClick={() => handleCollapseToggle(7)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #3 - Support
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 7 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div id="href_7"
                                                                className={`collapse ${openCollapse === 7 ? 'show' : ''}`}
                                                                aria-labelledby="7"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Support</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[7]"
                                                                                value="2"
                                                                                className="check_auth all_7"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">Select All</span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_7"
                                                                                data-access="7"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Dispute</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[9]"
                                                                                value="2"
                                                                                className="check_auth all_7"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">
                                                                            Contact Information
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[10]"
                                                                                value="2"
                                                                                className="check_auth all_7"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">
                                                                            view complaint summary
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[10]"
                                                                                value="2"
                                                                                className="check_auth all_7"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">
                                                                            edit Dispute
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[10]"
                                                                                value="2"
                                                                                className="check_auth all_7"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">
                                                                            DTH customer care Info
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[10]"
                                                                                value="2"
                                                                                className="check_auth all_7"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">
                                                                            Edit contact info
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[10]"
                                                                                value="2"
                                                                                className="check_auth all_7"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">
                                                                            Dispute comment
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[10]"
                                                                                value="2"
                                                                                className="check_auth all_7"
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="11">
                                                                <h5 className="m-0 font-size-15">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 11 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_11"
                                                                        aria-expanded={openCollapse === 11 ? 'true' : 'false'}
                                                                        aria-controls="href_11"
                                                                        onClick={() => handleCollapseToggle(11)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #4 - Transaction
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 11 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_11"
                                                                className={`collapse ${openCollapse === 11 ? 'show' : ''}`}
                                                                aria-labelledby="11"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Transaction</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[11]"
                                                                                value="2"
                                                                                className="check_auth all_11"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">Select All</span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_11"
                                                                                data-access="11"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Transaction History</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[12]"
                                                                                value="2"
                                                                                className="check_auth all_11"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Pending Transactions</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[12]"
                                                                                value="2"
                                                                                className="check_auth all_11"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Refund Transaction</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[12]"
                                                                                value="2"
                                                                                className="check_auth all_11"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">transaction history search</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[12]"
                                                                                value="2"
                                                                                className="check_auth all_11"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">check transaction status</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[12]"
                                                                                value="2"
                                                                                className="check_auth all_11"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">pending transaction edit</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[12]"
                                                                                value="2"
                                                                                className="check_auth all_11"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">edit refund transaction</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[12]"
                                                                                value="2"
                                                                                className="check_auth all_11"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">update record</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[12]"
                                                                                value="2"
                                                                                className="check_auth all_11"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1"> Resubmit Recharge</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[12]"
                                                                                value="2"
                                                                                className="check_auth all_11"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1"> Request Transactions</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[12]"
                                                                                value="2"
                                                                                className="check_auth all_11"
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="15">
                                                                <h5 className="m-0 font-size-15">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 15 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_15"
                                                                        aria-expanded={openCollapse === 15 ? 'true' : 'false'}
                                                                        aria-controls="href_15"
                                                                        onClick={() => handleCollapseToggle(15)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #5 - Wallet
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 15 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_15"
                                                                className={`collapse ${openCollapse === 15 ? 'show' : ''}`}
                                                                aria-labelledby="15"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Wallet</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[15]" value="2" className="check_auth all_15" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">Select All</span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_15"
                                                                                data-access="15"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Wallet Summary</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[16]" value="2" className="check_auth all_15" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Fund Request</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[16]" value="2" className="check_auth all_15" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Fund Manage </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[16]" value="2" className="check_auth all_15" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">approve fund request </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[16]" value="2" className="check_auth all_15" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">reject fund request </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[16]" value="2" className="check_auth all_15" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1"> virtual Balance </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[16]" value="2" className="check_auth all_15" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Fund credit </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[16]" value="2" className="check_auth all_15" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Fund debit </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[16]" value="2" className="check_auth all_15" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">internal payment transfer </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[16]" value="2" className="check_auth all_15" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Add money </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[16]" value="2" className="check_auth all_15" />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="25">
                                                                <h5 className="m-0 font-size-15">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 25 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_25"
                                                                        aria-expanded={openCollapse === 25 ? 'true' : 'false'}
                                                                        aria-controls="href_25"
                                                                        onClick={() => handleCollapseToggle(25)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #6 - Settings
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 25 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_25"
                                                                className={`collapse ${openCollapse === 25 ? 'show' : ''}`}
                                                                aria-labelledby="25"
                                                                data-parent="#accordion">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Settings</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[25]" value="2" className="check_auth all_25" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">Select All</span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_25"
                                                                                data-access="25"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Api &amp; Commission</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Switch API</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">package master</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">operator master</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Amount Block</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Recharge Settings</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">services</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Add API &  commission</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">view API</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">view Circle </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Edit API & Commission</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">customer Authority</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Edit package </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Add package</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Add operator</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Delete operator</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Add Block Amount</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">active/deactive Block amount</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Delete Block amount</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">edit mobie recharge service</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">edit service status</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">panel Access</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">SMS setting</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">edit api auto switch</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">edit recharge wallet lock</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">edit fund request lock</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">edit money transfer wallet lock</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">edit company initial</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Edit Api Credential</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Delete Api Credential</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">API Credential</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">set activation charges</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Edit API Amount</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Add API Amount</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">UPI Apps</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">payment Settings</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">VIP Numbers</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Dth Connection</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Circle master</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Utility APIS</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Utility Api's Update</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Email Configuration</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input type="checkbox" name="autho[26]" value="2" className="check_auth all_25" />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="33">
                                                                <h5 className="m-0 font-size-15">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 33 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_33"
                                                                        aria-expanded={openCollapse === 33 ? 'true' : 'false'}
                                                                        aria-controls="href_33"
                                                                        onClick={() => handleCollapseToggle(33)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #7 - Utilities
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 33 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_33"
                                                                className={`collapse ${openCollapse === 33 ? 'show' : ''}`}
                                                                aria-labelledby="33"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Utilities
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={selectAll}
                                                                                onChange={handleSelectAll}
                                                                                className="select_all all_33"
                                                                                data-access="33"
                                                                                value="0"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">Select All</span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[33]"

                                                                                value="2"
                                                                                className="check_auth all_33"

                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            News Panel
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[35]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Login Alert
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[36]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Send SMS
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[37]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Send Email
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[38]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add Bank Account
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[86]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Edit Bank Account
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[87]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Delete Bank Account
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[88]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add news panel
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[89]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Edit news panel
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[90]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add Service Process
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[92]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Email send
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[94]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            add login Alert
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[125]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Edit login alert
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[128]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            App Slider
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[129]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            add app slider
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[130]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Edit app slider
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[131]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Delete app slider
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[132]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            App Notification
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[141]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            edit refer condition
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[164]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Promocodes
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[165]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Create Promocode
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[166]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            edit Promocode
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[167]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Delete App Notification
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[177]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            View Promocode
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[182]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Cashback Offer
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[203]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add Cashback Offer
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[204]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Delete Cashback Offer
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[205]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Enquries
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[208]" value="2" className="check_auth all_33" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="39">
                                                                <h5 className="m-0 font-size-15 ">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 39 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_39"
                                                                        aria-expanded={openCollapse === 39 ? 'true' : 'false'}
                                                                        aria-controls="href_39"
                                                                        onClick={() => handleCollapseToggle(39)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #8 - Account Master
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 39 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_39"
                                                                className={`collapse ${openCollapse === 39 ? 'show' : ''}`}
                                                                aria-labelledby="39"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Account Master
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[39]" value="2" className="check_auth all_39" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">
                                                                                Select All
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_39"
                                                                                data-access="39"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Heads
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[40]" value="2" className="check_auth all_39" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Ledgers
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[41]" value="2" className="check_auth all_39" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Transaction
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[42]" value="2" className="check_auth all_39" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            General Entry
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[43]" value="2" className="check_auth all_39" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add Head
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[95]" value="2" className="check_auth all_39" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            edit head
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[96]" value="2" className="check_auth all_39" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add Ledger
                                                                        </div>
                                                                        <div class="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[97]" value="2" className="check_auth all_39" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            edit ledger
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[98]" value="2" className="check_auth all_39" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Balancesheet
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[175]" value="2" className="check_auth all_39" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="44">
                                                                <h5 className="m-0 font-size-15 ">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 44 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_44"
                                                                        aria-expanded={openCollapse === 44 ? 'true' : 'false'}
                                                                        aria-controls="href_44"
                                                                        onClick={() => handleCollapseToggle(44)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #9 - HR Management
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 44 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_44"
                                                                className={`collapse ${openCollapse === 44 ? 'show' : ''}`}
                                                                aria-labelledby="44"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            HR Management
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[44]" value="2" className="check_auth all_44" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">
                                                                                Select All
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_44"
                                                                                data-access="44"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Employee
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[45]" value="2" className="check_auth all_44" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add System User
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[70]" value="2" className="check_auth all_44" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Update User Authority
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[71]" value="2" className="check_auth all_44" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add Employee
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[100]" value="2" className="check_auth all_44" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            edit employee
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[101]" value="2" className="check_auth all_44" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            view employee
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[102]" value="2" className="check_auth all_44" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            System User
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[112]" value="2" className="check_auth all_44" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="46">
                                                                <h5 className="m-0 font-size-15 ">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 46 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_46"
                                                                        aria-expanded={openCollapse === 46 ? 'true' : 'false'}
                                                                        aria-controls="href_46"
                                                                        onClick={() => handleCollapseToggle(46)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #10 - Web Configuration
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 46 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_46"
                                                                className={`collapse ${openCollapse === 46 ? 'show' : ''}`}
                                                                aria-labelledby="46"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Web Configuration
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[46]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">
                                                                                Select All
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_46"
                                                                                data-access="46"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Site Settings
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[47]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Edit Service Proceess
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[91]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            set slider
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[103]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add Features
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[104]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            edit features
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[113]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Edit Headings
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[114]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Set Headings
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[115]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add Our Services
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[118]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Edit Our Services
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[119]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add Counter
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[126]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Edit Counters
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[127]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add Staff Members
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[133]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Edit Staff Members
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[134]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Company Setting
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[136]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Set Icon And Fevicon
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[137]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Set Copyright
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[138]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add Page Titles
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[139]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Edit Page Titles
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[140]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add About Paraghraph
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[142]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Contact Settings
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[144]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            About|Set What we do
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[145]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Google Map
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[162]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            edit Google Map
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[163]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Set App link
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[181]" value="2" className="check_auth all_46" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="48">
                                                                <h5 className="m-0 font-size-15">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 48 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_48"
                                                                        aria-expanded={openCollapse === 48 ? 'true' : 'false'}
                                                                        aria-controls="href_48"
                                                                        onClick={() => handleCollapseToggle(48)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #11 - Developer Tools
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 48 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_48"
                                                                className={`collapse ${openCollapse === 48 ? 'show' : ''}`}
                                                                aria-labelledby="48"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Developer Tools</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[48]"
                                                                                value="2"
                                                                                className="check_auth all_48"
                                                                                checked={apiUsersChecked}
                                                                                onChange={(e) => setApiUsersChecked(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">Select All</span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_48"
                                                                                data-access="48"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            API Users
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[49]" value="2" className="check_auth all_48" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            API Keys And Settings
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[50]" value="2" className="check_auth all_48" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            API Integration Tool
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[51]" value="2" className="check_auth all_48" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            edit API Keys And Settings
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[105]" value="2" className="check_auth all_48" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Delete API Keys And Settings
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[106]" value="2" className="check_auth all_48" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            API Services
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[210]" value="2" className="check_auth all_48" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add API Services
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[211]" value="2" className="check_auth all_48" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            EditApiUserService
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[212]" value="2" className="check_auth all_48" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="116">
                                                                <h5 className="m-0 font-size-15">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 116 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_116"
                                                                        aria-expanded={openCollapse === 116 ? 'true' : 'false'}
                                                                        aria-controls="href_116"
                                                                        onClick={() => handleCollapseToggle(116)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #12 - Report
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 116 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_116"
                                                                className={`collapse ${openCollapse === 116 ? 'show' : ''}`}
                                                                aria-labelledby="116"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Report
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[116]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">
                                                                                Select All
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_116"
                                                                                data-access="116"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Operatorwise Commission Report
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[117]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            My Earning
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[143]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Credit Debit Report
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[146]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Admin Profit Report
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[147]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Refund Transaction Report
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[149]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Fund Request Report
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[168]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Profit Loss Report
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[176]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Day Book
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[178]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Downline Transaction
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[179]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Downline Wallet
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[180]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Print Fund Req. Invoice
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[184]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Payment Report
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[213]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Payment Report In Detail
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[214]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Opening Closing
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[259]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Sales Report
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[262]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Retailer Report
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input type="checkbox" name="autho[263]" value="2" className="check_auth all_116" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="150">
                                                                <h5 className="m-0 font-size-15">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 150 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_150"
                                                                        aria-expanded={openCollapse === 150 ? 'true' : 'false'}
                                                                        aria-controls="href_150"
                                                                        onClick={() => handleCollapseToggle(150)}
                                                                        style={{ fontWeight: '400' }}

                                                                    >
                                                                        #13 - MRobotics Setup
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 150 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_150"
                                                                className={`collapse ${openCollapse === 150 ? 'show' : ''}`}
                                                                aria-labelledby="150"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">MRobotics Setup</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[150]"
                                                                                value="1"
                                                                                className="check_auth all_150"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">Select All</span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_150"
                                                                                data-access="150"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">MRobotics Credentials</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[151]"
                                                                                value="1"
                                                                                className="check_auth all_150"

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Add Credentials</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[152]"
                                                                                value="1"
                                                                                className="check_auth all_150"

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Delete MRobotics Credentials</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[153]"
                                                                                value="1"
                                                                                className="check_auth all_150"
                                                                                checked={deleteCredentialsChecked}
                                                                                onChange={(e) => setDeleteCredentialsChecked(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Lapu Configuration</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[154]"
                                                                                value="1"
                                                                                className="check_auth all_150"
                                                                                checked={lapuConfigurationChecked}
                                                                                onChange={(e) => setLapuConfigurationChecked(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Delete Lapu Config..</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[161]"
                                                                                value="1"
                                                                                className="check_auth all_150"
                                                                                checked={deleteLapuConfigChecked}
                                                                                onChange={(e) => setDeleteLapuConfigChecked(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="185">
                                                                <h5 className="m-0 font-size-15">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 185 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_185"
                                                                        aria-expanded={openCollapse === 185 ? 'true' : 'false'}
                                                                        aria-controls="href_185"
                                                                        onClick={() => handleCollapseToggle(185)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #14 - Dmt
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 185 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_185"
                                                                className={`collapse ${openCollapse === 185 ? 'show' : ''}`}
                                                                aria-labelledby="185"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Dmt</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[185]"
                                                                                value="2"
                                                                                className="check_auth all_185"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">Select All</span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_185"
                                                                                data-access="185"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Dashboard</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[186]"
                                                                                value="2"
                                                                                className="check_auth all_185"

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Add Beneficiary</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[188]"
                                                                                value="2"
                                                                                className="check_auth all_185"

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Delete Beneficiary</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[189]"
                                                                                value="2"
                                                                                className="check_auth all_185"

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Send Money</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[190]"
                                                                                value="2"
                                                                                className="check_auth all_185"

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Transaction History</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[191]"
                                                                                value="2"
                                                                                className="check_auth all_185"

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Wallet Summary</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[191]"
                                                                                value="2"
                                                                                className="check_auth all_185"

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Pending Transaction</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[192]"
                                                                                value="2"
                                                                                className="check_auth all_185"

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Update Transaction</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[193]"
                                                                                value="2"
                                                                                className="check_auth all_185"

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Refund</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[194]"
                                                                                value="2"
                                                                                className="check_auth all_185"

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Config</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[195]"
                                                                                value="2"
                                                                                className="check_auth all_185"

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Fund Transfer</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[196]"
                                                                                value="2"
                                                                                className="check_auth all_185"

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Fund Debit</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[197]"
                                                                                value="2"
                                                                                className="check_auth all_185"

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-4 mt-1">Fund Credit</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[198]"
                                                                                value="2"
                                                                                className="check_auth all_185"

                                                                            />
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="218">
                                                                <h5 className="m-0 font-size-15">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 218 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_218"
                                                                        aria-expanded={openCollapse === 218 ? 'true' : 'false'}
                                                                        aria-controls="href_218"
                                                                        onClick={() => handleCollapseToggle(218)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #15 - AEPS
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 218 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_218"
                                                                className={`collapse ${openCollapse === 218 ? 'show' : ''}`}
                                                                aria-labelledby="218"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">AEPS</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[218]"
                                                                                value="2"
                                                                                className="check_auth all_218"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">Select All</span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_218"
                                                                                data-access="218"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">AEPS Transactions</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[219]"
                                                                                value="2"
                                                                                className="check_auth all_218"

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">Settlement</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[220]"
                                                                                value="2"
                                                                                className="check_auth all_218"

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">AEPS Wallet</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[221]"
                                                                                value="2"
                                                                                className="check_auth all_218"

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">AEPS Transfer</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[222]"
                                                                                value="2"
                                                                                className="check_auth all_218"

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">AEPS Pending Transaction</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[223]"
                                                                                value="2"
                                                                                className="check_auth all_218"

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">One Time Settlement</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[224]"
                                                                                value="2"
                                                                                className="check_auth all_218"

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">AEPS Callback</div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[225]"
                                                                                value="2"
                                                                                className="check_auth all_218"

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />

                                    <input type="hidden" name="securityToken" value="2339912789ba77ea9526ef13f63da28b" />
                                    <div className="text-center">
                                        <button className="btn btn-sm btn-info" fdprocessedid="mbcjos">Update</button>
                                    </div>


                                </form>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link href="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

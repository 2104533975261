import React, { useEffect } from "react";
import Navbar from "./Navbar";
import { useState } from "react";
import RestService from "../http";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";
import { Link } from "react-router-dom";
import showToast from "./utilityComponents/APPToast";

export default function AssignPackage() {
    const service = new RestService();

    const [userList, setUserList] = useState([]);
    const [planList, setPlanList] = useState([]);
    const [planId, setPlanId] = useState("");
    const [fetchUserList, setFetchUserList] = useState("");
    const [serviceList, setServiceList] = useState([]);
    const [loading, setLoading] = useState(false);

    const handlePlanId = (e) => {
        setPlanId(e.target.value);
    };

    const handleUserType = (e) => {
        service
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setUserList(result.data);
                } else {
                    setUserList([]);
                }
            })
            .catch((error) => {
                console.error(error);
            });

    };

    const handlePlanList = () => {
        service
            .post("plans/getAllPlans", null)
            .then((result) => {
                if (result.status === service.SUCCESS) {
                    setPlanList(result.report);
                } else {
                    setPlanList([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        handlePlanList();
    }, []);

    // const handlePlanIdChange = (e) => {
    //   setFetchPlanId(e.target.value);
    // };

    const handleAddUser = () => {
        var raw = {
            userid: fetchUserList,
            planid: planId,
        };
        console.log(raw);

        setLoading(true);

        service.post("package/assignUserGroup", raw).then((result) => {
            setLoading(false);

            // console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
               
            } else {
                showToast("FAILED", result.message);
                
            }
        });
    };

    const handleSelectUser = (e) => {
        console.log(e.target.value);
        setFetchUserList(e.target.value);
    };

    const handleServiceCode = () => {
        service.post("service/getAllService", null).then((result) => {
            if (result.report === undefined) {
                setServiceList([]);
            } else {
                console.log(result);
                setServiceList(result.report);
            }
        });
    };

    useEffect(() => {
        handleServiceCode();
    }, []);


    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "600.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">CREATE PACKAGE </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Settings</Link>
                                    </li>
                                    <li className="breadcrumb-item active">ASSIGN PACKAGE</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">

                        <div className="card" id="addpack">
                            <div className="card-body">
                                <div>
                                    <div className="row" data-select2-id="4">
                                        <div className="col-md-3" data-select2-id="4">
                                            <label for="service_id">
                                                User Type: <span className="text-danger"> *</span>
                                            </label>
                                            <select
                                                className="form-control select2 "
                                                name="service_id"
                                                required=""
                                                id="service_id"
                                                data-select2-id="service_id"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                onChange={handleUserType}
                                            >
                                                <option>Select User Type</option>
                                                <option value={1}>API USER</option>
                                                <option value={2}>MASTER ADMIN</option>
                                            </select>
                                        </div>

                                        <div className="col-md-3" data-select2-id="4">
                                            <label for="service_id">
                                                User List: <span className="text-danger"> *</span>
                                            </label>
                                            <select
                                                className="form-control select2 "
                                                name="service_id"
                                                required=""
                                                id="service_id"
                                                data-select2-id="service_id"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                onChange={handleSelectUser}
                                            >
                                                <option>Select User...</option>
                                                {userList.map((item) => (
                                                    <option value={item.userId}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="col-md-3" data-select2-id="4">
                                            <label for="service_id">
                                                Plan Id: <span className="text-danger"> *</span>
                                            </label>
                                            <select
                                                className="form-control select2 "
                                                name="service_id"
                                                required=""
                                                id="service_id"
                                                data-select2-id="service_id"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                onChange={handlePlanId}
                                            >
                                                <option>Select Id</option>
                                                {planList.map((item) => (
                                                    <option key={item.planId} value={item.planId}>
                                                        {item.planName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mb-2">
                                <input
                                    type="submit"
                                    className="btn btn-primary btn-hover text-white"
                                    value="Create Package"
                                    onClick={handleAddUser}
                                />
                            </div>
                        </div>

                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    );
}

import React from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { RiHealthBookFill } from 'react-icons/ri'
import EmployeeNavbar from './EmployeeNavbar'

function EmployeeDashboard() {
    return (
        <>
        <EmployeeNavbar/>

            <div className="content-wrapper" style={{ minHeight: '532.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">
                                    Dashboard&nbsp;

                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="/employeedashboard">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="#">Settings</a>
                                    </li>
                                    <li className="breadcrumb-item active">Service</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <style>
                    {`
                        tr, td {
                       vertical-align: middle !important;
                      }
                     `}
                </style>
                <section className="content">
                    <div className="container-fluid">

                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="card" style={{ width: "22rem" }}>
                                        <div class="card-body">
                                            <h5 className="card-title" style={{ fontWeight: "bold" }}>Active Payroll</h5>
                                            <p className="card-text" style={{ color: "#6200ff", fontSize: "20px", fontWeight: "bold" }}>$5,000</p>
                                            <p className="card-text">Upcoming payment date: 24 March 2024</p>

                                        </div>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    <div className="card" style={{ width: "22rem" }}>
                                        <div class="card-body">
                                            <h5 className="card-title" style={{ fontWeight: "bold" }}>Time Of Balance</h5>
                                            <p className="card-text" style={{ color: "#6200ff", fontSize: "20px", fontWeight: "bold" }}>$5,000</p>
                                            <p className="card-text">Upcoming payment date: 24 March 2024</p>

                                        </div>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    <div className="card" style={{ width: "22rem" }}>
                                        <div class="card-body">
                                            <h5 className="card-title" style={{ fontWeight: "bold" }}>Billing Remaining</h5>
                                            <p className="card-text" style={{ color: "#6200ff", fontSize: "20px", fontWeight: "bold" }}>$5,000</p>
                                            <p className="card-text">Upcoming payment date: 24 March 2024</p>

                                        </div>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    <div className="card" style={{ width: "22rem" }}>
                                        <div class="card-body">
                                            <h5 className="card-title" style={{ fontWeight: "bold" }}>Active Payroll</h5>
                                            <p className="card-text" style={{ color: "#6200ff", fontSize: "20px", fontWeight: "bold" }}>$5,000</p>
                                            <p className="card-text">Upcoming payment date: 24 March 2024</p>

                                        </div>
                                    </div>

                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-8">
                                        <div className='card'>

                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ color: "purple" }}>#</th>
                                                        <th scope="col" style={{ color: "purple" }}>First</th>
                                                        <th scope="col" style={{ color: "purple" }}>Last</th>
                                                        <th scope="col" style={{ color: "purple" }}>Handle</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">1</th>
                                                        <td>Mark</td>
                                                        <td>Otto</td>
                                                        <td>@mdo</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">2</th>
                                                        <td>Jacob</td>
                                                        <td>Thornton</td>
                                                        <td>@fat</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">3</th>
                                                        <td>Larry</td>
                                                        <td>the Bird</td>
                                                        <td>@twitter</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <div className='card'>

                                            <div className='mui-paper-elevation' style={{ padding: "5px" }}>
                                                <h4 style={{ fontWeight: "bold", fontSize: "15px" }}>Available Benefits</h4><br />

                                                <div>
                                                    <RiHealthBookFill style={{ color: "#6200ff", fontSize: "20px" }} />
                                                    <h4 style={{ fontWeight: "bold", fontSize: "14px", marginTop: "1%" }}>Health Insurance</h4>
                                                    <p>Select the types of insurance for you and family  <MdKeyboardArrowRight style={{ color: "#6200ff", fontSize: "25px" }} /></p>
                                                </div>

                                                <div>
                                                    <RiHealthBookFill style={{ color: "#6200ff", fontSize: "20px" }} />
                                                    <h4 style={{ fontWeight: "bold", fontSize: "14px", marginTop: "1%" }}>Health Insurance</h4>
                                                    <p>Select the types of insurance for you and family  <MdKeyboardArrowRight style={{ color: "#6200ff", fontSize: "25px" }} /></p>
                                                </div>

                                                <div>
                                                    <RiHealthBookFill style={{ color: "#6200ff", fontSize: "20px" }} />
                                                    <h4 style={{ fontWeight: "bold", fontSize: "14px", marginTop: "1%" }}>Health Insurance</h4>
                                                    <p>Select the types of insurance for you and family  <MdKeyboardArrowRight style={{ color: "#6200ff", fontSize: "25px" }} /></p>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default EmployeeDashboard;
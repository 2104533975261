import React, { useState } from "react";
import RestService from "../../http";
import showToast from "./APPToast";
import Loader from "../Loader";

const AdminPayout = ({ data, allRecord }) => {

  const rest = new RestService();
  const [loading, setLoading] = useState(false);

  // const updateStatus = (userId) => {

  //   let request = {
  //     userId: userId,
  //   };

  //   rest.post("user/updatestatusmovetobank", request).then(result => {
  //     console.log(result);
  //     if (result.status === rest.SUCCESS) {
  //       showToast("SUCCESS", result.message);
  //       // allRecord();

  //     } else {
  //       showToast("FAILED", result.message);
  //     }
  //   }).catch(error => {
  //     console.log(error);
  //   })
  // }

  const handleStatusCheck = async (tranRefNo) => {

    var raw = {
      "operatorIncode": "YPS",
      "transRefno": tranRefNo
    }

    setLoading(true);

    const result = await rest.post("warehouse/payoutyes", raw);
    setLoading(false);

    console.log(result);

    if (result.status === rest.SUCCESS) {
      showToast("SUCCESS", result.message);
      
    } else {
      showToast("DANGER", result.message);
    }
  }

  return (
    <>
      {loading && <Loader />}

      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>INDEX</th>
            <th>Remeter Name</th>
            <th>Account No</th>
            <th>Tran Ref. No</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Time</th>
            <th>IFSC</th>
            <th>Status</th>
            <th>Bene No</th>
            <th>Mobile</th>
            <th>Mode</th>
            <th>Charge</th>
            <th>Bank RRN</th>
            <th>Bank Name</th>
            <th>Action</th>
            {/* <th>Action</th> */}
          </tr>
        </thead>

        <tbody>
          {data.length === 0 ? (
            <tr className="text-center">
              <td colSpan="16">NO DATA AVAILABLE</td>
            </tr>
          ) : (
            data.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item.remName}</td>
                <td>{item.accountNo}</td>
                <td>{item.tranRefNo}</td>
                <td className="font-weight-bold">{item.amount}</td>
                <td>{item.date}</td>
                <td>{item.time}</td>
                <td>{item.ifsc}</td>
                {/* <td>
                {item.status ? (
                  <span
                    onClick={() =>
                      updateStatus(
                        item.userId,
                      )
                    }
                    style={{ cursor: "pointer" }}
                    className="badge badge-success"
                  >
                    ACTIVE
                  </span>
                ) : (
                  <span
                    onClick={() =>
                      updateStatus(
                        item.userId,
                      )
                    }
                    style={{ cursor: "pointer" }}
                    className="badge badge-danger"
                  >
                    INACTIVE
                  </span>
                )}
              </td> */}
                <td style={{ color: item.status === "SUCCESS" ? 'green' : (item.status === "PENDING" ? 'orange' : 'red'), fontWeight: "bold" }}>{item.status}</td>
                <td>{item.beneId}</td>
                <td>{item.remMobile}</td>
                <td>{item.mode}</td>
                <td>{item.charge}</td>
                <td>{item.bankRrn}</td>
                <td>{item.bankName}</td>
                <td>
                  <button className="btn btn-primary" onClick={() => handleStatusCheck(item.tranRefNo)}>
                    Status Check
                  </button>
                </td>
                {/* <td>
                <button className="btn bg-blue">Edit</button>
              </td> */}
              </tr>
            ))
          )}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan="4"
              style={{ fontWeight: "bold" }}
              className="text-primary"
              rowSpan="1"
            >
              Row Count {allRecord.length}
            </td>

            <td
            className="text-right font-weight-bold text-success"
            rowSpan="1"
            colSpan="1"
          >
           Success Amt. ₹
            {allRecord.reduce(
              (accumulator, currentValue) =>
                accumulator +
                parseFloat(
                  currentValue.status === "SUCCESS"
                    ? currentValue.amount
                    : 0.0
                ),
              0
            )}
          </td>

          <td
            className="text-right font-weight-bold text-danger"
            rowSpan="1"
            colSpan="1"
          >
            Failed Amt. ₹
            {allRecord.reduce(
              (accumulator, currentValue) =>
                accumulator +
                parseFloat(
                  currentValue.status === "FAILED"
                    ? currentValue.amount
                    : 0.0
                ),
              0
            )}
          </td>

          <td
            className="text-right font-weight-bold text-warning"
            rowSpan="1"
            colSpan="1"
          >
           Pending Amt. ₹
            {allRecord.reduce(
              (accumulator, currentValue) =>
                accumulator +
                parseFloat(
                  currentValue.status === "PENDING"
                    ? currentValue.amount
                    : 0.0
                ),
              0
            )}
          </td>

            <td colSpan="3" rowSpan="1"></td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default AdminPayout;

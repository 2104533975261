import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestService from "../http";
import Loader from "./Loader";
import showToast from "./utilityComponents/APPToast";
import { Carousel } from "react-bootstrap";

function Newloginpage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [source] = useState("WEB");
  const [otp, setOtp] = useState("");
  const [hasOtp, setHasOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showForgotPass, setShowForgotPass] = useState(false);
  const [enableOtpForm, setEnableOtpForm] = useState(false);
  const service = new RestService();
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [showSecondOtpField, setShowSecondOtpField] = useState(false);
  const [secondOtp, setSecondOtp] = useState("");
  const [otpRequestId, setOtpRequestId] = useState("");

  const handleForgotPass = () => {
    setShowForgotPass(true);

  };

  const handleResetPassword = () => {

    if (!username) {
      showToast("WARN", "Please enter mobile number");
      return;
    }

    const raw = {
      "mobile": username,
      "reqId": "",
      "reqOtp": ""
    };

    service.post("user/sendOtp", raw).then(result => {
      console.log(result);

      if (result.status === service.SUCCESS) {
        showToast("SUCCESS", result.message);
        setShowSecondOtpField(true);
        setEnableOtpForm(false);
        setOtpRequestId(result.data.otpRequestId);


      } else {
        showToast("DANGER", result.message);
      }
    })
  };


  const handleSecondOtpLogin = () => {

    if (secondOtp === undefined || secondOtp === null || secondOtp === '') {
      showToast("WARN", "PLEASE ENTER OTP FIRST!!");
      return;
    }

    if (secondOtp.length !== 6) {
      showToast("WARN", "OTP SHOULD BE 6 DIGIT NUMBERS!!");
      return;
    }


    const raw = {
      "mobile": username,
      "reqId": otpRequestId,
      "reqOtp": secondOtp
    };

    service.post("user/sendOtp", raw).then(result => {
      console.log(result);

      if (result.status === service.SUCCESS) {
        showToast("SUCCESS", result.message);
        setHasOtp(true);
        setEnableOtpForm(false);

        setTimeout(() => {
          window.location.reload()
        }, 2000);

      } else {
        showToast("DANGER", result.message);
      }
    })
  }

  const handleLoginAgain = () => {
    setShowForgotPass(false);
  };

  const handleOtpKeyPress = (event) => {
    if (event.key === "Enter") {
      // Handle OTP submission
    }
  };


  const handleLoginApi = async (e) => {
    e.preventDefault();
    setLoading(true);

    const raw = {
      userName: username,
      password: password,
      source: source
    };

    console.log(raw);

    const result = await service.post("auth/authenticateUser", raw)
      .catch((error) => {
        console.log(error);
        setLoading(false);
        // setButtonDisabled(true);

        return {
          status: service.FAILED,
          message: 'SOMETHING WENT WRONG!!'
        };
      });

    console.log(result);
    if (result.status === service.SUCCESS) {
      sessionStorage.setItem("token", result.token);
      sessionStorage.setItem("user", result.user);
      sessionStorage.setItem("name", result.user.name);
      sessionStorage.setItem("role", result.user.roleId);
      var headers = {};
      var encodedToken = null;

      showToast("SUCCESS", "LOGIN SUCCESSFUL!!");
      setTimeout(() => {
        setLoading(false);
        switch (result.user.roleId) {
          case service.ADMIN:
            navigate('/admin');
            setTimeout(() => {
              window.location.reload();
            }, 1000);

            break;
          case service.API_USER:
            navigate('/retailerservice');

            setTimeout(() => {
              window.location.reload();
            }, 1000);

            break;
          case service.MASTER_ADMIN:
            headers = {
              'token': result.token
            }
            encodedToken = new URLSearchParams(headers).toString();
            // window.location.href = `https://tsp.softmintdigital.com/dashboard?${encodedToken}`;
            window.location.href = `http://localhost:3001/dashboard?${encodedToken}`;

            setTimeout(() => {
              window.location.reload();
            }, 1000);
            break;
          case service.MASTER_DISTRIBUTOR:
            headers = {
              'token': result.token
            }
            encodedToken = new URLSearchParams(headers).toString();
            window.location.href = `https://tsp.softmintdigital.com/dashboard?${encodedToken}`;
            // window.location.href = `http://localhost:3001/dashboard?${encodedToken}`;

            //   setTimeout(() => {
            //     window.location.reload();
            // },1000);
            break;
          case service.DISTRIBUTOR:
            headers = {
              'token': result.token
            }
            encodedToken = new URLSearchParams(headers).toString();
            // window.location.href = `https://tsp.softmintdigital.c
            window.location.href = `https://tsp.softmintdigital.com/dashboard?${encodedToken}`;

            break;
          case service.RETAILER:
            navigate('/retailerservice');

            setTimeout(() => {
              window.location.reload();
            }, 1000);

            break;
          case service.EMPLOYEE:
            navigate('/employeedashboard');
            break;
          default:

        }
      }, 2000);

    } else if (result.status === service.OTP_SUCCESS) {
      showToast("SUCCESS", result.message);
      setEnableOtpForm(true);
      setLoading(false);

    } else {
      showToast("DANGER", result.message);
      setLoading(false);
    }
  };

  const handleOtpLogin = async () => {
    if (otp === undefined || otp === null || otp === '') {
      showToast("WARN", "PLEASE ENTER OTP FIRST!!");
      return;
    }

    if (otp.length !== 6) {
      showToast("WARN", "OTP SHOULD BE 6 DIGIT NUMBERS!!");
      return;
    }

    let request = {
      userName: username,
      password: password,
      source: source,
      otp: otp,
    };

    const result = await service.post("auth/authenticateUser", request)

      .catch((error) => {
        console.log(error);
        setLoading(false);
        // setButtonDisabled(true);

        showToast("DANGER", "PLEASE CHECK YOUR INTERNET CONNECTION OR SERVER IS TEMPORARILY UNAVAILABLE, PLEASE CONTACT ADMIN!");
        return {
          status: service.FAILED,
          message: 'SOMETHING WRONG IN API CALLING!!'
        };
      });

    console.log(result);

    if (result.status === service.SUCCESS) {
      sessionStorage.setItem("token", result.token);
      sessionStorage.setItem("user", result.user);
      sessionStorage.setItem("name", result.user.name);
      sessionStorage.setItem("role", result.user.roleId);

      var headers = {};
      var encodedToken = null;

      showToast("SUCCESS", "LOGIN SUCCESSFUL!!");
      setTimeout(() => {
        setLoading(false);
        switch (result.user.roleId) {
          case service.ADMIN:
            navigate('/admin');
            setTimeout(() => {
              window.location.reload();
            }, 1000);

            break;
          case service.API_USER:
            navigate('/retailerservice');

            setTimeout(() => {
              window.location.reload();
            }, 1000);

            break;
          case service.MASTER_ADMIN:
            headers = {
              'token': result.token
            }
            encodedToken = new URLSearchParams(headers).toString();
            // window.location.href = `https://tsp.softmintdigital.com/dashboard?${encodedToken}`;
            window.location.href = `http://localhost:3001/dashboard?${encodedToken}`;

            setTimeout(() => {
              window.location.reload();
            }, 1000);
            break;

          case service.MASTER_DISTRIBUTOR:
            headers = {
              'token': result.token
            }
            encodedToken = new URLSearchParams(headers).toString();
            // window.location.href = `https://tsp.softmintdigital.c            
            window.location.href = `https://tsp.softmintdigital.com/dashboard?${encodedToken}`;
            // window.location.href = `http://localhost:3001/dashboard?${encodedToken}`;

            setTimeout(() => {
              window.location.reload();
            }, 1000);
            break;

          case service.DISTRIBUTOR:
            headers = {
              'token': result.token
            }
            encodedToken = new URLSearchParams(headers).toString();
            // window.location.href = `https://tsp.softmintdigital.c
            window.location.href = `https://tsp.softmintdigital.com/dashboard?${encodedToken}`;
            // window.location.href = `http://localhost:3001/dashboard?${encodedToken}`;

            setTimeout(() => {
              window.location.reload();
            }, 1000);
            break;
          case service.RETAILER:
            navigate('/retailerservice');

            setTimeout(() => {
              window.location.reload();
            }, 1000);

            break;
          case service.EMPLOYEE:
            navigate('/employeedashboard');
            break;
          default:

        }
      }, 2000);
    } else if (result && result.status === service.OTP_SUCCESS) {
      showToast("SUCCESS", result.message);
      setLoading(false);
    } else {
      showToast("DANGER", result.message || "Unexpected error occurred");
      setLoading(false);
    }
  };


  return (
    <div id="main-wrapper" className="oxyy-login-register">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-md-8">
            <Carousel style={{ padding: "8%" }}>

              <Carousel.Item interval={3000}>

                <img
                  alt="carousel"
                  src="/image/IMG_3778.JPG"
                  className="login-bg"
                />

              </Carousel.Item>
              <Carousel.Item interval={3000}>

                <img
                  alt="carousel"
                  src="/image/4Y8A2976.jpg"
                  className="login-bg"
                // style={{ backgroundSize: 'cover', width: '100%' }}
                />
              </Carousel.Item>
            </Carousel>

          </div>

          <div className="col-md-4 col-lg-5 col-xl-4 flex-column bg-light shadow-lg order-2 order-md-2">
            <div
              className="container my-auto"
              id={showForgotPass ? "forgotpassdiv" : "logindiv"}
            >
              <div className="row">
                {!enableOtpForm ? <div className="col-11 col-md-10 mx-auto text-center" style={{ marginTop: "40%" }}>

                  <div className="logo mb-4">
                    <Link to="../" title="Softmint">
                      <img
                        src="../image/softmintlogo.png"
                        alt="Softmint"
                        style={{ width: "17rem" }}
                      />
                    </Link>
                  </div>
                  {showForgotPass ? (
                    <div className="form-border">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          autoFocus
                          tabIndex="1"
                          maxLength="10"
                          id="mobno"
                          autoComplete="off"
                          placeholder="Mobile Number"
                          onChange={(e) => setUsername(e.target.value)}
                        />

                      </div>
                      {!showSecondOtpField && (
                        <button
                          className="btn btn-primary btn-block text-uppercase my-4 resetpass-dt"
                          id="resetpass"
                          autoComplete="off"
                          tabIndex="2"
                          style={{ height: "40px", fontStyle: "normal" }}
                          onClick={handleResetPassword}
                        >
                          Reset Password
                        </button>
                      )}


                      {showSecondOtpField && (
                        <div id="secondOtpDiv">
                          <div className="form-group">
                            <input
                              type="password"
                              tabIndex="4"
                              id="secondOtp"
                              placeholder="Enter OTP"
                              className="form-control"
                              autoComplete="off"
                              value={secondOtp}
                              onChange={(e) => setSecondOtp(e.target.value)}
                            />
                          </div>
                          <input type="hidden" id="hasSecondOtp" />

                          <div className="text-center">
                            <button className="btn btn-primary btn-block text-uppercase my-4 resetpass-dt"
                              style={{ height: "40px", fontStyle: "normal" }}
                              onClick={handleSecondOtpLogin}>
                              SUBMIT
                            </button>
                          </div>
                        </div>

                      )}

                      <p
                        className="text-2"
                        style={{ color: "black", fontWeight: "600" }}
                        xxx
                      >
                        Login Again?{" "}
                        <button
                          className="btn-link"
                          onClick={handleLoginAgain}
                          style={{
                            border: "none",
                            background: "none",
                            fontSize: "15px",
                            fontWeight: "550",
                          }}
                        >
                          Click here
                        </button>
                      </p>
                    </div>
                  ) : (
                    <div className="form-border">
                      <div id="udiv">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            autoFocus
                            tabIndex="1"
                            maxLength="10"
                            id="uname"
                            autoComplete="off"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            tabIndex="2"
                            id="upass"
                            className="form-control"
                            maxLength="25"
                            autoComplete="off"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>

                      {/* {hasOtp && (
                        <div id="otpdiv">
                          <div className="form-group">
                            <input
                              type="password"
                              tabIndex="3"
                              id="otp"
                              placeholder="Enter OTP"
                              className="form-control"
                              autoComplete="off"
                              onKeyDown={handleOtpKeyPress}
                            />
                          </div>
                          <input type="hidden" id="hasotp" />
                        </div>
                      )} */}
                      <button
                        className="btn btn-primary btn-block text-uppercase my-4 submit-dt"
                        id="verifydt"
                        tabIndex="2"
                        onClick={handleLoginApi}
                        style={{ height: "40px", fontStyle: "normal", width: "100%" }}
                        disabled={buttonDisabled}
                      >
                        {loading ? <Loader /> : "Login"}
                      </button>
                      <p
                        className="text-2"
                        style={{ color: "black", fontWeight: "600" }}
                      >
                        Forgot Password?{" "}
                        <button
                          className="btn-link"
                          onClick={handleForgotPass}
                          style={{
                            border: "none",
                            background: "none",
                            fontSize: "15px",
                            fontWeight: "550",
                          }}
                        >
                          Click here
                        </button>
                      </p>
                    </div>
                  )}
                  <ToastContainer />

                </div> : <div className="col-11 col-md-10 mx-auto text-center" style={{ marginTop: "40%" }}>
                  <div className="logo mb-4">
                    <Link to="../" title="Softmint">
                      <img
                        src="../image/softmintlogo.png"
                        alt="Softmint"
                        style={{ width: "17rem" }}
                      />
                    </Link>
                  </div>
                  {showForgotPass ? (
                    <div className="form-border">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          autoFocus
                          tabIndex="1"
                          maxLength="10"
                          id="mobno"
                          autoComplete="off"
                          placeholder="Mobile Number"
                        />
                      </div>
                      <button
                        className="btn btn-primary text-uppercase my-4 resetpass-dt"
                        id="resetpass"
                        autoComplete="off"
                        tabIndex="2"
                        style={{ height: "50px", fontStyle: "normal" }}
                      >
                        Reset Password
                      </button>
                      <p
                        className="text-2"
                        style={{ color: "black", fontWeight: "600" }}
                        xxx
                      >
                        Login Again?{" "}
                        <button
                          className="btn-link"
                          onClick={handleLoginAgain}
                          style={{
                            border: "none",
                            background: "none",
                            fontSize: "15px",
                            fontWeight: "550",
                          }}
                        >
                          Click here
                        </button>
                      </p>
                    </div>
                  ) : (
                    <div className="form-border">
                      <div id="udiv">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            autoFocus
                            tabIndex="1"
                            maxLength="10"
                            id="uname"
                            autoComplete="off"
                            placeholder="Otp"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                          />
                        </div>

                      </div>

                      {hasOtp && (
                        <div id="otpdiv">
                          <div className="form-group">
                            <input
                              type="password"
                              tabIndex="3"
                              id="otp"
                              placeholder="Enter OTP"
                              className="form-control"
                              autoComplete="off"
                              onKeyDown={handleOtpKeyPress}
                            />
                          </div>
                          <input type="hidden" id="hasotp" />
                        </div>
                      )}
                      <button
                        className="btn btn-primary btn-block text-uppercase my-4 submit-dt"
                        id="verifydt"
                        tabIndex="2"
                        onClick={handleOtpLogin}
                        style={{ height: "40px", fontStyle: "normal", width: "100%" }}
                      >
                        {loading ? <Loader /> : "Login"}
                      </button>
                      <p
                        className="text-2"
                        style={{ color: "black", fontWeight: "600" }}
                      >
                        Forgot Password?{" "}
                        <button
                          className="btn-link"
                          onClick={handleForgotPass}
                          style={{
                            border: "none",
                            background: "none",
                            fontSize: "15px",
                            fontWeight: "550",
                          }}
                        >
                          Click here
                        </button>
                      </p>
                    </div>
                  )}
                  <ToastContainer />
                </div>}


              </div>
            </div>
            <div className="container py-2">
              <p className="text-2 text-muted text-center mb-0 copyright-text">
                © Copyright 2023 by Softmint
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* ... */}
    </div>
  );
}

export default Newloginpage;

import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import axios from 'axios';
import { useNavigate } from 'react-router';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function Editdeactivemem() {

    const location = useLocation();
    const [data, setData] = useState(location.state);
    console.log(data)
    const navigate = useNavigate();
    // const [userStatus, setUserStatus] = useState(data.status);
    
    const [customerType, setCustomerType] = useState('');
    const [businessName, setBusinessName] = useState('DEMO');
    const [gstNumber, setGstNumber] = useState('');
    const [firstName, setFirstName] = useState(data.name);
    // const [lastName, setLastName] = useState(data.lastName);
    const [dateOfBirth, setDateOfBirth] = useState(data.dateOfBirth);
    const [address, setAddress] = useState(data.address);
    const [village, setVillage] = useState('KOLLIDAM');
    const [pincode, setPincode] = useState(data.pinCode);
    const [district, setDistrict] = useState(data.district);
    const [state, setState] = useState(data.state);
    const [email, setEmail] = useState(data.email);
    const [mobile, setMobile] = useState(data.mobile);
    const [aadhaarNumber, setAadhaarNumber] = useState(data.aadhaar);
    const [panNumber, setPanNumber] = useState(data.pan);
    const [aadhaarFrontFile, setAadhaarFrontFile] = useState(null);
    const [aadhaarBackFile, setAadhaarBackFile] = useState(null);
    const [panFile, setPanFile] = useState(null);
    const [passportPhotoFile, setPassportPhotoFile] = useState(null);
    const [password, setPassword] = useState('12345678');
    const [pin, setPin] = useState('1234');
    const [selectedUpline, setSelectedUpline] = useState('1');
    const [selectedPackage, setSelectedPackage] = useState('5');
    const [rechargeWalletLock, setRechargeWalletLock] = useState('0.00');
    const [fundRequestLock, setFundRequestLock] = useState('0.00');
    const [moneyTransferWalletLock, setMoneyTransferWalletLock] = useState('0.00');
    const [packageLimit, setPackageLimit] = useState('2');
    const [customerStatus, setCustomerStatus] = useState('2');


    const handleCustomerTypeChange = (event) => {
        setCustomerType(event.target.value);
    };

    const handleBusinessNameChange = (event) => {
        setBusinessName(event.target.value);
    };

    const handleGstNumberChange = (event) => {
        setGstNumber(event.target.value);
    };


    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };



    const handleDateOfBirthChange = (event) => {
        setDateOfBirth(event.target.value);
    };

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };

    const handleVillageChange = (event) => {
        setVillage(event.target.value);
    };

    const handlePincodeChange = (event) => {
        setPincode(event.target.value);
    };


    const handleDistrictChange = (event) => {
        setDistrict(event.target.value);
    };

    const handleStateChange = (event) => {
        setState(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleMobileChange = (event) => {
        setMobile(event.target.value);
    };


    const handleAadhaarNumberChange = (event) => {
        setAadhaarNumber(event.target.value);
    };

    const handlePanNumberChange = (event) => {
        setPanNumber(event.target.value);
    };

    const handleAadhaarFrontFileChange = (event) => {
        setAadhaarFrontFile(event.target.files[0]);
    };

    const handleAadhaarBackFileChange = (event) => {
        setAadhaarBackFile(event.target.files[0]);
    };

    const handlePanFileChange = (event) => {
        setPanFile(event.target.files[0]);
    };

    const handlePassportPhotoFileChange = (event) => {
        setPassportPhotoFile(event.target.files[0]);
    };


    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handlePinChange = (event) => {
        setPin(event.target.value);
    };

    const handleUplineChange = (event) => {
        setSelectedUpline(event.target.value);
    };

    const handlePackageChange = (event) => {
        setSelectedPackage(event.target.value);
    };

    const handleRechargeWalletLockChange = (event) => {
        setRechargeWalletLock(event.target.value);
    };

    const handleFundRequestLockChange = (event) => {
        setFundRequestLock(event.target.value);
    };

    const handleMoneyTransferWalletLockChange = (event) => {
        setMoneyTransferWalletLock(event.target.value);
    };

    const handlePackageLimitChange = (event) => {
        setPackageLimit(event.target.value);
    };



    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropDownRef = useRef();

    const handleOptionSelect = (option) => {
        setSelectedOption(option.mobile);
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    };


    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);



    const [upline, setUpline] = useState([]);

    let memoizedData;

    // const fetchData = async () => {
    //     if (memoizedData) {
    //         return memoizedData;
    //     }

    //     var token = '';
    //     if (sessionStorage && sessionStorage.token) {
    //         token = 'Bearer ' + sessionStorage.token;
    //     } else {
    //         navigate({
    //             pathname: "/"
    //         });
    //         sessionStorage.removeItem("token");
    //         sessionStorage.removeItem("user");
    //         return null;
    //     }

    //     try {
    //         var role = data.roleId - 1;
    //         const myHeaders = new Headers();
    //         myHeaders.append("Authorization", token);
    //         var requestOptions = {
    //             method: 'GET',
    //             headers: myHeaders,
    //             redirect: 'follow'
    //         };

    //         const response = await fetch("https://uat.softmintdigital.com/softmintadminsoftware/user/getallUser/" + role, requestOptions);
    //         const result = await response.json();
    //         console.log(result.List);
    //         setUpline(result.List);

    //         // Store the result in memoizedData
    //         memoizedData = result.List;
    //     } catch (error) {
    //         console.log('error', error);
    //         return null; // Return null or some other default value if an error occurs
    //     }
    // }

    const updateUser = async () => {
        if (memoizedData) {
            return memoizedData;
        }

        var token = '';
        if (sessionStorage && sessionStorage.token) {
            token = 'Bearer ' + sessionStorage.token;
        } else {
            navigate({
                pathname: "/"
            });
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("user");
            return null;
        }

        try {
            var role = data.roleId - 1;
            const myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                raw: data,
                redirect: 'follow'
            };

            const response = await fetch("https://m.softmintindia.com/user/getallUser?pageSize=1000" + role, requestOptions);
            const result = await response.json();
            console.log(result.List);
            setUpline(result.List);

            memoizedData = result.List;
        } catch (error) {
            console.log('error', error);
            return null; 
        }
    }



    function checkDec(inputElement) {
        const inputValue = inputElement.value;

        const decimalRegex = /^\d+(\.\d+)?$/;
        const isValidDecimal = decimalRegex.test(inputValue);

        if (isValidDecimal) {
            console.log("Valid decimal number");
        } else {
            console.log("Not a valid decimal number");
        }
    }


    const [newStatus, setNewStatus] = useState(data.status);

    useEffect(() => {
        document.getElementById('radioPrimary1').checked = newStatus === 1;
        document.getElementById('radioPrimary2').checked = newStatus === 0;
    }, [])

    // const handleStatusChange = async (e) => {

    //     const newStatus = e.target.value;

    //     var active = 0;
    //     if (e.target.value === '1') {
    //         active = 1;
    //     } else {
    //         active = 0;
    //     }

    //     var token = '';
    //     if (sessionStorage && sessionStorage.token) {
    //         token = 'Bearer ' + sessionStorage.token;
    //     } else {
    //         navigate({
    //             pathname: "/"
    //         });
    //         sessionStorage.removeItem("token");
    //         sessionStorage.removeItem("user");
    //         return null; // Return null or some other default value if the token is not available
    //     }


    //     try {
    //         const myHeaders = new Headers();
    //         myHeaders.append("Authorization", token);
    //         myHeaders.append("Content-Type", "application/json");

    //         var raw = JSON.stringify({
    //             "userId": data.userId,
    //             "status": newStatus
    //         });

    //         var requestOptions = {
    //             method: 'PATCH',
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: 'follow'
    //         };

    //         const response = await fetch("https://uat.softmintdigital.com/softmintadminsoftware/user/statusChange", requestOptions);
    //         const result = await response.json();
    //         console.log(result);

    //         setCustomerStatus(newStatus);


    //     } catch (error) {
    //         console.log('error', error);

    //     }
    // };

    return (
        <>
            <Navbar />
            <ToastContainer/>

            <div className="content-wrapper" style={{ minHeight: '238.667px' }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Edit Member</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Member Master</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/managemember">Manage Member</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Edit</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <form
                                    action="#"
                                    method="POST"
                                    encType="multipart/form-data"
                                >
                                    <p className="font-weight-bold text-primary m-0">
                                        Business Information
                                    </p>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="font-weight-normal">Customer Type <span className="text-danger"> *</span></label>
                                            <select
                                                className="form-control"
                                                name="customer_type"
                                                onChange={handleCustomerTypeChange}
                                                value={customerType}
                                                fdprocessedid="1sifzp"
                                            >
                                                <option value="">SELECT BUSINESS TYPE</option>
                                                <option value={0}>ADMIN</option>
                                                <option value={1}>API USER</option>
                                                <option value={2}>MASTER ADMIN</option>
                                                <option value={3}>MASTER DISTRIBUTOR</option>
                                                <option value={4}>DISTRIBUTOR</option>
                                                <option value={5}>RETAILER</option>
                                                <option value={6}>CUSTOMER</option>
                                                <option value={7}>EMPLOYEE</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="font-weight-normal">
                                                Business Name <span className="text-danger"> *</span>
                                                <small className="text-primary">(Mandatory for business account.)</small>
                                            </label>
                                            <input
                                                onInput={handleBusinessNameChange}
                                                name="business_name"
                                                type="text"
                                                className="form-control"
                                                value={businessName}
                                                required
                                                placeholder="ENTER BUSINESS NAME"
                                                fdprocessedid="yesi2"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="font-weight-normal">
                                                GST Number (If Available)
                                                <small className="text-primary">(Mandatory for GST invoice.)</small>
                                            </label>
                                            <input
                                                name="gst_number"
                                                type="text"
                                                maxLength="15"
                                                onInput={handleGstNumberChange}
                                                className="form-control"
                                                value={gstNumber}
                                                placeholder="Enter gst number"
                                                fdprocessedid="kn5v2"
                                            />
                                        </div>
                                    </div>
                                    <hr />

                                    <p className="font-weight-bold text-primary m-0">Personal Information</p>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="font-weight-normal">
                                             Name <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                onInput={handleFirstNameChange}
                                                name="first_name"
                                                type="text"
                                                className="form-control"
                                                value={firstName}
                                                required
                                                placeholder="ENTER FIRST NAME"
                                                contentEditable={true}
                                            />
                                        </div>
                                        {/* <div className="col-md-4">
                                            <label className="font-weight-normal">
                                                Last Name <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                onInput={handleLastNameChange}
                                                name="last_name"
                                                type="text"
                                                className="form-control"
                                                value={lastName}
                                                required
                                                placeholder="ENTER LAST NAME"
                                                fdprocessedid="u70hee"
                                            />
                                        </div> */}
                                        <div className="col-md-4">
                                            <label className="font-weight-normal">
                                                Date of Birth <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                name="date_of_birth"
                                                type="date"
                                                className="form-control"
                                                value={dateOfBirth}
                                                onChange={handleDateOfBirthChange}
                                            />
                                        </div>

                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">Details Address <span className="text-danger"> *</span></label>
                                            <input
                                                onInput={handleAddressChange}
                                                name="address"
                                                type="text"
                                                className="form-control"
                                                value={address}
                                                required
                                                placeholder="ENTER ADDRESS"
                                            />
                                        </div>
                                        {/* <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">Village <span className="text-danger"> *</span></label>
                                            <input
                                                onInput={handleVillageChange}
                                                name="village"
                                                type="text"
                                                className="form-control"
                                                value='NA'
                                                required
                                                placeholder="ENTER VILLAGE"
                                                fdprocessedid="yzfnek"
                                            />
                                        </div> */}
                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">Pincode <span className="text-danger"> *</span></label>
                                            <input
                                                name="pincode"
                                                type="text"
                                                className="form-control"
                                                value={pincode}
                                                placeholder="ENTER PINCODE"
                                                required

                                                pattern="[0-9]{6}"
                                                onChange={handlePincodeChange}
                                            />
                                        </div>
                                        {/* <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">Taluka <span className="text-danger"> *</span></label>
                                            <input
                                                onMouseOut={handleTalukaChange}
                                                name="taluka"
                                                id="taluka"
                                                type="text"
                                                className="form-control"
                                                value='NA'
                                                placeholder="ENTER TALUKA/CITY"
                                                required
                                                fdprocessedid="2gmjdc"
                                            />
                                        </div> */}
                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">District <span className="text-danger"> *</span></label>
                                            <input
                                                onInput={handleDistrictChange}
                                                name="district"
                                                id="district"
                                                type="text"
                                                className="form-control"
                                                value={district}
                                                required
                                                placeholder="ENTER DISTRICT"
                                                fdprocessedid="vurdr"
                                            />
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">State <span className="text-danger"> *</span></label>
                                            <input
                                                onInput={handleStateChange}
                                                name="state"
                                                id="state"
                                                type="text"
                                                className="form-control"
                                                value={state}
                                                required
                                                placeholder="ENTER STATE"
                                                fdprocessedid="duf7y"
                                            />
                                        </div>
                                    </div>
                                    <hr />

                                    <p className="font-weight-bold text-primary m-0">Contact Information</p>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="font-weight-normal">
                                                Valid Email ID <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                name="email"
                                                type="email"
                                                className="form-control"
                                                value={email}
                                                required
                                                placeholder="Enter email"
                                                onChange={handleEmailChange}
                                                fdprocessedid="g1eauw"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="font-weight-normal">
                                                Valid Mobile <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                name="mobile"
                                                type="text"
                                                className="form-control"
                                                value={mobile}
                                                required
                                                placeholder="Enter mobile"
                                                maxLength="10"
                                                pattern="[0-9]{10}"
                                                onChange={handleMobileChange}
                                                fdprocessedid="yfquq"
                                            />
                                        </div>
                                    </div>
                                    <hr />

                                    <p className="font-weight-bold text-primary m-0">KYC Information</p>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="font-weight-normal">
                                                Aadhaar Number <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                name="aadhaar_number"
                                                type="text"
                                                className="form-control"
                                                value={aadhaarNumber}
                                                placeholder="Enter aadhaar number"
                                                maxLength="12"
                                                pattern="[0-9]{12}"
                                                onChange={handleAadhaarNumberChange}
                                                fdprocessedid="rbjysw"
                                            />
                                            <div className="row">
                                                <div className="col-md-6 mt-2">
                                                    <label className="font-weight-normal" htmlFor="aadhaarFrontFile">
                                                        Front Site<span className="text-danger"> *</span>{' '}
                                                    </label>
                                                    <div className="custom-file">
                                                        <input
                                                            name="aadhaar_front_file"
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="aadhaarFrontFile"
                                                            placeholder="Select File"
                                                            onChange={handleAadhaarFrontFileChange}
                                                        />
                                                        <label className="custom-file-label" htmlFor="aadhaarFrontFile">
                                                            Choose file
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-2">
                                                    <label className="font-weight-normal" htmlFor="aadhaarBackFile">
                                                        Back Side <span className="text-danger"> *</span>
                                                    </label>
                                                    <div className="custom-file">
                                                        <input
                                                            name="aadhaar_back_file"
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="aadhaarBackFile"
                                                            placeholder="Select File"
                                                            onChange={handleAadhaarBackFileChange}
                                                        />
                                                        <label className="custom-file-label" htmlFor="aadhaarBackFile">
                                                            Choose file
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="font-weight-normal">
                                                Pan Number <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                name="pan_number"
                                                type="text"
                                                className="form-control"
                                                value={panNumber}
                                                placeholder="Enter pan number"
                                                onChange={handlePanNumberChange}
                                                maxLength="10"
                                                size="10"
                                                fdprocessedid="i8gna5"
                                            />
                                            <div className="row">
                                                <div className="col-md-6 mt-2">
                                                    <label className="font-weight-normal" htmlFor="panFile">
                                                        PAN File <span className="text-danger"> *</span>
                                                        <small className="text-primary">(PDF, JPG)</small>
                                                    </label>
                                                    <div className="custom-file">
                                                        <input
                                                            name="pan_file"
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="panFile"
                                                            placeholder="Select File"
                                                            onChange={handlePanFileChange}
                                                        />
                                                        <label className="custom-file-label" htmlFor="panFile">
                                                            Choose file
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-2">
                                                    <label className="font-weight-normal" htmlFor="passportPhotoFile">
                                                        Passport Size Photo <span className="text-danger"> *</span>
                                                        <small className="text-primary">(PNG, JPG)</small>
                                                    </label>
                                                    <div className="custom-file">
                                                        <input
                                                            name="photo_file"
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="passportPhotoFile"
                                                            placeholder="Select File"
                                                            onChange={handlePassportPhotoFileChange}
                                                        />
                                                        <label className="custom-file-label" htmlFor="passportPhotoFile">
                                                            Choose file
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />

                                    <p className="font-weight-bold text-primary m-0">Account Information</p>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="font-weight-normal">
                                                Password <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                disabled
                                                name="password"
                                                type="password"
                                                className="form-control"
                                                value={password}
                                                placeholder="Enter secure password"
                                                minLength="8"
                                                onChange={handlePasswordChange}
                                                fdprocessedid="umq5ke"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="font-weight-normal">
                                                Login Access Pin <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                disabled
                                                name="pin"
                                                type="password"
                                                className="form-control"
                                                value={pin}
                                                required
                                                placeholder="Enter secure 4 digit pin"
                                                maxLength="4"
                                                pattern="[0-9]{4}"
                                                onChange={handlePinChange}
                                                fdprocessedid="mb251"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="font-weight-normal">
                                                Upline <span className="text-danger"> *</span>
                                            </label>
                                            <select
                                                className="form-control select2 select2-hidden-accessible"
                                                style={{ width: '100%' }}
                                                name="upline_id"
                                                id="upline_id"
                                                required
                                                onChange={handleUplineChange}
                                                data-select2-id="upline_id"
                                                tabIndex="-1"
                                                aria-hidden="true"
                                            >
                                                <option value="">Select Upline</option>
                                                <option value="1" selected={selectedUpline === '1'} data-select2-id="2">
                                                    Sharad Bandarkar - MASTER ADMIN
                                                </option>

                                            </select>

                                            <div className="col-md-4">

                                                <select name="customer_id" className="form-control select2 select2-hidden-accessible" id="customer_list" tabindex="-1" aria-hidden="true" data-select2-id="customer_list">
                                                    <option value="" data-select2-id="4">Select Upline</option>
                                                </select>
                                                <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="3" style={{ width: '228.5px' }}>

                                                    <div
                                                        ref={dropDownRef}
                                                        className={`select2 select2-container select2-container--default ${isDropdownOpen ? 'select2-container--open' : ''
                                                            }`}
                                                        dir="ltr"
                                                        data-select2-id="2"
                                                        style={{ width: '300.625px' }}
                                                        onClick={toggleDropdown}
                                                    >
                                                        <span className="selection">
                                                            <span
                                                                className={`select2-selection select2-selection--single ${isDropdownOpen ? 'select2-selection--active' : ''
                                                                    }`}

                                                                role="combobox"
                                                                aria-haspopup="true"
                                                                aria-expanded={isDropdownOpen}
                                                                tabIndex="0"
                                                                aria-disabled="false"
                                                                aria-labelledby="select2-package_id-24-container"
                                                            >
                                                                <span
                                                                    className="select2-selection__rendered"
                                                                    id="select2-package_id-24-container"
                                                                    role="textbox"
                                                                    aria-readonly="true"
                                                                    title={selectedOption ? data.find((option) => option.mobile === selectedOption).mobile : 'Select Upline'}
                                                                >
                                                                    {selectedOption ? data.find((option) => option.mobile === selectedOption).mobile : 'Select Upline'}
                                                                </span>

                                                                <span className="select2-selection__arrow" role="presentation">
                                                                    <b role="presentation"></b>
                                                                </span>
                                                            </span>
                                                        </span>

                                                        {isDropdownOpen && (
                                                            <span className="dropdown-wrapper" style={{ position: 'absolute' }}>
                                                                <span className="select2-dropdown select2-dropdown--below" dir="ltr" style={{ width: '300.625px' }}>
                                                                    <span className="select2-search select2-search--dropdown">
                                                                        <input
                                                                            className="select2-search__field"
                                                                            type="search"
                                                                            tabIndex="0"
                                                                            autoComplete="off"
                                                                            autoCorrect="off"
                                                                            autoCapitalize="none"
                                                                            spellCheck="false"
                                                                            role="searchbox"
                                                                            aria-autocomplete="list"
                                                                            aria-controls="select2-package_id-ci-results"
                                                                        />
                                                                    </span>
                                                                    <span className="select2-results">
                                                                        <ul className="select2-results__options" role="listbox" id="select2-package_id-ci-results" aria-expanded="true" aria-hidden="false">
                                                                            {upline.map((option) => (
                                                                                <li
                                                                                    key={option.mobile}
                                                                                    className={`select2-results__option ${option.value === selectedOption ? 'select2-results__option--highlighted' : ''}`}
                                                                                    role="data"
                                                                                    aria-selected={option.mobile === selectedOption}
                                                                                    data-select2-id={`select2-package_id-f9-result-${option.value}`}
                                                                                    onClick={() => handleOptionSelect(option)}
                                                                                >
                                                                                    {option.name + '[' + option.userName + '][' + option.mobile + ']'}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        )}

                                                    </div>

                                                </span>
                                            </div>

                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">
                                                Package <span className="text-danger"> *</span>
                                            </label>
                                            <select
                                                className="form-control"
                                                name="package_id"
                                                id="package_id"
                                                onChange={handlePackageChange}
                                                fdprocessedid="5ml1l"
                                            >
                                                <option value="">Select Package</option>
                                                <option value="5" selected={selectedPackage === '5'}>
                                                    MASTER DISTRIBUTOR
                                                </option>
                                                {/* Add other options if needed */}
                                            </select>
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">
                                                Recharge Wallet Lock <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                name="recharge_wallet_lock"
                                                type="text"
                                                className="form-control"
                                                value={rechargeWalletLock}
                                                required
                                                placeholder="Enter recharge wallet lock"
                                                onKeyUp={(event) => checkDec(event.target)}
                                                onChange={handleRechargeWalletLockChange}
                                                fdprocessedid="4gv6k5"
                                            />
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">
                                                Fund Request Lock <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                name="fund_request_lock"
                                                type="text"
                                                className="form-control"
                                                value={fundRequestLock}
                                                required
                                                placeholder="Enter fund request lock"
                                                onKeyUp={(event) => checkDec(event.target)}
                                                onChange={handleFundRequestLockChange}
                                                fdprocessedid="ifgys"
                                            />
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">
                                                Money Transfer Wallet Lock (DMT) <span className="text-danger"> * </span>
                                            </label>
                                            <input
                                                name="money_transfer_wallet_lock"
                                                type="text"
                                                className="form-control"
                                                value={moneyTransferWalletLock}
                                                required
                                                placeholder="Enter money transfer lock"
                                                onKeyUp={(event) => checkDec(event.target)}
                                                onChange={handleMoneyTransferWalletLockChange}
                                                fdprocessedid="te3sa"
                                            />
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">
                                                Package Limit <span className="text-danger"> * </span>
                                            </label>
                                            <input
                                                name="package_limit"
                                                type="text"
                                                className="form-control"
                                                value={packageLimit}
                                                required
                                                placeholder="Enter package limit"
                                                onChange={handlePackageLimitChange}
                                                fdprocessedid="y8cnsb"
                                            />
                                        </div>
                                        <input type="hidden" name="securityToken" value="b2881aaea5de2f3b5adad322f0d8f858" />
                                    </div>
                                    <hr />

                                    <p className="font-weight-bold text-primary m-0">Account Services</p>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group clearfix">
                                                <label className="font-weight-normal">
                                                    Customer Status <span className="text-danger"> * </span>
                                                </label>&nbsp;
                                                <div className="icheck-primary d-inline">
                                                    <input
                                                        type="radio"
                                                        id="radioPrimary1"
                                                        name="status"
                                                        value={1}
                                                        // checked={customerStatus === '1'}
                                                        // onClick={handleStatusChange}
                                                    />
                                                    <label htmlFor="radioPrimary1">Active</label>
                                                </div>&nbsp;
                                                <div className="icheck-danger d-inline">
                                                    <input
                                                        type="radio"
                                                        id="radioPrimary2"
                                                        name="status"
                                                        value={0}
                                                        // checked={customerStatus === '2'}
                                                        // onClick={handleStatusChange}
                                                    />
                                                    <label htmlFor="radioPrimary2">Deactive </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />

                                    <div className="text-center">
                                        <button type="submit" className="btn btn-sm btn-primary" onClick={updateUser}>
                                            <i className="fa fa-save"></i> Update Member
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from "react";

const AepsRecords = ({ data, allRecord }) => {
  return (
    <table className="table table-bordered table-striped">
      <thead>
        <tr>
          <th>INDEX</th>
          <th>NAME</th>
          <th>MOBILE</th>
          <th>TERMINAL ID</th>
          <th>ADHAAR NO.</th>
          <th>AMOUNT</th>
          <th>BALANCE AMOUNT</th>
          <th>BANK NAME</th>
          <th>MARCHENT TXN ID</th>
          <th>RRN</th>
          <th>TYPE</th>
          <th>STATUS</th>
          <th>NARRATION</th>
          <th>DATE</th>
          <th>TIME</th>
        </tr>
      </thead>
      <tbody>
        {data !== undefined ? (
          data.map((option, indx) => (
            <tr>
              <td>{indx + 1}</td>
              <td>{option.name}</td>
              <td>{option.mobile}</td>
              <td>{option.terminalId}</td>
              <td>{option.aadhar}</td>
              <td className="font-weight-bold">{option.transactionAmount}</td>
              <td>{option.remainingbalance}</td>
              <td>{option.iin}</td>
              <td>{option.merchantTxnId}</td>
              <td>{option.bankRRN}</td>
              <td>{option.transactionType}</td>
              <td style={{ color: option.status === "SUCCESS" ? 'green' : (option.status === "PENDING" ? 'orange' : 'red'), fontWeight: "bold" }}>{option.status}</td>
              <td>{option.narration}</td>
              <td>{option.date}</td>
              <td>{option.time}</td>
            </tr>
          ))
        ) : (
          <td colSpan={9} align="center">
            NO DATA AVAILABLE
          </td>
        )}
      </tbody>
      <tfoot>
        <tr>
          <td
            colSpan="4"
            style={{ fontWeight: "bold" }}
            className="text-primary"
            rowSpan="1"
          >
            Row Count {allRecord.length}
          </td>
          <td
            className="text-right font-weight-bold text-success"
            rowSpan="1"
            colSpan="1"
          >
            ₹
            {allRecord.reduce(
              (accumulator, currentValue) =>
                accumulator +
                parseFloat(
                  currentValue.status === "SUCCESS"
                    ? currentValue.transactionAmount
                    : 0.0
                ),
              0
            )}
          </td>
          <td
            className="text-right font-weight-bold text-danger"
            rowSpan="1"
            colSpan="1"
          >
            ₹{0.0}
            {/* {earningReport.reduce(
                            (accumulator, currentValue) =>
                              accumulator + parseFloat(currentValue.charge),
                            0
                          )} */}
          </td>
          <td
            className="text-right font-weight-bold text-danger"
            rowSpan="1"
            colSpan="1"
          >
            ₹{0.0}
            {/* {earningReport.reduce(
                            (accumulator, currentValue) =>
                              accumulator + parseFloat(currentValue.tds),
                            0
                          )} */}
          </td>
          <td
            className="text-right font-weight-bold text-success"
            rowSpan="1"
            colSpan="1"
          >
            ₹{0.0}
            {/* {earningReport.reduce(
                            (accumulator, currentValue) =>
                              accumulator +
                              parseFloat(currentValue.finalAmount),
                            0
                          )} */}
          </td>
          <td colSpan="3" rowSpan="1"></td>
        </tr>
      </tfoot>
    </table>
  );
};

export default AepsRecords;

import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Editpackagemast() {

   

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '532.667px' }}>

                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">Package Master</h1>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Settings</Link></li>
                                    <li className="breadcrumb-item"><Link to="/packagemast">Package Master</Link></li>
                                    <li className="breadcrumb-item active">Edit Package</li>
                                </ol>
                            </div>

                        </div>

                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card col-5 m-auto">
                            <div className="card-body">
                                <form action="/editpackage" method="post" acceptCharset="utf-8">
                                    <input type="hidden" name="securityToken" value="d0d5035820edaf33c75ea6f0b08e77e8" />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label htmlFor="package_name">Package Name: <span className="text-danger"> *</span></label>
                                            <input
                                                type="text"
                                                name="package_name"
                                                value="DEFAULT"
                                                autoFocus
                                                maxLength="100"
                                                className="form-control"
                                                required
                                                id="package_name"
                                                placeholder="Enter Package Name"
                                                fdprocessedid="oymq8r"
                                            />
                                            <div id="err"></div>
                                        </div>
                                        <div className="col-6 mt-3">
                                            <label>Set Default</label>&nbsp;
                                            <input type="checkbox" name="set_default" value="1" />
                                        </div>
                                        <div className="col-6 mt-3">
                                            <label>Flat Package</label>&nbsp;
                                            <input type="checkbox" name="is_flat_package" value="1" />
                                        </div>
                                        <div className="text-center col-12 mt-3">
                                            <Link to="/packagemast" className="btn btn-primary btn-hover text-white" >
                                                Save Changes
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

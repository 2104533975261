import React from 'react';
import Navbar from './Navbar';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import swal from 'sweetalert';
import RestService from '../http';
import { Link } from 'react-router-dom';


export default function Addkyc() {

    const rest = new RestService()

    const [customerType, setCustomerType] = useState('');
    const [userType, setUserType] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [marchentName, setMarchentName] = useState('');
    const [gstNumber, setGstNumber] = useState('');
    const [referedBy, setReferedBy] = useState('');
    const [name, setName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [address, setAddress] = useState('');
    const [longitude, setLongitude] = useState('');
    const [latitude, setLatitude] = useState('');
    const [pin, setPin] = useState('');
    const [taluka, setTaluka] = useState('');
    const [district, setDistrict] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [gender, setGender] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [adhaarNumber, setAdhaarNumber] = useState('');
    const [aadhaarFrontFile, setAadhaarFrontFile] = useState(null);
    const [aadhaarBackFile, setAadhaarBackFile] = useState(null);
    const [panNumber, setPanNumber] = useState('');
    const [panFile, setPanFile] = useState(null);
    const [passBook, setPassBook] = useState(null);
    const [marchentImage, setMarchentImage] = useState(null);
    const [shopImage, setShopImage] = useState(null);
    const [marchentVideo, setMarchentVideo] = useState(null);
    const [distributorImage, setDistributorImage] = useState(null);
    const [dbShopPic, setDbShopPic] = useState(null);
    const [passportPhoto, setPassportPhoto] = useState(null);
    const [password, setPassword] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [uplineId, setUplineId] = useState('');
    const [packageId, setPackageId] = useState('');
    const [rechargeWalletLock, setRechargeWalletLock] = useState('0.00');
    const [fundRequestLock, setFundRequestLock] = useState('0.00');
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [isSessionExpired, setIsSessionExpired] = useState(false);


    const sessionExpiretionPopup = () => {
        if (isSessionExpired) {
            // Show the SweetAlert when the session has expired
            swal({
                title: 'Session Expired',
                text: 'Your login session has expired. Please log in again.',
                icon: 'warning',
                closeOnClickOutside: false,
                buttons: {
                    confirm: {
                        text: 'OK',
                        className: 'btn btn-primary',
                    },
                },
                dangerMode: true,
            }).then((confirmed) => {
                // You can add additional logic here when the user clicks the OK button
                // For example, redirect to the login page or perform other actions
                if (confirmed) {
                    // Redirect to the login page (this is just an example)
                    window.location.href = '/';
                    sessionStorage.removeItem("token");
                    sessionStorage.removeItem("user");
                }
            });
        }
    }
    useEffect(() => {
        sessionExpiretionPopup();
    }, [sessionExpiretionPopup]);

    const navigate = useNavigate();

    const handleCustomerType = (e) => {
        console.log(e.target.value);
        setCustomerType(e.target.value);
    }

    const handleCustomerTypeChange = () => {
        try {
            rest.get("user/roles", null).then(result => {
                if(result.status === rest.SUCCESS){
                    setRoles(result.role);
                }else{
                    // setIsSessionExpired(true);
                    setRoles([]);
                }
            }).catch(error => {
                console.log(error);
                setRoles([]);
            });
        } catch (error) {
            console.log('error', error);
            setRoles([]);
        }

    };

    useEffect(() => {
        handleCustomerTypeChange()
    }, [])



    const [kyc, setKyc] = useState('')
    const formdata = new FormData();
    formdata.append("adhaarFont", aadhaarFrontFile);

    const handleAddKyc = async () => {
        var token = '';
        if (sessionStorage && sessionStorage.token) {
            token = 'Bearer ' + sessionStorage.token;
        } else {
            navigate({
                pathname: "/"
            });
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("user");
            return null; // Return null or some other default value if the token is not available
        }

        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", token);

            var formdata = new FormData();

            formdata.append("marchentMobile", mobile);
            formdata.append("marchentName", marchentName);
            formdata.append("marchentAdhaar", adhaarNumber);
            formdata.append("marchentPan", panNumber);
            formdata.append("marchentAddress", address);
            formdata.append("marchentCity", city);
            formdata.append("marchentPincode", pinCode);
            formdata.append("marchentState", state);
            formdata.append("marchentEmailId", email);
            formdata.append("latitude", latitude);
            formdata.append("longitude", longitude);
            formdata.append("gender", gender);
            formdata.append("dob", dateOfBirth);
            formdata.append("adhaarFont", aadhaarFrontFile[0]);
            formdata.append("adhaarBack", aadhaarBackFile[0]);
            formdata.append("panCard", panFile[0]);
            formdata.append("passbook", passBook[0]);
            formdata.append("marchentimage", marchentImage[0]);
            formdata.append("shopImage", shopImage[0]);
            formdata.append("marchentVideo", marchentVideo[0]);
            formdata.append("distributorImage", distributorImage[0]);
            formdata.append("marchentDbShopPic", dbShopPic[0]);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            const response = await fetch("https://uat.softmintdigital.com/softmintadminsoftware/user/addKyc", requestOptions);
            const result = await response.json();
            console.log(result);



        } catch (error) {
            console.log("Error uploading:", error);
        }
    }

    const handleUserTypeChange = (e) => {
        setUserType(e.target.value);
    }

    const handleBusinessNameChange = (event) => {
        setBusinessName(event.target.value.toUpperCase());
    };


    const handleReferedByChange = (event) => {
        setReferedBy(event.target.value);
    };


    const handleMarchentName = (e) => {
        console.log(e.target.value);
        setMarchentName(e.target.value);
    }

    const handleDateOfBirthChange = (event) => {
        console.log(event.target.value);
        setDateOfBirth(event.target.value);
    };

    const handleAddressChange = (event) => {
        console.log(event.target.value);
        setAddress(event.target.value);
    };

    const handleLongitude = (event) => {
        console.log(event.target.value);
        setLongitude(event.target.value);
    };

    const handleLatitude = (event) => {
        console.log(event.target.value);
        setLatitude(event.target.value);
    };

    const handlePinCodeChange = (event) => {
        setPinCode(event.target.value);
    };

    const handleTalukaChange = (event) => {
        setTaluka(event.target.value.toUpperCase());
    };

    const handleDistrictChange = (event) => {
        setDistrict(event.target.value.toUpperCase());
    };

    const handleStateChange = (event) => {
        setState(event.target.value.toUpperCase());
    };

    const handleCity = (event) => {
        setCity(event.target.value);
    };

    const handleGender = (event) => {
        setGender(event.target.value);
    };

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleMobileChange = (event) => {
        const regex = /^[0-9]{0,10}$/; // Restrict input to maximum 10 digits
        if (regex.test(event.target.value)) {
            setMobile(event.target.value);
        }
    };

    const handleAdhaarNumber = (event) => {
        const regex = /^[0-9]{0,12}$/; // Restrict input to maximum 12 digits
        if (regex.test(event.target.value)) {
            setAdhaarNumber(event.target.value);
        }
    };

    // const handleAadhaarFrontFileChange = (event) => {
    //     const file = event.target.files[0];
    //     setAadhaarFrontFile(file);
    // };

    // const handleAadhaarBackFileChange = (event) => {
    //     const file = event.target.files[0];
    //     setAadhaarBackFile(file);
    // };

    const handlePanNumberChange = (event) => {
        const regex = /^[A-Z0-9]{0,10}$/; // Restrict input to maximum 10 alphanumeric characters
        if (regex.test(event.target.value)) {
            setPanNumber(event.target.files);
        }
    };

    // const handlePanFileChange = (event) => {
    //     const file = event.target.files[0];
    //     setPanFile(file);
    // };

    // const handlePassBook = (event) => {
    //     const file = event.target.files[0];
    //     setPassBook(file);
    // };

    // const handleMarchentImage = (event) => {
    //     const file = event.target.files[0];
    //     setMarchentImage(file);
    // };

    // const handleShopImage = (event) => {
    //     const file = event.target.files[0];
    //     setShopImage(file);
    // };

    // const handleMarchentVideo = (event) => {
    //     const file = event.target.files[0];
    //     setMarchentVideo(file);
    // };

    // const handleDistributorImage = (event) => {
    //     const file = event.target.files[0];
    //     setDistributorImage(file);
    // };

    // const handleDbShopPic = (event) => {
    //     const file = event.target.files[0];
    //     setDbShopPic(file);
    // };

    // const handlePassportPhotoChange = (event) => {
    //     const file = event.target.files[0];
    //     setPassportPhoto(file);
    // };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handlePinChange = (event) => {
        const regex = /^[0-9]{0,4}$/; // Restrict input to maximum 4 digits
        if (regex.test(event.target.value)) {
            setPin(event.target.value);
        }
    };

    const handleUplineChange = (event) => {
        setUplineId(event.target.value);
    };

    const handlePackageChange = (event) => {
        setPackageId(event.target.value);
    };

    const handleRechargeWalletLockChange = (event) => {
        setRechargeWalletLock(event.target.value);
    };

    const handleFundRequestLockChange = (event) => {
        setFundRequestLock(event.target.value);
    };


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "238.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">New KYC Registration</h1>
                            </div>
                            {/* ... */}
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <form>
                                    {/* Business Information */}
                                    <p className="font-weight-bold text-primary m-0">Business Information</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="font-weight-normal" >Select User Type<span className="text-danger"> *</span></label>
                                            <select
                                                className="form-control"
                                                name="customer_type"
                                                id="customer_type"
                                                required
                                                onChange={handleCustomerType}
                                            >
                                                <option value="">SELECT USER TYPE</option>
                                                {roles.map((role) => (
                                                    <option key={role.roleId} value={role.roleId}>
                                                        {role.roleName}
                                                    </option>
                                                ))}

                                            </select>
                                        </div>

                                        <div className="col-md-6">
                                            <label className="font-weight-normal">Shop Name <small className="text-primary">(Mandatory for business account.)</small></label>
                                            <input
                                                onChange={handleBusinessNameChange}
                                                name="business_name"
                                                type="text"
                                                className="form-control"
                                                value={businessName}
                                                placeholder="ENTER BUSINESS NAME"
                                            />
                                        </div>

                                        <div className="col-md-6" style={{ marginTop: '10px' }}>
                                            <label className="font-weight-normal">Select User <span className="text-danger"> *</span></label>
                                            <select
                                                className="form-control"
                                                name="customer_type"
                                                id="customer_type"
                                                required
                                                value={userType}
                                                onChange={handleUserTypeChange}
                                            >
                                                <option value="">SELECT USER</option>
                                                <option value="1">MASTER ADMIN</option>
                                                <option value="3">MASTER DISTRIBUTOR</option>
                                                {/* ... Other options */}
                                            </select>
                                        </div>


                                        <div className="col-md-6" style={{ marginTop: '10px' }}>
                                            <label className="font-weight-normal">Refered By <small className="text-primary">(Maximum Refer 50)</small></label>
                                            <select
                                                onChange={handleReferedByChange}
                                                name="refered_by"
                                                className="form-control select2 "
                                                id="referred_customer"
                                                tabIndex="-1"
                                                aria-hidden="true"
                                                value={referedBy}
                                            >
                                                <option value="">Select Refered By</option>
                                                <option value="1">AJOY MONDAL [MD1003][9609236025]</option>
                                                <option value="1">NAVNATH SASE [RT1001][8600707581]</option>
                                                <option value="1">Sharad Bandarkar [MA1000][8600436163]</option>
                                            </select>
                                        </div>

                                    </div>
                                    <hr />
                                    {/* Personal Information */}
                                    <p className="font-weight-bold text-primary m-0">Personal Information</p>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="font-weight-normal"> Name <span className="text-danger"> *</span></label>
                                            <input
                                                name="first_name"
                                                onChange={handleMarchentName}
                                                type="text"
                                                className="form-control"
                                                value={marchentName}
                                                required
                                                placeholder="Enter first name"
                                            />
                                        </div>



                                        <div className="col-md-4">
                                            <label className="font-weight-normal">Date of Birth <span className="text-danger"> *</span></label>
                                            <input
                                                onChange={handleDateOfBirthChange}
                                                name="date_of_birth"
                                                type="date"
                                                className="form-control"
                                                value={dateOfBirth}
                                                required
                                            />
                                        </div>

                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">Address <span className="text-danger"> *</span></label>
                                            <input
                                                onChange={handleAddressChange}
                                                name="address"
                                                type="text"
                                                maxLength="35"
                                                className="form-control"
                                                value={address}
                                                required
                                                placeholder="Enter address"
                                            />
                                        </div>

                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">Longitude <span className="text-danger"> *</span></label>
                                            <input
                                                onChange={handleLongitude}
                                                name="Longitude"
                                                type="text"
                                                maxLength="25"
                                                className="form-control"
                                                value={longitude}
                                                required
                                                placeholder="Longitude"
                                            />
                                        </div>

                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">Latitude <span className="text-danger"> *</span></label>
                                            <input
                                                onChange={handleLatitude}
                                                name="Latitude"
                                                type="text"
                                                maxLength="25"
                                                className="form-control"
                                                value={latitude}
                                                required
                                                placeholder="Latitude"
                                            />
                                        </div>

                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">Pincode <span className="text-danger"> *</span></label>
                                            <input
                                                onChange={handlePinCodeChange}
                                                name="pincode"
                                                type="text"
                                                className="form-control"
                                                value={pinCode}
                                                placeholder="Enter pincode"
                                                required

                                            />
                                        </div>

                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">Taluka <span className="text-danger"> *</span></label>
                                            <input
                                                onChange={handleTalukaChange}
                                                name="taluka"
                                                id="taluka"
                                                type="text"
                                                className="form-control"
                                                value={taluka}
                                                placeholder="Enter taluka"
                                                required
                                            />
                                        </div>

                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">District <span className="text-danger"> *</span></label>
                                            <input
                                                onChange={handleDistrictChange}
                                                name="district"
                                                type="text"
                                                className="form-control"
                                                value={district}
                                                required
                                                placeholder="Enter district"
                                            />
                                        </div>

                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">State <span className="text-danger"> *</span></label>
                                            <input
                                                onChange={handleStateChange}
                                                name="state"
                                                type="text"
                                                className="form-control"
                                                value={state}
                                                required
                                                placeholder="Enter state"
                                            />
                                        </div>

                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">City <span className="text-danger"> *</span></label>
                                            <input
                                                onChange={handleCity}
                                                name="city"
                                                type="text"
                                                className="form-control"
                                                value={city}
                                                required
                                                placeholder="Enter state"
                                            />
                                        </div>

                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">Gender <span className="text-danger"> *</span></label>
                                            <input
                                                onChange={handleGender}
                                                name="gender"
                                                type="text"
                                                className="form-control"
                                                value={gender}
                                                required
                                                placeholder="Enter Gender"
                                            />
                                        </div>

                                    </div>
                                    <hr />
                                    {/* Contact Information */}
                                    <p className="font-weight-bold text-primary m-0">Contact Information</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="font-weight-normal">Email <span className="text-danger"> *</span></label>
                                            <input
                                                onChange={handleEmail}
                                                name="email"
                                                type="email"
                                                className="form-control"
                                                value={email}
                                                required
                                                placeholder="Enter email"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="font-weight-normal">Mobile <span className="text-danger"> *</span></label>
                                            <input
                                                onChange={handleMobileChange}
                                                name="mobile"
                                                type="text"
                                                className="form-control"
                                                value={mobile}
                                                required
                                                placeholder="Enter mobile"
                                                maxLength="10"
                                            />
                                        </div>

                                    </div>
                                    <hr />
                                    {/* KYC Information */}
                                    <p className="font-weight-bold text-primary m-0">KYC Information <small>Image max size should be 500kb</small></p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="font-weight-normal">
                                                Aadhaar Number <span className="text-danger"> *</span>{' '}
                                                <small className="text-primary">
                                                    (Download PDF file Only){' '}
                                                    <Link to="https://eaadhaar.uidai.gov.in/" target="_blank" rel="noopener noreferrer">
                                                        <u>Download Link!</u>
                                                    </Link>
                                                </small>
                                            </label>
                                            <input
                                                onChange={handleAdhaarNumber}
                                                name="aadhaar_number"
                                                type="text"
                                                className="form-control"
                                                value={adhaarNumber}
                                                placeholder="Enter aadhaar number"
                                                maxLength="12"
                                            />
                                            {/* <div className="row">
                                                <div className="col-md-6 mt-2">
                                                    <label className="font-weight-normal" htmlFor="exampleInputFile">
                                                        Front Side<span className="text-danger"> *</span>{' '}
                                                    </label>
                                                    <div className="custom-file">
                                                        <input
                                                            onChange={handleAadhaarFrontFileChange}
                                                            name="aadhar_file"
                                                            type="file"
                                                            accept=".jpg, .jpeg, .png"
                                                            className="custom-file-input"
                                                            id="exampleInputFile"
                                                            value={''}
                                                        />

                                                        <label htmlFor="aadhar_file" className="custom-file-label">
                                                            {aadhaarFrontFile ? aadhaarFrontFile.name : "Choose a file"}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-2">
                                                    <label className="font-weight-normal" htmlFor="exampleInputFile4">
                                                        Back Side <span className="text-danger"> *</span>
                                                    </label>
                                                    <div className="custom-file">
                                                        <input
                                                            onChange={handleAadhaarBackFileChange}
                                                            name="aadhaar_back_file"
                                                            type="file"
                                                            accept=".jpg, .jpeg, .png"
                                                            className="custom-file-input"
                                                            id="exampleInputFile4"
                                                            value={''}
                                                        />
                                                        <label htmlFor="aadhar_file" className="custom-file-label">
                                                            {aadhaarBackFile ? aadhaarBackFile.name : "Choose a file"}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-2">
                                                    <label className="font-weight-normal" htmlFor="exampleInputFile2">
                                                        Passbook <span className="text-danger"> *</span>
                                                        <small className="text-primary">(PNG,JPG)</small>
                                                    </label>
                                                    <div className="custom-file">
                                                        <input
                                                            onChange={handlePassBook}
                                                            name="photo_file"
                                                            type="file"
                                                            value={''}
                                                            accept=".jpg, .png"
                                                            className="custom-file-input"
                                                            id="exampleInputFile2"
                                                        />
                                                        <label htmlFor="passbook_file" className="custom-file-label">
                                                            {passBook ? passBook.name : "Choose a file"}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-2">
                                                    <label className="font-weight-normal" htmlFor="exampleInputFile2">
                                                        Marchent Image <span className="text-danger"> *</span>
                                                        <small className="text-primary">(PNG,JPG)</small>
                                                    </label>
                                                    <div className="custom-file">
                                                        <input
                                                            onChange={handleMarchentImage}
                                                            name="photo_file"
                                                            type="file"
                                                            value={''}
                                                            accept=".jpg, .png"
                                                            className="custom-file-input"
                                                            id="exampleInputFile2"
                                                        />
                                                        <label htmlFor="marchentImage_file" className="custom-file-label">
                                                            {marchentImage ? marchentImage.name : "Choose a file"}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-2">
                                                    <label className="font-weight-normal" htmlFor="exampleInputFile2">
                                                        Distributor Image <span className="text-danger"> *</span>
                                                        <small className="text-primary">(PNG,JPG)</small>
                                                    </label>
                                                    <div className="custom-file">
                                                        <input
                                                            onChange={handleDistributorImage}
                                                            name="photo_file"
                                                            type="file"
                                                            value={''}
                                                            accept=".jpg, .png"
                                                            className="custom-file-input"
                                                            id="exampleInputFile2"
                                                        />
                                                        <label htmlFor="distributorImage_file" className="custom-file-label">
                                                            {distributorImage ? distributorImage.name : "Choose a file"}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-2">
                                                    <label className="font-weight-normal" htmlFor="exampleInputFile2">
                                                        DB Shop Image <span className="text-danger"> *</span>
                                                        <small className="text-primary">(PNG,JPG)</small>
                                                    </label>
                                                    <div className="custom-file">
                                                        <input
                                                            onChange={handleDbShopPic}
                                                            name="photo_file"
                                                            type="file"
                                                            value={''}
                                                            accept=".jpg, .jpeg, .png"
                                                            className="custom-file-input"
                                                            id="exampleInputFile2"
                                                        />
                                                        <label htmlFor="dbshoppic_file" className="custom-file-label">
                                                            {dbShopPic ? dbShopPic.name : "Choose a file"}
                                                        </label>
                                                    </div>
                                                </div>

                                            </div> */}

                                        </div>
                                        <div className="col-md-6">
                                            <label className="font-weight-normal">
                                                Pan Number <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                onChange={handlePanNumberChange}
                                                name="pan_number"
                                                type="text"
                                                className="form-control"
                                                value={panNumber}
                                                placeholder="Enter pan number"
                                                maxLength="10"
                                            />
                                            {/* <div className="row">
                                                <div className="col-md-6 mt-2">
                                                    <label className="font-weight-normal" htmlFor="exampleInputFile1">
                                                        PAN File <span className="text-danger"> *</span>
                                                        <small className="text-primary">(PDF,JPG)</small>
                                                    </label>
                                                    <div className="custom-file">
                                                        <input
                                                            onChange={handlePanFileChange}
                                                            name="pan_file"
                                                            type="file"
                                                            value={''}
                                                            accept=".jpg, .jpeg, .png, pdf"
                                                            className="custom-file-input"
                                                            id="exampleInputFile1"
                                                        />
                                                        <label htmlFor="pan_file" className="custom-file-label">
                                                            {panFile ? panFile.name : "Choose a file"}
                                                        </label>
                                                    </div>

                                                </div>

                                                <div className="col-md-6 mt-2">
                                                    <label className="font-weight-normal" htmlFor="exampleInputFile2">
                                                        Passport Size Photo <span className="text-danger"> *</span>
                                                        <small className="text-primary">(PNG,JPG)</small>
                                                    </label>
                                                    <div className="custom-file">
                                                        <input
                                                            onChange={handlePassportPhotoChange}
                                                            name="photo_file"
                                                            type="file"
                                                            value={''}
                                                            accept=".jpg, .jpeg, .png"
                                                            className="custom-file-input"
                                                            id="exampleInputFile2"
                                                        />
                                                        <label htmlFor="passport_file" className="custom-file-label">
                                                            {passportPhoto ? passportPhoto.name : "Choose a file"}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-2">
                                                    <label className="font-weight-normal" htmlFor="exampleInputFile1">
                                                        Shop Image <span className="text-danger"> *</span>
                                                        <small className="text-primary">(PDF,JPG)</small>
                                                    </label>
                                                    <div className="custom-file">
                                                        <input
                                                            onChange={handleShopImage}
                                                            name="pan_file"
                                                            type="file"
                                                            value={''}
                                                            accept=".jpg, pdf, .png"
                                                            className="custom-file-input"
                                                            id="exampleInputFile1"
                                                        />
                                                        <label htmlFor="shopImage_file" className="custom-file-label">
                                                            {shopImage ? shopImage.name : "Choose a file"}
                                                        </label>
                                                    </div>


                                                </div>

                                                <div className="col-md-6 mt-2">
                                                    <label className="font-weight-normal" htmlFor="exampleInputFile1">
                                                        Marchent Video <span className="text-danger"> *</span>
                                                        <small className="text-primary">(PDF,JPG)</small>
                                                    </label>
                                                    <div className="custom-file">
                                                        <input
                                                            onChange={handleMarchentVideo}
                                                            name="pan_file"
                                                            type="file"
                                                            value={''}
                                                            accept=".jpg, pdf, .png"
                                                            className="custom-file-input"
                                                            id="exampleInputFile1"
                                                        />
                                                        <label htmlFor="marchentVideo_file" className="custom-file-label">
                                                            {marchentVideo ? marchentVideo.name : "Choose a file"}
                                                        </label>
                                                    </div>

                                                </div>

                                            </div> */}

                                        </div>

                                    </div>
                                    <hr />
                                    {/* Account Information */}
                                    <p className="font-weight-bold text-primary m-0">Account Information</p>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="font-weight-normal">
                                                Password <span className="text-danger"> *</span>
                                                <small className="text-primary">(Minimum 8 Character length)</small>
                                            </label>
                                            <input
                                                onChange={handlePasswordChange}
                                                name="password"
                                                type="password"
                                                className="form-control"
                                                required
                                                placeholder="Enter secure password"
                                                minLength="8"
                                                value={password}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="font-weight-normal">
                                                Pin <span className="text-danger"> *</span>
                                                <small className="text-primary">(4 Character Number Only)</small>
                                            </label>
                                            <input
                                                onChange={handlePinChange}
                                                name="pin"
                                                type="password"
                                                maxLength="4"
                                                minLength="4"
                                                className="form-control"
                                                required
                                                placeholder="Enter secure 4 digit pin"
                                                value={pin}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="font-weight-normal">
                                                Upline <span className="text-danger"> *</span>
                                            </label>
                                            <select
                                                onChange={handleUplineChange}
                                                className="form-control select2 "
                                                name="upline_id"
                                                id="upline_id"
                                                required
                                                value={uplineId}
                                            >
                                                <option value="">Select Upline</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">
                                                Package <span className="text-danger"> *</span>
                                            </label>
                                            <select
                                                onChange={handlePackageChange}
                                                className="form-control"
                                                name="package_id"
                                                id="package_id"
                                                value={packageId}
                                            >
                                                <option value="">Select Package</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">
                                                Recharge Wallet Lock <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                onChange={handleRechargeWalletLockChange}
                                                name="recharge_wallet_lock"
                                                type="text"
                                                className="form-control"
                                                value={rechargeWalletLock}
                                                required
                                                placeholder="Enter recharge wallet lock"
                                            />
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">
                                                Fund Request Lock <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                onChange={handleFundRequestLockChange}
                                                name="fund_request_lock"
                                                type="text"
                                                className="form-control"
                                                value={fundRequestLock}
                                                required
                                                placeholder="Enter fund request lock"
                                            />
                                        </div>
                                        <input type="hidden" name="securityToken" value="5ff53b14089896e4190c46b2ad082571" />
                                    </div>
                                    <hr />
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-sm btn-primary" onClick={handleAddKyc}><i className="fa fa-save"></i> Add Kyc</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';


export default function Editcontact() {

    const handleSubmit = (event) => {
        event.preventDefault();
        // Add your form submission logic here
    };


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '528.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">Contact Settings</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Web Configurations</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Contact Settings</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="text-info" style={{ fontWeight: 'bold' }}>
                                    Contact: Set Contact
                                </h6>
                                <form
                                    action="/editcontact"
                                    method="post"
                                    encType="multipart/form-data"
                                    acceptCharset="utf-8"
                                    onSubmit={handleSubmit}
                                >
                                    <input
                                        type="hidden"
                                        name="securityToken"
                                        value="5ff53b14089896e4190c46b2ad082571"
                                    />
                                    <div className="row">
                                        <div className="col-sm-4 mt-2">
                                            <label className="font-weight-normal">
                                                Company Name: <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                type="text"
                                                value="STAR PAY"
                                                name="company_name"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <div className="col-sm-4 mt-2">
                                            <label className="font-weight-normal">
                                                Customer support: <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="customer_support"
                                                value="91922457845"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <div className="col-sm-4 mt-2">
                                            <label className="font-weight-normal">
                                                Sales support: <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="sales_support"
                                                value="91922457845"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <div className="col-sm-4 mt-2">
                                            <label className="font-weight-normal">
                                                WhatsApp support: <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="whatsapp_support"
                                                value="91922457845"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <div className="col-sm-4 mt-2">
                                            <label className="font-weight-normal">
                                                Support Email: <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                value="sof@star.pay"
                                                name="support_email"
                                                required
                                            />
                                        </div>
                                        <div className="col-sm-4 mt-2">
                                            <label className="font-weight-normal">
                                                Sales Email: <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                value="softmint@gmail.com"
                                                name="sales_email"
                                                required
                                            />
                                        </div>
                                        <div className="col-sm-4 mt-2">
                                            <label className="font-weight-normal">
                                                Address: <span className="text-danger"> *</span>
                                            </label>
                                            <textarea
                                                required
                                                name="address"
                                                id="address"
                                                className="form-control"
                                            >
                                                Ahmednagar, MH 414505
                                            </textarea>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="submit"
                                            className="btn btn-info m-2 btn-hover"
                                            value="Set"
                                        />
                                    </div>
                                </form>
                                <hr />
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from "react";
import { useState, useEffect } from "react";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import RestService from "../../http";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { ToastContainer } from "react-toastify";
import showToast from "./APPToast";


const ThirdstageKycReport = ({ showNextComponent, showPreviousComponent, agentId }) => {

    const rest = new RestService();
    const [distributor, setDistributor] = useState("");
    const [shopImage, setShopImage] = useState("");
    const [imagePrefix] = useState("data:image/png;base64,");
    const [remark, setRemark] = useState("");
    const [name, setName] = useState("");
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [kycStatus, setKycStatus] = useState('');
    const [placeHolder, setPlaceHolder] = useState('');
    const [buttonName, setButtonName] = useState('');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    useEffect(() => {
        var raw = {
            "agentid": agentId
        }

        console.log(raw);

        rest.post("completekyc/fetchdistributor", raw).then(result => {
            console.log(result);

            const kycDoc = result.KYCDISTRIBUTOR;

            let distributorImage = `${imagePrefix}${kycDoc.dismerpic}`;
            let distributorShopImage = `${imagePrefix}${kycDoc.shoppic}`;

            setDistributor(distributorImage);
            setShopImage(distributorShopImage);

        }).catch((error) => {
            console.log(error);
        })
    }, []);

    const handleApprove = () => {
        setOpen(true);
        setKycStatus("APPROVED");
        setPlaceHolder("Approver Name");
        setButtonName("APPROVE");
    }

    const handleReject = () => {
        setOpen(true);
        setKycStatus("REJECTED");
        setPlaceHolder("Rejecter Name");
        setButtonName("REJECTED");
    }

    const handleKycStatus = () => {

        var raw = {
            "agentId": agentId,
            "status": kycStatus,
            "stage": "2",
            "message": remark,
            "approvername": name
        }

        console.log(raw);

        rest.post("completekyc/changestatus", raw).then(result => {
            console.log(result);
            if (result.status === rest.SUCCESS) {
                showToast("SUCCESS", result.message);

            } else {
                showToast("DANGER", result.message);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <>
            <ToastContainer />

            <div className="card bg-light">
                <div className="row mt-2 ml-2 mr-2">
                    <div className="col-md-6">
                        <img className="border"
                            src={distributor}
                            style={{ width: "100%", height: "50%", borderRadius: "10px" }} />
                        <br />

                        <label htmlFor="service_id">
                            Merchant Image
                        </label>
                        <br />

                    </div>

                    <div className="col-md-6">
                        <img className="border"
                            src={shopImage}
                            style={{ width: "100%", height: "50%", borderRadius: "10px" }}
                        />
                        <br />

                        <label for="service_id" style={{ textAlign: "center" }}>
                            Shop Image
                        </label><br />

                    </div>
                </div>

                <div className="row" style={{ marginTop: "-28%" }}>
                    <div className="col-md-12 text-center">
                        <button className="btn" style={{ backgroundColor: "darkblue", color: "white" }} onClick={showPreviousComponent}><FaLongArrowAltLeft /> Previous</button>&nbsp;&nbsp;
                        <button className="btn" style={{ backgroundColor: "red", color: "white" }} onClick={handleReject}>Reject <RxCross2 /></button>&nbsp;&nbsp;
                        <button className="btn" style={{ backgroundColor: "green", color: "white" }} onClick={handleApprove}>Approve <FaLongArrowAltRight /></button>&nbsp;&nbsp;
                    </div><br /><br /><br />
                </div>

            </div>

            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style}>
                            <div className="row">
                                <div className="col-md-12">
                                    <label className="font-weight-normal">
                                        Remark: <span className="text-danger"> *</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="remark"
                                        className="form-control"
                                        placeholder='Add Remark'
                                        onChange={(e) => setRemark(e.target.value)}
                                    />
                                </div>

                                <div className="col-md-12 mt-2">
                                    <label className="font-weight-normal">
                                        {placeHolder}: <span className="text-danger"> *</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder='Approver Name'
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>

                                <div className="col-md-12 mt-3 text-right">
                                    <button className={`btn ${buttonName === 'APPROVE' ? 'bg-success' : 'bg-danger'}`} onClick={handleKycStatus}>
                                        {buttonName}
                                    </button>
                                </div>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    );

};

export default ThirdstageKycReport;

import React from "react";
import Navbar from "./Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import RestService from "../http";


export default function Editmember() {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(location.state);
  console.log(data);

  const service = new RestService();

  const [option, setOption] = useState({});
  const [upline, setUpline] = useState([]);
  const [firmName, setFirmName] = useState(data.firmName);
  const name = useState(data.name);
  const [address, setAddress] = useState(data.address);
  const [mobile, setMobile] = useState(data.mobile);
  const [city, setCity] = useState(data.city);
  const [pinCode, setPinCode] = useState(data.pinCode);
  const [state] = useState(data.state);
  const [country] = useState(data.country);
  const [email, setEmail] = useState(data.email);
  const status = useState(data.status);
  const [tranPin, setTranPin] = useState(data.tranPin);
  const [lockedAmount, setLockedAmount] = useState(data.lockedAmount);
  const [pan, setPan] = useState(data.pan);
  const [aadhaar, setAadhaar] = useState(data.aadhaar);
  const [district, setDistrict] = useState(data.district);

  let memoizedData;

  const fetchData = async () => {
    if (memoizedData) {
      return memoizedData;
    }

    var token = "";
    if (sessionStorage && sessionStorage.token) {
      token = "Bearer " + sessionStorage.token;
    } else {
      navigate({
        pathname: "/",
      });
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      return null;
    }

    try {
      var role = data.roleId - 1;

      const myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        // "https://uat.softmintdigital.com/softmintadminsoftware/user/getallUser/" +
        role,
        requestOptions
      );
      const result = await response.json();
      console.log(result.List);
      setUpline(result.List);

      memoizedData = result.List;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setOption(data);
  }, [setOption]);

  const [selectedOption, setSelectedOption] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropDownRef = useRef();

  const handleOptionSelect = (option) => {
    setSelectedOption(option.mobile);
    setIsDropdownOpen(false); // Close the dropdown after selecting an option
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [selectedOption]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);


  const fetchEditData = () => {

    var raw = {
      userId: data.userId,
      address: address,
      mobile: mobile,
      email: email,
      pan: pan,
      aadhaar: aadhaar,
    };

    service.post("user/editProfile", raw).then(result => {
      console.log(result);
      if (result.status === service.SUCCESS) {
        toast.success(result.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setTimeout(() => {
          window.location.href = '/member';
        }, 500);

      } else if (result.status === 0 || result.status === 2) {
        toast.error(result.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    })
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const handleName = (e) => {
  //   const { value } = e.target;
  //   setName(value);
  // };

  const handleDistrict = (e) => {
    const { value } = e.target;
    setDistrict(value);
  };

  const handleCity = (e) => {
    const { value } = e.target;
    setCity(value);
  };

  const handlePinCode = (e) => {
    const { value } = e.target;
    setPinCode(value);
  };

  const handleFirmName = (e) => {
    const { value } = e.target;
    setFirmName(value);
  };

  const handleAddress = (e) => {
    const { value } = e.target;
    setAddress(value);
  };

  const handleMobile = (e) => {
    const { value } = e.target;
    setMobile(value);
  };



  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
  };


  const handleTranPin = (e) => {
    const { value } = e.target;
    setTranPin(value);
  };

  const handleAadhaar = (e) => {
    const { value } = e.target;
    setAadhaar(value);
  };

  const handlePan = (e) => {
    const { value } = e.target;
    setPan(value);
  };

  const handleLockedAmount = (e) => {
    const { value } = e.target;
    setLockedAmount(value);
  }

  return (
    <>
      <Navbar />

      <ToastContainer />

      <div className="content-wrapper" style={{ minHeight: "203.667px" }}>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Edit Member</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Member Master</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/managemember">Manage Member</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div action="#" method="POST" encType="multipart/form-data">
                  <p className="font-weight-bold text-primary m-0">
                    Business Information
                  </p>
                  <div className="row">
                    <div className="col-md-4">
                      <label className="font-weight-normal">
                        Customer Type <span className="text-danger"> *</span>
                      </label>
                      <select
                        className="form-control"
                        name="customer_type"
                        fdprocessedid="rdyf8uk"
                      >
                        <option value="">SELECT BUSINESS TYPE</option>
                        <option value="3" selected="">
                          MASTER DISTRIBUTOR
                        </option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label className="font-weight-normal">
                        Business Name <span className="text-danger"> *</span>
                        <small className="text-primary">
                          (Mandatory for business account.)
                        </small>
                      </label>
                      <input
                        onInput={(e) => {
                          setData((prevData) => ({
                            ...prevData,
                            businessName: e.target.value,
                          }));
                        }}
                        name="business_name"
                        type="text"
                        className="form-control"
                        value="NA"
                        required=""
                        placeholder="ENTER BUSINESS NAME"
                        fdprocessedid="gc1pp"
                      />
                    </div>
                  </div>
                  <hr />
                  <p className="font-weight-bold text-primary m-0">
                    Personal Information
                  </p>
                  <div className="row">
                    <div className="col-md-4">
                      <label className="font-weight-normal">
                        Name <span className="text-danger"> *</span>
                      </label>
                      <input
                        // onChange={handleName}
                        name="first_name"
                        type="text"
                        className="form-control"
                        value={name}
                        required=""
                        placeholder="ENTER NAME"
                        fdprocessedid="prhwrd"
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="font-weight-normal">
                        Address <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleAddress}
                        name="address"
                        type="text"
                        className="form-control"
                        value={address}
                        required=""
                        placeholder="ENTER ADDRESS"
                        fdprocessedid="z5je8i"
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="font-weight-normal">
                        Mobile <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleMobile}
                        name="mobile"
                        type="text"
                        className="form-control"
                        value={mobile}
                        required=""
                        maxLength={10}
                        placeholder="ENTER MOBILE NO"
                        fdprocessedid="z5je8i"
                      />
                    </div>

                    <div className="col-md-4 ">
                      <label className="font-weight-normal">
                        Firm Name <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleFirmName}
                        name="Firm Name"
                        type="text"
                        className="form-control"
                        value={firmName}
                        required=""
                        placeholder="FIRM NAME"
                        fdprocessedid="n4v9e7"
                      />
                    </div>
                    <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        Pincode <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handlePinCode}
                        name="pincode"
                        type="text"
                        className="form-control"
                        value={pinCode}
                        placeholder="ENTER PINCODE"
                        required=""
                        pattern="[0-9]{6}"
                        fdprocessedid="ccwsf3"
                      />
                    </div>

                    <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        City <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleCity}
                        name="city"
                        type="text"
                        className="form-control"
                        value={city}
                        placeholder="ENTER CITY"
                        required=""
                      />
                    </div>

                    <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        District <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleDistrict}
                        name="district"
                        id="district"
                        type="text"
                        className="form-control"
                        value={district}
                        required=""
                        placeholder="ENTER DISTRICT"
                        fdprocessedid="ieubp"
                      />
                    </div>
                    <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        State <span className="text-danger"> *</span>
                      </label>
                      <input
                        // onChange={handleState}
                        name="state"
                        id="state"
                        type="text"
                        className="form-control"
                        value={state}
                        required=""
                        placeholder="ENTER STATE"
                        fdprocessedid="742tr"
                      />
                    </div>

                    <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        Country <span className="text-danger"> *</span>
                      </label>
                      <input
                        // onChange={handleCountry}
                        name="country"
                        id="state"
                        type="text"
                        className="form-control"
                        value={country}
                        required=""
                        placeholder="ENTER COUNTRY"
                        fdprocessedid="742tr"
                      />
                    </div>

                    <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        Status <span className="text-danger"> *</span>
                      </label>
                      <input
                        // onChange={handleStatus}
                        name="country"
                        id="status"
                        type="text"
                        className="form-control"
                        value={status}
                        fdprocessedid="742tr"
                      />
                    </div>

                  </div>
                  <hr />
                  <p className="font-weight-bold text-primary m-0">
                    Contact Information
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="font-weight-normal">
                        Email ID <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleEmail}
                        name="email"
                        type="email"
                        className="form-control"
                        value={email}
                        required=""
                        placeholder="Enter email"
                        fdprocessedid="vpsi7o"
                      />
                    </div>
                  </div>
                  <hr />
                  <p className="font-weight-bold text-primary m-0">
                    KYC Information
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="font-weight-normal">
                        Aadhaar Number <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleAadhaar}
                        name="aadhaar_number"
                        type="text"
                        className="form-control"
                        value={aadhaar}
                        placeholder="Enter aadhaar number"
                        maxLength="12"
                        pattern="[0-9]{12}"
                        fdprocessedid="srkb53"
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="font-weight-normal">
                        Pan Number <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handlePan}
                        name="pan_number"
                        type="text"
                        maxLength="10"
                        size="10"
                        className="form-control"
                        value={pan}
                        placeholder="Enter pan number"
                        fdprocessedid="yc79jn"
                      />
                    </div>
                  </div>
                  <hr />
                  <p className="font-weight-bold text-primary m-0">
                    Account Information
                  </p>
                  <div className="row">
                    <div className="col-md-4 ">
                      <label className="font-weight-normal">
                        Recharge Wallet Lock{" "}
                        <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleLockedAmount}
                        name="recharge_wallet_lock"
                        type="text"
                        className="form-control"
                        value={lockedAmount}
                        required
                        placeholder="Enter recharge wallet lock"
                        fdprocessedid="00yhcj"
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="font-weight-normal">
                        Transaction Pin <span className="text-danger"> *</span>
                      </label>
                      <input
                        name="pin"
                        type="password"
                        className="form-control"
                        value={tranPin}
                        required=""
                        placeholder="Enter secure 4 digit pin"
                        onChange={handleTranPin}
                        maxLength="4"
                        pattern="[0-9]{4}"
                        fdprocessedid="5ocwgd"
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Upline</label><br />
                      <select
                        name="customer_id"
                        className="form-control select2 select2-hidden-accessible"
                        id="customer_list"
                        tabindex="-1"
                        aria-hidden="true"
                        data-select2-id="customer_list"
                      >
                        <option value="" data-select2-id="4">
                          Select Upline
                        </option>
                      </select>
                      <span
                        className="select2 select2-container select2-container--default"
                        dir="ltr"
                        data-select2-id="3"
                        style={{ width: "228.5px" }}
                      >
                        <div
                          ref={dropDownRef}
                          className={`select2 select2-container select2-container--default ${isDropdownOpen ? "select2-container--open" : ""
                            }`}
                          dir="ltr"
                          data-select2-id="2"
                          style={{ width: "300.625px" }}
                          onClick={toggleDropdown}
                        >
                          <span className="selection">
                            <span
                              className={`select2-selection select2-selection--single ${isDropdownOpen
                                ? "select2-selection--active"
                                : ""
                                }`}
                              role="combobox"
                              aria-haspopup="true"
                              aria-expanded={isDropdownOpen}
                              tabIndex="0"
                              aria-disabled="false"
                              aria-labelledby="select2-package_id-24-container"
                            >
                              <span
                                className="select2-selection__rendered"
                                id="select2-package_id-24-container"
                                role="textbox"
                                aria-readonly="true"
                                title={
                                  selectedOption
                                    ? data.find(
                                      (option) =>
                                        option.mobile === selectedOption
                                    ).mobile
                                    : "Select Upline"
                                }
                              >
                                {selectedOption
                                  ? data.find(
                                    (option) =>
                                      option.mobile === selectedOption
                                  ).mobile
                                  : "Select Upline"}
                              </span>

                              <span
                                className="select2-selection__arrow"
                                role="presentation"
                              >
                                <b role="presentation"></b>
                              </span>
                            </span>
                          </span>

                          {isDropdownOpen && (
                            <span
                              className="dropdown-wrapper"
                              style={{ position: "absolute" }}
                            >
                              <span
                                className="select2-dropdown select2-dropdown--below"
                                dir="ltr"
                                style={{ width: "300.625px" }}
                              >
                                <span className="select2-search select2-search--dropdown">
                                  <input
                                    className="select2-search__field"
                                    type="search"
                                    tabIndex="0"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="none"
                                    spellCheck="false"
                                    role="searchbox"
                                    aria-autocomplete="list"
                                    aria-controls="select2-package_id-ci-results"
                                  />
                                </span>
                                <span className="select2-results">
                                  <ul
                                    className="select2-results__options"
                                    role="listbox"
                                    id="select2-package_id-ci-results"
                                    aria-expanded="true"
                                    aria-hidden="false"
                                  >
                                    {upline.map((option) => (
                                      <li
                                        key={option.mobile}
                                        className={`select2-results__option ${option.value === selectedOption
                                          ? "select2-results__option--highlighted"
                                          : ""
                                          }`}
                                        role="option"
                                        aria-selected={
                                          option.mobile === selectedOption
                                        }
                                        data-select2-id={`select2-package_id-f9-result-${option.value}`}
                                        onClick={() =>
                                          handleOptionSelect(option)
                                        }
                                      >
                                        {option.name +
                                          "[" +
                                          option.userName +
                                          "][" +
                                          option.mobile +
                                          "]"}
                                      </li>
                                    ))}
                                  </ul>
                                </span>
                              </span>
                            </span>
                          )}
                        </div>
                      </span>
                    </div>

                    {/* <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        Fund Request Lock{" "}
                        <span className="text-danger"> *</span>
                      </label>
                      <input
                        onInput={(e) => {
                          setData((prevData) => ({
                            ...prevData,
                            lockedAmount: e.target.value,
                          }));
                        }}
                        name="fund_request_lock"
                        type="text"
                        className="form-control"
                        value={data.lockedAmount}
                        required=""
                        placeholder="Enter fund request lock"
                        fdprocessedid="p51479"
                      />{" "}
                    </div>
                    <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        Money Transfer Wallet Lock (DMT){" "}
                        <span className="text-danger"> * </span>
                      </label>
                      <input
                        name="money_transfer_wallet_lock"
                        type="text"
                        className="form-control"
                        value="NA"
                        required=""
                        placeholder="Enter money transfer lock"
                        onKeyUp={(e) => checkDec(e.target)}
                        fdprocessedid="5fca38"
                      />
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3 text-center">
                      <button
                        className="btn btn-primary float-right"
                        onClick={fetchEditData}
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger float-right mr-3"
                      >
                        <Link to="/member" style={{ color: "white" }}>
                          Cancel
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <footer className="main-footer">
        <strong>
          Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
        </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 4.5.0
        </div>
      </footer>
    </>
  );
}

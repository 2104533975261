import React, { useEffect } from 'react';
import { CiBellOn } from 'react-icons/ci';
import { AiOutlineFolderOpen } from 'react-icons/ai';
import { MdLogout } from 'react-icons/md';
import { FaUserAlt, FaHome, FaUser, FaWeixin, FaTimes } from 'react-icons/fa';
import { BiUserCircle, BiHomeAlt, BiChevronDown } from 'react-icons/bi';
import { HiOutlineChat } from 'react-icons/hi';
import { RiShoppingBasketLine } from 'react-icons/ri';
import { BsGrid, BsFingerprint } from 'react-icons/bs';
import { useState, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import RestService from "../http";
import showToast from '../components/utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from '../components/Loader';
import Logout from '../components/utilityComponents/Logout';
import swal from 'sweetalert';
import formatNsdlDate from '../components/utilityComponents/FormatNsdlDate';
import { useNavigate } from 'react-router-dom';


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function Nsdlpan() {

    const service = new RestService();
    const navigate = useNavigate();
    const [openDropdowns, setOpenDropdowns] = useState([]);
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [panType, setPanType] = useState("1");
    const [title, setTitle] = useState('');
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobile, setMobile] = useState("");
    const [fatherFirstName, setFatherFirstName] = useState("");
    const [fatherMiddleName, setFatherMiddleName] = useState("");
    const [fatherLastName, setFatherLastName] = useState("");
    const [date, setDate] = useState(new Date());
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [parentNamePrint, setParentNamePrint] = useState("");
    const [place, setPlace] = useState("");
    const [loading, setLoading] = useState(false);
    const [pan, setPan] = useState("");
    const [balance, setBalance] = useState({});
    const [data, setData] = useState('');
    const [url, setUrl] = useState('');
    const [amount, setAmount] = useState(0);
    const [payoutMode, setPayoutMode] = useState("");
    const [commCharge, setCommCharge] = useState(0);
    const [name] = useState(sessionStorage.getItem("name"));
    const [roleName] = useState(sessionStorage.getItem("role"));


    const handleClick = (index) => {
        const updatedOpenDropdowns = [...openDropdowns];

        if (updatedOpenDropdowns.includes(index)) {
            updatedOpenDropdowns.splice(updatedOpenDropdowns.indexOf(index), 1);
        } else {
            updatedOpenDropdowns.push(index);
        }

        setOpenDropdowns(updatedOpenDropdowns);
    };


    const modalRef = useRef(null);

    const openForm = (event) => {
        event.preventDefault();
        modalRef.current.style.display = 'block';
    };

    const closeForm = () => {
        modalRef.current.style.display = 'none';
    };


    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handlePanTypeCheck = (type) => {
        setPanType(type);
        console.log(type);
    };

    console.log(formatNsdlDate(date));

    const handleNewPanApply = () => {

        if (title === undefined || title === "" || title === null) {
            showToast('WARN', 'PLEASE SELECT YOUR TITLE!!');
            return false;
        }

        if (lastName === undefined || lastName === "" || lastName === null) {
            showToast('WARN', 'PLEASE FILL THE LAST NAME!!');
            return false;
        }

        if (fatherLastName === undefined || fatherLastName === "" || fatherLastName === null) {
            showToast('WARN', 'PLEASE FILL THE FATHER LAST NAME!!');
            return false;
        }

        if (date === undefined || date === "" || date === null) {
            showToast('WARN', 'PLEASE FILL YOUR DATE OF BIRTH!!');
            return false;
        }

        if (email === undefined || email === "" || email === null) {
            showToast('WARN', 'PLEASE FILL EMAIL ADDRESS!!');
            return false;
        }

        if (gender === undefined || gender === "" || gender === null) {
            showToast('WARN', 'PLEASE SELECT YOUR GENDER!!');
            return false;
        }

        if (place === undefined || place === "" || place === null) {
            showToast('WARN', 'PLEASE FILL YOUR PLACE NAME!!');
            return false;
        }

        // if (pan === undefined || pan === "" || pan === null) {
        //     showToast('WARN', 'PLEASE FILL YOUR PAN NUMBER!!');
        //     return false;
        // }



        var raw = {
            "amount": amount,
            "mode": "OTHER",
            "operatorCode": "NPA"
        }

        service.post("commission/fetchChargeByAmount", raw).then(result => {
            console.log(result);
            if (result.status == service.SUCCESS) {
                // setBasicModal(true);
                setCommCharge(result.data.charge);

            } else {
                setCommCharge(0.0)
            }
        });


        setLoading(true);

        const newReqUrl = "https://assisted-service.egov-nsdl.com/SpringBootFormHandling/newPanReq";
        const crReqUrl = "https://assisted-service.egov-nsdl.com/SpringBootFormHandling/crPanReq";

        if (panType === "1") {
            var raw = {
                "title": title,
                "firstName": firstName,
                "middleName": middleName,
                "lastName": lastName,
                "mobile": mobile,
                "fatherFirstName": fatherFirstName,
                "fatherMiddleName": fatherMiddleName,
                "fatherLastName": fatherLastName,
                "dob": formatNsdlDate(date),
                "email": email,
                "isdCode": "",
                "gender": gender,
                "parentNamePrint": parentNamePrint,
                "place": place,
                "operatorId": "NPA"
            };

            setLoading(false);

            swal({
                title: 'Confirmation',
                text: `This service is chargeable. Are you sure you want to submit?`,
                icon: 'warning',
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: "Cancel",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,

                    },

                    confirm: {
                        text: "Yes",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true,
                    }
                },
                dangerMode: true,
            }).then((confirmed) => {

                if (confirmed) {
                    service.post("nsdl/newPanApply", raw).then(result => {
                        setLoading(false);

                        console.log(result);
                        if (result.status === service.SUCCESS) {
                            showToast("SUCCESS", result.message);
                            sessionStorage.setItem("formData", result.data);
                            sessionStorage.removeItem("url");
                            sessionStorage.setItem("url", newReqUrl);

                            setTimeout(() => {
                                window.location.href = '/nsdlform'
                            }, 1000);

                            setData(result.data);
                            setUrl(newReqUrl);

                        } else {
                            setLoading(false);
                            showToast("DANGER", result.message);
                        }
                    }).catch(error => {
                        console.error("Error submitting form:", error);

                    });
                }
            });

        } else if (panType === "2") {

            var raw = {
                "title": title,
                "firstName": firstName,
                "middleName": middleName,
                "lastName": lastName,
                "mobile": mobile,
                "fatherFirstName": fatherFirstName,
                "fatherMiddleName": fatherMiddleName,
                "fatherLastName": fatherLastName,
                "dob": formatNsdlDate(date),
                "email": email,
                "isdCode": "",
                "gender": gender,
                "parentNamePrint": parentNamePrint,
                "place": place,
                "pan": pan,
                "operatorId": "NCR"
            };

            setLoading(false);

            swal({
                title: 'Confirmation',
                text: `This service is chargeable. Are you sure you want to submit?`,
                icon: 'warning',
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: "Cancel",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },

                    confirm: {
                        text: "Yes",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                },
                dangerMode: true,
            }).then((confirmed) => {

                if (confirmed) {
                    service.post("nsdl/panCorrectionApply", raw).then(result => {
                        setLoading(false);
                        console.log(result);
                        if (result.status === service.SUCCESS) {
                            showToast("SUCCESS", result.message);
                            sessionStorage.setItem("formData", result.data);
                            sessionStorage.removeItem("url");
                            sessionStorage.setItem("url", crReqUrl);

                            setTimeout(() => {
                                window.location.href = '/nsdlform'
                            }, 1000);

                            setData(result.data);
                            setUrl(crReqUrl);
                        } else {
                            setLoading(false);
                            showToast("DANGER", result.message);
                        }
                    }).catch(error => {
                        console.error("Error submitting form:", error);

                    });
                }
            });


        } else if (panType === "3") {

            var raw = {
                "title": title,
                "firstName": firstName,
                "middleName": middleName,
                "lastName": lastName,
                "mobile": mobile,
                "fatherFirstName": fatherFirstName,
                "fatherMiddleName": fatherMiddleName,
                "fatherLastName": fatherLastName,
                "dob": formatNsdlDate(date),
                "email": email,
                "isdCode": "",
                "gender": gender,
                "parentNamePrint": parentNamePrint,
                "place": place,
                "operatorId": "DBPA"
            };

            setLoading(false);
            swal({
                title: 'Confirmation',
                text: `This service is chargeable. Are you sure you want to submit?`,
                icon: 'warning',
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: "Cancel",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },

                    confirm: {
                        text: "Yes",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                },
                dangerMode: true,
            }).then((confirmed) => {

                if (confirmed) {
                    service.post("nsdl/docBasedNewPanApply", raw).then(result => {
                        setLoading(false);
                        console.log(result);
                        if (result.status === service.SUCCESS) {
                            showToast("SUCCESS", result.message);

                            sessionStorage.setItem("formData", result.data);
                            sessionStorage.removeItem("url");
                            sessionStorage.setItem("url", newReqUrl);

                            setTimeout(() => {
                                window.location.href = '/nsdlform'
                            }, 1000);

                            setData(result.data);
                            setUrl(newReqUrl);
                        } else {
                            setLoading(false);
                            showToast("DANGER", result.message);
                        }
                    }).catch(error => {
                        console.error("Error submitting form:", error);

                    });
                }
            });

        } else if (panType === "4") {

            var raw = {
                "title": title,
                "firstName": firstName,
                "middleName": middleName,
                "lastName": lastName,
                "mobile": mobile,
                "fatherFirstName": fatherFirstName,
                "fatherMiddleName": fatherMiddleName,
                "fatherLastName": fatherLastName,
                "dob": formatNsdlDate(date),
                "email": email,
                "isdCode": "",
                "gender": gender,
                "parentNamePrint": parentNamePrint,
                "place": place,
                "pan": pan,
                "operatorId": "DBCR"
            };

            setLoading(false);
            swal({
                title: 'Confirmation',
                text: `This service is chargeable. Are you sure you want to submit?`,
                icon: 'warning',
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: "Cancel",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },

                    confirm: {
                        text: "Yes",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                },
                dangerMode: true,
            }).then((confirmed) => {

                if (confirmed) {
                    service.post("nsdl/docBasedPanCorrectionApply", raw).then(result => {
                        setLoading(false);
                        console.log(result);
                        if (result.status === service.SUCCESS) {
                            showToast("SUCCESS", result.message);

                            sessionStorage.setItem("formData", result.data);
                            sessionStorage.removeItem("url");
                            sessionStorage.setItem("url", crReqUrl);

                            setTimeout(() => {
                                window.location.href = '/nsdlform'
                            }, 1000);

                            setData(result.data);
                            setUrl(crReqUrl);
                        } else {
                            setLoading(false);
                            showToast("DANGER", result.message);
                        }
                    }).catch(error => {
                        console.error("Error submitting form:", error);

                    });
                }
            });

        } else {
            showToast("PLEASE CHOOSE A SPECIFIC OPTION")
        }
    }


    useEffect(() => {
        service.fetchUserBalance().then(result => result.json()).then(balres => {
            console.log(balres);
            setBalance(balres);

        }).catch((error) => {
            console.log(error);
            setBalance(0.0);
        })

    }, []);


    return (
        <>
            <ToastContainer />
            {loading && <Loader />}

            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" className='bg-light' open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className="pull-right" style={{ marginLeft: "79%" }}>
                            <div className="btn-group main-balance" style={{ borderRadius: "10px", backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)', color: "white", marginTop: "2px" }}>
                                <button type="button" data-toggle="dropdown" style={{ color: "white", fontWeight: "500", background: "none", border: "none" }} >
                                    <img src="image/wallet.png" className="wallet-icon" />
                                    <span className="mybal-text-white">My Balance</span><br />
                                    <span className="badge" id="wallet" style={{ fontSize: "14px" }}>&#8377;
                                        {balance === undefined
                                            ? 0.0
                                            : balance.data}
                                    </span>
                                </button>
                            </div>&nbsp;&nbsp;

                            <div className="btn-group btn-group-list btn-group-notification notification">
                                <button style={{ background: "none", border: "none", color: "black", position: "relative", }} type="button" data-toggle="dropdown">
                                    <CiBellOn style={{ fontSize: "30px" }} />
                                    <span className="badge"
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            background: "red", // Change the background color as needed
                                            borderRadius: "50%",
                                            padding: "5px",
                                            fontSize: "12px",
                                            color: "white",
                                        }}
                                    >0</span></button>

                            </div>&nbsp;&nbsp;

                            <div className="btn-group btn-group-option">
                                <button style={{ background: "none", border: "none" }} type="button" data-toggle="dropdown"><span
                                    className='user-img'>
                                    <img className='rounded-circle img-circle' src='image/profile.png' width='40' alt='' /></span></button>
                                <ul className="dropdown-menu pull-right" role="menu">
                                    <li><a href="/editprofile"><FaUserAlt className='glyphicon' /> My Profile</a>
                                    </li>

                                    <li className="divider"></li>
                                    <li>
                                        <a onClick={() => Logout("your-url")}>
                                            <MdLogout className='glyphicon' />Sign Out
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            <img style={{ width: "150px" }} src='./image/softmintlogo.png' />
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>

                    </DrawerHeader>
                    <Divider />
                    <List>

                        <div className="leftpanel" style={{ backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)' }}>
                            <div className="media profile-left">
                                <a className="pull-left profile-thumb" href="/editprofile">
                                    <img
                                        src="./image/profile.png"
                                        alt="user"
                                        className="img-circle"
                                    /> </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                <div className="media-body">
                                    <h5 className="media-heading">{name}</h5>
                                    <small className="text-white">{service.roles[new Number(roleName)]}</small>
                                </div>
                            </div>
                            <ul className="nav nav-pills nav-stacked">
                                <li className="active"><a href="/side"><BiHomeAlt className='house' /> <span>
                                    Dashboard</span></a></li>

                                <li className="parent" ><a href="#" onClick={() => handleClick(0)} ><BiUserCircle className='house' />
                                    <span> My Account</span>

                                    <BiChevronDown style={{ marginLeft: "75px" }} />
                                </a>

                                    <ul className={`children ${openDropdowns.includes(0) ? 'show' : ''}`}>
                                        <li className="" ><a href="/editprofile"> Edit Profile</a></li>
                                        <li className=""><a href="/kyc"> KYC Verification</a></li>
                                        <li className=""><a href="/kycmicroatm"> KYC Microatm</a></li>
                                        <li className=""><a href="/changepasswrd"> Change Password</a></li>
                                        <li className=""><a href="/commison"> My Commission</a></li>
                                        <li className=""><a href="print/certificate-print.php" target="_blank"> Print
                                            Certificate</a></li>
                                    </ul>

                                </li>
                                <li className="parent" ><a href="#" onClick={() => handleClick(1)}>
                                    <RiShoppingBasketLine className='house' /> <span>My Wallet</span>

                                    <BiChevronDown style={{ marginLeft: "85px" }} />
                                </a>
                                    <ul className={`children ${openDropdowns.includes(1) ? 'show' : ''}`}>
                                        <li className=""><a href="/accountinfo"> Account Info</a></li>
                                        <li className=""><a href="/bankaccount"> Bank Account Add</a></li>
                                        <li className=""><a href="/sendmoney"> Send Money</a></li>
                                        {/* <li className=""><a href="/addmoney"> Add Money</a></li> */}
                                        {/* <li className=""><a href="/fundrequest"> Fund Request</a></li> */}
                                        {/* <li className=""><a href="/wallettrans"> Wallet Transfer</a></li> */}
                                        {/* <li className=""><a href="/mpin"> Change Mpin</a></li> */}
                                    </ul>
                                </li>

                                <li className="">
                                    <a href="/retailerservice"><BsGrid className='house' /> <span>
                                        Services</span>
                                        <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                    </a>
                                </li>

                                <li className="parent"><a href="#" onClick={() => handleClick(2)}><AiOutlineFolderOpen className='house' /> <span> Reports</span>

                                    <BiChevronDown style={{ marginLeft: "97px" }} />
                                </a>
                                    <ul className={`children ${openDropdowns.includes(2) ? 'show' : ''}`}>
                                        <li className=""><a href="/rechargereportretailer"> Recharge Report</a></li>
                                        <li className=""><a href="/aepsreport"> AEPS Report </a></li>
                                        <li className=""><a href="/aadharpayreport"> AadharPay Report </a></li>
                                        <li className=""><a href="/retailertxnreport"> Transaction Report </a></li>
                                        <li className=""><a href="/payout"> Payout Report</a></li>
                                        <li className=""><a href="/nsdlreport"> NSDL Report</a></li>
                                        <li className=""><a href="/retailerinsurancereport"> Insurance Report</a></li>                                        <li className=""><a href="/retailerearningreport"> Earning Report</a></li>
                                        <li className=""><a href="/bbpsreport"> BBPS Report</a></li>
                                        <li className=""><a href="/matmreport"> MATM Report</a></li>
                                        <li className=""><a href="/retailercouponreport"> Coupon Req. Report</a></li>
                                        <li className=""><a href="/retailermatmlogreport"> MATM Log Report</a></li>
                                        <li className=""><a href="/retailermovetobankuserreport"> MoveToBankUser Report</a></li>
                                    </ul>
                                </li>
                                <li className="">
                                    <a href="/feedback"><HiOutlineChat className='house' /> <span>
                                        Feedback</span>
                                        <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                    </a></li>

                                <li className="">
                                    <a href="/kyc"><BsFingerprint className='house' /> <span>
                                        AEPS E-Kyc</span>
                                        <BiChevronDown style={{ marginLeft: "70px", visibility: "hidden" }} />
                                    </a></li>
                            </ul>

                            <div className="menu-devide">&nbsp;</div>
                            <div style={{ display: open ? 'block' : 'none' }}>
                                <div className="customer-support-sec">
                                    <h3>Account Details</h3>
                                    <p>ICICI BANK</p>
                                    <p style={{ fontSize: "11px" }}>(SOFTMINT)</p>
                                    <p>A/C No - 260705000861</p>
                                    <p><span>IFSC -</span> ICIC0002607</p>
                                </div>
                                <div className="menu-devide">&nbsp;</div>
                                <div className="customer-support-sec" style={{ display: open ? 'block' : 'none' }}>
                                    <h3>Quick Support</h3>
                                    <p>SOFTMINT</p>
                                    <p>Phone : 9999726418</p>
                                    <p>What's App : 8809912400</p>
                                    <p>Email : <a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                        data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}>info@softmintdigital.com</a></p>
                                </div>
                            </div>
                            <div style={{
                                display: open ? 'none' : 'block',
                                height: '100vh'
                            }}>
                                <div>
                                    <h3></h3>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p><span></span></p>
                                </div>
                                <div className="menu-devide">&nbsp;</div>
                                <div className="">
                                    <h3></h3>
                                    <p></p>
                                    <p></p>
                                    <p><a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                        data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}></a></p>
                                </div>
                            </div>

                        </div><br />
                    </List>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                    <DrawerHeader />
                    <div className="pageheader">
                        <div className="media">
                            <div className="pageicon pull-left">
                                < FaHome />
                            </div>&nbsp;
                            <div className="media-body">
                                <ul className="breadcrumb">
                                    <li>
                                        <a href="#">
                                            < FaUser style={{ color: '#999' }} />
                                        </a>
                                    </li>&nbsp;
                                    <li>Services</li>
                                </ul>
                                <h4>NSDL PAN</h4>
                            </div>
                        </div>
                    </div>

                    <div className="contentpanel">
                        <div className="row">

                            <div className="col-md-12">
                                <div
                                    className="form-horizontalx service-form"
                                    name="rechargeform"
                                    id="rechargeform"
                                >
                                    <div className="service-panel">
                                        <h4 className="service-title">NSDL PAN</h4>

                                        <FormControl className='ml-5' style={{ alignItems: "center" }}>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                defaultValue={panType}
                                                onChange={(e) => handlePanTypeCheck(e.target.value)}
                                            >
                                                <FormControlLabel value={1} control={<Radio />} label="NEW PAN APPLY" />
                                                <FormControlLabel value={2} control={<Radio />} label="PAN CORRECTION" />
                                                <FormControlLabel value={3} control={<Radio />} label="DOC BASED NEW PAN APPLY" />
                                                <FormControlLabel value={4} control={<Radio />} label="DOC BASED PAN CORRECTION" />

                                            </RadioGroup>
                                        </FormControl>

                                        <div className="panel-body">
                                            <div className="row">
                                                <div style={{ visibility: "hidden" }}>
                                                    <form
                                                        id="panForm"
                                                        name="panForm"
                                                        action={url}
                                                        method="post"
                                                    >
                                                        <input
                                                            type="hidden"
                                                            name="req"
                                                            id="req"
                                                            value={data}
                                                        />
                                                    </form>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="input-box selectInput-box">
                                                        <label className="control-label">Title</label>
                                                        <select
                                                            className="form-control select-box width100p"
                                                            autoFocus
                                                            tabIndex="1"
                                                            autoComplete="off"
                                                            id="pan_title"
                                                            name="pan_title"
                                                            fdprocessedid="bnilok"
                                                            onChange={(e) => setTitle(e.target.value)}
                                                        >
                                                            <option value="">Select Title</option>
                                                            <option value="1">SHRI</option>
                                                            <option value="2">SMT</option>
                                                            <option value="3">KUMARI</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="input-box">
                                                        <label className="control-label">
                                                            First Name <span>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="pan_fname"
                                                            name="pan_fname"
                                                            autoFocus
                                                            className="form-control text-box"
                                                            tabIndex="2"
                                                            autoComplete="off"
                                                            maxLength="16"
                                                            fdprocessedid="0ds6jr"
                                                            onChange={(e) => setFirstName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="input-box">
                                                        <label className="control-label">Middle Name</label>
                                                        <input
                                                            type="text"
                                                            id="pan_midname"
                                                            name="pan_midname"
                                                            className="form-control text-box"
                                                            tabIndex="2"
                                                            autoComplete="off"
                                                            maxLength="16"
                                                            fdprocessedid="1avswv"
                                                            onChange={(e) => setMiddleName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="input-box">
                                                        <label className="control-label">
                                                            Last Name <span>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="pan_lname"
                                                            name="pan_lname"
                                                            required
                                                            className="form-control text-box"
                                                            tabIndex="2"
                                                            autoComplete="off"
                                                            maxLength="16"
                                                            fdprocessedid="z7n2sv"
                                                            onChange={(e) => setLastName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 mt-2">
                                                    <div className="input-box">
                                                        <label className="control-label">
                                                            Mobile <span>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="pan_lname"
                                                            name="pan_lname"
                                                            className="form-control text-box"
                                                            tabIndex="2"
                                                            autoComplete="off"
                                                            maxLength="10"
                                                            fdprocessedid="z7n2sv"
                                                            onChange={(e) => setMobile(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 mt-2">
                                                    <div className="input-box">
                                                        <label className="control-label">
                                                            Father First Name <span>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="pan_lname"
                                                            name="pan_lname"
                                                            className="form-control text-box"
                                                            tabIndex="2"
                                                            autoComplete="off"
                                                            maxLength="16"
                                                            fdprocessedid="z7n2sv"
                                                            onChange={(e) => setFatherFirstName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 mt-2">
                                                    <div className="input-box">
                                                        <label className="control-label">
                                                            Father Middle Name <span>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="pan_lname"
                                                            name="pan_lname"
                                                            className="form-control text-box"
                                                            tabIndex="2"
                                                            autoComplete="off"
                                                            maxLength="16"
                                                            fdprocessedid="z7n2sv"
                                                            onChange={(e) => setFatherMiddleName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 mt-2">
                                                    <div className="input-box">
                                                        <label className="control-label">
                                                            Father Last Name <span>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="pan_lname"
                                                            name="pan_lname"
                                                            className="form-control text-box"
                                                            tabIndex="2"
                                                            autoComplete="off"
                                                            maxLength="16"
                                                            fdprocessedid="z7n2sv"
                                                            required
                                                            onChange={(e) => setFatherLastName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 mt-2">
                                                    <div className="input-box">
                                                        <label className="control-label">
                                                            Date Of Birth <span>*</span>
                                                        </label><br />
                                                        <DatePicker
                                                            className="form-control text-box"
                                                            name="string"
                                                            fdprocessedid="9n5i4u"
                                                            selected={date}
                                                            dateFormat={"dd/MM/yyyy"}
                                                            showYearDropdown
                                                            scrollableYearDropdown
                                                            yearDropdownItemNumber={80}
                                                            yearDropdownMin={1900}
                                                            onChange={(date) => setDate(date)}
                                                            showMonthDropdown
                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-md-3 mt-2">
                                                    <div className="input-box">
                                                        <label className="control-label">
                                                            Email <span>*</span>
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control text-box"
                                                            autoComplete="off"
                                                            required
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            style={{ textTransform: "lowercase" }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 mt-2">
                                                    <div className="input-box selectInput-box">
                                                        <label className="control-label">
                                                            Gender <span>*</span>
                                                        </label>
                                                        <select
                                                            className="form-control select-box width100p"
                                                            tabIndex="1"
                                                            autoComplete="off"
                                                            id="pan_gender"
                                                            name="pan_gender"
                                                            fdprocessedid="wpa3ep"
                                                            required
                                                            onChange={(e) => setGender(e.target.value)}
                                                        >
                                                            <option value="">Select Gender</option>
                                                            <option value="M">Male</option>
                                                            <option value="F">Female</option>
                                                            <option value="T">Transgender</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-3 mt-2">
                                                    <div className="input-box">
                                                        <label className="control-label">
                                                            Parent Name Print <span>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="pan_lname"
                                                            name="pan_lname"
                                                            autoFocus
                                                            className="form-control text-box"
                                                            tabIndex="2"
                                                            autoComplete="off"
                                                            onChange={(e) => setParentNamePrint(e.target.value)}
                                                        />
                                                    </div>
                                                </div> */}

                                                <div className="col-md-3 mt-2">
                                                    <div className="input-box">
                                                        <label className="control-label">
                                                            Place <span>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="pan_lname"
                                                            name="pan_lname"
                                                            className="form-control text-box"
                                                            tabIndex="2"
                                                            autoComplete="off"
                                                            onChange={(e) => setPlace(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-3 mt-2">
                                                    <div className="input-box">
                                                        <label className="control-label">
                                                            Operator Id <span>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="pan_lname"
                                                            name="pan_lname"
                                                            autoFocus
                                                            className="form-control text-box"
                                                            tabIndex="2"
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </div> */}

                                            </div>

                                            {panType === "2" || panType === "4" ? (
                                                <div className='row'>
                                                    <div className='col-md-3 mt-2'>
                                                        <div className="input-box">
                                                            <label className="control-label">
                                                                Pan <span>*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="pan_lname"
                                                                name="pan_lname"
                                                                className="form-control text-box"
                                                                tabIndex="2"
                                                                autoComplete="off"
                                                                style={{ textTransform: "uppercase" }}
                                                                onChange={(e) => setPan(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}

                                            <div className="form-group mt-5 text-center" style={{ marginBottom: 0 }}>
                                                <div className="col-md-12">
                                                    <input type="hidden" id="card_refid" />
                                                    <button
                                                        className="btn btn-primary service-btn but-save"
                                                        onClick={handleNewPanApply}
                                                        tabIndex="5"
                                                        id="but-save"
                                                        fdprocessedid="iaz3rp"

                                                    >
                                                        Proceed to Pan Application
                                                    </button>&nbsp;&nbsp;
                                                    <button
                                                        className="btn btn-default service-btn but-save"
                                                        // onClick={() =>
                                                        //     butresetpage('c-n-service-nsdl-pancard-booking.php')
                                                        // }
                                                        tabIndex="6"
                                                        fdprocessedid="wtl19q"
                                                    >
                                                        Clear
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Box>
            </Box>

            <a href="https://api.whatsapp.com/send/?phone=8809912400&text&app_absent=0" target="_blank"
                className="whatsapp-but" title="">
                <img src="image/whatsapp-icon.png" alt="Whatapps" />
            </a>

            <a
                href="#"
                target="_blank"
                className="helpdesk-but"
                title="Request Callback !"
                onClick={openForm}
            >
                <img src="image/help-icon.png" alt="Help" />
            </a>

            <div className="chat-popup" ref={modalRef} id="myForm">
                <form action="#" className="helpdesk-container" onSubmit={(e) => e.preventDefault()}>
                    <h1>
                        <div className="callbackheader-icon">
                            <FaWeixin aria-hidden="true" style={{ fontSize: '35px', paddingTop: '7px' }} />
                        </div>
                        CallBack Request
                    </h1>
                    <label htmlFor="callback_name"><b>Full Name</b></label>
                    <input
                        type="text"
                        id="callback_name"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="Name"
                        required
                    />
                    <label htmlFor="callback_phone"><b>Contact Number</b></label>
                    <input
                        type="text"
                        id="callback_phone"
                        maxLength="10"
                        name=""
                        className="form-control textbox"
                        placeholder="+91 9000 0000 00"
                        required
                    />
                    <label htmlFor="callback_email"><b>Email ID</b></label>
                    <input
                        type="text"
                        id="callback_email"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="info@softmintdigital.com"
                        required
                    />
                    <label htmlFor="callback_msg"><b>Message (250 Character Only)</b></label>
                    <textarea
                        id="callback_msg"
                        maxLength="250"
                        placeholder="Type message.."
                        name="msg"
                        className="form-controlx"
                    ></textarea>
                    <button type="submit" className="btn" id="reqcallbut">
                        Send Now
                    </button>
                    <a href="#" className="close-but" title="" onClick={closeForm}>
                        <FaTimes aria-hidden="true" />
                    </a>
                </form>
            </div>

            <div className="modal fade recharge-model success-model" id="model-succ-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="model-succ-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button success-button" onClick="errordisplay()" data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model error-model" id="model-err-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className=""><img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Error Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-err-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button error-button" onClick="errordisplay()" data-dismiss="modal">Dismiss</button>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model process-model" id="model-process-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Process Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-process-body">
                            <p className="mb-0"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model " id="model-yn-save" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-body-pdf">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="getinvno" />
                        <button type="button" className="btn btn-success model-button success-button" onClick="succok()" data-dismiss="modal" id="modal-no-save">OK</button>
                    </div>
                </div>
            </div>


            );
        </>
    );
}


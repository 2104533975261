import React from 'react';
import Navbar from './Navbar';
import { useState, useEffect } from 'react';
import RestService from '../http';
import { ToastContainer } from 'react-toastify';
import showToast from './utilityComponents/APPToast';
import Loader from './Loader';
import Select from 'react-select';
import { Link } from 'react-router-dom';


export default function Changepswrd() {

    const service = new RestService();
    const [showAlert, setShowAlert] = useState(true);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [userId, setUserId] = useState("");
    const [userList, setUserList] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);


    const handleAlertClose = () => {
        setShowAlert(false);
    };


    const handleChangePassword = () => {

        if (newPassword === undefined || newPassword === "" || newPassword === null) {
            showToast('WARN', 'PLEASE FILL YOUR NEW PASSWORD!!');
            return false;
        }

        if (confirmPassword === undefined || confirmPassword === "" || confirmPassword === null) {
            showToast('WARN', 'PLEASE RE-ENTER YOUR NEW PASSWORD!!');
            return false;
        }

        var raw = {
            "existingPassword": "",
            "newPassword": newPassword,
            "confirmPassword": confirmPassword,
            "userId": userId
        }

        setLoading(true);

        service.post("user/changePassword", raw).then(result => {
            setLoading(false);

            console.log(result)
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
            } else {
                showToast("DANGER", result.message);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleCustomerType = (e) => {
        console.log(e.target.value);
        service
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setUserList(result.data);
                } else {
                    setUserList([]);
                }
            })
            .catch((error) => {
                console.error(error);
                setUserList([]);

            });
    };


    useEffect(() => {
        service.get("user/roles").then(result => {
            console.log(result);
            setRoles(result.role);

        }).catch((error) => {
            console.log(error);
        });
    }, []);


    const [selectedUserId, setSelectedUserId] = useState(null);

     const options = [
        { value: '', label: 'Select an option' },
        ...userList.map((item) => ({
            value: item.userId,
            label: `${item.name} || ${item.mobile}`,
        }))
    ];

    const handleSelectChange = (selectedUserId) => {
        setSelectedUserId(selectedUserId);
        setUserId(selectedUserId.value);
    };

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "620.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Change Password</h1>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Profile</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Change Password</li>
                                </ol>
                            </div>

                        </div>

                    </div>

                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card col-6 m-auto">
                            <div className="card-body">
                                {showAlert && (
                                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                        Password Should Contain at least 1 Number, 1 Capital letter, 1 Small letter, and 1 Special Character And Minimum 8 characters length.
                                        <button type="button" className="close" onClick={handleAlertClose}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                )}
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div action="/changepaswrd">
                                        <div className="row"></div>

                                        <div className='row'>
                                            <div className="col-12 mt-5">
                                                <label for="assign_commission">
                                                    User Type: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleCustomerType}
                                                >
                                                    <option>Select User Type</option>
                                                    {roles.map((item) => (
                                                        item.roleId !== 0 ? <option value={item.roleId}>{item.roleName}</option> : null
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12" style={{ marginTop: '10px' }}>
                                                <label for="service_id">
                                                    User Id: <span className="text-danger"> *</span>
                                                </label>
                                                {/* <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setUserId(e.target.value)}
                                                >
                                                    <option value="">Select Id...</option>
                                                    {userList.map((item) => (
                                                        <option value={item.userId}>{item.name}</option>
                                                    ))}
                                                </select> */}

                                                <Select
                                                    className="select-box width100p"
                                                    options={options}
                                                    isSearchable
                                                    value={selectedUserId}
                                                    onChange={handleSelectChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12" style={{ marginTop: '10px' }}>
                                                <label>New Password</label>
                                                <span className="text-danger"> *</span>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    placeholder="Enter New Password"
                                                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                                    minLength="8"
                                                    className="form-control"
                                                    required
                                                    fdprocessedid="j6qsdd"
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12" style={{ marginTop: '10px' }}>
                                                <label>Confirm Password</label>
                                                <span className="text-danger"> *</span>
                                                <input
                                                    type="password"
                                                    placeholder="Please Confirm Your Password"
                                                    name="confirm_password"
                                                    minLength="8"
                                                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                                    className="form-control"
                                                    required
                                                    fdprocessedid="7zl31"
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                />
                                                <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center" style={{ marginTop: '10px' }}>
                                                <input onClick={handleChangePassword} name="" value="Change Password" className="btn btn-primary" fdprocessedid="0rzuuq" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

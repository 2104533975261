import React from 'react';
import { CiBellOn } from 'react-icons/ci';
import { AiOutlineFolderOpen, AiOutlineClose } from 'react-icons/ai';
import { MdLogout } from 'react-icons/md';
import { FaUserAlt, FaWeixin, FaTimes } from 'react-icons/fa';
import { BiUserCircle, BiHomeAlt, BiChevronDown } from 'react-icons/bi';
import { HiOutlineChat } from 'react-icons/hi';
import { RiShoppingBasketLine } from 'react-icons/ri';
import { BsGrid, BsFingerprint } from 'react-icons/bs';
import { useState, useRef, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RestService from '../http';
import showToast from '../components/utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Logout from '../components/utilityComponents/Logout';
import Loader from '../components/Loader';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function AepsLogin() {

    const service = new RestService();
    const navigate = useNavigate();
    const [openDropdowns, setOpenDropdowns] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [biometricData, setBiometricData] = useState('');
    // const [merchentBiometricData, setMerchentBiometricData] = useState(undefined);
    const [finalPort, setFinalPort] = useState(11100);
    const [infoPath, setInfoPath] = useState("");
    const [capturePath, setCapturePath] = useState("");
    const [selectedDevice, setSelectedDevice] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [balance, setBalance] = useState({});
    const [name] = useState(sessionStorage.getItem("name"));
    const [roleName] = useState(sessionStorage.getItem("role"));
    const [position, setPosition] = useState({ latitude: null, longitude: null });


    const [pidXml] = useState({
        MANTRA:
            '<PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="20000" posh="UNKNOWN" env="P" wadh=""/> <CustOpts><Param name="mantrakey" value="" /></CustOpts> </PidOptions>',
        MORPHO:
            '<PidOptions ver="1.0"><Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="" posh=""/></PidOptions>',
        STARTEK:
            '<PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="20000" posh="UNKNOWN" env="P" wadh=""/> <CustOpts><Param name="mantrakey" value="" /></CustOpts> </PidOptions>',
        MANTRA_IRIS:
            '<PidOptions ver="1.0"> <Opts fCount="0" fType="2" iCount="1" pCount="0" format="0" pidVer="2.0" timeout="20000" posh="UNKNOWN" env="P" wadh=""/> <CustOpts><Param name="mantrakey" value="" /></CustOpts> </PidOptions>',

    });

    const [merchentCapture, setMerchentCapture] = useState(false);
    const [userCapture, setUserCapture] = useState(true);
    const [submitButton, setSubmitButton] = useState(true);

    /**
      * @author SANTANU & SAKSHI
      * @description TO GET BIOMETRIC STATUS CODE AND ERROR MESSAGE
      */
    const getParsedBiometric = (xmlText) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlText, 'application/xml');
        const respElement = xmlDoc.querySelector('Resp');
        const errorCode = respElement.getAttribute('errCode');
        const errorMsg = respElement.getAttribute('errInfo');

        return {
            errorCode: errorCode,
            errorMsg: errorMsg,
            data: xmlDoc
        };
    };

    /**
     * @author SANTANU & SAKSHI
     * @description TO GET DEVICE READY STATUS AND DEVICE INFORMATION ENDPOINT AND DEVICE CAPTURE ENDPOINT
     */
    const parseXmlText = (xmlText) => {
        const parser = new DOMParser();

        const xmlDoc = parser.parseFromString(xmlText, "application/xml");
        const rdServiceElement = xmlDoc.querySelector("RDService");
        const deviceInfoInterface = xmlDoc.querySelector(
            'Interface[id="DEVICEINFO"]'
        );
        const captureInterface = xmlDoc.querySelector('Interface[id="CAPTURE"]');
        const rdServiceStatus = rdServiceElement.getAttribute("status");
        const deviceInfoPath = deviceInfoInterface.getAttribute("path");
        const capturePath = captureInterface.getAttribute("path");
        setInfoPath(deviceInfoPath);
        setCapturePath(capturePath);
        console.log("DEVICEINFO path:", deviceInfoPath, capturePath);
        var infoData = {
            status: rdServiceStatus,
            infoPath: deviceInfoPath,
            capturePath: capturePath,
        };
        return infoData;
    };

    /**
     * @author SANTANU & SAKSHI
     * @description TO DISCOVER BIOMETRIC DEVICE
     */
    const discoverRDService = async () => {
        // setLoading(true);
        for (let index = 11100; index < 11106; index++) {
            console.log('PORT DISCOVERY', index);
            let url = `http://localhost:${index}/`;
            let headers = {
                accept: "application/json, text/xml, */*",
            };
            let request = {
                method: "RDSERVICE",
                headers: headers,
            };
            console.log(url, request);

            const info = await fetch(url, request)
                .then((response) => response.text())
                .then((result) => {
                    console.log(result);
                    let parsedXml = parseXmlText(result);
                    if (parsedXml.status === "READY") {
                        setLoading(false);
                        setFinalPort(index);
                        showToast("SUCCESS", "DEVICE DISCOVERED SUCCESSFULLY!!");
                        return {
                            status: "READY",
                            data: parsedXml,
                        };
                    }
                    return {
                        status: "NOTREADY",
                        data: parsedXml,
                    };
                })
                .catch((err) => {
                    // showToast("DANGER", "RD SERVICE DISCOVERY FAILED!!");
                    console.log(err);
                    return {
                        status: "ERROR",
                        data: undefined,
                    };
                });



            if (info.status === "READY") {
                setLoading(false);
                setFinalPort(index);
                // showToast("SUCCESS", "DEVICE DISCOVERED SUCCESSFULLY!!");
                break;
            }
            // else if (info.status === "ERROR") {
            //     setLoading(false);
            //     break;
            // }

            setTimeout(() => { }, 500);
        }
    };
    useEffect(() => {
        discoverRDService();
    }, []);

    /**
   * @author SANTANU 
   * @description TO CAPTURE THE BIOMETRIC DATA
   */
    const capture = () => {

        setLoading(true);
        if (selectedDevice === undefined) {
            showToast("DANGER", "PLEASE CHOOSE A DEVICE TYPE FIRST");
            setLoading(false);
            return {
                result: null,
                status: service.FAILED
            };
        }
        var pidXmlData = "";
        if (selectedDevice === "MANTRA") {
            pidXmlData = pidXml.MANTRA;
        } else if (selectedDevice === "MORPHO") {
            pidXmlData = pidXml.MORPHO;
        } else if (selectedDevice === "STARTEK") {
            pidXmlData = pidXml.STARTEK;
        } else if (selectedDevice === "MANTRA_IRIS") {
            pidXmlData = pidXml.MANTRA_IRIS;
        }

        let url = `http://localhost:${finalPort}${capturePath}`;
        let headers = {
            accept: "application/json, text/xml, */*",
        };
        let request = {
            method: "CAPTURE",
            headers: headers,
            body: pidXmlData,
        };
        const bioData = fetch(url, request)
            .then((response) => response.text())
            .then((result) => {
                setLoading(false);
                const parsedData = getParsedBiometric(result);
                if (parsedData.errorCode === '0') {
                    setBiometricData(result);
                    showToast("SUCCESS", "BIOMETRIC CAPTURED SUCCESSFULLY");
                    return {
                        result: result,
                        status: service.SUCCESS
                    }
                } else {
                    setBiometricData(undefined);
                    showToast("DANGER", `BIOMETRIC CAPTURE FAILED, ${parsedData.errorMsg}`);
                    return {
                        result: null,
                        status: service.FAILED
                    };
                }
            })
            .catch((error) => {
                setLoading(false);
                showToast("DANGER", "BIOMETRIC CAPTURE FAILED!!");
                console.log(error);
                return {
                    result: null,
                    status: service.FAILED
                };
            });

        return bioData;
    };

    // const captureMerchentBiometric = async () => {
    //     const data = await capture();
    //     if (data.status === service.SUCCESS) {
    //         setBiometricData(data.result);
    //         setUserCapture(false);
    //     } else {
    //         showToast("DANGER", "PLEASE RE CAPTURE BIOMETRIC!!");
    //     }
    // }

    const handleClick = (index) => {
        const updatedOpenDropdowns = [...openDropdowns];

        if (updatedOpenDropdowns.includes(index)) {
            updatedOpenDropdowns.splice(updatedOpenDropdowns.indexOf(index), 1);
        } else {
            updatedOpenDropdowns.push(index);
        }

        setOpenDropdowns(updatedOpenDropdowns);
    };


    const modalRef = useRef(null);

    const openForm = (event) => {
        event.preventDefault();
        modalRef.current.style.display = 'block';
    };

    const closeForm = () => {
        modalRef.current.style.display = 'none';
    };

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };


    const blockAddressChar = (event) => {
        const blockedChars = ['A', 'B', 'C']; // Add the characters you want to block

        if (blockedChars.includes(event.key.toUpperCase())) {
            event.preventDefault();
        }
    };

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setPosition({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            });
        } else {
            console.log("Geolocation is not available in your browser.");
        }
    }, []);

    const handleAepsLogin = () => {

        if (biometricData === undefined || biometricData === "") {
            showToast("WARN", "PLEASE CAPTURE BIOMETRIC DATA!!");
            return;
        }

        var raw = {
            "operatorIncode": "2FALOGIN",
            "BiometricData": biometricData,
            "latitude": position.latitude,
            "longitude": position.longitude
        }

        setLoading(true);

        service.post("warehouse/aeps", raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {

                showToast("SUCCESS", result.message);
                navigate("/aepsservice")

            } else {
                showToast("DANGER", result.message);
            }

        }).catch(error => {
            console.error(error);
        });
    }

    const handleCancelClick = () => {
        navigate("/retailerservice")
    };

    const handleAepsRegister = () => {
        // var raw = {
        //     "operatorIncode": "AREG"
        // }

        // service.post("nsdlaeps/register", raw).then(result => {
        //     if (result.status === service.SUCCESS) {

        //         if (result.Register !== null) {
        //             setShowModal(false);

        //         } else {

        //             showToast("DANGER", result.message);
        //         }
        //     }
        // }).catch((error) => {
        //     console.log(error);
        // })

        setShowModal(false);
    }

    const modalStyle = {
        display: 'flex',
        zIndex: '1',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', /* Black with opacity */
    };

    const modalContentStyle = {
        backgroundColor: '#fefefe',
        margin: '15% auto',
        padding: '20px',
        border: '1px solid #888',
        width: '50%',
    };

    const closeButtonStyle = {
        color: '#aaa',
        fontSize: '28px',
        fontWeight: 'bold',
        cursor: 'pointer',
    };

    const okButtonStyle = {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '10px 15px',
        border: 'none',
        borderRadius: '5px',
        margin: '5px',
        cursor: 'pointer',
    };

    const cancelButtonStyle = {
        backgroundColor: '#f44336',
        color: 'white',
        padding: '10px 15px',
        border: 'none',
        borderRadius: '5px',
        margin: '5px',
        cursor: 'pointer',
    };

    useEffect(() => {
        service.fetchUserBalance().then(result => result.json()).then(balres => {
            console.log(balres);
            setBalance(balres);

        }).catch((error) => {
            console.log(error);
        })

    }, []);

    return (
        <>
            <ToastContainer />
            {loading && <Loader />}

            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" className='bg-light' open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className="pull-right" style={{ marginLeft: "79%" }}>
                            <div className="btn-group main-balance" style={{ borderRadius: "10px", backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)', color: "white", marginTop: "2px" }}>
                                <button type="button" data-toggle="dropdown" style={{ color: "white", fontWeight: "500", background: "none", border: "none" }} >
                                    <img src="image/wallet.png" className="wallet-icon" />
                                    <span className="mybal-text-white">My Balance</span><br />
                                    <span className="badge" id="wallet" style={{ fontSize: "14px" }}>&#8377;
                                        {balance === undefined
                                            ? 0.0
                                            : balance.data}
                                    </span>
                                </button>
                            </div>&nbsp;&nbsp;

                            <div className="btn-group btn-group-list btn-group-notification notification">
                                <button style={{ background: "none", border: "none", color: "black", position: "relative", }} type="button" data-toggle="dropdown">
                                    <CiBellOn style={{ fontSize: "30px" }} />
                                    <span className="badge"
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            background: "red", // Change the background color as needed
                                            borderRadius: "50%",
                                            padding: "5px",
                                            fontSize: "12px",
                                            color: "white",
                                        }}
                                    >0</span></button>

                            </div>&nbsp;&nbsp;

                            <div className="btn-group btn-group-option">
                                <button style={{ background: "none", border: "none" }} type="button" data-toggle="dropdown"><span
                                    className='user-img'>
                                    <img className='rounded-circle img-circle' src='image/profile.png' width='40' alt='' /></span></button>
                                <ul className="dropdown-menu pull-right" role="menu">
                                    <li><a href="/editprofile"><FaUserAlt className='glyphicon' /> My Profile</a>
                                    </li>

                                    <li className="divider"></li>
                                    <li>
                                        <a onClick={() => Logout("your-url")}>
                                            <MdLogout className='glyphicon' />Sign Out
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            <img style={{ width: "150px" }} src='./image/softmintlogo.png' />
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>

                    </DrawerHeader>
                    <Divider />
                    <List>

                        <div className="leftpanel" style={{ backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)' }}>
                            <div className="media profile-left">
                                <a className="pull-left profile-thumb" href="/editprofile">
                                    <img
                                        src="./image/profile.png"
                                        alt="user"
                                        className="img-circle"
                                    /> </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                <div className="media-body">
                                    <h5 className="media-heading">{name}</h5>
                                    <small className="text-white">{service.roles[new Number(roleName)]}</small>
                                </div>
                            </div>
                            <ul className="nav nav-pills nav-stacked">
                                <li className="active"><Link to="/side"><BiHomeAlt className='house' /> <span>
                                    Dashboard</span></Link></li>

                                <li className="parent" ><Link href="#" onClick={() => handleClick(0)} ><BiUserCircle className='house' />
                                    <span> My Account</span>

                                    <BiChevronDown style={{ marginLeft: "75px" }} />
                                </Link>

                                    <ul className={`children ${openDropdowns.includes(0) ? 'show' : ''}`}>
                                        <li className="" ><Link to="/editprofile"> Edit Profile</Link></li>
                                        <li className=""><Link to="/kyc"> KYC Verification</Link></li>
                                        <li className=""><Link to="/kycmicroatm"> KYC Microatm</Link></li>
                                        <li className=""><Link to="/changepasswrd"> Change Password</Link></li>
                                        <li className=""><Link to="/commison"> My Commission</Link></li>
                                        <li className=""><Link to="print/certificate-print.php" target="_blank"> Print Certificate</Link></li>
                                    </ul>

                                </li>
                                <li className="parent" ><a href="#" onClick={() => handleClick(1)}>
                                    <RiShoppingBasketLine className='house' /> <span>My Wallet</span>

                                    <BiChevronDown style={{ marginLeft: "85px" }} />
                                </a>
                                    <ul className={`children ${openDropdowns.includes(1) ? 'show' : ''}`}>
                                        <li className=""><Link to="/accountinfo"> Account Info</Link></li>
                                        <li className=""><Link to="/bankaccount"> Bank Account Add</Link></li>
                                        <li className=""><Link to="/sendmoney"> Send Money</Link></li>
                                        {/* <li className=""><Link to="/addmoney"> Add Money</Link></li> */}
                                        {/* <li className=""><Link to="/fundrequest"> Fund Request</Link></li> */}
                                        {/* <li className=""><Link to="/wallettrans"> Wallet Transfer</Link></li> */}
                                        {/* <li className=""><Link to="/mpin"> Change Mpin</Link></li> */}
                                    </ul>
                                </li>

                                <li className="">
                                    <Link to="/retailerservice"><BsGrid className='house' /> <span>
                                        Services</span>
                                        <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                    </Link>
                                </li>

                                <li className="parent"><a href="#" onClick={() => handleClick(2)}><AiOutlineFolderOpen className='house' /> <span> Reports</span>

                                    <BiChevronDown style={{ marginLeft: "97px" }} />
                                </a>
                                    <ul className={`children ${openDropdowns.includes(2) ? 'show' : ''}`}>
                                        <li className=""><Link to="/rechargereportretailer"> Recharge Report</Link></li>
                                        <li className=""><Link to="/aepsreport"> AEPS Report </Link></li>
                                        <li className=""><Link to="/aadharpayreport"> AadharPay Report </Link></li>
                                        <li className=""><Link to="/retailertxnreport"> Transaction Report </Link></li>
                                        <li className=""><Link to="/payout"> Payout Report</Link></li>
                                        <li className=""><Link to="/nsdlreport"> NSDL Report</Link></li>
                                        <li className=""><Link to="/retailerinsurancereport"> Insurance Report</Link></li>
                                        <li className=""><Link to="/retailerearningreport"> Earning Report</Link></li>
                                        <li className=""><Link to="/bbpsreport"> BBPS Report</Link></li>
                                        <li className=""><Link to="/matmreport"> MATM Report</Link></li>
                                        <li className=""><Link to="/retailercouponreport"> Coupon Req. Report</Link></li>
                                        <li className=""><Link to="/retailermatmlogreport"> MATM Log Report</Link></li>
                                        <li className=""><Link to="/retailermovetobankuserreport"> MoveToBankUser Report</Link></li>
                                        <li className=""><Link to="/dmtreport"> DMT Report</Link></li>

                                    </ul>
                                </li>
                                <li className=""><Link to="/feedback"><HiOutlineChat className='house' /> <span>
                                    Feedback</span>
                                    <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                </Link></li>
                                <li className=""><Link to="/kyc"><BsFingerprint className='house' /> <span>
                                    AEPS E-Kyc</span>
                                    <BiChevronDown style={{ marginLeft: "70px", visibility: "hidden" }} />
                                </Link></li>
                            </ul>

                            <div className="menu-devide">&nbsp;</div>
                            <div style={{ display: open ? 'block' : 'none' }}>
                                <div className="customer-support-sec">
                                    <h3>Account Details</h3>
                                    <p>ICICI BANK</p>
                                    <p style={{ fontSize: "11px" }}>(SOFTMINT)</p>
                                    <p>A/C No - 260705000861</p>
                                    <p><span>IFSC -</span> ICIC0002607</p>
                                </div>
                                <div className="menu-devide">&nbsp;</div>
                                <div className="customer-support-sec" style={{ display: open ? 'block' : 'none' }}>
                                    <h3>Quick Support</h3>
                                    <p>SOFTMINT</p>
                                    <p>Phone : 9999726418</p>
                                    <p>What's App : 8809912400</p>
                                    <p>Email : <a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                        data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}>info@softmintdigital.com</a></p>
                                </div>
                            </div>
                            <div style={{
                                display: open ? 'none' : 'block',
                                height: '100vh'
                            }}>
                                <div>
                                    <h3></h3>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p><span></span></p>
                                </div>
                                <div className="menu-devide">&nbsp;</div>
                                <div className="">
                                    <h3></h3>
                                    <p></p>
                                    <p></p>
                                    <p><a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                        data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}></a></p>
                                </div>
                            </div>

                        </div><br />
                    </List>
                </Drawer>

                <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                    <DrawerHeader />
                    <div className="pageheader">
                        <div className="media">
                            <div className="media-body">
                                <ul className="breadcrumb">
                                    <li><a href="javascript:void(0);"><BiHomeAlt style={{ color: '#999' }} /></a></li>&nbsp;
                                    <li>Services</li>
                                </ul>
                                <h4>AEPS Service</h4>
                            </div>
                        </div>
                    </div>

                    {showModal && (
                        <div className="modal" style={modalStyle}>
                            <div className="modal-content" style={modalContentStyle}>
                                <span className="close text-end" style={closeButtonStyle}>
                                    &times;
                                </span>
                                <p>
                                    NPCI के निर्देशों के अनुसार, AEPS ट्रांजेक्शन करने के लिए हर दिन एक बार मर्चेंट को Biomatric या Iris से सत्यापन करना होगा। तभी आप एप्स के ट्रांजैक्शन कर सकते हैं।
                                    <br /><br />
                                    As per NPCI guidelines, merchants will need to authenticate themselves through biometric or iris verification once a day to perform AEPS transactions.
                                    <br />
                                    Hello SOFTMINT DEMO, You Need to Login To Proceed!!
                                </p>
                                <div className="button-container text-end mt-2">
                                    <button style={okButtonStyle} onClick={handleAepsRegister}>
                                        Login AEPS
                                    </button>
                                    <button style={cancelButtonStyle} onClick={handleCancelClick}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="contentpanel">
                        <div className="row">
                            <div className="col-md-12">
                                <div name="rechargeform" className="service-form" id="rechargeform">
                                    <div className="service-panel">

                                        <h4 className="service-title">AEPS Service</h4>

                                        <div className="panel-body">
                                            <div className="form-group">
                                                <div className="col-md-6">
                                                    <div className="input-box">
                                                        <label className="control-label">Select Device <span>*</span></label>
                                                        <span className="shell">
                                                            <select className="form-control select-box width100p" onChange={(e) => setSelectedDevice(e.target.value)}>
                                                                <option value="">Select Device Type</option>
                                                                <option value="MANTRA">MANTRA</option>
                                                                <option value="MORPHO">MORPHO</option>
                                                                <option value="STARTEK">STARTEK</option>
                                                                <option value="MANTRA_IRIS">MANTRA IRIS</option>
                                                                <option value="MORPHOL1">MORPHO L1</option>
                                                                <option value="PRECISION">PRECISION</option>
                                                            </select>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div><br />

                                            <div className="col-md-6">
                                                <div className="input-box">
                                                    <label className="control-label">Biometric Data <span>*</span></label>&nbsp;&nbsp;&nbsp;<br />
                                                    <span className="shell">
                                                        <button className='btn bg-green' enabled={userCapture} onClick={capture}>SCAN</button>
                                                    </span>
                                                </div>
                                            </div><br />

                                            <div className="form-group mt-4">
                                                <div className="col-md-12">
                                                    <button className="btn btn-primary service-btn" onClick={handleAepsLogin}>LOGIN</button>&nbsp;
                                                    <button className="btn btn-default service-btn but-save" tabindex="6">Clear</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </Box>
            </Box>

            {showPopup && (
                <div className="modal-backdrop">
                    <div id="model_bank" className="modal" style={{ width: '100%', top: '10%', display: 'block', padding: "10%" }}>
                        <div className="modal-dialog modal-lg" >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 id="label_bank_heading" style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>FAVORITE BANK !</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={closePopup}>
                                        <AiOutlineClose className="fa fa-close" />
                                    </button>

                                </div>
                                <div className="modal-body" style={{ marginBottom: '-8px' }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="box box-black">
                                                <div className="panel panel-default">
                                                    <div className="row">
                                                        <div className="col-md-12 tabla-border" style={{ padding: '11px 22px' }}>
                                                            <div id="bank_display">
                                                                <div className="box-content" style={{ padding: '0px', margin: '0px', maxHeight: '330px', overflow: 'scroll', fontSize: '14px' }}>
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr className="table-new">
                                                                                <th style={{ width: '180px', whiteSpace: 'nowrap', textAlign: 'left' }}>BANK NAME</th>
                                                                                <th style={{ width: '150px', textAlign: 'center', whiteSpace: 'nowrap' }}>BANK IMAGE</th>
                                                                                <th style={{ width: '150px', textAlign: 'center', whiteSpace: 'nowrap' }}>IS ADD</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody style={{ fontSize: '10px' }}>
                                                                            <tr>
                                                                                <td style={{ textAlign: 'left' }}>Airtel Payment Bank</td>
                                                                                <td style={{ textAlign: 'center', whiteSpace: 'normal' }}>
                                                                                    <img
                                                                                        src="./image/airtel.png"
                                                                                        style={{
                                                                                            width: '30px',
                                                                                            height: '30px',
                                                                                            border: 'solid 1px #d0d0d0',
                                                                                            borderRadius: '50%',
                                                                                            padding: '4px',
                                                                                        }}
                                                                                        alt="Airtel Payment Bank"
                                                                                    />
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    <input type="checkbox" className="" id="isactv_1" onClick="activefevbank(1)" />
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="panel panel-default">
                                                    <div className="row">
                                                        <div enctype="multipart/form-data" className="form-horizontal form-bordered">
                                                            <div className="form-group service-form" style={{ marginBottom: '0px' }}>
                                                                <div className="col-md-7">
                                                                    <div className="input-box">
                                                                        <label className="control-label">BANK NAME</label>
                                                                        <input
                                                                            type="text"
                                                                            maxLength="50"
                                                                            name="optbantext"
                                                                            id="optbantext"
                                                                            tabIndex="1"
                                                                            className="form-control text-box"
                                                                            onKeyUp="searchfevbank(1)"
                                                                            onKeyDown={blockAddressChar}
                                                                            fdprocessedid="un9h2p"
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-3 mt-3">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary service-btn but-save btnall"
                                                                            tabIndex="3"
                                                                            onClick="searchfevbank(2)"
                                                                            fdprocessedid="t6aky"
                                                                        >
                                                                            ALL
                                                                        </button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <input type="hidden" id="setid" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            <a href="https://api.whatsapp.com/send/?phone=8809912400&text&app_absent=0" target="_blank"
                className="whatsapp-but" title="">
                <img src="image/whatsapp-icon.png" alt="Whatapps" />
            </a>

            <a
                href="#"
                target="_blank"
                className="helpdesk-but"
                title="Request Callback !"
                onClick={openForm}
            >
                <img src="image/help-icon.png" alt="Help" />
            </a>

            <div className="chat-popup" ref={modalRef} id="myForm">
                <div action="#" className="helpdesk-container">
                    <h1>
                        <div className="callbackheader-icon">
                            <FaWeixin aria-hidden="true" style={{ fontSize: '35px', paddingTop: '7px' }} />
                        </div>
                        CallBack Request
                    </h1>
                    <label htmlFor="callback_name"><b>Full Name</b></label>
                    <input
                        type="text"
                        id="callback_name"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="Name"
                        required
                    />
                    <label htmlFor="callback_phone"><b>Contact Number</b></label>
                    <input
                        type="text"
                        id="callback_phone"
                        maxLength="10"
                        name=""
                        className="form-control textbox"
                        placeholder="+91 9000 0000 00"
                        required
                    />
                    <label htmlFor="callback_email"><b>Email ID</b></label>
                    <input
                        type="text"
                        id="callback_email"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="info@softmintdigital.com"
                        required
                    />
                    <label htmlFor="callback_msg"><b>Message (250 Character Only)</b></label>
                    <textarea
                        id="callback_msg"
                        maxLength="250"
                        placeholder="Type message.."
                        name="msg"
                        className="form-controlx"
                    ></textarea>
                    <button type="submit" className="btn" id="reqcallbut">
                        Send Now
                    </button>
                    <a href="#" className="close-but" title="" onClick={closeForm}>
                        <FaTimes aria-hidden="true" />
                    </a>
                </div>
            </div>

            <div className="modal fade recharge-model success-model" id="model-succ-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="model-succ-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button success-button" onClick="errordisplay()" data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model error-model" id="model-err-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Error Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-err-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button error-button" onClick="errordisplay()" data-dismiss="modal">Dismiss</button>
                    </div>
                </div>
            </div>

            <div className="modal fade recharge-model process-model" id="model-process-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Process Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-process-body">
                            <p className="mb-0"></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade recharge-model " id="model-yn-save" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-body-pdf">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="getinvno" />
                        <button type="button" className="btn btn-success model-button success-button" data-dismiss="modal" id="modal-no-save">OK</button>
                    </div>
                </div>
            </div>


            );
        </>
    );
}

import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Box } from '@mui/material'
// import Container from '@mui/material/Container';
// import Donut from "./Donut";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Recharge from "./Recharge";



class Mychart extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   options: {
    //     chart: {
    //       id: "area-chart"
    //     },
    //     xaxis: {
    //       categories: ['25 Apr', '27 Apr', '29 Apr', '01 May', '03 May']
    //     }
    //   },
    //   series: [
    //     {
    //       name: "series-1",
    //       data: [1.0, 0.5, 0, -0.5, 1.0]
    //     }
    //   ]
    // };

    this.state = {
      options: {
        chart: {
          type: 'line',
          height: 350,
        },

        xaxis: {
          type: 'datetime',
          categories: [
            '01 Jan 2001',
            '02 Jan 2001',
            '03 Jan 2001',
            '04 Jan 2001',
            '05 Jan 2001',
            '06 Jan 2001',
            '07 Jan 2001',
            '08 Jan 2001',
            '09 Jan 2001',
            '10 Jan 2001',
            '11 Jan 2001',
            '12 Jan 2001',
          ],
        },

        stroke: {
          width: [0, 4],
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
      },
      series: [
        {
          name: 'Website Blog',
          type: 'column',
          data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
        },
        {
          name: 'Social Media',
          type: 'line',
          data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
        },
      ],
    };
  }


  render() {



    return (
      <>
        <Box style={{ height: "30px" }} />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} md={6} sm={12}>

            <div
              className="cont"
              style={{
                display: 'flex',
                border: '1px solid #ddd',
                padding: '15px',
                overflow: 'scroll',
                height: '100%',
                backgroundColor: 'white',
              }}
            >
              <div className="app">
                <Typography style={{ fontSize: '13px' }}>SERVICE PERFORMANCE</Typography>
                <Typography style={{ fontSize: '12px' }}>
                  Last 18 day's transition details...
                </Typography>
                <div className="row">
                  <div className="mixed-chart">
                    <Chart
                      options={this.state.options}
                      series={this.state.series}
                      type="line"
                      width="500"
                    />
                  </div>
                </div>
              </div>
            </div>

          </Grid>

          <Grid item xs={12} lg={6} md={6} sm={12}>
            <div style={{ border: "1px solid #ddd", paddingTop: "15px", height: "100%", backgroundColor: "white" }}>
              <Typography style={{ display: "flex", textAlign: "center", justifyContent: "center", fontSize: "20px" }}>Service Wise Transaction Report</Typography><br />

              <div style={{ display: "grid", justifyContent: "right", fontSize: "13px", marginTop: "4rem", marginRight: "3rem", cursor: "pointer" }}>
                <ol><button style={{ backgroundColor: "skyblue", border: "none", color: "skyblue", cursor: "pointer", }}>1</button>  AEPS Transaction</ol>
                <ol><button style={{ backgroundColor: "blue", border: "none", color: "blue", cursor: "pointer" }}>1</button>  Mobile Recharge</ol>
                <ol><button style={{ backgroundColor: "brown", border: "none", color: "brown", cursor: "pointer" }}>1</button>  DTH Recharge</ol>
                <ol><button style={{ backgroundColor: "yellow", border: "none", color: "yellow", cursor: "pointer" }}>1</button>  Elctricity Bill Payment</ol>
                <ol><button style={{ backgroundColor: "black", border: "none", color: "black", cursor: "pointer" }}>1</button>  Money Transfer</ol>
                <ol><button style={{ backgroundColor: "grey", border: "none", color: "grey", cursor: "pointer" }}>1</button>  Micro ATM</ol>
              </div>
            </div>

          </Grid>
        </Grid><br />

        <Recharge />
      </>
    );
  }
}

export default Mychart;
import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import { Link } from 'react-router-dom';


export default function Disputecmnt() {

    const [showModal, setShowModal] = useState(false);
    const [comment, setComment] = useState('');

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setComment('');
    };

    const handleInputChange = (event) => {
        setComment(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Submitted comment:', comment);
        handleCloseModal();
    };


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">
                                    Dispute Comment{' '}
                                    <button type="button" className="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" fdprocessedid="nw1xoj" onClick={handleShowModal}>
                                        NEW <i className="fa fa-plus"></i>
                                    </button>{' '}
                                </h1>

                                {showModal && (
                                    <div className="modal fade show" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" aria-modal="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <form onSubmit={handleSubmit}>
                                                    <input type="hidden" name="securityToken" value="bed1260933bb1ce5f2853ff164b25d88" />
                                                    <div className="modal-header">
                                                        <h4 className="modal-title" id="exampleModalLabel">
                                                            New Comment
                                                        </h4>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal} fdprocessedid="8mjxrg">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <label>Comment</label>
                                                        <input type="text" className="form-control" name="comment" value={comment} onChange={handleInputChange} placeholder="Enter your Comment" fdprocessedid="6k70hr" />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" onClick={handleCloseModal} fdprocessedid="iygvon">
                                                            Close
                                                        </button>
                                                        <button type="submit" className="btn btn-primary" fdprocessedid="rvd5ps">
                                                            Save
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Report</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Dispute Comment</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table m-0 table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Comment</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <form action="/disputecmnt" method="post" acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="bed1260933bb1ce5f2853ff164b25d88" />
                                            </form>
                                            <tr>
                                                <td>1</td>
                                                <input type="hidden" name="id" value="Vjc~" />
                                                <td>
                                                    <input type="text" className="form-control" name="comment" value="Recharge Not Success." required="" fdprocessedid="k2fph" />
                                                </td>
                                                <td className="text-center">
                                                    <button className="btn btn-sm btn-primary" fdprocessedid="hi9lta">
                                                        SAVE
                                                    </button>
                                                </td>
                                            </tr>
                                            <form action="/disputecmnt" method="post" acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="bed1260933bb1ce5f2853ff164b25d88" />
                                            </form>
                                            <tr>
                                                <td>2</td>
                                                <input type="hidden" name="id" value="DW8~" />
                                                <td>
                                                    <input type="text" className="form-control" name="comment" value="Transaction success but customer not received, Please refund transaction." required="" fdprocessedid="31bgs9" />
                                                </td>
                                                <td className="text-center">
                                                    <button className="btn btn-sm btn-primary" fdprocessedid="ivllcd">
                                                        SAVE
                                                    </button>
                                                </td>
                                            </tr>
                                            <form action="/disputecmnt" method="post" acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="bed1260933bb1ce5f2853ff164b25d88" />
                                            </form>
                                            <tr>
                                                <td>3</td>
                                                <input type="hidden" name="id" value="VTY~" />
                                                <td>
                                                    <input type="text" className="form-control" name="comment" value="Please recheck transaction and update status ." required="" fdprocessedid="jt3wmk" />
                                                </td>
                                                <td className="text-center">
                                                    <button className="btn btn-sm btn-primary" fdprocessedid="dmk4r">
                                                        SAVE
                                                    </button>
                                                </td>
                                            </tr>
                                            <form action="/disputecmnt" method="post" acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="bed1260933bb1ce5f2853ff164b25d88" />
                                            </form>
                                            <tr>
                                                <td>4</td>
                                                <input type="hidden" name="id" value="A2c~" />
                                                <td>
                                                    <input type="text" className="form-control" name="comment" value="Wrong number recharge processed, Please refund transaction." required="" fdprocessedid="ehjg5w" />
                                                </td>
                                                <td className="text-center">
                                                    <button className="btn btn-sm btn-primary" fdprocessedid="8xqd99">
                                                        SAVE
                                                    </button>
                                                </td>
                                            </tr>
                                            <form action="/disputecmnt" method="post" acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="bed1260933bb1ce5f2853ff164b25d88" />
                                            </form>
                                            <tr>
                                                <td>5</td>
                                                <input type="hidden" name="id" value="VjM~" />
                                                <td>
                                                    <input type="text" className="form-control" name="comment" value="Recharge operator is different from original operator." required="" fdprocessedid="dgrqdr" />
                                                </td>
                                                <td className="text-center">
                                                    <button className="btn btn-sm btn-primary" fdprocessedid="1lcdiq">
                                                        SAVE
                                                    </button>
                                                </td>
                                            </tr>
                                            <form action="/disputecmnt" method="post" acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="bed1260933bb1ce5f2853ff164b25d88" />
                                            </form>
                                            <tr>
                                                <td>6</td>
                                                <input type="hidden" name="id" value="VjA~" />
                                                <td>
                                                    <input type="text" className="form-control" name="comment" value="Other." required="" fdprocessedid="u4j1qo" />
                                                </td>
                                                <td className="text-center">
                                                    <button className="btn btn-sm btn-primary" fdprocessedid="t8w7rf">
                                                        SAVE
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { useState, useEffect } from 'react';
import { FaFileExcel, FaCopy, FaEye, FaEdit } from "react-icons/fa";
import RestService from '../http';
import formatDate from './utilityComponents/FormatDate';
import showToast from './utilityComponents/APPToast';
import DatePicker from "react-datepicker";
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import Pagination2 from './utilityComponents/Pagination2';


export default function Deactivemember() {

    const service = new RestService();
    const [date, setDate] = useState('');
    const [date1, setDate1] = useState('');
    const [mobile, setMobile] = useState('');
    const [downlineUserList, setDownlineUserList] = useState([]);
    const [filteredUserDataReport, setFilteredUserDataReport] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleShowData = async () => {

        var raw = {
            "mobile": mobile,
            "startDate": formatDate(date),
            "endDate": formatDate(date1)
        }

        const result = await service.post("user/viewDownlineByMobile", raw);
        console.log(result);

        if (result.status === service.SUCCESS) {
            setDownlineUserList(result.data);

        } else {
            showToast("DANGER", result.message);
        }
    }

    useEffect(() => {
        const filteredList = downlineUserList.filter(item =>
            item.mobile.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.aadhaar.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.createdDate.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.pan.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.pinCode.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.wlId.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.userId.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.name.toLowerCase().includes(searchInput.toLowerCase()) 

        );
        setFilteredUserDataReport(filteredList);
    }, [downlineUserList, searchInput]);

    const paginatedUserDataReport = filteredUserDataReport.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <>
            <Navbar />
            <ToastContainer />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1 class="m-0 text-dark">Downline Fetch</h1>
                            </div>
                            <div class="col-sm-6">
                                <ol class="breadcrumb float-sm-right">
                                    <li class="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li class="breadcrumb-item"><Link to="#">DownLine Fetch</Link></li>
                                    <li class="breadcrumb-item active">Downline Fetch</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div>
                                    <div>
                                        <input type="hidden" name="securityToken" value="55b9bc60523c0753e329ec7da5c677c6" />
                                        <div className="row">
                                            <div className="col-md-3" style={{ float: "left" }}>
                                                <div>
                                                    <label style={{ fontSize: "13px", float: "left" }}>SEARCH DOWNLINE BY MOBILE</label>
                                                    <input
                                                        className="form-control text-box"
                                                        type="text"
                                                        placeholder="SEARCH DOWNLINE BY MOBILE"
                                                        onChange={(e) => setMobile(e.target.value)}
                                                    />
                                                </div>

                                            </div>

                                            <div className="col-md-3">
                                                <label for="rec_to_load"> Start Date</label><br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    placeholderText='Select date'
                                                    onChange={(date) => setDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label for="rec_to_load"> End Date</label>
                                                <br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    disabledDayAriaLabelPrefix="#$"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date1}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    placeholderText='Select date'
                                                    onChange={(date) => setDate1(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3 mt-3 pt-3">
                                                <input
                                                    type="button"
                                                    className="btn btn-primary btn-sm mr-2"
                                                    value="SEARCH"
                                                    fdprocessedid="q2im1m"
                                                    style={{ marginTop: "2%", padding: "5px" }}
                                                    onClick={handleShowData}

                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="table-responsive">
                                    <center>
                                        <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                            <div>

                                                <div className="col-md-3" style={{ float: "left" }}>
                                                    <div>
                                                        <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                        <input
                                                            className="form-control text-box"
                                                            type="text"
                                                            placeholder="SEARCH..."
                                                            value={searchInput}
                                                            onChange={(e) => setSearchInput(e.target.value)}

                                                        />
                                                    </div>
                                                </div><br />

                                                <Pagination2
                                                    totalPages={Math.ceil(downlineUserList.length / itemsPerPage)}
                                                    currentPage={currentPage}
                                                    onChange={handlePageChange}

                                                />
                                            </div>
                                        </div>
                                    </center>

                                    <div
                                        id="example1_wrapper"
                                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                                    >

                                        <div className="dt-buttons">
                                            <a
                                                className="dt-button buttons-copy buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="Copy"
                                            >
                                                <span>
                                                    <FaCopy className="fa fa-files-o text-info font-weight-bold" />
                                                </span>
                                            </a>
                                            <a
                                                className="dt-button buttons-excel buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="Excel"
                                            >
                                                <span>
                                                    <FaFileExcel className="fa fa-file-excel-o text-success font-weight-bold" />
                                                </span>
                                            </a>

                                        </div>
                                        <table
                                            id="example1"
                                            className="table m-0 table-striped table-bordered dataTable no-footer"
                                            role="grid"
                                            aria-describedby="example1_info"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Role Id</th>
                                                    <th>User Id</th>
                                                    <th>Wlid</th>
                                                    <th>Name</th>
                                                    <th>Firm Name</th>
                                                    <th>Address</th>
                                                    <th>City</th>
                                                    <th>Pin Code</th>
                                                    <th>state</th>
                                                    <th>Country</th>
                                                    <th>Mobile</th>
                                                    <th>Upline Id</th>
                                                    <th>Email</th>
                                                    <th>Created Date</th>
                                                    <th>Created Time</th>
                                                    <th>Balance</th>
                                                    <th>Status</th>
                                                    <th>Locked Amt.</th>
                                                    <th>Pan</th>
                                                    <th>Aadhaar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(!paginatedUserDataReport || paginatedUserDataReport.length === 0) ? (
                                                    <tr className='text-center'>
                                                        <td colSpan="22">NO DATA AVAILABLE</td>
                                                    </tr>
                                                ) : (
                                                    paginatedUserDataReport.map((option, index) => (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{service.roles[option.roleId]}</td>
                                                            <td>{option.userId}</td>
                                                            <td>{option.wlId}</td>
                                                            <td>{option.name}</td>
                                                            <td>{option.firmName}</td>
                                                            <td>{option.address}</td>
                                                            <td>{option.city}</td>
                                                            <td>{option.pinCode}</td>
                                                            <td>{option.state}</td>
                                                            <td>{option.country}</td>
                                                            <td>{option.mobile}</td>
                                                            <td>{option.uplineId}</td>
                                                            <td>{option.email}</td>
                                                            <td>{option.createdDate}</td>
                                                            <td>{option.createdTime}</td>
                                                            <td>{option.balance}</td>
                                                            <td>{option.status}</td>
                                                            <td>{option.loackedAmount}</td>
                                                            <td>{option.pan}</td>
                                                            <td>{option.aadhaar}</td>
                                                        </tr>

                                                    ))
                                                )}
                                            </tbody>
                                        </table>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

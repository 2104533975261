import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import RestService from "../http";
import "react-datepicker/dist/react-datepicker.css";
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';
import Select from 'react-select';
import Pagination2 from './utilityComponents/Pagination2';
import { Link } from 'react-router-dom';


export default function AddMatmAgent() {

    const [selectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();
    const [userList, setUserList] = useState([]);
    const [userId, setUserId] = useState("");
    const [roles, setRoles] = useState([]);
    const [agentCode, setAgentCode] = useState("");
    const [agentPassword, setAgentPassWord] = useState("");
    const [bankSwitch, setBankSwitch] = useState("");
    const [agentList, setAgentList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const itemsPerPage = 10;
    const [filteredAgentReport, setFilteredAgentReport] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);



    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);


    const service = new RestService();


    const handleAddMatmAgent = () => {
        var raw = {
            "userId": userId,
            "agentCode": agentCode,
            "agentPassword": agentPassword,
            "bankSwitch": bankSwitch
        }

        setLoading(true);

        service.post("matm/addMatmAgent", raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                handleMatmAgentReport();

            } else {
                showToast("DANGER", result.message)
            }

        }).catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        service.get("user/roles").then(result => {
            console.log(result);
            setRoles(result.role);

        }).catch((error) => {
            console.log(error);
        });
    }, []);


    const handleCustomerType = (e) => {
        console.log(e.target.value);
        service
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setUserList(result.data);
                } else {
                    setUserList([]);
                }
            })
            .catch((err) => {
                console.error(err);
                setUserList([]);
            });
    };


    const handleMatmAgentReport = () => {
        service.post("matm/fetchMicroAtmAgent", null).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setAgentList(result.data);
                
            } else {
                showToast("DANGER", result.message);
                setAgentList([]);

            }
        })
    }

    useEffect(() => {
        handleMatmAgentReport();
    }, []);


    const updateStatus = (userId, status) => {
        let request = {
            "userId": userId,
            "status": !status
        };

        setLoading(true);

        service.post("matm/updateAgentStatus", request).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                handleMatmAgentReport();

            } else {
                showToast("FAILED", result.message);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const [selectedUserId, setSelectedUserId] = useState(null);

    const options = [
        { value: '', label: 'Select an option' },
        ...userList.map((item) => ({
            value: item.userId,
            label: `${item.name} || ${item.mobile}`,
        }))
    ];

    const handleSelectChange = (selectedUserId) => {
        setSelectedUserId(selectedUserId);
        setUserId(selectedUserId.value);
    };


    // const filterData = (input) => {
    //     setSearchInput(input);
    //     const filteredList = agentList.filter(item =>
    //         item.userid.toLowerCase().includes(input.toLowerCase()) ||
    //         item.agentId.toLowerCase().includes(input.toLowerCase()) ||
    //         item.agentPassword.toLowerCase().includes(input.toLowerCase()) ||
    //         item.date.toLowerCase().includes(input.toLowerCase())

    //     );
    //     setFilteredList(filteredList);
    // }

    useEffect(() => {
        const filteredList = agentList.filter(item =>
            item.userid === null ? "NA" : item.userid.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.agentId === null ? "NA" : item.agentId.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.agentPassword === null ? "NA" : item.agentPassword.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.date === null ? "NA" : item.date.toLowerCase().includes(searchInput.toLowerCase())

        );
        
        setFilteredAgentReport(filteredList);
    }, [agentList, searchInput]);

    const paginatedAgentReport = filteredAgentReport.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}


            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">MICRO ATM & AADHARPAY AGENT </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">MATM</Link>
                                    </li>
                                    <li className="breadcrumb-item active">MATM & AADHARPAY Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">

                                            <div className="col-md-3">
                                                <label for="assign_commission">
                                                    User Type: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleCustomerType}
                                                >
                                                    <option>Select User Type</option>
                                                    {roles.map((item) => (
                                                        item.roleId !== 0 ? <option value={item.roleId}>{item.roleName}</option> : null
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="service_id">
                                                    User Id: <span className="text-danger"> *</span>
                                                </label>
                                                {/* <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setUserId(e.target.value)}
                                                >
                                                    <option value="">Select Id...</option>
                                                    {userList.map((item) => (
                                                        <option value={item.userId}>{item.name}</option>
                                                    ))}
                                                </select> */}

                                                <Select
                                                    className="select-box width100p"
                                                    options={options}
                                                    isSearchable
                                                    value={selectedUserId}
                                                    onChange={handleSelectChange}
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label className="font-weight-normal">
                                                    Agent Code <span className="text-danger"> *</span>
                                                </label>
                                                <input
                                                    name="agent_code"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Agent Code"
                                                    onChange={(e) => setAgentCode(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label className="font-weight-normal">
                                                    Agent Password <span className="text-danger"> *</span>
                                                </label>
                                                <input
                                                    name="agent_pass"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Agent Password"
                                                    onChange={(e) => setAgentPassWord(e.target.value)}
                                                    maxLength={6}
                                                />

                                            </div>

                                            <div className="col-md-3 mt-2">
                                                <label className="font-weight-normal">
                                                    Bank Switch <span className="text-danger"> *</span>
                                                </label>
                                                <input
                                                    name="bank_switch"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Bank Switch"
                                                    onChange={(e) => setBankSwitch(e.target.value)}
                                                />
                                            </div>

                                            <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                                            <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ textAlign: 'center' }}>
                                                <button
                                                    onClick={handleAddMatmAgent}
                                                    className="btn btn-primary btn-block bg-success"
                                                    style={{ marginTop: '37px', color: 'white', fontWeight: 600 }}
                                                    fdprocessedid="qnf2"
                                                >
                                                    ADD
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                <div className="table-responsive">

                                    <center>
                                        <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                            <div>
                                                <div className="col-md-3" style={{ float: "left" }}>
                                                    <div>
                                                        <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                        <input
                                                            className="form-control text-box"
                                                            type="text"
                                                            placeholder="SEARCH..."
                                                            value={searchInput}
                                                            onChange={(e) => setSearchInput(e.target.value)}
                                                        />
                                                    </div>
                                                </div><br />

                                                <Pagination2
                                                    totalPages={Math.ceil(agentList.length / itemsPerPage)}
                                                    currentPage={currentPage}
                                                    onChange={handlePageChange}

                                                />
                                            </div>
                                        </div>
                                    </center><br />

                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr className="wrap-text text-center">
                                                <th className="px-1" style={{ fontWeight: "bold" }}>SL.NO</th>
                                                <th style={{ fontWeight: "bold" }}>USER ID</th>
                                                <th style={{ fontWeight: "bold" }}>AGENT ID</th>
                                                <th style={{ fontWeight: "bold" }}>AGENT PASSWORD</th>
                                                {/* <th className="px-1">BANK SWITCH</th> */}
                                                <th style={{ fontWeight: "bold" }}>DATE</th>
                                                <th style={{ fontWeight: "bold" }}>TIME</th>
                                                <th style={{ fontWeight: "bold" }}>STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!paginatedAgentReport || paginatedAgentReport.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="8">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                paginatedAgentReport.map((item, index) => (
                                                    <tr className='text-center'>
                                                        <td key={index}>{index + 1}</td>
                                                        <td>{item.userid}</td>
                                                        <td>{item.agentId}</td>
                                                        <td>{item.agentPassword}</td>
                                                        {/* <td>{item.bankSwitch}</td> */}
                                                        <td>{item.date}</td>
                                                        <td>{item.time}</td>
                                                        <td>
                                                            {item.status ? (
                                                                <span
                                                                    onClick={() =>
                                                                        updateStatus(
                                                                            item.userid,
                                                                            item.status,
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge badge-success"
                                                                >
                                                                    ACTIVE
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    onClick={() =>
                                                                        updateStatus(
                                                                            item.userid,
                                                                            item.status,
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge badge-danger"
                                                                >
                                                                    INACTIVE
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>

                                                ))
                                            )}
                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <td
                                                    colSpan="4"
                                                    style={{ fontWeight: "bold" }}
                                                    className="text-primary"
                                                    rowSpan="1"
                                                >
                                                    Row Count {agentList.length}
                                                </td>

                                            </tr>
                                        </tfoot>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <footer className="main-footer" >
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

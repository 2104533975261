import React from "react";

const Pagination = ({ nPages, currentPage, setCurrentPage, setPageSize, setFilterData }) => {
  let startPage = 1;
  let endPage = Math.min(nPages, 5);
  if (currentPage > 3) {
    startPage = currentPage - 2;
    endPage = Math.min(currentPage + 2, nPages);
  }

  const pageNumbers = [...Array(endPage - startPage + 1).keys()].map(i => startPage + i);

  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(nPages);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-2">
            <label>Show Entries</label>
            <select
              name="example1_length"
              aria-controls="example1"
              className="custom-select custom-select-sm form-control form-control-sm"
              fdprocessedid="6lzt2"
              onChange={(e) => setPageSize(e.target.value, currentPage)}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={10000}>All</option>
            </select>{" "}
          </div>

          <div className="col-md-6">
            <div
              className="dataTables_length"
              id="example1_length"
              style={{
                paddingTop: "1rem",
              }}
            >
              <nav>
                <ul className="pagination justify-content-center">
                  <li className="page-item">
                    <a className="page-link" onClick={goToFirstPage} href="#">
                      First
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" onClick={goToPrevPage} href="#">
                      Previous
                    </a>
                  </li>
                  {pageNumbers.map((pgNumber) => (
                    <li
                      key={pgNumber}
                      className={`page-item ${currentPage === pgNumber ? "active" : ""
                        } `}
                    >
                      <a
                        onClick={() => setCurrentPage(pgNumber)}
                        className="page-link"
                        href="#"
                      >
                        {pgNumber}
                      </a>
                    </li>
                  ))}
                  {nPages > 5 && currentPage < nPages - 1 && (
                    <li className="page-item">
                      <a className="page-link" onClick={goToLastPage} href="#">
                        End..
                      </a>
                    </li>
                  )}
                  <li className="page-item">
                    <a className="page-link" onClick={goToNextPage} href="#">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="rec_to_load"> Search</label>
            <input
              type="text"
              className="form-control"
              name="string"
              placeholder="Search Here"
              fdprocessedid="9n5i4u"
              onChange={(e) => setFilterData(e.target.value)}
            />
          </div>
        </div>
        <br />
      </div>
    </>
  );
};

export default Pagination;

import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Emailconfig() {

    const [formData, setFormData] = useState({
        mail_id: 'meghacom2021@gmail.com',
        name: 'Megha Communication',
        smtp_host: 'test',
        smtp_password: 'test',
        smtp_port: 'test',
        protocol: 'test',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here (e.g., send data to the server)
        console.log(formData);
    };

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "232.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">Email Config </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Settings</Link></li>
                                    <li className="breadcrumb-item active">Email Configuration</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card col-md-10 m-auto">
                                    <form onSubmit={handleSubmit}>
                                        <input type="hidden" name="securityToken" value="3a57dd61812290cf4269b7af078fa7e1" />
                                        <div className="card-body row">
                                            <div className="col-12 mt-2">
                                                <label>Email :</label>
                                                <input type="email" name="mail_id" required className="form-control" placeholder="Enter email" value={formData.mail_id} onChange={handleChange} />
                                            </div>
                                            <div className="col-12 mt-2">
                                                <label>Name :</label>
                                                <input type="text" name="name" required className="form-control" placeholder="Enter name" value={formData.name} onChange={handleChange} />
                                            </div>
                                            <div className="col-12 mt-2">
                                                <label>SMTP Host :</label>
                                                <input type="text" name="smtp_host" required className="form-control" placeholder="Enter host" value={formData.smtp_host} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <label>Mail Password :</label>
                                                <input type="text" name="smtp_password" required className="form-control" placeholder="Enter password" value={formData.smtp_password} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <label>SMTP Port :</label>
                                                <input type="text" name="smtp_port" required className="form-control" placeholder="Enter port no" value={formData.smtp_port} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <label>Protocol :</label>
                                                <input type="text" name="protocol" required className="form-control" placeholder="Enter protocol" value={formData.protocol} onChange={handleChange} />
                                            </div>
                                            <div className="col-12 mt-3 text-center">
                                                <button type="submit" className="btn btn-sm btn-primary">SAVE</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card col-md-8 m-auto">
                                    <form action="/emailconfig" method="post" acceptCharset="utf-8">
                                        <input type="hidden" name="securityToken" value="3a57dd61812290cf4269b7af078fa7e1" />
                                        <div className="card-body row">
                                            <div className="col-12 mt-2">
                                                <label>Email :</label>
                                                <input type="email" name="email" required className="form-control" placeholder="Enter email" />
                                            </div>
                                            <div className="col-12 mt-3 text-center">
                                                <button className="btn btn-danger btn-sm" type="submit">TEST</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { useState, useEffect } from 'react';
import RestService from "../http";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from 'sweetalert';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from './Loader';


export default function Editapicommison() {

    const service = new RestService();
    const [isSessionExpired, setIsSessionExpired] = useState(false);
    const navigate = useNavigate();
    const [updateApi, setUpdateApi] = useState('');
    const location = useLocation();
    console.log(location.state);
    const [apiList, setApiList] = useState(location.state)
    const [apiId, setapiId] = useState(apiList.apiId);
    const [apiName, setApiName] = useState(apiList.apiName);
    const [apiUserName, setApiUserName] = useState(apiList.username);
    const [apiPassword, setApiPassword] = useState(apiList.password);
    const [baseUrl, setBaseUrl] = useState(apiList.url);
    const [serviceType, setServiceType] = useState(apiList.serviceType);
    const [credential, setCredential] = useState(apiList.credential);
    const [loading, setLoading] = useState(false);


    const sessionExpiretionPopup = () => {
        if (isSessionExpired) {
            swal({
                title: "Session Expired",
                text: "Your login session has expired. Please log in again.",
                icon: "warning",
                closeOnClickOutside: false,
                buttons: {
                    confirm: {
                        text: "OK",
                        className: "btn btn-primary",
                    },
                },
                dangerMode: true,
            }).then((confirmed) => {
                if (confirmed) {
                    window.location.href = "/";
                    sessionStorage.removeItem("token");
                    sessionStorage.removeItem("user");
                }
            });
        }
    };
    useEffect(() => {
        sessionExpiretionPopup();
    }, [sessionExpiretionPopup]);


    const showToast = (status, message) => {
        if (status === "SUCCESS") {
            toast.success(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else if (status === "DANGER") {
            toast.error(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.warn(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handleApiId = (e) => {
        const { value } = e.target;
        setapiId(value);
    }

    const handleApiName = (e) => {
        const { value } = e.target;
        setApiName(value);
    }

    const handleApiUserName = (e) => {
        const { value } = e.target;
        setApiUserName(value);
    }

    const handlePassword = (e) => {
        const { value } = e.target;
        setApiPassword(value);
    }

    const handleBaseUrl = (e) => {
        const { value } = e.target;
        setBaseUrl(value);
    }

    const handleServiceType = (e) => {
        const { value } = e.target;
        setServiceType(value);
    }

    const handleCredential = (e) => {
        const { value } = e.target;
        setCredential(value);
    }

    const handleUpdateApi = () => {
        var raw = {
            "apiId": apiId,
            "apiname": apiName,
            "username": apiUserName,
            "password": apiPassword,
            "baseUrl": baseUrl,
            "serviceType": serviceType,
            "credential": credential
        };

        setLoading(true);

        service.post("api/editApi", raw).then(result => {
            setLoading(false);

            setUpdateApi(result.report);
            if (result.status === 1) {
                showToast("SUCCESS", result.message);
                setTimeout(() => {
                    navigate({
                        pathname: "/apicommison",
                    });
                }, 2000);

            } else {
                showToast("FAILED", result.message);
            }
        })
    }

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}


            <div className="content-wrapper" style={{ minHeight: '525.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">API &amp; Commission</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Settings</Link></li>
                                    <li className="breadcrumb-item"><Link to="/apicommison">API</Link></li>
                                    <li className="breadcrumb-item active">Edit API</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className='content'>
                    <div className='container-fluid'>
                        <div className='card'>
                            <div card-body>
                                <div id='accordian'>

                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h4 className="card-title">
                                                <Link data-toggle="collapse" data-parent="#accordion" to="#collapseOne" className="" aria-expanded="false">
                                                    Api Details
                                                </Link>
                                            </h4>
                                        </div>
                                        <div id="collapseOne" className="panel-collapse in collapse show">
                                            <div className="card-body">
                                                <div>
                                                    <input type="hidden" name="securityToken" value="adb04c72b8d26840ba808fd10a739df7" />

                                                    <div className="row">

                                                        <div className="col-md-3">
                                                            <label htmlFor="api_name">API Id: </label>
                                                            <input
                                                                type="text"
                                                                name="api_name"
                                                                value={apiList.apiId    }
                                                                autoFocus
                                                                className="form-control"
                                                                id="api_name"
                                                                placeholder="API Id"
                                                                onChange={handleApiId}
                                                            />
                                                        </div>

                                                        <div className="col-md-3">
                                                            <label htmlFor="api_name">API Name: </label>
                                                            <input
                                                                type="text"
                                                                name="api_name"
                                                                value={apiName}
                                                                className="form-control"
                                                                id="api_name"
                                                                placeholder="Enter API Name"
                                                                onChange={handleApiName}
                                                            />

                                                        </div>

                                                        <div className="col-md-3">
                                                            <label htmlFor="api_name">User Name: </label>
                                                            <input
                                                                type="text"
                                                                name="api_name"
                                                                value={apiUserName}
                                                                className="form-control"
                                                                id="api_name"
                                                                placeholder="Enter User Name"
                                                                onChange={handleApiUserName}
                                                            />
                                                        </div>

                                                        <div className="col-md-3">
                                                            <label htmlFor="api_name">Password: </label>
                                                            <input
                                                                type="text"
                                                                name="api_name"
                                                                value={apiPassword}
                                                                className="form-control"
                                                                id="api_name"
                                                                placeholder="Enter Password"
                                                                onChange={handlePassword}
                                                            />
                                                        </div>

                                                        <div className="col-md-3 mt-2">
                                                            <label htmlFor="api_name">Base Url: </label>
                                                            <input
                                                                type="text"
                                                                name="api_name"
                                                                value={baseUrl}
                                                                className="form-control"
                                                                id="api_name"
                                                                placeholder="Base Url"
                                                                onChange={handleBaseUrl}
                                                            />
                                                        </div>

                                                        <div className="col-md-3 mt-2">
                                                            <label htmlFor="api_name">Service Type: </label>
                                                            <input
                                                                type="text"
                                                                name="api_name"
                                                                value={apiList.serviceType}
                                                                className="form-control"
                                                                id="api_name"
                                                                placeholder="Service Type"
                                                                onChange={handleServiceType}
                                                            />
                                                        </div>

                                                        <div className="col-md-3 mt-2">
                                                            <label htmlFor="api_name">Credential: </label>
                                                            <input
                                                                type="text"
                                                                name="api_name"
                                                                value={credential}
                                                                className="form-control"
                                                                id="api_name"
                                                                placeholder="Credential"
                                                                onChange={handleCredential}
                                                            />
                                                        </div>

                                                        <div className="col-md-12 text-center mt-4">
                                                            <button onClick={handleUpdateApi} className="btn btn-primary">SAVE</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card card-primary dynamic d-none" style={{ display: 'none' }} >
                                        <div className="card-header">
                                            <h4 className="card-title">
                                                <Link data-toggle="collapse" data-parent="#accordion" to="#collapseTwo" className="collapsed" aria-expanded="false">
                                                    Balance URL
                                                </Link>
                                            </h4>
                                        </div>
                                        <div id="collapseTwo" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <button type="button" className="btn btn-default btn-sm m-1"><i className="fas fa-share"></i> Variable to share </button>
                                                <div className="card-footer card-comments col-6">
                                                    <div className="card-comment">
                                                        <div className="comment-text ml-0">
                                                            <span className="username">
                                                                @optional1 : For Any Kind of Request ID
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form action="#" method="post" acceptCharset="utf-8">
                                                    <input type="hidden" name="securityToken" value="adb04c72b8d26840ba808fd10a739df7" />
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label>URL </label>
                                                            <textarea className="form-control" name="url" required placeholder="Enter URL"></textarea>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>Headers</label>
                                                            <textarea className="form-control" name="headers" placeholder="Enter headers"></textarea>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Method </label>
                                                            <select name="method" className="form-control" required>
                                                                <option value="">Select Method</option>
                                                                <option value="1">GET</option>
                                                                <option value="2">POST</option>
                                                                <option value="3">PATCH</option>
                                                                <option value="4">DELETE</option>
                                                                <option value="5">PUT</option>
                                                                <option value="6">Both GET &amp; POST</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-3 col-md-3">
                                                            <label>Body Type </label>
                                                            <select name="body_type" className="form-control">
                                                                <option value="">Select Type</option>
                                                                <option value="2">x-www-form-urlencoded</option>
                                                                <option value="3">raw</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-3 col-md-3">
                                                            <label>Query String </label>
                                                            <textarea name="query_string" className="form-control" placeholder="Enter string"></textarea>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Response Type</label>
                                                            <select name="response_type" id="response_type" className="form-control" required>
                                                                <option value="">Select Response Type</option>
                                                                <option value="1">JSON</option>
                                                                <option value="2">XML</option>
                                                                <option value="3">STRING</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Separator</label>
                                                            <input type="text" className="form-control" name="separator" placeholder="Enter separator" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Status Key</label>
                                                            <input type="text" className="form-control" name="success_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Success Value</label>
                                                            <input type="text" className="form-control" name="success_value" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Balance Key</label>
                                                            <input type="text" className="form-control" name="balance_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3" style={{ marginTop: '55px' }}>
                                                            <div className="icheck-primary d-inline">
                                                                <input type="checkbox" id="checkboxPrimary1" value="1" name="is_array_balance" />
                                                                <label htmlFor="checkboxPrimary1">Is array balance</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3" style={{ marginTop: '55px' }}>
                                                            <div className="icheck-primary d-inline">
                                                                <input type="checkbox" id="data_object_balance" value="1" name="is_object" />
                                                                <label htmlFor="data_object_balance">Is Object</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Object Key</label>
                                                            <input type="text" className="form-control" name="object_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Object Key Includes</label>
                                                            <select name="object_keys[]" multiple className="form-control select2 select2-hidden-accessible" data-placeholder="Select Object Keys" tabIndex="-1" aria-hidden="true">
                                                                <option value="1">STATUS</option>
                                                                <option value="2">OPERATOR REF</option>
                                                                <option value="3">MESSAGE</option>
                                                                <option value="4">API TXN ID</option>
                                                                <option value="5">ROFFER</option>
                                                                <option value="6">BALANCE</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3 text-center">
                                                        <input type="submit" className="btn btn-primary" value="SAVE" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card card-primary dynamic d-none" style={{ display: 'none' }} >
                                        <div className="card-header">
                                            <h4 className="card-title">
                                                <Link data-toggle="collapse" data-parent="#accordion" to="#collapseThree" className="collapsed" aria-expanded="false">
                                                    Check Status URL
                                                </Link>
                                            </h4>
                                        </div>
                                        <div id="collapseThree" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <button type="button" className="btn btn-default btn-sm m-1"><i className="fas fa-share"></i> Variable to share </button>
                                                <div className="card-footer card-comments ">
                                                    <div className="card-comment">
                                                        <div className="comment-text ml-0">
                                                            <span className="username">
                                                                1. @txn_id: For SOFTMINT system unique transaction id. 2.@number: For SOFTMINT system recharge number. 3.@amount: For SOFTMINT system recharge amount.
                                                                4.@api_txn_id: Client Transaction ID.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form action="#" method="post" acceptCharset="utf-8">
                                                    <input type="hidden" name="securityToken" value="adb04c72b8d26840ba808fd10a739df7" />
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label>URL </label>
                                                            <textarea className="form-control" name="url" required placeholder="Enter URL"></textarea>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>Headers</label>
                                                            <textarea className="form-control" name="headers" placeholder="Enter headers"></textarea>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Method </label>
                                                            <select name="method" className="form-control" required>
                                                                <option value="">Select Method</option>
                                                                <option value="1">GET</option>
                                                                <option value="2">POST</option>
                                                                <option value="3">PATCH</option>
                                                                <option value="4">DELETE</option>
                                                                <option value="5">PUT</option>
                                                                <option value="6">Both GET &amp; POST</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-3 col-md-3">
                                                            <label>Body Type </label>
                                                            <select name="body_type" className="form-control">
                                                                <option value="">Select Type</option>
                                                                <option value="2">x-www-form-urlencoded</option>
                                                                <option value="3">raw</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-3 col-md-3">
                                                            <label>Query String </label>
                                                            <textarea name="query_string" className="form-control" placeholder="Enter string"></textarea>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Response Type</label>
                                                            <select name="response_type" id="response_type1" className="form-control" required>
                                                                <option value="">Select Response Type</option>
                                                                <option value="1">JSON</option>
                                                                <option value="2">XML</option>
                                                                <option value="3">STRING</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Separator</label>
                                                            <input type="text" className="form-control" name="separator" placeholder="Enter separator" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Status Key</label>
                                                            <input type="text" className="form-control" name="status_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Success Value</label>
                                                            <input type="text" className="form-control" name="success_value" placeholder="Enter Value" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Failure Value</label>
                                                            <input type="text" className="form-control" name="failure_value" placeholder="Enter Value" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Message Key</label>
                                                            <input type="text" className="form-control" name="message_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Operator Reference Key</label>
                                                            <input type="text" className="form-control" name="ref_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3" style={{ marginTop: '55px' }}>
                                                            <div className="icheck-primary d-inline">
                                                                <input type="checkbox" id="is_object_status" value="1" name="is_object" />
                                                                <label htmlFor="is_object_status">Is Object</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Object Key</label>
                                                            <input type="text" className="form-control" name="object_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Object Key Includes</label>
                                                            <select name="object_keys[]" multiple className="form-control select2 select2-hidden-accessible" data-placeholder="Select Object Keys" tabIndex="-1" aria-hidden="true">
                                                                <option value="1">STATUS</option>
                                                                <option value="2">OPERATOR REF</option>
                                                                <option value="3">MESSAGE</option>
                                                                <option value="4">API TXN ID</option>
                                                                <option value="5">ROFFER</option>
                                                                <option value="6">BALANCE</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3 text-center">
                                                        <input type="submit" className="btn btn-primary" value="SAVE" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card card-primary dynamic" style={{ display: 'none' }} >
                                        <div className="card-header">
                                            <h4 className="card-title">
                                                <Link data-toggle="collapse" data-parent="#accordion" to="#collapseFour" className="collapsed" aria-expanded="false">
                                                    Callback URL
                                                </Link>
                                            </h4>
                                        </div>
                                        <div id="collapseFour" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <button type="button" className="btn btn-default btn-sm m-1">
                                                    <i className="fas fa-share"></i> Variable to Used
                                                </button>
                                                <form action="#" method="post" acceptCharset="utf-8">
                                                    <input type="hidden" name="securityToken" value="adb04c72b8d26840ba808fd10a739df7" />
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label>URL</label>
                                                            <textarea className="form-control" name="url" required placeholder="Enter URL"></textarea>
                                                            <span>
                                                                <i className="fa fa-hand-o-right" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Method</label>
                                                            <select name="method" className="form-control" required>
                                                                <option value="">Select Method</option>
                                                                <option value="1">GET</option>
                                                                <option value="2">POST</option>
                                                                <option value="3">PATCH</option>
                                                                <option value="4">DELETE</option>
                                                                <option value="5">PUT</option>
                                                                <option value="6">Both GET &amp; POST</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-3 col-md-3">
                                                            <label>Body Type</label>
                                                            <select name="body_type" className="form-control">
                                                                <option value="">Select Type</option>
                                                                <option value="2">x-www-form-urlencoded</option>
                                                                <option value="3">raw</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Data Type</label>
                                                            <select name="response_type" id="response_type2" className="form-control">
                                                                <option value="">Select Data Type</option>
                                                                <option value="1">JSON</option>
                                                                <option value="2">XML</option>
                                                                <option value="3">STRING</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>System Txn ID Variable</label>
                                                            <input type="text" className="form-control" name="txn_id_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Status Key</label>
                                                            <input type="text" className="form-control" name="status_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Success Value</label>
                                                            <input type="text" className="form-control" name="success_value" placeholder="Enter Value" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Failure Value</label>
                                                            <input type="text" className="form-control" name="failure_value" placeholder="Enter Value" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Message Key</label>
                                                            <input type="text" className="form-control" name="message_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Operator Reference Key</label>
                                                            <input type="text" className="form-control" name="ref_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>API Txn Id Key</label>
                                                            <input type="text" className="form-control" name="api_txn_id_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>
                                                                IP Address <span className="text-danger">*</span> Allowed callback only from this IP
                                                            </label>
                                                            <input type="text" className="form-control" name="ip" placeholder="Enter ip" value="" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-3 text-center">
                                                        <input type="submit" className="btn btn-primary" value="SAVE" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card card-primary dynamic" style={{ display: 'none' }} >
                                        <div className="card-header">
                                            <h4 className="card-title">
                                                <Link data-toggle="collapse" data-parent="#accordion" to="#collapseFive" className="collapsed" aria-expanded="false">
                                                    Recharge API
                                                </Link>
                                            </h4>
                                        </div>
                                        <div id="collapseFive" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <button type="button" className="btn btn-default btn-sm m-1">
                                                    <i className="fas fa-share"></i> Variable to Use
                                                </button>
                                                <div className="card-footer card-comments ">
                                                    <div className="card-comment">
                                                        <div className="comment-text ml-0">
                                                            <span className="username">
                                                                1.@number : Recharge Number
                                                                2.@operator : Operator Code
                                                                3.@circle : Circle Code
                                                                4.@amount : Recharge amount.
                                                                5.@txn_id : Unique transaction ID
                                                                6.@optional1 : Optional Parameter 1.
                                                                7.@optional2 : Optional Parameter 2.
                                                                8.@optional3 : Optional Parameter 3.
                                                                9.@optional4 : Optional Parameter 4.
                                                                10.@stv : If Stv Parameter.
                                                                11.@onlyroffer : For Only roffer recharge
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form action="#" method="post" acceptCharset="utf-8">
                                                    <input type="hidden" name="securityToken" value="adb04c72b8d26840ba808fd10a739df7" />
                                                    <div className="row">
                                                        <input type="hidden" name="url_type" value="1" />
                                                        <div className="col-md-6">
                                                            <label>URL</label>
                                                            <textarea className="form-control" name="url" required placeholder="Enter URL"></textarea>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>Headers</label>
                                                            <textarea className="form-control" name="headers" placeholder="Enter headers"></textarea>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Method</label>
                                                            <select name="method" className="form-control" required>
                                                                <option value="">Select Method</option>
                                                                <option value="1">GET</option>
                                                                <option value="2">POST</option>
                                                                <option value="3">PATCH</option>
                                                                <option value="4">DELETE</option>
                                                                <option value="5">PUT</option>
                                                                <option value="6">Both GET &amp; POST</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-3 col-md-3">
                                                            <label>Body Type</label>
                                                            <select name="body_type" className="form-control">
                                                                <option value="">Select Type</option>
                                                                <option value="2">x-www-form-urlencoded</option>
                                                                <option value="3">raw</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-3 col-md-3">
                                                            <label>Query String</label>
                                                            <textarea name="query_string" className="form-control" placeholder="Enter string"></textarea>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Response Type</label>
                                                            <select name="response_type" id="response_type4" className="form-control" required>
                                                                <option value="">Select Response Type</option>
                                                                <option value="1">JSON</option>
                                                                <option value="2">XML</option>
                                                                <option value="3">STRING</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-3 mt-3" id="seprator_div4">
                                                            <label>Seprator</label>
                                                            <input type="text" className="form-control" name="seprator" placeholder="Enter seprator" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Status Key</label>
                                                            <input type="text" className="form-control" name="status_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Success Value</label>
                                                            <input type="text" className="form-control" name="success_value" placeholder="Enter Value" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Failure Value</label>
                                                            <input type="text" className="form-control" name="failure_value" placeholder="Enter Value" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Pending Value</label>
                                                            <input type="text" className="form-control" name="pending_value" placeholder="Enter Value" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Message Key</label>
                                                            <input type="text" className="form-control" name="message_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Operator Reference Key</label>
                                                            <input type="text" className="form-control" name="ref_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>API Txn Id Key</label>
                                                            <input type="text" className="form-control" name="api_txn_id_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Stv Value</label>
                                                            <input type="text" className="form-control" name="stv_value" placeholder="Enter Stv Value" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Roffer False Value</label>
                                                            <input type="text" className="form-control" name="roffer_false_value" placeholder="Enter Roffer value" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Roffer True Value</label>
                                                            <input type="text" className="form-control" name="roffer_true_value" placeholder="Enter Roffer value" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Roffer Operators</label>
                                                            <select name="roffer_operators[]" multiple className="form-control select2 select2-hidden-accessible" data-placeholder="Select Operators" data-select2-id="5" tabIndex="-1" aria-hidden="true">
                                                                <option value="1">Airtel</option>
                                                                <option value="2">VI - Vodafone &amp; Idea</option>
                                                                <option value="6">BSNL Special (STV)</option>
                                                                <option value="178">BSNL</option>
                                                                <option value="179">Jio</option>
                                                                <option value="32">Dish TV</option>
                                                                <option value="33">Tata Play</option>
                                                                <option value="35">d2h - Videocon DTH</option>
                                                                <option value="36">Sun Direct</option>
                                                                <option value="37">Airtel DTH</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Roffer Amount Key</label>
                                                            <input type="text" className="form-control" name="roffer_key" placeholder="Enter Roffer Key" value="" />
                                                        </div>
                                                        <div className="col-md-3" style={{ marginTop: '55px' }}>
                                                            <div className="icheck-primary d-inline">
                                                                <input type="checkbox" id="is_bbps" value="1" name="is_bbps" />
                                                                <label htmlFor="is_bbps">Same for BBPS</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3" style={{ marginTop: '55px' }}>
                                                            <div className="icheck-primary d-inline">
                                                                <input type="checkbox" id="data_object" value="1" name="data_object" />
                                                                <label htmlFor="data_object">Is Object</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Object Key</label>
                                                            <input type="text" className="form-control" name="object_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Object Key Includes</label>
                                                            <select name="object_keys[]" multiple className="form-control select2 select2-hidden-accessible" data-placeholder="Select Object Keys" data-select2-id="7" tabIndex="-1" aria-hidden="true">
                                                                <option value="1">STATUS</option>
                                                                <option value="2">OPERATIR REF</option>
                                                                <option value="3">MESSAGE</option>
                                                                <option value="4">API TXN ID</option>
                                                                <option value="5">ROFFER</option>
                                                                <option value="6">BALANCE</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3 text-center">
                                                        <input type="submit" className="btn btn-primary" value="SAVE" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card card-primary dynamic" style={{ display: 'none' }} >
                                        <div className="card-header">
                                            <h4 className="card-title">
                                                <Link data-toggle="collapse" data-parent="#accordion" to="#collapseSix" className="collapsed" aria-expanded="false">
                                                    BBPS API
                                                </Link>
                                            </h4>
                                        </div>
                                        <div id="collapseSix" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <button type="button" className="btn btn-default btn-sm m-1">
                                                    <i className="fas fa-share"></i> Variable to Use
                                                </button>
                                                <div className="card-footer card-comments ">
                                                    <div className="card-comment">
                                                        <div className="comment-text ml-0">
                                                            <span className="username">
                                                                1.@number : BBPS Number
                                                                2.@operator : Operator Code
                                                                3.@circle : Circle Code
                                                                4.@amount : Recharge amount.
                                                                5.@txn_id : Unique transaction ID
                                                                6.@optional1 : Optional Parameter 1.
                                                                7.@optional2 : Optional Parameter 2.
                                                                8.@optional3 : Optional Parameter 3.
                                                                9.@optional4 : Optional Parameter 4.
                                                                10.@stv : If Stv Parameter.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form action="#" method="post" acceptCharset="utf-8">
                                                    <input type="hidden" name="securityToken" value="adb04c72b8d26840ba808fd10a739df7" />
                                                    <div className="row">
                                                        <input type="hidden" name="url_type" value="2" />
                                                        <div className="col-md-6">
                                                            <label>URL</label>
                                                            <textarea className="form-control" name="url" required placeholder="Enter URL"></textarea>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>Headers</label>
                                                            <textarea className="form-control" name="headers" placeholder="Enter headers"></textarea>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Method</label>
                                                            <select name="method" className="form-control" required>
                                                                <option value="">Select Method</option>
                                                                <option value="1">GET</option>
                                                                <option value="2">POST</option>
                                                                <option value="3">PATCH</option>
                                                                <option value="4">DELETE</option>
                                                                <option value="5">PUT</option>
                                                                <option value="6">Both GET &amp; POST</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-3 col-md-3">
                                                            <label>Body Type</label>
                                                            <select name="body_type" className="form-control">
                                                                <option value="">Select Type</option>
                                                                <option value="2">x-www-form-urlencoded</option>
                                                                <option value="3">raw</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-3 col-md-3">
                                                            <label>Query String</label>
                                                            <input type="text" name="query_string" className="form-control" placeholder="Enter string" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Response Type</label>
                                                            <select name="response_type" id="response_type5" className="form-control" required>
                                                                <option value="">Select Response Type</option>
                                                                <option value="1">JSON</option>
                                                                <option value="2">XML</option>
                                                                <option value="3">STRING</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-3 mt-3" id="seprator_div5">
                                                            <label>Seprator</label>
                                                            <input type="text" className="form-control" name="seprator" placeholder="Enter seprator" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Status Key</label>
                                                            <input type="text" className="form-control" name="status_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Success Value</label>
                                                            <input type="text" className="form-control" name="success_value" placeholder="Enter Value" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Failure Value</label>
                                                            <input type="text" className="form-control" name="failure_value" placeholder="Enter Value" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Pending Value</label>
                                                            <input type="text" className="form-control" name="pending_value" placeholder="Enter Value" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Message Key</label>
                                                            <input type="text" className="form-control" name="message_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Operator Reference Key</label>
                                                            <input type="text" className="form-control" name="ref_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>API Txn ID Key</label>
                                                            <input type="text" className="form-control" name="api_txn_id_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Object Key</label>
                                                            <input type="text" className="form-control" name="object_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3" style={{ marginTop: '55px' }}>
                                                            <div className="icheck-primary d-inline">
                                                                <input type="checkbox" id="data_object_bbps" value="1" name="data_object" />
                                                                <label htmlFor="data_object_bbps">Is Object</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Object Key Includes</label>
                                                            <select name="object_keys[]" multiple className="form-control select2 select2-hidden-accessible" data-placeholder="Select Object Keys" data-select2-id="9" tabIndex="-1" aria-hidden="true">
                                                                <option value="1">STATUS</option>
                                                                <option value="2">OPERATIR REF</option>
                                                                <option value="3">MESSAGE</option>
                                                                <option value="4">API TXN ID</option>
                                                                <option value="5">ROFFER</option>
                                                                <option value="6">BALANCE</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3 text-center">
                                                        <input type="submit" className="btn btn-primary" value="SAVE" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card card-primary dynamic" style={{ display: 'none' }} >
                                        <div className="card-header">
                                            <h4 className="card-title">
                                                <Link data-toggle="collapse" data-parent="#accordion" to="#collapseSeven" className="collapsed" aria-expanded="false">
                                                    Dispute URL
                                                </Link>
                                            </h4>
                                        </div>
                                        <div id="collapseSeven" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <button type="button" className="btn btn-default btn-sm m-1">
                                                    <i className="fas fa-share"></i> Variable to Used
                                                </button>
                                                <div className="card-footer card-comments">
                                                    <div className="card-comment">
                                                        <div className="comment-text ml-0">
                                                            <span className="username">
                                                                1. @txn_id: For SOFTMINT system unique transaction id.
                                                                2. @number: For SOFTMINT system recharge number.
                                                                3. @api_txn_id: Client Transaction ID.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <input type="hidden" name="securityToken" value="adb04c72b8d26840ba808fd10a739df7" />
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label>URL <span className="text-danger">*</span></label>
                                                            <textarea className="form-control" name="url" required placeholder="Enter URL"></textarea>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Method <span className="text-danger">*</span></label>
                                                            <select name="method" className="form-control" required >
                                                                <option value="">Select Method</option>
                                                                <option value="1">GET</option>
                                                                <option value="2">POST</option>
                                                                <option value="3">PATCH</option>
                                                                <option value="4">DELETE</option>
                                                                <option value="5">PUT</option>
                                                                <option value="6">Both GET &amp; POST</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-3 col-md-3">
                                                            <label>Body Type <span className="text-danger">*</span></label>
                                                            <select name="body_type" className="form-control">
                                                                <option value="">Select Type</option>
                                                                <option value="2">x-www-form-urlencoded</option>
                                                                <option value="3">raw</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Data Type</label>
                                                            <select name="response_type" className="form-control">
                                                                <option value="">Select Data Type</option>
                                                                <option value="1">JSON</option>
                                                                <option value="2">XML</option>
                                                                <option value="3">STRING</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-3 col-md-3 mt-3">
                                                            <label>Query String</label>
                                                            <input type="text" name="query_string" className="form-control" placeholder="Enter string" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Status Key</label>
                                                            <input type="text" className="form-control" name="status_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Success Value</label>
                                                            <input type="text" className="form-control" name="success_value" placeholder="Enter Value" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Failure Value</label>
                                                            <input type="text" className="form-control" name="failure_value" placeholder="Enter Value" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Message Key</label>
                                                            <input type="text" className="form-control" name="message_key" placeholder="Enter key" value="" />
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <label>Reference Key</label>
                                                            <input type="text" className="form-control" name="ref_key" placeholder="Enter key" value="" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-3 text-center">
                                                        <input type="submit" className="btn btn-primary" value="SAVE" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import RestService from "../http";
import { FaFileExcel, FaPrint } from "react-icons/fa";
import formatDate from "./utilityComponents/FormatDate";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Pagination2 from './utilityComponents/Pagination2';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';
import * as XLSX from 'xlsx';


export default function AdminBbpsReport() {

    const navigate = useNavigate();
    const selectedOption = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();
    const [bbpsList, setBbpsList] = useState([]);
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const [status, setStatus] = useState('');

    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);



    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);


    const service = new RestService();

    const handleSearch = async () => {
        var raw = {
            "status": status,
            "fromDate": formatDate(date),
            "toDate": formatDate(date1),
        };

        setLoading(true);
        const result = await service.post("report/fetchBbpsReport", raw);
        setLoading(false);

        console.log(result);

        if (result.status === service.SUCCESS) {
            showToast("SUCCESS", result.message);
            setBbpsList(result.data);

        } else {
            showToast("DANGER", result.message);
            setBbpsList([]);
        }
    }

    useEffect(() => {
        handleSearch();
    }, []);


    const [searchInput, setSearchInput] = useState('');
    const [filteredBbpsList, setFilteredBbpsList] = useState([]);


    useEffect(() => {
        const filteredList = bbpsList.filter(item =>
            item.serviceName.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.mobile.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.customerName.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.customerMobile.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.accountNumber.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.date.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.orderId.toLowerCase().includes(searchInput.toLowerCase())

        );
        setFilteredBbpsList(filteredList);
    }, [bbpsList, searchInput]);

    const paginatedBbpsReport = filteredBbpsList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    const downloadExcel = () => {
        // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
        // writeFile(wb, 'recharge_report.xlsx');

        const ws = XLSX.utils.json_to_sheet(bbpsList);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'BBPS Report');

        XLSX.writeFile(wb, 'bbps_report.xlsx');
    };

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">BBPS Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Report</Link>
                                    </li>
                                    <li className="breadcrumb-item active">BBPS Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label for="rec_to_load"> Start Date</label><br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label for="rec_to_load"> End Date</label>
                                                <br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    disabledDayAriaLabelPrefix="#$"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date1}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate1(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label>
                                                    Status <span className="text-danger"> *</span>
                                                </label>

                                                <select
                                                    className="custom-date-picker"
                                                    name="customer_type"
                                                    id="customer_type"
                                                    fdprocessedid="g30y5e"
                                                    onChange={(e) => setStatus(e.target.value)}
                                                >
                                                    <option value={""}>SELECT STATUS</option>
                                                    <option value={"SUCCESS"}>SUCCESS</option>
                                                    <option value={"FAILED"}>FAILED</option>
                                                    <option value={"PENDING"}>PENDING</option>
                                                </select>
                                            </div>

                                            <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                                            <div className="col-md-3" style={{ textAlign: 'center' }}>
                                                <button
                                                    onClick={handleSearch}
                                                    className="btn btn-primary btn-block"
                                                    style={{ marginTop: '32px', color: 'white', fontWeight: 600 }}
                                                    fdprocessedid="qnf2"
                                                >
                                                    Search
                                                </button>
                                                &nbsp;&nbsp;

                                                <button type="button" name="" className="btn btn-success" style={{ marginTop: "12%" }} onClick={downloadExcel} fdprocessedid="9svl7o">
                                                    <FaFileExcel className="fa fa-file-excel-o" />
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                <center>
                                    <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                        <div>

                                            <div className="col-md-3" style={{ float: "left" }}>
                                                <div>
                                                    <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                    <input
                                                        className="form-control text-box"
                                                        type="text"
                                                        placeholder="SEARCH..."
                                                        value={searchInput}
                                                        onChange={(e) => setSearchInput(e.target.value)}

                                                    />
                                                </div>
                                            </div><br />

                                            <Pagination2
                                                totalPages={Math.ceil(bbpsList.length / itemsPerPage)}
                                                currentPage={currentPage}
                                                onChange={handlePageChange}

                                            />
                                        </div>
                                    </div>
                                </center>

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr className="wrap-text text-center">
                                                <th>#</th>
                                                <th>User Id</th>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                                <th>Cust. Name</th>
                                                <th>Cust. Mob.</th>
                                                <th>A/c No.</th>
                                                <th>Order Id</th>
                                                <th>Amount</th>
                                                <th>Service Id</th>
                                                <th>Service Name</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedBbpsReport.length > 0 ? (
                                                paginatedBbpsReport.map((item, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.userId}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{item.customerName}</td>
                                                        <td>{item.customerMobile}</td>
                                                        <td>{item.accountNumber}</td>
                                                        <td>{item.orderId}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{item.serviceId}</td>
                                                        <td>{item.serviceName}</td>
                                                        <td>{item.date}</td>
                                                        <td>{item.time}</td>
                                                        <td style={{ color: item.status === "SUCCESS" ? 'green' : 'red', fontWeight: "bold" }}>{item.status}</td>

                                                    </tr>

                                                ))
                                            ) : (
                                                <td colSpan={14} align="center">
                                                    NO DATA AVAILABLE
                                                </td>
                                            )
                                            }
                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <td
                                                    colSpan="4"
                                                    style={{ fontWeight: "bold" }}
                                                    className="text-primary"
                                                    rowSpan="1"
                                                >
                                                    Row Count {bbpsList.length}
                                                </td>
                                                <td
                                                    className="text-right font-weight-bold text-success"

                                                >
                                                </td>

                                            </tr>
                                        </tfoot>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <footer className="main-footer" >
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import Swal from "sweetalert2";


const Logout = (url) => {
    const root = `#`;
    Swal.fire({
        title: "Are you sure you want to logout ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        buttons: {
            confirm: {
                text: "YES",
                className: "btn btn-primary",
            },
            cancel: {
                text: "No",
                className: "btn btn-danger",
            },
        },
        dangerMode: true,
    }).then((confirmed) => {
        if (confirmed.isConfirmed) {
            // Redirect to the login page (this is just an example)
            window.location.href = "/";
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("user");
        }
    });
};

export default Logout;
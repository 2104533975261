import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';

export default function Virtual() {

    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const handleLoadClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleVerifyPin = (event) => {
        const inputs = document.getElementsByClassName('mpin');
        let pin = '';

        for (let i = 0; i < inputs.length; i++) {
            pin += inputs[i].value;
        }

        if (event.target.value !== '') {
            const currentInput = event.target;
            const currentIndex = Array.from(inputs).indexOf(currentInput);

            if (currentIndex < inputs.length - 1) {
                inputs[currentIndex + 1].focus(); // Move to the next input field
            } else {
                currentInput.blur(); // Remove focus from the current input field
            }
        } else if (event.keyCode === 8) {
            const currentInput = event.target;
            const currentIndex = Array.from(inputs).indexOf(currentInput);

            if (currentIndex > 0) {
                inputs[currentIndex - 1].focus(); // Move back to the previous input field on Backspace key press
            }
        }

        if (pin.length === 4) {
            // Perform verification logic
        } else {
            setShowMessage(true);
            setErrorMessage(true);

            setTimeout(() => {
                setErrorMessage(false);
                setShowMessage(false);
            }, 2000);
        }
    };


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Virtual Balance</h1>
                            </div>
                            {/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/admin">Dashboard</a></li>
                                    <li className="breadcrumb-item"><a href="#">Wallet</a></li>
                                    <li className="breadcrumb-item active">Virtual Balance</li>
                                </ol>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card col-md-5 m-auto">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <form action="/virtualbal" method="post" id="virtual_form">
                                        <div className="row">
                                            <div className="col-12">
                                                <label>Amount</label><span className="text-danger"> *</span>
                                                <input type="text" maxLength="7" name="amount" placeholder="Enter Amount" className="form-control" required="" onKeyUp="checkDec(this)" onKeyPress="checkDec(this)" fdprocessedid="50ucv5" />
                                            </div>
                                            <div className="col-12">
                                                <label>Remark</label>
                                                <input type="text" name="remark" placeholder="Enter Remark" className="form-control" fdprocessedid="30n599" />
                                            </div>
                                            <input type="hidden" name="securityToken" value="6e27d367a43feebc3c45aa522ef551ac" />
                                            <div className="col-12 text-center">
                                                <button type="button" className="btn btn-primary" onClick={handleLoadClick} style={{ marginTop: '30px' }} fdprocessedid="c7u8ib">Load</button>
                                            </div>

                                            {showModal && (
                                                <div className="modal fade show" id="mpin_modal" aria-modal="true" style={{ display: 'block' }}>
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">MPIN</h5>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                                                                    <span aria-hidden="true">×</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="form-group text-center">
                                                                    <input
                                                                        type="password"
                                                                        autoFocus
                                                                        name="mpin[]"
                                                                        className="mpin form-control"
                                                                        maxLength="1"
                                                                        minLength="1"
                                                                        onKeyUp={(event) => handleVerifyPin(event, 0)} // Pass index 0 for the first input field
                                                                    />
                                                                    <input
                                                                        type="password"
                                                                        name="mpin[]"
                                                                        className="mpin form-control"
                                                                        maxLength="1"
                                                                        minLength="1"
                                                                        onKeyUp={(event) => handleVerifyPin(event, 1)} // Pass index 1 for the second input field
                                                                    />
                                                                    <input
                                                                        type="password"
                                                                        name="mpin[]"
                                                                        className="mpin form-control"
                                                                        maxLength="1"
                                                                        minLength="1"
                                                                        onKeyUp={(event) => handleVerifyPin(event, 2)} // Pass index 2 for the third input field
                                                                    />
                                                                    <input
                                                                        type="password"
                                                                        name="mpin[]"
                                                                        className="mpin form-control"
                                                                        maxLength="1"
                                                                        minLength="1"
                                                                        onKeyUp={(event) => handleVerifyPin(event, 3)} // Pass index 3 for the fourth input field
                                                                    />
                                                                </div>
                                                                <hr />
                                                                <div className="m-2 text-center">
                                                                    <button type="button" className="btn btn-primary" onClick={handleVerifyPin}>
                                                                        VERIFY
                                                                    </button>&nbsp;

                                                                    <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={handleCloseModal}>
                                                                        CLOSE
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {showMessage && (
                                                <div id="toast-container" className="toast-top-right">
                                                    <div className="toast toast-error" aria-live="assertive">
                                                        <div className="toast-message">Invalid Pin Length</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <a href="www.softmintdigital.com">SOFTMINT</a>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React, { useEffect } from 'react';
import { CiBellOn } from 'react-icons/ci';
import { AiOutlineFolderOpen } from 'react-icons/ai';
import { MdLogout } from 'react-icons/md';
import { FaUserAlt, FaWeixin, FaTimes } from 'react-icons/fa';
import { BiUserCircle, BiHomeAlt, BiChevronDown } from 'react-icons/bi';
import { HiOutlineChat } from 'react-icons/hi';
import { RiShoppingBasketLine } from 'react-icons/ri';
import { BsGrid, BsFingerprint } from 'react-icons/bs';
import { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import RestService from "../http";
import { ToastContainer } from "react-toastify";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logout from '../components/utilityComponents/Logout';
import showToast from '../components/utilityComponents/APPToast';



const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function Aepsreport() {

    const rest = new RestService();
    const [openDropdowns, setOpenDropdowns] = useState([]);
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [balance, setBalance] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [roles, setRoles] = useState([]);
    const [userList, setUserList] = useState([]);
    const [userId, setUserId] = useState("");
    const [roleId, setRoleId] = useState(-1);
    const [earningReport, setEarningReport] = useState([]);
    const [name] = useState(sessionStorage.getItem("name"));
    const [roleName] = useState(sessionStorage.getItem("role"));


    const handleClick = (index) => {
        const updatedOpenDropdowns = [...openDropdowns];

        if (updatedOpenDropdowns.includes(index)) {
            updatedOpenDropdowns.splice(updatedOpenDropdowns.indexOf(index), 1);
        } else {
            updatedOpenDropdowns.push(index);
        }

        setOpenDropdowns(updatedOpenDropdowns);
    };

    const modalRef = useRef(null);

    const openForm = (event) => {
        event.preventDefault();
        modalRef.current.style.display = 'block';
    };

    const closeForm = () => {
        modalRef.current.style.display = 'none';
    };

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        rest.post("user/fetchUserBalance", null).then(result => {
            console.log(result);
            setBalance(result);
        }).catch((error) => {
            console.log(error);
        })
    }, []);


    const formatDate = (date) => {
        const formattedDate = date
            .toLocaleDateString("en-GB", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            })
            .split("/")
            .reverse()
            .join("-");
        return formattedDate;
    };


    const handleCustomerTypeChange = async () => {
        try {
            rest
                .get(`user/roles`, null)
                .then((response) => {
                    console.log(response);
                    if (response.status === rest.SUCCESS) {
                        setRoles(response.role);
                    } else {
                        // setIsSessionExpired(true);
                        setRoles([]);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        handleCustomerTypeChange();
    }, []);

    const handleCustomerType = (customerType) => {
        setRoleId(customerType);
        rest
            .post(`user/getUpline?roleId=${customerType}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === rest.SUCCESS) {
                    setUserList(result.data);
                } else {
                    setUserList([]);
                }
            })
            .catch((err) => {
                console.error(err);
                setUserList([]);
            });
    };

    const handleEarningReport = () => {
        try {
            let request = {
                startDate: formatDate(startDate),
                endDate: formatDate(endDate),
                roleId: roleId,
                userId: userId,
            };
            rest
                .post(`report/fetchEarningReport`, request)
                .then((response) => {
                    console.log(response);
                    if (response.status === rest.SUCCESS) {
                        setEarningReport(response.data);

                    } else if (response.status === rest.EXCEPTIONAL_ERROR) {
                        console.log(response);
                        showToast("WARNING", response.message);
                        setEarningReport([]);

                    } else {
                        showToast("DANGER", response.message);
                        setEarningReport([]);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setEarningReport([]);
                });
        } catch (error) {
            console.log(error);
            setEarningReport([]);
        }
    };


    return (
        <>
            <ToastContainer />
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" className='bg-light' open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className="pull-right" style={{ marginLeft: "79%" }}>
                            <div className="btn-group main-balance" style={{ borderRadius: "10px", backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)', color: "white", marginTop: "2px" }}>
                                <button type="button" data-toggle="dropdown" style={{ color: "white", fontWeight: "500", background: "none", border: "none" }} >
                                    <img src="image/wallet.png" className="wallet-icon" />
                                    <span className="mybal-text-white">My Balance</span><br />
                                    <span className="badge" id="wallet" style={{ fontSize: "14px" }}>&#8377;
                                        {balance === undefined
                                            ? 0.0
                                            : balance.data}
                                    </span>
                                </button>
                            </div>&nbsp;&nbsp;

                            <div className="btn-group btn-group-list btn-group-notification notification">
                                <button style={{ background: "none", border: "none", color: "black", position: "relative", }} type="button" data-toggle="dropdown">
                                    <CiBellOn style={{ fontSize: "30px" }} />
                                    <span className="badge"
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            background: "red",
                                            borderRadius: "50%",
                                            padding: "5px",
                                            fontSize: "12px",
                                            color: "white",
                                        }}
                                    >0</span></button>

                            </div>&nbsp;&nbsp;

                            <div className="btn-group btn-group-option">
                                <button style={{ background: "none", border: "none" }} type="button" data-toggle="dropdown"><span
                                    className='user-img'>
                                    <img className='rounded-circle img-circle' src='image/profile.png' width='40' alt='' /></span></button>
                                <ul className="dropdown-menu pull-right" role="menu">
                                    <li><a href="/editprofile"><FaUserAlt className='glyphicon' /> My Profile</a>
                                    </li>

                                    <li className="divider"></li>
                                    <li>
                                        <a onClick={() => Logout("your-url")}>
                                            <MdLogout className='glyphicon' />Sign Out
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            <img style={{ width: "150px" }} src='./image/softmintlogo.png' />
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>

                    </DrawerHeader>
                    <Divider />
                    <List>

                        <div className="leftpanel" style={{ backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)' }}>
                            <div className="media profile-left">
                                <a className="pull-left profile-thumb" href="/editprofile">
                                    <img
                                        src="./image/profile.png"
                                        alt="user"
                                        className="img-circle"
                                    /> </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                <div className="media-body">
                                    <h5 className="media-heading">{name}</h5>
                                    <small className="text-white">{rest.roles[new Number(roleName)]}</small>
                                </div>
                            </div>
                            <ul className="nav nav-pills nav-stacked">
                                <li className="active"><a href="/side"><BiHomeAlt className='house' /> <span>
                                    Dashboard</span></a></li>

                                <li className="parent" ><a href="#" onClick={() => handleClick(0)} ><BiUserCircle className='house' />
                                    <span> My Account</span>

                                    <BiChevronDown style={{ marginLeft: "75px" }} />
                                </a>

                                    <ul className={`children ${openDropdowns.includes(0) ? 'show' : ''}`}>
                                        <li className="" ><a href="/editprofile"> Edit Profile</a></li>
                                        <li className=""><a href="/kyc"> KYC Verification</a></li>
                                        <li className=""><a href="/kycmicroatm"> KYC Microatm</a></li>
                                        <li className=""><a href="/changepasswrd"> Change Password</a></li>
                                        <li className=""><a href="/commison"> My Commission</a></li>
                                        <li className=""><a href="print/certificate-print.php" target="_blank"> Print
                                            Certificate</a></li>
                                    </ul>

                                </li>
                                <li className="parent" >
                                    <a href="#" onClick={() => handleClick(1)}>
                                        <RiShoppingBasketLine className='house' /> <span>My Wallet</span>

                                        <BiChevronDown style={{ marginLeft: "85px" }} />
                                    </a>
                                    <ul className={`children ${openDropdowns.includes(1) ? 'show' : ''}`}>
                                        <li className=""><a href="/accountinfo"> Account Info</a></li>
                                        <li className=""><a href="/bankaccount"> Bank Account Add</a></li>
                                        <li className=""><a href="/sendmoney"> Send Money</a></li>
                                        {/* <li className=""><a href="/addmoney"> Add Money</a></li> */}
                                        {/* <li className=""><a href="/fundrequest"> Fund Request</a></li> */}
                                        {/* <li className=""><a href="/wallettrans"> Wallet Transfer</a></li> */}
                                        {/* <li className=""><a href="/mpin"> Change Mpin</a></li> */}
                                    </ul>
                                </li>

                                <li className="">
                                    <a href="/retailerservice"><BsGrid className='house' /> <span>
                                        Services</span>
                                        <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                    </a>
                                </li>

                                <li className="parent"><a href="#" onClick={() => handleClick(2)}><AiOutlineFolderOpen className='house' /> <span> Reports</span>

                                    <BiChevronDown style={{ marginLeft: "97px" }} />
                                </a>
                                    <ul className={`children ${openDropdowns.includes(2) ? 'show' : ''}`}>
                                        <li className=""><a href="/rechargereportretailer"> Recharge Report</a></li>
                                        <li className=""><a href="/aepsreport"> AEPS Report </a></li>
                                        <li className=""><a href="/aadharpayreport"> AadharPay Report </a></li>

                                        <li className=""><a href="/retailertxnreport"> Transaction Report </a></li>
                                        <li className=""><a href="/payout"> Payout Report</a></li>
                                        <li className=""><a href="/nsdlreport"> NSDL Report</a></li>
                                        <li className=""><a href="/retailerinsurancereport"> Insurance Report</a></li>                                        <li className=""><a href="/retailerearningreport"> Earning Report</a></li>
                                                                              <li className=""><a href="/bbpsreport"> BBPS Report</a></li>

                                         
                                        <li className=""><a href="/matmreport"> MATM Report</a></li>
                                        <li className=""><a href="/retailercouponreport"> Coupon Req. Report</a></li>
                                        <li className=""><a href="/retailermatmlogreport"> MATM Log Report</a></li>
                                        <li className=""><a href="/retailermovetobankuserreport"> MoveToBankUser Report</a></li>
                                        <li className=""><a href="/dmtreport"> DMT Report</a></li>
                                        
                                    </ul>
                                </li>
                                <li className=""><a href="/feedback"><HiOutlineChat className='house' /> <span>
                                    Feedback</span>
                                    <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                </a></li>
                                <li className=""><a href="/kyc"><BsFingerprint className='house' /> <span>
                                    AEPS E-Kyc</span>
                                    <BiChevronDown style={{ marginLeft: "70px", visibility: "hidden" }} />
                                </a></li>
                            </ul>

                            <div className="menu-devide">&nbsp;</div>
                            <div style={{ display: open ? 'block' : 'none' }}>
                                <div className="customer-support-sec">
                                    <h3>Account Details</h3>
                                    <p>ICICI BANK</p>
                                    <p style={{ fontSize: "11px" }}>(SOFTMINT)</p>
                                    <p>A/C No - 260705000861</p>
                                    <p><span>IFSC -</span> ICIC0002607</p>
                                </div>
                                <div className="menu-devide">&nbsp;</div>
                                <div className="customer-support-sec" style={{ display: open ? 'block' : 'none' }}>
                                    <h3>Quick Support</h3>
                                    <p>SOFTMINT</p>
                                    <p>Phone : 9999726418</p>
                                    <p>What's App : 8809912400</p>
                                    <p>Email : <a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                        data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}>info@softmintdigital.com</a></p>
                                </div>
                            </div>
                            <div style={{
                                display: open ? 'none' : 'block',
                                height: '100vh'
                            }}>
                                <div>
                                    <h3></h3>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p><span></span></p>
                                </div>
                                <div className="menu-devide">&nbsp;</div>
                                <div className="">
                                    <h3></h3>
                                    <p></p>
                                    <p></p>
                                    <p><a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                        data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}></a></p>
                                </div>
                            </div>

                        </div><br />
                    </List>
                </Drawer>

                <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                    <DrawerHeader />
                    <div className="pageheader">
                        <div className="media">
                            <div className="media-body">
                                <ul className="breadcrumb">
                                    <li><a href="javascript:void(0);"><BiHomeAlt style={{ color: '#999' }} /></a></li>&nbsp;
                                    <li>Reports</li>
                                </ul>
                                <h4>Earning Report</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <section className="content" style={{ marginRight: "14%", marginLeft: "-1%" }}>
                            <div className="container-fluid">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div>
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                        <label>From Date</label><br />
                                                        <DatePicker
                                                            className="custom-date-picker"
                                                            dropdownMode="select"
                                                            name="string"
                                                            fdprocessedid="9n5i4u"
                                                            selected={startDate}
                                                            dateFormat={"yyyy-MM-dd"}
                                                            onChange={(date) => setStartDate(date)}
                                                            showYearDropdown
                                                            showMonthDropdown
                                                        />
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                        <label>To Date</label>
                                                        <br />
                                                        {/* <input type="date" name="to_date" className="form-control" defaultValue="2023-07-25" /> */}
                                                        <DatePicker
                                                            className="custom-date-picker"
                                                            dropdownMode="select"
                                                            name="string"
                                                            fdprocessedid="9n5i4u"
                                                            selected={endDate}
                                                            dateFormat={"yyyy-MM-dd"}
                                                            onChange={(date) => setEndDate(date)}
                                                            showYearDropdown
                                                            showMonthDropdown
                                                        />
                                                    </div>
                                                    {/* <div className="col-md-3">
                                                                    <label>
                                                                        Customer Type <span className="text-danger"> *</span>
                                                                    </label>

                                                                    <select
                                                                        className="form-control"
                                                                        name="customer_type"
                                                                        id="customer_type"
                                                                        fdprocessedid="g30y5e"
                                                                        onChange={(e) => handleCustomerType(e.target.value)}
                                                                    >
                                                                        <option value={-1}>SELECT CUSTOMER TYPE</option>
                                                                        {roles.map((role) =>
                                                                            role.roleId !== 0 ? (
                                                                                <option key={role.roleId} value={role.roleId}>
                                                                                    {role.roleName}
                                                                                </option>
                                                                            ) : null
                                                                        )}
                                                                    </select>
                                                                </div>

                                                                <div className="col-md-3">
                                                                    <label>
                                                                        User <span className="text-danger"> *</span>
                                                                    </label>

                                                                    <select
                                                                        className="form-control"
                                                                        name="customer_type"
                                                                        id="customer_type"
                                                                        fdprocessedid="g30y5e"
                                                                        onChange={(e) => setUserId(e.target.value)}
                                                                    >
                                                                        <option value={""}>SELECT USER</option>
                                                                        {userList.map((user) => (
                                                                            <option key={user.userId} value={user.userId}>
                                                                                {user.name} | {user.mobile} | {user.userId}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div> */}

                                                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                        <label>Number</label>
                                                        <input
                                                            type="text"
                                                            name="number"
                                                            placeholder="Enter number"
                                                            className="form-control"
                                                            defaultValue=""
                                                        />
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                        <button
                                                            className="btn btn-primary btn-sm"
                                                            style={{ marginTop: "35px" }}
                                                            onClick={handleEarningReport}
                                                        >
                                                            <i className="fa fa-search"></i> Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="content" style={{ marginRight: "14%", marginLeft: "-1%" }}>
                            <div className="container-fluid">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <div
                                                id="example1_wrapper"
                                                className="dataTables_wrapper dt-bootstrap4"
                                            >
                                                <div className="dataTables_length" id="example1_length">
                                                    <label>
                                                        Show &nbsp;
                                                        <select
                                                            name="example1_length"
                                                            aria-controls="example1"
                                                            className="custom-select custom-select-sm form-control form-control-sm"
                                                            fdprocessedid="w1uy4f"
                                                        >
                                                            <option value="10">10</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option value="All">All</option>
                                                        </select>{" "}
                                                        &nbsp; entries
                                                    </label>
                                                </div>

                                                <table
                                                    id="example1"
                                                    className="table table-bordered table-striped dataTable"
                                                    style={{ width: "100%" }}
                                                    role="grid"
                                                    aria-describedby="example1_info"
                                                >
                                                    <thead>
                                                        <tr className="wrap-text" role="row">
                                                            <th
                                                                className="sorting_asc"
                                                                tabIndex="0"
                                                                aria-controls="example1"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                                aria-sort="ascending"
                                                                aria-label="#: activate to sort column descending"
                                                                style={{ width: "8.45833px" }}
                                                            >
                                                                #
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex="0"
                                                                aria-controls="example1"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                                aria-label="Number: activate to sort column ascending"
                                                                style={{ width: "57.2708px" }}
                                                            >
                                                                USER ID
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex="0"
                                                                aria-controls="example1"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                                aria-label="Amount: activate to sort column ascending"
                                                                style={{ width: "56.1354px" }}
                                                            >
                                                                ROLE
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex="0"
                                                                aria-controls="example1"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                                aria-label="Operator: activate to sort column ascending"
                                                                style={{ width: "145.521px" }}
                                                            >
                                                                OPERATOR
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex="0"
                                                                aria-controls="example1"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                                aria-label="Type: activate to sort column ascending"
                                                                style={{ width: "34.3125px" }}
                                                            >
                                                                NARRATION
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex="0"
                                                                aria-controls="example1"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                                aria-label="Commission: activate to sort column ascending"
                                                                style={{ width: "86.6562px" }}
                                                            >
                                                                COMMISSION
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex="0"
                                                                aria-controls="example1"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                                aria-label="Surcharge: activate to sort column ascending"
                                                                style={{ width: "71.9896px" }}
                                                            >
                                                                SURCHARGE
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex="0"
                                                                aria-controls="example1"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                                aria-label="Surcharge: activate to sort column ascending"
                                                                style={{ width: "71.9896px" }}
                                                            >
                                                                TDS
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex="0"
                                                                aria-controls="example1"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                                aria-label="Surcharge: activate to sort column ascending"
                                                                style={{ width: "71.9896px" }}
                                                            >
                                                                FINAL CREDIT
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex="0"
                                                                aria-controls="example1"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                                aria-label="TxnID: activate to sort column ascending"
                                                                style={{ width: "79.4792px" }}
                                                            >
                                                                TRANSACTION ID
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex="0"
                                                                aria-controls="example1"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                                aria-label="Date: activate to sort column ascending"
                                                                style={{ width: "135.552px" }}
                                                            >
                                                                DATE
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex="0"
                                                                aria-controls="example1"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                                aria-label="Date: activate to sort column ascending"
                                                                style={{ width: "135.552px" }}
                                                            >
                                                                TIME
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex="0"
                                                                aria-controls="example1"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                                aria-label="Remark: activate to sort column ascending"
                                                                style={{ width: "55.2188px" }}
                                                            >
                                                                REMARK
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {earningReport.map((report, index) => (
                                                            <tr
                                                                key={index}
                                                                role="row"
                                                                className={index % 2 === 0 ? "even" : "odd"}
                                                            >
                                                                <td className="sorting_1">{index + 1}</td>
                                                                <td className="sorting_1">{report.userId}</td>
                                                                <td>{rest.roles[report.roleId]}</td>
                                                                <td>
                                                                    {report.operatorCode}
                                                                </td>
                                                                <td>{report.narration}</td>
                                                                <td className="font-weight-bold text-right text-success">
                                                                    {report.comm}
                                                                </td>
                                                                <td className="font-weight-bold text-right text-danger">
                                                                    {report.charge}
                                                                </td>
                                                                <td className="font-weight-bold text-right text-danger">
                                                                    {report.tds}
                                                                </td>
                                                                <td className="font-weight-bold text-right text-success">
                                                                    {report.finalAmount}
                                                                </td>
                                                                <td>{report.orderid}</td>
                                                                <td>{report.date}</td>
                                                                <td>{report.time}</td>
                                                                <td>{report.remark}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td
                                                                colSpan="5"
                                                                style={{ fontWeight: "bold" }}
                                                                className="text-primary"
                                                                rowSpan="1"
                                                            >
                                                                Row Count {earningReport.length}
                                                            </td>
                                                            <td
                                                                className="text-right font-weight-bold text-success"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                            >
                                                                ₹ {earningReport.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.comm), 0)}
                                                            </td>
                                                            <td
                                                                className="text-right font-weight-bold text-danger"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                            >
                                                                ₹ {earningReport.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.charge), 0)}
                                                            </td>
                                                            <td
                                                                className="text-right font-weight-bold text-danger"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                            >
                                                                ₹ {earningReport.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.tds), 0)}
                                                            </td>
                                                            <td
                                                                className="text-right font-weight-bold text-success"
                                                                rowSpan="1"
                                                                colSpan="1"
                                                            >
                                                                ₹ {earningReport.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.finalAmount), 0)}
                                                            </td>
                                                            <td colSpan="3" rowSpan="1"></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>

                                                <div
                                                    className="dataTables_info"
                                                    id="example1_info"
                                                    role="status"
                                                    aria-live="polite"
                                                >
                                                    Showing 1 to 20 of 20 entries
                                                </div>

                                                <div
                                                    className="dataTables_paginate paging_simple_numbers"
                                                    id="example1_paginate"
                                                >
                                                    <ul className="pagination">
                                                        <li
                                                            className="paginate_button page-item previous disabled"
                                                            id="example1_previous"
                                                        >
                                                            <a
                                                                aria-controls="example1"
                                                                data-dt-idx="0"
                                                                tabIndex="0"
                                                                className="page-link"
                                                            >
                                                                Previous
                                                            </a>
                                                        </li>
                                                        <li className="paginate_button page-item active">
                                                            <a
                                                                aria-controls="example1"
                                                                data-dt-idx="1"
                                                                tabIndex="0"
                                                                className="page-link"
                                                            >
                                                                1
                                                            </a>
                                                        </li>
                                                        <li
                                                            className="paginate_button page-item next disabled"
                                                            id="example1_next"
                                                        >
                                                            <a
                                                                aria-controls="example1"
                                                                data-dt-idx="2"
                                                                tabIndex="0"
                                                                className="page-link"
                                                            >
                                                                Next
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                </Box>
            </Box>

            <a href="https://api.whatsapp.com/send/?phone=8809912400&text&app_absent=0" target="_blank"
                className="whatsapp-but" title="">
                <img src="image/whatsapp-icon.png" alt="Whatapps" />
            </a>

            <a
                href="#"
                target="_blank"
                className="helpdesk-but"
                title="Request Callback !"
                onClick={openForm}
            >
                <img src="image/help-icon.png" alt="Help" />
            </a>

            <div className="chat-popup" ref={modalRef} id="myForm">
                <form action="#" className="helpdesk-container" onSubmit={(e) => e.preventDefault()}>
                    <h1>
                        <div className="callbackheader-icon">
                            <FaWeixin aria-hidden="true" style={{ fontSize: '35px', paddingTop: '7px' }} />
                        </div>
                        CallBack Request
                    </h1>
                    <label htmlFor="callback_name"><b>Full Name</b></label>
                    <input
                        type="text"
                        id="callback_name"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="Name"
                        required
                    />
                    <label htmlFor="callback_phone"><b>Contact Number</b></label>
                    <input
                        type="text"
                        id="callback_phone"
                        maxLength="10"
                        name=""
                        className="form-control textbox"
                        placeholder="+91 9000 0000 00"
                        required
                    />
                    <label htmlFor="callback_email"><b>Email ID</b></label>
                    <input
                        type="text"
                        id="callback_email"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="info@softmintdigital.com"
                        required
                    />
                    <label htmlFor="callback_msg"><b>Message (250 Character Only)</b></label>
                    <textarea
                        id="callback_msg"
                        maxLength="250"
                        placeholder="Type message.."
                        name="msg"
                        className="form-controlx"
                    ></textarea>
                    <button type="submit" className="btn" id="reqcallbut">
                        Send Now
                    </button>
                    <a href="#" className="close-but" title="" onClick={closeForm}>
                        <FaTimes aria-hidden="true" />
                    </a>
                </form>
            </div>

            <div className="modal fade recharge-model success-model" id="model-succ-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="model-succ-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button success-button" onClick="errordisplay()" data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model error-model" id="model-err-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className=""><img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Error Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-err-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button error-button" onClick="errordisplay()" data-dismiss="modal">Dismiss</button>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model process-model" id="model-process-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Process Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-process-body">
                            <p className="mb-0"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model " id="model-yn-save" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-body-pdf">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="getinvno" />
                        <button type="button" className="btn btn-success model-button success-button" onClick="succok()" data-dismiss="modal" id="modal-no-save">OK</button>
                    </div>
                </div>
            </div>


            );
        </>
    );
}

import React from "react";
import Navbar from "./Navbar";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestService from "../http";
import showToast from "./utilityComponents/APPToast";
import Loader from "./Loader";

export default function AddCharge() {

    const rest = new RestService();
    const [customerType, setCustomerType] = useState("");
    const [charge, setCharge] = useState("");
    const [loading, setLoading] = useState(false);
    const [operatorList, setOperatorList] = useState([]);
    const [outCode, setOutCode] = useState('');
    const [inCode, setInCode] = useState('');
    const [serviceCode, setServiceCode] = useState('')
    const [serviceList, setServiceList] = useState([]);
    const [operatorCode, setOperatorCode] = useState([]);
    const [payoutMode, setPayoutMode] = useState("");
    const [chargeType, setChargeType] = useState("");
    const [slab1, setSlab1] = useState('');
    const [slab2, setSlab2] = useState('');
    const [slabList, setSlabList] = useState([]);


    const navigate = useNavigate();

    const handleSlabs = (slabObj) => {
        // setSlabList(e.target.value);
        const data = JSON.parse(slabObj);
        setSlab1(data.slab1);
        setSlab2(data.slab2);
    }

    const fetchOperatorSlab = (serviceType) => {
        console.log(serviceType);
        rest.post(`commission/fetchSlabByService/${serviceType}`, null).then(result => {
            console.log(result);
            if (result.status === rest.SUCCESS) {
                setSlabList(result.data);
            } else {
                setSlabList([]);
            }

        }).catch((error) => {
            console.log(error);
        });
    }

    const handleAddCharge = () => {

        if (operatorCode === undefined || operatorCode === "" || operatorCode === null) {
            showToast('WARN', 'PLEASE ENTER OPERATOR CODE!!');
            return false;
        }

        if (charge === undefined || charge === "" || charge === null) {
            showToast('WARN', 'PLEASE FILL CHARGE!!');
            return false;
        }


        var raw = {
            "operatorCode": outCode,
            "incode": inCode,
            "charge": charge,
            "mode": payoutMode,
            "slab1": slab1,
            "slab2": slab2,
            "type": chargeType
        };

        setLoading(true);

        rest.post(`commission/addCharge`, raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === rest.SUCCESS) {
                showToast('SUCCESS', result.message);

                setTimeout(() => {
                    navigate("/chargesetup");
                }, 2000);

            } else {
                setLoading(false);
                showToast("DANGER", result.message);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleCharge = (event) => {
        setCharge(event.target.value);
    };


    const handleOperatorCode = (serviceCode) => {
        setServiceCode(serviceCode);
        rest.post(`operator/fetchOperatorByService/${serviceCode}`, null).then(result => {
            if (result.status === rest.SUCCESS) {
                setOperatorList(result.report);
            } else {
                setOperatorList([]);
            }
        }).catch((error) => {
            console.log(error);
        });

        rest.post(`commission/fetchSlabByService/${serviceCode}`).then(result => {
            console.log(result);

        })
    }

    const handleServiceCode = () => {

        rest.post("service/getAllService", null).then((result) => {
            if (result.report === undefined) {
                setServiceList([]);
            } else {
                console.log(result);
                setServiceList(result.report);
            }
        })
    }

    useEffect(() => {
        handleServiceCode();
    }, []);

    const handleIncode = (e) => {
        const data = JSON.parse(e.target.value);
        setOutCode(data.operatorOutcode);
        setInCode(data.operatorIncode);
        console.log(outCode);

        fetchOperatorSlab(data.serviceType);

    }


    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}


            <div className="content-wrapper" style={{ minHeight: "560.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">New Charge Add</h1>
                            </div>
                            {/* ... */}
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div>

                                    <div className="row">

                                        <div className="col-md-3" data-select2-id="4">
                                            <label for="service_id">
                                                Service Code: <span className="text-danger"> *</span>
                                            </label>
                                            <select
                                                className="form-control select2 "
                                                required=""
                                                id="service_id"
                                                data-select2-id="service_id"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                onChange={(e) => handleOperatorCode(e.target.value)}
                                            >

                                                <option>Select Id</option>
                                                {serviceList.map((item) => (
                                                    <option key={item.serviceType} value={item.serviceType}>{item.serviceName}</option>
                                                ))}

                                            </select>
                                        </div>

                                        <div className="col-md-3" data-select2-id="4">
                                            <label for="service_id">
                                                Operator Code: <span className="text-danger"> *</span>
                                            </label>
                                            <select
                                                className="form-control select2 "
                                                required=""
                                                id="service_id"
                                                data-select2-id="service_id"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                onChange={handleIncode}
                                            >
                                                <option value={JSON.stringify("{}")}>SELECT OPERATOR</option>
                                                {operatorList.length > 0 ?
                                                    operatorList.map((item) => (
                                                        <option value={JSON.stringify(item)}>{item.operatorName} || {item.operatorOutcode} || {item.operatorIncode}</option>
                                                    )) : <></>}

                                            </select>
                                        </div>

                                        <div className="col-md-3" data-select2-id="4">
                                            <label for="service_id">
                                                Slabs: <span className="text-danger"> *</span>
                                            </label>
                                            <select
                                                className="form-control select2 "
                                                required=""
                                                id="service_id"
                                                data-select2-id="service_id"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                onChange={(e) => handleSlabs(e.target.value)}
                                            >
                                                <option>Slabs</option>
                                                {slabList.map((item) => (
                                                    <option value={JSON.stringify(item)}>{`(${item.slab1}-${item.slab2})`}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="col-md-3">
                                            <label className="font-weight-normal">
                                                Charge : <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                onChange={handleCharge}
                                                name="charge"
                                                type="text"
                                                maxLength="6"
                                                className="form-control"
                                                value={charge}
                                                placeholder="Enter Charge"
                                            />
                                        </div>

                                        <div className="col-md-3 mt-2">
                                            <div>
                                                <label className="control-label">Select Payout Mode </label>
                                                <select className="form-control select-box width100p" onChange={(e) => setPayoutMode(e.target.value)} tabindex="3" name="paytype" id="paytype" fdprocessedid="pvo5dp">
                                                    <option value="">SELECT MODE</option>
                                                    <option value={"IMPS"}>IMPS</option>
                                                    <option value={"NEFT"}>NEFT</option>
                                                    <option value={"OTHER"}>OTHER</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-3 mt-2" data-select2-id="4">
                                                <label for="service_id">
                                                    Is Charge?: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setChargeType(e.target.value)}
                                                >
                                                    <option value="">select</option>
                                                    <option value={"PERC"}>PERCENTAGE</option>
                                                    <option value={"FLAT"}>FLAT</option>

                                                </select>
                                            </div>

                                    </div>

                                    <div className="col-md-12 text-center mt-4">
                                        <button
                                            className="btn btn-sm btn-primary"
                                            onClick={handleAddCharge}
                                        >
                                            <i className="fa fa-save"></i>
                                            Add Charge
                                        </button>
                                    </div>
                                    <hr />

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    );
}

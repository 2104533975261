import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import RestService from "../http";
import swal from 'sweetalert';
import { FaFileExcel } from "react-icons/fa";
import formatDate from "./utilityComponents/FormatDate";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Pagination2 from './utilityComponents/Pagination2';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';


export default function ViewAepsUser() {

    const [selectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();
    const [isSessionExpired, setIsSessionExpired] = useState(false);
    const [aepsUser, setAepsUser] = useState([]);
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const sessionExpiretionPopup = () => {
        if (isSessionExpired) {
            swal({
                title: 'Session Expired',
                text: 'Your login session has expired. Please log in again.',
                icon: 'warning',
                closeOnClickOutside: false,
                buttons: {
                    confirm: {
                        text: 'OK',
                        className: 'btn btn-primary',
                    },
                },
                dangerMode: true,
            }).then((confirmed) => {
                if (confirmed) {
                    window.location.href = '/';
                    sessionStorage.removeItem("token");
                    sessionStorage.removeItem("user");
                }
            });
        }
    }
    useEffect(() => {
        sessionExpiretionPopup();
    }, [sessionExpiretionPopup]);



    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    const downloadExcel = () => {
        // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
        // writeFile(wb, 'recharge_report.xlsx');

        const ws = XLSX.utils.json_to_sheet(aepsUser);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'MATM Report');

        XLSX.writeFile(wb, 'aepsUser_report.xlsx');
    };

    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);



    const service = new RestService();


    const handleAepsUser = () => {
        var raw = {
            "startDate": formatDate(date),
            "endDate": formatDate(date1),
        };

        setLoading(true);

        service.post("package/getallusermapped", raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                setAepsUser(result.data);
            } else {
                showToast("DANGER", result.message);
                setAepsUser([]);
            }
        }).catch((err) => {
            console.error(err);
            setAepsUser([]);
        });
    };

    useEffect(() => {
        handleAepsUser();
    }, []);


    const [filteredAepsUser, setFilteredAepsUser] = useState([]);
    const [searchInput, setSearchInput] = useState('');


    useEffect(() => {
        const filteredList = aepsUser.filter(item =>
            item.userId.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredAepsUser(filteredList);
    }, [aepsUser, searchInput]);

    const paginatedAepsUserReport = filteredAepsUser.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    const totalSuccessAmount = aepsUser.reduce((total, item) => {
        return item.status === "SUCCESS" ? total + parseFloat(item.txnAmount) : total;
    }, 0).toFixed(2);


    const updateStatus = (userId, status) => {
        let request = {
            userId: userId,
            status: status === "0" ? "1" : "0",
        };

        service.post("package/updateusermap", request).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                handleAepsUser();
            } else {
                showToast("FAILED", result.message);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const switchBank = (userId, bankSwitch) => {
        let request = {
            userId: userId,
            bankSwitch: bankSwitch === "NSDLBANK" ? "YESBANK" : "NSDLBANK",
        };

        service.post("package/updatebankaepsuser", request).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                handleAepsUser();
            } else {
                showToast("FAILED", result.message);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const selectDevice = (userId, device) => {
        let request = {
            userId: userId,
            device: device === "Iris" ? "Biometric" : "Both",
        };

        service.post("package/updateusermapdevice", request).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                handleAepsUser();
            } else {
                showToast("FAILED", result.message);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">AEPS User Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">AEPS User Report</Link>
                                    </li>
                                    <li className="breadcrumb-item active">AEPS User Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label for="rec_to_load"> Start Date</label><br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label for="rec_to_load"> End Date</label>
                                                <br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    disabledDayAriaLabelPrefix="#$"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date1}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate1(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>


                                            <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                                            <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ textAlign: 'center' }}>
                                                <button
                                                    onClick={handleAepsUser}
                                                    className="btn btn-primary btn-block"
                                                    style={{ marginTop: '30px', color: 'white', fontWeight: 600 }}
                                                    fdprocessedid="qnf2"
                                                >
                                                    Search
                                                </button>

                                            </div>
                                            <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ marginTop: '10px', textAlign: 'center' }}>
                                                <button type="button" name="" className="btn btn-success" style={{ marginTop: '19px' }} onClick={downloadExcel} fdprocessedid="9svl7o">
                                                    <FaFileExcel className="fa fa-file-excel-o" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <center>
                                    <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                        <div>

                                            <div className="col-md-3" style={{ float: "left", visibility: "hidden" }}>
                                                <div>
                                                    <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                    <input
                                                        className="form-control text-box"
                                                        type="text"
                                                        placeholder="SEARCH BY USERID"
                                                        value={searchInput}
                                                        onChange={(e) => setSearchInput(e.target.value)}

                                                    />
                                                </div>
                                            </div><br />

                                            <Pagination2
                                                totalPages={Math.ceil(aepsUser.length / itemsPerPage)}
                                                currentPage={currentPage}
                                                onChange={handlePageChange}

                                            />
                                        </div>
                                    </div>
                                </center>

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="px-1">SL.NO</th>
                                                <th>User Id</th>
                                                <th>Api Code</th>
                                                <th>Agent Code</th>
                                                <th className="px-1">Date</th>
                                                <th>Time</th>
                                                <th>Status</th>
                                                <th className="px-1">Kyc</th>
                                                <th>Device</th>
                                                <th>Bank Switch</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!paginatedAepsUserReport || paginatedAepsUserReport.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="10">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                paginatedAepsUserReport.map((item, index) => (
                                                    <tr>
                                                        <td key={index}>{index + 1}</td>
                                                        <td>{item.userId}</td>
                                                        <td>{item.apiCode}</td>
                                                        <td>{item.agentcode}</td>
                                                        <td>{item.date}</td>
                                                        <td>{item.time}</td>
                                                        <td>
                                                            {item.status === "1" ? (
                                                                <span
                                                                    onClick={() =>
                                                                        updateStatus(
                                                                            item.userId,
                                                                            item.status,
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge badge-success"
                                                                >
                                                                    ACTIVE
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    onClick={() =>
                                                                        updateStatus(
                                                                            item.userId,
                                                                            item.status,
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge badge-danger"
                                                                >
                                                                    INACTIVE
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>{item.kyc}</td>

                                                        <td>
                                                            {item.device === "Iris" ? (
                                                                <span
                                                                    onClick={() =>
                                                                        selectDevice(
                                                                            item.userId,
                                                                            item.device
                                                                        )}
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge bg-blue"
                                                                >
                                                                    IRIS
                                                                </span>
                                                            ) : item.device === "Biometric" ? (
                                                                <span
                                                                    onClick={() =>
                                                                        selectDevice(
                                                                            item.userId,
                                                                            item.device
                                                                        )}
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge bg-yellow"
                                                                >
                                                                    BIOMETRIC
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    onClick={() =>
                                                                        selectDevice(
                                                                            item.userId,
                                                                            item.device
                                                                        )}
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge bg-green"
                                                                >
                                                                    BOTH
                                                                </span>
                                                            )}
                                                        </td>

                                                        <td>
                                                            {item.bankSwitch === "YESBANK" ? (
                                                                <span
                                                                    onClick={() =>
                                                                        switchBank(
                                                                            item.userId,
                                                                            item.bankSwitch,
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge bg-blue"
                                                                >
                                                                    YESBANK
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    onClick={() =>
                                                                        switchBank(
                                                                            item.userId,
                                                                            item.bankSwitch,
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge bg-yellow"
                                                                >
                                                                    NSDLBANK
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>

                                                ))
                                            )}
                                        </tbody>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <footer className="main-footer" >
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

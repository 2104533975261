import React, { useEffect } from "react";
import Navbar from "./Navbar";
import { useState } from "react";
import RestService from "../http";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";
import { Link } from "react-router-dom";
import showToast from "./utilityComponents/APPToast";

export default function Commission() {
    const service = new RestService();

    const [showForm2, setShowForm2] = useState(false);
    const [userList, setUserList] = useState([]);
    const [planList, setPlanList] = useState([]);
    const [planId, setPlanId] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [assignUserList, setAssignUserList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [outCode, setOutCode] = useState("");
    const [commission, setCommission] = useState("");
    const [commissionType, setCommissionType] = useState("");
    const [operatorList, setOperatorList] = useState([]);
    const [packageId, setPackageId] = useState("");
    const [slabList, setSlabList] = useState([]);
    const [serviceCode, setServiceCode] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [commissionData, setCommissionData] = useState(null);
    const [packageList, setPackageList] = useState([]);
    const [slab1, setSlab1] = useState('');
    const [slab2, setSlab2] = useState('');
    const [incode, setIncode] = useState("");
    const [loading, setLoading] = useState(false);



    const handlePlanId = (e) => {
        setPlanId(e.target.value);
    };

    const toggleFormVisibility2 = () => {
        setShowForm2((prevShowForm) => !prevShowForm);
    };

   
    const handlePlanList = () => {
        service
            .post("plans/getAllPlans", null)
            .then((result) => {
                if (result.status === service.SUCCESS) {
                    setPlanList(result.report);
                } else {
                    setPlanList([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        handlePlanList();
    }, []);

    // const handlePlanIdChange = (e) => {
    //   setFetchPlanId(e.target.value);
    // };

    const handleServiceId = (e) => {
        setServiceCode(e.target.value);

        service
            .post(`operator/fetchOperatorByService/${e.target.value}`)
            .then((result) => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setOperatorList(result.report);
                } else {
                    setOperatorList([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        fetchSlabByService(e.target.value);
    };


    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleAssignUserType = (e) => {
        service
            .post(`package/fetchUserGroup?userType=${e.target.value}`, null)
            .then((result) => {
                if (result.status === service.SUCCESS) {
                    setAssignUserList(result.data);
                } else {
                    setAssignUserList([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        handleCustomerType(e);
    };


    const handleServiceCode = () => {
        service.post("service/getAllService", null).then((result) => {
            if (result.report === undefined) {
                setServiceList([]);
            } else {
                console.log(result);
                setServiceList(result.report);
            }
            handlePackageId();
        });
    };

    useEffect(() => {
        handleServiceCode();
    }, []);


    const handleCommission = (e) => {
        setCommission(e.target.value);
    };

    const handleSlabs = (e) => {
        // setSlabList(e.target.value);
        const data = JSON.parse(e);
        console.log(data.slab1, data.slab2);
        setSlab1(data.slab1);
        setSlab2(data.slab2);
    }

    const handleCommissionType = (e) => {
        setCommissionType(e.target.value);
    };

    const handleAssignCommission = () => {
        let raw = {
            packageId: packageId,
            planId: planId,
            serviceCode: serviceCode,
            outCode: outCode,
            commission: commission,
            commissionType: commissionType,
            isCharge: isChecked,
            incode: incode,
            slab1: slab1,
            slab2: slab2,
        };

        console.log(raw);

        setLoading(true);

        service.post("commission/addMasterApiCommission", raw).then(result => {
            setLoading(false);

            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);

            } else {
                showToast("DANGER", result.message);

            }
        });
    };


    const handleCloseModal = () => {
        setCommissionData(null);
        setShowModal(false);
    };


    const handlePackageId = (userId) => {

        service.post(`package/fetchUserGroupById/${userId}`, null).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                setPackageList(result.data);
                setAssignUserList(result.data);
            } else {
                setAssignUserList([]);
                setPackageList([]);
            }

        }).catch((error) => {
            console.log(error);
        })
    }

    const handleCustomerType = (e) => {
        console.log(e.target.value);
        service
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setUserList(result.data);
                } else {
                    setUserList([]);
                }
            })
            .catch((err) => {
                console.error(err);
                setUserList([]);
            });
    };

    const fetchSlabByService = (serviceId) => {
        service.post(`commission/fetchSlabByService/${serviceId}`, null).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                setSlabList(result.data);

            } else {
                setSlabList([]);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const handleOperatorCode = (data) => {
        const parsedObj = JSON.parse(data);
        setIncode(parsedObj.operatorIncode);
        setOutCode(parsedObj.operatorOutcode);
    }

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "600.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">COMMISSION SETUP </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Settings</Link>
                                    </li>
                                    <li className="breadcrumb-item active"> COMMISSION</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        {showForm2 && (
                            <div className="card" id="addpack">
                                <div className="card-body">
                                    <div>
                                        <div className="row" data-select2-id="4">
                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="assign_commission">
                                                    User Type: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleCustomerType}
                                                >
                                                    <option>Select User Type</option>
                                                    <option value={1}>API USER</option>
                                                    <option value={2}>MASTER ADMIN</option>
                                                </select>
                                            </div>

                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="service_id">
                                                    User List: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => handlePackageId(e.target.value)}
                                                >
                                                    <option>Select User...</option>
                                                    {userList.map((item) => (
                                                        <option value={item.userId}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="assign_commission">
                                                    Package Id: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setPackageId(e.target.value)}
                                                >
                                                    <option>Select Package Id</option>
                                                    {packageList.map((item) => (
                                                        <option value={item.packageId}>
                                                            {item.packageId} | {item.plan}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="service_id">
                                                    Plan Id: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handlePlanId}
                                                >
                                                    <option>Select Plan Id</option>
                                                    {planList.map((item) => (
                                                        <option key={item.planId} value={item.planId}>
                                                            {item.planName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3 mt-2" data-select2-id="4">
                                                <label for="service_id">
                                                    Service Code: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleServiceId}
                                                >
                                                    <option>Select Id</option>
                                                    {serviceList.map((item) => (
                                                        <option
                                                            key={item.serviceType}
                                                            value={item.serviceType}
                                                        >
                                                            {item.serviceName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3 mt-2" data-select2-id="4">
                                                <label for="service_id">
                                                    Operator Code: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => handleOperatorCode(e.target.value)}
                                                >
                                                    <option value={""}>SELECT OPERATOR</option>
                                                    {operatorList.length > 0 ? (
                                                        operatorList.map((item) => (
                                                            <option value={JSON.stringify(item)}>
                                                                {item.operatorName} | {item.operatorIncode} |{" "}
                                                                {item.operatorOutcode}
                                                            </option>
                                                        ))
                                                    ) : (
                                                        <></>
                                                    )}
                                                </select>
                                            </div>

                                            <div className="col-md-3 mt-2" data-select2-id="4">
                                                <label for="service_id">
                                                    Commission: <span className="text-danger"> *</span>
                                                </label>
                                                <input
                                                    placeholder="commission"
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    aria-hidden="true"
                                                    onChange={handleCommission}
                                                />
                                            </div>

                                            <div className="col-md-3 mt-2" data-select2-id="4">
                                                <label for="service_id">
                                                    Slabs: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => handleSlabs(e.target.value)}
                                                >
                                                    <option>Slabs</option>
                                                    {slabList.map((item, index) => (
                                                        <option key={index} value={JSON.stringify(item)}>{`(${item.slab1}-${item.slab2})`}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3 mt-2" data-select2-id="4">
                                                <label for="service_id">
                                                    Commission Type:{" "}
                                                    <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleCommissionType}
                                                >
                                                    <option>select commission type</option>
                                                    <option value={"FLAT"}>FLAT</option>
                                                    <option value={"PERC"}>PERCENTAGE</option>
                                                </select>
                                            </div>

                                            <div className="form-control col-md-3 mt-5" style={{ width: "130px", height: "30px" }}>
                                                <label>Is Charge :</label>&nbsp;&nbsp;&nbsp;
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mb-2">
                                    <input
                                        type="submit"
                                        className="btn btn-primary btn-hover text-white"
                                        value="Assign Commission"
                                        onClick={handleAssignCommission}
                                    />
                                </div>
                            </div>
                        )}


                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label for="assign_commission">
                                            User Type: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={handleAssignUserType}
                                        >
                                            <option>Select User Type</option>
                                            <option value={1}>API USER</option>
                                            <option value={2}>MASTER ADMIN</option>
                                        </select>
                                    </div>

                                    <div className="col-md-3" data-select2-id="4">
                                        <label for="service_id">
                                            User Id: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            name="service_id"
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={(e) => handlePackageId(e.target.value)}
                                        >
                                            <option>Select Id...</option>
                                            {userList.map((item) => (
                                                <option value={item.userId}>{item.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                </div>

                                <h1 className="m-0 text-dark float-right">
                                   
                                    <span
                                        className="btn btn-sm btn-primary"
                                        onClick={toggleFormVisibility2}
                                    >
                                        {/* Render only the "CANCEL" text if showForm is true */}
                                        {showForm2 ? (
                                            "CANCEL"
                                        ) : (
                                            <>
                                                <i id="sign" className="fa fa-plus" style={{}}></i>{" "}
                                                ASSIGN COMMISSION
                                            </>
                                        )}
                                    </span>
                                    &nbsp;
                                </h1>
                                <div className="clearfix"></div>
                                <br />
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Id</th>
                                                <th>User Id</th>
                                                <th>Name</th>
                                                <th>Plan</th>
                                                <th>User Type</th>
                                                <th>Package Id</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {assignUserList.map((item, index) => (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item.id}</td>
                                                    <td>{item.userid}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.plan}</td>
                                                    <td>{item.userType}</td>
                                                    <td>{item.packageId}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    {showModal && (
                                        <div
                                            className="modal fade show"
                                            tabIndex="-1"
                                            role="dialog"
                                            aria-labelledby="serviceUpdateTitle"
                                            style={{ display: "block" }}
                                            aria-modal="true"
                                        >
                                            <div
                                                className="modal-dialog modal-dialog-centered"
                                                role="document"
                                            >
                                                <div
                                                    className="modal-content"
                                                    style={{ width: "100%" }}
                                                >
                                                    <div>
                                                        <div className="modal-header">
                                                            <h5
                                                                className="modal-title"
                                                                id="exampleModalLongTitle"
                                                            >
                                                                View Commission
                                                            </h5>
                                                            <button
                                                                type="button"
                                                                className="close"
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                                onClick={handleCloseModal}
                                                            >
                                                                <span aria-hidden="true">×</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="row">
                                                                <input
                                                                    type="hidden"
                                                                    name="action_type"
                                                                    id="action_type"
                                                                    value="1"
                                                                />

                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Slab</th>
                                                                            <th>Commison/Charge</th>
                                                                            <th>Commison Type</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{commissionData.slab}</td>
                                                                            <td>{commissionData.commission}</td>
                                                                            <td>{commissionData.commissionType}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                data-dismiss="modal"
                                                                fdprocessedid="7wkuky"
                                                                onClick={handleCloseModal}
                                                            >
                                                                Close
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                                fdprocessedid="w927w4"
                                                            // onClick={addService}
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    );
}

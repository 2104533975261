import React from 'react';
import Navbar from './Navbar';
import { useState, useEffect } from 'react';
import RestService from '../http';
import showToast from './utilityComponents/APPToast';
import { Link, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';
import Select from 'react-select';


export default function AddBankPermission() {

    const service = new RestService();
    const [addPermission, setAddPermission] = useState("");
    const [editPermission, setEditPermission] = useState("");
    const [userId, setUserId] = useState('');
    const [roles, setRoles] = useState([]);
    const [userList, setUserList] = useState([]);
    const [loading, setLoading] = useState(false);


    const handlePermission = () => {

        var raw = {
            "userId": userId,
            "addPermission": addPermission,
            "editPermission": editPermission
        }

        setLoading(true);

        service.post("user/addmovetobankpermission", raw).then(result => {
            setLoading(false);

            console.log(result);

            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);

                setTimeout(() => {
                    window.location.href = "/movetobankpermissiontable";
                }, 1000);

            } else {
                showToast("DANGER", result.message);

                //   setTimeout(() => {
                //     window.location.href = "/movetobankpermissiontable";
                // }, 2000);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleCustomerType = (e) => {
        console.log(e.target.value);
        service
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setUserList(result.data);
                } else {
                    showToast("DANGER", result.message)
                    setUserList([]);
                }
            })
            .catch((error) => {
                console.error(error);
                setUserList([]);

            });
    };

    useEffect(() => {
        service.get("user/roles").then(result => {
            console.log(result);
            if (result.role === undefined) {
                setRoles([])
            } else {
                setRoles(result.role);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);


    const [selectedUserId, setSelectedUserId] = useState(null);

     const options = [
        { value: '', label: 'Select an option' },
        ...userList.map((item) => ({
            value: item.userId,
            label: `${item.name} || ${item.mobile}`,
        }))
    ];

    const handleSelectChange = (selectedUserId) => {
        setSelectedUserId(selectedUserId);
        setUserId(selectedUserId.value);
    };
    

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}


            <div className="content-wrapper" style={{ minHeight: '532.667px' }}>

                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Edit Permission</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Settings</Link></li>
                                    <li clLinkssName="breadcrumb-item"><Link to="/operatormast">Permission</Link></li>
                                    <li className="breadcrumb-item active"> Edit Permission</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='content'>
                    <div className='container-fluid'>
                        <div className='card' id='addpack'>
                            <div className="card-header" >
                                Edit Permission
                            </div>

                            <div className='card-body'>
                                <div className="row">

                                    <div className="col-md-3" data-select2-id="4">
                                        <label for="assign_commission">
                                            User Type: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={handleCustomerType}
                                        >
                                            <option>Select User Type</option>
                                            {roles.map((item) => (
                                                item.roleId !== 0 ? <option value={item.roleId}>{item.roleName}</option> : null
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-md-3" data-select2-id="4">
                                        <label for="service_id">
                                            User Id: <span className="text-danger"> *</span>
                                        </label>
                                        {/* <select
                                            className="form-control select2 "
                                            name="service_id"
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={(e) => setUserId(e.target.value)}
                                        >
                                            <option value="">Select Id...</option>
                                            {userList.map((item) => (
                                                <option value={item.userId}>{item.name}</option>
                                            ))}
                                        </select> */}

                                        <Select
                                            className="select-box width100p"
                                            options={options}
                                            isSearchable
                                            value={selectedUserId}
                                            onChange={handleSelectChange}
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label className="font-weight-normal">
                                            Add Permission: <span className="text-danger"> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="add_permission"
                                            className="form-control"
                                            placeholder='Add Permission'
                                            onChange={(e) => setAddPermission(e.target.value)}
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label className="font-weight-normal">
                                            Edit Permission: <span className="text-danger"> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="edit_permission"
                                            className="form-control"
                                            placeholder='Edit Permission'
                                            onChange={(e) => setEditPermission(e.target.value)}
                                        />
                                    </div>

                                </div>

                                <div className='raw mt-4 text-center'>
                                    <div className='col-md-12'>
                                        <button className='btn bg-green' onClick={handlePermission}>Add Permission</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React, { useEffect } from 'react';
import { CiBellOn } from 'react-icons/ci';
import { AiOutlineFolderOpen } from 'react-icons/ai';
import { MdLogout } from 'react-icons/md';
import { FaUserAlt, FaWeixin, FaTimes, FaCheckCircle } from 'react-icons/fa';
import { BiUserCircle, BiHomeAlt, BiChevronDown } from 'react-icons/bi';
import { HiOutlineChat } from 'react-icons/hi';
import { RiShoppingBasketLine } from 'react-icons/ri';
import { BsGrid, BsFingerprint } from 'react-icons/bs';
import { useState, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RestService from '../http';
import Button from 'react-bootstrap/Button';
import RechargePlanView from '../components/utilityComponents/RechargePlanView';
import showToast from '../components/utilityComponents/APPToast';
import Logout from '../components/utilityComponents/Logout';
import Loader from '../components/Loader';
import { ToastContainer } from 'react-toastify';
import formatDate from '../components/utilityComponents/FormatDate';
import RechargeSlip from '../components/utilityComponents/RechargeSlip';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from "mdb-react-ui-kit";


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function Mobilerecharge({ name, ...props }) {

    const service = new RestService();
    const [show, setShow] = useState(false);
    const [openDropdowns, setOpenDropdowns] = useState([]);
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [rechargeNumber, setRechargeNumber] = useState('');
    const [operatorList, setOperatorList] = useState([]);
    const [rechargeAmount, setRechargeAmount] = useState(0);
    const [operatorName, setOperatorName] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [showOffer, setShowOffer] = useState(false);
    const [offerVisible, setOfferVisible] = useState(false);
    // const [planDetails, setPlanDetails] = useState({
    //     "TOPUP": []
    // });
    const [operatorCode, setOperatorCode] = useState();

    const [topup, setTopup] = useState([]);
    const [fultt, setFulltt] = useState([]);
    const [stv, setStv] = useState([]);
    const [sms, setSms] = useState([]);
    const [roming, setRoming] = useState([]);
    const [data, setData] = useState([]);
    const [jioPhone, setJioPhone] = useState([]);
    const [frc, setFrc] = useState([]);
    // const [loading, setLoading] = useState(true);
    const [balance, setBalance] = useState({});
    const [loading, setLoading] = useState(false);
    const [rechargeReport, setRechargeReport] = useState([]);
    const [userList, setUserList] = useState([]);
    const [userId, setUserId] = useState("");
    const [date] = useState(new Date());
    const [date1] = useState(new Date());
    const [status, setStatus] = useState('');
    // const [selectedRow, setSelectedRow] = useState(null);
    const [isRechargeSlipModalOpen, setIsRechargeSlipModalOpen] = useState(false);
    const [rechargeReciptData, setRechargeReciptData] = useState({});
    const [basicModal, setBasicModal] = useState(false);
    const [agentName] = useState(sessionStorage.getItem("name"));
    const [roleName] = useState(sessionStorage.getItem("role"));


    const handleClose = () => {
        setShow(false);
    }

    const fetchPlan = (mobile) => {
        setRechargeNumber(mobile);
        if (mobile.length === 10) {

            const requestBody = {
                type: "INFO",
                mobile: mobile,
            };

            setLoading(true);

            service.post('recharge/fetchRechargePlan', requestBody).then(result => {

                setLoading(false);
                if (result.status === "1") {
                    const rechargePlans = result.planDetails;
                    const numberInfo = result.info;
                    setOperatorCode(numberInfo.outCode);
                    setOperatorName(numberInfo.Operator);
                    console.log(numberInfo);

                    setFulltt(rechargePlans.FULLTT);
                    setTopup(rechargePlans.TOPUP);
                    setStv(rechargePlans.STV);
                    setData(rechargePlans.DATA);
                    setFrc(rechargePlans.FRC);
                    setRoming(rechargePlans.Romaing);
                    setSms(rechargePlans.SMS);
                    setJioPhone(rechargePlans.JioPhone)
                }
                setShow(true);
                setShowOffer(true);

            }).catch(error => {
                setLoading(false);
                console.log(error);
            });
        }
    };

    const handleShow = () => {
        setShow(true);
        // fetchPlan(rechargeNumber);
    }

    const handleClick = (index) => {
        const updatedOpenDropdowns = [...openDropdowns];

        if (updatedOpenDropdowns.includes(index)) {
            updatedOpenDropdowns.splice(updatedOpenDropdowns.indexOf(index), 1);
        } else {
            updatedOpenDropdowns.push(index);
        }

        setOpenDropdowns(updatedOpenDropdowns);
    };


    const handleClearForm = () => {
        setRechargeNumber('');
        setOperatorList('');
        setRechargeAmount('');
        setOfferVisible(false);
        setErrorMessage('');
    };


    const modalRef = useRef(null);

    const openForm = (event) => {
        event.preventDefault();
        modalRef.current.style.display = 'block';
    };

    const closeForm = () => {
        modalRef.current.style.display = 'none';
    };

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleOperator = () => {
        service.post(`operator/fetchOperatorByService/${1}`, null).then(result => {
            setOperatorList(result.report);
            console.log(result)

        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        handleOperator();
    }, []);

    const handleMobileRecharge = () => {

        const amount = parseFloat(rechargeAmount);

        let request = {
            "mobile": rechargeNumber,
            "amount": amount,
            "operatorCode": operatorCode
        };

        setLoading(true);

        service.post(`recharge/recharge`, request).then(response => {
            setBasicModal(false);
            setLoading(false);
            console.log(response);
            if (response.status === service.SUCCESS) {
                showToast("SUCCESS", response.message);

                const rechargeData = response.data;
                setRechargeReciptData(rechargeData);
                handleRechargeReport();
                setIsRechargeSlipModalOpen(true);

            } else if (response.status === service.EXCEPTIONAL_ERROR) {
                console.log(response);
                showToast("WARN", response.message);

            } else {
                showToast("DANGER", response.message);
                // const rechargeData = response.data;
                // setRechargeReciptData(rechargeData);
                // setIsRechargeSlipModalOpen(true);
            }
        }).catch(error => {
            console.error("Error submitting form:", error);

        });


    };

    useEffect(() => {
        service.fetchUserBalance().then(result => result.json()).then(balres => {
            console.log(balres);
            setBalance(balres);

        }).catch((error) => {
            console.log(error);
        })

    }, []);


    const handleRechargeAmount = (amount) => {
        console.log(amount);
        setRechargeAmount(amount);
        setShow(false);
    }


    const handleRechargeReport = () => {
        var raw = {
            "fromDate": formatDate(date),
            "toDate": formatDate(date1),
            "userid": userId,
            "status": status
        };

        service.post("report/fetchRechargeReport", raw).then(result => {
            console.log(result);
            setRechargeReport(result.data);

        }).catch((err) => {
            console.error(err);
            setUserList([]);
        });

    };

    useEffect(() => {
        handleRechargeReport();
    }, []);


    // const handleRepeatButtonClick = (item) => {
    //     setSelectedRow(item);
    //     setRechargeNumber(item.mobile);
    //     setOperatorCode(item.operatorId);
    //     setRechargeAmount(item.amount);
    // };


    const modalStyle = {
        display: 'block',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    };

    const modalContentStyle = {
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
        // padding: '20px',
    };

    const closeButtonStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        fontSize: '20px',
        cursor: 'pointer',
    };

    const handlePrintData = (status, data) => {
        setRechargeReciptData(data);
        setIsRechargeSlipModalOpen(status);
    }


    const handleCloseModal = () => {
        setBasicModal(false);
    }


    const handleModal = () => {


        if (rechargeNumber === undefined || rechargeNumber === "" || rechargeNumber === null) {
            showToast('WARN', 'PLEASE FILL YOUR MOBILE NO!!');
            return false;
        }

        if (operatorCode === undefined || operatorCode === "" || operatorCode === null) {
            showToast('WARN', 'PLEASE SELECT YOUR OPERATOR!!');
            return false;
        }

        if (rechargeAmount === undefined || rechargeAmount === "" || rechargeAmount === null) {
            showToast('WARN', 'PLEASE FILL AMOUNT!!');
            return false;
        }


        setBasicModal(true);

    };


    const handleOperatorChange = (e) => {
        const selectedOperatorCode = e.target.value;
        const selectedOperator = operatorList.find(item => item.operatorOutcode === selectedOperatorCode);

        setOperatorCode(selectedOperatorCode);
        setOperatorName(selectedOperator ? selectedOperator.operatorName : '');
    };


    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <ToastContainer />
                <CssBaseline />
                {loading && <Loader />}

                <AppBar position="fixed" className='bg-light' open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className="pull-right" style={{ marginLeft: "79%" }}>
                            <div className="btn-group main-balance" style={{ borderRadius: "10px", backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)', color: "white", marginTop: "2px" }}>
                                <button type="button" data-toggle="dropdown" style={{ color: "white", fontWeight: "500", background: "none", border: "none" }} >
                                    <img src="image/wallet.png" className="wallet-icon" />
                                    <span className="mybal-text-white">My Balance</span><br />
                                    <span className="badge" id="wallet" style={{ fontSize: "14px" }}>&#8377;
                                        {balance === undefined
                                            ? 0.0
                                            : balance.data}
                                    </span>
                                </button>
                            </div>&nbsp;&nbsp;

                            <div className="btn-group btn-group-list btn-group-notification notification">
                                <button style={{ background: "none", border: "none", color: "black", position: "relative", }} type="button" data-toggle="dropdown">
                                    <CiBellOn style={{ fontSize: "30px" }} />
                                    <span className="badge"
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            background: "red", // Change the background color as needed
                                            borderRadius: "50%",
                                            padding: "5px",
                                            fontSize: "12px",
                                            color: "white",
                                        }}
                                    >0</span></button>

                            </div>&nbsp;&nbsp;

                            <div className="btn-group btn-group-option">
                                <button style={{ background: "none", border: "none" }} type="button" data-toggle="dropdown"><span
                                    className='user-img'>
                                    <img className='rounded-circle img-circle' src='image/profile.png' width='40' alt='' /></span></button>
                                <ul className="dropdown-menu pull-right" role="menu">
                                    <li><a href="/editprofile"><FaUserAlt className='glyphicon' /> My Profile</a>
                                    </li>

                                    <li className="divider"></li>
                                    <li>
                                        <a onClick={() => Logout("your-url")}>
                                            <MdLogout className='glyphicon' />Sign Out
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>

                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            <img style={{ width: "150px" }} src='./image/softmintlogo.png' />
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>

                    </DrawerHeader>
                    <Divider />
                    <List>

                        <div className="leftpanel" style={{ backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)' }}>
                            <div className="media profile-left">
                                <a className="pull-left profile-thumb" href="/editprofile">
                                    <img
                                        src="./image/profile.png"
                                        alt="user"
                                        className="img-circle"
                                    /> </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                <div className="media-body">
                                    <h4 className="media-heading">{agentName}</h4>
                                    <small className="text-muted">{service.roles[new Number(roleName)]}</small>
                                </div>
                            </div>
                            <ul className="nav nav-pills nav-stacked">
                                <li className="active"><a href="/side"><BiHomeAlt className='house' /> <span>
                                    Dashboard</span></a></li>

                                <li className="parent" ><a href="#" onClick={() => handleClick(0)} ><BiUserCircle className='house' />
                                    <span> My Account</span>

                                    <BiChevronDown style={{ marginLeft: "75px" }} />
                                </a>

                                    <ul className={`children ${openDropdowns.includes(0) ? 'show' : ''}`}>
                                        <li className="" ><a href="/editprofile"> Edit Profile</a></li>
                                        <li className=""><a href="/kyc"> KYC Verification</a></li>
                                        <li className=""><a href="/kycmicroatm"> KYC Microatm</a></li>
                                        <li className=""><a href="/changepasswrd"> Change Password</a></li>
                                        <li className=""><a href="/commison"> My Commission</a></li>
                                        <li className=""><a href="print/certificate-print.php" target="_blank"> Print
                                            Certificate</a></li>
                                    </ul>

                                </li>
                                <li className="parent" ><a href="#" onClick={() => handleClick(1)}>
                                    <RiShoppingBasketLine className='house' /> <span>My Wallet</span>

                                    <BiChevronDown style={{ marginLeft: "85px" }} />
                                </a>
                                    <ul className={`children ${openDropdowns.includes(1) ? 'show' : ''}`}>
                                        <li className=""><a href="/accountinfo"> Account Info</a></li>
                                        <li className=""><a href="/bankaccount"> Bank Account Add</a></li>
                                        <li className=""><a href="/sendmoney"> Send Money</a></li>
                                        {/* <li className=""><a href="/addmoney"> Add Money</a></li> */}
                                        {/* <li className=""><a href="/fundrequest"> Fund Request</a></li> */}
                                        {/* <li className=""><a href="/wallettrans"> Wallet Transfer</a></li> */}
                                        {/* <li className=""><a href="/mpin"> Change Mpin</a></li> */}
                                    </ul>
                                </li>

                                <li className="">
                                    <a href="/retailerservice"><BsGrid className='house' /> <span>
                                        Services</span>
                                        <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                    </a>
                                </li>

                                <li className="parent"><a href="#" onClick={() => handleClick(2)}><AiOutlineFolderOpen className='house' /> <span> Reports</span>

                                    <BiChevronDown style={{ marginLeft: "97px" }} />
                                </a>
                                    <ul className={`children ${openDropdowns.includes(2) ? 'show' : ''}`}>
                                        <li className=""><a href="/rechargereportretailer"> Recharge Report</a></li>
                                        <li className=""><a href="/aepsreport"> AEPS Report </a></li>
                                        <li className=""><a href="/aadharpayreport"> AadharPay Report </a></li>
                                        <li className=""><a href="/retailertxnreport"> Transaction Report </a></li>
                                        <li className=""><a href="/payout"> Payout Report</a></li>
                                        <li className=""><a href="/nsdlreport"> NSDL Report</a></li>
                                        <li className=""><a href="/retailerinsurancereport"> Insurance Report</a></li>                                        <li className=""><a href="/retailerearningreport"> Earning Report</a></li>
                                        <li className=""><a href="/bbpsreport"> BBPS Report</a></li>


                                        <li className=""><a href="/matmreport"> MATM Report</a></li>
                                        <li className=""><a href="/retailercouponreport"> Coupon Req. Report</a></li>
                                        <li className=""><a href="/retailermatmlogreport"> MATM Log Report</a></li>
                                        <li className=""><a href="/retailermovetobankuserreport"> MoveToBankUser Report</a></li>
                                        <li className=""><a href="/dmtreport"> DMT Report</a></li>

                                    </ul>
                                </li>
                                <li className="">
                                    <a href="/feedback"><HiOutlineChat className='house' /> <span>
                                        Feedback</span>
                                        <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                    </a></li>

                                <li className="">
                                    <a href="/kyc"><BsFingerprint className='house' /> <span>
                                        AEPS E-Kyc</span>
                                        <BiChevronDown style={{ marginLeft: "70px", visibility: "hidden" }} />
                                    </a></li>
                            </ul>

                            <div className="menu-devide">&nbsp;</div>
                            <div style={{ display: open ? 'block' : 'none' }}>
                                <div className="customer-support-sec">
                                    <h3>Account Details</h3>
                                    <p>ICICI BANK</p>
                                    <p style={{ fontSize: "11px" }}>(SOFTMINT)</p>
                                    <p>A/C No - 260705000861</p>
                                    <p><span>IFSC -</span> ICIC0002607</p>
                                </div>
                                <div className="menu-devide">&nbsp;</div>
                                <div className="customer-support-sec" style={{ display: open ? 'block' : 'none' }}>
                                    <h3>Quick Support</h3>
                                    <p>SOFTMINT</p>
                                    <p>Phone : 9999726418</p>
                                    <p>What's App : 8809912400</p>
                                    <p>Email : <a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                        data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}>info@softmintdigital.com</a></p>
                                </div>
                            </div>
                            <div style={{
                                display: open ? 'none' : 'block',
                                height: '100vh'
                            }}>
                                <div>
                                    <h3></h3>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p><span></span></p>
                                </div>
                                <div className="menu-devide">&nbsp;</div>
                                <div className="">
                                    <h3></h3>
                                    <p></p>
                                    <p></p>
                                    <p><a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                        data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}></a></p>
                                </div>
                            </div>

                        </div><br />
                    </List>
                </Drawer>

                <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                    <RechargePlanView setAmount={handleRechargeAmount} show={show} setShow={handleClose} topup={topup} stv={stv} data={data} frc={frc} fultt={fultt} jioPhone={jioPhone} roming={roming} sms={sms} />
                    <DrawerHeader />
                    <div className="pageheader">
                        <div className="media">
                            <div className="media-body">
                                <ul className="breadcrumb">
                                    <li><a href="javascript:void(0);"><BiHomeAlt style={{ color: '#999' }} /></a></li>&nbsp;
                                    <li>Services</li>
                                </ul>
                                <h4>Mobile Recharge</h4>
                            </div>
                        </div>
                    </div>

                    <div className="contentpanel">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-horizontalx service-form" id="rechargeform">
                                    <div className="service-panel">
                                        <h4 className="service-title">Mobile Recharge </h4>
                                        <div className="panel-body">
                                            <div className="form-group">
                                                <div className="col-md-12">
                                                    <div className="input-box">
                                                        <label className="control-label">Recharge Number</label>
                                                        <input
                                                            type="text"
                                                            name="rechargeNumber"
                                                            className="form-control text-box"
                                                            value={rechargeNumber}
                                                            onChange={(e) => fetchPlan(e.target.value)}
                                                            maxLength="10"
                                                            autoFocus
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="col-md-12">
                                                    <div className="input-box selectInput-box">
                                                        <label className="control-label">Operator</label>
                                                        <select
                                                            className="form-control select-box width100p"
                                                            onChange={handleOperatorChange}
                                                            value={operatorCode}
                                                        >
                                                            <option value="">select operator</option>
                                                            {operatorList.map((item) => (
                                                                <option value={item.operatorOutcode}>
                                                                    {item.operatorName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="col-md-12">
                                                    <div className="input-box">
                                                        <label className="control-label">Recharge Amount</label>
                                                        <input
                                                            type="text"
                                                            name="rechargeAmount"
                                                            className="form-control text-box"
                                                            value={rechargeAmount}
                                                            onChange={(e) => handleRechargeAmount(e.target.value)}
                                                            maxLength="4"
                                                            autoComplete="off"
                                                        />

                                                        <Button variant="primary" onClick={handleShow} className="me-2 view-plan mt-2">
                                                            View Plan
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Error Message */}
                                            {errorMessage && <p className="error-message">{errorMessage}</p>}

                                            <div className="form-group" style={{ marginBottom: '0px' }}>
                                                <div className="col-md-12">
                                                    <button type="submit" className="btn btn-primary service-btn but-save" onClick={(e) => handleModal(e, rechargeNumber, operatorName, rechargeAmount)}>
                                                        Proceed to Recharge
                                                    </button>&nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn btn-default service-btn but-save"
                                                        onClick={handleClearForm}
                                                    >
                                                        Clear
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="offer" style={{ display: 'none' }} id="mobofferdt">
                                            <div className="offer-heading-sec">
                                                <h3 className="plan-heading">View Plans Details</h3>
                                                <span className="close-but" id="offerclose">✕</span>
                                            </div>
                                            <ul id="offerbody"></ul>
                                            <div className="border-angle">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {isRechargeSlipModalOpen && (
                                <div className="modal" style={modalStyle}>
                                    <div className="modal-content" style={modalContentStyle}>
                                        <span className="close" style={closeButtonStyle} onClick={() => setIsRechargeSlipModalOpen(false)}>
                                            &times;
                                        </span>
                                        <div>
                                            <RechargeSlip onClose={() => setIsRechargeSlipModalOpen(false)} data={rechargeReciptData} />
                                        </div>

                                    </div>
                                </div>
                            )}

                            <div className="col-md-6" style={{ overflowY: "scroll", }}>
                                <div className="service-panel report-panel">
                                    <h4 className="service-title">Recent Transactions</h4>

                                    <div className="report-box">
                                        <div className="row">
                                            <div className="table-responsive" style={{ padding: "3%" }}>
                                                <table className="table table-bordered mb30" style={{ overflowX: 'scroll' }}>
                                                    <thead>
                                                        <tr className="table-new">
                                                            <th className="px-1">SL.NO</th>
                                                            <th>MOBILE</th>
                                                            <th>Operator Id</th>
                                                            <th>AMOUNT</th>
                                                            <th className="px-1">Status</th>
                                                            <th>Date&Time</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(!rechargeReport || rechargeReport.length === 0) ? (
                                                            <tr>
                                                                <td colSpan="7">NO DATA AVAILABLE</td>
                                                            </tr>
                                                        ) : (
                                                            rechargeReport.map((item, index) => (
                                                                <tr className='text-center'>
                                                                    <td key={index}>{index + 1}</td>
                                                                    <td>{item.mobile}</td>
                                                                    <td>{item.operatorId}</td>
                                                                    <td>{item.amount}</td>
                                                                    <td style={{ color: item.status === "SUCCESS" ? 'green' : 'red' }}>{item.status}</td>
                                                                    <td>{`${item.date} & ${item.time}`}</td>
                                                                    <td>
                                                                        <button className='btn bg-red' onClick={() => handlePrintData(true, item)}>
                                                                            Print
                                                                        </button>
                                                                    </td>
                                                                </tr>

                                                            ))
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </Box>
            </Box>


            <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
                <MDBModalDialog>
                    <MDBModalContent style={{ marginLeft: "-100%", marginTop: "30%" }}>
                        <MDBModalHeader>
                            <MDBModalTitle>ARE YOU SURE, YOU WANT TO RECHARGE?</MDBModalTitle>
                            <MDBBtn
                                className="btn-close"
                                color="none"
                                onClick={handleCloseModal}
                            >
                            </MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className='container bg-green' style={{ borderRadius: "10px", padding: "2%" }}>
                                <div className='row'>
                                    <div className='col-md-3' style={{ marginTop: "4%" }}>
                                        <FaCheckCircle style={{ fontSize: "40px" }} />
                                    </div>

                                    <div className='col-md-9'>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th style={{ fontWeight: "bold" }}>Mobile : </th>
                                                    <td style={{ color: "white", fontWeight: "300" }}> {rechargeNumber}</td>
                                                </tr>

                                                <tr>
                                                    <th style={{ fontWeight: "bold" }}>Operator Name : </th>
                                                    <td style={{ color: "white", fontWeight: "300" }}> {operatorName}</td>
                                                </tr>

                                                <tr>
                                                    <th style={{ fontWeight: "bold" }}>Amount :</th>
                                                    <td style={{ color: "white", fontWeight: "300" }}>{rechargeAmount} /- </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                            </div>
                        </MDBModalBody>

                        <MDBModalFooter>
                            <button className='btn bg-red' color="secondary" onClick={handleCloseModal}>
                                Close
                            </button>
                            <button className='btn bg-green' onClick={handleMobileRecharge}>Proceed</button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <a href="https://api.whatsapp.com/send/?phone=8809912400&text&app_absent=0" target="_blank"
                className="whatsapp-but" title="">
                <img src="image/whatsapp-icon.png" alt="Whatapps" />
            </a>

            <a
                href="#"
                target="_blank"
                className="helpdesk-but"
                title="Request Callback !"
                onClick={openForm}
            >
                <img src="image/help-icon.png" alt="Help" />
            </a>

            <div className="chat-popup" ref={modalRef} id="myForm">
                <div action="#" className="helpdesk-container">
                    <h1>
                        <div className="callbackheader-icon">
                            <FaWeixin aria-hidden="true" style={{ fontSize: '35px', paddingTop: '7px' }} />
                        </div>
                        CallBack Request
                    </h1>
                    <label htmlFor="callback_name"><b>Full Name</b></label>
                    <input
                        type="text"
                        id="callback_name"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="Name"
                        required
                    />
                    <label htmlFor="callback_phone"><b>Contact Number</b></label>
                    <input
                        type="text"
                        id="callback_phone"
                        maxLength="10"
                        name=""
                        className="form-control textbox"
                        placeholder="+91 9000 0000 00"
                        required
                    />
                    <label htmlFor="callback_email"><b>Email ID</b></label>
                    <input
                        type="text"
                        id="callback_email"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="info@softmintdigital.com"
                        required
                    />
                    <label htmlFor="callback_msg"><b>Message (250 Character Only)</b></label>
                    <textarea
                        id="callback_msg"
                        maxLength="250"
                        placeholder="Type message.."
                        name="msg"
                        className="form-controlx"
                    ></textarea>
                    <button type="submit" className="btn" id="reqcallbut">
                        Send Now
                    </button>
                    <a href="#" className="close-but" title="" onClick={closeForm}>
                        <FaTimes aria-hidden="true" />
                    </a>
                </div>
            </div>

            <div className="modal fade recharge-model success-model" id="model-succ-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="model-succ-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button success-button" onClick="errordisplay()" data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model error-model" id="model-err-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className=""><img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Error Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-err-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button error-button" onClick="errordisplay()" data-dismiss="modal">Dismiss</button>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model process-model" id="model-process-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Process Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-process-body">
                            <p className="mb-0"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model " id="model-yn-save" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-body-pdf">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="getinvno" />
                        <button type="button" className="btn btn-success model-button success-button" onClick="succok()" data-dismiss="modal" id="modal-no-save">OK</button>
                    </div>
                </div>
            </div>


            );
        </>
    );
}

import React from 'react';
import Navbar from './Navbar';
import { FaMinusCircle, FaMoneyBill, FaFile } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import RestService from '../http';


export default function ViewTicketDetail() {
    const rest = new RestService();
    const location = useLocation();
    const [option, setOption] = useState({});
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const stateData = location.state;
    useEffect(() => {

        setData(stateData);
    }, [setData]);



    useEffect(() => {
        setOption(data); // Close the dropdown after selecting an option
    }, [setOption]);

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '553.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Detailed View Ticket</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/admin">Dashboard</a></li>
                                    <li className="breadcrumb-item"><a href="#">Member Master</a></li>
                                    <li className="breadcrumb-item"><a href="/managemember">View Ticket</a></li>
                                    <li className="breadcrumb-item active">View</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}> Ticket Id :</span> {data.ticketId}</label>
                                    </div>

                                    <div className="col-md-4">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Opening Date :</span>{data.openingDate}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Closing Date :</span> {data.closingDate}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Transaction Id :</span> {data.refNo}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>User Id :</span> {data.userId}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>User Message :</span> {data.message}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Employee Id :</span> {data.ticket_assignee} </label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Is Priority :</span> {data.is_priority}</label>
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Service :</span> {data.service}</label>
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Service Category :</span> {data.service_category}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Status :</span> {data.status}</label>
                                    </div>
                                </div>


                                <div className='mt-2 text-center' style={{ float: "right" }}>
                                    <a className='btn bg-blue' href='/viewtickets'>Back</a>&nbsp;&nbsp;
                                    <a className='btn bg-green' href='/viewtickets'>Transfer</a>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <a href="www.softmintdigital.com">SOFTMINT</a>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from "react";
import {
    FaUsers, FaGlobe, FaTachometerAlt, FaUserCog, FaChartPie, FaBitbucket,
    FaTools, FaAngleLeft, FaAngleDown, FaCogs
} from "react-icons/fa";

import { AiOutlineFileText } from "react-icons/ai";
import { BiSolidBriefcase } from "react-icons/bi";
import { PiNotePencilBold } from "react-icons/pi";
import { BsSquareFill } from "react-icons/bs";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import RestService from "../http";


export default function EmployeeNavbar() {
    const [searchVisible, setSearchVisible] = useState(false);
    const navigate = useNavigate();

    const logout = (url) => {
        const root = `#`;
        Swal.fire({
            title: "Are you sure you want to logout ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            buttons: {
                confirm: {
                    text: "YES",
                    className: "btn btn-primary",
                },
                cancel: {
                    text: "No",
                    className: "btn btn-danger",
                },
            },
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed.isConfirmed) {
                // Redirect to the login page (this is just an example)
                window.location.href = "/";
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("user");
            }
        });
    };

    const showSearch = (id) => {
        setSearchVisible(id === 1);
    };

    useEffect(() => {
        checkSidebar();
    }, []);

    const checkSidebar = () => {
        const sidebar = localStorage.getItem("sidebar");
        let src = "#";
        if (sidebar != null) {
            if (sidebar === "1") {
                $("#body").removeClass("sidebar-collapse sidebar-open");
            } else {
                $("#body").addClass("sidebar-collapse sidebar-close");
            }
            $("#brand_logo").attr("src", src);
        }
    };

    const collapseSidebar = () => {
        let src = "";
        const sidebar = localStorage.getItem("sidebar");
        if (sidebar == null) {
            $("#body").addClass("sidebar-collapse");
            localStorage.setItem("sidebar", "2");
        } else {
            if (sidebar === "2") {
                $("#body").removeClass("sidebar-collapse");
                localStorage.setItem("sidebar", "1");
            } else if (sidebar === "1") {
                $("#body").addClass("sidebar-collapse");
                localStorage.setItem("sidebar", "2");
            }
        }
        $("#brand_logo").attr("src", src);
        if ($("#body").hasClass("sidebar-open")) {
            $("#body").removeClass("sidebar-open");
            $("#body").addClass("sidebar-close");
        } else {
            $("#body").removeClass("sidebar-close");
            $("#body").addClass("sidebar-open");
        }
    };

    const rest = new RestService();
    const [memberDropdownOpen, setMemberDropdownOpen] = useState(false);
    const [transactionDropdownOpen, setTransactionDropdownOpen] = useState(false);
    const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
    const [supportDropdownOpen, setSupportDropdownOpen] = useState(false);
    const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);
    const [commSettingsDropdownOpen, setCommSettingsDropdownOpen] = useState(false);
    const [apiDropdownOpen, setApiDropdownOpen] = useState(false);
    const [commissionDropdownOpen, setCommissionDropdownOpen] = useState(false);
    const [adminCommDropdownOpen, setAdminCommDropdownOpen] = useState(false);

    const [utilitiesDropdownOpen, setUtilitiesDropdownOpen] = useState(false);
    const [permissionDropdownOpen, setPermissionDropdownOpen] = useState(false);
    const [hrDropdownOpen, setHrDropdownOpen] = useState(false);
    const [reportsDropdownOpen, setReportsDropdownOpen] = useState(false);
    const [webDropdownOpen, setWebDropdownOpen] = useState(false);
    const [developerDropdownOpen, setDeveloperDropdownOpen] = useState(false);
    const [name, setName] = useState(sessionStorage.getItem("name"));
    const [roleName, setRoleName] = useState(sessionStorage.getItem("role"));


    const toggleMemberDropdown = () => {
        setMemberDropdownOpen(!memberDropdownOpen);
        setTransactionDropdownOpen(false);
    };

    const toggleTransactionDropdown = () => {
        setTransactionDropdownOpen(!transactionDropdownOpen);
        setMemberDropdownOpen(false);
    };


    const toggleProfileDropdown = () => {
        setProfileDropdownOpen(!profileDropdownOpen);
        setMemberDropdownOpen(false);
        setTransactionDropdownOpen(false);
    };

    const toggleSupportDropdown = () => {
        setSupportDropdownOpen(!supportDropdownOpen);
        setMemberDropdownOpen(false);
        setTransactionDropdownOpen(false);
        setProfileDropdownOpen(false);
    };

    const toggleCommSettingsDropdown = () => {
        setCommSettingsDropdownOpen(!commSettingsDropdownOpen);
        setMemberDropdownOpen(false);
        setTransactionDropdownOpen(false);
        setProfileDropdownOpen(false);
        setSupportDropdownOpen(false);
    };

    const toggleSettingsDropdown = () => {
        setSettingsDropdownOpen(!settingsDropdownOpen);
        setCommSettingsDropdownOpen(false);
        setMemberDropdownOpen(false);
        setTransactionDropdownOpen(false);
        setProfileDropdownOpen(false);
        setSupportDropdownOpen(false);
    };

    const toggleUtilitiesDropdown = () => {
        setUtilitiesDropdownOpen(!utilitiesDropdownOpen);
        setMemberDropdownOpen(false);
        setTransactionDropdownOpen(false);
        setProfileDropdownOpen(false);
        setSupportDropdownOpen(false);
        setSettingsDropdownOpen(false);
        setCommSettingsDropdownOpen(false);
    };

    const toggleApiDropdown = () => {
        setApiDropdownOpen((prevState) => !prevState);
    };

    const toggleCommissionDropdown = () => {
        setCommissionDropdownOpen((prevState) => !prevState);
    };

    const toggleAdminCommissionDropdown = () => {
        setAdminCommDropdownOpen((prevState) => !prevState);
    };


    const togglePermissionDropdown = () => {
        setPermissionDropdownOpen(!permissionDropdownOpen)
        setHrDropdownOpen(false);
        setMemberDropdownOpen(false);
        setTransactionDropdownOpen(false);
        setProfileDropdownOpen(false);
        setSupportDropdownOpen(false);
        setSettingsDropdownOpen(false);
        setCommSettingsDropdownOpen(false);
        setUtilitiesDropdownOpen(false);
    };


    const toggleHrDropdown = () => {
        setHrDropdownOpen(!hrDropdownOpen);
        setPermissionDropdownOpen(false);
        setMemberDropdownOpen(false);
        setTransactionDropdownOpen(false);
        setProfileDropdownOpen(false);
        setSupportDropdownOpen(false);
        setSettingsDropdownOpen(false);
        setCommSettingsDropdownOpen(false);
        setUtilitiesDropdownOpen(false);
    };


    const toggleReportsDropdown = () => {
        setReportsDropdownOpen(!reportsDropdownOpen);
        setMemberDropdownOpen(false);
        setTransactionDropdownOpen(false);
        setProfileDropdownOpen(false);
        setSupportDropdownOpen(false);
        setSettingsDropdownOpen(false);
        setCommSettingsDropdownOpen(false);
        setUtilitiesDropdownOpen(false);
        setHrDropdownOpen(false);
        setPermissionDropdownOpen(false);
    };

    const toggleWebDropdown = () => {
        setWebDropdownOpen(!webDropdownOpen);
        setMemberDropdownOpen(false);
        setTransactionDropdownOpen(false);
        setProfileDropdownOpen(false);
        setSupportDropdownOpen(false);
        setSettingsDropdownOpen(false);
        setCommSettingsDropdownOpen(false);
        setUtilitiesDropdownOpen(false);
        setHrDropdownOpen(false);
        setReportsDropdownOpen(false);
        setPermissionDropdownOpen(false);
    };

    const toggleDeveloperDropdown = () => {
        setDeveloperDropdownOpen(!developerDropdownOpen);
        setMemberDropdownOpen(false);
        setTransactionDropdownOpen(false);
        setProfileDropdownOpen(false);
        setSupportDropdownOpen(false);
        setSettingsDropdownOpen(false);
        setCommSettingsDropdownOpen(false);
        setUtilitiesDropdownOpen(false);
        setHrDropdownOpen(false);
        setReportsDropdownOpen(false);
        setWebDropdownOpen(false);
        setPermissionDropdownOpen(false);
    };

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    return (
        <div id="body" className="hold-transition sidebar-mini layout-fixed">
            {/* sidebar-mini layout-fixed  */}

            <div className="wrapperr" id="layout-wrapper">

                <div
                    className={searchVisible ? "" : "d-none"}
                    id="search_div"
                    style={{ backgroundColor: "#0053AF" }}
                >
                    <form action="#" method="post" accept-charset="utf-8">
                        <input
                            type="hidden"
                            name="securityToken"
                            value="36f49cd8777d81015ef0eb7e51fa7dbd"
                        />
                        <div className="row p-1">
                            <div className="col-3"></div>
                            <div className="col-1">
                                <select
                                    className="form-control form-control-sm"
                                    name="action"
                                    required
                                >
                                    <option value="number" selected>
                                        Number
                                    </option>
                                    <option value="txn_id">Txn ID</option>
                                </select>
                            </div>
                            <div className="col-3">
                                <div className="input-group input-group-sm">
                                    <input
                                        className="form-control form-control-sm"
                                        type="search"
                                        name="value"
                                        placeholder="Search"
                                        aria-label="Search"
                                        required
                                    />
                                    <div className="input-group-append">
                                        <button
                                            className="btn btn-sm "
                                            style={{ backgroundColor: "#767679" }}
                                            type="submit"
                                        >
                                            <i className="fas fa-search"></i> Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-1 pt-1">
                                <a
                                    href="#"
                                    className="text-active"
                                    onClick="show_search(2)"
                                >
                                    <i className="fas fa-close"></i>
                                </a>
                            </div>
                        </div>
                    </form>
                </div>

                {/* /.navbar  */}

                {/* Main Sidebar Container */}

                <aside
                    className="main-sidebar sidebar-dark-primary elevation-4"
                    style={{
                        backgroundColor: "#6200ff",
                    }}
                >
                    {/* Brand Logo  */}

                    <div className="text-center" >
                        <a href="/" className="brand-link bg-white">
                            <img
                                src="./image/softmintlogo.png"
                                style={{
                                    marginTop: "-11px",
                                    width: "161px",

                                }}
                            />
                        </a>
                    </div>

                    {/* Sidebar user panel (optional)  */}

                    {/* Sidebar Menu  */}

                    <div className="sidebar">
                        <div className="user-panel mt-2 mb-2 d-flex">
                            <div className="justify-content-center align-self-center">
                                <img
                                    src="./image/profile.png"
                                    className="img-square elevation-2"
                                    alt="Image"
                                    style={{ borderRadius: "50px" }}
                                />
                            </div>
                            <div className="info">
                                <a
                                    href="#"
                                    className="d-block"
                                    style={{ paddingLeft: "18px", color: 'white', textDecoration: "none", fontWeight: "bold", textTransform: "uppercase" }}
                                >
                                    {name}
                                </a>
                                <small className="text-white" style={{ paddingLeft: "18px" }}>
                                    {/* {roleName} */}
                                    {rest.roles[new Number(roleName)]}
                                    <i className="fa fa-circle text-success pl-2"></i>
                                </small>
                            </div>
                        </div>
                        <nav className="mt-2">
                            <ul
                                className="nav nav-pills nav-sidebar flex-column"
                                data-widget="treeview"
                                role="menu"
                                data-accordion="false"
                            >
                                <li className="nav-item">
                                    <a href="/employeedashboard" className="nav-link active">
                                        <FaTachometerAlt className="nav-icon fas fa-tachometer-alt" />
                                        <p >Dashboard</p>
                                    </a>
                                </li>

                                <li
                                    className={`nav-item has-treeview ${memberDropdownOpen ? "menu-open" : ""
                                        }`}
                                >
                                    <a
                                        href="#"
                                        className="nav-link"
                                        onClick={toggleMemberDropdown}
                                    >
                                        <FaUsers className="nav-icon fas fa-users" />
                                        <p style={{ fontWeight: '600' }}>
                                            Marketing Employee
                                            {memberDropdownOpen ? (
                                                <FaAngleDown className="fas fa-angle-down right" />
                                            ) : (
                                                <FaAngleLeft className="fas fa-angle-left right" />
                                            )}
                                        </p>
                                    </a>

                                    <ul
                                        className={`nav nav-treeview ${memberDropdownOpen ? "open" : ""
                                            }`}
                                    >
                                        <li className="nav-item">
                                            <a href="/fetchemp" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Employee</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/membercommison" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Member Commission</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li
                                    className={`nav-item has-treeview ${transactionDropdownOpen ? "menu-open" : ""
                                        }`}
                                >
                                    <a
                                        href="#"
                                        className="nav-link"
                                        onClick={toggleTransactionDropdown}
                                    >
                                        <PiNotePencilBold className="nav-icon fas fa-edit" />
                                        <p style={{ fontWeight: '600' }}>
                                            Transaction
                                            {transactionDropdownOpen ? (
                                                <FaAngleDown className="fas fa-angle-down right" />
                                            ) : (
                                                <FaAngleLeft className="fas fa-angle-left right" />
                                            )}
                                        </p>
                                    </a>

                                    <ul
                                        className={`nav nav-treeview ${transactionDropdownOpen ? "open" : ""
                                            }`}
                                    >
                                        <li className="nav-item">
                                            <a href="/transactionhis" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Transaction History</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li
                                    className={`nav-item has-treeview ${profileDropdownOpen ? "menu-open" : ""
                                        }`}
                                >
                                    <a
                                        href="#"
                                        className="nav-link "
                                        onClick={toggleProfileDropdown}
                                    >
                                        <FaUserCog className="nav-icon fas fa-user-cog" />
                                        <p style={{ fontWeight: '600' }}>
                                            Profile
                                            {profileDropdownOpen ? (
                                                <FaAngleDown className="fas fa-angle-down right" />
                                            ) : (
                                                <FaAngleLeft className="fas fa-angle-left right" />
                                            )}
                                        </p>
                                    </a>
                                    <ul
                                        className={`nav nav-treeview ${profileDropdownOpen ? "open" : ""
                                            }`}
                                    >
                                        <li className="nav-item">
                                            <a href="/profileupdate" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Profile Update</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/changepaswrd" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Change Password</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li
                                    className={`nav-item has-treeview ${supportDropdownOpen ? "menu-open" : ""
                                        }`}
                                >
                                    <a
                                        href="#"
                                        className="nav-link "
                                        onClick={toggleSupportDropdown}
                                    >
                                        <FaChartPie className="nav-icon fas fa-chart-pie" />
                                        <p style={{ fontWeight: '600' }}>
                                            Support
                                            {supportDropdownOpen ? (
                                                <FaAngleDown className="fas fa-angle-down right" />
                                            ) : (
                                                <FaAngleLeft className="fas fa-angle-left right" />
                                            )}
                                        </p>
                                    </a>
                                    <ul
                                        className={`nav nav-treeview ${supportDropdownOpen ? "open" : ""
                                            }`}
                                    >
                                        <li className="nav-item has-treeview">
                                            <a href="/dispute" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Dispute</p>
                                            </a>
                                        </li>
                                        <li className="nav-item has-treeview">
                                            <a href="/contactinfo" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Contact Information</p>
                                            </a>
                                        </li>
                                        <li className="nav-item has-treeview">
                                            <a href="custcare" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Customer Care</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li
                                    className={`nav-item has-treeview ${commSettingsDropdownOpen ? "menu-open" : ""
                                        }`}
                                >
                                    <a
                                        href="#"
                                        className="nav-link "
                                        onClick={toggleCommSettingsDropdown}
                                    >
                                        <FaCogs className="fa fa-cogs mr-2" aria-hidden="true" />
                                        <p style={{ fontWeight: '600' }}>
                                            Commission Settings
                                            {commSettingsDropdownOpen ? (
                                                <FaAngleDown className="fas fa-angle-down right" />
                                            ) : (
                                                <FaAngleLeft className="fas fa-angle-left right" />
                                            )}
                                        </p>
                                    </a>

                                    <ul
                                        className={`nav nav-treeview ${commSettingsDropdownOpen ? "open" : ""
                                            }`}
                                        style={{ overflowX: "hidden", overflowY: "scroll" }}
                                    >
                                        <li
                                            className={`nav-item has-treeview ${apiDropdownOpen ? "menu-open" : ""
                                                }`}
                                        >
                                            <a
                                                href="#"
                                                className="nav-link"
                                                onClick={toggleApiDropdown}
                                            >
                                                <BsSquareFill
                                                    className="fas fa-square nav-icon"
                                                    aria-hidden="true"
                                                />
                                                <p>
                                                    API
                                                    {apiDropdownOpen ? (
                                                        <FaAngleDown className="fas fa-angle-down right" />
                                                    ) : (
                                                        <FaAngleLeft className="fas fa-angle-left right" />
                                                    )}
                                                </p>
                                            </a>
                                            <ul
                                                className={`nav nav-treeview ${apiDropdownOpen ? "open" : ""
                                                    }`}
                                            >
                                                <li className="nav-item ml-2">
                                                    <a href="/apicommison" className="nav-link">
                                                        <BsSquareFill className="fas fa-square nav-icon" />
                                                        <p>Api</p>
                                                    </a>
                                                </li>
                                                <li className="nav-item ml-2">
                                                    <a href="/apiswitching" className="nav-link">
                                                        <BsSquareFill className="fas fa-square nav-icon" />
                                                        <p>API Switching</p>
                                                    </a>
                                                </li>

                                                <li className="nav-item ml-2">
                                                    <a href="/commission" className="nav-link">
                                                        <BsSquareFill className="fas fa-square nav-icon" />
                                                        <p>Commission Setup</p>
                                                    </a>
                                                </li>

                                                {/* <li className="nav-item ml-2">
                          <a href="/apiamount" className="nav-link">
                            <BsSquareFill className="fas fa-square nav-icon" />
                            <p>API Amounts</p>
                          </a>
                        </li> */}

                                                <li className="nav-item ml-2">
                                                    <a href="/apicredential" className="nav-link">
                                                        <BsSquareFill className="fas fa-square nav-icon" />
                                                        <p>API Credentials</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/addslabs" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Add Slabs</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/addcommcharge" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Add Comm Charge</p>
                                            </a>
                                        </li>

                                        <li
                                            className={`nav-item has-treeview ${commissionDropdownOpen ? "menu-open" : ""
                                                }`}
                                        >
                                            <a
                                                href="#"
                                                className="nav-link"
                                                onClick={toggleCommissionDropdown}
                                            >
                                                <BsSquareFill
                                                    className="fas fa-square nav-icon"
                                                    aria-hidden="true"
                                                />
                                                <p>
                                                    User Commission
                                                    {commissionDropdownOpen ? (
                                                        <FaAngleDown className="fas fa-angle-down right" />
                                                    ) : (
                                                        <FaAngleLeft className="fas fa-angle-left right" />
                                                    )}
                                                </p>
                                            </a>
                                            <ul
                                                className={`nav nav-treeview ${commissionDropdownOpen ? "open" : ""
                                                    }`}
                                            >
                                                <li className="nav-item ml-2">
                                                    <a href="/usercommission" className="nav-link">
                                                        <BsSquareFill className="fas fa-square nav-icon" />
                                                        <p>Commission Setup</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>


                                        <li
                                            className={`nav-item has-treeview ${adminCommDropdownOpen ? "menu-open" : ""
                                                }`}
                                        >
                                            <a
                                                href="#"
                                                className="nav-link"
                                                onClick={toggleAdminCommissionDropdown}
                                            >
                                                <BsSquareFill
                                                    className="fas fa-square nav-icon"
                                                    aria-hidden="true"
                                                />
                                                <p>
                                                    Admin Commission
                                                    {commissionDropdownOpen ? (
                                                        <FaAngleDown className="fas fa-angle-down right" />
                                                    ) : (
                                                        <FaAngleLeft className="fas fa-angle-left right" />
                                                    )}
                                                </p>
                                            </a>
                                            <ul
                                                className={`nav nav-treeview ${adminCommDropdownOpen ? "open" : ""
                                                    }`}
                                            >
                                                <li className="nav-item ml-2">
                                                    <a href="/admincommission" className="nav-link">
                                                        <BsSquareFill className="fas fa-square nav-icon" />
                                                        <p>Admin Commission Setup</p>
                                                    </a>
                                                </li>

                                                <li className="nav-item ml-2">
                                                    <a href="/masteradmincomm" className="nav-link">
                                                        <BsSquareFill className="fas fa-square nav-icon" />
                                                        <p>Master Admin Comm Setup</p>
                                                    </a>
                                                </li>

                                                <li className="nav-item ml-2">
                                                    <a href="/bbpsadmincomm" className="nav-link">
                                                        <BsSquareFill className="fas fa-square nav-icon" />
                                                        <p>BBPS Admin Comm Setup</p>
                                                    </a>
                                                </li>

                                                <li className="nav-item ml-2">
                                                    <a href="/bbpsusercomm" className="nav-link">
                                                        <BsSquareFill className="fas fa-square nav-icon" />
                                                        <p>BBPS User Comm Setup</p>
                                                    </a>
                                                </li>

                                            </ul>
                                        </li>
                                    </ul>
                                </li>


                                <li
                                    className={`nav-item has-treeview ${settingsDropdownOpen ? "menu-open" : ""
                                        }`}
                                >
                                    <a
                                        href="#"
                                        className="nav-link "
                                        onClick={toggleSettingsDropdown}
                                    >
                                        <FaCogs className="fa fa-cogs mr-2" aria-hidden="true" />
                                        <p style={{ fontWeight: '600' }}>
                                            Settings
                                            {settingsDropdownOpen ? (
                                                <FaAngleDown className="fas fa-angle-down right" />
                                            ) : (
                                                <FaAngleLeft className="fas fa-angle-left right" />
                                            )}
                                        </p>
                                    </a>
                                    <ul
                                        className={`nav nav-treeview ${settingsDropdownOpen ? "open" : ""
                                            }`}
                                        style={{ overflowX: "hidden", overflowY: "scroll" }}
                                    >

                                        <li className="nav-item">
                                            <a href="servicemast" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Services Master</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/operatormast" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Operator Master</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/packagemast" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Add Plan</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/chargesetup" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Charge Setup</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/rechargeset" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Recharge Setting</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/amountblock" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Recharge Amount Block</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/amountcharge" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Account Charges</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/upiapps" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Payment UPI Apps</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/paymentsetting" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Payment Settings</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/panelacces" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Panel Access</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/disputecmnt" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Dispute Comment</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li
                                    className={`nav-item has-treeview ${permissionDropdownOpen ? "menu-open" : ""
                                        }`}
                                >
                                    <a href="#" className="nav-link " onClick={togglePermissionDropdown}>
                                        <BiSolidBriefcase className="fas fa-briefcase nav-icon" />
                                        <p style={{ fontWeight: '600' }}>
                                            Permission Management
                                            {permissionDropdownOpen ? (
                                                <FaAngleDown className="fas fa-angle-down right" />
                                            ) : (
                                                <FaAngleLeft className="fas fa-angle-left right" />
                                            )}
                                        </p>
                                    </a>

                                    <ul
                                        className={`nav nav-treeview ${permissionDropdownOpen ? "open" : ""
                                            }`}
                                    >
                                        <li className="nav-item">
                                            <a href="/movetobankpermissiontable" className="nav-link ">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Bank Permission Table</p>
                                            </a>
                                        </li>

                                    </ul>
                                </li>

                                <li
                                    className={`nav-item has-treeview ${hrDropdownOpen ? "menu-open" : ""
                                        }`}
                                >
                                    <a href="#" className="nav-link " onClick={toggleHrDropdown}>
                                        <BiSolidBriefcase className="fas fa-briefcase nav-icon" />
                                        <p style={{ fontWeight: '600' }}>
                                            HR Management
                                            {hrDropdownOpen ? (
                                                <FaAngleDown className="fas fa-angle-down right" />
                                            ) : (
                                                <FaAngleLeft className="fas fa-angle-left right" />
                                            )}
                                        </p>
                                    </a>

                                    <ul
                                        className={`nav nav-treeview ${hrDropdownOpen ? "open" : ""
                                            }`}
                                    >
                                        <li className="nav-item">
                                            <a href="/employee" className="nav-link ">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Employee</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/systemuser" className="nav-link ">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>System Users</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li
                                    className={`nav-item has-treeview ${reportsDropdownOpen ? "menu-open" : ""
                                        }`}
                                >
                                    <a
                                        href="#"
                                        className="nav-link "
                                        onClick={toggleReportsDropdown}
                                    >
                                        <AiOutlineFileText className="nav-icon far fa-file-alt" />
                                        <p style={{ fontWeight: '600' }}>
                                            Reports
                                            {reportsDropdownOpen ? (
                                                <FaAngleDown className="fas fa-angle-down right" />
                                            ) : (
                                                <FaAngleLeft className="fas fa-angle-left right" />
                                            )}
                                        </p>
                                    </a>
                                    <ul
                                        className={`nav nav-treeview ${reportsDropdownOpen ? "open" : ""
                                            }`}
                                        style={{ overflowX: "hidden", overflowY: "scroll" }}
                                    >

                                        <li className="nav-item">
                                            <a href="/viewallreport" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>View All Report</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/rechargereport" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Recharge Report</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/adminAepsReport" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>AEPS Report</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/adminpayoutreport" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Payout Reports</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/allmovetobankuser" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>MoveToBank User Reports</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/salesreport" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Sales Reports</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/ledgerreport" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Ledger Reports</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/viewallactiveinactive" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>All Active/Inactive Reports</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/viewtickets" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>View Tickets</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/viewpendingtickets" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>View Pending Tickets</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/assignticket" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>View Assign Tickets</p>
                                            </a>
                                        </li>


                                        <li className="nav-item">
                                            <a href="/earningreport" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Earning Report</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/adminprofitreport" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Admin Profit Report</p>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/fundreqreport" className="nav-link">
                                                <BsSquareFill className="fas fa-square nav-icon" />
                                                <p>Fund Request Report</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                            </ul>
                        </nav>
                    </div>

                </aside>

            </div>
        </div>
    );
}

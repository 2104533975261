import React from 'react';
import Navbar from './Navbar';
import { FaMinusCircle, FaMoneyBill, FaFile } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import RestService from '../http';


export default function Viewmember() {
    const rest = new RestService();
    const location = useLocation();
    const [option, setOption] = useState({});
    const [data, setData] = useState({});
    const stateData = location.state;

    useEffect(() => {
        setData(stateData);
    }, [setData]);

    const [uplineUser, setUplineUser] = useState({});

    const fetchData = async () => {
        try {

            rest.post(`user/findById/${stateData.uplineId}`).then(result => {
                if (result.status === rest.SUCCESS) {
                    setUplineUser(result.data);
                } else {
                    setUplineUser({});
                }

            }).catch(error => {
                setUplineUser({});
            });
        } catch (error) {
            console.log('error', error);
            return null; 
        }
    }



    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        setOption(data); 
    }, [setOption]);

    const handleShowPassword = () => {
        const form = document.getElementById('form');
        form.style.display = 'block';
    };

    const handleCheckPassword = (e) => {
        e.preventDefault();
    };

    const handleDebitCharges = () => {
        // Handle debit account charges logic here
    };

    const handleChangeCommissionType = () => {
        // Handle change commission type logic here
    };

    const handleKYCStatus = () => {
        // Handle KYC status logic here
    };

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '203.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Edit Member</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/admin">Dashboard</a></li>
                                    <li className="breadcrumb-item"><a href="#">Member Master</a></li>
                                    <li className="breadcrumb-item"><a href="/managemember">Manage Member</a></li>
                                    <li className="breadcrumb-item active">View</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <p className="font-weight-bold text-primary m-0">Business Information</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Customer Type : </span>
                                            MASTER DISTRIBUTOR
                                        </label>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Business Name :</span> NA</label>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="font-weight-normal" >GST Number : </label>
                                    </div>
                                </div>
                                <hr />
                                <p className="font-weight-bold text-primary m-0">Personal Information</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}> Name :</span> {data.name}</label>
                                    </div>

                                    <div className="col-md-4">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Date of Birth :</span> NA </label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Address :</span> {data.address}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Village :</span> {data.village}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Pincode :</span> {data.pinCode}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Taluka :</span> {data.taluka}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>District :</span> {data.district} </label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>State :</span> {data.state}</label>
                                    </div>
                                </div>
                                <hr />
                                <p className="font-weight-bold text-primary m-0">Contact Information</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Email :</span> {data.email}</label>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Mobile :</span> {data.mobile}</label>
                                    </div>
                                </div>
                                <hr />
                                <p className="font-weight-bold text-primary m-0">Balances</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">Recharge &amp; Utility: <strong>{data.balance}</strong></label>
                                    </div>
                                </div>
                                <hr />
                                <p className="font-weight-bold text-primary m-0">KYC Information</p>
                                <div className="row">
                                    <div className="col-md-4">KYC :
                                        <span className="text-warning">Not Verified</span>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Aadhaar Number :</span> {data.aadhaar}

                                        </label>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Pan Number :</span> {data.pan}
                                        </label>
                                    </div>
                                </div>
                                <hr />
                                <p className="font-weight-bold text-primary m-0">Account Information</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <input type="hidden" value="1" id="pass_view_val" />
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Password :</span>
                                        </label>
                                        <button className="btn btn-sm btn-light" onClick={handleShowPassword}>
                                            <i id="eye" className="fa fa-eye"></i>
                                        </button>

                                        <div id="form" style={{ display: 'none' }}>
                                            <form onSubmit={handleCheckPassword} action="/viewmember" method="post" acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="55b9bc60523c0753e329ec7da5c677c6" />

                                                <div className="row">
                                                    <div className="col-9">
                                                        <input type="password" className="form-control" name="pass" placeholder="Enter admin password" />
                                                    </div>
                                                    <div className="col-3">
                                                        <button className="btn btn-sm btn-danger mt-1">Check</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Pin :</span> NA
                                        </label>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Upline :</span> {uplineUser.name}
                                        </label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Package :</span> NA
                                        </label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Recharge Wallet Lock :</span> {data.lockedAmount}
                                        </label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Fund Request Lock :</span> {data.lockedAmount}
                                        </label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Money Transfer Lock :</span> {data.lockedAmount}
                                        </label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Package Limit :</span> 2</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600' }}>Commission Type :</span> NA
                                        </label>
                                    </div>
                                </div>
                                <hr />
                                <div className="row ">
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">

                                            <span style={{ fontWeight: '600' }}>Status : </span>{data.status == 0 ? <kbd className="bg-danger">Deactive</kbd> : <kbd className="bg-success">Active</kbd>}</label>
                                    </div>
                                </div>
                                <hr />
                                <div className="text-center">
                                    <Link className="btn btn-sm btn-primary" to="/editmember" state={data}>
                                        <i className="fa fa-edit"></i> Edit
                                    </Link>&nbsp;
                                    <a className="btn btn-sm btn-danger" href="#" onClick={handleDebitCharges}>
                                        <FaMinusCircle className="fas fa-minus-circle" /> Debit Account Charges
                                    </a>&nbsp;
                                    <a className="btn btn-sm btn-info" data-target="#myModal" data-toggle="modal" data-backdrop="static" data-keyboard="false" href="#" onClick={handleChangeCommissionType}>
                                        <FaMoneyBill className="fas fa-money" /> Change Commission Type
                                    </a>&nbsp;
                                    <a className="btn btn-sm btn-warning" href="/memberkyc" onClick={handleKYCStatus}>
                                        <FaFile className="fas fa-file" /> KYC
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <a href="www.softmintdigital.com">SOFTMINT</a>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React, { useEffect } from "react";
import Navbar from "./Navbar";
import { useState } from "react";
import RestService from "../http";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import { FaEdit } from "react-icons/fa";
import Loader from "./Loader";

export default function Apicommison() {

  const service = new RestService();

  const [showForm, setShowForm] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [operatorList, setOperatorList] = useState([]);
  const [serviceCode, setServiceCode] = useState('')
  const [apiName, setApiName] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const [credential, setCredential] = useState('');
  const [apiUserName, setApiUserName] = useState('');
  const [apiPassword, setApiPassword] = useState('');
  const [apiUrl, setApiUrl] = useState('');
  const [apiList, setApiList] = useState([]);
  const [loading, setLoading] = useState(false);


  const showToast = (status, message) => {
    if (status === "SUCCESS") {
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (status === "DANGER") {
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.warn(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const toggleFormVisibility = () => {
    setShowForm((prevShowForm) => !prevShowForm);
  };


  const handlePlanList = () => {
    service.post("package/getAllPackageType", null).then((result) => {
      setPlanList(result.data);
    })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    handlePlanList();
  }, []);


  const handleServiceId = (e) => {
    setServiceCode(e.target.value);
    var raw = {
      serviceType: e.target.value
    }
    service.post('operator/fetchOperator', raw).then(result => {
      setOperatorList(result.report === undefined ? [] : result.report)
    });
  }


  const handleServiceCode = () => {

    service.post("service/getAllService", null).then((result) => {
      if (result.report === undefined) {
        setServiceList([]);
      } else {
        console.log(result);
        setServiceList(result.report);

      }
    })
  }

  useEffect(() => {
    handleServiceCode();
  }, []);


  const handleAddApi = () => {
    var raw = {
      "apiname": apiName,
      "username": userName,
      "password": password,
      "baseUrl": baseUrl,
      "serviceType": serviceCode,
      "credential": credential,
    };

    setLoading(true);

    service.post("api/addApi", raw).then((result) => {
      setLoading(false);

      console.log(result);
      if (result.status === 1) {
        showToast("SUCCESS", result.message);
      } else {
        showToast("FAILED", result.message);
      }
    })
  }


  const handleApiUserName = (e) => {
    console.log(e.target.value);
    setApiUserName(e.target.value);
  }

  const handleApiPassword = (e) => {
    console.log(e.target.value);
    setApiPassword(e.target.value);
  }

  const handleUrl = (e) => {
    console.log(e.target.value);
    setApiUrl(e.target.value);
  }

  const handleFetchService = (e) => {
    var raw = {
      "serviceType": e.target.value,
    };

    service.post("api/fetchApiByService", raw).then(result => {
      setApiList(result.report);
      console.log(result);
    }).catch(error => {
      console.log(error);
    })
  }

  return (
    <>
      <Navbar />
      <ToastContainer />
      {loading && <Loader />}

      <div className="content-wrapper" style={{ minHeight: "525.667px" }}>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6 float-right">
                <h1 className="m-0 text-dark">API </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Settings</Link>
                  </li>
                  <li className="breadcrumb-item active">API</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">

          <div className="container-fluid">

            {showForm && (
              <div className="card" id="addpack">
                <div className="card-body">
                  <div>

                    <div className="row" data-select2-id="4">

                      <div className="col-md-3" data-select2-id="4">
                        <label for="assign_commission">
                          Api Name: <span className="text-danger"> *</span>
                        </label>
                        <input
                          className="form-control select2 "
                          required=""
                          id="service_id"
                          data-select2-id="service_id"
                          tabindex="-1"
                          aria-hidden="true"
                          placeholder="Api Name"
                          onChange={(e) => setApiName(e.target.value)}
                        />

                      </div>

                      <div className="col-md-3" data-select2-id="4">
                        <label for="assign_commission">
                          User Name: <span className="text-danger"> *</span>
                        </label>
                        <input
                          className="form-control select2 "
                          required=""
                          id="service_id"
                          data-select2-id="service_id"
                          tabindex="-1"
                          aria-hidden="true"
                          placeholder="User Name"
                          onChange={(e) => setUserName(e.target.value)}
                        />

                      </div>

                      <div className="col-md-3" data-select2-id="4">
                        <label for="assign_commission">
                          Password: <span className="text-danger"> *</span>
                        </label>
                        <input
                          className="form-control select2 "
                          required=""
                          id="service_id"
                          data-select2-id="service_id"
                          tabindex="-1"
                          aria-hidden="true"
                          type="password"
                          placeholder="Enter Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />

                      </div>

                      <div className="col-md-3" data-select2-id="4">
                        <label for="assign_commission">
                          Base URL: <span className="text-danger"> *</span>
                        </label>
                        <input
                          className="form-control select2 "
                          required=""
                          id="service_id"
                          data-select2-id="service_id"
                          tabindex="-1"
                          aria-hidden="true"
                          placeholder="Base URL"
                          onChange={(e) => setBaseUrl(e.target.value)}
                        />

                      </div>

                      <div className="col-md-3 mt-2" data-select2-id="4">
                        <label for="service_id">
                          Service Type: <span className="text-danger"> *</span>
                        </label>
                        <select
                          className="form-control select2 "
                          name="service_id"
                          required=""
                          id="service_id"
                          data-select2-id="service_id"
                          tabindex="-1"
                          aria-hidden="true"
                          onChange={handleServiceId}

                        >
                          <option>select Service Type</option>
                          {serviceList.map((item) => (
                            <option key={item.serviceType} value={item.serviceType}>{item.serviceName}</option>
                          ))}

                        </select>

                        <div className=" mt-3 " >

                          <label>
                            <textarea onChange={(e) => setCredential(e.target.value)} name="postContent" rows={4} cols={150} placeholder="Input API Credential" style={{ paddingLeft: "10px" }} />
                          </label>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>

                <div className="text-center mb-2">
                  <input
                    type="submit"
                    className="btn btn-primary btn-hover text-white"
                    value="Add API"
                    onClick={handleAddApi}
                  />
                </div>
              </div>
            )}

            <div className="card">
              <div className="card-body">

                <div className="col-md-3" >
                  <label for="assign_commission">
                    Service Type: <span className="text-danger"> *</span>
                  </label>
                  <select
                    className="form-control select2 "
                    id="service_id"
                    data-select2-id="service_id"
                    tabindex="-1"
                    aria-hidden="true"
                    onChange={handleFetchService}
                  >
                    <option>Select Service Type</option>
                    {serviceList.map((item) => (
                      <option key={item.serviceType} value={item.serviceType}>{item.serviceName}</option>
                    ))}

                  </select>
                </div>

                <h1 className="m-0 text-dark float-right">

                  <span
                    className="btn btn-sm btn-primary"
                    onClick={toggleFormVisibility}
                  >
                    {/* Render only the "CANCEL" text if showForm is true */}
                    {showForm ? (
                      "CANCEL"
                    ) : (
                      <>
                        <i id="sign" className="fa fa-plus" style={{}}></i> ADD API
                      </>
                    )}
                  </span>
                </h1>

                <div className="clearfix"></div><br />
                <div className="table-responsive">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th># ID</th>
                        <th>Api Id</th>
                        <th>API Name</th>
                        <th>User Name</th>
                        <th>Password</th>
                        <th>URL</th>
                        <th>Service</th>
                        <th>Date</th>
                        <th>Credential</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {apiList.map((item, index) => (
                        <tr>

                          <td>{index + 1}</td>
                          <td>{item.apiId}</td>
                          <td>{item.apiName}</td>
                          <td><input type="text" value={item.username} onChange={handleApiUserName} /></td>
                          <td><input type="text" value={item.password} onChange={handleApiPassword} /></td>
                          <td><input type="text" value={item.url} onChange={handleUrl} /></td>
                          <td>{item.serviceType}</td>
                          <td>{item.date}</td>
                          <td>
                            <textarea value={item.credential} onChange={(e) => setCredential(e.target.value)} name="postContent" rows={2} cols={20} placeholder="Input API Credential" />
                          </td>
                          <td>
                            <Link to={'/editapicommison'} state={item} className="btn btn-sm btn-primary btn-block">

                              <FaEdit className="fa fa-edit" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <footer className="main-footer">
        <strong>
          Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
        </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 4.5.0
        </div>
      </footer>
    </>
  );
}

import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

export default function Opnclsreport() {

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Opening Closing Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Report</Link></li>
                                    <li className="breadcrumb-item active">Opening Closing Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card col-md-6 m-auto">
                            <div className="card-body">
                                <form action="/opnclsreport" method="post" acceptCharset="utf-8">
                                    <input type="hidden" name="securityToken" value="9cef7f07ace2f392d52897f458a81a6f" />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input type="date" name="date" required value="" className="form-control" />
                                        </div>
                                        <div className="col-md-6">
                                            <button className="btn btn-primary" type="submit" fdprocessedid="d6rztm">Search</button>
                                        </div>
                                    </div>
                                    <hr />
                                </form>
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <tbody>
                                            <tr>
                                                <td className="font-weight-bold">OPENING BALANCE</td>
                                                <td className="text-right">23,727.20</td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">OLD REFUND</td>
                                                <td className="text-right">0.00</td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">DMT OLD REFUND</td>
                                                <td className="text-right">0.00</td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">CLOSING BALANCE</td>
                                                <td className="text-right">23,727.20</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

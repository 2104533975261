import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

export default function Profileupdate() {

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '203.667px' }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Profile</h1>
                            </div>
                            {/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Profile</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Profile Update</li>
                                </ol>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <form
                                    action="/profileupdate"
                                    method="post"
                                    encType="multipart/form-data"
                                >
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                                                <p className="text-primary">
                                                    <b>Customer Type :</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                                <h5 className="text-danger">MASTER ADMIN</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                                                <p className="text-primary">
                                                    <b>Business Information</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                <label>
                                                    Business Name{' '}
                                                    <small className="text-primary">
                                                        (Mandatory for business account.)
                                                    </small>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="business_name"
                                                    className="form-control"
                                                    onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                                                    value="SOFTMINT"
                                                    placeholder="Business Name"
                                                    required
                                                    fdprocessedid="985fon"
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                <label>
                                                    GST Number{' '}
                                                    <small className="text-primary">
                                                        (Mandatory for GST invoice.)
                                                    </small>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="gst_number"
                                                    className="form-control"
                                                    onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                                                    maxLength="15"
                                                    value=""
                                                    placeholder="Enter GST Number"
                                                    fdprocessedid="9i3nnp"
                                                />
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: '20px' }}>
                                            <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                                                <p className="text-primary">
                                                    <b>Personal Information</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    First Name<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                                                    className="form-control"
                                                    value="Sharad"
                                                    placeholder="Enter First Name"
                                                    required
                                                    fdprocessedid="zg8dlo"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    Last Name<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                                                    className="form-control"
                                                    value="Bandarkar"
                                                    placeholder="Enter Last Name"
                                                    fdprocessedid="n86yks"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    Date Of Birth<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    name="date_of_birth"
                                                    className="form-control"
                                                    value="2001-04-14"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    Address<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="address"
                                                    className="form-control"
                                                    onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                                                    value="AHMEDNAGAR"
                                                    placeholder="Enter Your Address"
                                                    required
                                                    fdprocessedid="mhg7et"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    Village<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="village"
                                                    className="form-control"
                                                    value="AHMEDNAGAR"
                                                    placeholder="Enter Village"
                                                    onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                                                    fdprocessedid="bw7chh"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    Pin Code<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="pincode"
                                                    minLength="6"
                                                    className="form-control"
                                                    value="414002"
                                                    placeholder="Enter Pin Code Number"
                                                    pattern="[0-9]{6}"
                                                    fdprocessedid="72blnkh"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    Taluka<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="taluka"
                                                    className="form-control"
                                                    id="taluka"
                                                    value="AHMEDNAGAR"
                                                    placeholder="Enter Taluka"
                                                    required
                                                    onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                                                    fdprocessedid="t8awtk"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    District<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="district"
                                                    className="form-control"
                                                    id="district"
                                                    value="AHMED NAGAR"
                                                    placeholder="Enter District"
                                                    fdprocessedid="aqwujr"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    State<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="state"
                                                    className="form-control"
                                                    id="state"
                                                    value="MAHARASHTRA"
                                                    placeholder="Enter State"
                                                    fdprocessedid="u40j5k"
                                                />
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: '20px' }}>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <p className="text-primary">
                                                    <b>Contact Information</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    Mobile Number -{' '}
                                                    <span style={{ fontSize: '18px' }}> 8600436163</span>
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    Email ID -{' '}
                                                    <span style={{ fontSize: '18px' }}>
                                                        softmint@gmail.com
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: '20px' }}>
                                            <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                                                <p className="text-primary">
                                                    <b>KYC Information</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    Aadhaar Number<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="aadhaar_number"
                                                    className="form-control"
                                                    value="650088278597"
                                                    placeholder="Enter Aadhaar Number"
                                                    pattern="[0-9]{12}"
                                                    fdprocessedid="4tu43"
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    Pan Number<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="pan_number"
                                                    className="form-control"
                                                    value="AWAPS5436Q"
                                                    placeholder="Enter Pan Number"
                                                    onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                                                    fdprocessedid="rl8yk"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    Aadhaar file<span className="text-danger">*</span>{' '}
                                                    <small className="text-primary">(Supported file- PDF, <br/>JPG Max Size 300kb)</small>{' '}
                                                </label>
                                                <input
                                                    type="file"
                                                    name="aadhaar_file"
                                                    className="form-control"
                                                    value=""
                                                    required
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Aadhaar Back File<span className="text-danger">*</span></label><br/><br/>
                                                <input
                                                    type="file"
                                                    name="aadhaar_back_file"
                                                    className="form-control"
                                                    value=""
                                                    required
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    Pan File<span className="text-danger">*</span>
                                                    <small className="text-primary">(Supported file- PDF, <br/>JPG Max Size 300kb)</small>
                                                </label>
                                                <input type="file" name="pan_file" className="form-control" value="" required />
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>
                                                    Passport Photo<span className="text-danger">*</span>
                                                    <small className="text-primary">(PNG, JPG file <br/>only-Max Size 100kb)</small>
                                                </label>
                                                <input type="file" name="photo_file" className="form-control" value="" required />
                                            </div>
                                        </div>
                                        <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                                        <div className="row text-center">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12" style={{ marginTop: '20px' }}>
                                                <input
                                                    type="submit"
                                                    name=""
                                                    className="btn btn-primary"
                                                    value="Update Profile"
                                                    fdprocessedid="qz5yes"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

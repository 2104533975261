// import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Admin from './components/Admin';
import Member from './components/Member';
import Editmember from './components/Editmember';
import Navbar from './components/Navbar';
import Viewmember from './components/Viewmember';
import Deactivemember from './components/Deactivemember';
import Memberkyc from './components/Memberkyc';
import Logindetail from './components/Logindetail';
import Membercommison from './components/Membercommison';
import Transactionhis from './components/Transactionhis';
import Pendingtrans from './components/Pendingtrans';
import Memberlogindetail from './components/Memberlogindetail';
import Refundtrans from './components/Refundtrans';
import Walletsummary from './components/Walletsummary';
import Fundtrans from './components/Fundtrans';
import Transfered from './components/Transfered';
import Fundreqmanager from './components/Fundreqmanager';
import Virtualbal from './components/Virtualbal';
import Profileupdate from './components/Profileupdate';
import Changepaswrd from './components/Changepaswrd';
import Dispute from './components/Dispute';
import Contactinfo from './components/Contactinfo';
import Custcare from './components/Custcare';
import Packagemast from './components/Packagemast';
import Editpackagemast from './components/Editpackagemast';
import Servicemast from './components/Servicemast';
import Operatormast from './components/Operatormast';
import Rechargeset from './components/Rechargeset';
import Upiapps from './components/Upiapps';
import Paymentsetting from './components/Paymentsetting';
import Disputecmnt from './components/Disputecmnt';
import Emailconfig from './components/Emailconfig';
import Sendsms from './components/Sendsms';
import Sendemail from './components/Sendemail';
import Sendnotification from './components/Sendnotification';
import Newspanel from './components/Newspanel';
import Loginalert from './components/Loginalert';
import Sliderimage from './components/Sliderimage';
import Promocode from './components/Promocode';
import Createpromocd from './components/Createpromocd';
import Viewpromocode from './components/Viewpromocd';
import Employee from './components/Employee';
import Empregist from './components/Empregist';
import Editemp from './components/Editemp';
import Viewemp from './components/Viewemp';
import Addsystemuser from './components/Addsystemuser';
import Updateuser from './components/Updateuser';
import Updateautority from './components/Updateautority';
import Operatorwisereport from './components/Operatorwisereport';
import Workingreport from './components/Workingreport';
import Opnclsreport from './components/Opnclsreport';
import Earningreport from './components/Earningreport';
import Creddebtreport from './components/Creddebtreport';
import Paymentreport from './components/Paymentreport';
import Adminprofitreport from './components/Adminprofitreport';
import Refundreport from './components/Refundreport';
import Fundreqreport from './components/Fundreqreport';
import Apicommison from './components/Apicommison';
import Viewapi from './components/Viewapi';
import Editapicommison from './components/Editapicommison';
import Editapicredential from './components/Editapicredential';
import Smsapi from './components/Smsapi';
import Utilityapi from './components/Utilityapi';
import Editutilityapi from './components/Editutilityapi';
import Addmember from './components/Addmember';
import Editcontact from './components/Editcontact';
import Editnews from './components/Editnews';
import Editdeactivemem from './components/Editdeactivemem';
import Viewdeactivemem from './components/Viewdeactivemem';
import Softmintlog from './components/Softmintlog';
import RDService from './components/RDService';
import Updatetransreport from './components/Updatetransreport';
import Ledgerreport from './components/Ledgerreport';
import Addkyc from './components/Addkyc';
import Newloginpage from './components/Newloginpage';
import Rechargereport from './components/Rechargereport';
// import Loader from './components/Loader';
import Commission from './components/Commission';
import Usercommission from './components/Usercommission';
import Side from './retailer/Side';
import Accountinfo from './retailer/Accountinfo';
import Addmoney from './retailer/Addmoney';
import Aepsreport from './retailer/Aepsreport';
import Aepsservice from './retailer/Aepsservice';
import Bankaccount from './retailer/Bankaccount';
import Cashdeposit from './retailer/Cashdeposit';
import Changepasswrd from './retailer/Changepasswrd';
import Cms from './retailer/Cms';
import Commison from './retailer/Commison';
import Dmt from './retailer/Dmt';
import Dth from './retailer/Dth';
import Editprofile from './retailer/Editprofile';
import Electricity from './retailer/Electricity';
import Fastag from './retailer/Fastag';
import Feedback from './retailer/Feedback';
import Fundrequest from './retailer/Fundrequest';
import Gasbooking from './retailer/Gasbooking';
import Insurance from './retailer/Insurance';
import Kyc from './retailer/Kyc';
import Landline from './retailer/Landline';
import Lic from './retailer/Lic';
import Lpg from './retailer/Lpg';
import Mobilepostpaid from './retailer/Mobilepostpaid';
import Mobilerecharge from './retailer/Mobilerecharge';
import Moneytrans from './retailer/Moneytrans';
import Mpin from './retailer/Mpin';
import Municipility from './retailer/Muncipility';
import Nsdlpan from './retailer/Nsdlpan';
import Pancard from './retailer/Pancard';
import Payout from './retailer/Payout';
import Rechargereportretailer from './retailer/Rechargereportretailer';
import Sendmoney from './retailer/Sendmoney';
import Wallettrans from './retailer/Wallettrans';
import Water from './retailer/Water';
import Cards from './components/Cards';
import Admincommission from './components/Admincommission';
import AdminAepsReport from './components/AdminAepsReport';
import AdminEarningReport from './components/AdminEarningReport';
import ManageMiniWallet from './components/ManageMiniWallet';
import Addslab from './components/Addslab';
import ProviderName from './components/utilityComponents/ProviderName';
import Kycreport from './components/Kycreport';
import Sidebar from './retailer/Sidebar';
import RetailerService from './retailer/RetailerService';
import { UAParser } from 'ua-parser-js';
import Cookies from 'js-cookie';
import Biometric from './retailer/Biometric';
import RetailerTxnReport from './retailer/RetailerTxnReport';
import RegisterAeps from './retailer/RegisterAeps';
import AepsLogin from './retailer/AepsLogin';
import TicketRaise from './retailer/TicketRaise';
import RetailerEarningReport from './retailer/RetailerEarningReport';
import ViewTickets from './components/ViewTickets';
import ViewTicketDetail from './components/ViewTicketDetail';
import TicketChart from './components/TicketChart';
import Editpermission from './components/Editpermission';
import AdminPayoutReport from './components/AdminPayoutReport';
import ChargeSetup from './components/ChargeSetup';
import AddCharge from './components/AddCharge';
import LedgerTxnReport from './components/LedgerTxnReport';
import ViewPendingTickets from './components/ViewPendingTickets';
import DetailedPendingViewTickets from './components/DetailedPendingViewTickets';
import AssignTicket from './components/AssignTicket';
import DetailAssignTicket from './components/DetailAssignTicket';
import MovetobankPermissionTable from './components/MovetobankPermissionTable';
import AddBankPermission from './components/AddBankPermission';
import AllMovetobankUserReport from './components/AllMovetobankUserReport';
import Updatemovetobankuser from './components/Updatemovetobankuser';
import RetailerMoveToBankUserReport from './retailer/RetailerMoveToBankUserReport';
import Editmovetobankuser from './retailer/EditMovetobankuser';
import NsdlForm from './components/utilityComponents/NsdlForm';
import NsdlReceipt from './components/utilityComponents/NsdlReceipt';
import Nsdlreport from './retailer/Nsdlreport';
import Bbps from './retailer/Bbps';
import BbpsReport from './retailer/BbpsReport';
import ViewAllReports from './components/ViewAllReports';
import MasterAdminComm from './components/MasterAdminComm';
import BbpsAdminComm from './components/BbpsAdminComm';
import BbpsUserComm from './components/BbpsUserComm';
import ViewAllActiveInactive from './components/ViewAllActiveInactive';
import AddSlabs from './components/AddSlabs';
import Expance from './components/Expance';
import AadharpayService from './retailer/AadharpayService';
import Aadhar2FaLogin from './retailer/Aadhar2FaLogin';
import AddCommCharge from './components/AddCommCharge';
import EmployeeDashboard from './Employee Component/EmployeeDashboard';
import MATMReport from './retailer/MATMReport';
import AdminMatmReport from './components/AdminMatmReport';
import AadharPayReport from './retailer/AadharPayReport';
import AdminAadharPayReport from './components/AdminAadharPayReport';
import AddMatmAgent from './components/AddMatmAgent';
import MatmLogReport from './components/MatmLogReport';
import RetailerMatmLogReport from './retailer/RetailerMatmlogReport';
import Broadband from './retailer/Broadband';
import Cable from './retailer/Cable';
import DataCardPrepaid from './retailer/DataCardPrepaid';
import TrafficChallan from './retailer/TrafficChallan';
import EmiPayment from './retailer/EmiPayment';
import FeePayment from './retailer/FeePayment';
import LoanRepayment from './retailer/LoanRepayment';
import BroadbandPostpaid from './retailer/BroadbandPostpaid';
import CableTv from './retailer/CableTv';
import Emi from './retailer/Emi';
import EmployeeNavbar from './Employee Component/EmployeeNavbar';
import AddEmployee from './Employee Component/AddEmployee';
import Changesponser from './components/Changesponser';
import AddUserMap from './components/AddUserMap';
import FetchEmp from './Employee Component/FetchEmp';
import EditEmployee from './Employee Component/EditEmployee';
import KycAgentView from './components/utilityComponents/KycAgentView';
import SelfEarningReport from './components/SelfEarningReport';
import DistributorMapping from './components/DistributorMapping';
// import ApiUserDashboard from './ApiUser.jsx/ApiUserDashboard';
import ViewAepsUser from './components/ViewAepsUser';
import InitInsuranceReport from './retailer/InitInsuranceReport';
import AdminInitInsurance from './components/AdminInitInsurance';
import UtiPan from './retailer/UtiPan';
import UtiAgentReport from './retailer/UtiAgentReport';
import EmployeeUserMap from './components/EmployeeUserMap';
import AdminInsurance from './components/AdminInsurance';
import AddUtiAgent from './components/AddUtiAgent';
import NewUserPage from './components/NewUserPage';
import CouponReqReport from './components/CouponReqReport';
import RetailerCouponReport from './retailer/RetailerCouponReport';
// import AddGlobalPanCharge from './components/AddGlobalPanCharge';
import SuspisiousFroudReport from './components/SuspisiousFroudReport';
import FetchUserTxnActivity from './components/FetchUserTxnActivity';
import ServiceWiseTxnReport from './components/ServiceWiseTxnReport';
import FigmaDatepicker from './components/FigmaDatepicker';
import AdminNsdlReport from './components/AdminNsdlReport';
import InsuranceReport from './components/InsuranceReport';
import RetailerInsuranceReport from './retailer/RetailerInsuranceReport';
import DmtRegister from './retailer/DmtRegister';
import AddBeneficiary from './retailer/AddBeneficiary';
import DmtReport from './retailer/DmtReport';
import AdminDmtReport from './components/AdminDmtReport';
import AssignPackage from './components/AssignPackage';
import Kycmicroatm from './retailer/Kycmicroatm';
import AdminBbpsReport from './components/AdminBbpsReport';


function App() {

  // const [isLoading, setLoading] = useState(true);

  if (Cookies.get('deviceId') === undefined) {
    var fingerprint = "";
    const uaparser = new UAParser();
    fingerprint += navigator.userAgent;
    fingerprint += navigator.language;
    fingerprint += window.screen.colorDepth;
    fingerprint += window.screen.height;
    fingerprint += window.screen.width;
    fingerprint += uaparser.getResult().os.name;
    Cookies.set('deviceId', fingerprint, { expires: 1 });
  }


  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, []);

  const [deviceID, setDeviceID] = useState('');

  useEffect(() => {
    // Check if device ID and timestamp already exist in local storage
    let storedDeviceID = localStorage.getItem('deviceID');
    let storedTimestamp = localStorage.getItem('deviceIDTimestamp');

    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 1 day in milliseconds

    if (!storedDeviceID || !storedTimestamp || Date.now() - storedTimestamp > oneDayInMilliseconds) {
      // Generate a new device ID if it doesn't exist or if it's older than one day
      storedDeviceID = generateDeviceID();
      storedTimestamp = Date.now();
      localStorage.setItem('deviceID', storedDeviceID);
      localStorage.setItem('deviceIDTimestamp', storedTimestamp);
    }

    setDeviceID(storedDeviceID);
  }, []);

  const generateDeviceID = () => {
    // Generate a unique device ID
    return 'device-' + Math.random().toString(36).substring(2, 9);
  };


  return (
    <BrowserRouter>
      {/* {isLoading && <Loader />} */}
      {/* <Suspense fallback={<Loader />}> */}
      <Routes>
        <Route path='/' element={<Newloginpage />} />
        <Route path='/admin' element={<Admin />} />
        <Route path='/member' element={<Member />} />
        <Route path='/newuserpage' element={<NewUserPage />} />
        <Route path='/addmember' element={<Addmember />} />
        <Route path='/editmember' element={<Editmember />} />
        <Route path='/navbar' element={<Navbar />} />
        <Route path='/viewmember' element={<Viewmember />} />
        <Route path='/deactivemember' element={<Deactivemember />} />
        <Route path='/editdeactivemem' element={<Editdeactivemem />} />
        <Route path='/viewdeactivemem' element={<Viewdeactivemem />} />
        <Route path='/memberkyc' element={<Memberkyc />} />
        <Route path='/logindetail' element={<Logindetail />} />
        <Route path='/membercommison' element={<Membercommison />} />
        <Route path='/transactionhis' element={<Transactionhis />} />
        <Route path='/pendingtrans' element={<Pendingtrans />} />
        <Route path='/memberlogindetail' element={<Memberlogindetail />} />
        <Route path='/refundtrans' element={<Refundtrans />} />
        <Route path='/walletsummary' element={<Walletsummary />} />
        <Route path='/fundtrans' element={<Fundtrans />} />
        <Route path='/transfered' element={<Transfered />} />
        <Route path='/fundreqmanager' element={<Fundreqmanager />} />
        <Route path='/virtualbal' element={<Virtualbal />} />
        <Route path='/profileupdate' element={<Profileupdate />} />
        <Route path='/changepaswrd' element={<Changepaswrd />} />
        <Route path='/dispute' element={<Dispute />} />
        <Route path='/contactinfo' element={<Contactinfo />} />
        <Route path='/custcare' element={<Custcare />} />
        <Route path='/packagemast' element={<Packagemast />} />
        <Route path='/editpackagemast' element={<Editpackagemast />} />
        <Route path='/servicemast' element={<Servicemast />} />
        <Route path='/operatormast' element={<Operatormast />} />
        <Route path='/rechargeset' element={<Rechargeset />} />
        <Route path='/upiapps' element={<Upiapps />} />
        <Route path='/paymentsetting' element={<Paymentsetting />} />
        <Route path='/disputecmnt' element={<Disputecmnt />} />
        <Route path='/emailconfig' element={<Emailconfig />} />
        <Route path='/sendsms' element={<Sendsms />} />
        <Route path='/changesponser' element={<Changesponser />} />
        <Route path='/sendemail' element={<Sendemail />} />
        <Route path='/sendnotification' element={<Sendnotification />} />
        <Route path='/newspanel' element={<Newspanel />} />
        <Route path='/editnews' element={<Editnews />} />
        <Route path='/loginalert' element={<Loginalert />} />
        <Route path='/sliderimage' element={<Sliderimage />} />
        <Route path='/promocode' element={<Promocode />} />
        <Route path='/createpromocd' element={<Createpromocd />} />
        <Route path='/viewpromocd' element={<Viewpromocode />} />
        <Route path='/employee' element={<Employee />} />
        <Route path='/empregist' element={<Empregist />} />
        <Route path='/editemp' element={<Editemp />} />
        <Route path='/viewemp' element={<Viewemp />} />
        <Route path='/addsystemuser' element={<Addsystemuser />} />
        <Route path='/updateuser' element={<Updateuser />} />
        <Route path='/updateautority' element={<Updateautority />} />
        <Route path='/operatorwisereport' element={<Operatorwisereport />} />
        <Route path='/workingreport' element={<Workingreport />} />
        <Route path='/opnclsreport' element={<Opnclsreport />} />
        <Route path='/earningreport' element={<Earningreport />} />
        <Route path='/creddebtreport' element={<Creddebtreport />} />
        <Route path='/paymentreport' element={<Paymentreport />} />
        <Route path='/adminprofitreport' element={<Adminprofitreport />} />
        <Route path='/refundreport' element={<Refundreport />} />
        <Route path='/fundreqreport' element={<Fundreqreport />} />
        <Route path='/apicommison' element={<Apicommison />} />
        <Route path='/viewapi' element={<Viewapi />} />
        <Route path='/editapicommison' element={<Editapicommison />} />
        <Route path='/editapicredential' element={<Editapicredential />} />
        <Route path='/smsapi' element={<Smsapi />} />
        <Route path='/utilityapi' element={<Utilityapi />} />
        <Route path='/editutilityapi' element={<Editutilityapi />} />
        <Route path='/editcontact' element={<Editcontact />} />
        <Route path='/softmintlog' element={<Softmintlog />} />
        <Route path='/rdservice' element={<RDService />} />
        <Route path='/updatetransreport' element={<Updatetransreport />} />
        <Route path='/ledgerreport' element={<Ledgerreport />} />
        <Route path='/addkyc' element={<Addkyc />} />
        <Route path='/rechargereport' element={<Rechargereport />} />
        {/* <Route path='/loader' element={<Loader />} /> */}
        <Route path='/commission' element={<Commission />} />
        <Route path='/usercommission' element={<Usercommission />} />
        <Route path='/addslab' element={<Addslab />} />
        <Route path='/providername' element={<ProviderName />} />
        <Route path='/kycreport' element={<Kycreport />} />
        <Route path='/viewtickets' element={<ViewTickets />} />
        <Route path='/viewticketdetail' element={<ViewTicketDetail />} />
        <Route path='/ticketchart' element={<TicketChart />} />
        <Route path='/editpermission' element={<Editpermission />} />
        <Route path='/adminpayoutreport' element={<AdminPayoutReport />} />
        <Route path='/chargesetup' element={<ChargeSetup />} />
        <Route path='/addcharge' element={<AddCharge />} />
        <Route path='/ledgertxnreport' element={<LedgerTxnReport />} />
        <Route path='/viewpendingtickets' element={<ViewPendingTickets />} />
        <Route path='/detailedpendingviewticket' element={<DetailedPendingViewTickets />} />
        <Route path='/assignticket' element={<AssignTicket />} />
        <Route path='/detailassignticket' element={<DetailAssignTicket />} />
        <Route path='/movetobankpermissiontable' element={<MovetobankPermissionTable />} />
        <Route path='/addbankpermission' element={<AddBankPermission />} />
        <Route path='/allmovetobankuser' element={<AllMovetobankUserReport />} />
        <Route path='/updatemovetobankuser' element={<Updatemovetobankuser />} />
        <Route path='/nsdlform' element={<NsdlForm />} />
        <Route path='/nsdlreceipt' element={<NsdlReceipt />} />
        <Route path='/viewallreport' element={<ViewAllReports />} />
        <Route path='/masteradmincomm' element={<MasterAdminComm />} />
        <Route path='/bbpsadmincomm' element={<BbpsAdminComm />} />
        <Route path='/bbpsusercomm' element={<BbpsUserComm />} />
        <Route path='/viewallactiveinactive' element={<ViewAllActiveInactive />} />
        <Route path='/addslabs' element={<AddSlabs />} />
        <Route path='/expanse' element={<Expance />} />
        <Route path='/addcommcharge' element={<AddCommCharge />} />
        <Route path='/adminmatmreport' element={<AdminMatmReport />} />
        <Route path='/adminaadharpayreport' element={<AdminAadharPayReport />} />
        <Route path='/addmatmagent' element={<AddMatmAgent />} />
        <Route path='/matmlogreport' element={<MatmLogReport />} />
        <Route path='/addusermap' element={<AddUserMap />} />
        <Route path='/kycagentview' element={<KycAgentView />} />
        <Route path='/selfearningreport' element={<SelfEarningReport />} />
        <Route path='/distributormapping' element={<DistributorMapping />} />
        <Route path='/admininitinsurance' element={<AdminInitInsurance />} />
        <Route path='/employeeusermap' element={<EmployeeUserMap />} />
        <Route path='/admininsurance' element={<AdminInsurance />} />
        <Route path='/suspisiousfroudreport' element={<SuspisiousFroudReport />} />
        <Route path='/addutiagent' element={<AddUtiAgent />} />
        {/* <Route path='/addglobalpancharge' element={<AddGlobalPanCharge />} /> */}
        <Route path='/couponreqreport' element={<CouponReqReport />} />
        <Route path='/fetchusertxn' element={<FetchUserTxnActivity />} />
        <Route path='/servicewisetxn' element={<ServiceWiseTxnReport />} />
        <Route path='/figmadatepicker' element={<FigmaDatepicker />} />
        <Route path='/adminnsdlreport' element={<AdminNsdlReport />} />
        <Route path='/insurancereport' element={<InsuranceReport />} />
        <Route path='/admindmtreport' element={<AdminDmtReport />} />
        <Route path='/adminbbpsreport' element={<AdminBbpsReport />} />

        {/* For retailer */}
        <Route path='/side' element={<Side />} />
        <Route path='/sidebar' element={<Sidebar />} />
        <Route path='/accountinfo' element={<Accountinfo />} />
        <Route path='/addmoney' element={<Addmoney />} />
        <Route path='/aepsreport' element={<Aepsreport />} />
        <Route path='/aepsservice' element={<Aepsservice />} />
        <Route path='/bankaccount' element={<Bankaccount />} />
        <Route path='/cashdeposit' element={<Cashdeposit />} />
        <Route path='/changepasswrd' element={<Changepasswrd />} />
        <Route path='/cms' element={<Cms />} />
        <Route path='/commison' element={<Commison />} />
        <Route path='/dmt' element={<Dmt />} />
        <Route path='/dth' element={<Dth />} />
        <Route path='/editprofile' element={<Editprofile />} />
        <Route path='/electricity' element={<Electricity />} />
        <Route path='/fastag' element={<Fastag />} />
        <Route path='/feedback' element={<Feedback />} />
        <Route path='/fundrequest' element={<Fundrequest />} />
        <Route path='/gasbooking' element={<Gasbooking />} />
        <Route path='/insurance' element={<Insurance />} />
        <Route path='/kyc' element={<Kyc />} />
        <Route path='/landline' element={<Landline />} />
        <Route path='/lic' element={<Lic />} />
        {/* <Route path='/loader' element={<Loader />} /> */}
        <Route path='/lpg' element={<Lpg />} />
        <Route path='/mobilepostpaid' element={<Mobilepostpaid />} />
        <Route path='/mobilerecharge' element={<Mobilerecharge />} />
        <Route path='/moneytrans' element={<Moneytrans />} />
        <Route path='/mpin' element={<Mpin />} />
        <Route path='/muncipility' element={<Municipility />} />
        <Route path='/nsdlpan' element={<Nsdlpan />} />
        <Route path='/pancard' element={<Pancard />} />
        <Route path='/payout' element={<Payout />} />
        <Route path='/rechargereportretailer' element={<Rechargereportretailer />} />
        <Route path='/sendmoney' element={<Sendmoney />} />
        <Route path='/retailerservice' element={<RetailerService />} />
        <Route path='/wallettrans' element={<Wallettrans />} />
        <Route path='/water' element={<Water />} />
        <Route path='/cards' element={<Cards />} />
        <Route path='/admincommission' element={<Admincommission />} />
        <Route path='/adminAepsReport' element={<AdminAepsReport />} />
        <Route path='/adminEarningReport' element={<AdminEarningReport />} />
        <Route path='/manageMiniWallet' element={<ManageMiniWallet />} />
        <Route path='/biometric' element={<Biometric />} />
        <Route path='/retailertxnreport' element={<RetailerTxnReport />} />
        <Route path='/registeraeps' element={<RegisterAeps />} />
        <Route path='/aepslogin' element={<AepsLogin />} />
        <Route path='/ticketraise' element={<TicketRaise />} />
        <Route path='/retailerearningreport' element={<RetailerEarningReport />} />
        <Route path='/retailermovetobankuserreport' element={<RetailerMoveToBankUserReport />} />
        <Route path='/editmovetobankuser' element={<Editmovetobankuser />} />
        <Route path='/nsdlreport' element={<Nsdlreport />} />
        <Route path='/bbps' element={<Bbps />} />
        <Route path='bbpsreport' element={<BbpsReport />} />
        <Route path='aadharpayservice' element={<AadharpayService />} />
        <Route path='aadhar2falogin' element={<Aadhar2FaLogin />} />
        <Route path='matmreport' element={<MATMReport />} />
        <Route path='aadharpayreport' element={<AadharPayReport />} />
        <Route path='retailermatmlogreport' element={<RetailerMatmLogReport />} />
        <Route path='broadband' element={<Broadband />} />
        <Route path='cable' element={<Cable />} />
        <Route path='datacardprepaid' element={<DataCardPrepaid />} />
        <Route path='trafficchallan' element={<TrafficChallan />} />
        <Route path='emipayment' element={<EmiPayment />} />
        <Route path='feepayment' element={<FeePayment />} />
        <Route path='loanrepayment' element={<LoanRepayment />} />
        <Route path='broadbandpostpaid' element={<BroadbandPostpaid />} />
        <Route path='cabletv' element={<CableTv />} />
        <Route path='emi' element={<Emi />} />
        <Route path='viewaepsuser' element={<ViewAepsUser />} />
        <Route path='initinsurancereport' element={<InitInsuranceReport />} />
        <Route path='utipan' element={<UtiPan />} />
        <Route path='utiagentreport' element={<UtiAgentReport />} />
        <Route path='retailercouponreport' element={<RetailerCouponReport />} />
        <Route path='retailerinsurancereport' element={<RetailerInsuranceReport />} />
        <Route path='dmtregister' element={<DmtRegister />} />
        <Route path='addbeneficiary' element={<AddBeneficiary />} />
        <Route path='dmtreport' element={<DmtReport />} />
        <Route path='assignpackage' element={<AssignPackage />} />
        <Route path='kycmicroatm' element={<Kycmicroatm />} />

        {/* For Employee */}

        <Route path='/employeedashboard' element={<EmployeeDashboard />} />
        <Route path='/employeeNavbar' element={<EmployeeNavbar />} />
        <Route path='/addemployee' element={<AddEmployee />} />
        <Route path='/fetchemp' element={<FetchEmp />} />
        <Route path='/editemployee' element={<EditEmployee />} />

        
      </Routes>
      {/* </Suspense> */}

    </BrowserRouter>
  );
}

export default App;

const formatNsdlDate = (date) => {
  if (!date) {
    return ""; // Or any other default value or action you prefer
  }

  const formattedDate = date
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .join("/");
  return formattedDate;
};

export default formatNsdlDate;

import React from "react";


const UtilityRechargeReport = ({ data }) => {

    return (
        <>
          <table className="table table-striped table-bordered">
                <thead>
                    <tr className="wrap-text text-center">
                        <th className="px-1">SL.NO</th>
                        <th>User Id</th>
                        <th>MOBILE</th>
                        <th>Operator Id</th>
                        <th className="px-1">OP BAL</th>
                        <th>AMOUNT</th>
                        <th>CHARGE</th>
                        <th className="px-1">COMMISSION</th>
                        <th>CL BAL</th>
                        <th>TID</th>
                        <th className="px-1">Status</th>
                        <th>Source</th>
                        <th>Api Id</th>
                        <th>Role Id</th>
                        <th>Wl Id</th>
                        <th>Date&Time</th>
                        <th>IP</th>
                        <th>Order Id</th>
                    </tr>
                </thead>
                <tbody>
                    {!data || data.length === 0 ? (
                        <tr className="text-center">
                            <td colSpan="18">NO DATA AVAILABLE</td>
                        </tr>
                    ) : (
                        data.map((item, index) => (
                            <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.userId}</td>
                                <td>{item.mobile}</td>
                                <td>{item.operatorId}</td>
                                <td>{item.openBal}</td>
                                <td>{item.amount}</td>
                                <td>{item.charge}</td>
                                <td>{item.comm}</td>
                                <td>{item.closeBal}</td>
                                <td>{item.tid}</td>
                                <td>{item.status}</td>
                                <td>{item.source}</td>
                                <td>{item.apiId}</td>
                                <td>{item.roleId}</td>
                                <td>{item.wlId}</td>
                                <td>{`${item.date} & ${item.time}`}</td>
                                <td>{item.ip}</td>
                                <td>{item.orderId}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </>
    );

};

export default UtilityRechargeReport;

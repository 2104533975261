import React from 'react';
import Navbar from './Navbar';
import { useState, useRef } from 'react';


export default function Workingreport() {

    const options = [
        { value: '', label: 'AJOY MONDAL [MD1003][9609236025]' },
        { value: '1', label: 'NAVNATH SASE [RT1001][8600707581]' },
        { value: '2', label: 'Sharad Bandarkar [MA1000][8600436163]' },

    ];

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const dropdownRef = useRef();

    const toggleDropdown = () => {
        setDropdownOpen((prevState) => !prevState);
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option.value);
        setDropdownOpen(false);
        console.log('Selected Value:', option.value);


    };


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Customer Working Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/admin">Dashboard</a></li>
                                    <li className="breadcrumb-item"><a href="#">Reports</a></li>
                                    <li className="breadcrumb-item active">Customer Working Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div>
                                    <form action="/workingreport" method="post" acceptCharset="utf-8">
                                        <input type="hidden" name="securityToken" value="9cef7f07ace2f392d52897f458a81a6f" />
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label>Select Member</label>
                                                <select name="customer_id" className="form-control select2 select2-hidden-accessible" id="customer_list" tabIndex="-1" aria-hidden="true" data-select2-id="customer_list">
                                                    <option value="" data-select2-id="4">Select Member</option>
                                                </select>
                                                <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="3" style={{ width: "218.5px" }}>

                                                    <div
                                                        ref={dropdownRef}
                                                        className={`select2 select2-container select2-container--default ${isDropdownOpen ? 'select2-container--open' : ''
                                                            }`}
                                                        dir="ltr"
                                                        data-select2-id="2"
                                                        style={{ width: '226.625px' }}
                                                        onClick={toggleDropdown}
                                                    >
                                                        <span className="selection">
                                                            <span
                                                                className={`select2-selection select2-selection--single ${isDropdownOpen ? 'select2-selection--active' : ''
                                                                    }`}

                                                                role="combobox"
                                                                aria-haspopup="true"
                                                                aria-expanded={isDropdownOpen}
                                                                tabIndex="0"
                                                                aria-disabled="false"
                                                                aria-labelledby="select2-package_id-24-container"
                                                            >
                                                                <span
                                                                    className="select2-selection__rendered"
                                                                    id="select2-package_id-24-container"
                                                                    role="textbox"
                                                                    aria-readonly="true"
                                                                    title={selectedOption ? options.find((option) => option.value === selectedOption).label : 'Select Member'}
                                                                >
                                                                    {selectedOption ? options.find((option) => option.value === selectedOption).label : 'Select Member'}
                                                                </span>

                                                                <span className="select2-selection__arrow" role="presentation">
                                                                    <b role="presentation"></b>
                                                                </span>
                                                            </span>
                                                        </span>

                                                        {isDropdownOpen && (
                                                            <span className="dropdown-wrapper" style={{ position: 'absolute' }}>
                                                                <span className="select2-dropdown select2-dropdown--below" dir="ltr" style={{ width: '226.625px' }}>
                                                                    <span className="select2-search select2-search--dropdown">
                                                                        <input
                                                                            className="select2-search__field"
                                                                            type="search"
                                                                            tabIndex="0"
                                                                            autoComplete="off"
                                                                            autoCorrect="off"
                                                                            autoCapitalize="none"
                                                                            spellCheck="false"
                                                                            role="searchbox"
                                                                            aria-autocomplete="list"
                                                                            aria-controls="select2-package_id-ci-results"
                                                                        />
                                                                    </span>
                                                                    <span className="select2-results" >
                                                                        <ul className="select2-results__options" role="listbox" id="select2-package_id-ci-results" aria-expanded="true" aria-hidden="false">
                                                                            {options.map((option) => (
                                                                                <li
                                                                                    key={option.value}
                                                                                    className={`select2-results__option ${option.value === selectedOption ? 'select2-results__option--highlighted' : ''
                                                                                        }`}
                                                                                    role="option"
                                                                                    aria-selected={option.value === selectedOption}
                                                                                    data-select2-id={`select2-package_id-f9-result-${option.value}`}
                                                                                    onClick={() => handleOptionSelect(option)}
                                                                                >
                                                                                    {option.label}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        )}
                                                    </div>

                                                </span>
                                            </div>

                                            <div className="col-md-3">
                                                <label>Interval</label>
                                                <select name="interval" className="form-control" fdprocessedid="4dcjbl">
                                                    <option value="">Select Interval</option>
                                                    <option value="1">Today</option>
                                                    <option value="7">Last 7 Days</option>
                                                    <option value="15">Last 15 Days</option>
                                                    <option value="30">Last 30 Days</option>
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Working/Non Working</label>
                                                <select name="working" className="form-control" fdprocessedid="5o891">
                                                    <option value="">Select</option>
                                                    <option value="1">Working Customers</option>
                                                    <option value="2">Non Working Customers</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12" style={{ marginTop: "30px" }}>
                                                <input type="submit" className="btn btn-primary" value="Search" fdprocessedid="dyi0gf" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <hr />
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <thead className="text-center">
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Txn Type</th>
                                                <th>Credit</th>
                                                <th>Debit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="text-center">
                                                <td>1</td>
                                                <td>AJOY MONDAL</td>
                                                <td><span className="bg-danger p-1">No Transaction</span></td>
                                                <td>0.00</td>
                                                <td>0.00</td>
                                            </tr>
                                            <tr className="text-center">
                                                <td>2</td>
                                                <td>ARUL DOSS</td>
                                                <td><span className="bg-danger p-1">No Transaction</span></td>
                                                <td>0.00</td>
                                                <td>0.00</td>
                                            </tr>
                                            <tr className="text-center">
                                                <td>3</td>
                                                <td>NAVNATH SASE</td>
                                                <td><span className="bg-danger p-1">No Transaction</span></td>
                                                <td>0.00</td>
                                                <td>0.00</td>
                                            </tr>
                                            <tr className="text-center">
                                                <td>4</td>
                                                <td>Sharad Bandarkar</td>
                                                <td><span className="bg-danger p-1">No Transaction</span></td>
                                                <td>0.00</td>
                                                <td>0.00</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <a href="www.softmintdigital.com">SOFTMINT</a>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

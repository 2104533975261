import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import { useRef, useEffect } from 'react';
import RestService from '../http';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';
import { Link } from 'react-router-dom';


export default function DistributorMapping() {

    const service = new RestService();
    const [selectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropDownRef = useRef();
    const [loading, setLoading] = useState(false);
    const [designationList, setDesignationList] = useState([]);
    const [roleId, setRoleId] = useState('');
    const [empList, setEmpList] = useState([]);
    const [employee, setEmployee] = useState('');
    const [downline, setDownline] = useState('');
    const [downlineList, setDownlineList] = useState([]);
    const [downlineList2, setDownlineList2] = useState([]);
    const [downlineList3, setDownlineList3] = useState([]);
    const [downlineList4, setDownlineList4] = useState([]);
    const [downlineListVisible, setDownlineListVisible] = useState(false);
    const [downlineListVisible2, setDownlineListVisible2] = useState(false);
    const [downlineListVisible3, setDownlineListVisible3] = useState(false);
    const [downlineListVisible4, setDownlineListVisible4] = useState(false);


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    useEffect(() => {
        service.post("emp/viewDesignation", null).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                setDesignationList(result.data);

            } else {
                setDesignationList([]);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);


    const handleEmployeeRole = (e) => {
        setRoleId(e.target.value);

        service.post(`emp/viewEmp/${e.target.value}`, null).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setEmpList(result.data);

            } else {
                showToast("DANGER", result.message);
                setEmpList([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    }


    useEffect(() => {
        if (roleId === '8') {
            setDownlineListVisible(true);
            setDownlineListVisible2(true);
            setDownlineListVisible3(true);
            setDownlineListVisible4(true);

        } else if (roleId === '9') {
            setDownlineListVisible(false);
            setDownlineListVisible2(true);
            setDownlineListVisible3(true);
            setDownlineListVisible4(true);

        } else if (roleId === '10') {
            setDownlineListVisible(false);
            setDownlineListVisible2(false);
            setDownlineListVisible3(true);
            setDownlineListVisible4(true);

        } else if (roleId === '11') {
            setDownlineListVisible(false);
            setDownlineListVisible2(false);
            setDownlineListVisible3(false);
            setDownlineListVisible4(true);
            
        } else if (roleId === '12') {
            setDownlineListVisible(false);
            setDownlineListVisible2(false);
            setDownlineListVisible3(false);
            setDownlineListVisible4(false);
        }
    }, [roleId]);


    const handleEmployeeList = (e) => {
        setEmployee(e.target.value);

        service.post(`emp/viewDownline/${e.target.value}`, null).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setDownlineList(result.data);

            } else {
                showToast("DANGER", result.message);
                setDownlineList([]);
            }
        }).catch((error) => {
            console.log(error);
        })

    }

    const handleDownline = (e) => {
        setDownline(e.target.value);

        service.post(`emp/viewDownline/${e.target.value}`, null).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setDownlineList2(result.data);

            } else {
                showToast("DANGER", result.message);
                setDownlineList2([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleDownline2 = (e) => {
        setDownline(e.target.value);

        service.post(`emp/viewDownline/${e.target.value}`, null).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setDownlineList3(result.data);

            } else {
                showToast("DANGER", result.message);
                setDownlineList3([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleDownline3 = (e) => {

        service.post(`emp/viewDownline/${e.target.value}`, null).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setDownlineList4(result.data);

            } else {
                showToast("DANGER", result.message);
                setDownlineList4([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "520.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header card">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Distributor Mapping</h1>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Support</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Dist. Mapping</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    {/* <div className="container-fluid"> */}
                    {/* <div className="card col-6 m-auto"> */}
                    <div className="card" style={{ padding: "2%" }}>

                        {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className='row'>
                                            <div className="col-12 mt-5">
                                                <label for="service_id">
                                                    Employee Role: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setRoleId(e.target.value)}
                                                >
                                                    <option value="">Select...</option>
                                                    {designationList.map((item) => (
                                                        <option value={item.id}>{item.designation}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12" style={{ marginTop: '10px' }}>
                                                <label for="service_id">
                                                    User Id: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    // onChange={(e) => setUserId(e.target.value)}
                                                >
                                                    <option value="">Select Id...</option>
                                                 
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12" style={{ marginTop: '10px' }}>
                                                <label for="service_id">
                                                    User Id: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    // onChange={(e) => setUserId(e.target.value)}
                                                >
                                                    <option value="">Select Id...</option>
                                                   
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12" style={{ marginTop: '10px' }}>
                                                <label for="service_id">
                                                    User Id: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    // onChange={(e) => setUserId(e.target.value)}
                                                >
                                                    <option value="">Select Id...</option>
                                                   
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12" style={{ marginTop: '10px' }}>
                                                <label for="service_id">
                                                    User Id: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    // onChange={(e) => setUserId(e.target.value)}
                                                >
                                                    <option value="">Select Id...</option>
                                                    
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 text-center" style={{ marginTop: '10px' }}>
                                                <input name="" value="SUBMIT" className="btn btn-primary" fdprocessedid="0rzuuq" />
                                            </div>
                                        </div>
                                </div> */}

                        <div className='row'>
                            <div className='col-md-3'>
                                <label for="service_id">
                                    Employee Role: <span className="text-danger"> *</span>
                                </label>
                                <select
                                    className="form-control select2 "
                                    name="service_id"
                                    required=""
                                    id="service_id"
                                    data-select2-id="service_id"
                                    tabindex="-1"
                                    aria-hidden="true"
                                    onChange={handleEmployeeRole}
                                >
                                    <option value="">Select...</option>
                                    {designationList.map((item) => (
                                        <option value={item.id}>{item.designation}</option>
                                    ))}
                                </select>
                            </div>

                            <div className='col-md-3'>
                                <label for="service_id">
                                    Employee List: <span className="text-danger"> *</span>
                                </label>
                                <select
                                    className="form-control select2 "
                                    name="service_id"
                                    required=""
                                    id="service_id"
                                    data-select2-id="service_id"
                                    tabindex="-1"
                                    aria-hidden="true"
                                    onChange={handleEmployeeList}
                                >
                                    <option value="">Select...</option>
                                    {empList.map((item) => (
                                        <option value={item.empId}>{item.empName}</option>
                                    ))}
                                </select>
                            </div>

                            {downlineListVisible && (
                                <div className='col-md-3'>
                                    <label for="service_id">
                                        Downline List1: <span className="text-danger"> *</span>
                                    </label>
                                    <select
                                        className="form-control select2 "
                                        name="service_id"
                                        required=""
                                        id="service_id"
                                        data-select2-id="service_id"
                                        tabindex="-1"
                                        aria-hidden="true"
                                        onChange={handleDownline}
                                    >
                                        <option value="">Select...</option>
                                        {downlineList.map((item) => (
                                            <option value={item.empId}>{item.empName}</option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            {downlineListVisible2 && (
                                <div className='col-md-3'>
                                    <label for="service_id">
                                        Downline List2: <span className="text-danger"> *</span>
                                    </label>
                                    <select
                                        className="form-control select2 "
                                        name="service_id"
                                        required=""
                                        id="service_id"
                                        data-select2-id="service_id"
                                        tabindex="-1"
                                        aria-hidden="true"
                                        onChange={handleDownline2}
                                    >
                                        <option value="">Select...</option>
                                        {downlineList2.map((item) => (
                                            <option value={item.empId}>{item.empName}</option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            {downlineListVisible3 && (
                                <div className='col-md-3'>
                                    <label for="service_id">
                                        Downline List3: <span className="text-danger"> *</span>
                                    </label>
                                    <select
                                        className="form-control select2 "
                                        name="service_id"
                                        required=""
                                        id="service_id"
                                        data-select2-id="service_id"
                                        tabindex="-1"
                                        aria-hidden="true"
                                        onChange={handleDownline3}
                                    >
                                        <option value="">Select...</option>
                                        {downlineList3.map((item) => (
                                            <option value={item.empId}>{item.empName}</option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            {downlineListVisible4 && (
                                <div className='col-md-3'>
                                    <label for="service_id">
                                        Downline List4: <span className="text-danger"> *</span>
                                    </label>
                                    <select
                                        className="form-control select2 "
                                        name="service_id"
                                        required=""
                                        id="service_id"
                                        data-select2-id="service_id"
                                        tabindex="-1"
                                        aria-hidden="true"
                                        onChange={(e) => setDownline(e.target.value)}
                                    >
                                        <option value="">Select...</option>
                                        {downlineList4.map((item) => (
                                            <option value={item.empId}>{item.empName}</option>
                                        ))}
                                    </select>
                                </div>
                            )}

                        </div>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                </section>
            </div>


            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import RestService from '../http';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';



export default function TicketChart() {
    const rest = new RestService();

    const cardsData = [
        { title: 'Total Complaint', count: 10 },
        { title: 'Success Complaint', count: 5 },
        { title: 'Failed Complaint', count: 2 },
        { title: 'In Progress Complaint', count: 3 },
        { title: 'In Review Complaint', count: 1 },
    ];


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '553.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Ticket Chart</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Member Master</Link></li>
                                    <li className="breadcrumb-item"><Link to="/managemember">View Ticket</Link></li>
                                    <li className="breadcrumb-item active">View</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                <div className="container mt-4">
                                    <h1>View Ticket Status</h1>
                                    <div className="row">
                                        {cardsData.map((data, index) => (
                                            <div className="col-md-4 mb-4" key={index}>
                                                <Card>
                                                    <Card.Body>
                                                        <Link to='/viewtickets'>
                                                            <Card.Title style={{ fontWeight: "bold" }}>{data.title}</Card.Title>
                                                        </Link>

                                                        <Card.Text>Total: {data.count}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

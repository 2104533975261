import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import RestService from '../http';
import { ToastContainer } from 'react-toastify';
import EmployeeNavbar from './EmployeeNavbar';
import showToast from '../components/utilityComponents/APPToast';
import Loader from '../components/Loader';
import { useLocation } from 'react-router-dom';

export default function EditEmployee() {

    const service = new RestService();
    const location = useLocation();
    const [item, setItem] = useState({});
    const [empList, setEmpList] = useState(location.state);
    const [empId, setEmpId] = useState(empList.empId);
    const [empName, setEmpName] = useState(empList.empName);
    const [mobile, setMobile] = useState(empList.mobile);
    const [address, setAddress] = useState(empList.address);
    const [level, setLevel] = useState(empList.level);
    const [category, setCategory] = useState(empList.category);
    const [designation, setDesignation] = useState(empList.designation);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [loading, setLoading] = useState(false);


    const dropdownRef = useRef();

    const toggleDropdown = () => {
        setDropdownOpen((prevState) => !prevState);
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option.value);
        setDropdownOpen(false);
        console.log('Selected Value:', option.value);

    };

    useEffect(() => {
        setItem(empList);
    }, [setItem]);


    const handleEmpId = (e) => {
        const { value } = e.target;
        setEmpId(value);
    }

    const handleEmpName = (e) => {
        const { value } = e.target;
        setEmpName(value);
    }

    const handleMobile = (e) => {
        const { value } = e.target;
        setMobile(value);
    }

    const handleAddress = (e) => {
        const { value } = e.target;
        setAddress(value);
    }

    const handleLevel = (e) => {
        const { value } = e.target;
        setLevel(value);
    }

    const handleCategory = (e) => {
        const { value } = e.target;
        setCategory(value);
    }

    const handleDesignation = (e) => {
        const { value } = e.target;
        setDesignation(value);
    }

    const handleUpdateEmployee = () => {

        var raw = {
            "empId": empId,
            "empName": empName,
            "mobile": mobile,
            "level": level,
            "designation": designation,
            "category": category,
            "address": address
        }

        setLoading(true);

        service.post("emp/edit", raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);

                setTimeout(() => {
                    window.location.href = "/fetchemp";
                }, 1000);

            } else {
                showToast("DANGER", result.message);

                setTimeout(() => {
                    window.location.href = "/employee";
                }, 2000);
            }
        }).catch((error) => {
            console.log(error);
        })

    }


    return (
        <>
            <EmployeeNavbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: '600.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Marketing Employee</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right" style={{ marginLeft: '-20px' }}>
                                    <li className="breadcrumb-item"><a href="/admin">Dashboard</a></li>
                                    <li className="breadcrumb-item"><a href="#">Update</a></li>
                                    <li className="breadcrumb-item">employee</li>
                                    <li className="breadcrumb-item active" style={{ marginLeft: 'auto' }}>Update Employee</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <label className="text-primary">Personal Information</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Employee Id</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    name="employee_id"
                                                    value={empId}
                                                    className="form-control"
                                                    placeholder="Enter Id"
                                                    required=""
                                                    onChange={handleEmpId}
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Employee Name</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    name="employee_name"
                                                    value={empName}
                                                    className="form-control"
                                                    placeholder="Enter Name"
                                                    required=""
                                                    onChange={handleEmpName}
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Mobile</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    name="mobile"
                                                    value={mobile}
                                                    minLength="10"
                                                    maxLength="10"
                                                    className="form-control"
                                                    placeholder="Enter Mobile Number"
                                                    required=""
                                                    pattern="[0-9]{10}"
                                                    onChange={handleMobile}
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Address</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    value={address}
                                                    onChange={handleAddress}
                                                    className="form-control"
                                                    placeholder="Enter Address"
                                                    required

                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Level</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    name="level"
                                                    value={level}
                                                    className="form-control"
                                                    placeholder="Enter level"
                                                    required=""
                                                    onChange={handleLevel}
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Category</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    name="category"
                                                    value={category}
                                                    className="form-control"
                                                    placeholder="Category"
                                                    required=""
                                                    onChange={handleCategory}
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Designation</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    name="designation"
                                                    value={designation}
                                                    className="form-control"
                                                    placeholder="Enter Designation"
                                                    required=""
                                                    onChange={handleDesignation}
                                                />
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12" style={{ marginTop: '20px', textAlign: 'center' }}>
                                                <input
                                                    type="submit"
                                                    value="Update Employee"
                                                    className="btn btn-primary"
                                                    onClick={handleUpdateEmployee}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <a href="www.softmintdigital.com">SOFTMINT</a>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

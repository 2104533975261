import React, { useEffect } from "react";
import Navbar from "./Navbar";
import { useState } from "react";
import RestService from "../http";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import showToast from "./utilityComponents/APPToast";
import Loader from "./Loader";
import { Link } from "react-router-dom";

export default function AddSlabs() {

    const service = new RestService();
    const [serviceList, setServiceList] = useState([]);
    const [outCode, setOutCode] = useState('');
    const [operatorList, setOperatorList] = useState([]);
    const [slabList, setSlabList] = useState([]);
    const [serviceCode, setServiceCode] = useState('')
    const [slab1, setSlab1] = useState("");
    const [slab2, setSlab2] = useState("");
    const [incode, setIncode] = useState("");
    const [outcode, setOutcode] = useState("");
    const [isCharge, setIsCharge] = useState(false);
    const [loading, setLoading] = useState(false);


    const handleServiceCode = () => {

        service.post("service/getAllService", null).then((result) => {
            if (result.report === undefined) {
                setServiceList([]);
            } else {
                console.log(result);
                setServiceList(result.report);

            }
        })
    }

    useEffect(() => {
        handleServiceCode();
    }, []);

    const handleIncode = (e) => {

        const data = JSON.parse(e.target.value);
        setOutCode(data.operatorOutcode);
        fetchOperatorSlab(data.operatorIncode);
        console.log(outCode);

        setIncode(data.operatorIncode);
        setOutcode(data.operatorOutcode);

    }

    const handleSlabs = (e) => {
        // setSlabList(e.target.value);
        const data = slabList[e.target.value];
        console.log(data.slab1, data.slab2);
    }


    const fetchOperatorSlab = (incode) => {
        service.post(`operator/fetchOperatorSlab/${incode}`, null).then(result => {
            console.log(result);
            setSlabList(result.slabs === undefined ? [] : result.slabs);
        })
    }


    const handleOperatorCode = (serviceCode) => {
        setServiceCode(serviceCode);

        service.post(`operator/fetchOperatorByService/${serviceCode}`, null).then(result => {
            if (result.status === service.SUCCESS) {
                setOperatorList(result.report);
                fetchSlabs(serviceCode);

            } else {
                setOperatorList([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    }


    const handleAddSlab = () => {

        var raw = {
            "incode": incode,
            "outcode": outcode,
            "serviceId": serviceCode,
            "isCharge": isCharge,
            "slabs": [
                {
                    "slab1": slab1,
                    "slab2": slab2
                }
            ]
        }

        setLoading(true);

        service.post("commission/addSlab", raw).then(result => {
            setLoading(false);

            console.log(result);

            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
               
            } else {
                showToast("DANGER", result.message);
            }
        })
    };


    const fetchSlabs = (serviceCode) => {

        service.post(`commission/fetchSlabByService/${serviceCode}`, null).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                setSlabList(result.data);
            } else {
                setSlabList([]);
            }

        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        fetchSlabs();
    }, [])

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "600.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">ADD SLABS</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link href="#">Settings</Link>
                                    </li>
                                    <li className="breadcrumb-item active"> ADD SLABS</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">

                    <div className="container-fluid">
                        <div className="card" id="addpack">
                            <div className="card-body">
                                <div>
                                    <div className="row" data-select2-id="4">

                                        <div className="col-md-2" data-select2-id="4">
                                            <label for="service_id">
                                                Service Type: <span className="text-danger"> *</span>
                                            </label>
                                            <select
                                                className="form-control select2 "
                                                required=""
                                                id="service_id"
                                                data-select2-id="service_id"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                onChange={(e) => handleOperatorCode(e.target.value)}
                                            >
                                                <option>Select Id</option>
                                                {serviceList.map((item) => (
                                                    <option value={item.serviceType}>{item.serviceName}</option>
                                                ))}

                                            </select>
                                        </div>

                                        <div className="col-md-2" data-select2-id="4">
                                            <label for="service_id">
                                                Operator Code: <span className="text-danger"> *</span>
                                            </label>
                                            <select
                                                className="form-control select2 "
                                                required=""
                                                id="service_id"
                                                data-select2-id="service_id"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                onChange={handleIncode}
                                            >
                                                <option value={JSON.stringify("{}")}>SELECT OPERATOR</option>
                                                {operatorList.length > 0 ?
                                                    operatorList.map((item) => (
                                                        <option value={JSON.stringify(item)}>{item.operatorName} || {item.operatorIncode}</option>
                                                    )) : <></>}

                                            </select>
                                        </div>

                                        <div className="col-md-2" data-select2-id="4">
                                            <label for="service_id">
                                                Slab 1: <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                className="form-control select2 "
                                                style={{ width: "200px" }}
                                                name="service_id"
                                                required=""
                                                id="service_id"
                                                data-select2-id="service_id"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                onChange={(e) => setSlab1(e.target.value)}
                                            />
                                        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                        <div className="col-md-2" data-select2-id="4">
                                            <label for="service_id" className="">
                                                Slab 2: <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                className="form-control select2 "
                                                style={{ width: "200px" }}
                                                name="service_id"
                                                required=""
                                                id="service_id"
                                                data-select2-id="service_id"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                onChange={(e) => setSlab2(e.target.value)}
                                            />

                                        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                        <div className="col-md-2" data-select2-id="4">
                                            <label for="service_id">
                                                Is Charge?: <span className="text-danger"> *</span>
                                            </label>
                                            <select
                                                className="form-control select2 "
                                                name="service_id"
                                                required=""
                                                id="service_id"
                                                data-select2-id="service_id"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                onChange={(e) => setIsCharge(e.target.value)}
                                            >
                                                <option value="">select</option>
                                                <option value={true}>YES</option>
                                                <option value={false}>NO</option>

                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="text-center mb-2">
                                <input
                                    type="submit"
                                    className="btn btn-primary btn-hover text-white"
                                    value="Add Slab"
                                    onClick={handleAddSlab}
                                />
                            </div>
                        </div>

                        <div className="clearfix"></div><br />

                    </div>
                </section >

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                <div className="row">
                                    <div className="col-md-3" data-select2-id="4">
                                        <label for="service_id">
                                            Service Type: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={(e) => handleOperatorCode(e.target.value)}
                                        >
                                            <option>Select Id</option>
                                            {serviceList.map((item) => (
                                                <option value={item.serviceType}>{item.serviceName}</option>
                                            ))}

                                        </select>
                                    </div>
                                </div><br />

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th># ID</th>
                                                <th >Operator Code</th>
                                                <th>Slab1</th>
                                                <th>Slab2</th>
                                                <th>Is Charge</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!slabList || slabList.length === 0) ? (
                                                <tr className="text-center">
                                                    <td colSpan="5">NO SLABS AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                slabList.map((item, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.incode}</td>
                                                        <td>{item.slab1}</td>
                                                        <td>{item.slab2}</td>
                                                        <td>{item.isCharge}</td>
                                                    </tr>
                                                ))
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    );
}

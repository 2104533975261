import React from "react";
import { FaUsers, FaMoneyBill } from "react-icons/fa";
import { AiOutlineMinus } from "react-icons/ai";
import { BiRefresh } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import Navbar from "./Navbar";
import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import RestService from "../http";
import formatDate from "./utilityComponents/FormatDate";


export default function Admin() {

  const service = new RestService();
  const navigate = useNavigate();

  const [apiUserData, setApiUserData] = useState({});
  const [masterAdmin, setMasterAdmin] = useState({});
  const [masterDist, setMasterDist] = useState({});
  const [distributor, setDistributor] = useState({});
  const [retailer, setRetailer] = useState({});
  const [pendingDispute, setPendingDispute] = useState({});
  const [payout, setPayout] = useState({});
  const [payoutSuccessAmt, setPayoutSuccessAmt] = useState('');
  const [payoutPendingAmt, setPayoutPendingAmt] = useState('');
  const [payoutFailedAmt, setPayoutFailedAmt] = useState('');
  const [aeps, setAeps] = useState({});
  const [aepsSuccessAmt, setAepsSuccessAmt] = useState('');
  const [aadharpay, setAadharpay] = useState({});
  const [aadharSuccessAmt, setAadharSuccessAmt] = useState('');
  const [microatm, setMicroatm] = useState({});
  const [microatmSuccessAmt, setMicroatmSuccessAmt] = useState('');
  const [startDate] = useState(new Date());
  const [endDate] = useState(new Date());


  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const sessionExpiretionPopup = () => {
    if (isSessionExpired) {
      swal({
        title: "Session Expired",
        text: "Your login session has expired. Please log in again.",
        icon: "warning",
        closeOnClickOutside: false,
        buttons: {
          confirm: {
            text: "OK",
            className: "btn btn-primary",
          },
        },
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
          window.location.href = "/";
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("user");
        }
      });
    }
  };
  useEffect(() => {
    sessionExpiretionPopup();
  }, [sessionExpiretionPopup]);

  const handleDetails = () => {

    service.fetchDashboardData().then(result => result.json()).then(dashdata => {

      console.log(dashdata);

      if (dashdata.status === service.SUCCESS) {
        const dashboardData = dashdata.data;

        setApiUserData(dashboardData[0]);
        setMasterAdmin(dashboardData[1]);
        setMasterDist(dashboardData[2]);
        setDistributor(dashboardData[3]);
        setRetailer(dashboardData[4]);
        setPendingDispute(dashboardData[5]);

      } else if (dashdata.status === service.INVALID_TOKEN) {
        setIsSessionExpired(true);
      } else {
        setApiUserData(undefined);
        setMasterAdmin(undefined);
        setMasterDist(undefined);
        setDistributor(undefined);
        setRetailer(undefined);
        setPendingDispute(undefined);
      }
    }).catch((error) => {
      console.log(error);
    })
  };

  useEffect(() => {
    handleDetails();
  }, []);


  const [collectedRequest, setCollectedRequest] = useState({
    size: 0,
    balance: 0.0
  });

  // const handleServiceTxn = async () => {

  //   var raw = {
  //     "fromDate": formatDate(startDate),
  //     "toDate": formatDate(endDate)
  //   }

  //   const result = await service.fetchServiceWiseData(raw);

  //   console.log(result);

  //   if (result.status === service.SUCCESS) {
  //     const payoutData = result.data.payout;
  //     const aepsData = result.data.aeps;
  //     const aadharpayData = result.data.aadhaarPay;
  //     const microatmData = result.data.microAtm;

  //     // showToast("SUCCESS", result.message)
  //     setPayoutSuccessAmt(payoutData.success);
  //     setPayoutPendingAmt(payoutData.pending);
  //     setPayoutFailedAmt(payoutData.failed);

  //     setAadharSuccessAmt(aadharpayData.success);

  //     setAepsSuccessAmt(aepsData.success);

  //     setMicroatmSuccessAmt(microatmData.success);

  //   } else {
  //     // showToast("DANGER", result.message);
  //     setPayoutSuccessAmt(0);
  //     setPayoutPendingAmt(0);
  //     setPayoutFailedAmt(0);

  //     setAadharSuccessAmt(0);

  //     setAepsSuccessAmt(0);

  //     setMicroatmSuccessAmt(0);
  //   }
  // }

  // useEffect(() => {
  //   handleServiceTxn();
  // }, []);


  return (
    <>
      <Navbar />

      <div className="content-wrapper" style={{ minHeight: "600.667px" }}>
        <div>
          <br />
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row ">
              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box">
                  <span className="info-box-icon bg-success elevation-1">
                    <FaUsers style={{ fontSize: "40px" }} />
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">MASTER ADMIN</span>
                    <span className="info-box-number">
                      {masterAdmin === undefined
                        ? 0
                        : masterAdmin.totalMasterAdmin}
                      / ₹
                      {masterAdmin === undefined
                        ? 0.0
                        : masterAdmin.totalMasterAdminBalance}
                      <small></small>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box">
                  <span className="info-box-icon bg-danger elevation-1">
                    <FaUsers style={{ fontSize: "40px" }} />
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">MASTER DISTRIBUTOR</span>
                    <span className="info-box-number">
                      {masterDist === undefined
                        ? 0
                        : masterDist.totalMasterDistributorAdmin}
                      / ₹
                      {masterDist === undefined
                        ? 0.0
                        : masterDist.totalMasterDistributorBalance}
                      <small></small>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box">
                  <span className="info-box-icon bg-primary elevation-1">
                    <FaUsers style={{ fontSize: "40px" }} />
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">DISTRIBUTOR</span>
                    <span className="info-box-number">
                      {distributor === undefined
                        ? 0
                        : distributor.totalDistributor}
                      / ₹
                      {distributor === undefined
                        ? 0.0
                        : distributor.totalDistributorBalance}
                      <small></small>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box">
                  <span className="info-box-icon bg-warning elevation-1">
                    <FaUsers style={{ fontSize: "40px" }} />
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">RETAILER</span>
                    <span className="info-box-number">
                      {retailer === undefined
                        ? 0
                        : retailer.totalRetailer}
                      / ₹
                      {retailer === undefined
                        ? 0.0
                        : retailer.totalRetailerBalance}
                      <small></small>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box">
                  <span className="info-box-icon bg-light elevation-1">
                    <FaUsers style={{ fontSize: "40px" }} />
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">CUSTOMER</span>
                    <span className="info-box-number">
                      {/* {data === undefined
                        ? 0
                        : data.customer.totalcustomer}
                        
                      / ₹
                      {data === undefined
                        ? 0.0
                        : data.customer.totalcustomer} */}
                      <small></small>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box">
                  <span className="info-box-icon bg-info elevation-1">
                    <FaUsers style={{ fontSize: "40px" }} />
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">API USER</span>
                    <span className="info-box-number">
                      {apiUserData === undefined
                        ? 0
                        : apiUserData.totalApiUsers}
                      / ₹
                      {apiUserData === undefined
                        ? 0.0
                        : apiUserData.totalApiUserBalance}
                      <small></small>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box">
                  <span className="info-box-icon bg-danger elevation-1">
                    <FaUsers style={{ fontSize: "40px" }} />
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">NEW CUSTOMERS</span>
                    <span className="info-box-number">
                      0<small></small>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box">
                  <span className="info-box-icon bg-success elevation-1">
                    <FaMoneyBill style={{ fontSize: "40px" }} />
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">Today's Profit</span>
                    <span className="info-box-number">
                      0.00
                      <small></small>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card" style={{ lineHeight: "4" }}>
                  <div className="card-footer">
                    <div className="row">
                      {/* <div
                        className="col "
                        style={{ boxShadow: "3px 3px 7px #e845a052" }}
                      >
                        <div className="description-block">

                          <h5
                            className="description-header"
                            id="pending_dispute"

                          >
                            <span className="info-box-number">
                              {pendingDispute === undefined
                                ? 0
                                : pendingDispute.pendingTickets}
                            </span>
                          </h5>
                          <Link to="/dispute" className="text-dark" style={{ textDecoration: "none" }}>
                            <span className="description-text">
                              PENDING DISPUTE
                            </span>
                          </a>
                        </div>
                      </div> */}

                      <div
                        className="col "
                        style={{ boxShadow: "3px 3px 7px #e845a052" }}
                      >
                        <div className="description-block">
                          <Link to="/dispute" className="text-dark" style={{ textDecoration: "none" }}>
                            <span className="description-text">
                              MICROATM SUCCESS AMT.
                            </span>
                            {/* <div className="txnStatus2" style={{justifyContent:"center"}}>
                              <div className="txnStatus3">Success:</div>
                              <div className="txnStatus4">{microatmSuccessAmt}</div>
                            </div> */}
                          </Link>
                        </div>
                      </div>

                      <div
                        className="col"
                        style={{ boxShadow: "3px 3px 7px #e845a052" }}
                      >
                        <div className="description-block">

                          <Link to="/fundreqmanager" className="text-dark" style={{ textDecoration: "none" }}>
                            <span className="description-text">
                              SUCCESS PAYOUT AMT.
                            </span>

                            {/* <div className="txnStatus2" style={{ justifyContent: "center" }}>
                              <div className="txnStatus3">Success:</div>
                              <div className="txnStatus4">{payoutSuccessAmt}</div>
                            </div> */}
                          </Link>
                        </div>
                      </div>
                      <div
                        className="col"
                        style={{ boxShadow: "3px 3px 7px #e845a052" }}
                      >
                        <div className="description-block">

                          <Link to="/memberlogindetail" className="text-dark" style={{ textDecoration: "none" }}>
                            <span className="description-text">
                              PENDING PAYOUT AMT.
                            </span>

                            {/* <div className="txnStatus5" style={{ justifyContent: "center" }}>
                              <div className="txnStatus3">Pending:</div>
                              <div className="txnStatus4">{payoutPendingAmt}</div>
                            </div> */}
                          </Link>
                        </div>
                      </div>

                      <div
                        className="col"
                        style={{ boxShadow: "3px 3px 7px #e845a052" }}
                      >
                        <div className="description-block">
                          <span className="description-text">
                            FAILED PAYOUT AMT.
                          </span>
                          {/* <div className="txnStatus8" style={{ justifyContent: "center" }}>
                            <div className="txnStatus3">Failed:</div>
                            <div className="txnStatus4">{payoutFailedAmt}</div>
                          </div> */}
                        </div>
                      </div>

                      <div
                        className="col"
                        style={{ boxShadow: "3px 3px 7px #e845a052" }}
                      >
                        <div className="description-block">
                          <span className="description-text">AEPS SUCCESS</span>
                          {/* <div className="txnStatus2" style={{justifyContent:"center"}}>
                            <div className="txnStatus3">Success:</div>
                            <div className="txnStatus4">{aepsSuccessAmt}</div>
                          </div> */}
                        </div>
                      </div>

                      <div
                        className="col"
                        style={{ boxShadow: "3px 3px 7px #e845a052" }}
                      >
                        <div className="description-block">

                          <span className="description-text">AADHAR SUCCESS AMT.</span>

                          {/* <div className="txnStatus2" style={{ justifyContent: "center" }}>
                            <div className="txnStatus3">Success:</div>
                            <div className="txnStatus4">{aadharSuccessAmt}</div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer">
                    <div className="row">
                      <div className="col">
                        <div
                          className="description-block "
                          style={{ boxShadow: "-6px -5px #28a745" }}
                        >
                          <h5 className="description-header">
                            {/* {data === undefined ? 0 : data.successRecharge.totalRecharge} - ₹{data === undefined ? 0 : data.successRecharge.totalAmount} */}

                          </h5>
                          <Link to="/rechargereport" style={{ textDecoration: "none" }}>
                            <span className="description-text">
                              RECHARGE SUCCESS
                            </span>
                            
                          </Link>
                        </div>
                      </div>

                      <div className="col">
                        <div
                          className="description-block "
                          style={{ boxShadow: "-6px -5px #dc3545" }}
                        >
                          <h5 className="description-header">
                            {/* {data === undefined ? 0 : data.failedRecharge.totalRecharge} - ₹{data === undefined ? 0 : data.failedRecharge.totalAmount} */}
                          </h5>
                          <Link to="/rechargereport" style={{ textDecoration: "none" }}>
                            <span className="description-text">
                              RECHARGE PENDING
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div
                          className="description-block "
                          style={{ boxShadow: "-6px -5px #ffc107" }}
                        >
                          <Link to="/rechargereport" style={{ textDecoration: "none" }}>
                            <span className="description-text">
                              RECHARGE FAILED
                            </span>

                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div
                          className="description-block "
                          style={{ boxShadow: "-6px -5px #dc3545" }}
                        >
                          <Link to="/rechargereport" style={{ textDecoration: "none" }}>
                            <span className="description-text">
                              RECHARGE REQUEST
                            </span>

                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div
                          className="description-block "
                          style={{ boxShadow: "-6px -5px #ffc107" }}
                        >
                          <h5 className="description-header">{collectedRequest.size} - ₹{collectedRequest.balance}</h5>
                          <Link to="/transactionhis" style={{ textDecoration: "none" }}>
                            <span className="description-text">
                              COLLECTED REQUEST
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col">
                        <div
                          className="description-block "
                          style={{ boxShadow: "-6px -5px #dc3545" }}
                        >
                          <h5 className="description-header">
                            {/* {data === undefined ? 0 : data.successRecharge.totalRecharge} - ₹{data === undefined ? 0 : data.successRecharge.totalAmount} */}

                          </h5>
                          <Link to="/ticketchart" style={{ textDecoration: "none" }}>
                            <span className="description-text">
                              TOTAL COMPLAINT
                            </span>
                          </Link>
                        </div>

                      </div>

                      <div className="col">
                        <div
                          className="description-block "
                          style={{ boxShadow: "-6px -5px #28a745" }}
                        >
                          <h5 className="description-header">
                            {/* {data === undefined ? 0 : data.failedRecharge.totalRecharge} - ₹{data === undefined ? 0 : data.failedRecharge.totalAmount} */}
                          </h5>
                          <Link to="/ticketchart" style={{ textDecoration: "none" }}>
                            <span className="description-text">
                              SUCCESS COMPLAINT
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="col">
                        <div
                          className="description-block "
                          style={{ boxShadow: "-6px -5px #dc3545" }}
                        >
                          <h5 className="description-header">
                            {/* {data === undefined ? 0 : data.failedRecharge.totalRecharge} - ₹{data === undefined ? 0 : data.failedRecharge.totalAmount} */}
                          </h5>
                          <Link to="/ticketchart" style={{ textDecoration: "none" }}>
                            <span className="description-text">
                              FAILED COMPLAINT
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="col">
                        <div
                          className="description-block "
                          style={{ boxShadow: "-6px -5px #ffc107" }}
                        >
                          <h5 className="description-header">
                            {/* {data === undefined ? 0 : data.pendingRecharge.totalRecharge} - ₹{data === undefined ? 0 : data.pendingRecharge.totalAmount} */}
                          </h5>
                          <Link to="/ticketchart" style={{ textDecoration: "none" }}>
                            <span className="description-text">
                              IN PROGRESS COMPLAINT
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div
                          className="description-block "
                          style={{ boxShadow: "-6px -5px #dc3545" }}
                        >
                          <h5 className="description-header">
                            {/* {data === undefined ? 0 : data.failedRecharge.totalRecharge} - ₹{data === undefined ? 0 : data.failedRecharge.totalAmount} */}
                          </h5>
                          <Link to="/ticketchart" style={{ textDecoration: "none" }}>
                            <span className="description-text">
                              IN REVIEW COMPLAINT
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary" >
                  <div className="card-header" style={{ backgroundImage: 'linear-gradient( red, red, #ff8000)' }}>
                    <h3 className="card-title">Business Chart</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        onClick="chart_data()"
                        fdprocessedid="tvnxkm"
                      >
                        <BiRefresh className="fas fa-sync-alt" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        fdprocessedid="tpgwf"
                      >
                        <AiOutlineMinus className="fas fa-minus" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="remove"
                        fdprocessedid="pgghx7"
                      >
                        <RxCross2 className="fas fa-times" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body d-none" id="table-chart">
                    <p className="text-info">Last 12 months records</p>
                    <div className="chart">
                      <canvas
                        id="areaChart"
                        style={{
                          minHeight: "300px",
                          height: "300px",
                          maxHeight: "400px",
                          maxWidth: "100%",
                        }}
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header" style={{ backgroundImage: 'linear-gradient( red, red, #ff8000)' }}>
                    <h3 className="card-title">Business Income Chart</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        onClick="commission_chart()"
                        fdprocessedid="zkgkxw"
                      >
                        <BiRefresh className="fas fa-sync-alt" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        fdprocessedid="fwucl"
                      >
                        <AiOutlineMinus className="fas fa-minus" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="remove"
                        fdprocessedid="ris2sk"
                      >
                        <RxCross2 className="fas fa-times" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body d-none" id="commission_chart">
                    <p className="text-info">Last 12 months records</p>
                    <div className="chart">
                      <canvas
                        id="areaChartCommission"
                        style={{
                          minHeight: "300px",
                          height: "300px",
                          maxHeight: "400px",
                          maxWidth: "100%",
                        }}
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <footer className="main-footer">
        <strong>
          Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
        </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 4.5.0
        </div>
      </footer>
    </>
  );
}

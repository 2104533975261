import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import RestService from "../http";
import swal from 'sweetalert';
import { FaFileExcel } from "react-icons/fa";
import formatDate from "./utilityComponents/FormatDate";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Pagination2 from './utilityComponents/Pagination2';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';
import * as XLSX from 'xlsx';
import Select from 'react-select';



export default function AdminInitInsurance() {

    const [selectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();
    const [isSessionExpired, setIsSessionExpired] = useState(false);
    const [insuranceReport, setInsuranceReport] = useState([]);
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());
    const [userList, setUserList] = useState([]);
    const [userId, setUserId] = useState("");
    const [roles, setRoles] = useState([]);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const sessionExpiretionPopup = () => {
        if (isSessionExpired) {
            swal({
                title: 'Session Expired',
                text: 'Your login session has expired. Please log in again.',
                icon: 'warning',
                closeOnClickOutside: false,
                buttons: {
                    confirm: {
                        text: 'OK',
                        className: 'btn btn-primary',
                    },
                },
                dangerMode: true,
            }).then((confirmed) => {
                if (confirmed) {
                    // Redirect to the login page (this is just an example)
                    window.location.href = '/';
                    sessionStorage.removeItem("token");
                    sessionStorage.removeItem("user");
                }
            });
        }
    }
    useEffect(() => {
        sessionExpiretionPopup();
    }, [sessionExpiretionPopup]);


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);



    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);


    const service = new RestService();

    const handleInsuranceReport = () => {
        var raw = {
            "fromDate": formatDate(date),
            "toDate": formatDate(date1),
            "userId": userId,
        };

        setLoading(true);

        service.post("report/fetchInitInsuranceReport", raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                setInsuranceReport(result.data);

            } else {
                showToast("DANGER", result.message);
                setInsuranceReport([]);
            }

        }).catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        handleInsuranceReport();
    }, []);


    useEffect(() => {
        service.get("user/roles").then(result => {
            console.log(result);
            setRoles(result.role);

        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const handleCustomerType = (e) => {
        console.log(e.target.value);
        service
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setUserList(result.data);
                } else {
                    setUserList([]);
                }
            })
            .catch((err) => {
                console.error(err);
                setUserList([]);
            });
    };


    const [filteredInsuranceReport, setFilteredInsuranceReport] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const totalTransactionAmount = filteredInsuranceReport
        .filter(item => item.status === 'SUCCESS')
        .reduce((total, item) => total + parseFloat(item.transactionAmount || 0), 0);


    useEffect(() => {
        const filteredList = insuranceReport.filter(item =>
            item.mobile.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredInsuranceReport(filteredList);
    }, [insuranceReport, searchInput]);

    const paginatedInsuranceReport = filteredInsuranceReport.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    const totalSuccessAmount = paginatedInsuranceReport.reduce((total, item) => {
        return item.status === "SUCCESS" ? total + parseFloat(item.amount) : total;
    }, 0).toFixed(2);

    const downloadExcel = () => {
        // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
        // writeFile(wb, 'recharge_report.xlsx');

        const ws = XLSX.utils.json_to_sheet(insuranceReport);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Insurance Report');

        XLSX.writeFile(wb, 'insurance_report.xlsx');
    };


    const [selectedUserId, setSelectedUserId] = useState(null);

     const options = [
        { value: '', label: 'Select an option' },
        ...userList.map((item) => ({
            value: item.userId,
            label: `${item.name} || ${item.mobile}`,
        }))
    ];

    const   handleSelectChange = (selectedUserId) => {
        setSelectedUserId(selectedUserId);
        setUserId(selectedUserId.value);
    };

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Wallet Debit Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Report</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Wallet Debit Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label for="rec_to_load"> Start Date</label><br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label for="rec_to_load"> End Date</label>
                                                <br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    disabledDayAriaLabelPrefix="#$"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date1}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate1(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label for="assign_commission">
                                                    User Type: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleCustomerType}
                                                >
                                                    <option>Select User Type</option>
                                                    {roles.map((item) => (
                                                        item.roleId !== 0 ? <option value={item.roleId}>{item.roleName}</option> : null
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="service_id">
                                                    User Id: <span className="text-danger"> *</span>
                                                </label>
                                                {/* <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setUserId(e.target.value)}
                                                >
                                                    <option value="">Select Id...</option>
                                                    {userList.map((item) => (
                                                        <option value={item.userId}>{item.name}</option>
                                                    ))}
                                                </select> */}
                                                <Select
                                                    className="select-box width100p"
                                                    options={options}
                                                    isSearchable
                                                    value={selectedUserId}
                                                    onChange={handleSelectChange}
                                                />
                                            </div>

                                            <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />

                                            <div className="col-md-12 text-center" style={{ textAlign: 'center' }}>
                                                <button
                                                    onClick={handleInsuranceReport}
                                                    className="btn btn-primary btn-block"
                                                    style={{ marginTop: '37px', color: 'white', fontWeight: 600 }}
                                                    fdprocessedid="qnf2"
                                                >
                                                    Search
                                                </button>
                                                &nbsp;&nbsp;

                                                <button type="button" name="" className="btn btn-success" style={{ marginTop: "35px" }} onClick={downloadExcel} fdprocessedid="9svl7o">
                                                    <FaFileExcel className="fa fa-file-excel-o" />
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                <center>
                                    <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                        <div>

                                            <div className="col-md-3" style={{ float: "left" }}>
                                                <div>
                                                    <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                    <input
                                                        className="form-control text-box"
                                                        type="text"
                                                        placeholder="SEARCH BY MOBILE"
                                                        value={searchInput}
                                                        onChange={(e) => setSearchInput(e.target.value)}

                                                    />
                                                </div>
                                            </div><br />

                                            <Pagination2
                                                totalPages={Math.ceil(insuranceReport.length / itemsPerPage)}
                                                currentPage={currentPage}
                                                onChange={handlePageChange}

                                            />
                                        </div>
                                    </div>
                                </center>

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr className="wrap-text text-center">
                                                <th className="px-1">SL.NO</th>
                                                <th>User Id</th>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                                <th className="px-1">Agent Id</th>
                                                <th>Order Id</th>
                                                <th>Amount</th>
                                                <th className="px-1">Operator Name</th>
                                                <th>Service Name</th>
                                                <th>Status</th>
                                                <th className="px-1">Date</th>
                                                <th>Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!paginatedInsuranceReport || paginatedInsuranceReport.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="12">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                paginatedInsuranceReport.map((item, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.userId}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{item.agentId}</td>
                                                        <td>{item.orderId}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{item.operatorName}</td>
                                                        <td>{item.serviceName}</td>
                                                        <td style={{ color: item.status === "SUCCESS" ? 'green' : 'red', fontWeight: "bold" }}>{item.status}</td>
                                                        <td>{item.date}</td>
                                                        <td>{item.time}</td>
                                                    </tr>

                                                ))
                                            )}
                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <td colSpan="5"></td>
                                                <td colSpan="5" className="text-right" style={{ fontWeight: "bold", color: "brown" }}>
                                                    Total Success Amount: {totalSuccessAmount}
                                                </td>
                                                <td colSpan="3"></td>
                                            </tr>
                                        </tfoot>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <footer className="main-footer" >
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

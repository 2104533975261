import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import DatePicker from "react-datepicker";
import RestService from "../http";
import Loader from "./Loader";
import showToast from "./utilityComponents/APPToast";
import * as XLSX from 'xlsx';
import { FaFileExcel } from "react-icons/fa";
import Select from 'react-select';
import { Link } from "react-router-dom";
import Pagination2 from "./utilityComponents/Pagination2";


export default function Earningreport() {
  const rest = new RestService();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [roles, setRoles] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userId, setUserId] = useState("");
  const [roleId, setRoleId] = useState(-1);
  const [earningReport, setEarningReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredEarningReport, setFilteredEarningReport] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const formatDate = (date) => {
    const formattedDate = date
      .toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .reverse()
      .join("-");
    return formattedDate;
  };


  // const handleCustomerTypeChange = async () => {
  //   try {
  //     rest
  //       .get("user/roles", null)
  //       .then((response) => {
  //         console.log(response);
  //         if (response.status === rest.SUCCESS) {
  //           setRoles(response.role);
  //         } else {
  //           // setIsSessionExpired(true);
  //           setRoles([]);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  // useEffect(() => {
  //   handleCustomerTypeChange();
  // }, []);

  useEffect(() => {
    rest.get("user/roles").then(result => {
      console.log(result);
      if (result.role === undefined) {
        setRoles([])
      } else {
        setRoles(result.role);
      }
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  const handleCustomerType = (e) => {
    setRoleId(e.target.value);
    console.log(e.target.value);
    rest
      .post(`user/getUpline/${e.target.value}`, null)
      .then((result) => {
        console.log(result);
        if (result.status === rest.SUCCESS) {
          setUserList(result.data);
        } else {
          setUserList([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setUserList([]);
      });
  };

  const handleEarningReport = () => {
    try {
      let request = {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        roleId: roleId,
        userId: userId,
      };

      setLoading(true);

      rest
        .post(`report/fetchEarningReport`, request)
        .then((response) => {
          setLoading(false);

          console.log(response);

          if (response.status === rest.SUCCESS) {
            setEarningReport(response.data);

          } else if (response.status === rest.EXCEPTIONAL_ERROR) {
            console.log(response);
            setEarningReport([]);

          } else {
            showToast("DANGER", response.message);
            setEarningReport([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setEarningReport([]);
        });
    } catch (error) {
      console.log(error);
      setEarningReport([]);
    }
  };

  useEffect(() => {
    handleEarningReport();
  }, [currentPage]);


  useEffect(() => {
    const filteredList = earningReport.filter(item =>
      item.userId.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.operatorCode.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.narration.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.date.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.orderid.toLowerCase().includes(searchInput.toLowerCase())

    );
    setFilteredEarningReport(filteredList);
  }, [earningReport, searchInput]);

  const paginatedEarningReport = filteredEarningReport.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const downloadExcel = () => {
    // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
    // writeFile(wb, 'recharge_report.xlsx');

    const ws = XLSX.utils.json_to_sheet(earningReport);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Earning Report');

    XLSX.writeFile(wb, 'earning_report.xlsx');
  };


  const [selectedUserId, setSelectedUserId] = useState(null);

  const options = userList.map((item) => ({
    value: item.userId,
    label: `${item.name} || ${item.mobile}`,
  }));

  const handleSelectChange = (selectedUserId) => {
    setSelectedUserId(selectedUserId);
    setUserId(selectedUserId.value);
  };


  return (
    <>
      <Navbar />
      {loading && <Loader />}


      <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">My Earning</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Report</Link>
                  </li>
                  <li className="breadcrumb-item active">My Earning</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div>
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                        <label>From Date</label><br />
                        <DatePicker
                          className="custom-date-picker"
                          dropdownMode="select"
                          name="string"
                          fdprocessedid="9n5i4u"
                          selected={startDate}
                          dateFormat={"yyyy-MM-dd"}
                          onChange={(date) => setStartDate(date)}
                          showYearDropdown
                          showMonthDropdown
                        />
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                        <label>To Date</label>
                        <br />
                        {/* <input type="date" name="to_date" className="form-control" defaultValue="2023-07-25" /> */}
                        <DatePicker
                          className="custom-date-picker"
                          dropdownMode="select"
                          name="string"
                          fdprocessedid="9n5i4u"
                          selected={endDate}
                          dateFormat={"yyyy-MM-dd"}
                          onChange={(date) => setEndDate(date)}
                          showYearDropdown
                          showMonthDropdown
                        />
                      </div>
                      <div className="col-md-3">
                        <label>
                          Customer Type <span className="text-danger"> *</span>
                        </label>

                        <select
                          className="form-control"
                          name="customer_type"
                          id="customer_type"
                          fdprocessedid="g30y5e"
                          onChange={handleCustomerType}
                        >
                          <option value={-1}>SELECT CUSTOMER TYPE</option>
                          {roles.map((role) =>
                            role.roleId !== 0 ? (
                              <option key={role.roleId} value={role.roleId}>
                                {role.roleName}
                              </option>
                            ) : null
                          )}
                        </select>
                      </div>
                      <div className="col-md-3">
                        <label>
                          User <span className="text-danger"> *</span>
                        </label>

                        {/* <select
                          className="form-control"
                          name="customer_type"
                          id="customer_type"
                          fdprocessedid="g30y5e"
                          onChange={(e) => setUserId(e.target.value)}
                        >
                          <option value={""}>SELECT USER</option>
                          {userList.map((user) => (
                            <option key={user.userId} value={user.userId}>
                              {user.name} | {user.mobile}| {user.userId}
                            </option>
                          ))}
                        </select> */}

                        <Select
                          className="select-box width100p"
                          options={options}
                          isSearchable
                          value={selectedUserId}
                          onChange={handleSelectChange}
                        />
                      </div>

                      <div className="col-md-12 text-center">
                        <button
                          className="btn btn-primary btn-sm"
                          style={{ marginTop: "40px" }}
                          onClick={handleEarningReport}
                        >
                          <i className="fa fa-search"></i> Search
                        </button>
                        &nbsp;

                        <button type="button" name="" className="btn btn-success" style={{ marginTop: "3.2%" }} onClick={downloadExcel} fdprocessedid="9svl7o">
                          <FaFileExcel className="fa fa-file-excel-o" />
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">

                <center>
                  <div className="box-content" style={{ padding: "0", margin: "0" }}>
                    <div>

                      <div className="col-md-3" style={{ float: "left"}}>
                        <div>
                          <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                          <input
                            className="form-control text-box"
                            type="text"
                            placeholder="SEARCH..."
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}

                          />
                        </div>
                      </div><br />

                      <Pagination2
                        totalPages={Math.ceil(earningReport.length / itemsPerPage)}
                        currentPage={currentPage}
                        onChange={handlePageChange}

                      />
                    </div>
                  </div>
                </center>

                <div className="table-responsive">
                  <div
                    id="example1_wrapper"
                    className="dataTables_wrapper dt-bootstrap4"
                  >

                    <table
                      id="example1"
                      className="table table-bordered table-striped dataTable"
                      style={{ width: "100%" }}
                      role="grid"
                      aria-describedby="example1_info"
                    >
                      <thead>
                        <tr className="wrap-text" role="row">
                          <th
                            className="sorting_asc"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-sort="ascending"
                            aria-label="#: activate to sort column descending"
                            style={{ width: "8.45833px" }}
                          >
                            #
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Number: activate to sort column ascending"
                            style={{ width: "57.2708px" }}
                          >
                            USER ID
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Amount: activate to sort column ascending"
                            style={{ width: "56.1354px" }}
                          >
                            ROLE
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Operator: activate to sort column ascending"
                            style={{ width: "145.521px" }}
                          >
                            OPERATOR
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Type: activate to sort column ascending"
                            style={{ width: "34.3125px" }}
                          >
                            NARRATION
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Commission: activate to sort column ascending"
                            style={{ width: "86.6562px" }}
                          >
                            COMMISSION
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Surcharge: activate to sort column ascending"
                            style={{ width: "71.9896px" }}
                          >
                            SURCHARGE
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Surcharge: activate to sort column ascending"
                            style={{ width: "71.9896px" }}
                          >
                            TDS
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Surcharge: activate to sort column ascending"
                            style={{ width: "71.9896px" }}
                          >
                            FINAL CREDIT
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="TxnID: activate to sort column ascending"
                            style={{ width: "79.4792px" }}
                          >
                            TRANSACTION ID
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Date: activate to sort column ascending"
                            style={{ width: "135.552px" }}
                          >
                            DATE
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Date: activate to sort column ascending"
                            style={{ width: "135.552px" }}
                          >
                            TIME
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Remark: activate to sort column ascending"
                            style={{ width: "55.2188px" }}
                          >
                            REMARK
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(!paginatedEarningReport || paginatedEarningReport.length === 0) ? (
                          <tr className='text-center'>
                            <td colSpan="13">NO DATA AVAILABLE</td>
                          </tr>
                        ) : (
                          paginatedEarningReport.map((report, index) => (
                            <tr
                              key={index}
                              role="row"
                              className={index % 2 === 0 ? "even" : "odd"}
                            >
                              <td className="sorting_1">{index + 1}</td>
                              <td className="sorting_1">{report.userId}</td>
                              <td>{rest.roles[report.roleId]}</td>
                              <td>
                                {report.operatorCode}
                              </td>
                              <td>{report.narration}</td>
                              <td className="font-weight-bold text-right text-success">
                                {report.comm}
                              </td>
                              <td className="font-weight-bold text-right text-danger">
                                {report.charge}
                              </td>
                              <td className="font-weight-bold text-right text-danger">
                                {report.tds}
                              </td>
                              <td className="font-weight-bold text-right text-success">
                                {report.finalAmount}
                              </td>
                              <td>{report.orderid}</td>
                              <td>{report.date}</td>
                              <td>{report.time}</td>
                              <td>{report.remark}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td
                            colSpan="5"
                            style={{ fontWeight: "bold" }}
                            className="text-primary"
                            rowSpan="1"
                          >
                            Row Count {earningReport.length}
                          </td>
                          <td
                            className="text-right font-weight-bold text-success"
                            rowSpan="1"
                            colSpan="1"
                          >
                            ₹ {earningReport.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.comm), 0)}
                          </td>
                          <td
                            className="text-right font-weight-bold text-danger"
                            rowSpan="1"
                            colSpan="1"
                          >
                            ₹ {earningReport.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.charge), 0)}
                          </td>
                          <td
                            className="text-right font-weight-bold text-danger"
                            rowSpan="1"
                            colSpan="1"
                          >
                            ₹ {earningReport.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.tds), 0)}
                          </td>
                          <td
                            className="text-right font-weight-bold text-success"
                            rowSpan="1"
                            colSpan="1"
                          >
                            ₹ {earningReport.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.finalAmount), 0)}
                          </td>
                          <td colSpan="3" rowSpan="1"></td>
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <footer className="main-footer">
        <strong>
          Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
        </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 4.5.0
        </div>
      </footer>
    </>
  );
}

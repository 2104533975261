import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import RestService from "../http";
import { FaFileExcel } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Pagination2 from './utilityComponents/Pagination2';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';
import * as XLSX from 'xlsx';
import Select from 'react-select';
import { Link } from 'react-router-dom';


export default function AddUtiAgent() {

    const service = new RestService();

    const selectedOption = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();
    const [logReport, setLogReport] = useState([]);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [userId, setUserId] = useState('');
    const [roles, setRoles] = useState([]);
    const [userList, setUserList] = useState([]);
    const [utiAgentList, setUtiAgentList] = useState([]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);



    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);

    const handleCustomerType = (e) => {
        service
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setUserList(result.data);
                } else {
                    showToast("DANGER", result.message)
                    setUserList([]);
                }
            })
            .catch((error) => {
                console.error(error);
                setUserList([]);

            });
    };

    useEffect(() => {
        service.get("user/roles").then(result => {
            console.log(result);
            if (result.role === undefined) {
                setRoles([])
            } else {
                setRoles(result.role);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);


    const options = [
        { value: '', label: 'Select an option' },
        ...userList.map((item) => ({
            value: item.userId,
            label: `${item.name} || ${item.mobile}`,
        }))
    ];

    const handleSelectChange = (selectedUserId) => {
        setSelectedUserId(selectedUserId);
        setUserId(selectedUserId.value);
    };


    useEffect(() => {
        service.post("utiPan/fetchUtiAgent", null).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setUtiAgentList(result.data);
            } else {
                showToast("DANGER", result.message);
                setUtiAgentList([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    const [filteredAgentReport, setFilteredAgentReport] = useState([]);
    const [searchInput, setSearchInput] = useState('');


    useEffect(() => {
        const filteredList = utiAgentList.filter(item =>
            item.mobile.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredAgentReport(filteredList);
    }, [utiAgentList, searchInput]);

    const paginatedUtiAgentReport = filteredAgentReport.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    const handleAddUtiAgent = async () => {
        var raw = {
            "userId": userId
        }

        setLoading(true);

        const result = await service.post("utiPan/createUtiAgent", raw);
        setLoading(false);
        console.log(result);
        if (result.status === service.SUCCESS) {
            showToast("SUCCESS", result.message);
        } else {
            showToast("DANGER", result.message);
        }
    }


    const downloadExcel = () => {
        // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
        // writeFile(wb, 'recharge_report.xlsx');

        const ws = XLSX.utils.json_to_sheet(logReport);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'MATM Log Report');

        XLSX.writeFile(wb, 'matmLog_report.xlsx');
    };

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add UTI Agent</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Uti Agent</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Add UTI Agent</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label for="assign_commission">
                                                    User Type: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleCustomerType}
                                                >
                                                    <option>Select User Type</option>
                                                    {roles.map((item) => (
                                                        item.roleId !== 0 ? <option value={item.roleId}>{item.roleName}</option> : null
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3">
                                                <label for="service_id">
                                                    User Id: <span className="text-danger"> *</span>
                                                </label>
                                                {/* <select
                                            className="form-control select2 "
                                            name="service_id"
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={(e) => setUserId(e.target.value)}
                                        >
                                            <option value="">Select Id...</option>
                                            {userList.map((item) => (
                                                <option value={item.userId}>{item.name}</option>
                                            ))}
                                        </select> */}

                                                <Select
                                                    className="select-box width100p"
                                                    options={options}
                                                    isSearchable
                                                    value={selectedUserId}
                                                    onChange={handleSelectChange}
                                                />

                                            </div>

                                            <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                                            <div className="col-md-3" style={{ textAlign: 'center' }}>
                                                <button
                                                    onClick={handleAddUtiAgent}
                                                    className="btn btn-primary btn-block"
                                                    style={{ marginTop: '32px', color: 'white', fontWeight: 600 }}
                                                    fdprocessedid="qnf2"
                                                >
                                                    Add Agent
                                                </button>
                                                &nbsp;&nbsp;

                                                <button type="button" name="" className="btn btn-success" style={{ marginTop: "12%" }} onClick={downloadExcel} fdprocessedid="9svl7o">
                                                    <FaFileExcel className="fa fa-file-excel-o" />
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                <center>
                                    <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                        <div>

                                            <div className="col-md-3" style={{ float: "left" }}>
                                                <div>
                                                    <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                    <input
                                                        className="form-control text-box"
                                                        type="text"
                                                        placeholder="SEARCH BY MOBILE"
                                                        value={searchInput}
                                                        onChange={(e) => setSearchInput(e.target.value)}

                                                    />
                                                </div>
                                            </div><br />

                                            <Pagination2
                                                totalPages={Math.ceil(logReport.length / itemsPerPage)}
                                                currentPage={currentPage}
                                                onChange={handlePageChange}

                                            />
                                        </div>
                                    </div>
                                </center>

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr className="wrap-text text-center">
                                                <th className="px-1">SL.NO</th>
                                                <th>NAME</th>
                                                <th>USER ID</th>
                                                <th>AGENT ID</th>
                                                <th className="px-1">PAN USER ID</th>
                                                <th className="px-1">PAN PASSWORD</th>
                                                <th>AADHAR</th>
                                                <th>EMAIL</th>
                                                <th className="px-1">DATE</th>
                                                <th>TIME</th>
                                                <th>CITY</th>
                                                <th className="px-1">STATE</th>
                                                <th>STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!paginatedUtiAgentReport || paginatedUtiAgentReport.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="13">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                paginatedUtiAgentReport.map((item, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.userid}</td>
                                                        <td>{item.agentId}</td>
                                                        <td>{item.panUserid}</td>
                                                        <td>{item.panPassword}</td>
                                                        <td>{item.aadhar}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.date}</td>
                                                        <td>{item.time}</td>
                                                        <td>{item.city}</td>
                                                        <td>{item.state}</td>
                                                        <td style={{ color: item.status === "SUCCESS" ? 'green' : (item.status === "PENDING" ? 'orange' : 'red'), fontWeight: "bold" }}>{item.status}</td>

                                                    </tr>
                                                ))
                                            )}
                                        </tbody>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <footer className="main-footer" >
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

export default function Editutilityapi() {

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">Edit Utility API</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">Settings</li>
                                    <li className="breadcrumb-item">Api</li>
                                    <li className="breadcrumb-item active">Edit Utility Api</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <form action="#" method="post" acceptCharset="utf-8">
                                    <input type="hidden" name="securityToken" value="796e3b5e25cfcb0f56e216485e7616d3" />
                                    <input type="hidden" name="id" value="" />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>API Name</label>
                                            <select name="offer_type" id="offer_type" className="form-control" required>
                                                <option value=""> Select Api</option>
                                                <option value="OPERATOR_FETCH">OPERATOR FETCH</option>
                                                <option value="ROFFER">ROFFER</option>
                                                <option value="BROWSE_PLAN">BROWSE PLAN</option>
                                                <option value="BILLFETCH">BILL FETCH</option>
                                            </select>
                                        </div>

                                        <div className="col-md-4">
                                            <label htmlFor="browse_plan_api">API</label>
                                            <select className="form-control" name="api" id="api" required>
                                                <option value="">Select</option>
                                                <option value="1">OFFLINE</option>
                                                <option value="2">PLAN API</option>
                                            </select>
                                        </div>

                                        <div className="col-md-4">
                                            <label for="service_id">Service</label>
                                            <select class="form-control select2 " name="service_id" required="" id="service_id" data-select2-id="service_id" tabindex="-1" aria-hidden="true">
                                                <option value="" data-select2-id="2">Select Service</option>
                                                <option value="1" data-select2-id="6">PREPAID</option>
                                                <option value="1" data-select2-id="6">POSTPAID</option>
                                                <option value="1" data-select2-id="6">DTH</option>
                                                <option value="1" data-select2-id="6">LANDLINE</option>
                                                <option value="1" data-select2-id="6">ELECTRICITY</option>
                                                <option value="1" data-select2-id="6">GAS</option>
                                                <option value="1" data-select2-id="6">INSURANCE</option>
                                                <option value="1" data-select2-id="6">BROADBAND</option>
                                                <option value="1" data-select2-id="6">LOAN EMI</option>
                                                <option value="1" data-select2-id="6">WATER</option>
                                                <option value="1" data-select2-id="6">BOKKING GAS</option>
                                                <option value="1" data-select2-id="6">FASTAG</option>
                                                <option value="1" data-select2-id="6">SUBSCRIPTION (OTT)</option>
                                                <option value="1" data-select2-id="6">GOOGLE PLAY</option>
                                                <option value="1" data-select2-id="6">SHOPING</option>
                                                <option value="1" data-select2-id="6">NEW</option>
                                                <option value="1" data-select2-id="6">AEPS</option>

                                            </select>
                                            
                                        </div>

                                        <div className="col-md-6 mt-3">
                                            <label>URL </label>
                                            <textarea className="form-control" name="url" required placeholder="Enter URL"></textarea>
                                        </div>

                                        <div className="col-md-6 mt-3">
                                            <label>Headers</label>
                                            <textarea className="form-control" name="headers" placeholder="Enter headers"></textarea>
                                        </div>

                                        <div className="col-md-3 mt-3">
                                            <label>Method </label>
                                            <select name="method" className="form-control" required>
                                                <option value=""> Select Method</option>
                                                <option value="1">GET</option>
                                                <option value="2">POST</option>
                                                <option value="3">PATCH</option>
                                                <option value="4">DELETE</option>
                                                <option value="5">PUT</option>
                                                <option value="6">Both GET &amp; POST</option>
                                            </select>
                                        </div>

                                        <div className="mt-3 col-md-3 ">
                                            <label>Body Type </label>
                                            <select name="body_type" className="form-control">
                                                <option value=""> Select Type</option>
                                                <option value="2">x-www-form-urlencoded</option>
                                                <option value="3">raw</option>
                                            </select>
                                        </div>

                                        <div className="mt-3 col-md-6">
                                            <label>Query String </label>
                                            <textarea name="query_string" className="form-control" placeholder="Enter string"></textarea>
                                        </div>

                                        <div className="col-md-3 mt-3">
                                            <label>Response Type</label>
                                            <select name="response_type" id="response_type4" className="form-control" required>
                                                <option value=""> Select Response Type</option>
                                                <option value="1">JSON</option>
                                                <option value="2">XML</option>
                                                <option value="3">STRING</option>
                                            </select>
                                        </div>

                                        <div className="col-md-3 mt-3 " id="seprator_div4">
                                            <label>Seprator</label>
                                            <input type="text" className="form-control" name="seprator" placeholder="Enter seprator" value="" />
                                        </div>

                                        <div className="col-md-3 mt-3">
                                            <label>Status Key</label>
                                            <input type="text" className="form-control" name="status_key" placeholder="Enter key" value="" />
                                        </div>

                                        <div className="col-md-3 mt-3">
                                            <label>Success Value</label>
                                            <input type="text" className="form-control" name="success_value" placeholder="Enter Key" value="" />
                                        </div>

                                        <div className="col-md-3 mt-3">
                                            <label>Failure Value</label>
                                            <input type="text" className="form-control" name="failure_value" placeholder="Enter Key" value="" />
                                        </div>

                                        <div className="col-md-3 mt-3">
                                            <label>Records Key</label>
                                            <input type="text" className="form-control" name="records_key" placeholder="Enter Key" value="" />
                                        </div>

                                        <div className="col-md-3" style={{ marginTop: '55px' }}>
                                            <div className="icheck-primary d-inline">
                                                <input type="checkbox" id="is_array_record" value="1" name="is_array_record" />
                                                <label htmlFor="is_array_record">Is Array Record </label>
                                            </div>
                                        </div>

                                        <div className="col-md-3 mt-3">
                                            <label>Array Index Key</label>
                                            <input type="text" className="form-control" name="array_index" placeholder="Enter Key" value="" />
                                        </div>

                                       
                                            <div className="col-md-3 mt-3">
                                                <label>Operator Key</label>
                                                <input type="text" className="form-control" name="operator_value" placeholder="Enter Key" value="" />
                                            </div>

                                            <div className="col-md-3 mt-3">
                                                <label>Circle Key</label>
                                                <input type="text" className="form-control" name="circle_value" placeholder="Enter Key" value="" />
                                            </div>

                                            <div className="col-md-3 mt-3">
                                                <label>Plan Amount Key</label>
                                                <input type="text" className="form-control" name="rs_key" placeholder="Enter Key" value="" />
                                            </div>

                                            <div className="col-md-3 mt-3">
                                                <label>Description Key</label>
                                                <input type="text" className="form-control" name="desc_key" placeholder="Enter Key" value="" />
                                            </div>

                                            <div className="col-md-3 mt-3">
                                                <label>Validity Key</label>
                                                <input type="text" className="form-control" name="validity" placeholder="Enter Key" value="" />
                                            </div>

                                            <div className="col-md-3 mt-3">
                                                <label>Bill Amount Key</label>
                                                <input type="text" className="form-control" name="amount_key" placeholder="Enter Key" value="" />
                                            </div>

                                            <div className="col-md-3 mt-3">
                                                <label>Message Key</label>
                                                <input type="text" className="form-control" name="msg_key" placeholder="Enter Key" value="" />
                                            </div>

                                            <div className="col-md-3 mt-3">
                                                <label>Status</label>
                                                <select className="form-control" name="status" required>
                                                    <option value="">SELECT</option>
                                                    <option value="1">ACTIVE</option>
                                                    <option value="0">DEACTIVE</option>
                                                </select>
                                            </div>

                                            <div className="col-md-6 mt-3">
                                                <label>Bill Data</label>
                                                <textarea className="form-control" name="bill_fetch_data" placeholder="Enter json object"></textarea>
                                            </div>
                                       

                                    </div>
                                    <hr />
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-sm btn-primary">
                                            SAVE
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div >
                </section >
            </div >

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import RestService from '../http';
import { ToastContainer } from 'react-toastify';
import EmployeeNavbar from './EmployeeNavbar';
import showToast from '../components/utilityComponents/APPToast';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';

export default function FetchEmp() {

    const service = new RestService();
    const [empList, setEmpList] = useState([]);
    const [empId, setEmpId] = useState("");
    const [empName, setEmpName] = useState("");
    const [mobile, setMobile] = useState("");
    const [status, setStatus] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 10;


    const handleEmployeeData = () => {

        var raw = {
            "empId": empId,
            "empName": empName,
            "mobile": mobile,
            "status": status,
        }

        service.post("emp/getEmp", raw).then(result => {
            console.log(result);
            setEmpList(result.data);
        }).catch((error) => {
            console.log(error);
        })

    }

    useEffect(() => {
        handleEmployeeData();
    }, []);


    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
            handleEmployeeData();
        }
    };

    const handleNextPage = () => {
        if (empList.length >= pageSize) {
            setCurrentPage(currentPage + 1);
            handleEmployeeData();
        }

    };

    const getPaginatedData = () => {
        const startIndex = currentPage * pageSize;
        const endIndex = startIndex + pageSize;
        return empList.slice(startIndex, endIndex);
    };

    return (
        <>
            <EmployeeNavbar />
            <ToastContainer />

            <div className="content-wrapper" style={{ minHeight: '600.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Marketing Employee <span>
                                    <a href="/addemployee" className="btn btn-sm btn-primary">
                                        <i className="fa fa-plus"></i> NEW</a></span>&nbsp;
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right" style={{ marginLeft: '-20px' }}>
                                    <li className="breadcrumb-item"><a href="/admin">Dashboard</a></li>
                                    <li className="breadcrumb-item"><a href="#">Marketing Employee</a></li>
                                    <li className="breadcrumb-item">employee</li>
                                    <li className="breadcrumb-item active" style={{ marginLeft: 'auto' }}>Employee</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div
                                        id="example1_wrapper"
                                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                                    >

                                        <div className='card' style={{ padding: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <label>Employee Id <span>*</span></label><br />
                                                    <input
                                                        className='form-control'
                                                        onChange={(e) => setEmpId(e.target.value)}
                                                        placeholder='Enter Emp Id'
                                                    />
                                                </div>

                                                <div className='col-md-3'>
                                                    <label>Employee Name <span>*</span></label><br />
                                                    <input
                                                        className='form-control'
                                                        onChange={(e) => setEmpName(e.target.value)}
                                                        placeholder='Enter Emp Name'
                                                    />
                                                </div>

                                                <div className='col-md-3'>
                                                    <label>Mobile No. <span>*</span></label><br />
                                                    <input
                                                        className='form-control'
                                                        onChange={(e) => setMobile(e.target.value)}
                                                        placeholder='Enter Mobile No.'
                                                    />
                                                </div>

                                                <div className='col-md-3'>
                                                    <label>Status <span>*</span></label><br />
                                                    <select
                                                        className='form-control'
                                                        onChange={(e) => setStatus(e.target.value)}
                                                    >
                                                        <option value="">Select Status</option>
                                                        <option value={"1"}>ACTIVE</option>
                                                        <option value={"0"}>DEACTIVE</option>
                                                    </select>
                                                </div>

                                                <div className='col-md-12 text-center' style={{marginTop:"2%"}}>
                                                    <button className='btn bg-blue' onClick={handleEmployeeData}>Search</button>
                                                </div>

                                            </div>
                                        </div>

                                        <table
                                            className="table m-0 table-bordered table-striped dataTable no-footer"
                                            id="example1"
                                            role="grid"
                                            aria-describedby="example1_info"
                                        >
                                            <thead>
                                                <tr role="row">
                                                    <th
                                                        className="sorting_asc"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colSpan="1"
                                                        aria-sort="ascending"
                                                        aria-label="Name: activate to sort column descending"
                                                        style={{ width: '41.0729px' }}
                                                    >
                                                        No.
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colSpan="1"
                                                        aria-label="Mobile: activate to sort column ascending"
                                                        style={{ width: '51.5208px' }}
                                                    >
                                                        Employee Id
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colSpan="1"
                                                        aria-label="Email: activate to sort column ascending"
                                                        style={{ width: '101.25px' }}
                                                    >
                                                        Employee Name
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colSpan="1"
                                                        style={{ width: '80.5521px' }}
                                                    >
                                                        Joining Date
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colSpan="1"
                                                        aria-label="District: activate to sort column ascending"
                                                        style={{ width: '62.5312px' }}
                                                    >
                                                        Resign Date
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colSpan="1"
                                                        aria-label="State: activate to sort column ascending"
                                                        style={{ width: '55.75px' }}
                                                    >
                                                        Status
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colSpan="1"
                                                        style={{ width: '61.9583px' }}
                                                    >
                                                        Mobile
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colSpan="1"
                                                        aria-label="Address: activate to sort column ascending"
                                                        style={{ width: '107.271px' }}
                                                    >
                                                        Address
                                                    </th>

                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colSpan="1"
                                                        aria-label="WlId: activate to sort column ascending"
                                                        style={{ width: '45.0625px' }}
                                                    >
                                                        Wl Id
                                                    </th>

                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colSpan="1"
                                                        aria-label="Category: activate to sort column ascending"
                                                        style={{ width: '45.0625px' }}
                                                    >
                                                        Category
                                                    </th>

                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colSpan="1"
                                                        aria-label="Level: activate to sort column ascending"
                                                        style={{ width: '45.0625px' }}
                                                    >
                                                        Level
                                                    </th>

                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colSpan="1"
                                                        aria-label="Designation: activate to sort column ascending"
                                                        style={{ width: '45.0625px' }}
                                                    >
                                                        Designation
                                                    </th>

                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colSpan="1"
                                                        aria-label="Work Count: activate to sort column ascending"
                                                        style={{ width: '45.0625px' }}
                                                    >
                                                        Work Count
                                                    </th>

                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colSpan="1"
                                                        style={{ width: '45.0625px' }}
                                                    >
                                                        Action
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(!empList || empList.length === 0) ? (
                                                    <tr>
                                                        <td colSpan="16">NO DATA AVAILABLE</td>
                                                    </tr>
                                                ) : (
                                                    getPaginatedData().map((item, index) => (
                                                        // {empList.length > 0 ? empList.map((item, index) => (
                                                        <tr role="row" className="odd text-center">
                                                            <td className="sorting_1">{index + 1}</td>
                                                            <td>{item.empId}</td>
                                                            <td>{item.empName}</td>
                                                            <td>{item.joiningDate}</td>
                                                            <td>{item.resignDate}</td>
                                                            <td>{item.status}</td>
                                                            <td>{item.mobile}</td>
                                                            <td>{item.address}</td>
                                                            <td>{item.wlId}</td>
                                                            <td>{item.category}</td>
                                                            <td>{item.level}</td>
                                                            <td>{item.designation}</td>
                                                            <td>{item.workCount}</td>
                                                            <td>
                                                                <Link to={'/editemployee'} state={item} className="btn btn-sm btn-primary btn-block">
                                                                    <FaEdit className="fa fa-edit text-white" />&nbsp;
                                                                </Link>
                                                                {/* <a href="/editemp">
                                                                <FaEdit className="fa fa-edit text-primary" />&nbsp;
                                                            </a>{' '} */}
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}

                                            </tbody>
                                        </table>
                                        <div
                                            className="dataTables_info"
                                            id="example1_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            Showing 1 to 1 of 1 entries
                                        </div>
                                        <div
                                            className="dataTables_paginate paging_simple_numbers"
                                            id="example1_paginate"
                                        >
                                            <ul className="pagination">
                                                <li className={`paginate_button page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                    <button onClick={handlePreviousPage} className="page-link">
                                                        Previous
                                                    </button>
                                                </li>
                                                <li className="paginate_button page-item active">
                                                    <span className="page-link">{currentPage}</span>
                                                </li>
                                                <li className="paginate_button page-item">
                                                    <button onClick={handleNextPage} className="page-link">
                                                        Next
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <a href="www.softmintdigital.com">SOFTMINT</a>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

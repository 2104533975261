import React from "react";
import Navbar from "./Navbar";
import { useState } from "react";
import Service from '../http'
import { toast, ToastContainer } from "react-toastify";
import { FaFileExcel, FaFilePdf, FaCopy } from "react-icons/fa";
import { Link } from "react-router-dom";


export default function Packagemast() {
  const [showAddPackageForm, setShowAddPackageForm] = useState(false);
  const [packageName, setPackageName] = useState('');
  const [packageId, setPackageId] = useState('');
  const http = new Service();

  const showToast = (status, message) => {
    if (status === "SUCCESS") {
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (status === "DANGER") {
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.warn(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const toggleAddPackageForm = () => {
    setShowAddPackageForm((prevState) => !prevState);
  };

  const handlePackageName = (e) => {
    setPackageName(e.target.value);
  }

  const handlePackageId = (e) => {
    setPackageId(e.target.value);
  }

  const addPlan = () => {
    var raw = {
      packageId: packageId,
      packageName: packageName
    }
    console.log(raw);
    http.post(`commission/addPlanType`, raw).then(result => {
     
      console.log(result);
      if (result.status === 1) {
        showToast("SUCCESS", result.message);
        
      } else {
        showToast("FAILED", result.message);

      }

    });
  }

  const packageData = [
    {
      id: 1,
      packageName: "DEFAULT",
      author: "Sharad Bandarkar [MA1000][8600436163]",
      customerType: "RETAILER",
      lastUpdated: "30-04-2023 2:12:56 PM",
    },
    // Add more package objects as needed
  ];




  return (
    <>
      <Navbar />
      <ToastContainer />
      <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6 float-right">
                <h1 className="m-0 text-dark">ADD PLAN</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Member Master</Link>
                  </li>
                  <li className="breadcrumb-item active">Plan Module</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div
                  className="card col-5 m-auto"
                >
                  <div className="card-body">
                    <div>
                      <input
                        type="hidden"
                        name="securityToken"
                        value="d0d5035820edaf33c75ea6f0b08e77e8"
                      />
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="package_name">
                            Plan Name: <span className="text-danger"> *</span>
                          </label>
                          <input
                            type="text"
                            name="package_name"
                            // value=""
                            autoFocus=""
                            maxLength="100"
                            required=""
                            value={packageName}
                            className="form-control"
                            id="package_name"
                            placeholder="Enter Plan Name"
                            onChange={handlePackageName}
                          />
                          <div id="err"></div>
                        </div>

                        <div className="col-12 mt-2">
                          <label htmlFor="package_name">
                            Plan Id: <span className="text-danger"> *</span>
                          </label>
                          <input
                            type="text"
                            name="package_id"
                            // value=""
                            autoFocus=""
                            maxLength="100"
                            required=""
                            value={packageId}
                            className="form-control"
                            id="package_id"
                            placeholder="Enter Plan Id"
                            onChange={handlePackageId}
                          />
                          <div id="err"></div>
                        </div>

                        <div className="col-12 mt-2 text-center">
                          <input
                            type="submit"
                            className="btn btn-primary btn-hover text-white"
                            value="Add Plan"
                            onClick={addPlan}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <footer className="main-footer">
        <strong>
          Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
        </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 4.5.0
        </div>
      </footer>
    </>
  );
}

import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import RestService from "../http";
import { FaFileExcel } from "react-icons/fa";
import formatDate from './utilityComponents/FormatDate';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import * as XLSX from 'xlsx';
import Pagination2 from './utilityComponents/Pagination2';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';
import { Link } from 'react-router-dom';


export default function Transactionhis() {

    const service = new RestService();
    // const [selectedAPI, setSelectedAPI] = useState('');
    // const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [txnData, setTxnData] = useState([])
    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();
    const [userId, setUserId] = useState('');
    const [narration, setNarration] = useState('');
    const [mobile, setMobile] = useState('');
    const [txnId, setTxnId] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    // const pageSize = 10;
    const [status] = useState('');
    const [api, setApi] = useState('');
    const [operator, setOperator] = useState('');
    const [reportService, setReportService] = useState('');
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [filteredTxnReport, setFilteredTxnReport] = useState([]);
    const [loading, setLoading] = useState(false);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleEntriesChange = (event) => {
        setItemsPerPage(Number(event.target.value)); // Convert value to number and update state
        setCurrentPage(1); // Reset current page when entries per page changes
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // const handleStatusChange = (event) => {
    //     setSelectedStatus(event.target.value);
    // };

    // const handleAPIChange = (event) => {
    //     setSelectedAPI(event.target.value);
    // };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    // const fetchData = async () => {

    //     const result = await service.get("user/getAllUser", null);

    //     if (result.List === undefined) {
    //         setData([]);
    //     } else {
    //         setData(result.List);
    //     }
    //     return result.List;

    // };

    // useEffect(() => {
    //     fetchData();
    // }, []);


    const handleApi = (e) => {
        console.log(e.target.value);
        setApi(e.target.value);
    };

    const handleOperator = (e) => {
        console.log(e.target.value);
        setOperator(e.target.value);
    };

    const handleService = (e) => {
        console.log(e.target.value);
        setReportService(e.target.value);
    };


    const handleReportData = async () => {

        var raw = {
            startdate: formatDate(date),
            enddate: formatDate(date1),
            userId: userId,
            narration: narration,
            mobile: mobile,
            txnid: txnId,
            status: status,
            api: api,
            operator: operator,
            service: reportService
        };

        setLoading(true);

        const result = await service.post("report/getTxnReport", raw);
        setLoading(false);

        console.log(result);

        if (result.status === service.SUCCESS) {
            setTxnData(result.TxnReport)
        } else {
            showToast("DANGER", result.message);
            setTxnData([]);
            // setIsSessionExpired(true);
        }
    }

    useEffect(() => {
        handleReportData();
    }, []);


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    const downloadExcel = () => {
        // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
        // writeFile(wb, 'recharge_report.xlsx');

        const ws = XLSX.utils.json_to_sheet(txnData);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Transaction Report');

        XLSX.writeFile(wb, 'txn_report.xlsx');
    };


    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);


    const [modalVisible, setModalVisible] = useState(false);
    const [selectedTransactionId, setSelectedTransactionId] = useState(null);


    const raiseComplaint = (transId) => {
        setSelectedTransactionId(transId);
        setModalVisible(true);
    };

    const closeModal = () => {
        setSelectedTransactionId(null);
        setModalVisible(false);
    };


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [responseData, setResponseData] = useState(null);

    const openModal = (data) => {
        setIsModalOpen(true);
        setResponseData(data);
    };

    const closeModal2 = () => {
        setIsModalOpen(false);
        setResponseData(null);
    };

    useEffect(() => {
        const filteredList = txnData.filter(transaction =>
            transaction.mobile.toLowerCase().includes(searchInput.toLowerCase()) ||
                transaction.userId.toLowerCase().includes(searchInput.toLowerCase()) ||
                transaction.orderId.toLowerCase().includes(searchInput.toLowerCase()) ||
                transaction.narration.toLowerCase().includes(searchInput.toLowerCase()) ||
                transaction.operator.toLowerCase().includes(searchInput.toLowerCase()) ||
                transaction.serviceName === null ? "NA" : transaction.serviceName.toLowerCase().includes(searchInput.toLowerCase()) ||
                    transaction.userName === null ? "NA" : transaction.userName.toLowerCase().includes(searchInput.toLowerCase())

        );
        setFilteredTxnReport(filteredList);
    }, [txnData, searchInput]);

    const paginatedTxnReport = filteredTxnReport.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // const getPaginatedData = () => {
    //     const startIndex = currentPage * pageSize;
    //     const endIndex = startIndex + pageSize;
    //     return txnData.slice(startIndex, endIndex);
    // };

    // useEffect(() => {
    //     handleReportData();
    // }, [currentPage, pageSize]);

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "620.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Transaction History</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Transaction</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Transaction History</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div
                                        action="/transactionhis"
                                        method="post"
                                        id="form_data"
                                    >
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                                                <label for="rec_to_load"> Start Date</label><br />
                                                <DatePicker
                                                    className="txn-date-picker"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                                                <label for="rec_to_load"> End Date</label>
                                                <br />
                                                <DatePicker
                                                    className="txn-date-picker"
                                                    name="string"
                                                    disabledDayAriaLabelPrefix="#$"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date1}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate1(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                                                <label>Mobile Number</label>
                                                <input
                                                    type="text"
                                                    name="number"
                                                    placeholder="Mobile No"
                                                    value={mobile}
                                                    className="form-control"
                                                    onChange={(e) => setMobile(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                                                <label>TXN ID</label>
                                                <input
                                                    type="text"
                                                    name="txn_id"
                                                    placeholder="Transaction ID"
                                                    value={txnId}
                                                    className="form-control"
                                                    fdprocessedid="pzcec7"
                                                    onChange={(e) => setTxnId(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-lg-2 col-md-2 col-sm-12 col-12" data-select2-id="15">
                                                <label>API</label>
                                                <select name="api_id" class="form-control select2 " data-select2-id="1" tabindex="-1" aria-hidden="true" onChange={handleApi}>
                                                    <option value="" data-select2-id="3">Select API</option>
                                                    <option value="1" data-select2-id="17">OFFLINE</option>
                                                    <option value="2" data-select2-id="18">PLAN API</option>
                                                </select>
                                            </div>

                                            <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                                                <label>Operator</label>
                                                <select
                                                    name="operator_id"
                                                    className="form-control select2 "
                                                    data-select2-id="6"
                                                    tabIndex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleOperator}
                                                >
                                                    <option value="" data-select2-id="8" >Select Operator</option>
                                                    <option value="1">Airtel</option>
                                                    <option value="2">VI - Vodafone &amp; Idea</option>
                                                    <option value="6">BSNL Special (STV)</option>
                                                    <option value="13">Airtel Postpaid</option>
                                                </select>

                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Service</label>
                                                <select
                                                    name="service_id"
                                                    className="form-control select2 "
                                                    data-select2-id="9"
                                                    tabIndex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleService}
                                                >
                                                    <option value="" data-select2-id="11">Select Service</option>
                                                    <option value="1">PREPAID</option>
                                                    <option value="2">POSTPAID</option>

                                                </select>

                                            </div>

                                            <div className="col-lg-2 col-md-2 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>User Id</label>
                                                <input
                                                    type="text"
                                                    placeholder="UserId"
                                                    defaultValue=""
                                                    className="form-control"
                                                    fdprocessedid="9jpmht"
                                                    onChange={(e) => setUserId(e.target.value)}
                                                />

                                            </div>

                                            <div className="col-lg-2 col-md-2 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Narration</label>
                                                <input
                                                    type="text"
                                                    placeholder="Narration"
                                                    defaultValue=""
                                                    className="form-control"
                                                    fdprocessedid="9jpmht"
                                                    onChange={(e) => setNarration(e.target.value)}
                                                />

                                            </div>

                                            <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                                            <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ marginTop: '10px', textAlign: 'center' }}>
                                                <input onClick={handleReportData} type="submit" value="Search" className="btn btn-primary btn-block" style={{ marginTop: '30px' }} fdprocessedid="qnf2" />
                                            </div>

                                            <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ marginTop: '10px', textAlign: 'center' }}>
                                                <button type="button" name="" className="btn btn-success" style={{ marginTop: '30px' }} onClick={downloadExcel} fdprocessedid="9svl7o">
                                                    <span>
                                                        <FaFileExcel className="fa fa-file-excel-o" />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                {/* <center>
                                    <div
                                        className="dataTables_paginate paging_simple_numbers"
                                        id="example1_paginate"
                                        style={{ float: "right" }}
                                    >

                                        <div className="col-md-3" style={{ float: "left" }}>
                                            <div>
                                                <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                <input
                                                    className="form-control text-box"
                                                    type="text"
                                                    placeholder="SEARCH BY NUMBER"
                                                // value={searchInput}
                                                // onChange={(e) => setSearchInput(e.target.value)}
                                                />
                                            </div>
                                        </div><br />

                                        <Pagination2
                                            totalPages={Math.ceil(txnData.length / itemsPerPage)}
                                            currentPage={currentPage}
                                            onChange={handlePageChange}

                                        />
                                    </div>
                                </center> */}

                                <center>
                                    <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                        <div>
                                            <div className="col-md-3" style={{ float: "left" }}>
                                                <div>
                                                    <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                    <input
                                                        className="form-control text-box"
                                                        type="text"
                                                        placeholder="SEARCH..."
                                                        value={searchInput}
                                                        onChange={(e) => setSearchInput(e.target.value)}
                                                    />
                                                </div>
                                            </div><br />

                                            <Pagination2
                                                totalPages={Math.ceil(txnData.length / itemsPerPage)}
                                                currentPage={currentPage}
                                                onChange={handlePageChange}

                                            />

                                            <div className="col-md-2" style={{ float: "right", marginTop: "-7%" }}>
                                                <label>Show Entries</label>
                                                <select
                                                    name="example1_length"
                                                    aria-controls="example1"
                                                    className="custom-select custom-select-sm form-control form-control-sm"
                                                    fdprocessedid="6lzt2"
                                                    onChange={handleEntriesChange}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={25}>25</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                    <option value={10000}>All</option>
                                                </select>{" "}
                                            </div>

                                        </div>
                                    </div>
                                </center><br />

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr className="wrap-text text-center">
                                                <th>#</th>
                                                <th>User Id</th>
                                                <th>User Name</th>
                                                <th>Mobile</th>
                                                <th>Order Id</th>
                                                <th>Op. Bal</th>
                                                <th>Credit</th>
                                                <th>Debit</th>
                                                <th>Closing Bal</th>
                                                <th>Narration</th>
                                                <th>Remarks</th>
                                                <th>Date & Time</th>
                                                <th>Admin Opening Bal</th>
                                                <th>Admin Closing Bal</th>
                                                <th>White Label Id</th>
                                                <th>API</th>
                                                <th>Operator</th>
                                                <th>Service Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {(!txnData || txnData.length === 0) ? (
                                                <tr>
                                                    <td colSpan="18">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                getPaginatedData().map((transaction, index) => ( */}

                                            {(!filteredTxnReport || filteredTxnReport.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="18">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                filteredTxnReport.slice(startIndex, endIndex).map((transaction, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{transaction.userId}</td>
                                                        <td>{transaction.userName}</td>
                                                        <td>{transaction.mobile}</td>
                                                        <td>{transaction.orderId}</td>
                                                        <td>{transaction.openingBal.toFixed(2)}</td>
                                                        <td>{transaction.credit.toFixed(2)}</td>
                                                        <td>{transaction.debit}</td>
                                                        <td>{transaction.closingBal.toFixed(2)}</td>
                                                        <td>{transaction.narration}</td>
                                                        <td>{transaction.remarks}</td>
                                                        <td>{transaction.date} & {transaction.time}</td>
                                                        <td>{transaction.adopbal.toFixed(2)}</td>
                                                        <td>{transaction.adclbal.toFixed(2)}</td>
                                                        <td>{transaction.wlId}</td>
                                                        <td>{transaction.api}</td>
                                                        <td>{transaction.operator}</td>
                                                        <td>{transaction.serviceName}</td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <td
                                                    colSpan="4"
                                                    style={{ fontWeight: "bold" }}
                                                    className="text-primary"
                                                    rowSpan="1"
                                                >
                                                    Row Count {filteredTxnReport.length}
                                                </td>
                                                <td
                                                    className="text-right font-weight-bold text-success"

                                                >

                                                </td>

                                                <td
                                                    className="text-right font-weight-bold text-success"
                                                    rowSpan="1"
                                                    colSpan="2"
                                                >
                                                    ₹
                                                    {filteredTxnReport.reduce(
                                                        (accumulator, currentValue) =>
                                                            accumulator +
                                                            parseFloat(currentValue.credit),
                                                        0
                                                    )}

                                                </td>
                                                <td
                                                    className="text-right font-weight-bold text-danger"
                                                    rowSpan="1"
                                                    colSpan="1"
                                                >
                                                    ₹
                                                    {filteredTxnReport.reduce(
                                                        (accumulator, currentValue) =>
                                                            accumulator +
                                                            parseFloat(currentValue.debit),
                                                        0
                                                    )}
                                                </td>

                                                <td
                                                    className="text-right font-weight-bold text-success"
                                                    rowSpan="1"
                                                    colSpan="6"
                                                >
                                                    ₹{filteredTxnReport.length > 0 ? parseFloat(filteredTxnReport[0].adopbal) : 0}

                                                </td>

                                                <td
                                                    className="text-right font-weight-bold text-danger"
                                                    rowSpan="1"
                                                    colSpan="1"
                                                >
                                                    ₹{filteredTxnReport.length > 0 ? parseFloat(filteredTxnReport[filteredTxnReport.length - 1].adclbal) : 0}

                                                </td>

                                            </tr>
                                        </tfoot>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {modalVisible && (
                <div className="modal fade show" id="complaintModal" tabIndex="-1" role="dialog" aria-labelledby="complaintModalTitle" style={{ display: 'block', padding: '7px' }} aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form action="#" method="post" acceptCharset="utf-8" onSubmit={handleSubmit}>
                                <input type="hidden" name="securityToken" value="611b4fe391fdf325cbaff79919321a5d" />
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLongTitle">Modal title</h5>
                                    <button type="button" className="close" dataDismiss="modal" ariaLabel="Close">
                                        <span ariaHidden="true" onClick={closeModal} >×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <label>Transaction ID</label>
                                        <input type="text" name="transaction_id" id="txn_id" className="form-control" readOnly value={selectedTransactionId} />
                                    </div>
                                    <div className="mt-3">
                                        <label>Select Comment</label>
                                        <select className="form-control" name="complaint" required value={selectedOption} onChange={handleOptionChange}>
                                            <option value="">Select</option>
                                            <option value="1">Recharge Not Success.</option>
                                            <option value="2">Transaction success but customer not received, Please refund transaction.</option>
                                            <option value="3">Please recheck transaction and update status .</option>
                                            <option value="4">Wrong number recharge processed, Please refund transaction.</option>
                                            <option value="5">Recharge operator is different from original operator.</option>
                                            <option value="6">Other.</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" dataDismiss="modal" onClick={closeModal} >Close</button>
                                    <button type="submit" className="btn btn-primary">Send</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}


            {isModalOpen && (
                <div className="modal fade bd-example-modal-lg show" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" style={{ paddingRight: '7px', display: 'block' }} aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Response</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" onClick={closeModal2}>×</span>
                                </button>
                            </div>
                            <div className="modal-body ">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <tbody id="log">
                                            <tr>
                                                <td>
                                                    <label>LOG : </label>
                                                    {JSON.stringify(responseData)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal2}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React, { useEffect, useState } from 'react';
import { FaBackward, FaPrint } from 'react-icons/fa';
import { Link } from 'react-router-dom';


function NsdlReceipt() {

    const [data, setData] = useState({
        txnStatus: "",
        txnid: "",
        ackNo: "",
        amount: "",
        responseGeneratedTimeStamp: "",
        reqType: "",
        applicationMode: "K",
        errorMsg: "",
        adhaar: "",
        name: "",
        mobile: "",
        agentId: ""
      
    });    


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const encodedData = urlParams.get('d');
    
        if (encodedData) {
            try {
                const decodedData = decodeURIComponent(encodedData || '');
                const base64Decoded = atob(decodedData);
                const receiptData = JSON.parse(base64Decoded);
                setData(receiptData);
                console.log(receiptData);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }
    }, []);


    const handlePrint = () => {
        window.print();
    };

    return (
        <div className='card' style={{ padding: "5%" }}>
            <div className='container' style={{ border: "2px solid black", padding: "5%", width: "50%" }}>
                <div className="row">
                    <div className="col-md-6">
                        <img src='./image/softmintlogo.png' alt="Softmint Logo" style={{ width: "150px" }} />
                    </div>

                    <div className="col-md-6 text-right" style={{ marginTop: "-6%" }}>
                        <img src='./image/nsdllogo.png' alt="NSDL Logo" style={{ width: "100%" }} />
                    </div>
                </div>

                <center>
                    <div className='mt-3'>
                        <p style={{ border: "3px dotted black", fontWeight: "600", width: "65%", padding: "1%" }}>
                            Pan pplication Receipt / Acknowledgement
                        </p>
                    </div>
                </center>


                <div className='mt-3'>
                    <table className='col-md-12'>
                        <tbody>
                            <th style={{ fontSize: "15px" }}>Application Type:</th>
                            <td className='text-right' style={{ fontSize: "15px" }}>NA</td>
                        </tbody>

                        <tbody style={{ marginTop: "2%" }}>
                            <th style={{ fontSize: "15px" }}>Category:</th>
                            <td className='text-right' style={{ fontSize: "15px" }}>Individual</td>
                        </tbody>

                        <tbody>
                            <th style={{ fontSize: "15px" }}>Agent Name:</th>
                            <td className='text-right' style={{ fontSize: "15px", fontSize:"10px" }}>{data.name}</td>
                        </tbody>

                        <tbody>
                            <th style={{ fontSize: "15px" }}>Agent Number:</th>
                            <td className='text-right' style={{ fontSize: "15px" }}>{data.mobile}</td>
                        </tbody>

                        <tbody>
                            <th style={{ fontSize: "15px" }}>Aadhar Number:</th>
                            <td className='text-right' style={{ fontSize: "15px" }}>{data.adhaar}</td>
                        </tbody>

                        <tbody>
                            <th style={{ fontSize: "15px" }}>Txn Id:</th>
                            <td className='text-right' style={{ fontSize: "15px" }}>{data.txnid}</td>
                        </tbody>

                        <tbody>
                            <th style={{ fontSize: "15px" }}>Amount:</th>
                            <td className='text-right' style={{ fontSize: "15px" }}>{data.amount}</td>
                        </tbody>

                        <tbody>
                            <th style={{ fontSize: "15px" }}>Request Type:</th>
                            <td className='text-right' style={{ fontSize: "15px" }}>{data.reqType}</td>
                        </tbody>

                        <tbody>
                            <th style={{ fontWeight: "600", fontSize: "15px" }}>Acknowledgement Number:</th>
                            <td className='text-right' style={{ fontWeight: "600", fontSize: "15px" }}>{data.ackNo}</td>
                        </tbody>

                        <tbody>
                            <th style={{ fontSize: "15px" }}>Branch Code:</th>
                            <td className='text-right' style={{ fontSize: "15px" }}>{data.agentId}</td>
                        </tbody>

                        <tbody>
                            <th style={{ fontSize: "15px" }}>Message:</th>
                            <td className='text-right' style={{ fontSize: "15px" }}>{data.errorMsg}</td>
                        </tbody>

                        <tbody>
                            <th style={{ fontSize: "15px" }}>Txn Status:</th>
                            <td className='text-right' style={{ fontSize: "15px", color: data.txnStatus === 1 ? 'green' : 'red' }}>
                                {data.txnStatus === 1 ? 'SUCCESS' : 'FAILED'}
                            </td>
                        </tbody>

                        <tbody>
                            <th style={{ fontSize: "15px" }}>Date & Time:</th>
                            <td className='text-right' style={{ fontSize: "15px" }}>{data.responseGeneratedTimeStamp}</td>
                        </tbody>

                    </table>
                </div>
                <hr />

                <div className='mt-2 text-center'>
                    <p style={{ fontWeight: "bold" }}>Thank You Visit Again.</p>

                    <Link to="/side" className="btn bg-green">
                        <FaBackward /> Back
                    </Link>&nbsp;&nbsp;
                    <button className='btn bg-red' onClick={handlePrint}>Print <FaPrint /></button>
                </div>

            </div>
        </div>
    )
}

export default NsdlReceipt
import React from "react";


const UtilityBbpsReport = ({ data }) => {

    return (
        <>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr className='text-center'>
                        <th>INDEX</th>
                        <th>Customer Name</th>
                        <th>User Id</th>
                        <th>Tran Id</th>
                        <th>Operator Name</th>
                        <th>Acc. No</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Mobile</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length === 0 ? (
                        <tr>
                            <td colSpan="8" className='text-center'>NO DATA AVAILABLE</td>
                        </tr>
                    ) : (
                        data.map((item, index) => (
                            <tr className='text-center'>
                                <td>{index + 1}</td>
                                <td>{item.customerName}</td>
                                <td>{item.userId}</td>
                                <td>{item.transactionId}</td>
                                <td>{item.operatorName}</td>
                                <td className="font-weight-bold">{item.accountNo}</td>
                                <td>{item.amount}</td>
                                <td>{item.status}</td>
                                <td>{item.customerMobile}</td>
                            </tr>
                        ))
                    )}

                </tbody>
            </table>
        </>
    );

};

export default UtilityBbpsReport;

import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import RestService from "../http";
import swal from 'sweetalert';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaFileExcel } from "react-icons/fa";
import formatDate from './utilityComponents/FormatDate';
import DatePicker from "react-datepicker";
import showToast from './utilityComponents/APPToast';
import Loader from './Loader';
import Pagination2 from './utilityComponents/Pagination2';


export default function Ledgerreport() {

    const navigate = useNavigate();

    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();

    const [ledgerData, setLedgerData] = useState([]);
    const service = new RestService();
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [filteredLedgerReport, setFilteredLedgerReport] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };


    const handleLedgerReport = () => {
        var raw = {
            "startDate": formatDate(date),
            "endDate": formatDate(date1)
        };

        setLoading(true);

        service.post("report/getUserLedgerReport", raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                setLedgerData(result.data);

            } else {
                showToast("DANGER", result.message)
                setLedgerData([])
            }
        }).catch((error) => {
            console.log(error);
        })
    };

    useEffect(() => {
        handleLedgerReport();
    }, []);


    const handleOptionSelect = (option) => {
        setSelectedOption(option.mobile);
        setIsDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);


    const [modalVisible, setModalVisible] = useState(false);
    const [selectedTransactionId, setSelectedTransactionId] = useState(null);


    const raiseComplaint = (transId) => {
        setSelectedTransactionId(transId);
        setModalVisible(true);
    };

    const closeModal = () => {
        setSelectedTransactionId(null);
        setModalVisible(false);
    };


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [responseData, setResponseData] = useState(null);


    const closeModal2 = () => {
        setIsModalOpen(false);
        setResponseData(null);
    };


    // const handleEditService = (modal, item) => {
    //     console.log(item);
    //     setShowModal(modal);

    //     const { userId } = item;

    //     var raw = {
    //         "startDate": formatDate(date),
    //         "endDate": formatDate(date1),
    //         "mobile": mobile
    //     };

    //     console.log(raw);

    //     service.post("ledger/ledgerreporttrandsaction", raw).then(result => {
    //         console.log(result);
    //         setTransData(result.report);
    //     });
    // }

    useEffect(() => {
        const filteredList = ledgerData.filter(item =>
            item.clBal.toString().includes(searchInput.toLowerCase()) ||
            item.adClBal.toString().includes(searchInput.toLowerCase()) ||
            item.adOpBal.toString().includes(searchInput.toLowerCase()) ||
            item.opBal.toString().includes(searchInput.toLowerCase()) ||
            item.mobile.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.userName.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredLedgerReport(filteredList);
    }, [ledgerData, searchInput]);

    const paginatedLedgerReport = filteredLedgerReport.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}


            <div className="content-wrapper" style={{ minHeight: "620.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Ledger Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Ledger</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Ledger Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label for="rec_to_load"> Start Date</label><br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label for="rec_to_load"> End Date</label>
                                                <br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    disabledDayAriaLabelPrefix="#$"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date1}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate1(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                                            <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ marginTop: '10px', textAlign: 'center' }}>
                                                <button
                                                    className="btn btn-primary btn-block"
                                                    style={{ marginTop: '20px' }}
                                                    onClick={handleLedgerReport}
                                                >
                                                    SEARCH
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                <center>
                                    <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                        <div>

                                            <div className="col-md-3" style={{ float: "left" }}>
                                                <div>
                                                    <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                    <input
                                                        className="form-control text-box"
                                                        type="text"
                                                        placeholder="SEARCH..."
                                                        value={searchInput}
                                                        onChange={(e) => setSearchInput(e.target.value)}

                                                    />
                                                </div>
                                            </div><br />

                                            <Pagination2
                                                totalPages={Math.ceil(ledgerData.length / itemsPerPage)}
                                                currentPage={currentPage}
                                                onChange={handlePageChange}

                                            />
                                        </div>
                                    </div>
                                </center>

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr className="wrap-text text-center bg-blue">
                                                <th style={{ color: "white", fontWeight: "bold" }}>#</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>User Name</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Mobile</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>OP Bal</th>
                                                <th className="px-1" style={{ color: "white", fontWeight: "bold" }}>Add OP Bal</th>
                                                <th className="px-1" style={{ color: "white", fontWeight: "bold" }}>Add CL Bal</th>
                                                <th className="px-1" style={{ color: "white", fontWeight: "bold" }}>CL Bal</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!paginatedLedgerReport || paginatedLedgerReport.length === 0) ? (
                                                <tr>
                                                    <td colSpan="8">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                paginatedLedgerReport.map((item, index) => (
                                                    <tr key={index} className='text-center'>
                                                        <td>{index + 1}</td>
                                                        <td>{item.userName}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{item.opBal}</td>
                                                        <td>{item.adOpBal}</td>
                                                        <td>{item.adClBal}</td>
                                                        <td>{item.clBal}</td>

                                                        <td>
                                                            <Link to={'/ledgertxnreport'} state={{ "startDate": item.startDate, "endDate": item.endDate, "mobile": item.mobile }} className='btn bg-green'>
                                                                View
                                                            </Link>
                                                        </td>

                                                    </tr>
                                                ))
                                            )}

                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <td
                                                    colSpan="4"
                                                    style={{ fontWeight: "bold" }}
                                                    className="text-primary"
                                                    rowSpan="1"
                                                >
                                                    Row Count {ledgerData.length}
                                                </td>
                                                <td
                                                    className="text-right font-weight-bold text-success"

                                                >

                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {modalVisible && (
                <div className="modal fade show" id="complaintModal" tabIndex="-1" role="dialog" aria-labelledby="complaintModalTitle" style={{ display: 'block', padding: '7px' }} aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div>
                                <input type="hidden" name="securityToken" value="611b4fe391fdf325cbaff79919321a5d" />
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLongTitle">Modal title</h5>
                                    <button type="button" className="close" dataDismiss="modal" ariaLabel="Close">
                                        <span ariaHidden="true" onClick={closeModal} >×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <label>Transaction ID</label>
                                        <input type="text" name="transaction_id" id="txn_id" className="form-control" readOnly value={selectedTransactionId} />
                                    </div>
                                    <div className="mt-3">
                                        <label>Select Comment</label>
                                        <select className="form-control" name="complaint" required value={selectedOption} onChange={handleOptionChange}>
                                            <option value="">Select</option>
                                            <option value="1">Recharge Not Success.</option>
                                            <option value="2">Transaction success but customer not received, Please refund transaction.</option>
                                            <option value="3">Please recheck transaction and update status .</option>
                                            <option value="4">Wrong number recharge processed, Please refund transaction.</option>
                                            <option value="5">Recharge operator is different from original operator.</option>
                                            <option value="6">Other.</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" dataDismiss="modal" onClick={closeModal} >Close</button>
                                    <button type="submit" className="btn btn-primary">Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {isModalOpen && (
                <div className="modal fade bd-example-modal-lg show" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" style={{ paddingRight: '7px', display: 'block' }} aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Response</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" onClick={closeModal2}>×</span>
                                </button>
                            </div>
                            <div className="modal-body ">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <tbody id="log">
                                            <tr>
                                                <td>
                                                    <label>LOG : </label>
                                                    {JSON.stringify(responseData)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal2}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

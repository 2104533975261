import React, { useEffect, useState } from "react";
import RestService from "../http";
import Navbar from "./Navbar";
import { FaFileExcel, FaSearch } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import AepsRecords from "./utilityComponents/AepsRecords";
import Pagination from "./utilityComponents/Pagination";
import formatDate from "./utilityComponents/FormatDate";
import Loader from "./Loader";
import { ToastContainer } from "react-toastify";
import showToast from "./utilityComponents/APPToast";
import * as XLSX from 'xlsx';
import Select from 'react-select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Pagination2 from "./utilityComponents/Pagination2";
import { Link } from "react-router-dom";


export default function AdminAepsReport() {
  const rest = new RestService();

  const [userId, setUserId] = useState("");
  const [status, setStatus] = useState("");
  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState("0");
  const [date, setDate] = useState(new Date());
  const [date1, setDate1] = useState(new Date());
  const [aepsReport, setAepsReport] = useState([]);
  const [aepsCurrentRecord, setAepsCurrentRecord] = useState([]);
  const [userList, setUserList] = useState([]);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [nPages, setNPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState('');
  const itemsPerPage = 10;


  const handleAepsReport = () => {
    let raw = {
      userId: userId,
      status: status,
      startDate: formatDate(date),
      endDate: formatDate(date1),
    };

    setLoading(true);

    rest
      .post(`report/aepsreport`, raw)
      .then((result) => {

        setLoading(false);

        console.log(result);
        if (result.status === rest.SUCCESS) {
          setAepsReport(result.data);

          const pages = Math.ceil(result.data.length / recordsPerPage);
          setNPages(pages);
          const lastIndex = currentPage * recordsPerPage;
          const firstIndex = lastIndex - recordsPerPage;
          const currentRecords = result.data.slice(firstIndex, lastIndex);
          setAepsCurrentRecord(currentRecords);
          console.log("CURRENT RECORD", currentRecords);
        } else {
          showToast("DANGER", result.message);
          setAepsReport([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDownlineReport = () => {
    let raw = {
      "userId": userId,
      "roleId": roleId,
      "startDate": formatDate(date),
      "endDate": formatDate(date1),
    };

    setLoading(true);

    rest
      .post("report/aepsreportdownline", raw)
      .then((result) => {

        setLoading(false);

        console.log(result);
        if (result.status === rest.SUCCESS) {
          setAepsReport(result.data);

          const pages = Math.ceil(result.data.length / recordsPerPage);
          setNPages(pages);
          const lastIndex = currentPage * recordsPerPage;
          const firstIndex = lastIndex - recordsPerPage;
          const currentRecords = result.data.slice(firstIndex, lastIndex);
          setAepsCurrentRecord(currentRecords);
          console.log("CURRENT RECORD", currentRecords);
        } else {
          showToast("DANGER", result.message);
          setAepsReport([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCustomerType = (e) => {
    console.log(e.target.value);
    setRoleId(e.target.value);

    rest
      .post(`user/getUpline/${e.target.value}`, null)
      .then((result) => {
        console.log(result);
        if (result.status === rest.SUCCESS) {
          setUserList(result.data);
        } else {
          setUserList([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setUserList([]);
      });
  };

  // const handleCustomerTypeChange = async () => {
  //   try {
  //     rest
  //       .get(`user/roles`, null)
  //       .then((response) => {
  //         if (response.status === rest.SUCCESS) {
  //           setRoles(response.role);
  //         } else {
  //           setIsSessionExpired(true);
  //           setRoles([]);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  // useEffect(() => {
  //   handleCustomerTypeChange();
  // }, []);

  useEffect(() => {
    rest.get("user/roles").then(result => {
      console.log(result);
      if (result.role === undefined) {
        setRoles([])
      } else {
        setRoles(result.role);
      }
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  // const handleCurrentPage = (index) => {
  //   setCurrentPage(index);
  //   const lastIndex = index * recordsPerPage;
  //   const firstIndex = lastIndex - recordsPerPage;
  //   const currentRecords = aepsReport.slice(firstIndex, lastIndex);
  //   setAepsCurrentRecord(currentRecords);
  //   const pages = Math.ceil(aepsReport.length / recordsPerPage);
  //   setNPages(pages);
  // };

  // const handleRecordsPerPage = (size, index) => {
  //   setRecordsPerPage(size);
  //   setCurrentPage(index);
  //   const lastIndex = index * recordsPerPage;
  //   const firstIndex = lastIndex - recordsPerPage;
  //   const currentRecords = aepsReport.slice(firstIndex, lastIndex);
  //   setAepsCurrentRecord(currentRecords);
  //   const pages = Math.ceil(aepsReport.length / recordsPerPage);
  //   setNPages(pages);
  //   console.log("CURRENT RECORD", currentRecords);
  // };

  // const handleDataFilter = (text) => {
  //   const currentData = aepsReport.filter(record =>
  //     record.aadhar.toLowerCase().includes(text.toLowerCase()) ||
  //     record.terminalId.toLowerCase().includes(text.toLowerCase()) ||
  //     record.status.toLowerCase().includes(text.toLowerCase()) ||
  //     record.mobile.toLowerCase().includes(text.toLowerCase()) ||
  //     record.bankRRN.toLowerCase().includes(text.toLowerCase()) ||
  //     record.merchantTxnId.toLowerCase().includes(text.toLowerCase()) ||
  //     // record.narration.toLowerCase().includes(text.toLowerCase()) ||
  //     record.name.toLowerCase().includes(text.toLowerCase()) ||
  //     record.iin.toLowerCase().includes(text.toLowerCase()) ||
  //     record.date.toLowerCase().includes(text.toLowerCase())
  //   );

  //   const lastIndex = currentPage * recordsPerPage;
  //   const firstIndex = lastIndex - recordsPerPage;
  //   const currentRecords = currentData.slice(firstIndex, lastIndex);
  //   setAepsCurrentRecord(currentRecords);
  // };


  const downloadExcel = () => {
    // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
    // writeFile(wb, 'recharge_report.xlsx');

    const ws = XLSX.utils.json_to_sheet(aepsReport);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Aeps Report');

    XLSX.writeFile(wb, 'aeps_report.xlsx');
  };


  const [selectedUserId, setSelectedUserId] = useState(null);

  const options = userList.map((item) => ({
    value: item.userId,
    label: `${item.name} || ${item.mobile}`,
  }));

  const handleSelectChange = (selectedUserId) => {
    setSelectedUserId(selectedUserId);
    setUserId(selectedUserId.value);
  };

  const handleReportTypeChange = (e) => {
    setReportType(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // const [filteredAepsReport, setFilteredAepsReport] = useState([]);
  const [searchInput, setSearchInput] = useState('');


  useEffect(() => {
    const filteredList = aepsReport.filter(record =>
      record.aadhar.toLowerCase().includes(searchInput.toLowerCase()) ||
      record.terminalId.toLowerCase().includes(searchInput.toLowerCase()) ||
      record.status.toLowerCase().includes(searchInput.toLowerCase()) ||
      record.mobile.toLowerCase().includes(searchInput.toLowerCase()) ||
      record.bankRRN.toLowerCase().includes(searchInput.toLowerCase()) ||
      record.merchantTxnId.toLowerCase().includes(searchInput.toLowerCase()) ||
      // record.narration.toLowerCase().includes(searchInput.toLowerCase()) ||
      record.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      record.iin.toLowerCase().includes(searchInput.toLowerCase()) ||
      record.date.toLowerCase().includes(searchInput.toLowerCase())
    );

    setAepsCurrentRecord(filteredList);
  }, [aepsReport, searchInput]);

  const paginatedAepsReport = aepsCurrentRecord.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <Navbar />
      {loading && <Loader />}
      <ToastContainer />

      <div className="content-wrapper" style={{ minHeight: "570.667px" }}>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">
                  Aeps Report &nbsp;

                </h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">AEPS</Link>
                  </li>
                  <li className="breadcrumb-item active">AEPS Report</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div>

                  <div className="row">
                    <div className='col-md-12 text-center'>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="General"
                            control={<Radio />}
                            label="GENERAL REPORT"
                            onChange={handleReportTypeChange}
                          />
                          <FormControlLabel
                            value="Downline"
                            control={<Radio />}
                            label="DOWNLINE REPORT"
                            onChange={handleReportTypeChange}
                          />

                        </RadioGroup>
                      </FormControl>
                    </div>

                  </div>

                  {reportType === 'General' && (
                    <div className="row">
                      <div className="col-md-3">
                        <label for="rec_to_load"> Start Date</label><br />
                        <DatePicker
                          className="custom-date-picker"
                          name="string"
                          fdprocessedid="9n5i4u"
                          selected={date}
                          dateFormat={"yyyy-MM-dd"}
                          onChange={(date) => setDate(date)}
                          showYearDropdown
                          showMonthDropdown
                        />
                      </div>

                      <div className="col-md-3">
                        <label for="rec_to_load"> End Date</label>
                        <br />
                        <DatePicker
                          className="custom-date-picker"
                          name="string"
                          disabledDayAriaLabelPrefix="#$"
                          fdprocessedid="9n5i4u"
                          selected={date1}
                          dateFormat={"yyyy-MM-dd"}
                          onChange={(date) => setDate1(date)}
                          showYearDropdown
                          showMonthDropdown
                        />
                      </div>

                      <div className="col-md-3">
                        <label>
                          Customer Type <span className="text-danger"> *</span>
                        </label>

                        <select
                          className="custom-date-picker"
                          name="customer_type"
                          id="customer_type"
                          fdprocessedid="g30y5e"
                          onChange={handleCustomerType}
                        >
                          <option value="">SELECT CUSTOMER TYPE</option>
                          {roles.map((role) => (
                            <option key={role.roleId} value={role.roleId}>
                              {role.roleName}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-md-3">
                        <label>
                          User <span className="text-danger"> *</span>
                        </label>

                        {/* <select
                        className="custom-date-picker"
                        name="customer_type"
                        id="customer_type"
                        fdprocessedid="g30y5e"
                        onChange={(e) => setUserId(e.target.value)}
                        style={{ overflow: "scroll" }}
                      >
                        <option value={""}>SELECT USER</option>
                        {userList.map((user) => (
                          <option key={user.userId} value={user.userId}>
                            {user.name} | {user.mobile}| {user.userId}
                          </option>
                        ))}
                      </select> */}

                        <Select
                          className="select-box width100p"
                          options={options}
                          isSearchable
                          value={selectedUserId}
                          onChange={handleSelectChange}
                        />
                      </div>

                      <div className="col-md-3 mt-2">
                        <label>
                          Status <span className="text-danger"> *</span>
                        </label>

                        <select
                          className="custom-date-picker"
                          name="customer_type"
                          id="customer_type"
                          fdprocessedid="g30y5e"
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value={""}>SELECT STATUS</option>
                          <option value={"SUCCESS"}>SUCCESS</option>
                          <option value={"FAILED"}>FAILED</option>
                          <option value={"PENDING"}>PENDING</option>
                        </select>
                      </div>

                      <div className="col-md-3" style={{ marginTop: "40px" }}>
                        <button
                          className="btn btn-primary "
                          fdprocessedid="fl0v7"
                          onClick={handleAepsReport}
                        >
                          <FaSearch className="fas fa-search" /> Search
                        </button>
                        &nbsp;
                        <button type="button" name="" className="btn btn-success" onClick={downloadExcel} fdprocessedid="9svl7o">
                          <FaFileExcel className="fa fa-file-excel-o" />
                        </button>
                      </div>
                    </div>
                  )}

                  {reportType === 'Downline' && (
                    <div className="row">
                      <div className="col-md-3">
                        <label for="rec_to_load"> Start Date</label><br />
                        <DatePicker
                          className="custom-date-picker"
                          name="string"
                          fdprocessedid="9n5i4u"
                          selected={date}
                          dateFormat={"yyyy-MM-dd"}
                          onChange={(date) => setDate(date)}
                          showYearDropdown
                          showMonthDropdown
                        />
                      </div>

                      <div className="col-md-3">
                        <label for="rec_to_load"> End Date</label>
                        <br />
                        <DatePicker
                          className="custom-date-picker"
                          name="string"
                          disabledDayAriaLabelPrefix="#$"
                          fdprocessedid="9n5i4u"
                          selected={date1}
                          dateFormat={"yyyy-MM-dd"}
                          onChange={(date) => setDate1(date)}
                          showYearDropdown
                          showMonthDropdown
                        />
                      </div>

                      <div className="col-md-3">
                        <label>
                          Customer Type <span className="text-danger"> *</span>
                        </label>

                        <select
                          className="custom-date-picker"
                          name="customer_type"
                          id="customer_type"
                          fdprocessedid="g30y5e"
                          onChange={handleCustomerType}
                        >
                          <option value="0" selected>All</option>
                          {roles.map((role) => (
                            (role.roleId > 1 && role.roleId < 6) &&
                            <option key={role.roleId} value={role.roleId}>
                              {role.roleName}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-md-3">
                        <label>
                          User <span className="text-danger"> *</span>
                        </label>

                        {/* <select
                        className="custom-date-picker"
                        name="customer_type"
                        id="customer_type"
                        fdprocessedid="g30y5e"
                        onChange={(e) => setUserId(e.target.value)}
                        style={{ overflow: "scroll" }}
                      >
                        <option value={""}>SELECT USER</option>
                        {userList.map((user) => (
                          <option key={user.userId} value={user.userId}>
                            {user.name} | {user.mobile}| {user.userId}
                          </option>
                        ))}
                      </select> */}

                        <Select
                          className="select-box width100p"
                          options={options}
                          isSearchable
                          value={selectedUserId}
                          onChange={handleSelectChange}
                        />
                      </div>

                      <div className="col-md-12 text-center" style={{ marginTop: "40px" }}>
                        <button
                          className="btn btn-primary "
                          fdprocessedid="fl0v7"
                          onClick={handleDownlineReport}
                        >
                          <FaSearch className="fas fa-search" /> Search
                        </button>
                        &nbsp;
                        <button type="button" name="" className="btn btn-success" onClick={downloadExcel} fdprocessedid="9svl7o">
                          <FaFileExcel className="fa fa-file-excel-o" />
                        </button>
                      </div>
                    </div>
                  )}

                </div>
                <hr />

                {(reportType === 'Downline' || reportType === 'General') && (
                  <div className="table-responsive">
                    {/* <Pagination
                      nPages={nPages}
                      currentPage={currentPage}
                      setCurrentPage={handleCurrentPage}
                      setPageSize={handleRecordsPerPage}
                      setFilterData={handleDataFilter}
                    /> */}

                    <div className="col-md-3" style={{ float: "left" }}>
                      <div>
                        <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                        <input
                          className="form-control text-box"
                          type="text"
                          placeholder="SEARCH..."
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}

                        />
                      </div>
                    </div><br />

                    <Pagination2
                      totalPages={Math.ceil(aepsReport.length / itemsPerPage)}
                      currentPage={currentPage}
                      onChange={handlePageChange}
                    />

                    <AepsRecords
                      data={paginatedAepsReport}
                      allRecord={aepsReport}
                    />
                    
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>

      <footer className="main-footer">
        <strong>
          Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
        </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 4.5.0
        </div>
      </footer>
    </>
  );
}

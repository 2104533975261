
class APPConstant {

    FAILED = 0;
    SUCCESS = 1;

    ACTIVE = 1;
    INACTIVE = 0;

    NOT_PERMITTED = 2;
    SOMETHING_WENT_WRONG = 3;
    PENDING = 4;
    EXCEPTIONAL_ERROR = 5;
}

export default APPConstant;
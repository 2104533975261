import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Loginalert() {

    const [showAlert, setShowAlert] = useState(true);

    const handleAlertClose = () => {
        setShowAlert(false);
    };

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">Login Alert</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Utility</Link></li>
                                    <li className="breadcrumb-item active">Login Alert</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card" id="addpack">
                            <div className="card-body">
                                {showAlert && (
                                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                        alert image size should be max 600*500px Allowed Types jpg,png,gif
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={handleAlertClose}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                )}
                                <form action="/loginalert" method="post" encType="multipart/form-data">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <label>Heading</label><span className="text-danger"> *</span><br />
                                            <input type="text" name="heading" value="" placeholder="Heading" className="form-control" required />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <label>Alert</label>
                                            <input type="file" name="html_code" className="form-control" />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label>Alert Enable/Disable</label><span className="text-danger"> *</span><br />
                                            <input type="radio" name="status" value="1" required /> Enable&nbsp;&nbsp;
                                            <input type="radio" name="status" value="0" required /> Disable
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label>Notification Type</label><span className="text-danger"> *</span><br />
                                            <input type="radio" name="notification_type" value="1" required /> App&nbsp;&nbsp;
                                            <input type="radio" name="notification_type" value="0" required /> Web
                                        </div>
                                        <input type="hidden" name="securityToken" value="fb30010b54f1ddcaec64b95f099ad2ce" />
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                            <input type="submit" value="Add Alert" className="btn btn-primary" style={{ marginTop: '30px' }} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card" id="addpack">
                            <div className="card-body">
                                <div className="table-responsive" style={{ marginTop: '50px' }}>
                                    <div id="example1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <div className="dataTables_length" id="example1_length">
                                            <label>
                                                Show{' '}
                                                <select name="example1_length" aria-controls="example1" className="custom-select custom-select-sm form-control form-control-sm">
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="All">All</option>
                                                </select>{' '}
                                                entries
                                            </label>
                                        </div>
                                        <div className="dt-buttons">
                                            <Link className="dt-button buttons-copy buttons-html5" tabIndex="0" aria-controls="example1" title="Copy">
                                                <span>
                                                    <i className="fa fa-files-o text-info font-weight-bold"></i>
                                                </span>
                                            </Link>
                                            <Link className="dt-button buttons-excel buttons-html5" tabIndex="0" aria-controls="example1" title="Excel">
                                                <span>
                                                    <i className="fa fa-file-excel-o text-success font-weight-bold"></i>
                                                </span>
                                            </Link>
                                            <Link className="dt-button buttons-pdf buttons-html5" tabIndex="0" aria-controls="example1" title="PDF">
                                                <span>
                                                    <i className="fa fa-file-pdf-o text-danger font-weight-bold"></i>
                                                </span>
                                            </Link>
                                        </div>
                                        <table id="example1" className="table m-0 dataTable no-footer" role="grid" aria-describedby="example1_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting_asc" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="#: activate to sort column descending" style={{ width: '40.1875px' }}>
                                                        #
                                                    </th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="alert img: activate to sort column ascending" style={{ width: '126.427px' }}>
                                                        alert img
                                                    </th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Active/Deactive: activate to sort column ascending" style={{ width: '198.917px' }}>
                                                        Active/Deactive
                                                    </th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Notification Type: activate to sort column ascending" style={{ width: '216.083px' }}>
                                                        Notification Type
                                                    </th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Action: activate to sort column ascending" style={{ width: '97.3854px' }}>
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="odd">
                                                    <td valign="top" colSpan="5" className="dataTables_empty">
                                                        No data available in table
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                            Showing 0 to 0 of 0 entries
                                        </div>
                                        <div className="dataTables_paginate paging_simple_numbers" id="example1_paginate">
                                            <ul className="pagination">
                                                <li className="paginate_button page-item previous disabled" id="example1_previous">
                                                    <Link aria-controls="example1" data-dt-idx="0" tabIndex="0" className="page-link">
                                                        Previous
                                                    </Link>
                                                </li>
                                                <li className="paginate_button page-item next disabled" id="example1_next">
                                                    <Link aria-controls="example1" data-dt-idx="1" tabIndex="0" className="page-link">
                                                        Next
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import RestService from '../http';
import showToast from './utilityComponents/APPToast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';


export default function Editpermission() {

  const location = useLocation();
  const navigate = useNavigate();
  const service = new RestService();
  const [permissionReport] = useState(location.state);
  const [addPermission, setAddPermission] = useState(permissionReport.addPermission);
  const [editPermission, setEditPermission] = useState(permissionReport.editPermission);


  const handlePermission = () => {

    var raw = {
      "userId": permissionReport.userId,
      "addPermission": addPermission,
      "editPermission": editPermission
    }

    console.log(raw);

    service.post("user/updatemovetobankpermission", raw).then(result => {
      console.log(result);

      if (result.status === service.SUCCESS) {
        showToast("SUCCESS", result.message);

        setTimeout(() => {
          window.location.href = "/movetobankpermissiontable";
      }, 1000);
      
      } else {
        showToast("DANGER", result.message);

      //   setTimeout(() => {
      //     window.location.href = "/movetobankpermissiontable";
      // }, 2000);
      }
    }).catch((error) => {
      console.log(error);
    })
  }


  const handleAddPermission = (e) => {
    const { value } = e.target;
    setAddPermission(value);
  };

  const handleEditPermission = (e) => {
    const { value } = e.target;
    setEditPermission(value);
  };


  return (
    <>
      <Navbar />
      <ToastContainer/>

      <div className="content-wrapper" style={{ minHeight: '532.667px' }}>

        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Edit Permission</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Settings</Link></li>
                  <li className="breadcrumb-item"><Link to="/operatormast">Permission</Link></li>
                  <li className="breadcrumb-item active"> Edit Permission</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className='content'>
          <div className='container-fluid'>
            <div className='card' id='addpack'>
              <div className="card-header" >
                Edit Permission
              </div>

              <div className='card-body'>
                <div className="row">
                  <div className="col-md-6">
                    <label className="font-weight-normal">
                      Add Permission: <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      name="add_permission"
                      className="form-control"
                      value={addPermission}
                      onChange={handleAddPermission}
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="font-weight-normal">
                      Edit Permission: <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      name="edit_permission"
                      className="form-control"
                      value={editPermission}
                      onChange={handleEditPermission}
                    />
                  </div>

                </div>

                <div className='raw mt-3 text-center'>
                  <div className='col-md-12'>
                    <button className='btn bg-green' onClick={handlePermission}>Update</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="main-footer">
        <strong>
          Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
        </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 4.5.0
        </div>
      </footer>
    </>
  )
}

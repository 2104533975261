import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestService from "../http";
import { Link } from "react-router-dom";

export default function Operatormast() {
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const [operatorName, setOperatorName] = useState("");
  const [serviceProvider, setServiceProvider] = useState();
  const [serviceProviderName, setServiceProviderName] = useState([]);
  const [outCode, setOutCode] = useState("");
  // const [serviceType, setServiceType] = useState([]);
  const [incodeListData, setIncodeListData] = useState([]);
  const [apiList, setApiList] = useState([]);
  const [serviceId, setServiceId] = useState();
  const [apiId, setApiId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [planId, setPlanId] = useState('');
  const [operatorList, setOperatorList] = useState([]);
  const [incode, setIncode] = useState("");
  // const [commission, setCommission] = useState("");
  // const [commissionType, setCommissionType] = useState("");
  const [serviceCode, setServiceCode] = useState('')
  // const [slabList, setSlabList] = useState([]);
  const [assignUserList, setAssignUserList] = useState([]);
  const service = new RestService();
  const [viewSlab, setViewSlab] = useState([]);
  const [slabName, setSlabName] = useState('');
  const [slabs, setSlabs] = useState([{ id: 1, value: '' }]);
  // const [serviceName, setServiceName] = useState([]);


  // const handleModal = (e, incode) => {
  //   setShowModal(true);
  //   console.log(incode);
  //   service.post(`operator/fetchOperatorSlab/${incode}`, null).then((result) => {
  //     setViewSlab(result.slabs === undefined ? [] : result.slabs);
  //     console.log(result.slabs);
  //   });
  // }

  // const handleCommission = (e) => {
  //   setCommission(e.target.value);
  // }


  const handleAddSlab = () => {

    var raw = {
      "incode": "1",
      "serviceId": 2,
      "isCharge": false,
      "slabs": [
        {
          "slab1": "101",
          "slab2": "500"
        },
        {
          "slab1": "501",
          "slab2": "999"
        },
        {
          "slab1": "1000",
          "slab2": "1499"
        },
        {
          "slab1": "1500",
          "slab2": "1999"
        },
        {
          "slab1": "2000",
          "slab2": "2499"
        },
        {
          "slab1": "2500",
          "slab2": "2999"
        },
        {
          "slab1": "3000",
          "slab2": "4999"
        },
        {
          "slab1": "5000",
          "slab2": "7999"
        },
        {
          "slab1": "8000",
          "slab2": "10000"
        }
      ]
    }

    service.post("commission/addSlab", raw).then(result => {
      setSlabName()
    })
  };

  const handleServiceChange = (data) => {
    const parsedData = JSON.parse(data);
    setIncode(parsedData.incode);
    var raw = {
      "serviceType": parsedData.serviceCode
    };

    service.post("api/fetchApiByService", raw).then(result => {
      setApiList(result.report);
      console.log(result.report)
    }).catch((error) => {
      console.log('error', error);
    });
  };

  const handleSelectApi = (e) => {
    setApiId(e.target.value);
  };

  const handleOperatorChange = (e) => {
    setOperatorName(e.target.value);
  };

  const handleServiceProviderName = () => {

    service.post("service/fetchAllServiceProvider", null).then(result => {
      setServiceProviderName(result.data);
      console.log(result)

    }).catch((error) => {
      console.log('error', error);
    });

  };

  useEffect(() => {
    handleServiceProviderName();
  }, []);

  const handleOutcodeChange = (e) => {
    setOutCode(e.target.value);
  };



  const handleCloseModal = () => {
    setShowModal(false);
  };


  const showToast = (status, message) => {
    if (status === "SUCCESS") {
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (status === "DANGER") {
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.warn(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const getAllPackageType = () => {
    service.post("plans/getAllPlans", null).then(result => {
      if (result.status === service.SUCCESS) {
        setPlanList(result.report);
      } else {
        setPlanList([]);
      }
    }).catch(error => {
      console.log(error);

    });
  };

  useEffect(() => {
    getAllPackageType();
  }, []);

  const addOperator = () => {
    var raw = {
      operatorProviderName: serviceProvider,
      outcode: outCode,
      incode: incode,
      serviceType: serviceId,
      apiId: apiId,
    };

    service.post("operator/addOperator", raw).then(result => {
      console.log(result);
      setData(result);
      if (result.status === 1) {
        showToast("SUCCESS", result.message);

      } else {
        showToast("FAILED", result.message);

      }
    }).catch(error => {
      console.log(error);
    })
  };

  //retailerservices api
  const [servicesData, setServicesData] = useState();
  const [serviceList, setServiceList] = useState([]);

  const getServiceList = () => {
    service.post("service/getAllService", null).then((result) => {
      // setServiceList(result.report != undefined ? result.report : []);
      console.log(result);
      if (result.status === service.SUCCESS) {
        setServiceList(result.report);
      } else {
        setServiceList([]);
      }
    }).catch(error => {
      console.log(error);
    })
  };

  useEffect(() => {
    getServiceList();
  }, []);



  const handleServicesData = (serviceId) => {
    var raw = {
      serviceType: serviceId,
    };

    service.post("operator/fetchOperator", raw).then((result) => {
      setServicesData(result.report === undefined ? [] : result.report);
    }).catch(error => {
      console.log(error);
    })
  };

  useEffect(() => {
    handleServicesData();
  }, []);

  const [operatorData, setOperatorData] = useState([]);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);

    service.post(`operator/fetchOperatorByService/${tabId}`, null).then(result => {
      console.log(result);
      setOperatorData(result.report === undefined ? [] : result.report);
    }).catch((error) => {
      console.log(error);
    })
  };

  const [showAddOperator, setShowAddOperator] = useState(false);
  const [showAddIncode, setShowAddIncode] = useState(false);

  const handleNewOperatorClick = () => {
    setShowAddOperator(true);
    setShowAddIncode(false);
  };

  const handleAddIncode = () => {
    setShowAddOperator(false);
    setShowAddIncode(true);
  };


  const handleCommissionType = (e) => {
    console.log(e.target.value);
  }

  const handlePlanId = (e) => {
    setPlanId(e.target.value);
  }

  const handleServiceId = (e) => {
    setServiceCode(e.target.value);
    var raw = {
      serviceType: e.target.value
    }
    service.post('operator/fetchOperator', raw).then(result => {
      setOperatorList(result.report === undefined ? [] : result.report)
    });
  }

  // const fetchOperatorSlab = (incode) => {
  //   console.log(incode);
  //   service.post(`operator/fetchOperatorSlab/${incode}`, null).then(result => {
  //     console.log(result);
  //     setSlabList(result.slabs === undefined ? [] : result.slabs);
  //   })
  // }

  const handleAssignUserType = (e) => {
    service.post(`package/fetchUserGroup?userType=${e.target.value}`, null).then(result => {
      if (result.status === service.SUCCESS) {
        setAssignUserList(result.data);
      } else {
        setAssignUserList([]);
      }
      console.log(result);
    }).catch(error => {
      console.log(error);
    });
  }



  const handlePlanList = () => {
    service.post("plans/getAllPlans", null).then(result => {
      if (result.status === service.SUCCESS) {
        setPlanList(result.report);
      } else {
        setPlanList([]);
      }
    }).catch(error => {
      console.log(error);

    });
  }

  useEffect(() => {
    handlePlanList();
  }, []);


  // const handleAddCommission = () => {
  //   const slabsdata = viewSlab.at(slabIndex);
  //   var raw = {
  //     "packageId": packageId,
  //     "planId": planId,
  //     "serviceCode": serviceCode,
  //     "incode": incode,
  //     "commission": commission,
  //     "commissionType": commissionType,
  //     "isCharge": isChecked,
  //     "slab1": slabsdata[0],
  //     "slab2": slabsdata[1]
  //   };
  //   console.log(raw);
  //   service.post("operator/addSlab", raw).then(result => {
  //     if (result.status === service.SUCCESS) {
  //       showToast("SUCCESS", result.message);

  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 1000);
  //     } else {
  //       showToast("FAILED", result.message);

  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 1000);
  //     }
  //   })
  // }

  //For add operator incode 

  const handleAddOperatorIncode = () => {

    var raw = {
      "operatorName": operatorName,
      "serviceType": serviceId
    }

    service.post("operator/addOperatorIncode", raw).then(result => {
      console.log(result);
      if (result.status === service.SUCCESS) {
        showToast("SUCCESS", result.message);
      
      } else {
        showToast("DANGER", result.message);

      }
    }).catch(error => {
      console.log(error);
      showToast("DANGER", error);

    })
  }


  const handleOperatorStatus = (e, status, outcode, id) => {
    console.log(e, status, outcode, id);

    var raw = {
      "id": id,
      "outcode": outcode,
      "status": !status
    };

    service.post("operator/updateOperator", raw).then(result => {
      if (result.status === service.SUCCESS) {
        showToast("SUCCESS", result.message);
        handleTabClick(activeTab);
      } else {
        showToast("DANGER", result.message);

      }
    }).catch(error => {
      console.log(error);
      showToast("DANGER", error);
    });
  }


  const handleServiceType = (serviceId) => {

    service.post(`operator/fetchIncodeByService/${serviceId}`, null).then(result => {
      console.log(result.data);
      if (result.status === service.SUCCESS) {
        setIncodeListData(result.data);
      } else {
        setIncodeListData([]);
      }

    }).catch((error) => {
      console.log(error);
    });

    var raw = {
      "serviceType": serviceId
    }

    service.post("api/fetchApiByService", raw).then(result => {
      console.log(result);
      if (result.status === service.SUCCESS) {
        setApiList(result.report);

      } else {
        setApiList([]);
      }
    }).catch((error) => {
      console.log(error)
    })

  }

  return (
    <>
      <Navbar />
      <ToastContainer />

      <div className="content-wrapper" style={{ minHeight: "570.667px" }}>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Operator Master</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Settings</Link>
                  </li>
                  <li className="breadcrumb-item active">Operator Master</li>
                </ol>
              </div>
            </div>
          </div>
          <br />

          <div className="content">
            <div className="container-fluid">
              {showAddOperator && (
                <div className="card" id="addpack">
                  <div
                    className="card-header text-primary"
                    style={{ fontWeight: "bold" }}
                  >
                    Add Operator:
                  </div>

                  <div className="card-body">
                    <div className="alert alert-primary">
                      <strong>Note: </strong>After adding operator, You must
                      have to set API(API Switching) for that operator in order
                      to working correctly.
                    </div>
                    <div
                      method="post"
                      accept-charset="utf-8"
                      data-select2-id="6"
                    >
                      <input
                        type="hidden"
                        name="securityToken"
                        value="a7725efcc034af34ecbb58036babadae"
                      />
                      <div className="row" data-select2-id="5">
                        <div className="col-md-3">
                          <label for="operator_name">
                            Service Provider Name:{" "}
                            <span className="text-danger"> *</span>
                          </label>
                          <select
                            onChange={(e) => setServiceProvider(e.target.value)}
                            autofocus=""
                            required=""
                            className="form-control"
                          >
                            <option>Select Service Provider</option>
                            {serviceProviderName.map((type) => (
                              <option
                                key={type.serviceProvider}
                                value={type.serviceProvider}
                              >
                                {type.serviceProvider}
                              </option>
                            ))}
                          </select>
                        </div>


                        <div className="col-md-3" data-select2-id="4">
                          <label for="service_id">
                            Service Type:{" "}
                            <span className="text-danger"> *</span>
                          </label>
                          <select
                            onChange={(e) => handleServiceType(e.target.value)}
                            className="form-control select2 "
                            name="service_id1"
                            required=""
                            id="service_id1"
                            data-select2-id="service_id1"
                            tabindex="-1"
                            aria-hidden="true"
                          >
                            <option>Select Service Type</option>
                            {serviceList.map((type) => (
                              type.status ? (
                                <option value={type.serviceType}>
                                  {type.serviceName}
                                </option>
                              ) : null
                            ))}
                          </select>
                        </div>

                        <div className="col-md-3" data-select2-id="4">
                          <label for="service_id">
                            Incode:{" "}
                            <span className="text-danger"> *</span>
                          </label>
                          <select
                            className="form-control select2 "
                            name="service_id"
                            required=""
                            id="service_id"
                            data-select2-id="service_id"
                            tabindex="-1"
                            aria-hidden="true"
                            onChange={(e) => setIncode(e.target.value)}
                          >
                            <option>Select Incode</option>
                            {incodeListData.map((incodeData, index) => (
                              <option
                                value={incodeData.incode}
                              >
                                {incodeData.incode} | {incodeData.operatorName}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-md-3" data-select2-id="4">
                          <label for="service_id">
                            Select API:{" "}
                            <span className="text-danger"> *</span>
                          </label>
                          <select
                            onChange={handleSelectApi}
                            className="form-control select2 "
                            name="service_id"
                            required=""
                            id="service_id"
                            data-select2-id="service_id"
                            tabindex="-1"
                            aria-hidden="true"
                          >
                            <option>Select API</option>
                            {apiList.map((type) => (
                              <option
                                value={type.apiId}
                              >
                                {type.apiName}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-md-3 mt-2">
                          <label for="operator_code">
                            Operator Code:{" "}
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            type="text"
                            name="operator_code"
                            onChange={handleOutcodeChange}
                            autofocus=""
                            maxlength="100"
                            required=""
                            className="form-control"
                            id="operator_name"
                            placeholder="Enter Operator Code"
                            fdprocessedid="93t1yq"
                          />
                          <div id="err"></div>
                        </div>

                      </div>
                      <div
                        className="text-center"
                        style={{ marginTop: "30px" }}
                      >
                        <input
                          type="button"
                          onClick={addOperator}
                          className="btn btn-primary btn-hover text-white"
                          value="Add Operator"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}


              {showAddIncode && (
                <div className="card" id="addpack">
                  <div
                    className="card-header text-primary"
                    style={{ fontWeight: "bold" }}
                  >
                    Add Operator:
                  </div>

                  <div className="card-body">

                    <div
                      method="post"
                      accept-charset="utf-8"
                      data-select2-id="6"
                    >
                      <input
                        type="hidden"
                        name="securityToken"
                        value="a7725efcc034af34ecbb58036babadae"
                      />
                      <div className="row" data-select2-id="5">
                        <div className="col-md-3">
                          <label for="operator_name">
                            Operator Name:{" "}
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            onChange={handleOperatorChange}
                            type="text"
                            name="operator_name"
                            autofocus=""
                            maxlength="100"
                            required=""
                            className="form-control"
                            id="operator_name"
                            placeholder="Enter Operator Name"
                            fdprocessedid="u1pe4"
                          />

                          <div id="err"></div>
                        </div>

                        <div className="col-md-3" data-select2-id="4">
                          <label for="service_id">
                            Service Type:{" "}
                            <span className="text-danger"> *</span>
                          </label>
                          <select
                            onChange={(e) => setServiceId(e.target.value)}
                            className="form-control select2 "
                            name="service_id"
                            required=""
                            id="service_id"
                            data-select2-id="service_id"
                            tabindex="-1"
                            aria-hidden="true"
                          >
                            <option>Select Service Type</option>
                            {serviceList.map((type) => (
                              type.status ? (
                                <option key={type.serviceType} value={type.serviceType}>
                                  {type.serviceName}
                                </option>
                              ) : null
                            ))}
                          </select>
                        </div>

                      </div>
                      <div
                        className="text-center"
                        style={{ marginTop: "30px" }}
                      >
                        <input
                          type="button"
                          onClick={handleAddOperatorIncode}
                          className="btn btn-primary btn-hover text-white"
                          value="Add Incode"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="card">
                <div className="card-header">

                  <h1 className="m-0 text-dark float-right">

                    {/* <span
                      className="btn btn-sm btn-primary"
                      onClick={handleNewOperatorClick}
                    >
                      <i id="sign" className="fa fa-plus"></i>{" "}
                      <span id="new">ADD API</span>
                    </span>&nbsp; */}

                    <span
                      className="btn btn-sm btn-primary"
                      onClick={handleNewOperatorClick}
                    >
                      <i id="sign" className="fa fa-plus"></i>{" "}
                      <span id="new">NEW OPERATOR</span>
                    </span>

                  </h1>&nbsp;&nbsp;

                  <h1 className="m-0 text-dark float-right">
                    <span
                      className="btn btn-sm btn-primary"
                      onClick={handleAddIncode}
                    >
                      <i id="sign" className="fa fa-plus"></i>{" "}
                      <span id="new">ADD INCODE</span>
                    </span>

                  </h1>
                </div>

                <div className="card-body">
                  <div>
                    <input
                      type="hidden"
                      name="securityToken"
                      value="8197b02cbf23f9f24cf3056800de6362"
                    />

                    <div
                      className="card-header p-0"
                      style={{
                        boxShadow: "0px 0px 5px 4px #0053AF",
                        borderRadius: "4px",
                      }}
                    >
                      <ul
                        className="nav nav-tabs"
                        id="custom-tabs-one-tab"
                        role="tablist"
                        style={{
                          overflowY: "auto",
                          overflowX: "hidden",
                          height: "85px",
                        }}
                      >
                        {serviceList.map((tab) => (
                          <li
                            className="nav-item"
                            style={{ display: "inline-block" }}
                            key={tab.id}
                          >
                            <a
                              className={`nav-link color2 ${activeTab === tab.serviceType ? "active" : ""
                                }`}
                              id={tab.serviceType}
                              data-toggle="pill"
                              href={`#${tab.serviceType}`}
                              role="tab"
                              aria-controls={tab.serviceType}
                              aria-selected="true"
                              onClick={() => handleTabClick(tab.serviceType)}
                            >
                              <div className="text-center">
                                <img
                                  src="./image/electricity.png"
                                  alt={tab.alt}
                                  style={{ width: "45px" }}
                                />
                                <h4
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "18px",
                                  }}
                                >
                                  {tab.serviceName}
                                </h4>
                              </div>
                              {tab.alt}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div
                      className="tab-content mt-3"
                      id="custom-tabs-one-tabContent"
                    >
                      {/* Prepaid */}

                      <div
                        className="tab-pane fade show active"
                        id="tab_1"
                        role="tabpanel"
                        aria-labelledby="ref_1"
                      >
                        <div className="table-responsive">
                          <table
                            id=""
                            className="table m-0 table-bordered table-striped"
                          >
                            <thead>
                              <tr className="text-center">
                                <th>#</th>
                                <th>Operator Name</th>
                                <th>Operator Incode</th>
                                <th>Operator Outcode</th>
                                <th>Operator Provider Name</th>
                                <th>Service Type</th>
                                <th>API Id</th>
                                <th>Status</th>

                              </tr>
                            </thead>
                            <tbody>
                              {operatorData.map((option, index) => (
                                <tr className="text-center">
                                  <td>{index + 1}</td>
                                  <td>{option.operatorName}</td>
                                  <td>{option.operatorIncode}</td>
                                  <td>{option.operatorOutcode}</td>
                                  <td>{option.operatorProviderName}</td>
                                  <td>{option.serviceType}</td>
                                  <td>{option.apiId}</td>
                                  <td className="text-center" onClick={(e) => handleOperatorStatus(e, option.status, option.operatorOutcode, option.id)} style={{ cursor: "pointer" }}>
                                    {option.status
                                      ? <span className="badge badge-success">ACTIVE</span>
                                      : <span className="badge badge-danger">INACTIVE</span>
                                    }
                                  </td>

                                  {/* <td>
                                    <button
                                      className="slab_btn"
                                      // onClick={() => setShowModal(true)}
                                      onClick={(event) => handleModal2(event, option.operatorIncode, option.serviceType)}
                                    >
                                      Add Commission
                                    </button>
                                  </td> */}

                                </tr>
                              ))}
                            </tbody>
                          </table>

                          {showModal && (
                            <div
                              className="modal fade show"
                              tabIndex="-1"
                              role="dialog"
                              aria-labelledby="serviceUpdateTitle"
                              style={{ display: "block" }}
                              aria-modal="true"
                            >
                              <div
                                className="modal-dialog modal-dialog-centered"
                                role="document"
                              >
                                <div
                                  className="modal-content"
                                  style={{ width: "max-content" }}
                                >
                                  <div>
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="exampleModalLongTitle"
                                      >
                                        View Slab
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={handleCloseModal}
                                      >
                                        <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <div className="row">
                                        <input
                                          type="hidden"
                                          name="action_type"
                                          id="action_type"
                                          value="1"
                                        />

                                        <table className="table" cellspacing={10}>
                                          <thead>
                                            <tr>
                                              <th>Slab 1</th>
                                              <th>Slab 2</th>
                                              <th>Commison/Charge</th>
                                              <th>Commison Type</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {viewSlab.length > 0 ? viewSlab.map((item, index) => (
                                              <tr>
                                                <td>
                                                  <input
                                                    type="text"
                                                    value={item.slab1}
                                                    className="form-control clear"
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    style={{ width: "60px" }}
                                                    type="text"
                                                    value={item.slab2}
                                                    className="form-control clear"
                                                  />
                                                </td>

                                                <td>
                                                  <input
                                                    type="text"
                                                    value={`${item.commission}/${item.charge}`}
                                                    className="form-control clear"
                                                  />
                                                </td>

                                                <td>
                                                  <select
                                                    style={{
                                                      width: "110px",
                                                      height: "35px",
                                                      border: "1px solid skyblue",
                                                    }}
                                                    onChange={handleCommissionType}
                                                  >
                                                    <option>Select*</option>
                                                    <option value="FLAT">Flat</option>
                                                    <option value="PERC">Percentage</option>
                                                  </select>
                                                </td>
                                              </tr>
                                            )) :
                                              <tr>
                                                <td colSpan={4}> NO SLAB AVAILABLE </td>
                                              </tr>
                                            }
                                          </tbody>
                                        </table>

                                      </div>
                                    </div>

                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                        fdprocessedid="7wkuky"
                                        onClick={handleCloseModal}
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="w927w4"
                                      // onClick={addService}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="main-footer">
        <strong>
          Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
        </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 4.5.0
        </div>
      </footer>
    </>
  );
}

import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import RestService from "../http";
import swal from 'sweetalert';
import { FaFileExcel } from "react-icons/fa";
import formatDate from "./utilityComponents/FormatDate";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Pagination2 from './utilityComponents/Pagination2';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';


export default function AdminMatmReport() {

    const [selectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();
    const [isSessionExpired, setIsSessionExpired] = useState(false);
    const [matmReport, setMatmReport] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [log] = useState('');
    const [showModal2, setShowModal2] = useState(false);
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());
    const [roles, setRoles] = useState([]);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const sessionExpiretionPopup = () => {
        if (isSessionExpired) {
            swal({
                title: 'Session Expired',
                text: 'Your login session has expired. Please log in again.',
                icon: 'warning',
                closeOnClickOutside: false,
                buttons: {
                    confirm: {
                        text: 'OK',
                        className: 'btn btn-primary',
                    },
                },
                dangerMode: true,
            }).then((confirmed) => {
                if (confirmed) {
                    window.location.href = '/';
                    sessionStorage.removeItem("token");
                    sessionStorage.removeItem("user");
                }
            });
        }
    }
    useEffect(() => {
        sessionExpiretionPopup();
    }, [sessionExpiretionPopup]);


    const [status, setStatus] = useState('');

    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    const downloadExcel = () => {
        // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
        // writeFile(wb, 'recharge_report.xlsx');

        const ws = XLSX.utils.json_to_sheet(matmReport);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'MATM Report');

        XLSX.writeFile(wb, 'matm_report.xlsx');
    };

    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);



    const service = new RestService();


    const handleMatmReport = () => {
        var raw = {
            "fromDate": formatDate(date),
            "toDate": formatDate(date1),
            "status": status
        };

        setLoading(true);

        service.post("report/fetchMicroAtmReport", raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                setMatmReport(result.data);
                setCurrentPage(1);
            } else {
                showToast("DANGER", result.message);
                setMatmReport([]);
            }
        }).catch((err) => {
            console.error(err);
            setMatmReport([]);
        });
    };

    useEffect(() => {
        handleMatmReport();
    }, []);

    useEffect(() => {
        service.get("user/roles").then(result => {
            console.log(result);
            setRoles(result.role);

        }).catch((error) => {
            console.log(error);
        });
    }, []);


    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCloseModal2 = () => {
        setShowModal2(false);
    };


    const [filteredMatmReport, setFilteredMatmReport] = useState([]);
    const [searchInput, setSearchInput] = useState('');


    useEffect(() => {
        const filteredList = matmReport.filter(item =>
            item.userId.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.name.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
            item.mobile.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.cardType.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.cardNo.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.bankName.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.rrn.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.refNo.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.txnAmount.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
            item.balAmount.toString().includes(searchInput.toLowerCase()) ||
            item.txnType.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.remark.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.date.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.time.toLowerCase().includes(searchInput.toLowerCase())
        );

        setFilteredMatmReport(filteredList);
    }, [matmReport, searchInput]);

    const paginatedMatmReport = filteredMatmReport.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalSuccessAmount = matmReport.reduce((total, item) => {
        return item.status === "SUCCESS" ? total + parseFloat(item.txnAmount) : total;
    }, 0).toFixed(2);


    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">MATM Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Recharge</Link>
                                    </li>
                                    <li className="breadcrumb-item active">MATM Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label for="rec_to_load"> Start Date</label><br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label for="rec_to_load"> End Date</label>
                                                <br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    disabledDayAriaLabelPrefix="#$"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date1}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate1(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label>
                                                    Status <span className="text-danger"> *</span>
                                                </label>

                                                <select
                                                    className="custom-date-picker"
                                                    name="customer_type"
                                                    id="customer_type"
                                                    fdprocessedid="g30y5e"
                                                    onChange={(e) => setStatus(e.target.value)}
                                                >
                                                    <option value={""}>SELECT STATUS</option>
                                                    <option value={"SUCCESS"}>SUCCESS</option>
                                                    <option value={"FAILED"}>FAILED</option>
                                                    <option value={"PENDING"}>PENDING</option>
                                                </select>
                                            </div>

                                            <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                                            <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ textAlign: 'center' }}>
                                                <button
                                                    onClick={handleMatmReport}
                                                    className="btn btn-primary btn-block"
                                                    style={{ marginTop: '30px', color: 'white', fontWeight: 600 }}
                                                    fdprocessedid="qnf2"
                                                >
                                                    Search
                                                </button>

                                            </div>
                                            <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ marginTop: '10px', textAlign: 'center' }}>
                                                <button type="button" name="" className="btn btn-success" style={{ marginTop: '19px' }} onClick={downloadExcel} fdprocessedid="9svl7o">
                                                    <FaFileExcel className="fa fa-file-excel-o" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                <center>
                                    <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                        <div>

                                            <div className="col-md-3" style={{ float: "left" }}>
                                                <div>
                                                    <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                    <input
                                                        className="form-control text-box"
                                                        type="text"
                                                        placeholder="SEARCH..."
                                                        value={searchInput}
                                                        onChange={(e) => setSearchInput(e.target.value)}

                                                    />
                                                </div>
                                            </div><br />

                                            <Pagination2
                                                totalPages={Math.ceil(matmReport.length / itemsPerPage)}
                                                currentPage={currentPage}
                                                onChange={handlePageChange}

                                            />
                                        </div>
                                    </div>
                                </center>

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="px-1">SL.NO</th>
                                                <th>User Id</th>
                                                <th>NAME</th>
                                                <th>MOBILE</th>
                                                <th className="px-1">CARD TYPE</th>
                                                <th>CARD NO.</th>
                                                <th>BANK NAME</th>
                                                <th className="px-1">RRN</th>
                                                <th>REF NO.</th>
                                                <th>TXN. AMT.</th>
                                                <th className="px-1">BAL. AMT</th>
                                                <th>TXN. TYPE</th>
                                                <th>STATUS</th>
                                                <th>REMARK</th>
                                                <th>Date</th>
                                                <th>TIME</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!paginatedMatmReport || paginatedMatmReport.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="28">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                paginatedMatmReport.map((item, index) => (
                                                    <tr>
                                                        <td key={index}>{index + 1}</td>
                                                        <td>{item.userId}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{item.cardType}</td>
                                                        <td>{item.cardNo}</td>
                                                        <td>{item.bankName}</td>
                                                        <td>{item.rrn}</td>
                                                        <td>{item.refNo}</td>
                                                        <td>{item.txnAmount}</td>
                                                        <td>{item.balAmount}</td>
                                                        <td>{item.txnType}</td>
                                                        <td style={{ color: item.status === "SUCCESS" ? 'green' : 'red', fontWeight: "bold" }}>{item.status}</td>
                                                        <td>{item.remark}</td>
                                                        <td>{item.date}</td>
                                                        <td>{item.time}</td>
                                                    </tr>

                                                ))
                                            )}
                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <td
                                                    colSpan="4"
                                                    style={{ fontWeight: "bold" }}
                                                    className="text-primary"
                                                    rowSpan="1"
                                                >
                                                    Row Count {matmReport.length}
                                                </td>
                                                <td
                                                    className="text-right font-weight-bold text-success"

                                                >

                                                </td>
                                                <td colSpan="5" className="text-right" style={{ fontWeight: "bold", color: "green" }}>
                                                    Total Success Amount: {totalSuccessAmount}
                                                </td>
                                                <td colSpan="3"></td>
                                            </tr>
                                        </tfoot>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {showModal && (
                <div className="modal fade show" style={{ display: 'block' }} aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLongTitle">Request</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='card'>
                                        <div className='card-body'>
                                            {log}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" fdprocessedid="7wkuky" onClick={handleCloseModal}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showModal2 && (
                <div className="modal fade show" style={{ display: 'block' }} aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLongTitle">Response</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal2}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='card'>
                                        <div className='card-body'>
                                            {log}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" fdprocessedid="7wkuky" onClick={handleCloseModal2}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            <footer className="main-footer" >
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

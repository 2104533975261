import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router';


export default function Walletsummary() {

    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropDownRef = useRef();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };


    const handleToDateChange = (date) => {
        setToDate(date);
    };


    const options = [
        { value: '', label: 'AJOY MONDAL [MD1003][9609236025]' },
        { value: '1', label: 'ARUL DOSS [MD1002][7904634883]' },
        { value: '2', label: 'NAVNATH SASE [RT1001][8600707581]' },
        { value: '3', label: 'Sharad Bandarkar [MA1000][8600436163]' },

    ];

    let memoizedData;

    const fetchData = async () => {
        // If memoizedData is already set, return it immediately
        if (memoizedData) {
            return memoizedData;
        }

        var token = '';
        if (sessionStorage && sessionStorage.token) {
            token = 'Bearer ' + sessionStorage.token;
        } else {
            navigate({
                pathname: "/"
            });
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("user");
            return null; // Return null or some other default value if the token is not available
        }

        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            const response = await fetch("https://uat.softmintdigital.com/softmintadminsoftware/user/getallUser", requestOptions);
            const result = await response.json();
            console.log(result.List);
            setData(result.List);

            // Store the result in memoizedData
            memoizedData = result.List;

            return result.List;
        } catch (error) {
            console.log('error', error);
            return null; // Return null or some other default value if an error occurs
        }
    };


    // Call the fetchData function
    useEffect(() => {
        fetchData();
    }, []);

    const handleOptionSelect = (option) => {
        setSelectedOption(option.mobile);
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "620.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Wallet Summary</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="/admin">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="#">Wallet</a>
                                    </li>
                                    <li className="breadcrumb-item active">Wallet Summary</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <form action="/Walletsummary" method="post">
                                        <div className="row">

                                            <div className="col-md-2">
                                                <label>From Date</label><br />
                                                <DatePicker
                                                    selected={selectedDate}
                                                    onChange={handleDateChange}
                                                    dateFormat="yyyy-MM-dd" //m Specify the desired format
                                                    className="form-control"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-2">
                                                <label>To Date</label><br />
                                                <DatePicker
                                                    selected={toDate}
                                                    onChange={handleToDateChange}
                                                    dateFormat="yyyy-MM-dd"
                                                    className="form-control"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-2">
                                                <label>Txn ID</label>
                                                <input
                                                    type="text"
                                                    name="txn_id"
                                                    defaultValue=""
                                                    className="form-control"
                                                    placeholder="Enter transaction ID"
                                                    fdprocessedid="qr2ew"
                                                />
                                            </div>
                                            <div className="col-md-2" data-select2-id="9">
                                                <label>Type</label>
                                                <select className="form-control select2 " name="transaction_type" data-select2-id="1" tabindex="-1" aria-hidden="true">
                                                    <option value="" data-select2-id="3">SELECT TYPE</option>
                                                    <option value="1" data-select2-id="12">RECHARGE</option>
                                                    <option value="2" data-select2-id="13">COMMISSION</option>
                                                    <option value="3" data-select2-id="14">FUND REQUEST</option>
                                                    <option value="4" data-select2-id="15">TRANSFER</option>
                                                    <option value="5" data-select2-id="16">MANUAL</option>
                                                    <option value="6" data-select2-id="17">SURCHARGE</option>
                                                    <option value="7" data-select2-id="18">REFUND</option>
                                                    <option value="8" data-select2-id="19">CASHBACK</option>
                                                    <option value="9" data-select2-id="20">REFUND COMMISSION</option>
                                                    <option value="10" data-select2-id="21">REFUND SURCHARGE</option>
                                                    <option value="11" data-select2-id="22">DMT</option>
                                                    <option value="12" data-select2-id="23">DMT COMMISSION</option>
                                                    <option value="13" data-select2-id="24">SERVICE ACTIVATION</option>
                                                    <option value="14" data-select2-id="25">DMT REFUND</option>
                                                    <option value="15" data-select2-id="26">DMT REFUND COMMISSION</option>
                                                    <option value="16" data-select2-id="27">ACCOUNT ACTIVATION</option>
                                                    <option value="17" data-select2-id="28">ROFFER</option>
                                                    <option value="20" data-select2-id="29">AEPS BALANCE CHECK</option>
                                                    <option value="21" data-select2-id="30">AEPS COMMISSION</option>
                                                    <option value="22" data-select2-id="31">AEPS SURCHARGE</option>
                                                    <option value="23" data-select2-id="32">CASH WITHDRAWAL</option>
                                                    <option value="24" data-select2-id="33">MINI STATEMENT</option>
                                                    <option value="25" data-select2-id="34">AEPS SETTLEMENT</option>
                                                    <option value="28" data-select2-id="35">AEPS CREDIT</option>
                                                    <option value="30" data-select2-id="36">INTENAL FUND TRANFER</option>
                                                    <option value="31" data-select2-id="37">IFT CHARGES</option>
                                                    <option value="32" data-select2-id="38">DMT SURCHARGE</option>
                                                    <option value="34" data-select2-id="39">PGW CHARGES</option>
                                                </select>
                                                {/* <span className="select2 select2-container select2-container--default select2-container--below" dir="ltr" data-select2-id="2" style={{ width: '144.833px' }}>
                                                    <span className="selection">
                                                        <span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-transaction_type-v2-container">
                                                            <span className="select2-selection__rendered" id="select2-transaction_type-v2-container" role="textbox" aria-readonly="true" title="SELECT TYPE">SELECT TYPE</span>
                                                            <span className="select2-selection__arrow" role="presentation">
                                                                <b role="presentation"></b>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <span className="dropdown-wrapper" aria-hidden="true"></span>
                                                </span> */}
                                            </div>

                                            <div className="col-md-2">
                                                <label>Members</label>
                                                <select className="form-control select2 select2-hidden-accessible" name="customer_id" id="customer_list" tabindex="-1" aria-hidden="true" data-select2-id="customer_list">
                                                    <option value="" data-select2-id="7">SELECT MEMBER</option>
                                                </select>
                                                <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="6" style={{ width: '144.833px' }}>

                                                    <div
                                                        ref={dropDownRef}
                                                        className={`select2 select2-container select2-container--default ${isDropdownOpen ? 'select2-container--open' : ''
                                                            }`}
                                                        dir="ltr"
                                                        data-select2-id="2"
                                                        style={{ width: '180.625px' }}
                                                        onClick={toggleDropdown}
                                                    >
                                                        <span className="selection">
                                                            <span
                                                                className={`select2-selection select2-selection--single ${isDropdownOpen ? 'select2-selection--active' : ''
                                                                    }`}

                                                                role="combobox"
                                                                aria-haspopup="true"
                                                                aria-expanded={isDropdownOpen}
                                                                tabIndex="0"
                                                                aria-disabled="false"
                                                                aria-labelledby="select2-package_id-24-container"
                                                            >
                                                                <span
                                                                    className="select2-selection__rendered"
                                                                    id="select2-package_id-24-container"
                                                                    role="textbox"
                                                                    aria-readonly="true"
                                                                    title={selectedOption ? data.find((option) => option.mobile === selectedOption).mobile : 'Select Member'}
                                                                >
                                                                    {selectedOption ? data.find((option) => option.mobile === selectedOption).mobile : 'Select Member'}
                                                                </span>

                                                                <span className="select2-selection__arrow" role="presentation">
                                                                    <b role="presentation"></b>
                                                                </span>
                                                            </span>
                                                        </span>

                                                        {isDropdownOpen && (
                                                            <span className="dropdown-wrapper" style={{ position: 'absolute' }}>
                                                                <span className="select2-dropdown select2-dropdown--below" dir="ltr" style={{ width: '180.625px' }}>
                                                                    <span className="select2-search select2-search--dropdown">
                                                                        <input
                                                                            className="select2-search__field"
                                                                            type="search"
                                                                            tabIndex="0"
                                                                            autoComplete="off"
                                                                            autoCorrect="off"
                                                                            autoCapitalize="none"
                                                                            spellCheck="false"
                                                                            role="searchbox"
                                                                            aria-autocomplete="list"
                                                                            aria-controls="select2-package_id-ci-results"
                                                                        />
                                                                    </span>
                                                                    <span className="select2-results">
                                                                        <ul className="select2-results__options" role="listbox" id="select2-package_id-ci-results" aria-expanded="true" aria-hidden="false">
                                                                            {data.map((option) => (
                                                                                <li
                                                                                    key={option.mobile}
                                                                                    className={`select2-results__option ${option.value === selectedOption ? 'select2-results__option--highlighted' : ''}`}
                                                                                    role="option"
                                                                                    aria-selected={option.mobile === selectedOption}
                                                                                    data-select2-id={`select2-package_id-f9-result-${option.value}`}
                                                                                    onClick={() => handleOptionSelect(option)}
                                                                                >
                                                                                    {option.name + '[' + option.userName + '][' + option.mobile + ']'}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        )}

                                                    </div>

                                                </span>
                                            </div>

                                            <input
                                                type="hidden"
                                                name="securityToken"
                                                value="42bdbc95cdc725ac7523c42c2612ebba"
                                            />
                                            <div className="col-md-2 pt-3 text-center">
                                                <input
                                                    type="submit"
                                                    value="Search"
                                                    className="btn btn-primary btn-sm mt-3"
                                                    fdprocessedid="1s20jk"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Customer</th>
                                                <th className="text-right">Credit</th>
                                                <th className="text-right">Debit</th>
                                                <th className="text-right">Opening</th>
                                                <th className="text-right">Closing</th>
                                                <th>Txn Type</th>
                                                <th>Txn ID</th>
                                                <th>Date</th>
                                                <th>Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody>{/* Add table rows dynamically */}</tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="2" style={{ fontWeight: 'bold' }} className="text-primary">
                                                    Row Count 0
                                                </td>
                                                <td style={{ fontWeight: 'bold' }} className="text-primary">
                                                    0
                                                </td>
                                                <td style={{ fontWeight: 'bold' }} className="text-primary">
                                                    0
                                                </td>
                                                <td colSpan="6"></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <a href="www.softmintdigital.com">SOFTMINT</a>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

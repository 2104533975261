import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

export default function Utilityapi() {

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '520.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">
                                    Utility API{' '}
                                    <Link to="/editutilityapi" className="btn btn-success btn-sm">
                                        <i className="fa fa-plus"></i> NEW
                                    </Link>
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Settings</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Api</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Utility Api</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Url</th>
                                                <th>Status</th>
                                                <th>Api</th>
                                                <th>Service</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>OPERATOR_FETCH</td>
                                                <td>https://planapi.in/api/Mobile/OperatorFetchNew?ApiUserID=3695&amp;ApiPassword=megamoney&amp;Mobileno=@number</td>
                                                <td className="text-center">
                                                    <span className="badge badge-success">Active</span>
                                                </td>
                                                <td>PLAN API</td>
                                                <td>PREPAID</td>
                                                <td>
                                                    <Link to="/editutilityapi" className="btn btn-primary btn-sm">
                                                        <i className="fa fa-edit"></i>
                                                    </Link>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>ROFFER</td>
                                                <td>https://planapi.in/api/Mobile/RofferCheck?apimember_id=3695&amp;api_password=megamoney&amp;mobile_no=@number&amp;operator_code=@operator</td>
                                                <td className="text-center">
                                                    <span className="badge badge-success">Active</span>
                                                </td>
                                                <td>PLAN API</td>
                                                <td>PREPAID</td>
                                                <td>
                                                    <Link to="/editutilityapi" className="btn btn-primary btn-sm">
                                                        <i className="fa fa-edit"></i>
                                                    </Link>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>BROWSE_PLAN</td>
                                                <td>https://planapi.in/api/Mobile/Operatorplan?apimember_id=3695&amp;api_password=megamoney&amp;cricle=@circle&amp;operatorcode=@operator</td>
                                                <td className="text-center">
                                                    <span className="badge badge-success">Active</span>
                                                </td>
                                                <td>PLAN API</td>
                                                <td>PREPAID</td>
                                                <td>
                                                    <Link to="/editutilityapi" className="btn btn-primary btn-sm">
                                                        <i className="fa fa-edit"></i>
                                                    </Link>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>BILLFETCH</td>
                                                <td>https://www.mplan.in/api/electricinfo.php?apikey=207faf7650dd8842e564ad405e17e015&amp;offer=roffer&amp;tel=@number&amp;operator=@operator</td>
                                                <td className="text-center">
                                                    <span className="badge badge-success">Active</span>
                                                </td>
                                                <td>PLAN API</td>
                                                <td>ELECTRICITY</td>
                                                <td>
                                                    <Link to="/editutilityapi" className="btn btn-primary btn-sm">
                                                        <i className="fa fa-edit"></i>
                                                    </Link>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>BILLFETCH</td>
                                                <td>https://planapi.in/api/Mobile/DTHINFOCheck?apimember_id=3695&amp;api_password=megamoney&amp;Opcode=@operator&amp;mobile_no=@number</td>
                                                <td className="text-center">
                                                    <span className="badge badge-success">Active</span>
                                                </td>
                                                <td>PLAN API</td>
                                                <td>DTH</td>
                                                <td>
                                                    <Link to="/editutilityapi" className="btn btn-primary btn-sm">
                                                        <i className="fa fa-edit"></i>
                                                    </Link>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

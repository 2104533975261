import React from 'react';
import Navbar from './Navbar';
import RestService from '../http';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalFooter,
} from "mdb-react-ui-kit";
import { Link } from 'react-router-dom';


export default function EmployeeUserMap() {

    const service = new RestService();
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [roles, setRoles] = useState([]);
    const [userList, setUserList] = useState([]);
    const [userId, setUserId] = useState("");
    const [designationList, setDesignationList] = useState([]);
    const [designation, setDesignation] = useState('');
    const [ViewEmpList, setViewEmpList] = useState([]);
    const [viewEmp, setViewEmp] = useState('');
    const [basicModal, setBasicModal] = useState(false);
    const [confirmation, setConfirmation] = useState('');

     const options = [
        { value: '', label: 'Select an option' },
        ...userList.map((item) => ({
            value: item.userId,
            label: `${item.name} || ${item.mobile}`,
        }))
    ];

    const handleSelectChange = (selectedUserId) => {
        setSelectedUserId(selectedUserId);
        setUserId(selectedUserId.value);
    };

    const handleCustomerType = (e) => {
        console.log(e.target.value);
        service
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setUserList(result.data);
                } else {
                    setUserList([]);
                }
            })
            .catch((err) => {
                console.error(err);
                setUserList([]);
            });
    };

    useEffect(() => {
        service.get("user/roles").then(result => {
            console.log(result);
            setRoles(result.role);

        }).catch((error) => {
            console.log(error);
        });
    }, []);


    useEffect(() => {
        service.post("emp/viewDesignation", null).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                setDesignationList(result.data)
            } else {
                setDesignationList([]);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const handleDesignation = (e) => {
        setDesignation(e.target.value);

        service.post(`emp/viewEmp/${e.target.value}`, null).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setViewEmpList(result.data);

            } else {
                setViewEmpList([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    };

    const handleEmpUserMap = () => {

        var raw = {
            "userId": userId,
            "empId": viewEmp,
            "confirmation": confirmation
        }

        service.post("emp/userMap", raw).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);

            } else {
                showToast("DANGER", result.message);
            }
        }).catch((error) => {
            console.log(error);
        })

    };

    const handleCloseModal = () => {
        setBasicModal(false);
        setConfirmation(0);
    }

    const handleModal = () => {

        if (userId === undefined || userId === "" || userId === null) {
            showToast('WARN', 'PLEASE SELECT USER ID FIRST!!');
            return false;
        }

        if (viewEmp === undefined || viewEmp === "" || viewEmp === null) {
            showToast('WARN', 'PLEASE SELECT EMPLOYEE!!');
            return false;
        }

        setBasicModal(true);
        setConfirmation(1);
    };

    return (
        <>
            <Navbar />
            <ToastContainer />

            <div className="content-wrapper" style={{ minHeight: '532.667px' }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">
                                    Employee User Map &nbsp;
                                    {/* <span>
                                        <a
                                            type="button"
                                            href="/empregist"
                                            className="btn btn-sm btn-primary"
                                        >
                                            <i className="fa fa-plus"></i> NEW
                                        </a>
                                    </span> */}
                                </h1>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Employee User map</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Employee</li>
                                </ol>
                            </div>

                        </div>

                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div
                                        id="example1_wrapper"
                                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                                    >

                                        <div className='row'>
                                            <div className="col-md-3">
                                                <label for="assign_commission">
                                                    User Type: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleCustomerType}
                                                >
                                                    <option>Select User Type</option>
                                                    {roles.map((item) => (
                                                        item.roleId !== 0 ? <option value={item.roleId}>{item.roleName}</option> : null
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="service_id">
                                                    User Id: <span className="text-danger"> *</span>
                                                </label>
                                                {/* <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setUserId(e.target.value)}
                                                >
                                                    <option value="">Select Id...</option>
                                                    {userList.map((item) => (
                                                        <option value={item.userId}>{item.name}</option>
                                                    ))}
                                                </select> */}

                                                <Select
                                                    className="select-box width100p"
                                                    options={options}
                                                    isSearchable
                                                    value={selectedUserId}
                                                    onChange={handleSelectChange}
                                                />
                                            </div>

                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="service_id">
                                                    Employee Role: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleDesignation}
                                                >
                                                    <option value="">Select...</option>
                                                    {designationList.map((item) => (
                                                        <option value={item.id}>{item.designation}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3">
                                                <label for="service_id">
                                                    View Employee: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setViewEmp(e.target.value)}
                                                >
                                                    <option value="">Select...</option>
                                                    {ViewEmpList.map((item) => (
                                                        <option value={item.empId}>{item.empName}</option>
                                                    ))}
                                                </select>
                                            </div>

                                        </div>

                                        <div className='row mt-5'>
                                            <div className='col-md-12 text-center'>
                                                <button className='btn btn-primary px-4' onClick={handleModal}>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
                <MDBModalDialog>
                    <MDBModalContent style={{ marginLeft: "-100%", marginTop: "30%"}}>
                        <MDBBtn
                            className="btn-close mt-3"
                            color="none"
                            onClick={handleCloseModal}
                        >
                        </MDBBtn>
                        <MDBModalHeader>
                            <MDBModalTitle>ARE YOU SURE YOU WANT TO MAP WITH THIS EMPLOYEE ?</MDBModalTitle>

                        </MDBModalHeader>

                        <MDBModalFooter>
                            <button className='btn bg-red' color="secondary" onClick={handleCloseModal}>
                                Close
                            </button>
                            <button className='btn bg-blue' onClick={handleEmpUserMap}>Proceed</button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

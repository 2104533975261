import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';


export default function Addsystemuser() {

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '532.667px' }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add System User</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">HR Management</Link>
                                    </li>
                                    <li className="breadcrumb-item">System Users</li>
                                    <li className="breadcrumb-item active">Add System Users</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <form action="/systemuser" method="post">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Select Employee</label>
                                                <span className="text-danger"> *</span>
                                                <select name="employee_id" className="form-control" required="" fdprocessedid="jny59">
                                                    <option value="">Select Employee</option>
                                                    <option value="1">Sharad</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>User Name</label>
                                                <span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    name="username"
                                                    value=""
                                                    className="form-control"
                                                    placeholder="Enter Username"
                                                    required=""
                                                    fdprocessedid="0pasqc"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Password</label>
                                                <span className="text-danger"> *</span>
                                                <input
                                                    type="password"
                                                    pattern="^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$"
                                                    name="password"
                                                    minLength="8"
                                                    className="form-control"
                                                    placeholder="Enter Password"
                                                    required=""
                                                    fdprocessedid="fr5fmh"
                                                />
                                            </div>
                                        </div>
                                        <input type="hidden" name="securityToken" value="d88abbe18fac915880c3858e75abdf7c" />
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center" style={{ marginTop: '10px' }}>
                                                <input type="submit" name="" className="btn btn-primary" fdprocessedid="nxjum" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

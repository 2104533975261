import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import RestService from "../http";
import { FaFileExcel } from "react-icons/fa";
import formatDate from "./utilityComponents/FormatDate";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Pagination2 from './utilityComponents/Pagination2';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';
import * as XLSX from 'xlsx';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import showToast from './utilityComponents/APPToast';
import { Link } from 'react-router-dom';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


export default function AdminNsdlReport() {

    const service = new RestService();
    const selectedOption = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();
    const [nsdlReport, setNsdlReport] = useState([]);
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [orderId, setOrderId] = useState('');
    const [status, setStatus] = useState('');
    const [reportStatus, setReportStatus] = useState('');
    const [ackNo, setAckNo] = useState('');
    const [modalHeading, setModalHeading] = useState('');

    const handleOpen = (status, orderId) => {
        setReportStatus(status);
        setOpen(true);
        setOrderId(orderId);

        if (status) {
            setModalHeading('UPDATE OPERATION');
        } else {
            setModalHeading('FAILED OPERATION');
        }

    }

    const handleClose = () => setOpen(false);


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);



    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);



    const handleNsdlReport = () => {
        var raw = {
            "fromDate": formatDate(date),
            "toDate": formatDate(date1),
            "status": status
        };

        setLoading(true);

        service.post("report/fetchNsdlPanReport", raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                setNsdlReport(result.data);
            } else {
                showToast("DANGER", result.message);
                setNsdlReport([]);
            }

        }).catch((err) => {
            setLoading(false);
            console.error(err);
        });
    };

    useEffect(() => {
        handleNsdlReport();
    }, []);


    const [filteredNsdlReport, setFilteredNsdlReport] = useState([]);
    const [searchInput, setSearchInput] = useState('');


    useEffect(() => {
        const filteredList = nsdlReport.filter(item =>
        (item.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.userid?.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.mobile?.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.ackNo?.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.agentCode?.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.orderId?.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.type?.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.status?.toLowerCase().includes(searchInput.toLowerCase()) ||
            (item.date && item.date.toString().includes(searchInput.toLowerCase())))
        );

        setFilteredNsdlReport(filteredList);
    }, [nsdlReport, searchInput]);

    const paginatedNsdlReport = filteredNsdlReport.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    const handleUpdateOperation = () => {
        var raw = {
            "orderId": orderId,
            "ackNo": ackNo,
            "status": reportStatus
        }
         setLoading(true);

        service.post("nsdl/updatePanApplication", raw).then(result => {
            setLoading(false);
            console.log(result);
            if(result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                handleNsdlReport();
                setOpen(false);

            } else {
                showToast("DANGER", result.message);
            }
        }).catch((error) => {
            console.log(error);
        })
    }


    const downloadExcel = () => {
        // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
        // writeFile(wb, 'recharge_report.xlsx');

        const ws = XLSX.utils.json_to_sheet(nsdlReport);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'NSDL Report');

        XLSX.writeFile(wb, 'nsdl_report.xlsx');
    };

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">NSDL PAN Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Report</Link>
                                    </li>
                                    <li className="breadcrumb-item active">NSDL Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label for="rec_to_load"> Start Date</label><br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label for="rec_to_load"> End Date</label>
                                                <br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    disabledDayAriaLabelPrefix="#$"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date1}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate1(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label>
                                                    Status <span className="text-danger"> *</span>
                                                </label>

                                                <select
                                                    className="custom-date-picker"
                                                    name="customer_type"
                                                    id="customer_type"
                                                    fdprocessedid="g30y5e"
                                                    onChange={(e) => setStatus(e.target.value)}
                                                >
                                                    <option value={""}>SELECT STATUS</option>
                                                    <option value={"SUCCESS"}>SUCCESS</option>
                                                    <option value={"FAILED"}>FAILED</option>
                                                    <option value={"PENDING"}>PENDING</option>
                                                </select>
                                            </div>

                                            <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                                            <div className="col-md-3" style={{ textAlign: 'center' }}>
                                                <button
                                                    onClick={handleNsdlReport}
                                                    className="btn btn-primary btn-block"
                                                    style={{ marginTop: '32px', color: 'white', fontWeight: 600 }}
                                                    fdprocessedid="qnf2"
                                                >
                                                    Search
                                                </button>
                                                &nbsp;&nbsp;

                                                <button type="button" name="" className="btn btn-success" style={{ marginTop: "12%" }} onClick={downloadExcel} fdprocessedid="9svl7o">
                                                    <FaFileExcel className="fa fa-file-excel-o" />
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                <center>
                                    <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                        <div>

                                            <div className="col-md-3" style={{ float: "left" }}>
                                                <div>
                                                    <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                    <input
                                                        className="form-control text-box"
                                                        type="text"
                                                        placeholder="SEARCH..."
                                                        value={searchInput}
                                                        onChange={(e) => setSearchInput(e.target.value)}

                                                    />
                                                </div>
                                            </div><br />

                                            <Pagination2
                                                totalPages={Math.ceil(nsdlReport.length / itemsPerPage)}
                                                currentPage={currentPage}
                                                onChange={handlePageChange}

                                            />
                                        </div>
                                    </div>
                                </center>

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr className="wrap-text text-center">
                                                <th className="px-1">SL.NO</th>
                                                <th>Name</th>
                                                <th>User Id</th>
                                                <th>Mobile</th>
                                                <th>Ack. No</th>
                                                <th>Agent Code</th>
                                                <th>Order Id</th>
                                                <th>Type</th>
                                                <th>Source</th>
                                                <th>Op Bal</th>
                                                <th className="px-1">Charge</th>
                                                <th>Cl Bal</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th className="px-1">Remark</th>
                                                <th className="px-1">Status</th>
                                                <th className="px-1">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!paginatedNsdlReport || paginatedNsdlReport.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="17">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                paginatedNsdlReport.map((item, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.userid}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{item.ackNo}</td>
                                                        <td>{item.agentCode}</td>
                                                        <td>{item.orderId}</td>
                                                        <td>{item.type}</td>
                                                        <td>{item.source}</td>
                                                        <td>{item.opbal}</td>
                                                        <td>{item.charge}</td>
                                                        <td>{item.clbal}</td>
                                                        <td>{item.date}</td>
                                                        <td>{item.time}</td>
                                                        <td>{item.errorMsg}</td>
                                                        <td style={{
                                                            color:
                                                                item.status === "SUCCESS" ? 'green' :
                                                                    item.status === "FAILED" ? 'red' :
                                                                        item.status === "INCOMPLETE" ? 'orange' : 'black',
                                                            fontWeight: "bold"
                                                        }}>
                                                            {item.status}
                                                        </td>

                                                        {item.status === "INCOMPLETE" ?
                                                            <td>
                                                                <button className='btn bg-success' onClick={() => handleOpen(true, item.orderId)}>
                                                                    Success
                                                                </button>&nbsp;&nbsp;
                                                                <button className='btn bg-danger' onClick={() => handleOpen(false, item.orderId)}>
                                                                    Failed
                                                                </button>
                                                            </td> : <td></td>
                                                        }
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>

                                        <tfoot>
                                            {paginatedNsdlReport !== undefined && paginatedNsdlReport.length > 0 && (
                                                <tr>
                                                    <td colSpan="5" style={{ color: "brown", fontWeight: "bold" }}>Total Amount:
                                                        {paginatedNsdlReport.reduce((total, item) => total + item.charge, 0)}
                                                    </td>
                                                    <td colSpan="1"></td>

                                                    <td colSpan="1" style={{ color: "green", fontWeight: "bold" }}>Success Status:
                                                        {paginatedNsdlReport.filter(item => item.status === "SUCCESS").length}
                                                    </td>
                                                    <td colSpan="1" style={{ color: "red", fontWeight: "bold" }}>Failed Status:
                                                        {paginatedNsdlReport.filter(item => item.status === "FAILED").length}
                                                    </td>
                                                    <td colSpan="1" style={{ color: "orange", fontWeight: "bold" }}>Pending Status:
                                                        {paginatedNsdlReport.filter(item => item.status === "INCOMPLETE").length}
                                                    </td>

                                                    <td colSpan="1"></td>
                                                </tr>
                                            )}
                                        </tfoot>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2"
                        style={{ background: "#4e6d4e", color: "white", fontWeight: "bold", padding: "5px", justifyContent: "center", display: "flex" }}
                    >
                        {modalHeading}
                    </Typography>

                    <div className="row mt-2">
                        <div className="col-12" style={{ marginTop: '10px' }}>
                            <label>Order Id</label>
                            <span className="text-danger"> *</span>
                            <input
                                type="text"
                                className="form-control"
                                required
                                fdprocessedid="j6qsdd"
                                value={orderId}
                            />
                        </div>

                        <div className="col-12" style={{ marginTop: '10px' }}>
                            <label>Acknowledgement No.</label>
                            <span className="text-danger"> *</span>
                            <input
                                type="text"
                                className="form-control"
                                required
                                fdprocessedid="j6qsdd"
                                placeholder='Enter Ack No.'
                                onChange={(e) => setAckNo(e.target.value)}
                            />
                        </div>

                        <div className='col-md-12 mt-4 text-center'>
                            <button className='btn bg-primary' onClick={handleUpdateOperation}>
                                SUBMIT
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>


            <footer className="main-footer" >
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React, { useRef } from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBContainer, MDBTable, MDBTableBody
} from "mdb-react-ui-kit";


const BbpsRechargeSlip = ({ receiptdata, onClose }) => {

    const receiptRef = useRef();

    const handlePrint = () => {
        const printContents = receiptRef.current.innerHTML;
        const originalContents = document.body.innerHTML;

        const printWindow = window.open('', '', 'height=800,width=900');
        printWindow.document.write('<html><head><title>Print</title>');
        // Optionally include CSS
        printWindow.document.write('<style>/* Add your CSS here */</style>');
        printWindow.document.write('</head><body>');
        printWindow.document.write(printContents);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();

        // Restore the original contents
        document.body.innerHTML = originalContents;
        window.location.reload(); 
    };


    return (
        <>
            <MDBContainer style={{ width: "25%", height: "100vh", marginTop: "30%", background: "cover" }}>
                <MDBCard>
                    <MDBCardBody className="mx-4" style={{ flex: "1" }}>
                        <MDBContainer ref={receiptRef}>
                            {/* Bank Logo */}
                            <div className="logo-container" style={{ background: "lightblue", backgroundSize: 'cover', width: '130%', height: '150px', marginLeft: "-15%", marginTop: "-4%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src="./image/softmintlogo.png" alt="Bbps Logo" style={{ width: '160px', display: "flex", justifyContent: "center" }} />
                            </div>

                            <div style={{ display: 'inline-block', position: 'relative', display: "flex", justifyContent: "center" }}>
                                <div className="dashed-line" style={{ position: 'absolute', width: "23vw", top: '60%', left: '-41px', transform: 'translateY(-50%)', borderTop: '2px dashed', color: 'skyblue', zIndex: '0' }}></div>

                                <button className="btn mt-3" style={{ borderRadius: "15px", position: 'relative', zIndex: '1', justifyContent: "center", display: "flex", color: "white", backgroundColor: "darkcyan" }}>
                                    {receiptdata.serviceName}
                                </button>
                            </div><br />

                            <p className="text-center" style={{ fontWeight: "bold" }}>{receiptdata.type}</p>

                            <MDBTable style={{ tableLayout: 'fixed', width: '100%', overflowY: "scroll" }}>
                                <MDBTableBody>
                                    <tr>
                                        <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Customer Name</td>
                                        <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: "10px" }}>{receiptdata.customerName}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Customer Number</td>
                                        <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{receiptdata.customerMobile}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Payment Date</td>
                                        <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{receiptdata.date}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Bill Amount</td>
                                        <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{receiptdata.amount}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Status</td>
                                        <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', color: receiptdata.status === 'SUCCESS' ? 'green' : 'red' }}>{receiptdata.status}</td>
                                    </tr>
                                </MDBTableBody>
                            </MDBTable>

                            <div style={{ float: "right" }}>
                                <button className="btn bg-blue" onClick={onClose}>Back</button>&nbsp;
                                <button className="btn bg-red" onClick={handlePrint}>Print</button>
                            </div>
                        </MDBContainer>
                    </MDBCardBody>
                </MDBCard>
            </MDBContainer>

        </>
    );

};

export default BbpsRechargeSlip;

import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

export default function Custcare() {

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '532.667px' }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">Customer Care Numbers</h1>
                            </div>
                            
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Support</Link></li>
                                    <li className="breadcrumb-item active">Customer Care Numbers</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card col-md-6 m-auto">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table m-0 table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Operator</th>
                                                <th>Customer Care Number</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Airtel</td>
                                                <td>198</td>
                                                <td><Link to="tel:198"><i className="fa fa-phone"></i></Link></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Dish TV</td>
                                                <td>18001021555</td>
                                                <td><Link to="tel:18001021555"><i className="fa fa-phone"></i></Link></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Rechargeset() {

    const [mobRechargeTime, setMobRechargeTime] = useState(300);
    const [rechargeWalletLock, setRechargeWalletLock] = useState('0.00');
    const [fundRequestLock, setFundRequestLock] = useState('0.00');
    const [companyInitial, setCompanyInitial] = useState('SOFTMINT');
    const [rechargeService, setRechargeService] = useState('1');
    const [apiAutoSwitch, setApiAutoSwitch] = useState('1');
    const [operatorCheck, setOperatorCheck] = useState('0');
    const [operatorCheckApi, setOperatorCheckApi] = useState('2');
    const [selfRegistrationEmailVerification, setSelfRegistrationEmailVerification] = useState('1');
    const [registrationByDtEmailVerification, setRegistrationByDtEmailVerification] = useState('1');
    const [saveRequestResponse, setSaveRequestResponse] = useState('0');
    const [websiteLoginOtp, setWebsiteLoginOtp] = useState('0');

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission here
    };

    const handleRechargeServiceChange = (e) => {
        setRechargeService(e.target.value);
    };

    const handleApiAutoSwitchChange = (e) => {
        setApiAutoSwitch(e.target.value);
    };

    const ToggleOption = ({ label, name, value, checked }) => {
        return (
            <div className="icheck-primary d-inline">
                <input type="radio" id={`${name}${value}`} name={name} value={value} checked={checked} />
                <label htmlFor={`${name}${value}`}>{label}</label>
            </div>

        );
    };

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '232.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">Recharge Setting</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Settings</Link></li>
                                    <li className="breadcrumb-item active">Recharge Setting</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 shadow p-4 mb-4 bg-white">
                                                <label>Mobile Recharge Time(In Seconds)</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    name="mob_recharge_time_seconds"
                                                    value={mobRechargeTime}
                                                    onChange={(e) => setMobRechargeTime(e.target.value)}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 shadow p-4 mb-4 bg-white">
                                                <label>Recharge Wallet Lock</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    name="recharge_wallet_lock"
                                                    value={rechargeWalletLock}
                                                    onChange={(e) => setRechargeWalletLock(e.target.value)}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 shadow p-4 mb-4 bg-white">
                                                <label>Fund Request Lock</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    name="fund_request_lock"
                                                    value={fundRequestLock}
                                                    onChange={(e) => setFundRequestLock(e.target.value)}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 shadow p-4 mb-4 bg-white">
                                                <label>Company Initial</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    name="company_initial"
                                                    value={companyInitial}
                                                    onChange={(e) => setCompanyInitial(e.target.value)}
                                                    maxLength="3"
                                                    className="form-control"
                                                    required
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 shadow p-4 mb-4 bg-white">
                                                <label>Recharge Service</label>
                                                <div className="form-group clearfix">
                                                    <div className="icheck-primary d-inline">
                                                        <input
                                                            type="radio"
                                                            id="radioPrimary3"
                                                            name="recharge_service"
                                                            value="1"
                                                            checked={rechargeService === '1'}
                                                            onChange={handleRechargeServiceChange}
                                                        />
                                                        <label htmlFor="radioPrimary3">Active</label>
                                                    </div>&nbsp;
                                                    <div className="icheck-danger d-inline">
                                                        <input
                                                            type="radio"
                                                            id="radioPrimary4"
                                                            name="recharge_service"
                                                            value="0"
                                                            checked={rechargeService === '0'}
                                                            onChange={handleRechargeServiceChange}
                                                        />
                                                        <label htmlFor="radioPrimary4">Deactive</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-4 shadow p-4 mb-4 bg-white">
                                                <label>API Auto Switch</label>
                                                <div className="form-group clearfix">
                                                    <div className="icheck-primary d-inline">
                                                        <input
                                                            type="radio"
                                                            id="radioPrimary1"
                                                            name="api_auto_switch"
                                                            value="1"
                                                            checked={apiAutoSwitch === '1'}
                                                            onChange={handleApiAutoSwitchChange}
                                                        />
                                                        <label htmlFor="radioPrimary1">Active</label>
                                                    </div>&nbsp;
                                                    <div className="icheck-danger d-inline">
                                                        <input
                                                            type="radio"
                                                            id="radioPrimary2"
                                                            name="api_auto_switch"
                                                            value="0"
                                                            checked={apiAutoSwitch === '0'}
                                                            onChange={handleApiAutoSwitchChange}
                                                        />
                                                        <label htmlFor="radioPrimary2">Deactive</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">

                                            <div className="col-lg-4 col-md-4 shadow p-4 mb-4 bg-white">
                                                <label>Operator Check</label>
                                                <div className="form-group clearfix">
                                                    <div className="icheck-primary d-inline">
                                                        <input type="radio" id="radioPrimary7" name="operator_check" value="1" />
                                                        <label htmlFor="radioPrimary7">ON</label>
                                                    </div>&nbsp;
                                                    <div className="icheck-danger d-inline">
                                                        <input type="radio" id="radioPrimary8" name="operator_check" value="0" defaultChecked />
                                                        <label htmlFor="radioPrimary8">OFF</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 shadow p-4 mb-4 bg-white">
                                                <label>Operator Check API:</label>
                                                <select className="form-control" name="operator_check_api" required="" fdprocessedid="it4vqd">
                                                    <option value="">SELECT</option>
                                                    <option value="2" selected>PLAN API</option>
                                                </select>
                                            </div>

                                            <div className="col-lg-4 col-md-4 shadow p-4 mb-4 bg-white">
                                                <label>Self Registration Email Verification</label>
                                                <div className="form-group clearfix">
                                                    <div className="icheck-primary d-inline">
                                                        <input type="radio" id="email_verification1" name="email_verification" value="1" defaultChecked />
                                                        <label htmlFor="email_verification1">ON</label>
                                                    </div>&nbsp;
                                                    <div className="icheck-danger d-inline">
                                                        <input type="radio" id="email_verificatio2" name="email_verification" value="0" />
                                                        <label htmlFor="email_verificatio2">OFF</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-4 shadow p-4 mb-4 bg-white">
                                                <label>Registration by DT Email Verification</label>
                                                <div className="form-group clearfix">
                                                    <div className="icheck-primary d-inline">
                                                        <input type="radio" id="dt_email_verification1" name="dt_email_verification" value="1" defaultChecked />
                                                        <label htmlFor="dt_email_verification1">ON</label>&nbsp;
                                                    </div>
                                                    <div className="icheck-danger d-inline">
                                                        <input type="radio" id="dt_email_verification2" name="dt_email_verification" value="0" />
                                                        <label htmlFor="dt_email_verification2">OFF </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-4 shadow p-4 mb-4 bg-white">
                                                <label>Save Request Response</label>
                                                <div className="form-group clearfix">
                                                    <div className="icheck-primary d-inline">
                                                        <input type="radio" id="save_request1" name="save_request" value="1" />
                                                        <label htmlFor="save_request1">ON</label>&nbsp;
                                                    </div>
                                                    <div className="icheck-danger d-inline">
                                                        <input type="radio" id="save_request2" name="save_request" value="0" defaultChecked />
                                                        <label htmlFor="save_request2">OFF </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-4 shadow p-4 mb-4 bg-white">
                                                <label>Website Login OTP</label>
                                                <div className="form-group clearfix">
                                                    <div className="icheck-primary d-inline">
                                                        <input type="radio" id="web_login_otp1" name="web_login_otp" value="1" />
                                                        <label htmlFor="web_login_otp1">ON</label>&nbsp;
                                                    </div>
                                                    <div className="icheck-danger d-inline">
                                                        <input type="radio" id="web_login_otp2" name="web_login_otp" value="0" defaultChecked />
                                                        <label htmlFor="web_login_otp2">OFF </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12" style={{ textAlign: 'center' }}>
                                                <input type="submit" className="btn btn-primary" value="SAVE" fdprocessedid="urdq45" />
                                            </div>

                                        </div>
                                    </form>
                                </div>

                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 shadow p-4 mb-4">
                                    <h5 className="text-primary">Low Balance Alert Amount : </h5>
                                    <form action="#" method="post" acceptCharset="utf-8">
                                        <input type="hidden" name="securityToken" value="bed1260933bb1ce5f2853ff164b25d88" />
                                        <div className="row mt-4">
                                            <div className="col-md-2">
                                                <label>MASTER<br/> DISTRIBUTOR</label>
                                                <input type="text" name="amount[3]" value="100.00" className="form-control" fdprocessedid="a9pcvy" />
                                            </div>
                                            <div className="col-md-2">
                                                <label>DISTRIBUTOR</label>
                                                <input type="text" name="amount[4]" value="0.00" className="form-control" fdprocessedid="gq19gv" />
                                            </div>
                                            <div className="col-md-2">
                                                <label>RETAILER</label>
                                                <input type="text" name="amount[5]" value="0.00" className="form-control" fdprocessedid="yuiapu" />
                                            </div>
                                            <div className="col-md-2">
                                                <label>CUSTOMER</label>
                                                <input type="text" name="amount[6]" value="0.00" className="form-control" fdprocessedid="fmx5hm" />
                                            </div>
                                            <div className="col-md-2">
                                                <label>API USER</label>
                                                <input type="text" name="amount[7]" value="0.00" className="form-control" fdprocessedid="xry7pb" />
                                            </div>
                                            <div className="col-md-2" style={{ marginTop: '32px' }}>
                                                <button className="btn btn-primary" fdprocessedid="edqatc">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 shadow p-4 mb-4 bg-white">
                                    <h5 className="text-primary">Set Fund Request Lock for Existing User : </h5>
                                    <form action="#" method="post" acceptCharset="utf-8">
                                        <input type="hidden" name="securityToken" value="bed1260933bb1ce5f2853ff164b25d88" />
                                        <div className="row mt-4">
                                            <div className="col-md-3">
                                                <label>Customer Type</label>
                                                <select className="form-control" name="customer_type" id="customer_type" required fdprocessedid="eq1s7t">
                                                    <option value="">SELECT CUSTOMER TYPE</option>
                                                    <option value="1">MASTER ADMIN</option>
                                                    <option value="3">MASTER DISTRIBUTOR</option>
                                                    <option value="4">DISTRIBUTOR</option>
                                                    <option value="5">RETAILER</option>
                                                    <option value="6">CUSTOMER</option>
                                                    <option value="7">API USER</option>
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Amount</label>
                                                <input type="text" name="amount" className="form-control" value="10" placeholder="Enter amount" required fdprocessedid="qlkocd" />
                                            </div>
                                            <div className="col-md-2" style={{ marginTop: '32px' }}>
                                                <button className="btn btn-primary" fdprocessedid="q348o">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div >
                </section >
            </div >

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react'
import { FaEdit, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useState, useEffect, useRef } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import swal from 'sweetalert';
import RestService from '../http';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';


export default function ChargeSetup() {

    const navigate = useNavigate();
    const rest = new RestService();
    const [selectedAction, setSelectedAction] = useState(null);
    const [data, setData] = useState([]);
    const [isInputOpen, setIsInputOpen] = useState(false);
    const [charge, setCharge] = useState(0.0);


    const handleChargeSetup = () => {

        rest.post("commission/fetchAllCharge", null).then(response => {
            console.log(response);
            if (response.status === rest.SUCCESS) {
                showToast("SUCCESS", response.message);
                setData(response.data)

            } else {
                showToast("DANGER", response.message);
                setData([]);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        handleChargeSetup()
    }, []);

    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);


    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropDownRef = useRef();
    const [editedRows, setEditedRows] = useState({});


    const handleOptionSelect = (option) => {
        setSelectedOption(option.userId);
        setIsDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    const handleDoubleClick = (operatorCode, currentCharge) => {
        setEditedRows((prevRows) => ({
            ...Object.keys(prevRows).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            [operatorCode]: true,
        }));
        setCharge(currentCharge);
    };


    const handleUpdateCharge = (e, operatorCode) => {

        const updatedData = data.map((option) =>
            option.operatorCode === operatorCode
                ? { ...option, charge: charge }
                : option
        );

        const updatedCharge = updatedData.find((option) => option.operatorCode === operatorCode).charge;

        var raw = {
            "operatorCode": operatorCode,
            "charge": charge
        }

        console.log(raw);

        rest.post("commission/updateCharge", raw).then(result => {
            console.log(result);
            if (result.status === rest.SUCCESS) {
                showToast("SUCCESS", result.message);

            } else {
                showToast("DANGER", result.message);
            }
        }).catch((error) => {
            console.log(error);
        })

        setData(updatedData);
        setEditedRows((prevRows) => ({ ...prevRows, [operatorCode]: false }));
    }


    return (
        <>
            <Navbar />
            <ToastContainer />

            <div className="content-wrapper" style={{ minHeight: '560.667px' }}>
                {/* Content Header (Page header)  */}

                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Charge Setup <span>
                                    <Link to="/addcharge" className="btn btn-sm btn-primary">
                                        <i className="fa fa-plus"></i> NEW</Link></span>&nbsp;
                                </h1>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Commission Charge</Link></li>
                                    <li className="breadcrumb-item active">Charge setup</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr className='text-center bg-dark'>
                                                <th className='text-white'>Id</th>
                                                <th className='text-white'>Operator Code</th>
                                                <th className='text-white'>Operator Name</th>
                                                <th className='text-white'>Charge</th>
                                                <th className='text-white'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data !== undefined ? 
                                            data.map((option, index) => (
                                                <tr className='text-center'>
                                                    <td>{index + 1}</td>
                                                    <td>{option.operatorName}</td>
                                                    <td>{option.operatorCode}</td>
                                                    {editedRows[option.operatorCode] ? (
                                                        <td>
                                                            <div>
                                                                <input
                                                                    name="charge"
                                                                    type="text"
                                                                    maxLength="6"
                                                                    value={charge}
                                                                    onChange={(e) => setCharge(e.target.value)}
                                                                    placeholder="Enter Charge"
                                                                />
                                                            </div>
                                                        </td>
                                                    ) : (
                                                        <td onDoubleClick={() => handleDoubleClick(option.operatorCode, option.charge)}>
                                                            {option.charge}
                                                        </td>
                                                    )}
                                                    <td>
                                                        {/* <Link to={'/editmember'} state={option} className="btn btn-primary bg-dark">
                                                            <FaEdit /> Edit
                                                        </Link> */}
                                                        <button className='btn btn-success' onClick={(e) => handleUpdateCharge(e, option.operatorCode)}>Update</button>
                                                    </td>

                                                </tr>

                                            )) : <td colSpan={5} align='center'>NO DATA AVAILABLE</td>}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>

        </>

    )
}


import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import RestService from "../http";
import { FaFileExcel } from "react-icons/fa";
import formatDate from "./utilityComponents/FormatDate";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Pagination2 from './utilityComponents/Pagination2';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';
import * as XLSX from 'xlsx';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function CouponReqReport() {

    const [selectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();
    const [couponReport, setCouponReport] = useState([]);
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');
    const [open, setOpen] = React.useState(false);
    const [remark, setRemark] = useState('');
    const [couponStatus, setCouponStatus] = useState('');
    const [reqId, setReqId] = useState('');

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    const downloadExcel = () => {
        // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
        // writeFile(wb, 'recharge_report.xlsx');

        const ws = XLSX.utils.json_to_sheet(couponReport);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Coupon Req Report');

        XLSX.writeFile(wb, 'coupon_req.xlsx');
    };

    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);



    const service = new RestService();

    const handleCouponReqReport = () => {
        var raw = {
            "fromDate": formatDate(date),
            "toDate": formatDate(date1),
            "status": status
        };

        setLoading(true);

        service.post("report/fetchCouponRequestReport", raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                setCouponReport(result.data);
            } else {
                showToast("DANGER", result.message);
                setCouponReport([]);
            }
        }).catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        handleCouponReqReport();
    }, []);


    const [filteredCouponReport, setFilteredCouponReport] = useState([]);
    const [searchInput, setSearchInput] = useState('');


    useEffect(() => {
        const filteredList = couponReport.filter(item =>
            item.status.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredCouponReport(filteredList);
    }, [couponReport, searchInput]);

    const paginatedCouponReport = filteredCouponReport.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleApprove = (requestId) => {
        setReqId(requestId);
        setOpen(true);
        setCouponStatus("SUCCESS");
    }

    const handleReject = (requestId) => {
        setReqId(requestId);
        setOpen(true);
        setCouponStatus("FAILED");
    }


    const handleUpdateCouponReq = () => {

        var raw = {
            "requestId": reqId,
            "status": couponStatus,
            "remark": remark
        }

        service.post("utiPan/updateCouponRequest", raw).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                handleCouponReqReport();
            } else {
                showToast("DANGER", result.message);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Coupon Request Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Coupon</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Coupon Req. Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label for="rec_to_load"> Start Date</label><br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label for="rec_to_load"> End Date</label>
                                                <br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    disabledDayAriaLabelPrefix="#$"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date1}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate1(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label>
                                                    Status <span className="text-danger"> *</span>
                                                </label>

                                                <select
                                                    className="custom-date-picker"
                                                    name="customer_type"
                                                    id="customer_type"
                                                    fdprocessedid="g30y5e"
                                                    onChange={(e) => setStatus(e.target.value)}
                                                >
                                                    <option value={""}>SELECT STATUS</option>
                                                    <option value={"SUCCESS"}>SUCCESS</option>
                                                    <option value={"FAILED"}>FAILED</option>
                                                    <option value={"PENDING"}>PENDING</option>
                                                </select>
                                            </div>

                                            <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                                            <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ textAlign: 'center' }}>
                                                <button
                                                    onClick={handleCouponReqReport}
                                                    className="btn btn-primary btn-block"
                                                    style={{ marginTop: '30px', color: 'white', fontWeight: 600 }}
                                                    fdprocessedid="qnf2"
                                                >
                                                    Search
                                                </button>

                                            </div>
                                            <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ marginTop: '10px', textAlign: 'center' }}>
                                                <button type="button" name="" className="btn btn-success" style={{ marginTop: '19px' }} onClick={downloadExcel} fdprocessedid="9svl7o">
                                                    <FaFileExcel className="fa fa-file-excel-o" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                <center>
                                    <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                        <div>

                                            <div className="col-md-3" style={{ float: "left", visibility: "hidden" }}>
                                                <div>
                                                    <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                    <input
                                                        className="form-control text-box"
                                                        type="text"
                                                        placeholder="SEARCH BY STATUS"
                                                        value={searchInput}
                                                        onChange={(e) => setSearchInput(e.target.value)}

                                                    />
                                                </div>
                                            </div><br />

                                            <Pagination2
                                                totalPages={Math.ceil(couponReport.length / itemsPerPage)}
                                                currentPage={currentPage}
                                                onChange={handlePageChange}

                                            />
                                        </div>
                                    </div>
                                </center>

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr className='text-center'>
                                                <th className="px-1">SL.NO</th>
                                                <th>User Id</th>
                                                <th>Ps. NAME</th>
                                                <th>Opening Bal.</th>
                                                <th>Amount</th>
                                                <th>Closing Bal.</th>
                                                <th>Coupon</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Operator Code</th>
                                                <th>Pan UserId</th>
                                                <th>Pan Password</th>
                                                <th>STATUS</th>
                                                <th>REMARK</th>
                                                <th>Request Id</th>
                                                <th>Total Coupon</th>
                                                <th>Total Charge</th>
                                                <th>WLID</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!paginatedCouponReport || paginatedCouponReport.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="19">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                paginatedCouponReport.map((item, index) => (
                                                    <tr className='text-center'>
                                                        <td key={index}>{index + 1}</td>
                                                        <td>{item.userId}</td>
                                                        <td>{item.psname}</td>
                                                        <td>{item.opbl}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{item.clbl}</td>
                                                        <td>{item.coupon}</td>
                                                        <td>{item.date}</td>
                                                        <td>{item.time}</td>
                                                        <td>{item.operatorCode}</td>
                                                        <td>{item.panUserid}</td>
                                                        <td>{item.panPassword}</td>
                                                        <td style={{
                                                            color: item.status === "SUCCESS" ? 'green' : (item.status === "PENDING" ? 'orange' : 'red'),
                                                            fontWeight: "bold"
                                                        }}>{item.status}
                                                        </td>
                                                        <td>{item.remark}</td>
                                                        <td>{item.requestId}</td>
                                                        <td>{item.totalCoupon}</td>
                                                        <td>{item.totalcharge}</td>
                                                        <td>{item.wild}</td>
                                                        {item.status === "PENDING" ? <td>
                                                            <button className='btn bg-green' onClick={() => handleApprove(item.requestId)}>Accept</button>&nbsp;
                                                            <button className='btn bg-danger' onClick={() => handleReject(item.requestId)}>Reject</button>
                                                        </td> : <td></td>}
                                                    </tr>

                                                ))
                                            )}
                                        </tbody>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} style={{ background: "lavenderblush", border: "none" }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center' style={{ color: "brown" }}>
                            Remark<span>*</span>
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <div className="col-md-12">
                                <input
                                    name="mobile"
                                    type="text"
                                    className="form-control"
                                    required=""
                                    placeholder="ENTER REMARK"
                                    fdprocessedid="z5je8i"
                                    onChange={(e) => setRemark(e.target.value)}
                                />
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12 text-center'>
                                    <button className='btn bg-primary px-4' onClick={(e) => handleUpdateCouponReq(e.target.value)}>SUBMIT</button>
                                </div>
                            </div>
                        </Typography>
                    </Box>
                </Modal>
            </div>


            <footer className="main-footer" >
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';

export default function Viewpromocode() {

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '532.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">View Promocode</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="/">Utilities</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="#">Promocode</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="/viewpromocd">View Promocode</a>
                                    </li>
                                    <li className="breadcrumb-item active">View</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                        <label>Promocode :</label>
                                        <span> FLAT10</span>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                        <label>Status :</label>
                                        <span>
                                            {' '}
                                            <span className="text-success" style={{ fontWeight: 'bold' }}>
                                                ACTIVE
                                            </span>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <hr />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                        <label>Minimum Amount :</label>
                                        <span> 100.00</span>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                        <label>Is this for first recharge ?</label>
                                        <span> 1</span>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                        <label>Cashback :</label>
                                        <span> 10.00</span>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                        <label>Cashback Type :</label>
                                        <span> FLAT</span>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                        <label>Recharge applicable times :</label>
                                        <span> 10</span>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                        <label>Description :</label>
                                        <span> test</span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <hr />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-1 col-sm-5 col-5">
                                                <label>Operator :</label>
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-sm-7 col-7">
                                                <span> <p></p></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <a href="www.softmintdigital.com">SOFTMINT</a>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { useState, useEffect } from 'react';
import RestService from "../http";
import swal from 'sweetalert';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';
import { Link } from 'react-router-dom';


export default function Transfered() {

    const [selectedOption, setSelectedOption] = useState('');
    // const [creditTo, setCreditTo] = useState('');
    const [amount, setAmount] = useState(0);
    const [userId, setUserId] = useState('');
    const [roles, setRoles] = useState([]);
    const [userList, setUserList] = useState([]);
    const service = new RestService();
    const [isSessionExpired, setIsSessionExpired] = useState(false);
    const [remark, setRemark] = useState("");


    const showToast = (status, message) => {
        if (status === "SUCCESS") {
            toast.success(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else if (status === "DANGER") {
            toast.error(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.warn(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handleOptionChange = (e) => {
        console.log(e.target.value);
        setSelectedOption(e.target.value);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handleSubmit = () => {

        var raw = {
            "userId": userId,
            "balance": amount,
            "operatorIncode": 2,
            "remark": remark
        }

        console.log(raw);
        swal({
            title: 'Confirmation',
            text: 'Are you sure you want to submit?.',
            icon: 'warning',
            closeOnClickOutside: false,
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },

                confirm: {
                    text: "Yes",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                }
            },
            dangerMode: true,
        }).then((confirmed) => {

            if (confirmed) {
                if (selectedOption === "CR") {
                    service.post("user/creditFund", raw).then(result => {
                        console.log(result);
                        if (result === undefined) {
                            showToast("DANGER", result.message);

                            setTimeout(() => {
                                window.location.reload();
                            }, 1000);

                        } else {
                            if (result.status === 0 || result.status === 3) {
                                showToast("DANGER", result.message);


                            } else {
                                showToast("SUCCESS", result.message);
                                setTimeout(() => {
                                    window.location.reload();
                                }, 2000);
                            }
                        }

                    }).catch(error => {
                        console.error("Error submitting form:", error);

                    });
                } else if (selectedOption === "DR") {
                    service.post("user/debitFund", raw).then(result => {
                        console.log(result);
                        if (result === undefined) {
                            showToast("DANGER", result.message);


                        } else {
                            if (result.status === 0 || result.status === 3) {
                                showToast("DANGER", result.message);


                            } else {
                                showToast("SUCCESS", result.message);
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);
                            }
                        }

                    }).catch(error => {
                        console.error("Error submitting form:", error);

                    });
                } else {
                    showToast("WARN", "PLEASE SELECT VALID TYPE!!");
                }
            }
        });
    };


    const handleCustomerType = (e) => {
        console.log(e.target.value);
        service
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setUserList(result.data);
                } else {
                    setUserList([]);
                }
            })
            .catch((error) => {
                console.error(error);
                setUserList([]);

            });
    };


    useEffect(() => {
        service.get("user/roles").then(result => {
            console.log(result);
            setRoles(result.role);

        }).catch((error) => {
            console.log(error);
        });
    }, []);


    const [selectedUserId, setSelectedUserId] = useState(null);

    const options = userList.map((item) => ({
        value: item.userId,
        label: `${item.name} || ${item.mobile}`,
    }));

    const handleSelectChange = (selectedUserId) => {
        setSelectedUserId(selectedUserId);
        setUserId(selectedUserId.value);
    };


    return (
        <>
            <Navbar />

            <ToastContainer />

            <div className="content-wrapper" style={{ minHeight: "620.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Fund Transfer</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Wallet</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Fund Transfer</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 d-flex justify-content-center align-items-center">
                                <div className="card col-md-9 m-auto">

                                    <div className="card-body">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <select
                                                        className="form-control"
                                                        name="selfund"
                                                        id="fundingselect"
                                                        value={selectedOption}
                                                        onChange={handleOptionChange}
                                                    >
                                                        <option value="">Select Funding</option>
                                                        <option value="CR">Credit Fund</option>
                                                        <option value="DR">Debit Fund</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {selectedOption === 'CR' && (
                                                <div >
                                                    <div className="row" id="show_credit_form">

                                                        <div className="col-md-12" style={{ marginTop: '10px' }}>
                                                            <label>User Type</label>
                                                            <span className="text-danger"> *</span>
                                                            <select
                                                                className="form-control select2 "
                                                                required=""
                                                                id="service_id"
                                                                data-select2-id="service_id"
                                                                tabindex="-1"
                                                                aria-hidden="true"
                                                                onChange={handleCustomerType}
                                                            >
                                                                <option>Select User Type</option>
                                                                {roles.map((item) => (
                                                                    item.roleId !== 0 ? <option value={item.roleId}>{item.roleName}</option> : null
                                                                ))}
                                                            </select>

                                                        </div>

                                                        <div className="col-md-12" style={{ marginTop: '10px' }}>
                                                            <label>Credit To</label>
                                                            <span className="text-danger"> *</span>
                                                            {/* <select
                                                                className="form-control select2 "
                                                                name="service_id"
                                                                required=""
                                                                id="service_id"
                                                                data-select2-id="service_id"
                                                                tabindex="-1"
                                                                aria-hidden="true"
                                                                onChange={(e) => setUserId(e.target.value)}
                                                            >
                                                                <option value="">Select Id...</option>
                                                                {userList.map((item) => (
                                                                    <option value={item.userId}>{item.name}</option>
                                                                ))}
                                                            </select> */}

                                                            <Select
                                                                className="select-box width100p"
                                                                options={options}
                                                                isSearchable
                                                                value={selectedUserId}
                                                                onChange={handleSelectChange}
                                                            />

                                                        </div>

                                                        <div className="col-md-12" style={{ marginTop: '10px' }}>
                                                            <label>Amount</label>
                                                            <span className="text-danger"> *</span>
                                                            <input
                                                                type="text"
                                                                name="amount"
                                                                className="form-control"
                                                                placeholder="Enter Amount"
                                                                required=""
                                                                onChange={handleAmountChange}
                                                            />
                                                            <span className="text-danger"></span> <span id="extra_commission"></span>
                                                        </div>

                                                        <div className="col-md-12" style={{ marginTop: '10px' }}>
                                                            <label>Remark</label>
                                                            <span className="text-danger"> *</span>
                                                            <input
                                                                type="text"
                                                                name="remark"
                                                                className="form-control"
                                                                placeholder="Enter Remark"
                                                                required=""
                                                                onChange={(e) => setRemark(e.target.value)}
                                                            />
                                                        </div>

                                                        <input type="hidden" name="securityToken" value="642c0c82ae60879fc606d16fcb5ced29" />
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center" style={{ marginTop: '10px' }}>
                                                            <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {selectedOption === 'DR' && (
                                                <div>
                                                    <div className="row">

                                                        <div className="col-md-12" style={{ marginTop: '10px' }}>
                                                            <label>User Type</label>
                                                            <span className="text-danger"> *</span>
                                                            <select
                                                                className="form-control select2 "
                                                                required=""
                                                                id="service_id"
                                                                data-select2-id="service_id"
                                                                tabindex="-1"
                                                                aria-hidden="true"
                                                                onChange={handleCustomerType}
                                                            >
                                                                <option>Select User Type</option>
                                                                {roles.map((item) => (
                                                                    item.roleId !== 0 ? <option value={item.roleId}>{item.roleName}</option> : null
                                                                ))}
                                                            </select>
                                                        </div>

                                                        <div className="col-md-12" style={{ marginTop: '10px' }}>
                                                            <label>Debit From</label>
                                                            <span className="text-danger"> *</span>
                                                            {/* <select
                                                                className="form-control select2 "
                                                                name="service_id"
                                                                required=""
                                                                id="service_id"
                                                                data-select2-id="service_id"
                                                                tabindex="-1"
                                                                aria-hidden="true"
                                                                onChange={(e) => setUserId(e.target.value)}
                                                            >
                                                                <option value="">Select Id...</option>
                                                                {userList.map((item) => (
                                                                    <option value={item.userId}>{item.name}</option>
                                                                ))}
                                                            </select> */}

                                                            <Select
                                                                className="select-box width100p"
                                                                options={options}
                                                                isSearchable
                                                                value={selectedUserId}
                                                                onChange={handleSelectChange}
                                                            />

                                                            <span className="text-info" id="customer_bal1"></span>
                                                        </div>
                                                        <div className="col-md-12" style={{ marginTop: '10px' }}>
                                                            <label>Amount</label>
                                                            <span className="text-danger"> *</span>
                                                            <input
                                                                type="text"
                                                                name="amount"
                                                                className="form-control"
                                                                placeholder="Enter Amount"
                                                                required
                                                                onChange={handleAmountChange}
                                                            />
                                                            <span className="text-danger"></span>
                                                        </div>

                                                        <div className="col-md-12" style={{ marginTop: '10px' }}>
                                                            <label>Remark</label>
                                                            <span className="text-danger"> *</span>
                                                            <input
                                                                type="text"
                                                                name="remark"
                                                                className="form-control"
                                                                placeholder="Enter Remark"
                                                                required=""
                                                                onChange={(e) => setRemark(e.target.value)}
                                                            />
                                                        </div>

                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center" style={{ marginTop: '10px' }}>
                                                            <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

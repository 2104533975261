import React from 'react';
import { CiBellOn, CiSearch } from 'react-icons/ci';
import { AiOutlineFolderOpen, AiFillSetting, AiFillStar, AiFillQuestionCircle, AiFillPrinter, AiOutlineDoubleRight, AiOutlineDoubleLeft, } from 'react-icons/ai';
import { MdLogout } from 'react-icons/md';
import { FaUserAlt, FaAngleDown, FaBars, FaSearch, FaFileExport, FaWeixin, FaTimes } from 'react-icons/fa';
import { BiUserCircle, BiHomeAlt, BiChevronDown } from 'react-icons/bi';
import { HiOutlineChat } from 'react-icons/hi';
import { RiShoppingBasketLine } from 'react-icons/ri';
import { BsGrid, BsFingerprint } from 'react-icons/bs';
import { useState, useRef, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RestService from "../http";
import formatDate from "../components/utilityComponents/FormatDate";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Logout from '../components/utilityComponents/Logout';
import Pagination2 from '../components/utilityComponents/Pagination2';
import Loader from '../components/Loader';
import { ToastContainer } from 'react-toastify';
import showToast from '../components/utilityComponents/APPToast';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Nsdlreport() {

  const service = new RestService();
  const [openDropdowns, setOpenDropdowns] = useState([]);
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [date, setDate] = useState(new Date());
  const [date1, setDate1] = useState(new Date());
  const [userList, setUserList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [nsdlReport, setNsdlReport] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [log] = useState('');
  const [showModal2, setShowModal2] = useState(false);
  const [status, setStatus] = useState('');
  const [balance, setBalance] = useState({});
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [name] = useState(sessionStorage.getItem("name"));
  const [roleName] = useState(sessionStorage.getItem("role"));


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const [searchInput, setSearchInput] = useState('');
  const [filteredNsdlReport, setFilteredNsdlReport] = useState([]);
  const totalAmount = filteredNsdlReport.reduce(
    (total, item) => total + parseFloat(item.amount),
    0
  );

  useEffect(() => {
    try {
      const filteredList = nsdlReport.filter(item =>
        item.transactionType &&
        item.transactionType.toLowerCase().includes((searchInput || '').toLowerCase())
      );
      setFilteredNsdlReport(nsdlReport);

    } catch (error) {
      console.error('Error in useEffect:', error);
    }
  }, [nsdlReport, searchInput]);


  const paginatedNsdlReport = filteredNsdlReport.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );


  const handleClick = (index) => {
    const updatedOpenDropdowns = [...openDropdowns];

    if (updatedOpenDropdowns.includes(index)) {
      updatedOpenDropdowns.splice(updatedOpenDropdowns.indexOf(index), 1);
    } else {
      updatedOpenDropdowns.push(index);
    }

    setOpenDropdowns(updatedOpenDropdowns);
  };


  const modalRef = useRef(null);

  const openForm = (event) => {
    event.preventDefault();
    modalRef.current.style.display = 'block';
  };

  const closeForm = () => {
    modalRef.current.style.display = 'none';
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //API INTEGRATION

  const handleNsdlReport = () => {
    var raw = {
      "fromDate": formatDate(date),
      "toDate": formatDate(date1),
      "status": status
    };

    setLoading(true);
    service.post("report/fetchNsdlPanReport", raw).then(result => {
      setLoading(false);

      console.log(result);
      setNsdlReport(result.data);

    }).catch((err) => {
      console.error(err);
      setUserList([]);
    });
  };

  useEffect(() => {
    handleNsdlReport();
  }, []);

  useEffect(() => {
    service.get("user/roles").then(result => {
      console.log(result);
      setRoles(result.role);

    }).catch((error) => {
      console.log(error);
    });
  }, []);


  // const handleShowModal = (e, data) => {
  //   setShowModal(true);
  //   setShowModal2(false);
  //   setLog(data);
  // }

  // const handleShowModal2 = (e, data) => {
  //   setShowModal2(true);
  //   setShowModal(false);
  //   setLog(data);
  // }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseModal2 = () => {
    setShowModal2(false);
  };

  useEffect(() => {
    service.fetchUserBalance().then(result => result.json()).then(balres => {
      console.log(balres);
      setBalance(balres);

    }).catch((error) => {
      console.log(error);
    })

  }, []);

  const handlePrint = () => {
    window.print();
  };


  const handleIncompleteApply = (orderId) => {
    var raw = {
      "incTxnId": orderId
    }

    setLoading(true);

    service.post("nsdl/incompleteApply", raw).then(result => {
      setLoading(false);

      console.log(result);

      const dataUrl = "https://assisted-service.egov-nsdl.com/SpringBootFormHandling/incompleteApplication"

      if (result.status === service.SUCCESS) {
        // setInsuranceUrl(dataUrl);
        sessionStorage.setItem("formData", result.data);
        sessionStorage.removeItem("url");
        sessionStorage.setItem("url", dataUrl);

        setTimeout(() => {
          window.location.href = '/nsdlform'
        }, 2000);
      } else {
        showToast("DANGER", result.message);
      }

    }).catch((error) => {
      console.log(error);
    })
  }

  return (
    <>
      {loading && <Loader />}
      <ToastContainer />

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" className='bg-light' open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <div className="pull-right" style={{ marginLeft: "79%" }}>
              <div className="btn-group main-balance" style={{ borderRadius: "10px", backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)', color: "white", marginTop: "2px" }}>
                <button type="button" data-toggle="dropdown" style={{ color: "white", fontWeight: "500", background: "none", border: "none" }} >
                  <img src="image/wallet.png" className="wallet-icon" />
                  <span className="mybal-text-white">My Balance</span><br />
                  <span className="badge" id="wallet" style={{ fontSize: "14px" }}>&#8377;
                    {balance === undefined
                      ? 0.0
                      : balance.data}
                  </span>
                </button>
              </div>&nbsp;&nbsp;

              <div className="btn-group btn-group-list btn-group-notification notification">
                <button style={{ background: "none", border: "none", color: "black", position: "relative", }} type="button" data-toggle="dropdown">
                  <CiBellOn style={{ fontSize: "30px" }} />
                  <span className="badge"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      background: "red", // Change the background color as needed
                      borderRadius: "50%",
                      padding: "5px",
                      fontSize: "12px",
                      color: "white",
                    }}
                  >0</span></button>

              </div>&nbsp;&nbsp;

              <div className="btn-group btn-group-option">
                <button style={{ background: "none", border: "none" }} type="button" data-toggle="dropdown"><span
                  className='user-img'>
                  <img className='rounded-circle img-circle' src='image/profile.png' width='40' alt='' /></span></button>
                <ul className="dropdown-menu pull-right" role="menu">
                  <li><a href="/editprofile"><FaUserAlt className='glyphicon' /> My Profile</a>
                  </li>

                  <li className="divider"></li>
                  <li>
                    <a onClick={() => Logout("your-url")}>
                      <MdLogout className='glyphicon' />Sign Out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              <img style={{ width: "150px" }} src='./image/softmintlogo.png' />
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>

          </DrawerHeader>
          <Divider />
          <List>

            <div className="leftpanel" style={{ backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)' }}>
              <div className="media profile-left">
                <a className="pull-left profile-thumb" href="/editprofile">
                  <img
                    src="./image/profile.png"
                    alt="user"
                    className="img-circle"
                  /> </a>&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="media-body">
                  <h4 className="media-heading">{name}</h4>
                  <small className="text-muted">{service.roles[new Number(roleName)]}</small>
                </div>
              </div>
              <ul className="nav nav-pills nav-stacked">
                <li className="active"><a href="/side"><BiHomeAlt className='house' /> <span>
                  Dashboard</span></a></li>

                <li className="parent" ><a href="#" onClick={() => handleClick(0)} ><BiUserCircle className='house' />
                  <span> My Account</span>

                  <BiChevronDown style={{ marginLeft: "75px" }} />
                </a>

                  <ul className={`children ${openDropdowns.includes(0) ? 'show' : ''}`}>
                    <li className="" ><a href="/editprofile"> Edit Profile</a></li>
                    <li className=""><a href="/kyc"> KYC Verification</a></li>
                    <li className=""><a href="/kycmicroatm"> KYC Microatm</a></li>
                    <li className=""><a href="/changepasswrd"> Change Password</a></li>
                    <li className=""><a href="/commison"> My Commission</a></li>
                    <li className=""><a href="print/certificate-print.php" target="_blank"> Print
                      Certificate</a></li>
                  </ul>

                </li>
                <li className="parent" ><a href="#" onClick={() => handleClick(1)}>
                  <RiShoppingBasketLine className='house' /> <span>My Wallet</span>

                  <BiChevronDown style={{ marginLeft: "85px" }} />
                </a>
                  <ul className={`children ${openDropdowns.includes(1) ? 'show' : ''}`}>
                    <li className=""><a href="/accountinfo"> Account Info</a></li>
                    <li className=""><a href="/bankaccount"> Bank Account Add</a></li>
                    <li className=""><a href="/sendmoney"> Send Money</a></li>
                    {/* <li className=""><a href="/addmoney"> Add Money</a></li> */}
                    {/* <li className=""><a href="/fundrequest"> Fund Request</a></li> */}
                    {/* <li className=""><a href="/wallettrans"> Wallet Transfer</a></li> */}
                    {/* <li className=""><a href="/mpin"> Change Mpin</a></li> */}
                  </ul>
                </li>

                <li className="">
                  <a href="/retailerservice"><BsGrid className='house' /> <span>
                    Services</span>
                    <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                  </a>
                </li>

                <li className="parent"><a href="#" onClick={() => handleClick(2)}><AiOutlineFolderOpen className='house' /> <span> Reports</span>

                  <BiChevronDown style={{ marginLeft: "97px" }} />
                </a>
                  <ul className={`children ${openDropdowns.includes(2) ? 'show' : ''}`}>
                    <li className=""><a href="/rechargereportretailer"> Recharge Report</a></li>
                    <li className=""><a href="/aepsreport"> AEPS Report </a></li>
                    <li className=""><a href="/aadharpayreport"> AadharPay Report </a></li>
                    <li className=""><a href="/retailertxnreport"> Transaction Report </a></li>
                    <li className=""><a href="/payout"> Payout Report</a></li>
                    <li className=""><a href="/nsdlreport"> NSDL Report</a></li>
                    <li className=""><a href="/retailerearningreport"> Earning Report</a></li>
                    <li className=""><a href="/pancard"> Pancard Report</a></li>
                    <li className=""><a href="/ledger"> Ledger Report</a></li>
                    <li className=""><a href="/matmreport"> MATM Report</a></li>
                    <li className=""><a href="/retailercouponreport"> Coupon Req. Report</a></li>
                    <li className=""><a href="/retailermatmlogreport"> MATM Log Report</a></li>
                    <li className=""><a href="/retailermovetobankuserreport"> MoveToBankUser Report</a></li>
                    <li className=""><a href="/dmtreport"> DMT Report</a></li>

                  </ul>
                </li>
                <li className="">
                  <a href="/feedback"><HiOutlineChat className='house' /> <span>
                    Feedback</span>
                    <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                  </a></li>

                <li className="">
                  <a href="/kyc"><BsFingerprint className='house' /> <span>
                    AEPS E-Kyc</span>
                    <BiChevronDown style={{ marginLeft: "70px", visibility: "hidden" }} />
                  </a></li>
              </ul>

              <div className="menu-devide">&nbsp;</div>
              <div style={{ display: open ? 'block' : 'none' }}>
                <div className="customer-support-sec">
                  <h3>Account Details</h3>
                  <p>ICICI BANK</p>
                  <p style={{ fontSize: "11px" }}>(SOFTMINT)</p>
                  <p>A/C No - 260705000861</p>
                  <p><span>IFSC -</span> ICIC0002607</p>
                </div>
                <div className="menu-devide">&nbsp;</div>
                <div className="customer-support-sec" style={{ display: open ? 'block' : 'none' }}>
                  <h3>Quick Support</h3>
                  <p>SOFTMINT</p>
                  <p>Phone : 9999726418</p>
                  <p>What's App : 8809912400</p>
                  <p>Email : <a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                    data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}>info@softmintdigital.com</a></p>
                </div>
              </div>

              <div style={{
                display: open ? 'none' : 'block',
                height: '100vh'
              }}>
                <div>
                  <h3></h3>
                  <p></p>
                  <p></p>
                  <p></p>
                  <p><span></span></p>
                </div>
                <div className="menu-devide">&nbsp;</div>
                <div className="">
                  <h3></h3>
                  <p></p>
                  <p></p>
                  <p><a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                    data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}></a></p>
                </div>
              </div>

            </div><br />
          </List>
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <DrawerHeader />
          <div className="pageheader">
            <div className="media">
              <div className="media-body">
                <ul className="breadcrumb">
                  <li><a href="javascript:void(0);"><BiHomeAlt style={{ color: '#999' }} /></a></li>&nbsp;
                  <li>Report</li>
                </ul>
                <h4>NSDL Report</h4>
              </div>
            </div>
          </div>

          <div className="card" style={{ margin: "1%" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="service-form">
                  <h1 className="service-title">NSDL Report</h1>
                  <div className="pull-right" style={{ marginTop: "-33px", marginRight: "15px", float: "right" }}>
                    {/* <a title="" data-toggle="tooltip" className="tooltips mr5" href="javascript:void(0);" data-original-title="Search" id="filterdt" onClick={showSearchPanel}><FaSearch className="glyphicon glyphicon-print" /> Search</a>&nbsp;&nbsp; */}
                    <a title="" data-toggle="tooltip" className="tooltips mr5" onClick={handlePrint} data-original-title="Print"><AiFillPrinter className="glyphicon glyphicon-print" /> Print</a>&nbsp;&nbsp;
                    <a title="" data-toggle="tooltip" className="tooltips mr5" href="javascript:void(0);" onClick="exportdata()" data-original-title="Export"><FaFileExport className="fa" /> Export</a>
                  </div>

                  <div>
                    <section className='card' style={{ margin: "1%", padding: "10px" }}>
                      <div className="row">
                        <div className="col-md-3">
                          <label for="rec_to_load"> Start Date</label><br />
                          <DatePicker
                            className="custom-date-picker"
                            name="string"
                            fdprocessedid="9n5i4u"
                            selected={date}
                            dateFormat={"yyyy-MM-dd"}
                            onChange={(date) => setDate(date)}
                            showYearDropdown
                            showMonthDropdown
                          />
                        </div>

                        <div className="col-md-3">
                          <label for="rec_to_load"> End Date</label>
                          <br />
                          <DatePicker
                            className="custom-date-picker"
                            name="string"
                            disabledDayAriaLabelPrefix="#$"
                            fdprocessedid="9n5i4u"
                            selected={date1}
                            dateFormat={"yyyy-MM-dd"}
                            onChange={(date) => setDate1(date)}
                            showYearDropdown
                            showMonthDropdown
                          />
                        </div>

                        <div className="col-md-3">
                          <label>
                            Status <span className="text-danger"> *</span>
                          </label>

                          <select
                            className="custom-date-picker"
                            name="customer_type"
                            id="customer_type"
                            fdprocessedid="g30y5e"
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option value={""}>SELECT STATUS</option>
                            <option value={"SUCCESS"}>SUCCESS</option>
                            <option value={"FAILED"}>FAILED</option>
                            <option value={"PENDING"}>PENDING</option>
                          </select>
                        </div>

                        <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                        <div className="col-lg-1 col-md-1 col-sm-12 mt-2" style={{ textAlign: 'center' }}>
                          <button
                            onClick={handleNsdlReport}
                            className="btn btn-primary btn-block"
                            style={{ marginTop: '27px', color: 'white', fontWeight: 600, borderRadius: "1px" }}
                            fdprocessedid="qnf2"
                          >
                            Search
                          </button>

                        </div>
                      </div>
                    </section>


                    {showModal && (
                      <div className="modal fade show" style={{ display: 'block' }} aria-modal="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                          <div className="modal-content">
                            <div>
                              <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Request</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <div className='card'>
                                  <div className='card-body'>
                                    {log}
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" fdprocessedid="7wkuky" onClick={handleCloseModal}>Close</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {showModal2 && (
                      <div className="modal fade show" style={{ display: 'block' }} aria-modal="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                          <div className="modal-content">
                            <div>
                              <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Response</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal2}>
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <div className='card'>
                                  <div className='card-body'>
                                    {log}
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" fdprocessedid="7wkuky" onClick={handleCloseModal2}>Close</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <center>
                      <div className="box-content" style={{ padding: "0", margin: "0", display: "flex", justifyContent: "center" }}>
                        <div>
                          {/* <ul className="pagination pagination-sm pagination-colory" style={{ display: "flex", justifyContent: "center" }}>
                                  <li className="disabled" style={{ border: "1px solid grey" }}><a href="javascript:void(0);"><AiOutlineDoubleLeft className='fa' /></a></li>
                                  <li className="disabled" style={{ border: "1px solid grey" }}><a href="javascript:void(0);"><AiOutlineDoubleRight className='fa' /></a></li>
                                </ul> */}

                          <Pagination2
                            totalPages={Math.ceil(nsdlReport.length / itemsPerPage)}
                            currentPage={currentPage}
                            onChange={handlePageChange}

                          />
                        </div>
                      </div>
                    </center>
                    <div className="table-responsive">
                      <table className="table table-bordered mb30" style={{ overflowX: 'scroll' }}>
                        <thead>
                          <tr className="table-new">
                            <th className="px-1">SL.NO</th>
                            <th>Name</th>
                            <th>User Id</th>
                            <th>Mobile</th>
                            <th>Ack. No</th>
                            <th>Agent Code</th>
                            <th>Order Id</th>
                            <th>Type</th>
                            {/* <th>Source</th> */}
                            <th className="px-1">Charge</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th className="px-1">Remark</th>
                            <th className="px-1">Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(!paginatedNsdlReport || paginatedNsdlReport.length === 0) ? (
                            <tr className='text-center'>
                              <td colSpan="12">NO DATA AVAILABLE</td>
                            </tr>
                          ) : (
                            paginatedNsdlReport.map((item, index) => (

                              // {paginatedRechargeReport !== undefined ? (
                              //   paginatedRechargeReport.map((item, index) => (
                              <tr>
                                <td key={index}>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.userid}</td>
                                <td>{item.mobile}</td>
                                <td>{item.ackNo}</td>
                                <td>{item.agentCode}</td>
                                <td>{item.orderId}</td>
                                <td>{item.type}</td>
                                {/* <td>{item.source}</td> */}
                                <td>{item.charge}</td>
                                <td>{item.date}</td>
                                <td>{item.time}</td>
                                <td>{item.errorMsg}</td>
                                <td style={{
                                  color:
                                    item.status === "SUCCESS" ? 'green' :
                                      item.status === "FAILED" ? 'red' :
                                        item.status === "INCOMPLETE" ? 'orange' : 'black',
                                  fontWeight: "bold"
                                }}>
                                  {item.status}
                                </td>
                                {item.status === "INCOMPLETE" ?
                                  <td>
                                    <button className='btn bg-success' onClick={() => handleIncompleteApply(item.orderId)}>Resume</button>
                                  </td> : <td></td>}
                              </tr>

                            ))
                          )
                          }
                        </tbody>

                        <tfoot>
                          {paginatedNsdlReport !== undefined && paginatedNsdlReport.length > 0 && (
                            <tr>
                              <td colSpan="5" style={{ color: "brown", fontWeight: "bold" }}>Total Amount:
                                {paginatedNsdlReport.reduce((total, item) => total + item.charge, 0)}
                              </td>

                              <td colSpan="1"></td>

                              <td colSpan="1" style={{ color: "green", fontWeight: "bold" }}>Success Status:
                                {paginatedNsdlReport.filter(item => item.status === "SUCCESS").length}
                              </td>
                              <td colSpan="1" style={{ color: "red", fontWeight: "bold" }}>Failed Status:
                                {paginatedNsdlReport.filter(item => item.status === "FAILED").length}
                              </td>
                              <td colSpan="1" style={{ color: "orange", fontWeight: "bold" }}>Pending Status:
                                {paginatedNsdlReport.filter(item => item.status === "INCOMPLETE").length}
                              </td>

                              <td colSpan="1"></td>
                            </tr>
                          )}
                        </tfoot>

                      </table>

                    </div>
                    <div className="panel-footer">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Box>
      </Box>

      <a href="https://api.whatsapp.com/send/?phone=8809912400&text&app_absent=0" target="_blank"
        className="whatsapp-but" title="">
        <img src="image/whatsapp-icon.png" alt="Whatapps" />
      </a>

      <a
        href="#"
        target="_blank"
        className="helpdesk-but"
        title="Request Callback !"
        onClick={openForm}
      >
        <img src="image/help-icon.png" alt="Help" />
      </a>

      <div className="chat-popup" ref={modalRef} id="myForm">
        <form action="#" className="helpdesk-container" onSubmit={(e) => e.preventDefault()}>
          <h1>
            <div className="callbackheader-icon">
              <FaWeixin aria-hidden="true" style={{ fontSize: '35px', paddingTop: '7px' }} />
            </div>
            CallBack Request
          </h1>
          <label htmlFor="callback_name"><b>Full Name</b></label>
          <input
            type="text"
            id="callback_name"
            maxLength="35"
            name=""
            className="form-control textbox"
            placeholder="Name"
            required
          />
          <label htmlFor="callback_phone"><b>Contact Number</b></label>
          <input
            type="text"
            id="callback_phone"
            maxLength="10"
            name=""
            className="form-control textbox"
            placeholder="+91 9000 0000 00"
            required
          />
          <label htmlFor="callback_email"><b>Email ID</b></label>
          <input
            type="text"
            id="callback_email"
            maxLength="35"
            name=""
            className="form-control textbox"
            placeholder="info@softmintdigital.com"
            required
          />
          <label htmlFor="callback_msg"><b>Message (250 Character Only)</b></label>
          <textarea
            id="callback_msg"
            maxLength="250"
            placeholder="Type message.."
            name="msg"
            className="form-controlx"
          ></textarea>
          <button type="submit" className="btn" id="reqcallbut">
            Send Now
          </button>
          <a href="#" className="close-but" title="" onClick={closeForm}>
            <FaTimes aria-hidden="true" />
          </a>
        </form>
      </div>

      <div className="modal fade recharge-model success-model" id="model-succ-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="">
              <img src="images/success-icon.png" alt="" title="" className="error-icon" />
              <h4 id="successLabel" className="heading-text">Success Message!</h4>
            </div>
            <div className="modal-body" id="model-succ-body">
              <p className="mb-0"></p>
            </div>
            <input type="hidden" id="focusid" />
            <button type="button" className="btn btn-success model-button success-button" onClick="errordisplay()" data-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
      <div className="modal fade recharge-model error-model" id="model-err-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className=""><img src="images/error-icon.png" alt="" title="" className="error-icon" />
              <h4 id="errorLabel" className="heading-text">Error Message!</h4>
            </div>
            <div className="modal-body" id="modal-err-body">
              <p className="mb-0"></p>
            </div>
            <input type="hidden" id="focusid" />
            <button type="button" className="btn btn-success model-button error-button" onClick="errordisplay()" data-dismiss="modal">Dismiss</button>
          </div>
        </div>
      </div>
      <div className="modal fade recharge-model process-model" id="model-process-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="">
              <img src="images/error-icon.png" alt="" title="" className="error-icon" />
              <h4 id="errorLabel" className="heading-text">Process Message!</h4>
            </div>
            <div className="modal-body" id="modal-process-body">
              <p className="mb-0"></p>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade recharge-model " id="model-yn-save" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="">
              <img src="images/success-icon.png" alt="" title="" className="error-icon" />
              <h4 id="successLabel" className="heading-text">Success Message!</h4>
            </div>
            <div className="modal-body" id="modal-body-pdf">
              <p className="mb-0"></p>
            </div>
            <input type="hidden" id="getinvno" />
            <button type="button" className="btn btn-success model-button success-button" onClick="succok()" data-dismiss="modal" id="modal-no-save">OK</button>
          </div>
        </div>
      </div>


      );
    </>

  );
}

import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';


function isNumberKey(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
    }
}

export default function Updatetransreport() {

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '244.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Records</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Transaction</Link></li>
                                    <li className="breadcrumb-item active">Update Records</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <form action="/updatetransreport" method="post">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Transaction ID</label>
                                                <input type="text" name="transaction_id" value="SOF0000022609" readOnly className="form-control" placeholder="Enter Transaction ID" />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Member ID</label>
                                                <input type="text" name="member_id" value="RT1001" readOnly className="form-control" placeholder="Enter Member ID" />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Member Name</label>
                                                <input type="text" name="member_name" value="NAVNATH SASE" readOnly className="form-control" placeholder="Enter Member Name" />
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Recharge Number</label>
                                                <input type="text" name="recharge_number" value="8888888888" readOnly className="form-control" placeholder="Enter Recharge Number" />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Amount</label>
                                                <input type="text" name="amount" value="12.0000" readOnly className="form-control" placeholder="Enter Amount" />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Status</label>
                                                <input type="text" name="status" value="PENDING" readOnly className="form-control" placeholder="Enter Status" />
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>API ID</label>
                                                <input type="text" name="api_id" value="1" onKeyDown={isNumberKey} onKeyUp={isNumberKey} className="form-control" placeholder="Enter API ID" pattern="[0-9]" required />
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>API Transaction ID</label>
                                                <input type="text" name="api_txn_id" value="20221226050835" className="form-control" onKeyDown={isNumberKey} onKeyUp={isNumberKey} placeholder="Enter API Transaction ID" required />
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Operator Ref ID</label>
                                                <input type="text" name="operator_reference" required value="REF1672054715" className="form-control" placeholder="Enter Operator Reference ID" />
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Error Message</label>
                                                <input type="text" name="api_message" value="Transaction Pending" required className="form-control" placeholder="Enter Error Message" />
                                            </div>

                                            <input type="hidden" name="securityToken" value="c9418b93dbc0733ef4e94858e9536b1b" />

                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12" style={{ marginTop: '10px', textAlign: 'center' }}>
                                                <input type="submit" value="Update Record" className="btn btn-primary" />
                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import { Link } from 'react-router-dom';


export default function Updateauthority() {

    const [openCollapse, setOpenCollapse] = useState(null);

    const handleCollapseToggle = (id) => {
        setOpenCollapse((prevId) => (prevId === id ? null : id));
    };

    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [selectAll, setSelectAll] = useState(false);


    const handleSelectAll = (checked) => {
        setSelectAllChecked(checked);

        const allCheckboxes = document.querySelectorAll('.check_auth');
        allCheckboxes.forEach((checkbox) => {
            checkbox.checked = checked;
        });

    };

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Authority</h1>
                            </div>
                            <div className="col-sm-6" style={{ marginLeft: "" }}>
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Member Master</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="manageMember">Manage Member</Link>
                                    </li>
                                    <li className="breadcrumb-item active" style={{ marginLeft: 'auto', marginTop: '-5.5%' }}>Update Authority</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header ">
                                <span className="main_title">#6 AJOY MONDAL</span>
                            </div>

                            <div className="card-body table-responsive">
                                <form action="/updateautority" method="POST">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div id="accordion" className="custom-accordion mb-4">
                                                <div className="row">


                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="1">
                                                                <h5 className="m-0 font-size-15 ">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 1 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_1"
                                                                        aria-expanded={openCollapse === 1 ? 'true' : 'false'}
                                                                        aria-controls="href_1"
                                                                        onClick={() => handleCollapseToggle(1)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #1 - Dashboard
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 1 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_1"
                                                                className={`collapse ${openCollapse === 1 ? 'show' : ''}`}
                                                                aria-labelledby="1"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Dashboard
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input 
                                                                            type="checkbox" 
                                                                            name="autho[1]" 
                                                                            value="1" 
                                                                            className="check_auth all_1" 
                                                                            checked="" 
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">
                                                                                Select All
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_1"
                                                                                data-access="1"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Customer Dashboard
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[93]"
                                                                                value="1"
                                                                                className="check_auth all_1"
                                                                            
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="2">
                                                                <h5 className="m-0 font-size-15 ">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 2 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_2"
                                                                        aria-expanded={openCollapse === 2 ? 'true' : 'false'}
                                                                        aria-controls="href_2"
                                                                        onClick={() => handleCollapseToggle(2)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #2 - Member
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 2 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_2"
                                                                className={`collapse ${openCollapse === 2 ? 'show' : ''}`}
                                                                aria-labelledby="2"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Member
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[2]"
                                                                                value="1"
                                                                                className="check_auth all_2"
                                                                                checked=""
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">
                                                                                Select All
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_2"
                                                                                data-access="2"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Manage Member
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[3]"
                                                                                value="1"
                                                                                className="check_auth all_2"
                                                                               
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add Member
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[52]"
                                                                                value="1"
                                                                                className="check_auth all_2"
                                                                               
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            edit Member
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[53]"
                                                                                value="1"
                                                                                className="check_auth all_2"
                                                                               
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            view Member
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[54]"
                                                                                value="1"
                                                                                className="check_auth all_2"
                                                                               
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="7">
                                                                <h5 className="m-0 font-size-15 ">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 7 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_7"
                                                                        aria-expanded={openCollapse === 7 ? 'true' : 'false'}
                                                                        aria-controls="href_7"
                                                                        onClick={() => handleCollapseToggle(7)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #3 - Support
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 7 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_7"
                                                                className={`collapse ${openCollapse === 7 ? 'show' : ''}`}
                                                                aria-labelledby="7"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Support
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[7]"
                                                                                value="1"
                                                                                className="check_auth all_7"
                                                                                checked=""
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">
                                                                                Select All
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_7"
                                                                                data-access="7"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Raise Complaint
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[8]"
                                                                                value="1"
                                                                                className="check_auth all_7"
                                                                               
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Contact Information
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[10]"
                                                                                value="1"
                                                                                className="check_auth all_7"
                                                                               
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add raise Complaint
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[56]"
                                                                                value="1"
                                                                                className="check_auth all_7"
                                                                               
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            view Complaint Summary
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[57]"
                                                                                value="1"
                                                                                className="check_auth all_7"
                                                                               
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            DTH Customer Care Info
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[120]"
                                                                                value="1"
                                                                                className="check_auth all_7"
                                                                                
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="11">
                                                                <h5 className="m-0 font-size-15 ">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 11 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_11"
                                                                        aria-expanded={openCollapse === 11 ? 'true' : 'false'}
                                                                        aria-controls="href_11"
                                                                        onClick={() => handleCollapseToggle(11)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #4 - Transaction
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 11 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_11"
                                                                className={`collapse ${openCollapse === 11 ? 'show' : ''}`}
                                                                aria-labelledby="11"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Transaction
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[11]"
                                                                                value="1"
                                                                                className="check_auth all_11"
                                                                                checked=""
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">
                                                                                Select All
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_11"
                                                                                data-access="11"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Transaction History
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[12]"
                                                                                value="1"
                                                                                className="check_auth all_11"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            transaction history search
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[107]"
                                                                                value="1"
                                                                                className="check_auth all_11"
                                                                                
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="15">
                                                                <h5 className="m-0 font-size-15 ">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 15 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_15"
                                                                        aria-expanded={openCollapse === 15 ? 'true' : 'false'}
                                                                        aria-controls="href_15"
                                                                        onClick={() => handleCollapseToggle(15)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #5 - Wallet
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 15 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_15"
                                                                className={`collapse ${openCollapse === 15 ? 'show' : ''}`}
                                                                aria-labelledby="15"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Wallet
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[15]"
                                                                                value="1"
                                                                                className="check_auth all_15"
                                                                                checked=""
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">
                                                                                Select All
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_15"
                                                                                data-access="15"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Wallet Summery
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[16]"
                                                                                value="1"
                                                                                className="check_auth all_15"
                                                                               
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Fund Transfer
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[17]"
                                                                                value="1"
                                                                                className="check_auth all_15"
                                                                               
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Fund Request
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[18]"
                                                                                value="1"
                                                                                className="check_auth all_15"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add fund transfer
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[59]"
                                                                                value="1"
                                                                                className="check_auth all_15"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            send fund request
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[60]"
                                                                                value="1"
                                                                                className="check_auth all_15"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Fund Credit
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[201]"
                                                                                value="1"
                                                                                className="check_auth all_15"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Fund Debit
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[202]"
                                                                                value="2"
                                                                                className="check_auth all_15"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add Money
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[253]"
                                                                                value="1"
                                                                                className="check_auth all_15"
                                                                                
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="20">
                                                                <h5 className="m-0 font-size-15 ">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 20 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_20"
                                                                        aria-expanded={openCollapse === 20 ? 'true' : 'false'}
                                                                        aria-controls="href_20"
                                                                        onClick={() => handleCollapseToggle(20)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #6 - Profile
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 20 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_20"
                                                                className={`collapse ${openCollapse === 20 ? 'show' : ''}`}
                                                                aria-labelledby="20"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Profile
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[20]"
                                                                                value="1"
                                                                                className="check_auth all_20"
                                                                                checked=""
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">
                                                                                Select All
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_20"
                                                                                data-access="20"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Update Profile
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[21]"
                                                                                value="1"
                                                                                className="check_auth all_20"
                                                                               
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Change Password
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[22]"
                                                                                value="1"
                                                                                className="check_auth all_20"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            My Commission
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[23]"
                                                                                value="1"
                                                                                className="check_auth all_20"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Update Profile
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[172]"
                                                                                value="1"
                                                                                className="check_auth all_20"
                                                                                
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="33">
                                                                <h5 className="m-0 font-size-15 ">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 33 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_33"
                                                                        aria-expanded={openCollapse === 33 ? 'true' : 'false'}
                                                                        aria-controls="href_33"
                                                                        onClick={() => handleCollapseToggle(33)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #7 - Utilities
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 33 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_33"
                                                                className={`collapse ${openCollapse === 33 ? 'show' : ''}`}
                                                                aria-labelledby="33"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Utilities
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[33]"
                                                                                value="1"
                                                                                className="check_auth all_33"
                                                                                checked=""
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">
                                                                                Select All
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_33"
                                                                                data-access="33"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Bank Account Master
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[34]"
                                                                                value="1"
                                                                                className="check_auth all_33"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Add Bank Account
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[86]"
                                                                                value="1"
                                                                                className="check_auth all_33"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Edit Bank Account
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[87]"
                                                                                value="1"
                                                                                className="check_auth all_33"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Delete Bank Account
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[88]"
                                                                                value="1"
                                                                                className="check_auth all_33"
                                                                                
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="116">
                                                                <h5 className="m-0 font-size-15 ">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 116 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_116"
                                                                        aria-expanded={openCollapse === 116 ? 'true' : 'false'}
                                                                        aria-controls="href_116"
                                                                        onClick={() => handleCollapseToggle(116)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #8 - Report
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 116 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_116"
                                                                className={`collapse ${openCollapse === 116 ? 'show' : ''}`}
                                                                aria-labelledby="116"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Report
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[116]"
                                                                                value="1"
                                                                                className="check_auth all_116"
                                                                                checked=""
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">
                                                                                Select All
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_116"
                                                                                data-access="116"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Operatorwise Commission Report
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[117]"
                                                                                value="1"
                                                                                className="check_auth all_116"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            My Earning
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[143]"
                                                                                value="1"
                                                                                className="check_auth all_116"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Credit Debit Report
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[146]"
                                                                                value="1"
                                                                                className="check_auth all_116"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Refund Transaction Report
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[149]"
                                                                                value="1"
                                                                                className="check_auth all_116"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Fund Request Report
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[168]"
                                                                                value="1"
                                                                                className="check_auth all_116"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Downline Transaction
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[179]"
                                                                                value="1"
                                                                                className="check_auth all_116"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Downline Wallet
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[180]"
                                                                                value="1"
                                                                                className="check_auth all_116"
                                                                                
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            Print Fund Req. Invoice
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[184]"
                                                                                value="1"
                                                                                className="check_auth all_116"
                                                                                
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6 mb-2">
                                                        <div className="card mb-0">
                                                            <div className="card-header bg-light" id="170">
                                                                <h5 className="m-0 font-size-15 ">
                                                                    <a
                                                                        className={`d-block pt-2 pb-2 text-dark ${openCollapse === 170 ? '' : 'collapsed'}`}
                                                                        data-toggle="collapse"
                                                                        href="#href_170"
                                                                        aria-expanded={openCollapse === 170 ? 'true' : 'false'}
                                                                        aria-controls="href_170"
                                                                        onClick={() => handleCollapseToggle(170)}
                                                                        style={{ fontWeight: '400' }}
                                                                    >
                                                                        #9 - Recharge Panel
                                                                        <span className="float-right">
                                                                            <i className={`mdi mdi-chevron-${openCollapse === 170 ? 'up' : 'down'} accordion-arrow`}></i>
                                                                        </span>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div
                                                                id="href_170"
                                                                className={`collapse ${openCollapse === 170 ? 'show' : ''}`}
                                                                aria-labelledby="170"
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">

                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-1">
                                                                            Recharge Panel
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">

                                                                            <input
                                                                                type="checkbox"
                                                                                name="autho[170]"
                                                                                value="1"
                                                                                className="check_auth all_170"
                                                                                checked=""
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4 mt-1">
                                                                            <span className="float-right">
                                                                                Select All
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2 mt-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="select_all all_170"
                                                                                data-access="170"
                                                                                value="0"
                                                                                checked={selectAllChecked}
                                                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <input type="hidden" name="securityToken" value="9e6625d817327095f22d8f3032b15ada" />
                                    <div className="text-center">
                                        <button className="btn btn-sm btn-info" fdprocessedid="x9ycrj">Update</button>
                                    </div>
                                </form>

                            </div>

                        </div>

                    </div>

                </section>

            </div>



            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>

        </>
    )
}

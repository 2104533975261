import React from 'react';
import Navbar from './Navbar';
import { useState, useEffect } from 'react';
import RestService from '../http';
import { ToastContainer } from 'react-toastify';
import showToast from './utilityComponents/APPToast';
import Loader from './Loader';
import Select from 'react-select';
import { Link } from 'react-router-dom';


export default function AdminInsurance() {

    const service = new RestService;
    const [showAlert, setShowAlert] = useState(true);
    const [userId, setUserId] = useState("");
    const [userList, setUserList] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mobile, setMobile] = useState('');

    const handleAlertClose = () => {
        setShowAlert(false);
    };


    const handleCustomerType = (e) => {
        console.log(e.target.value);
        service
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setUserList(result.data);
                } else {
                    setUserList([]);
                }
            })
            .catch((error) => {
                console.error(error);
                setUserList([]);

            });
    };


    useEffect(() => {
        service.get("user/roles").then(result => {
            console.log(result);
            if (result.role === undefined || result.role === null) {
                setRoles([]);
            } else {
                setRoles(result.role);
            }

        }).catch((error) => {
            console.log(error);
        });
    }, []);


    const [selectedUserId, setSelectedUserId] = useState(null);

    const options = userList.map((item) => ({
        value: item.mobile,
         label: `${item.name} || ${item.mobile}`,
    }));

    const handleSelectChange = (selectedMobile) => {
        setSelectedUserId(selectedMobile);
        setMobile(selectedMobile.value);
    };

    const handleAdminInsurance = async () => {
        setLoading(true);

        const result = await service.post(`insurance/generateInsuranceByAdmin/${mobile}`, null);
         setLoading(false);

        console.log(result);
        if(result.status === service.SUCCESS) {
            showToast("SUCCESS", result.message);
            window.open(result.data, '_blank');

        } else {
            showToast("DANGER", result.message);
        }
    }

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "620.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Admin Insurance</h1>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Insurance</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Admin Insurance</li>
                                </ol>
                            </div>

                        </div>

                    </div>

                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card col-6 m-auto">
                            <div className="card-body">

                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div action="/changepaswrd">
                                        <div className="row"></div>

                                        <div className='row'>
                                            <div className="col-12 mt-5">
                                                <label for="assign_commission">
                                                    User Type: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleCustomerType}
                                                >
                                                    <option>Select User Type</option>
                                                    {roles.map((item) => (
                                                        item.roleId === 5 ? <option value={item.roleId}>{item.roleName}</option> : null
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12" style={{ marginTop: '10px' }}>
                                                <label for="service_id">
                                                    User Id: <span className="text-danger"> *</span>
                                                </label>
                                                {/* <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setUserId(e.target.value)}
                                                >
                                                    <option value="">Select Id...</option>
                                                    {userList.map((item) => (
                                                        <option value={item.userId}>{item.name}</option>
                                                    ))}
                                                </select> */}

                                                <Select
                                                    className="select-box width100p"
                                                    options={options}
                                                    isSearchable
                                                    value={selectedUserId}
                                                    onChange={handleSelectChange}
                                                />
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-12 text-center px-3" style={{ marginTop: '10px' }}>
                                                <input
                                                    onClick={handleAdminInsurance}
                                                    name=""
                                                    value="SUBMIT"
                                                    className="btn btn-primary" fdprocessedid="0rzuuq"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import RestService from "../http";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import formatDate from './utilityComponents/FormatDate';
import DatePicker from "react-datepicker";
import showToast from './utilityComponents/APPToast';
import Loader from './Loader';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Pagination2 from './utilityComponents/Pagination2';
import * as XLSX from 'xlsx';
import { FaFileExcel } from 'react-icons/fa';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

};

export default function ViewAllActiveInactive() {

    const navigate = useNavigate();

    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();

    const [userData, setUserData] = useState([]);
    const service = new RestService();
    const [date, setDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [serviceList, setServiceList] = useState([]);
    const [serviceId, setServiceId] = useState("");
    const [status, setStatus] = useState("");
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 10;
    const itemsPerPage = 10;


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };


    const handleAllActiveInactiveUser = () => {
        var raw = {
            "date": formatDate(date),
            "serviceId": serviceId
        };

        setLoading(true);

        service.post("user/viewAllActiveInactiveUserForAdmin", raw).then(result => {
            setLoading(false);

            console.log(result);

            if (result.status === service.SUCCESS) {

                setUserData(result.data);

            } else {
                showToast("DANGER", result.message)
                setUserData([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    };


    useEffect(() => {
        service.post("service/getAllService", null).then(result => {
            if (result.status === service.SUCCESS) {
                setServiceList(result.report);
            } else {
                setServiceList([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [])

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);


    const getPaginatedData = () => {
        const startIndex = currentPage * pageSize;
        const endIndex = startIndex + pageSize;
        return userData.slice(startIndex, endIndex);
    };

    useEffect(() => {
        handleAllActiveInactiveUser();
    }, [currentPage, pageSize]);


    const downloadExcel = () => {
        // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
        // writeFile(wb, 'recharge_report.xlsx');

        const ws = XLSX.utils.json_to_sheet(userData);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'All User Data Report');

        XLSX.writeFile(wb, 'alluserData.xlsx');
    };

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}


            <div className="content-wrapper" style={{ minHeight: "620.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">All Active/Inactive Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">All Active/Inactive Users</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Active/Inactive Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label for="rec_to_load">Date</label><br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label>Service Id</label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setServiceId(e.target.value)}
                                                >
                                                    <option>Select Service Id</option>
                                                    {serviceList.map((service) => (
                                                        <option value={service.serviceType}>{service.serviceName}</option>
                                                    ))}

                                                </select>
                                            </div>

                                            <div className="col-md-3">
                                                <label>
                                                    Status <span className="text-danger"> *</span>
                                                </label>

                                                <select
                                                    className="custom-date-picker"
                                                    name="customer_type"
                                                    id="customer_type"
                                                    fdprocessedid="g30y5e"
                                                    onChange={(e) => setStatus(e.target.value)}
                                                >
                                                    <option value={""}>SELECT STATUS</option>
                                                    <option value={"ACTIVE"}>ACTIVE</option>
                                                    <option value={"INACTIVE"}>INACTIVE</option>
                                                </select>
                                            </div>

                                            <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                                            <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ marginTop: '10px', textAlign: 'center' }}>
                                                <button
                                                    className="btn btn-primary btn-block"
                                                    style={{ marginTop: '20px' }}
                                                    onClick={handleAllActiveInactiveUser}
                                                >
                                                    SEARCH
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">

                                    <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ float: "right" }}>
                                        <button type="button" name="" className="btn btn-success" onClick={downloadExcel} fdprocessedid="9svl7o">
                                            <span>
                                                Export<FaFileExcel className="fa fa-file-excel-o" />
                                            </span>
                                        </button>
                                    </div>

                                    <table className="table table-striped table-bordered mt-5">
                                        <thead>
                                            <tr className="wrap-text text-center bg-blue">
                                                <th style={{ color: "white", fontWeight: "bold" }}>#</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>User Name</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Mobile</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Status</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>View</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userData.length === 0 ? (
                                                <tr>
                                                    <td colSpan="5" className='text-center'>NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (

                                                getPaginatedData().map((item, index) => (
                                                    // userData.map((item, index) => (
                                                    <tr key={index} className='text-center'>
                                                        <td>{index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{item.status}</td>
                                                        <td>
                                                            {item.status === "ACTIVE" && (
                                                                <button className='btn bg-green' onClick={handleOpen}>View</button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            )}

                                        </tbody>
                                    </table>

                                    <div
                                        className="dataTables_paginate paging_simple_numbers"
                                        id="example1_paginate"
                                        style={{ float: "right" }}
                                    >
                                        <Pagination2
                                            totalPages={Math.ceil(userData.length / itemsPerPage)}
                                            currentPage={currentPage}
                                            onChange={handlePageChange}

                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style} style={{ borderRadius: "10px" }}>
                        {/* <button className='btn' style={{float:"right"}} onClose={handleClose}>Close</button><br/><br/> */}

                        <h3 className='text-center' style={{ fontFamily: "auto", fontWeight: "bold" }}>All Active Users</h3>
                        <hr />

                        <div className="table-responsive mt-4">
                            <table className="table table-striped table-bordered p-2">
                                <thead>
                                    <tr className="wrap-text text-center bg-blue">
                                        <th style={{ color: "white", fontWeight: "bold" }}>#</th>
                                        <th style={{ color: "white", fontWeight: "bold" }}>User Name</th>
                                        <th style={{ color: "white", fontWeight: "bold" }}>Date</th>
                                        <th style={{ color: "white", fontWeight: "bold" }}>Day</th>
                                        <th style={{ color: "white", fontWeight: "bold" }}>Mobile</th>
                                        <th style={{ color: "white", fontWeight: "bold" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {getPaginatedData().map((item, index) => (
                                        <React.Fragment key={index}>

                                            {item.data.map((subItem, subIndex) => (
                                                <tr key={subIndex} className='text-center'>
                                                    <td>{subIndex + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{subItem.date}</td>
                                                    <td>{subItem.day}</td>
                                                    <td>{item.mobile}</td>
                                                    <td>{subItem.status}</td>
                                                </tr>
                                            ))
                                            }
                                        </React.Fragment>

                                    ))}
                                </tbody>

                                <div
                                    className="dataTables_paginate paging_simple_numbers"
                                    id="example1_paginate"
                                    style={{ float: "right" }}
                                >
                                    <Pagination2
                                        totalPages={Math.ceil(userData.length / itemsPerPage)}
                                        currentPage={currentPage}
                                        onChange={handlePageChange}

                                    />
                                </div>
                            </table>

                        </div>
                    </Box>
                </Modal>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBContainer, MDBTable, MDBTableHead, MDBTableBody
} from "mdb-react-ui-kit";
import { FaFilePdf } from "react-icons/fa6";


const AadharpaySlip = ({ data, type, onClick }) => {

    const handlePrintRow = (rowData) => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
          <html>
            <head>
              <title>Print</title>
              <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; }
                th, td { border: 1px solid #000; padding: 5px; }
                th { background-color: #f2f2f2; }
              </style>
            </head>
            <body>
              <h2>Receipt Slip</h2>
              <table>
                <tbody>
                  <tr><th>IIN</th><td>${rowData.iin}</td></tr>
                  <tr><th>Aadhar</th><td>${rowData.aadhar}</td></tr>
                  <tr><th>Mobile</th><td>${rowData.mobile}</td></tr>
                  <tr><th>Transaction Amount</th><td>${rowData.amount}</td></tr>
                  <tr><th>Type</><td>${rowData.type}</td><tr/>
                  <tr><th>Remaining Bal</><td>${rowData.remainingbal}</td><tr/>
                  <tr><th>RRN</><td>${rowData.rrn}</td><tr/>
                  <tr><th>Res Mesg.</><td>${rowData.responsemessage}</td><tr/>
                  <tr><th>Merchant Code</><td>${rowData.merchantcode}</td><tr/>
                  <tr><th>Terminal Id</><td>${rowData.terminalid}</td><tr/>
                  <tr><th>Err mesg.</><td>${rowData.errormessage}</td><tr/>
                  <tr><th>Req Id</><td>${rowData.requestid}</td><tr/>
                  <tr><th>Status</><td>${rowData.status}</td><tr/>
                </tbody>
              </table>
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.print();
    };

    return (
        <>
            <MDBContainer style={{ width: "25%", height: "100vh", marginTop: "30%", background: "cover" }}>
                <MDBCard>
                    <MDBCardBody className="mx-4" style={{ flex: "1" }}>
                        <MDBContainer>
                            {/* Bank Logo */}
                            <div className="logo-container" style={{ background: "#151e39", backgroundSize: 'cover', width: '132%', height: '150px', marginLeft: "-16%", marginTop: "-6%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src="./image/YES Bank Logo.webp" alt="Bank Logo" style={{ width: '25%', borderRadius: "50%", display: "flex", justifyContent: "center" }} />
                                {/* <div className="amount-text" style={{ marginTop: "25%", zIndex: '2', color: 'white', fontSize: '18px', display:"flex", justifyContent:"center", marginLeft:"-16%"}}>
                                    ₹{data.remainingbal}
                                </div> */}
                            </div>

                            <div style={{ display: 'inline-block', position: 'relative', display: "flex", justifyContent: "center" }}>
                                <div className="dashed-line" style={{ position: 'absolute', width: "23vw", top: '60%', left: '-41px', transform: 'translateY(-50%)', borderTop: '2px dashed', color: data.status === 'SUCCESS' ? 'green' : 'red', zIndex: '0' }}></div>

                                <button className="btn mt-3"
                                    style={{ borderRadius: "15px", position: 'relative', zIndex: '1', justifyContent: "center", display: "flex", color: "white", backgroundColor: data.transactionStatus === 'SUCCESS' ? 'green' : 'red' }}
                                >
                                    {data.status}
                                </button>
                            </div><br />

                            <p className="text-center" style={{ fontWeight: "bold" }}>Aadhar Pay</p>

                            <MDBTable style={{ tableLayout: 'fixed', width: '100%', overflowY: "scroll" }}>
                                <MDBTableBody>
                                    <React.Fragment>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Name</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', fontSize:"10px" }}>{data.merchantName}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Aadhar</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.aadharNo}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Bank Name</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', fontSize:"10px" }}>{data.bankName}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Type</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.transactionType}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Txn. Amount</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.transactionAmount}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Txn. Time</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.requestTransactionTime}</td>
                                        </tr>


                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Remaining Bal.</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.balanceAmount}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>RRN</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.bankRRN}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Response Code</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.responseCode}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Merchant Id</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.merchantTxnId}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Terminal Id</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.terminalId}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Message</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.remark}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Txn.Id</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.fpTransactionId}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Status</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.status}</td>
                                        </tr>

                                    </React.Fragment>

                                    <td style={{ fontWeight: "bold", maxWidth: "100px" }}>Remark:</td>
                                    <td className="text-end" style={{ color: data.status === 'SUCCESS' ? 'green' : 'red' }}>{data.remark}</td>

                                </MDBTableBody>
                            </MDBTable>

                            <div style={{ float: "right" }}>
                                <button className="btn bg-blue" onClick={onClick}>Back</button>&nbsp;
                                <button className="btn bg-red" onClick={() => handlePrintRow(data)}><FaFilePdf /></button>
                            </div>

                        </MDBContainer>
                    </MDBCardBody>

                </MDBCard>
            </MDBContainer>

        </>
    );

};

export default AadharpaySlip;

import React from "react";
import { useState } from "react";
import RestService from "../../http";


const CommisionTable = ({ data, serviceName, handleUpdate }) => {

    const rest = new RestService();
    
    return (
        <>
            <div>
                <h3 className="text-center" style={{ fontWeight: "bold" }}>{serviceName}</h3>
            </div>
            <hr />
            <table className="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Operator Name</th>
                        <th>Operator Incode</th>
                        <th>Operator OutCode</th>
                        <th>Commission</th>
                        <th>Commission Type</th>
                        <th className="px-1">Slabs</th>
                        <th>Is Charge</th>
                        <th>Package Id</th>
                        <th>Plan Id</th>
                        <th>Plan Name</th>
                        <th>Role Id</th>
                        <th>Service Id</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {data.map((item, index) => ( */}
                    {data.length > 0 ? (
                        data.map((item, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{item.operatorName}</td>
                                <td>{item.operatorIncode}</td>
                                <td>{item.operatorOutcode}</td>
                                <td>{item.commission}</td>
                                <td>{item.commissionType}</td>
                                <td>{`(${item.slab1}-${item.slab2})`}</td>
                                <td>{item.isCharge}</td>
                                <td>{item.packageId}</td>
                                <td>{item.planId}</td>
                                <td>{item.planName}</td>
                                <td>{rest.roles[item.roleId]}</td>
                                <td>{serviceName}</td>
                                <td>
                                    <button className="btn bg-green" style={{ border: "none", cursor: "pointer", color: "white" }}
                                        onClick={handleUpdate}
                                    >
                                        Update
                                    </button>
                                </td>
                            </tr>
                        ))
                        ) : (
                            <td colSpan={14} align="center">
                                NO DATA AVAILABLE
                            </td>
                        )
                        }
                </tbody>
            </table>
        </>
    );

};

export default CommisionTable;

import React, { useEffect } from "react";
import Navbar from "./Navbar";
import { useState } from "react";
import RestService from "../http";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import showToast from "./utilityComponents/APPToast";
import Loader from "./Loader";
import { Link } from "react-router-dom";


export default function AddCommCharge() {
    const service = new RestService();

    const [userList, setUserList] = useState([]);
    const [planList, setPlanList] = useState([]);
    const [planId, setPlanId] = useState('');
    const [assignUserList, setAssignUserList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [incode, setIncode] = useState("");
    const [outCode, setOutCode] = useState("");
    const [commission, setCommission] = useState("");
    const [commissionType, setCommissionType] = useState('');
    const [operatorList, setOperatorList] = useState([]);
    const [packageId, setPackageId] = useState('');
    const [slabList, setSlabList] = useState([]);
    const [serviceCode, setServiceCode] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [commissionData, setCommissionData] = useState(null);
    const [roles, setRoles] = useState([]);
    const [customerType, setCustomerType] = useState();
    const [slab1, setSlab1] = useState('');
    const [slab2, setSlab2] = useState('');
    const [charge, setCharge] = useState("");
    const [userType, setUserType] = useState('');
    const [operatorName, setOperatorName] = useState("");
    const [operatorInCode, setOperatorIncode] = useState("");
    const [loading, setLoading] = useState(false);


    const handlePlanId = (e) => {
        setPlanId(e.target.value);
    }


    const handleUserType = (e) => {
        service.post(`user/getUpline/${e.target.value}`, null).then(result => {
            setUserList(result.report === undefined ? [] : result.report);
        }).catch((error) => {
            console.log(error);
        });
    }


    const handlePlanList = () => {
        service.post("plans/getAllPlans", null).then(result => {
            if (result.status === service.SUCCESS) {
                setPlanList(result.report);
            } else {
                setPlanList([]);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        handlePlanList();
    }, []);

    const handleServiceId = (e) => {
        setServiceCode(e.target.value);
        fetchOperatorSlab(e.target.value);

        service.post(`operator/fetchOperatorByService/${e.target.value}`, null).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                setOperatorList(result.report);
            } else {
                setOperatorList([]);
            }
        })
            .catch((error) => {
                console.log(error);
            });

    }


    const handleAssignUserType = (e) => {
        
        service.post(`package/fetchUserGroup?userType=${e.target.value}`, null).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setAssignUserList(result.data);
            } else {
                setAssignUserList([]);
            }
        }).catch((error) => {
            console.log(error);
        });
    }


    useEffect(() => {
        service.get("user/roles").then(result => {
            console.log(result);
            if (result.role === undefined) {
                setRoles([])
            } else {
                setRoles(result.role);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const handleServiceCode = () => {

        service.post("service/getAllService", null).then((result) => {
            if (result.report === undefined) {
                setServiceList([]);
            } else {
                console.log(result);
                setServiceList(result.report);

            }
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        handleServiceCode();
    }, []);

    const handleIncode = (rawData) => {
        const data = JSON.parse(rawData);
        console.log(data);

        setIncode(data.operatorIncode);
        setOutCode(data.operatorOutcode);
        setOperatorName(data.operatorName);
        // fetchOperatorSlab(data.serviceType);
    }


    const handleSlabs = (slabObj) => {
        // setSlabList(e.target.value);

        const data = JSON.parse(slabObj);
        setSlab1(data.slab1);
        setSlab2(data.slab2);
    }

    const handleCommission = (e) => {
        setCommission(e.target.value);
    }

    const handleCommissionType = (e) => {
        setCommissionType(e.target.value);
    }

    const fetchOperatorSlab = (serviceCode) => {

        service.post(`commission/fetchSlabByService/${serviceCode}`, null).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                setSlabList(result.data);
            } else {
                setSlabList([]);
            }

        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        fetchOperatorSlab();
    }, [])


    const handleUpdate = (data) => {
        setCommissionData(data);
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setCommissionData(null);
        setShowModal(false);
    };


    const handleCustomerType = (e) => {
        console.log(e.target.value);
        setCustomerType(e.target.value)
    }

    const handleCustomerTypeChange = () => {
        service.get("user/roles", null).then(result => {
            if (result.role === undefined) {
                setRoles([]);
            } else {
                const filteredRoles = result.role.filter((role) => [1, 2, 3, 4, 5].includes(role.roleId));
                setRoles(filteredRoles);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        handleCustomerTypeChange()
    }, [])


    const handleCommissionUpdate = () => {

        var raw = {
            "commission": commission,
            "operatorIncode": operatorInCode,
            "isCharge": charge,
            "operatorOutcode": operatorName,
            "packageId": packageId,
            "planId": planId,
            "roleId": userType,
            "slab1": slab1,
            "slab2": slab2,
            "commissionType": commissionType

        };

        console.log(raw);
        setLoading(true);

        service.post("commission/addCommissionCharge", raw).then(result => {
            setLoading(false);
            
            console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                // setTimeout(() => {
                //     window.location.reload();
                // }, 2000);

            } else {
                showToast("DANGER", result.message);
            }
        }).catch((error) => {
            console.log(error);
        });
    }


    const handleOperatorCode = (operatorData) => {

        const obj = JSON.parse(operatorData);

        setOperatorIncode(obj.operatorIncode);
        setOperatorName(obj.operatorOutcode);
    }

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}


            <div className="content-wrapper" style={{ minHeight: "600.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">ADD COMMISSION CHARGE</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Settings</Link>
                                    </li>
                                    <li className="breadcrumb-item active"> CHARGE</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="card" id="addpack">
                        <div className="card-body">
                            <div>
                                <div className="row" data-select2-id="4">

                                    <div className="col-md-3" data-select2-id="4">
                                        <label for="assign_commission">
                                            Select Type: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={(e) => setUserType(e.target.value)}
                                        >
                                            <option>Select User Type</option>
                                            {roles.map((item) => (
                                                item.roleId !== 0 ? <option value={item.roleId}>{item.roleName}</option> : null
                                            ))}
                                        </select>
                                    </div>


                                    <div className="col-md-3" data-select2-id="4">
                                        <label for="assign_commission">
                                            Fetch Package: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={handleAssignUserType}
                                        >
                                            <option>Select User Type</option>
                                            <option value={1}>API USER</option>
                                            <option value={2}>MASTER ADMIN</option>
                                        </select>
                                    </div>

                                    <div className="col-md-3" data-select2-id="4">
                                        <label for="assign_commission">
                                            Package Id: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={(e) => setPackageId(e.target.value)}
                                        >
                                            <option>Select Package</option>
                                            {assignUserList.map((item) => (
                                                <option value={item.packageId}>{item.name}</option>
                                            ))}

                                        </select>
                                    </div>

                                    <div className="col-md-3" data-select2-id="4">
                                        <label for="service_id">
                                            Plan Id: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={handlePlanId}
                                        >
                                            <option>Select Plan Id</option>
                                            {planList.map((item) => (
                                                <option key={item.planId} value={item.planId}>
                                                    {item.planName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-md-3 mt-2" data-select2-id="4">
                                        <label for="service_id">
                                            Service Type: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={handleServiceId}
                                        >
                                            <option>Select Id</option>
                                            {serviceList.map((item) => (
                                                <option key={item.serviceType} value={item.serviceType}>{item.serviceName}</option>
                                            ))}

                                        </select>
                                    </div>


                                    <div className="col-md-3 mt-2" data-select2-id="4">
                                        <label for="service_id">
                                            Operator: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={(e) => handleOperatorCode(e.target.value)}
                                        >
                                            <option value="{}">SELECT OPERATOR</option>
                                            {operatorList.length > 0 ?
                                                operatorList.map((item) => (
                                                    <option value={JSON.stringify(item)}>{item.operatorName}</option>
                                                )) : <></>}

                                        </select>
                                    </div>

                                    <div className="col-md-3 mt-2" data-select2-id="4">
                                        <label for="service_id">
                                            Slabs: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={(e) => handleSlabs(e.target.value)}
                                        >
                                            <option>Slabs</option>
                                            {slabList.map((item) => (
                                                <option value={JSON.stringify(item)}>{`(${item.slab1}-${item.slab2})`}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-md-3 mt-2" data-select2-id="4">
                                        <label for="service_id">
                                            Commission: <span className="text-danger"> *</span>
                                        </label>
                                        <input
                                            placeholder="commission"
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            aria-hidden="true"
                                            onChange={handleCommission}
                                        />
                                    </div>

                                    <div className="col-md-3 mt-2" data-select2-id="4">
                                        <label for="service_id">
                                            Commission Type: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            name="service_id"
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={handleCommissionType}
                                        >
                                            <option>select commission type</option>
                                            <option value={"FLAT"}>FLAT</option>
                                            <option value={"PERC"}>PERCENTAGE</option>

                                        </select>
                                    </div>

                                    <div className="col-md-3 mt-2" data-select2-id="4">
                                        <label for="service_id">
                                            Is Charge?: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            name="service_id"
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={(e) => setCharge(e.target.value)}
                                        >
                                            <option value="">select</option>
                                            <option value={true}>YES</option>
                                            <option value={false}>NO</option>

                                        </select>
                                    </div>

                                    {/* <div className="col-md-3 mt-5">
                                                <label>Flat:</label>&nbsp;&nbsp;&nbsp;
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                </label>
                                            </div> */}

                                </div>
                            </div>
                        </div>
                        <div className="text-center mb-2">
                            <input
                                type="submit"
                                className="btn btn-primary btn-hover text-white"
                                value="Add Comm Charge"
                                onClick={handleCommissionUpdate}
                            />
                        </div>
                    </div>

                </section >
            </div >

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    );
}

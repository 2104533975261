import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Smsapi() {

    const isCollapseOpen = true;

    const tableData = [

        {
            id: 1,
            url: 'https://example.com/api/sms',
            senderId: 'EXAMPLE',
            status: 'Active',
            type: 'POST',
            statusKey: 'statusCode',
            successValue: '200',
            api: 'sms',
        },
        // Add more data as needed...
    ];

    const [selectedOptions, setSelectedOptions] = useState(['TEXT']);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const options = ['TEXT', 'WHATSAPP'];

    const handleOptionSelect = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions((prevOptions) => prevOptions.filter((item) => item !== option));
        } else {
            setSelectedOptions((prevOptions) => [...prevOptions, option]);
        }
    };

    const toggleDropdown = () => {
        setDropdownOpen((prev) => !prev);
    };


    const [isOpen, setIsOpen] = useState(false);
    const [apiUrl, setApiUrl] = useState('');
    const [senderId, setSenderId] = useState('');
    const [apiType, setApiType] = useState('1');
    const [responseType, setResponseType] = useState('1');
    const [separatorChar, setSeparatorChar] = useState('');
    const [statusKey, setStatusKey] = useState('');
    const [successValue, setSuccessValue] = useState('');

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // ... (rest of the form submission logic)
        closeModal();

        console.log({
            template_name: templateName,
            template: template,
            template_id: templateId,
            sms_type: smsTypes,
            status: status,
        });
    };


    const [templateName, setTemplateName] = useState('');
    const [template, setTemplate] = useState('');
    const [templateId, setTemplateId] = useState('');
    const [smsTypes, setSmsTypes] = useState([]);
    const [status, setStatus] = useState('');
    const [isOpenn, setIsOpenn] = useState(false);

    const openModall = () => {
        setIsOpenn(true);
      };
    
      const closeModall = () => {
        setIsOpenn(false);
      };

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">SMS Setting</h1>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Setting</Link>
                                    </li>
                                    <li className="breadcrumb-item active">SMS Setting</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div id='accordion'>
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h4 className="card-title float-none">
                                                <a
                                                    data-toggle="collapse"
                                                    data-parent="#accordion"
                                                    href="#collapseOne"
                                                    className={isCollapseOpen ? 'collapsed' : ''}
                                                    aria-expanded={isCollapseOpen}
                                                >
                                                    SMS API
                                                </a>
                                                <Link to="#" data-toggle="modal" className="float-right" onClick={openModal} data-target="#smsapimodal">
                                                    <i className="fa fa-plus"></i> NEW SMS API
                                                </Link>
                                            </h4>
                                        </div>
                                        <div id="collapseOne" className={`panel-collapse collapse ${isCollapseOpen ? 'show' : ''}`}>
                                            <div className="card-body">
                                                <button type="button" className="btn btn-default btn-sm m-1" fdprocessedid="ara4ql">
                                                    <i className="fas fa-share"></i> Variable to use
                                                </button>
                                                <div className="card-footer card-comments mb-2">
                                                    <div className="card-comment">
                                                        <div className="comment-text ml-0">
                                                            <span className="username">
                                                                1. @msg :For the sms text. 2.@mobile :For the mobile number. 3.@senderid :For the sender ID. 4.@templateid :For the template ID
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table m-0 table-bordered table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>URL</th>
                                                                <th>Sender ID</th>
                                                                <th>Status</th>
                                                                <th>Type</th>
                                                                <th>Status Key</th>
                                                                <th>Success Value</th>
                                                                <th>API</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {tableData.map((item) => (
                                                                <tr key={item.id}>
                                                                    <td>{item.id}</td>
                                                                    <td>{item.url}</td>
                                                                    <td>{item.senderId}</td>
                                                                    <td>{item.status}</td>
                                                                    <td>{item.type}</td>
                                                                    <td>{item.statusKey}</td>
                                                                    <td>{item.successValue}</td>
                                                                    <td>{item.api}</td>
                                                                    <td>
                                                                        {/* Add action buttons here, e.g., edit, delete */}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card card-primary">
                                        <div className="card-header">
                                            <h4 className="card-title float-none">
                                                <a
                                                    data-toggle="collapse"
                                                    data-parent="#accordion"
                                                    href="#collapseTwo"
                                                    className={isCollapseOpen ? 'collapsed' : ''}
                                                    aria-expanded={isCollapseOpen}
                                                >
                                                    SMS Template
                                                </a>
                                                <Link to="#" data-toggle="modal" className="float-right" onClick={openModall} data-target="#exampleModalCenter">
                                                    <i className="fa fa-plus"></i> NEW SMS
                                                </Link>
                                            </h4>
                                        </div>

                                        <div id='collapseTwo' className='panel-collapse collapse show'>
                                            <div className='card-body'>
                                                <div className='table-responsive'>
                                                    <table className='table m-0 table-bordered table-striped'>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Template</th>
                                                                <th>SMS</th>
                                                                <th>Template ID</th>
                                                                <th>Send To</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <form action="/smsapi" method="post" accept-charset="utf-8"></form>
                                                            <input type="hidden" name="securityToken" value="796e3b5e25cfcb0f56e216485e7616d3" />

                                                            <tr>
                                                                <td>1</td>
                                                                <input type="hidden" name="id" value="Vjc~" />
                                                                <td style={{ width: '150px' }}>
                                                                    <input type="text" className="form-control" name="template_name" value="REGISTER" required="" />
                                                                </td>
                                                                <td>
                                                                    <textarea className="form-control" name="template" required="">
                                                                        Dear @VAR1, Welcome to Trimurty Softech. Your Login ID: @VAR2 Password: @VAR3 PIN: @VAR4 Never share password / PIN with anyone. Regards Trimurty Softech
                                                                    </textarea>
                                                                </td>
                                                                <td style={{ width: '150px' }}>
                                                                    <input type="text" className="form-control" name="template_id" value="0" required="" />
                                                                </td>
                                                                <td style={{ width: '200px' }} data-select2-id="40">
                                                                    <select className="select2 form-control select2-hidden-accessible" name="sms_type[]" multiple="" data-placeholder="Select Types" data-select2-id="1" tabindex="-1" aria-hidden="true">
                                                                        <option value="1" selected="" data-select2-id="3">TEXT</option>
                                                                        <option value="2" data-select2-id="47">WHATSAPP</option>
                                                                    </select>

                                                                    <span
                                                                        className={`select2 select2-container select2-container--default select2-container--${isDropdownOpen ? 'open' : 'below'
                                                                            }`}
                                                                        dir="ltr"
                                                                        style={{ width: '175.333px' }}
                                                                    >
                                                                        <span
                                                                            className="selection"
                                                                            onClick={toggleDropdown}
                                                                            onKeyDown={(e) => e.key === 'Enter' && toggleDropdown()}
                                                                            tabIndex="0"
                                                                            role="button"
                                                                            aria-haspopup="true"
                                                                            aria-expanded={isDropdownOpen}
                                                                            aria-disabled="false"
                                                                        >
                                                                            <span className="select2-selection select2-selection--multiple">
                                                                                <ul className="select2-selection__rendered">
                                                                                    {selectedOptions.map((option) => (
                                                                                        <li
                                                                                            key={option}
                                                                                            className="select2-selection__choice selected"
                                                                                            title={option}
                                                                                            onClick={() => handleOptionSelect(option)}
                                                                                        >
                                                                                            <span className="select2-selection__choice__remove">×</span>
                                                                                            {option}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </span>
                                                                        </span>
                                                                        {isDropdownOpen && (
                                                                            <span className="dropdown-wrapper" aria-hidden="true">
                                                                                <ul className="select2-results__options" role="tree">
                                                                                    {options.map((option) => (
                                                                                        <li
                                                                                            key={option}
                                                                                            className={`select2-results__option ${selectedOptions.includes(option) ? 'selected' : ''
                                                                                                }`}
                                                                                            title={option}
                                                                                            onClick={() => handleOptionSelect(option)}
                                                                                            role="treeitem"
                                                                                        >
                                                                                            {option}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </span>
                                                                        )}
                                                                    </span>

                                                                </td>
                                                                <td style={{ width: '150px' }}>
                                                                    <select className="form-control text-success" name="status">
                                                                        <option value="1" selected>ACTIVE</option>
                                                                        <option value="0">DEACTIVE</option>
                                                                    </select>
                                                                </td>
                                                                <td className="text-center">
                                                                    <button className="btn btn-primary btn-sm" type="submit">
                                                                        SAVE
                                                                    </button>
                                                                </td>
                                                            </tr>

                                                            <form action="/smsapi" method="post" accept-charset="utf-8"></form>
                                                            <input type="hidden" name="securityToken" value="796e3b5e25cfcb0f56e216485e7616d3" />

                                                            <tr>
                                                                <td>2</td>
                                                                <input type="hidden" name="id" value="VjQ~" />
                                                                <td style={{ width: '150px' }}>
                                                                    <input type="text" className="form-control" name="template_name" value="AMT_CREDIT" required="" />
                                                                </td>
                                                                <td>
                                                                    <textarea className="form-control" name="template" required="">
                                                                        Dear @VAR1 @VAR2, Your account has been credited @VAR3 @VAR4. Your new wallet Balance is @VAR5. Regards. Trimurty Softech
                                                                    </textarea>
                                                                </td>
                                                                <td style={{ width: '150px' }}>
                                                                    <input type="text" className="form-control" name="template_id" value="0" required="" />
                                                                </td>
                                                                <td style={{ width: '200px' }}>
                                                                    <select className="select2 form-control select2-hidden-accessible" name="sms_type[]" multiple="" data-placeholder="Select Types" data-select2-id="5" tabindex="-1" aria-hidden="true">
                                                                        <option value="1">TEXT</option>
                                                                        <option value="2" selected="" data-select2-id="7">WHATSAPP</option>
                                                                    </select>

                                                                    <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="6" style={{ width: '175.333px' }}>
                                                                        <span className="selection">
                                                                            <span className="select2-selection select2-selection--multiple" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="-1" aria-disabled="false">
                                                                                <ul className="select2-selection__rendered">
                                                                                    <li className="select2-selection__choice" title="WHATSAPP" data-select2-id="8">
                                                                                        <span className="select2-selection__choice__remove" role="presentation">×</span>WHATSAPP
                                                                                    </li>
                                                                                    <li className="select2-search select2-search--inline">
                                                                                        <input className="select2-search__field" type="search" tabindex="0" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" placeholder="" style={{ width: '0.75em' }} />
                                                                                    </li>
                                                                                </ul>
                                                                            </span>
                                                                        </span>
                                                                        <span className="dropdown-wrapper" aria-hidden="true"></span>
                                                                    </span>
                                                                </td>
                                                                <td style={{ width: '150px' }}>
                                                                    <select className="form-control text-danger" name="status">
                                                                        <option value="1">ACTIVE</option>
                                                                        <option value="0" selected>DEACTIVE</option>
                                                                    </select>
                                                                </td>
                                                                <td className="text-center">
                                                                    <button className="btn btn-primary btn-sm" type="submit">
                                                                        SAVE
                                                                    </button>
                                                                </td>
                                                            </tr>

                                                            <form action="/smsapi" method="post" accept-charset="utf-8"></form>
                                                            <input type="hidden" name="securityToken" value="796e3b5e25cfcb0f56e216485e7616d3" />

                                                            <tr>
                                                                <td>3</td>
                                                                <input type="hidden" name="id" value="VjU~" />
                                                                <td style={{ width: '150px' }}>
                                                                    <input type="text" className="form-control" name="template_name" value="AMT_DEBIT" required="" />
                                                                </td>
                                                                <td>
                                                                    <textarea className="form-control" name="template" required="">
                                                                        Dear @VAR1 @VAR2, Your account has been Debited @VAR3. Your new wallet Balance is @VAR4. Regards. Trimurty Softech.
                                                                    </textarea>
                                                                </td>
                                                                <td style={{ width: '150px' }}>
                                                                    <input type="text" className="form-control" name="template_id" value="0" required="" />
                                                                </td>
                                                                <td stylewi={{ wdth: '200px' }}>
                                                                    <select className="select2 form-control select2-hidden-accessible" name="sms_type[]" multiple="" data-placeholder="Select Types" data-select2-id="9" tabindex="-1" aria-hidden="true">
                                                                        <option value="1">TEXT</option>
                                                                        <option value="2" selected="" data-select2-id="11">WHATSAPP</option>
                                                                    </select>
                                                                    <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="10" style={{ width: '175.333px' }}>
                                                                        <span className="selection">
                                                                            <span className="select2-selection select2-selection--multiple" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="-1" aria-disabled="false">
                                                                                <ul className="select2-selection__rendered">
                                                                                    <li className="select2-selection__choice" title="WHATSAPP" data-select2-id="12">
                                                                                        <span className="select2-selection__choice__remove" role="presentation">×</span>WHATSAPP
                                                                                    </li>
                                                                                    <li className="select2-search select2-search--inline">
                                                                                        <input className="select2-search__field" type="search" tabindex="0" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" placeholder="" style={{ width: '0.75em' }} />
                                                                                    </li>
                                                                                </ul>
                                                                            </span>
                                                                        </span>
                                                                        <span class="dropdown-wrapper" aria-hidden="true"></span>
                                                                    </span>
                                                                </td>
                                                                <td style={{ width: '150px' }}>
                                                                    <select className="form-control text-danger" name="status">
                                                                        <option value="1">ACTIVE</option>
                                                                        <option value="0" selected>DEACTIVE</option>
                                                                    </select>
                                                                </td>
                                                                <td className="text-center">
                                                                    <button className="btn btn-primary btn-sm" type="submit">
                                                                        SAVE
                                                                    </button>
                                                                </td>
                                                            </tr>

                                                            <form action="/smsapi" method="post" accept-charset="utf-8"></form>
                                                            <input type="hidden" name="securityToken" value="796e3b5e25cfcb0f56e216485e7616d3" />

                                                            <tr>
                                                                <td>4</td>
                                                                <input type="hidden" name="id" value="AGQ~" />
                                                                <td style={{ width: '150px' }}>
                                                                    <input type="text" className="form-control" name="template_name" value="FUND_REQUEST" required="" />
                                                                </td>
                                                                <td>
                                                                    <textarea className="form-control" name="template" required="">
                                                                        Dear @VAR1 @VAR2, Your request for add fund @VAR3 has been approved. Your updated wallet balance is Rs.@VAR4. Regards Trimurty Softech
                                                                    </textarea>
                                                                </td>
                                                                <td style={{ width: '150px' }}>
                                                                    <input type="text" className="form-control" name="template_id" value="0" required="" />
                                                                </td>
                                                                <td style={{ width: '200px' }}>
                                                                    <select className="select2 form-control select2-hidden-accessible" name="sms_type[]" multiple="" data-placeholder="Select Types" data-select2-id="13" tabindex="-1" aria-hidden="true">
                                                                        <option value="1" selected="" data-select2-id="15">TEXT</option>
                                                                        <option value="2" selected="" data-select2-id="16">WHATSAPP</option>
                                                                    </select>
                                                                    <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="14" style={{ width: '175.333px' }}>
                                                                        <span className="selection">
                                                                            <span className="select2-selection select2-selection--multiple" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="-1" aria-disabled="false">
                                                                                <ul className="select2-selection__rendered">
                                                                                    <li className="select2-selection__choice" title="TEXT" data-select2-id="17">
                                                                                        <span className="select2-selection__choice__remove" role="presentation">×</span>TEXT
                                                                                    </li>
                                                                                    <li className="select2-selection__choice" title="WHATSAPP" data-select2-id="18">
                                                                                        <span className="select2-selection__choice__remove" role="presentation">×</span>WHATSAPP
                                                                                    </li>
                                                                                    <li className="select2-search select2-search--inline">
                                                                                        <input className="select2-search__field" type="search" tabindex="0" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" placeholder="" style={{ width: '0.75em' }} />
                                                                                    </li>
                                                                                </ul>
                                                                            </span>
                                                                        </span>
                                                                        <span className="dropdown-wrapper" aria-hidden="true"></span>
                                                                    </span>
                                                                </td>
                                                                <td style={{ width: '150px' }}>
                                                                    <select className="form-control text-success" name="status">
                                                                        <option value="1" selected>ACTIVE</option>
                                                                        <option value="0">DEACTIVE</option>
                                                                    </select>
                                                                </td>
                                                                <td className="text-center">
                                                                    <button className="btn btn-primary btn-sm" type="submit">
                                                                        SAVE
                                                                    </button>
                                                                </td>
                                                            </tr>

                                                            <form action="/smsapi" method="post" accept-charset="utf-8"></form>
                                                            <input type="hidden" name="securityToken" value="796e3b5e25cfcb0f56e216485e7616d3" />

                                                            <tr>
                                                                <td>6</td>
                                                                <input type="hidden" name="id" value="Vj8~" />
                                                                <td style={{ width: '150px' }}>
                                                                    <input type="text" className="form-control" name="template_name" value="UserID_Password" required="" />
                                                                </td>
                                                                <td>
                                                                    <textarea className="form-control" name="template" required="">
                                                                        Hi @VAR1, Your Trimurty Softech Account login ID is : @VAR2 and password is : @VAR3 Never share your password with anyone. Regards Trimurty Softech
                                                                    </textarea>
                                                                </td>
                                                                <td style={{ width: '150px' }}>
                                                                    <input type="text" className="form-control" name="template_id" value="0" required="" />
                                                                </td>
                                                                <td style={{ width: '200px' }}>
                                                                    <select className="select2 form-control select2-hidden-accessible" name="sms_type[]" multiple="" data-placeholder="Select Types" data-select2-id="19" tabindex="-1" aria-hidden="true">
                                                                        <option value="1" selected="" data-select2-id="21">TEXT</option>
                                                                        <option value="2">WHATSAPP</option>
                                                                    </select>
                                                                    <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="20" style={{ width: '175.333px' }}>
                                                                        <span className="selection">
                                                                            <span className="select2-selection select2-selection--multiple" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="-1" aria-disabled="false">
                                                                                <ul className="select2-selection__rendered">
                                                                                    <li className="select2-selection__choice" title="TEXT" data-select2-id="22">
                                                                                        <span className="select2-selection__choice__remove" role="presentation">×</span>TEXT
                                                                                    </li>
                                                                                    <li className="select2-search select2-search--inline">
                                                                                        <input className="select2-search__field" type="search" tabindex="0" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" placeholder="" style={{ width: '0.75em' }} />
                                                                                    </li>
                                                                                </ul>
                                                                            </span>
                                                                        </span>
                                                                        <span className="dropdown-wrapper" aria-hidden="true"></span>
                                                                    </span>
                                                                </td>
                                                                <td style={{ width: '150px' }}>
                                                                    <select className="form-control text-success" name="status">
                                                                        <option value="1" selected>ACTIVE</option>
                                                                        <option value="0">DEACTIVE</option>
                                                                    </select>
                                                                </td>
                                                                <td className="text-center">
                                                                    <button className="btn btn-primary btn-sm" type="submit">
                                                                        SAVE
                                                                    </button>
                                                                </td>
                                                            </tr>

                                                            <form action="/smsapi" method="post" accept-charset="utf-8"></form>
                                                            <input type="hidden" name="securityToken" value="796e3b5e25cfcb0f56e216485e7616d3" />

                                                            <tr>
                                                                <td>7</td>
                                                                <input type="hidden" name="id" value="AmMANQ~~" />
                                                                <td style={{ width: '150px' }}>
                                                                    <input type="text" className="form-control" name="template_name" value="PIN" required="" />
                                                                </td>
                                                                <td>
                                                                    <textarea className="form-control" name="template" required="">
                                                                        Hi @VAR1, Your PIN is : @VAR2 Never share your pin with anyone and save it for future login. Regards Trimurty Softech
                                                                    </textarea>
                                                                </td>
                                                                <td style={{ width: '150px' }}>
                                                                    <input type="text" className="form-control" name="template_id" value="0" required="" />
                                                                </td>
                                                                <td style={{ width: '200px' }}>
                                                                    <select className="select2 form-control select2-hidden-accessible" name="sms_type[]" multiple="" data-placeholder="Select Types" data-select2-id="23" tabindex="-1" aria-hidden="true">
                                                                        <option value="1" selected="" data-select2-id="25">TEXT</option>
                                                                        <option value="2">WHATSAPP</option>
                                                                    </select>
                                                                    <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="24" style={{ width: '175.333px' }}>
                                                                        <span className="selection">
                                                                            <span className="select2-selection select2-selection--multiple" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="-1" aria-disabled="false">
                                                                                <ul className="select2-selection__rendered">
                                                                                    <li className="select2-selection__choice" title="TEXT" data-select2-id="26">
                                                                                        <span className="select2-selection__choice__remove" role="presentation">×</span>TEXT
                                                                                    </li>
                                                                                    <li className="select2-search select2-search--inline">
                                                                                        <input className="select2-search__field" type="search" tabindex="0" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" placeholder="" style={{ width: '0.75em' }} />
                                                                                    </li>
                                                                                </ul>
                                                                            </span>
                                                                        </span>
                                                                        <span className="dropdown-wrapper" aria-hidden="true"></span>
                                                                    </span>
                                                                </td>
                                                                <td style={{ width: '150px' }}>
                                                                    <select className="form-control text-success" name="status">
                                                                        <option value="1" selected>ACTIVE</option>
                                                                        <option value="0">DEACTIVE</option>
                                                                    </select>
                                                                </td>
                                                                <td className="text-center">
                                                                    <button className="btn btn-primary btn-sm" type="submit">
                                                                        SAVE
                                                                    </button>
                                                                </td>
                                                            </tr>

                                                            <form action="/smsapi" method="post" accept-charset="utf-8"></form>
                                                            <input type="hidden" name="securityToken" value="796e3b5e25cfcb0f56e216485e7616d3" />

                                                            <tr>
                                                                <td>8</td>
                                                                <input type="hidden" name="id" value="UTAPOw~~" />
                                                                <td style={{ width: '150px' }}>
                                                                    <input type="text" className="form-control" name="template_name" value="CUSTOMER_UPDATE" required="" />
                                                                </td>
                                                                <td>
                                                                    <textarea className="form-control" name="template" required="">
                                                                        Dear Customer, Thank you for recharging your phone. Number @VAR1, Amount @VAR2. Regards Trimurty Softech.
                                                                    </textarea>
                                                                </td>
                                                                <td style={{ width: '150px' }}>
                                                                    <input type="text" className="form-control" name="template_id" value="0" required="" />
                                                                </td>
                                                                <td style={{ width: '200px' }}>
                                                                    <select className="select2 form-control select2-hidden-accessible" name="sms_type[]" multiple="" data-placeholder="Select Types" data-select2-id="13" tabindex="-1" aria-hidden="true">
                                                                        <option value="1" selected="" data-select2-id="15">TEXT</option>
                                                                        <option value="2" selected="" data-select2-id="16">WHATSAPP</option>
                                                                    </select>
                                                                    <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="14" style={{ width: '175.333px' }}>
                                                                        <span className="selection">
                                                                            <span className="select2-selection select2-selection--multiple" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="-1" aria-disabled="false">
                                                                                <ul className="select2-selection__rendered">
                                                                                    <li className="select2-selection__choice" title="TEXT" data-select2-id="17">
                                                                                        <span className="select2-selection__choice__remove" role="presentation">×</span>TEXT
                                                                                    </li>
                                                                                    <li className="select2-selection__choice" title="WHATSAPP" data-select2-id="18">
                                                                                        <span className="select2-selection__choice__remove" role="presentation">×</span>WHATSAPP
                                                                                    </li>
                                                                                    <li className="select2-search select2-search--inline">
                                                                                        <input className="select2-search__field" type="search" tabindex="0" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" placeholder="" style={{ width: '0.75em' }} />
                                                                                    </li>
                                                                                </ul>
                                                                            </span>
                                                                        </span>
                                                                        <span className="dropdown-wrapper" aria-hidden="true"></span>
                                                                    </span>
                                                                </td>
                                                                <td style={{ width: '150px' }}>
                                                                    <select className="form-control text-success" name="status">
                                                                        <option value="1" selected>ACTIVE</option>
                                                                        <option value="0">DEACTIVE</option>
                                                                    </select>
                                                                </td>
                                                                <td className="text-center">
                                                                    <button className="btn btn-primary btn-sm" type="submit">
                                                                        SAVE
                                                                    </button>
                                                                </td>
                                                            </tr>

                                                        </tbody>

                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section >

            </div >

            {isOpen && (
                <div className="modal fade show" id="smsapimodal" tabIndex="-1" role="dialog" aria-labelledby="smsapimodalCenterTitle" aria-modal="true" style={{ display: 'block', fontSize: '8px' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" style={{ marginTop: '-10%' }}>
                            <form onSubmit={handleSubmit} action="#" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="securityToken" value="796e3b5e25cfcb0f56e216485e7616d3" />
                                <div className="modal-header">
                                    <h5 className="modal-title" id="smsapiModalLongTitle">NEW SMS API</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" fdprocessedid="tz612" onClick={closeModal}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <button type="button" className="btn btn-default btn-sm m-1" fdprocessedid="hnx1h">
                                        <i className="fas fa-share"></i> Variable to use
                                    </button>
                                    <div className="card-footer card-comments mb-1">
                                        <div className="card-comment">
                                            <div className="comment-text ml-0">
                                                <span className="username" style={{ fontWeight:'bold'}}>
                                                    1. @msg :For the sms text. 2.@mobile :For the mobile number. 3.@senderid :For the sender ID.4.@templateid :For the template ID
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <label>API Url</label>
                                            <textarea
                                                className="form-control"
                                                name="api_url"
                                                placeholder="Enter api url"
                                                required=""
                                                value={apiUrl}
                                                onChange={(e) => setApiUrl(e.target.value)}
                                            ></textarea>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <label>Sender ID</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="sender_id"
                                                placeholder="Enter sender ID"
                                                fdprocessedid="upjbhg"
                                                value={senderId}
                                                onChange={(e) => setSenderId(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <label>Api Type</label>
                                            <select
                                                className="form-control"
                                                name="api_type"
                                                data-placeholder="Select Type"
                                                fdprocessedid="cd61zo"
                                                value={apiType}
                                                onChange={(e) => setApiType(e.target.value)}
                                            >
                                                <option value="1">TEXT</option>
                                                <option value="2">WHATSAPP</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <label>Response Type</label>
                                            <select
                                                className="form-control"
                                                name="response_type"
                                                required=""
                                                fdprocessedid="vgr8a"
                                                value={responseType}
                                                onChange={(e) => setResponseType(e.target.value)}
                                            >
                                                <option value="1">JSON</option>
                                                <option value="2">XML</option>
                                                <option value="3">STRING</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <label>Separator</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="separator_char"
                                                placeholder="Enter separator"
                                                fdprocessedid="z7gqxr"
                                                value={separatorChar}
                                                onChange={(e) => setSeparatorChar(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <label>Status Key</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="status_key"
                                                placeholder="Enter status key"
                                                fdprocessedid="pif3jh"
                                                value={statusKey}
                                                onChange={(e) => setStatusKey(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <label>Success Value</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="success_value"
                                                placeholder="Enter success value"
                                                fdprocessedid="r4wd7m"
                                                value={successValue}
                                                onChange={(e) => setSuccessValue(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal} fdprocessedid="zj6cqu">Close</button>
                                    <button type="submit" className="btn btn-primary" fdprocessedid="x5n6h4">SAVE</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}


            {isOpenn && (
                <div className="modal fade show" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-modal="true" style={{ paddingRight: '7px', display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form onSubmit={handleSubmit}>
                                <input type="hidden" name="securityToken" value="796e3b5e25cfcb0f56e216485e7616d3" />
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLongTitle">NEW SMS TEMPLATE</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModall}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>Template Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="template_name"
                                            required
                                            placeholder="Template Name"
                                            value={templateName}
                                            onChange={(e) => setTemplateName(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>SMS</label> Note: ADD @var1, @var2 to replace string.
                                        <textarea
                                            className="form-control"
                                            name="template"
                                            required
                                            placeholder="Template"
                                            value={template}
                                            onChange={(e) => setTemplate(e.target.value)}
                                        ></textarea>
                                    </div>

                                    <div className="form-group">
                                        <label>Template ID</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="template_id"
                                            placeholder="Template ID"
                                            value={templateId}
                                            onChange={(e) => setTemplateId(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Select Types</label>
                                        <select
                                            className="form-control"
                                            name="sms_type"
                                            multiple
                                            required
                                            value={smsTypes}
                                            onChange={(e) => setSmsTypes(Array.from(e.target.selectedOptions, (option) => option.value))}
                                        >
                                            <option value="1">TEXT</option>
                                            <option value="2">WHATSAPP</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Status</label>
                                        <select
                                            className="form-control"
                                            name="status"
                                            required
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                        >
                                            <option value="">SELECT</option>
                                            <option value="1">ACTIVE</option>
                                            <option value="0">DEACTIVE</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModall}>
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        SAVE
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

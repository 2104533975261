import React from "react";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { FaLongArrowAltLeft, FaLongArrowAltRight, FaRegEye } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useState, useEffect } from "react";
import RestService from "../../http";
import showToast from "./APPToast";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import Loader from "../Loader";


const NextstageKycReport = ({ showNextComponent, showPreviousComponent }) => {

    const rest = new RestService();
    const [isImageVisible, setIsImageVisible] = useState(false);
    const [isImageVisible2, setIsImageVisible2] = useState(false);
    const [isImageVisible3, setIsImageVisible3] = useState(false);
    const [isImageVisible4, setIsImageVisible4] = useState(false);
    const [isImageVisible5, setIsImageVisible5] = useState(false);
    const [isImageVisible6, setIsImageVisible6] = useState(false);
    const [imagePrefix] = useState("data:image/png;base64,");
    const [videoPrefix] = useState("data:video/mp4;base64,");
    const [merchentLiveImage, setMerchentLiveImage] = useState('');
    const [merchentImage, setMerchentImage] = useState('');
    const [merchentVideo, setMerchentVideo] = useState('');
    const [aadharFront, setAadharFront] = useState('');
    const [aadharBack, setAadharBack] = useState('');
    const [pan, setPan] = useState('');
    const [passbook, setPassbook] = useState('');
    const [shopImage, setShopImage] = useState('');
    const [downlodableImage, setDownlodableImage] = useState("");
    const location = useLocation();
    const stateData = location.state;
    const [agentData, setAgentData] = useState(location.state);
    const [agentId] = useState(agentData.agentcode);
    const [latitude] = useState(agentData.latitude);
    const [longitude] = useState(agentData.longitude);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setAgentData(stateData);
    }, [setAgentData]);


    const [isZoomed, setIsZoomed] = useState(false);
    const [isZoomed2, setIsZoomed2] = useState(false);


    const handleViewButtonClick = () => {

        var raw = {
            "agentid": agentId
        };

        rest.post("completekyc/fetchaadhar", raw).then(result => {
            console.log(result);

            const kycDoc = result.KYCDOCUMENT;

            if (result.status === rest.SUCCESS) {
                let adhaarFrontImage = `${imagePrefix} ${kycDoc.aadhar}`;
                let adhaarBackImage = `${imagePrefix} ${kycDoc.aadharbank}`;

                setAadharFront(adhaarFrontImage);
                setAadharBack(adhaarBackImage);

                setDownlodableImage(adhaarFrontImage);
                setIsImageVisible(true);
                setIsImageVisible2(false);
                setIsImageVisible3(false);
                setIsImageVisible4(false);
                setIsImageVisible5(false);
                setIsImageVisible6(false);

            } else {
                showToast("DANGER", result.message);
            }

        }).catch((error) => {
            console.log(error);
        })

    };

    const handleViewButtonClick2 = () => {

        var raw = {
            "agentid": agentId
        };

        rest.post("completekyc/fetchpan", raw).then(result => {
            console.log(result);

            const kycDoc = result.KYCDOCUMENT;

            if (result.status === rest.SUCCESS) {
                let panImage = `${imagePrefix} ${kycDoc.pan}`;

                setPan(panImage);
                setDownlodableImage(panImage);
                setIsImageVisible2(true);
                setIsImageVisible(false);
                setIsImageVisible3(false);
                setIsImageVisible4(false);
                setIsImageVisible5(false);
                setIsImageVisible6(false);

            } else {
                showToast("DANGER", result.message);
            }

        }).catch((error) => {
            console.log(error);
        })
    };

    const handleViewButtonClick3 = () => {

        var raw = {
            "agentid": agentId
        };

        rest.post("completekyc/fetchbankpassbook", raw).then(result => {
            console.log(result);

            const kycDoc = result.KYCDOCUMENT;

            if (result.status === rest.SUCCESS) {

                let passbookImage = `${imagePrefix} ${kycDoc.passbook}`;

                setPassbook(passbookImage);
                setDownlodableImage(passbookImage);
                setIsImageVisible3(true);
                setIsImageVisible2(false);
                setIsImageVisible(false);
                setIsImageVisible4(false);
                setIsImageVisible5(false);
                setIsImageVisible6(false);

            } else {
                showToast("DANGER", result.message);
            }

        }).catch((error) => {
            console.log(error);
        })
    };

    const handleViewButtonClick4 = () => {

        var raw = {
            "agentid": agentId
        };

        rest.post("completekyc/fetchliveshoppic", raw).then(result => {
            console.log(result);

            const kycDoc = result.KYCDOCUMENT;

            if (result.status === rest.SUCCESS) {

                let merchantShopImage = `${imagePrefix} ${kycDoc.liveshoppic}`;

                setShopImage(merchantShopImage);

                setDownlodableImage(merchantShopImage);
                setIsImageVisible4(true);
                setIsImageVisible3(false);
                setIsImageVisible2(false);
                setIsImageVisible(false);
                setIsImageVisible5(false);
                setIsImageVisible6(false);

            } else {
                showToast("DANGER", result.message);
            }

        }).catch((error) => {
            console.log(error);
        })
    };

    const handleViewButtonClick5 = () => {

        var raw = {
            "agentid": agentId
        };

        rest.post("completekyc/fetchlivepic", raw).then(result => {
            console.log(result);

            const kycDoc = result.KYCDOCUMENT;

            if (result.status === rest.SUCCESS) {

                let merchentImage = `${imagePrefix} ${kycDoc.merchantlivepic}`;

                setMerchentLiveImage(merchentImage);
                setDownlodableImage(merchentImage);
                setIsImageVisible5(true);
                setIsImageVisible6(false);
                setIsImageVisible4(false);
                setIsImageVisible3(false);
                setIsImageVisible2(false);
                setIsImageVisible(false);

            } else {
                showToast("DANGER", result.message);
            }

        }).catch((error) => {
            console.log(error);
        })
    };


    const handleMerchantImage = () => {

        var raw = {
            "agentid": agentId
        };

        rest.post("completekyc/fetchlivepic", raw).then(result => {
            console.log(result);

            const kycDoc = result.KYCDOCUMENT;

            if (result.status === rest.SUCCESS) {

                let merchentImage = `${imagePrefix} ${kycDoc.merchantlivepic}`;

                setMerchentImage(merchentImage);

            } else {
                showToast("DANGER", result.message);
            }

        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        handleMerchantImage();
    }, [])

    const handleViewButtonClick6 = () => {

        var raw = {
            "agentid": agentId
        };

        rest.post("completekyc/fetchvideo", raw).then(result => {
            console.log(result);
            const kycDoc = result.KYCDOCUMENT.livevideo;

            if (result.status === rest.SUCCESS) {

                let merchantVideo = `${videoPrefix} ${kycDoc.livevideo}`;

                setMerchentVideo(merchantVideo);
                setDownlodableImage(merchantVideo);

                setIsImageVisible6(true);
                setIsImageVisible5(false);
                setIsImageVisible4(false);
                setIsImageVisible3(false);
                setIsImageVisible2(false);
                setIsImageVisible(false);

            } else {
                showToast("DANGER", result.message);
            }

        }).catch((error) => {
            console.log(error);
        })
    };

    const handleImageClick = () => {
        setIsZoomed(!isZoomed);
    };

    const handleImageClick2 = () => {
        setIsZoomed2(!isZoomed2);
    };

    // const handleAgentId = () => {
    //     var raw = {
    //         "agentid": agentId
    //     };

    //     console.log(raw);
    //     setLoading(true);

    //     rest.post("completekyc/fetchdocument", raw).then(result => {
    //         setLoading(false);

    //         console.log(result);
    //         const kycDoc = result.KYCDOCUMENT;
    //         console.log(kycDoc);

    //         if (result.status === rest.SUCCESS) {
    //             let merchentImage = `${imagePrefix} ${kycDoc.merchantlivepic}`;
    //             let adhaarFrontImage = `${imagePrefix} ${kycDoc.aadhar}`;
    //             let adhaarBackImage = `${imagePrefix} ${kycDoc.aadharbank}`;
    //             let passbookImage = `${imagePrefix} ${kycDoc.passbook}`;
    //             let panImage = `${imagePrefix} ${kycDoc.pan}`;
    //             let merchantShopImage = `${imagePrefix} ${kycDoc.liveshoppic}`;
    //             let merchentVideo = `${videoPrefix} ${kycDoc.livevideo}`;

    //             setMerchentLiveImage(merchentImage);
    //             setAadharFront(adhaarFrontImage);
    //             setAadharBack(adhaarBackImage);
    //             setPassbook(passbookImage);
    //             setPan(panImage);
    //             setShopImage(merchantShopImage);
    //             setMerchentVideo(merchentVideo)


    //         } else {
    //             showToast("DANGER", result.message)
    //         }

    //     }).catch((error) => {
    //         console.log(error)
    //     })
    // }

    // useEffect(() => {
    //     handleAgentId()
    // }, []);

    const handleDownloadClick = () => {
        const link = document.createElement('a');
        link.href = downlodableImage;
        link.download = 'downloaded_image.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    // useEffect(() => {
    //     if ("geolocation" in navigator) {
    //         navigator.geolocation.getCurrentPosition(function (position) {
    //             setPosition({
    //                 latitude: position.coords.latitude,
    //                 longitude: position.coords.longitude,
    //             });
    //         });
    //     } else {
    //         console.log("Geolocation is not available in your browser.");
    //     }
    // }, []);

    return (
        <>
            <ToastContainer />
            {loading && <Loader />}

            <div className="card bg-light">
                <div className="row ml-2 mt-2">
                    <div className="col-sm-8 col-lg-8">
                        {/* <div className="row">
                            <div className="col-md-4">
                                <label for="service_id">
                                    Agent Id: <span className="text-danger"> *</span>
                                </label>
                                <input
                                    placeholder="Enter Agent Id"
                                    className="form-control select2 "
                                    id="service_id"
                                    data-select2-id="service_id"
                                    aria-hidden="true"
                                    onChange={(e => setAgentId(e.target.value))}
                                />
                            </div>

                            <div className="col-md-4" style={{marginTop:"3.5%"}}>
                                <button className="btn"
                                    onClick={handleAgentId}
                                    style={{ backgroundColor: "darkblue", color: "white" }}
                                >
                                    Search
                                </button>
                            </div>

                        </div> */}

                        <div className='row mt-4 ml-2'>

                            <div className="col-md-3">
                                {/* <label htmlFor="service_id">
                                    Aadhar Card: <span className="text-danger"> *</span>
                                </label>
                                <br /> */}
                                <button
                                    className="btn-light"
                                    style={{ backgroundColor: "lightblue", width: "100%" }}
                                    onClick={handleViewButtonClick}
                                >
                                    View Aadhar <FaRegEye />
                                </button>
                            </div>

                            <div className="col-md-3">

                                <button
                                    className="btn-light"
                                    onClick={handleViewButtonClick2}
                                    style={{ backgroundColor: "lightblue", width: "100%" }}>View Pan <FaRegEye />
                                </button>
                            </div>

                            <div className="col-md-4">

                                <button
                                    className="btn-light"
                                    onClick={handleViewButtonClick3}
                                    style={{ backgroundColor: "lightblue", width: "100%" }}>View Passbook <FaRegEye />
                                </button>
                            </div>

                            <div className="col-md-3 mt-3">

                                <button
                                    className="btn-light"
                                    onClick={handleViewButtonClick4}
                                    style={{ backgroundColor: "lightblue", width: "100%" }}>View Shop Pic <FaRegEye />
                                </button>
                            </div>

                            <div className="col-md-3 mt-3">

                                <button
                                    className="btn-light"
                                    onClick={handleViewButtonClick5}
                                    style={{ backgroundColor: "lightblue", width: "100%" }}>View User <FaRegEye />
                                </button>
                            </div>

                            <div className="col-md-4 mt-3">
                                {/* <label for="service_id">
                                    Shop Video: <span className="text-danger"> *</span>
                                </label><br /> */}

                                <button
                                    className="btn-light"
                                    onClick={handleViewButtonClick6}
                                    style={{ backgroundColor: "lightblue", width: "100%" }}>View Video <FaRegEye />
                                </button>

                                {/* <div>
                                    <h1>Video Player</h1>
                                    <video controls>
                                        <source src={videoUrl} type="video/mp4" />
                                    </video>
                                </div> */}
                            </div>

                        </div>

                        <div className="container" style={{ margin: "5%" }}>
                            <div className="row mt-3">
                                <div className={`mt-2 ${isImageVisible ? 'visible' : 'invisible'}`}>
                                    <img
                                        style={{ width: "40%", height: "60%", borderRadius: "10px" }}
                                        className={`border ${isZoomed ? 'zoomed' : ''}`}
                                        src={aadharFront}
                                        alt="Aadhar Card"
                                        onClick={handleImageClick}
                                    />&nbsp;&nbsp;&nbsp;

                                    <img
                                        style={{ width: "40%", height: "60%", borderRadius: "10px" }}
                                        className={`border ${isZoomed2 ? 'zoomed2' : ''}`}
                                        src={aadharBack}
                                        alt="Aadhar Card"
                                        onClick={handleImageClick2}
                                    /><br /><br />

                                    <div className="location-info">
                                        Latitude: {latitude}, Longitude: {longitude}
                                    </div>

                                    <button className="mt-2 btn" style={{ backgroundColor: "darkblue", color: "white" }} onClick={handleDownloadClick}>Download  <IoCloudDownloadOutline style={{ fontSize: "20px" }} /></button>

                                    <br />
                                </div>

                                <div className={`mt-2 ${isImageVisible2 ? 'visible' : 'invisible'}`}>
                                    <img
                                        style={{ width: "40%", height: "60%", borderRadius: "10px" }}
                                        className={`border ${isZoomed ? 'zoomed' : ''}`}
                                        src={pan}
                                        onClick={handleImageClick}
                                    />  <br />

                                    <div className="location-info">
                                        Latitude: {latitude}, Longitude: {longitude}
                                    </div>

                                    <button className="mt-2 btn" style={{ backgroundColor: "darkblue", color: "white" }} onClick={handleDownloadClick}>Download  <IoCloudDownloadOutline style={{ fontSize: "20px" }} /></button>

                                </div>

                                <div className={`mt-2 ${isImageVisible3 ? 'visible' : 'invisible'}`}>
                                    <img
                                        style={{ width: "40%", height: "60%", borderRadius: "10px" }}
                                        className={`border ${isZoomed ? 'zoomed' : ''}`}
                                        src={passbook}
                                        onClick={handleImageClick}
                                    /><br />

                                    <div className="location-info">
                                        Latitude: {latitude}, Longitude: {longitude}
                                    </div>

                                    <button className="mt-2 btn" style={{ backgroundColor: "darkblue", color: "white" }} onClick={handleDownloadClick}>Download  <IoCloudDownloadOutline style={{ fontSize: "20px" }} /></button>

                                </div>

                                <div className={`mt-2 ${isImageVisible4 ? 'visible' : 'invisible'}`}>
                                    <img
                                        style={{ width: "40%", height: "60%", borderRadius: "10px" }}
                                        className={`border ${isZoomed ? 'zoomed' : ''}`}
                                        src={shopImage}
                                        onClick={handleImageClick}
                                    /><br />

                                    <div className="location-info">
                                        Latitude: {latitude}, Longitude: {longitude}
                                    </div>

                                    <button className="mt-2 btn" style={{ backgroundColor: "darkblue", color: "white" }} onClick={handleDownloadClick}>Download  <IoCloudDownloadOutline style={{ fontSize: "20px" }} />
                                    </button>
                                </div>

                                <div className={`mt-2 ${isImageVisible5 ? 'visible' : 'invisible'}`}>
                                    <img
                                        style={{ width: "40%", height: "60%", borderRadius: "10px" }}
                                        className={`border ${isZoomed ? 'zoomed' : ''}`}
                                        src={merchentLiveImage}
                                        onClick={handleImageClick}
                                    /><br />

                                    <div className="location-info">
                                        Latitude: {latitude}, Longitude: {longitude}
                                    </div>

                                    <button className="mt-2 btn" style={{ backgroundColor: "darkblue", color: "white" }} onClick={handleDownloadClick}>Download  <IoCloudDownloadOutline style={{ fontSize: "20px" }} /></button>

                                    <br />
                                </div>

                                <div className={`mt-2 ${isImageVisible6 ? 'visible' : 'invisible'}`}>
                                    <img
                                        style={{ width: "40%", height: "60%", borderRadius: "10px" }}
                                        className={`border ${isZoomed ? 'zoomed' : ''}`}
                                        src={merchentVideo}
                                        onClick={handleImageClick}
                                    /><br />

                                    <button className="mt-2 btn" style={{ backgroundColor: "darkblue", color: "white" }} onClick={handleDownloadClick}>Download  <IoCloudDownloadOutline style={{ fontSize: "20px" }} /></button>

                                    <br />
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="col-md-3 mt-5 col-lg-3controls">

                        <div className="text-center">
                            <img className="border" src={merchentImage} style={{ width: "50%", height: "50%", borderRadius: "20px" }} /><br />
                        </div>

                        <div className="text-center" style={{ marginTop: "20%" }}>
                            <button className="btn" style={{ backgroundColor: "darkblue", color: "white" }} onClick={showPreviousComponent}><FaLongArrowAltLeft /> Previous</button>&nbsp;&nbsp;
                            <button className="btn" style={{ backgroundColor: "red", color: "white" }}>Reject <RxCross2 /></button>&nbsp;&nbsp;
                            <button className="btn" style={{ backgroundColor: "green", color: "white" }} onClick={(e) => showNextComponent(agentId)}>Next <FaLongArrowAltRight /></button>&nbsp;&nbsp;
                        </div><br />

                    </div>

                </div>
            </div>

        </>
    );

};

export default NextstageKycReport;

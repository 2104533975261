import React from 'react'
import { FaSearch, FaTrashAlt } from "react-icons/fa";
import { useState, useEffect, useRef } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
// import swal from 'sweetalert';
import RestService from '../http';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Pagination2 from './utilityComponents/Pagination2';
import Loader from './Loader';
import Select from 'react-select';
import formatDate from './utilityComponents/FormatDate';
import DatePicker from "react-datepicker";


export default function Member() {

    const navigate = useNavigate();
    const rest = new RestService();
    const [selectedAction, setSelectedAction] = useState(null);

    const [data, setData] = useState([]);
    // const [isSessionExpired, setIsSessionExpired] = useState(false);
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('');
    const [userMobile, setUserMobile] = useState('');
    const [userList, setUserList] = useState([]);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [userRole, setUserRole] = useState('');
    const [date, setDate] = useState('');
    const [date1, setDate1] = useState('');
    const [dateWise, setDateWise] = useState(true);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };


    const [roles, setRoles] = useState([]);

    const handleCustomerType = (e) => {

        setUserRole(e.target.value);

        rest
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                if (result.status === rest.SUCCESS) {
                    setUserList(result.data);

                } else {
                    showToast("DANGER", result.message)
                    setUserList([]);
                }
            })
            .catch((error) => {
                console.error(error);
                setUserList([]);
            });
    };


    useEffect(() => {
        rest.get("user/roles").then(result => {
            console.log(result);
            if (result.role === undefined) {
                setRoles([])
            } else {
                setRoles(result.role);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);


    // const sessionExpiretionPopup = () => {
    //     if (isSessionExpired) {
    //         swal({
    //             title: 'Session Expired',
    //             text: 'Your login session has expired. Please log in again.',
    //             icon: 'warning',
    //             closeOnClickOutside: false,
    //             buttons: {
    //                 confirm: {
    //                     text: 'OK',
    //                     className: 'btn btn-primary',
    //                 },
    //             },
    //             dangerMode: true,
    //         }).then((confirmed) => {
    //             if (confirmed) {
    //                 window.location.href = '/';
    //                 sessionStorage.removeItem("token");
    //                 sessionStorage.removeItem("user");
    //             }
    //         });
    //     }
    // }
    // useEffect(() => {
    //     sessionExpiretionPopup();
    // }, [sessionExpiretionPopup]);

    const handleSearchClick = () => {
        var raw = {
            "filterRoleType": userRole,
            "userId": userId,
            "mobile": userMobile,
            "startDate": formatDate(date),
            "endDate": formatDate(date1),
            "dateWise": dateWise

        };

        setLoading(true);

        rest.post("user/viewUser", raw).then(response => {
            console.log(response);

            setLoading(false);

            console.log(response);
            if (response.status === rest.SUCCESS) {
                // setData(response.data);
                setData(response.data)

            } else {
                setData([]);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        handleSearchClick()
    }, []);

    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);


    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropDownRef = useRef();

    const handleOptionSelect = (option) => {
        setSelectedOption(option.userId);
        setIsDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    const handleDeleteUser = (userId) => {
        rest.post(`user/deleteUser/${userId}`, null).then(result => {
            if (result.status === rest.SUCCESS) {
                showToast("SUCCESSS", result.message);
                handleSearchClick();
                window.location.reload();
            } else {
                showToast("DANGER", result.message)
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const updateStatus = (userId, status) => {
        let request = {
            userId: userId,
            status: status ? 0 : 1,
        };

        rest.post("user/changeStatus", request).then(result => {
            console.log(result);
            if (result.status === rest.SUCCESS) {
                showToast("SUCCESS", result.message);
                handleSearchClick();
            } else {
                showToast("FAILED", result.message);
            }
        }).catch(error => {
            console.log(error);
        })
    }


    const [filteredUserDataReport, setFilteredUserDataReport] = useState([]);
    const [searchInput, setSearchInput] = useState('');


    useEffect(() => {
        const filteredList = data.filter(item =>
            item.mobile.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredUserDataReport(filteredList);
    }, [data, searchInput]);

    const paginatedUserDataReport = filteredUserDataReport.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const [selectedUserId, setSelectedUserId] = useState(null);

    const options = [
        { value: '', label: 'Select an option' },
        ...userList.map((item) => ({
            value: item.userId,
            label: `${item.name} || ${item.mobile}`,
        }))
    ];

    const handleSelectChange = (selectedUserId) => {
        setSelectedUserId(selectedUserId);
        setUserId(selectedUserId.value);
    };

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: '570.667px' }}>
                {/* Content Header (Page header)  */}

                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Manage Member <span>
                                    <Link to="/addmember" className="btn btn-sm btn-primary">
                                        <i className="fa fa-plus"></i> NEW</Link></span>&nbsp;
                                    <span>
                                        <Link to="memberExcelFile" className="btn btn-sm btn-success text-white">
                                            <i className="btn-hover fa fa-file-excel-o "> Export</i></Link></span>
                                </h1>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Member Master</Link></li>
                                    <li className="breadcrumb-item active">Manage Member</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div>
                                    <input type="hidden" name="securityToken" value="e1a029f8b1eeea1fb39693c3478a9e7a" />
                                    <div className="row">
                                        <div className="col-md-3" data-select2-id="4">
                                            <label for="assign_commission">
                                                User Type: <span className="text-danger"> *</span>
                                            </label>
                                            <select
                                                className="form-control select2 "
                                                required=""
                                                id="service_id"
                                                data-select2-id="service_id"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                onChange={handleCustomerType}
                                            >
                                                <option value={"-1"}>All</option>
                                                {roles.map((item) => (
                                                    item.roleId !== 0 ? <option value={item.roleId}>{item.roleName}</option> : null
                                                ))}
                                            </select>
                                        </div>

                                        <div className="col-md-3" data-select2-id="4">
                                            <label for="service_id">
                                                User Id: <span className="text-danger"> *</span>
                                            </label>
                                            {/* <select
                                                className="form-control select2 "
                                                name="service_id"
                                                required=""
                                                id="service_id"
                                                data-select2-id="service_id"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                onChange={(e) => setUserId(e.target.value)}
                                            >
                                                <option value="">Select Id...</option>
                                                {userList.map((item) => (
                                                    <option value={item.userId}>{item.name}</option>
                                                ))}
                                            </select> */}
                                            <Select
                                                className="select-box width100p"
                                                options={options}
                                                isSearchable
                                                value={selectedUserId}
                                                onChange={handleSelectChange}
                                            />
                                        </div>

                                        <div className="col-md-3">
                                            <label for="rec_to_load"> Mobile</label>
                                            <input type="text" className="form-control" name="string" placeholder="Enter Mobile" fdprocessedid="9n5i4u" onChange={(e) => setUserMobile(e.target.value)} />
                                        </div>

                                        {/* <div className="col-md-3">
                                            <label for="rec_to_load">Parent Mobile</label>
                                            <input type="text" className="form-control" onChange={handleParentMobile} name="parent" placeholder="Enter number or id" fdprocessedid="v32v5m" />
                                        </div> */}


                                        <div className="col-md-3">
                                            <label for="rec_to_load"> Start Date</label><br />
                                            <DatePicker
                                                className="custom-date-picker"
                                                name="string"
                                                fdprocessedid="9n5i4u"
                                                selected={date}
                                                dateFormat={"yyyy-MM-dd"}
                                                placeholderText='Select date'
                                                onChange={(date) => setDate(date)}
                                                showYearDropdown
                                                showMonthDropdown
                                            />
                                        </div>

                                        <div className="col-md-3 mt-2">
                                            <label for="rec_to_load"> End Date</label>
                                            <br />
                                            <DatePicker
                                                className="custom-date-picker"
                                                name="string"
                                                disabledDayAriaLabelPrefix="#$"
                                                fdprocessedid="9n5i4u"
                                                selected={date1}
                                                dateFormat={"yyyy-MM-dd"}
                                                placeholderText='Select date'
                                                onChange={(date) => setDate1(date)}
                                                showYearDropdown
                                                showMonthDropdown
                                            />
                                        </div>

                                        <div className="col-md-3 mt-2" data-select2-id="4">
                                            <label for="assign_commission">
                                                Date Wise: <span className="text-danger"> *</span>
                                            </label>
                                            <select
                                                className="form-control select2 "
                                                required=""
                                                id="service_id"
                                                data-select2-id="service_id"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                onChange={(e) => setDateWise(e.target.value)}
                                            >
                                                <option value={"true"}>YES</option>
                                                <option value={"false"}>NO</option>
                                            </select>
                                        </div>

                                        <div className="col-md-12 text-center" style={{ marginTop: '40px' }}>
                                            <button className="btn btn-primary " fdprocessedid="fl0v7" onClick={handleSearchClick}>
                                                <FaSearch className="fas fa-search" /> Search
                                            </button>&nbsp;&nbsp;
                                            <Link to="/member" className="btn btn-warning text-white">Reset</Link>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                <center>
                                    <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                        <div>
                                            <div className="col-md-3" style={{ float: "left" }}>
                                                <div>
                                                    <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                    <input
                                                        className="form-control text-box"
                                                        type="text"
                                                        placeholder="SEARCH BY NUMBER"
                                                        value={searchInput}
                                                        onChange={(e) => setSearchInput(e.target.value)}
                                                    />
                                                </div>
                                            </div><br />

                                            <Pagination2
                                                totalPages={Math.ceil(userList.length / itemsPerPage)}
                                                currentPage={currentPage}
                                                onChange={handlePageChange}

                                            />

                                        </div>

                                    </div>
                                </center><br />

                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Role Id</th>
                                                <th>User Id</th>
                                                <th>Wlid</th>
                                                <th>Name</th>
                                                <th>Firm Name</th>
                                                <th>Address</th>
                                                <th>City</th>
                                                <th>District</th>
                                                <th>Pin Code</th>
                                                <th>state</th>
                                                <th>Country</th>
                                                <th>Mobile</th>
                                                <th>Upline Id</th>
                                                <th>Email</th>
                                                <th>Created Date & Time</th>
                                                <th>Balance</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {data !== undefined ? data.map((option, index) => ( */}
                                            {(!paginatedUserDataReport || paginatedUserDataReport.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="19">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                paginatedUserDataReport.map((option, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{rest.roles[option.roleId]}</td>
                                                        <td>{option.userId}</td>
                                                        <td>{option.wlId}</td>
                                                        <td>{option.name}</td>
                                                        <td>{option.firmName}</td>
                                                        <td>{option.address}</td>
                                                        <td>{option.city}</td>
                                                        <td>{option.district}</td>
                                                        <td>{option.pinCode}</td>
                                                        <td>{option.state}</td>
                                                        <td>{option.country}</td>
                                                        <td>{option.mobile}</td>
                                                        <td>{option.uplineId}</td>
                                                        <td>{option.email}</td>
                                                        <td>{option.createdDate} & {option.createdTime}</td>
                                                        <td>{option.balance}</td>
                                                        <td>
                                                            {option.status ? (
                                                                <span
                                                                    onClick={() =>
                                                                        updateStatus(
                                                                            option.userId,
                                                                            option.status,
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge badge-success"
                                                                >
                                                                    ACTIVE
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    onClick={() =>
                                                                        updateStatus(
                                                                            option.userId,
                                                                            option.status,
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge badge-danger"
                                                                >
                                                                    INACTIVE
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <DropdownButton
                                                                        title="Action"
                                                                        variant="primary"
                                                                        size="sm"
                                                                        block
                                                                        onSelect={(eventKey) => setSelectedAction(eventKey)}
                                                                    >
                                                                        <Dropdown.Item eventKey="editMember">
                                                                            <Link to={'/editmember'} state={option} className="btn btn-sm btn-primary btn-block">
                                                                                EDIT
                                                                            </Link>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item eventKey="viewMember">
                                                                            <Link to="/viewmember" state={option} className="btn btn-sm btn-primary btn-block">
                                                                                VIEW
                                                                            </Link>
                                                                        </Dropdown.Item>

                                                                    </DropdownButton>&nbsp;

                                                                    <button className='btn bg-red' onClick={() => handleDeleteUser(option.userId)}><FaTrashAlt /></button>

                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>

        </>

    )
}


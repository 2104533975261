import React, { useEffect } from "react";
import Navbar from "./Navbar";
import { useState } from "react";
import RestService from "../http";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import showToast from "./utilityComponents/APPToast";
import Loader from "./Loader";
import { Link } from "react-router-dom";

export default function Admincommission() {
    const service = new RestService();

    const [showForm2, setShowForm2] = useState(false);
    const [planList, setPlanList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [outCode, setOutCode] = useState('');
    const [commission, setCommission] = useState("");
    const [commissionType, setCommissionType] = useState('');
    const [operatorList, setOperatorList] = useState([]);
    const [slabList, setSlabList] = useState([]);
    const [serviceCode, setServiceCode] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [commissionData, setCommissionData] = useState(null);
    const [roles, setRoles] = useState([]);
    const [customerType, setCustomerType] = useState();
    const [operatorCode, setOperatorCode] = useState([]);
    const [slab1, setSlab1] = useState("");
    const [slab2, setSlab2] = useState("");
    const [commReport, setcommReport] = useState([]);
    const [incode, setIncode] = useState("");
    const [outcode, setOutcode] = useState("");
    const [loading, setLoading] = useState(false);


    const toggleFormVisibility2 = () => {
        setShowForm2((prevShowForm) => !prevShowForm);
    };


    const handlePlanList = () => {

        service.post("plans/getAllPlans", null).then(result => {
            if (result.status === service.SUCCESS) {
                setPlanList(result.report);
            } else {
                setPlanList([]);
            }
        }).catch(error => {
            console.log(error);

        });
    }

    useEffect(() => {
        handlePlanList();
    }, []);

    // const handlePlanIdChange = (e) => {
    //   setFetchPlanId(e.target.value);
    // };


    // const handleServiceId = (e) => {

    //     const selectedServiceCode = e.target.value;
    //     setServiceCode(selectedServiceCode);

    //     var raw = {
    //         serviceType: e.target.value
    //     }
    //     service.post('operator/fetchOperator', raw).then(result => {
    //         setOperatorList(result.report === undefined ? [] : result.report)
    //     });
    // }


    const handleServiceCode = () => {

        service.post("service/getAllService", null).then((result) => {
            if (result.report === undefined) {
                setServiceList([]);
            } else {
                console.log(result);
                setServiceList(result.report);

            }
        })
    }

    useEffect(() => {
        handleServiceCode();
    }, []);

    const handleIncode = (e) => {

        const data = JSON.parse(e.target.value);
        setOutCode(data.operatorOutcode);
        fetchOperatorSlab(data.serviceType);
        setIncode(data.operatorIncode);
        setOutcode(data.operatorOutcode);
    }

    const handleSlabs = (slabObj) => {
        // setSlabList(e.target.value);
        const data = JSON.parse(slabObj);
        setSlab1(data.slab1);
        setSlab2(data.slab2);
    }

    const handleCommission = (e) => {
        setCommission(e.target.value);
    }

    const handleCommissionType = (e) => {
        setCommissionType(e.target.value);
    }

    const fetchOperatorSlab = (serviceType) => {
        console.log(serviceType);
        service.post(`commission/fetchSlabByService/${serviceType}`, null).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                setSlabList(result.data);
            } else {
                setSlabList([]);
            }

        }).catch((error) => {
            console.log(error);
        });
    }

    const handleAssignCommission = () => {
        var raw = {
            "serviceCode": serviceCode,
            "operatorCode": incode,
            "outCode": outcode,
            "commission": commission,
            "commType": commissionType,
            "slab1": slab1,
            "slab2": slab2
        };
        console.log(raw);

        setLoading(true);

        service.post("commission/addAdminCommission", raw).then(result => {
            setLoading(false);
            if (result.status === 1) {

                showToast("SUCCESS", result.message);
               
            } else {

                showToast("DANGER", result.message);
            }
        })
    }


    const handleUpdate = (data) => {
        setCommissionData(data);
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setCommissionData(null);
        setShowModal(false);
    };


    const handleCustomerType = (e) => {
        console.log(e.target.value);
        setCustomerType(e.target.value)
    }

    const handleCustomerTypeChange = () => {
        service.get("user/roles", null).then(result => {
            if (result.role === undefined) {
                setRoles([]);
            } else {
                setRoles(result.role);
            }
        })
    };

    useEffect(() => {
        handleCustomerTypeChange()
    }, [])

    const filteredRoles = roles.filter((role) => role.roleId > 2 && role.roleId !== 6);


    const handleCommissionReport = () => {
        var raw = {
            "serviceId": serviceCode,
            "operatorCode": operatorCode
        };
        setLoading(true);

        service.post("commission/fetchAdminCommission", raw).then(result => {
            setLoading(false);
            console.log(result);
            if (result.status === service.SUCCESS) {
                setcommReport(result.data);
            } else {
                showToast("DANGER", result.message);
                setcommReport([]);
            }


        }).catch((error) => {
            console.log(error);
        })
    }

    const handleOperatorCode = (serviceCode) => {
        setServiceCode(serviceCode);

        service.post(`operator/fetchOperatorByService/${serviceCode}`, null).then(result => {
            if (result.status === service.SUCCESS) {
                setOperatorList(result.report);
            } else {
                setOperatorList([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}


            <div className="content-wrapper" style={{ minHeight: "600.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">ADMIN COMMISSION</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Settings</Link>
                                    </li>
                                    <li className="breadcrumb-item active"> COMMISSION</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">

                    <div className="container-fluid">

                        {showForm2 && (
                            <div className="card" id="addpack">
                                <div className="card-body">
                                    <div>

                                        <div className="row" data-select2-id="4">

                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="service_id">
                                                    Service Type: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => handleOperatorCode(e.target.value)}
                                                >
                                                    <option>Select Id</option>
                                                    {serviceList.map((item) => (
                                                        <option value={item.serviceType}>{item.serviceName}</option>
                                                    ))}

                                                </select>
                                            </div>

                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="service_id">
                                                    Operator Code: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleIncode}
                                                >
                                                    <option value={JSON.stringify("{}")}>SELECT OPERATOR</option>
                                                    {operatorList.length > 0 ?
                                                        operatorList.map((item) => (
                                                            <option value={JSON.stringify(item)}>{item.operatorName}| {item.operatorOutcode}</option>
                                                        )) : <></>}

                                                </select>
                                            </div>

                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="service_id">
                                                    Slabs: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => handleSlabs(e.target.value)}
                                                >
                                                    <option>Slabs</option>
                                                    {slabList.map((item) => (
                                                        <option value={JSON.stringify(item)}>{`(${item.slab1}-${item.slab2})`}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3 " data-select2-id="4">
                                                <label for="service_id">
                                                    Commission: <span className="text-danger"> *</span>
                                                </label>
                                                <input
                                                    placeholder="commission"
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    aria-hidden="true"
                                                    onChange={handleCommission}
                                                />
                                            </div>

                                            <div className="col-md-3 mt-2" data-select2-id="4">
                                                <label for="service_id">
                                                    Commission Type: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleCommissionType}
                                                >
                                                    <option>select commission type</option>
                                                    <option value={"FLAT"}>FLAT</option>
                                                    <option value={"PERC"}>PERCENTAGE</option>

                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mb-2">
                                    <input
                                        type="submit"
                                        className="btn btn-primary btn-hover text-white"
                                        value="Assign Commission"
                                        onClick={handleAssignCommission}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="card">
                            <div className="card-body">

                                <h1 className="m-0 text-dark float-right">

                                    <span
                                        className="btn btn-sm btn-primary"
                                        onClick={toggleFormVisibility2}
                                    >
                                        {/* Render only the "CANCEL" text if showForm is true */}
                                        {showForm2 ? (
                                            "CANCEL"
                                        ) : (
                                            <>
                                                <i id="sign" className="fa fa-plus" style={{}}></i> ASSIGN COMMISSION
                                            </>
                                        )}
                                    </span>&nbsp;
                                </h1>

                                <div className="row">
                                    <div className="col-md-3 mt-2" data-select2-id="4">
                                        <label for="service_id">
                                            Service Code: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={(e) => handleOperatorCode(e.target.value)}
                                        >

                                            <option>Select Id</option>
                                            {serviceList.map((item) => (
                                                <option key={item.serviceType} value={item.serviceType}>{item.serviceName}</option>
                                            ))}

                                        </select>
                                    </div>

                                    <div className="col-md-3 mt-2" data-select2-id="4">
                                        <label for="service_id">
                                            Operator Code: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={(e) => setOperatorCode(e.target.value)}
                                        >
                                            <option value={""}>SELECT OPERATOR</option>
                                            {operatorList.length > 0 ? (
                                                operatorList.map((item) => (
                                                    <option value={item.operatorOutcode}>
                                                        {item.operatorName}
                                                    </option>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </select>
                                    </div>

                                    <div className="col-md-3 mt-5" data-select2-id="4">
                                        <input
                                            type="submit"
                                            className="btn btn-primary btn-hover text-white"
                                            value="Search"
                                            onClick={handleCommissionReport}
                                        />
                                    </div>

                                </div>

                                <div className="clearfix"></div><br />
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th># ID</th>
                                                <th>Service Id</th>
                                                <th >Operator Code</th>
                                                <th>Slabs</th>
                                                <th>Commission</th>
                                                <th className="px-1">Commission Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!commReport || commReport.length === 0) ? (
                                                <tr className="text-center">
                                                    <td colSpan="6">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                commReport.map((item, index) => (
                                                    <tr>
                                                        <td key={index}>{index + 1}</td>
                                                        <td>{item.serviceId}</td>
                                                        <td>{item.operatorCode}</td>
                                                        <td>({item.slab1} - {item.slab2}) </td>
                                                        <td>{item.commission}</td>
                                                        <td>{item.type}</td>
                                                    </tr>
                                                ))
                                            )}

                                        </tbody>
                                    </table>


                                    {showModal && (
                                        <div
                                            className="modal fade show"
                                            role="dialog"
                                            aria-labelledby="serviceUpdateTitle"
                                            style={{ display: "block" }}
                                            aria-modal="true"
                                        >
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div
                                                    className="modal-content"
                                                    style={{ width: "max-content" }}
                                                >
                                                    <div>

                                                        <div className="modal-header">
                                                            <h4>Update User</h4>
                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                                                                <span aria-hidden="true">×</span>
                                                            </button>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>Customer Type <span className="text-danger"> *</span></label>

                                                            <select
                                                                className="form-control"
                                                                name="customer_type"
                                                                id="customer_type"
                                                                fdprocessedid="g30y5e"
                                                                onChange={handleCustomerType}
                                                                style={{ height: "10%" }}
                                                            >

                                                                <option value="">SELECT CUSTOMER TYPE</option>
                                                                {filteredRoles.map((role) => (
                                                                    <option key={role.roleId} value={role.roleId}>
                                                                        {role.roleName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div><br />

                                                        <table className="table table-striped table-bordered">
                                                            <thead>
                                                                <tr className="wrap-text text-center">
                                                                    <th>User Type</th>
                                                                    <th>Service Code</th>
                                                                    <th >Operator code</th>
                                                                    <th>Plan Id</th>
                                                                    <th>Slabs</th>
                                                                    <th>Commission</th>
                                                                    <th className="px-1">Commission Type</th>
                                                                    <th>Is Charge?</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>

                                                            {/* <tbody>
                                                                {userdata.map((item) => (
                                                                    <tr>
                                                                        <td>{item.userType}</td>
                                                                        <td>{item.serviceCode}</td>
                                                                        <td>{item.operatorCode}</td>
                                                                        <td>{item.planId}</td>
                                                                        <td>{`(${item.slab1}-${item.slab2})`}</td>
                                                                        <td>{item.commission}</td>
                                                                        <td>{item.type}</td>
                                                                        <td>
                                                                            <select>
                                                                                <option>Yes</option>
                                                                                <option>No</option>
                                                                            </select>
                                                                        </td>
                                                                        <td>
                                                                            <button style={{ border: "none", cursor: "pointer", color: "blue" }} onClick={handleCommissionUpdate}>
                                                                                Update
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody> */}

                                                        </table>

                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                data-dismiss="modal"
                                                                fdprocessedid="7wkuky"
                                                                onClick={handleCloseModal}
                                                            >
                                                                Close
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    );
}

import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import { Link } from 'react-router-dom';


export default function Refundtrans() {

    const [transactionId, setTransactionId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSearch = () => {
        if (transactionId === '') {
            setErrorMessage('Enter transaction ID');
            setTimeout(() => {
                setErrorMessage('');
            }, 2000);
        } else {
            // Perform your search logic here
        }
    };

    const handleKeyPress = (event) => {
        if (!isNumberKey(event)) {
            event.preventDefault();
        }
    };

    const isNumberKey = (event) => {
        const charCode = event.which ? event.which : event.keyCode;
        return charCode >= 48 && charCode <= 57;
    };


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "620.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Refund Transaction</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Transaction</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Refund Transaction</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4 col-sm-12 card">
                                        <div className="card-body">
                                            <form
                                                action="/refundtrans"
                                                id="txnForm"
                                                method="post"
                                                acceptCharset="utf-8"
                                            >
                                                <input
                                                    type="hidden"
                                                    name="securityToken"
                                                    value="42bdbc95cdc725ac7523c42c2612ebba"
                                                />
                                                <div className="col-12">
                                                    <label>Enter Transaction ID</label>
                                                    <input
                                                        type="text"
                                                        name="txn_id"
                                                        id="txn_id"
                                                        className="form-control"
                                                        autoFocus=""
                                                        value={transactionId}
                                                        onChange={(e) => setTransactionId(e.target.value)}
                                                        placeholder="Enter Transaction ID"
                                                        onKeyDown={handleKeyPress}
                                                        fdprocessedid="l0a3ng"
                                                    />
                                                </div>
                                                <div className="col-12 text-center mt-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-info btn-sm"
                                                        onClick={handleSearch}
                                                        fdprocessedid="tyd9up"
                                                    >
                                                        <i className="fa fa-search"></i> Search
                                                    </button>
                                                    {errorMessage &&
                                                        <div id="toast-container" className="toast-top-right">
                                                            <div className="toast toast-error" aria-live="assertive" >
                                                                <div className="toast-message">Enter transaction id</div>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-sm-12"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

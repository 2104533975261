import React from 'react';
import Navbar from './Navbar';
import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

export default function Createpromocd() {

    const [promocode, setPromocode] = useState('');
    const [minAmount, setMinAmount] = useState('');
    const [isForFirstRecharge, setIsForFirstRecharge] = useState(false);
    const [cashback, setCashback] = useState('');
    const [cashbackType, setCashbackType] = useState('1');
    const [numberOfTimes, setNumberOfTimes] = useState('');
    const [operators, setOperators] = useState([]);
    const [description, setDescription] = useState('');
    const [selectedOperators, setSelectedOperators] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log({
            promocode,
            minAmount,
            isForFirstRecharge,
            cashback,
            cashbackType,
            numberOfTimes,
            operators,
            description,
        });
    };


    const handleRadioChange = (e) => {
        setCashbackType(e.target.value);
    };

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        // Validate the input to allow only numbers
        if (/^\d*$/.test(inputValue)) {
            setNumberOfTimes(inputValue);

            setDescription(inputValue);
        }

    };


    const options = [
        { value: '', label: 'Airtel' },
        { value: '1', label: 'VI- Vodafone & Idea' },
        { value: '2', label: 'BSNL' },
        { value: '3', label: 'Airtel Postpaid' },
        { value: '4', label: 'Idea Postpaid' },
        { value: '5', label: 'Vodafone Postpaid' },
    ];

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const dropdownRef = useRef();

    const toggleDropdown = () => {
        setDropdownOpen((prevState) => !prevState);
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option.value);
        setDropdownOpen(false);
        console.log('Selected Value:', option.value);
    };


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Create Promocode</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Utilities</Link></li>
                                    <li className="breadcrumb-item"><Link to="/promocode">Promocodes</Link></li>
                                    <li className="breadcrumb-item active">Create</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <input type="hidden" name="securityToken" value="92cef3948f0cda9997b509ef982d8d61" />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="font-weight-normal">Promocode<span className="text-danger"> *</span></label>
                                            <input
                                                type="text"
                                                name="promocode"
                                                className="form-control"
                                                value={promocode}
                                                placeholder="Enter promocode"
                                                required
                                                onChange={(e) => setPromocode(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-md-4">
                                            <label className="font-weight-normal">Minimum Amount <span className="text-danger"> *</span></label>
                                            <input
                                                type="text"
                                                name="min_amount"
                                                className="form-control"
                                                value={minAmount}
                                                placeholder="Enter minimum amount"
                                                required
                                                onChange={(e) => setMinAmount(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-md-4" style={{ display: 'flex' }}>
                                            <label htmlFor="customCheckbox2" className="font-weight-normal">Is this for first recharge ?</label>&nbsp;
                                            <div className="custom-control custom-checkbox text-center">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="customCheckbox2"
                                                    value="1"
                                                    name="is_for_first_recharge"
                                                    checked={isForFirstRecharge}
                                                    onChange={(e) => setIsForFirstRecharge(e.target.checked)}
                                                />
                                                <label htmlFor="customCheckbox2" className="custom-control-label"></label>
                                            </div>
                                        </div>

                                        <div className="col-md-4 mt-3">
                                            <label className="font-weight-normal">Description <span className="text-danger"> *</span></label>
                                            <textarea
                                                name="description"
                                                className="form-control"
                                                placeholder="Enter description"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">Cashback Type <span className="text-danger"> * </span></label>
                                            <div className="form-group clearfix">
                                                <div className="icheck-primary d-inline">
                                                    <input
                                                        type="radio"
                                                        id="radioPrimary1"
                                                        name="cashback_type"
                                                        value="1"
                                                        checked={cashbackType === '1'}
                                                        onChange={handleRadioChange}
                                                    />
                                                    <label htmlFor="radioPrimary1">Percent</label>
                                                </div>
                                                <div className="icheck-primary d-inline ml-4">
                                                    <input
                                                        type="radio"
                                                        id="radioPrimary2"
                                                        name="cashback_type"
                                                        value="2"
                                                        checked={cashbackType === '2'}
                                                        onChange={handleRadioChange}
                                                    />
                                                    <label htmlFor="radioPrimary2">Flat</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 mt-3">
                                            <label className="font-weight-normal">Recharge applicable times <span className="text-danger"> *</span></label>
                                            <input
                                                type="text"
                                                name="number_of_times"
                                                className="form-control"
                                                value={numberOfTimes}
                                                placeholder="Recharge applicable times"
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                        <div className="col-md-4 mt-3" data-select2-id="4">
                                            <label className="font-weight-normal">Operators </label>
                                        
                                            <div
                                                ref={dropdownRef}
                                                className={`select2 select2-container select2-container--default ${isDropdownOpen ? 'select2-container--open' : ''
                                                    }`}
                                                dir="ltr"
                                                data-select2-id="2"
                                                style={{ width: '300.625px'}}
                                                onClick={toggleDropdown}
                                            >
                                                <span className="selection">
                                                    <span
                                                        className={`select2-selection select2-selection--single ${isDropdownOpen ? 'select2-selection--active' : ''
                                                            }`}

                                                        role="combobox"
                                                        aria-haspopup="true"
                                                        aria-expanded={isDropdownOpen}
                                                        tabIndex="0"
                                                        aria-disabled="false"
                                                        aria-labelledby="select2-package_id-24-container"
                                                    >
                                                        <span
                                                            className="select2-selection__rendered"
                                                            id="select2-package_id-24-container"
                                                            role="textbox"
                                                            aria-readonly="true"
                                                            title={selectedOption ? options.find((option) => option.value === selectedOption).label : 'Select Operators'}
                                                        >
                                                            {selectedOption ? options.find((option) => option.value === selectedOption).label : 'Select Operators'}
                                                        </span>

                                                        <span className="select2-selection__arrow" role="presentation">
                                                            <b role="presentation"></b>
                                                        </span>
                                                    </span>
                                                </span>
                                                {isDropdownOpen && (
                                                    <span className="dropdown-wrapper" style={{ position: 'absolute' }}>
                                                        <span className="select2-dropdown select2-dropdown--below" dir="ltr" style={{ width: '300.625px' }}>
                                                            <span className="select2-search select2-search--dropdown">
                                                            </span>
                                                            <span className="select2-results" >
                                                                <ul className="select2-results__options" role="listbox" id="select2-package_id-ci-results" aria-expanded="true" aria-hidden="false">
                                                                    {options.map((option) => (
                                                                        <li
                                                                            key={option.value}
                                                                            className={`select2-results__option ${option.value === selectedOption ? 'select2-results__option--highlighted' : ''
                                                                                }`}
                                                                            role="option"
                                                                            aria-selected={option.value === selectedOption}
                                                                            data-select2-id={`select2-package_id-f9-result-${option.value}`}
                                                                            onClick={() => handleOptionSelect(option)}
                                                                        >
                                                                            {option.label}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </span>
                                                        </span>
                                                    </span>
                                                )}

                                            </div>
                                        </div>

                                        <div className="col-md-4 mt-3">
                                            <label className="font-weight-normal">Description <span className="text-danger"> *</span></label>
                                            <textarea
                                                name="description"
                                                className="form-control"
                                                placeholder="Enter description"
                                                value={description}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                    </div>
                                    <hr />
                                    <div className="text-center">
                                        <input type="submit" className="btn btn-sm btn-primary" value="Submit" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import DatePicker from "react-datepicker";
import RestService from "../http";
import { FaFileExcel } from "react-icons/fa";
import * as XLSX from 'xlsx';
import { Link } from "react-router-dom";


export default function AdminEarningReport() {
  const rest = new RestService();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [roles, setRoles] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userId, setUserId] = useState("");

  const data = [
    {
      id: 1,
      number: "6666666666",
      amount: 30.0,
      operatorLogo: "./image/airtel.png",
      operatorName: "Airtel",
      type: "PREPAID",
      commission: 0.03,
      surcharge: "",
      txnID: "SOF0000022608",
      date: "26-Dec-2022 03:53:40 PM",
      remark: "Commission",
    }
  ];

  const handleCustomerTypeChange = async () => {
    try {
      rest
        .get(`user/roles`, null)
        .then(response => {
          console.log(response);
          if (response.status === rest.SUCCESS) {
            setRoles(response.role);
          } else {
            // setIsSessionExpired(true);
            setRoles([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleCustomerTypeChange();
  }, []);


  const handleCustomerType = (customerType) => {
    rest.post(`user/getUpline?roleId=${customerType}`, null).then(result => {
      console.log(result);
      if (result.status === rest.SUCCESS) {
        setUserList(result.data);
      } else {
        setUserList([]);
      }
    }).catch(err => {
      console.error(err);
      setUserList([]);
    });
  }

  const downloadExcel = () => {
    // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
    // writeFile(wb, 'recharge_report.xlsx');

    const ws = XLSX.utils.json_to_sheet(userList);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Admin Earning Report');

    XLSX.writeFile(wb, 'adminEarning_report.xlsx');
  };


  return (
    <>
      <Navbar />

      <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">My Earning</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Report</Link>
                  </li>
                  <li className="breadcrumb-item active">My Earning</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <form
                    action="/earningreport"
                    method="post"
                    accept-charset="utf-8"
                  >
                    <input
                      type="hidden"
                      name="securityToken"
                      value="9cef7f07ace2f392d52897f458a81a6f"
                    />
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                        <label>From Date</label>
                        {/* <input type="date" name="from_date" className="form-control" defaultValue="2023-07-25" /> */}
                        <DatePicker
                          className="form-control"
                          dropdownMode="select"
                          name="string"
                          fdprocessedid="9n5i4u"
                          selected={startDate}
                          dateFormat={"yyyy-MM-dd"}
                          onChange={(date) => setStartDate(date)}
                          showYearDropdown
                          showMonthDropdown
                        />
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                        <label>To Date</label>
                        <br />
                        {/* <input type="date" name="to_date" className="form-control" defaultValue="2023-07-25" /> */}
                        <DatePicker
                          className="form-control"
                          dropdownMode="select"
                          name="string"
                          fdprocessedid="9n5i4u"
                          selected={endDate}
                          dateFormat={"yyyy-MM-dd"}
                          onChange={(date) => setEndDate(date)}
                          showYearDropdown
                          showMonthDropdown
                        />
                      </div>
                      <div className="col-md-3">
                        <label>
                          Customer Type <span className="text-danger"> *</span>
                        </label>

                        <select
                          className="form-control"
                          name="customer_type"
                          id="customer_type"
                          fdprocessedid="g30y5e"
                          onChange={(e) => handleCustomerType(e.target.value)}
                        >
                          <option value="">SELECT CUSTOMER TYPE</option>
                          {roles.map((role) => (
                            role.roleId !== 0 ? <option key={role.roleId} value={role.roleId}>
                              {role.roleName}
                            </option> : null
                          ))}
                        </select>
                      </div>

                      <div className="col-md-3">
                        <label>
                          User <span className="text-danger"> *</span>
                        </label>

                        <select
                          className="form-control"
                          name="customer_type"
                          id="customer_type"
                          fdprocessedid="g30y5e"
                          onChange={(e) => setUserId(e.target.value)}
                        >
                          <option value={""}>SELECT USER</option>
                          {userList.map((user) => (
                            <option key={user.userId} value={user.userId}>
                              {user.name} | {user.mobile} | {user.userId}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                        <label>Number</label>
                        <input
                          type="text"
                          name="number"
                          placeholder="Enter number"
                          className="form-control"
                          defaultValue=""
                        />
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                        <button
                          className="btn btn-primary btn-sm"
                          style={{ marginTop: "35px" }}
                        >
                          <i className="fa fa-search"></i> Search
                        </button>
                        &nbsp;

                        <button type="button" name="" className="btn btn-success" onClick={downloadExcel} fdprocessedid="9svl7o">
                          <FaFileExcel className="fa fa-file-excel-o" />
                        </button>

                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <div
                    id="example1_wrapper"
                    className="dataTables_wrapper dt-bootstrap4"
                  >

                    <div className="dt-buttons">
                      <a
                        className="dt-button buttons-copy buttons-html5"
                        tabIndex="0"
                        aria-controls="example1"
                        title="Copy"
                      >
                        <span>
                          <i className="fa fa-files-o text-info font-weight-bold"></i>
                        </span>
                      </a>
                      <a
                        className="dt-button buttons-excel buttons-html5"
                        tabIndex="0"
                        aria-controls="example1"
                        title="Excel"
                      >
                        <span>
                          <i className="fa fa-file-excel-o text-success font-weight-bold"></i>
                        </span>
                      </a>
                      <a
                        className="dt-button buttons-pdf buttons-html5"
                        tabIndex="0"
                        aria-controls="example1"
                        title="PDF"
                      >
                        <span>
                          <i className="fa fa-file-pdf-o text-danger font-weight-bold"></i>
                        </span>
                      </a>
                    </div>

                    <table
                      id="example1"
                      className="table table-bordered table-striped dataTable"
                      style={{ width: "100%" }}
                      role="grid"
                      aria-describedby="example1_info"
                    >
                      <thead>
                        <tr className="wrap-text" role="row">
                          <th
                            className="sorting_asc"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-sort="ascending"
                            aria-label="#: activate to sort column descending"
                            style={{ width: "8.45833px" }}
                          >
                            #
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Number: activate to sort column ascending"
                            style={{ width: "57.2708px" }}
                          >
                            Number
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Amount: activate to sort column ascending"
                            style={{ width: "56.1354px" }}
                          >
                            Amount
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Operator: activate to sort column ascending"
                            style={{ width: "145.521px" }}
                          >
                            Operator
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Type: activate to sort column ascending"
                            style={{ width: "34.3125px" }}
                          >
                            Type
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Commission: activate to sort column ascending"
                            style={{ width: "86.6562px" }}
                          >
                            Commission
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Surcharge: activate to sort column ascending"
                            style={{ width: "71.9896px" }}
                          >
                            Surcharge
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="TxnID: activate to sort column ascending"
                            style={{ width: "79.4792px" }}
                          >
                            TxnID
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Date: activate to sort column ascending"
                            style={{ width: "135.552px" }}
                          >
                            Date
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="example1"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Remark: activate to sort column ascending"
                            style={{ width: "55.2188px" }}
                          >
                            Remark
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((row) => (
                          <tr
                            key={row.id}
                            role="row"
                            className={row.id % 2 === 0 ? "even" : "odd"}
                          >
                            <td className="sorting_1">{row.id}</td>
                            <td>{row.number}</td>
                            <td className="font-weight-bold text-right">
                              {row.amount}
                            </td>
                            <td>
                              <img
                                className="img-responsive pr-2"
                                src={row.operatorLogo}
                                width="38px"
                                alt={row.operatorName}
                              />
                              {row.operatorName}
                            </td>
                            <td>{row.type}</td>
                            <td className="font-weight-bold text-right text-success">
                              {row.commission}
                            </td>
                            <td className="font-weight-bold text-right text-danger">
                              {row.surcharge}
                            </td>
                            <td>{row.txnID}</td>
                            <td>{row.date}</td>
                            <td>{row.remark}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td
                            colSpan="3"
                            style={{ fontWeight: "bold" }}
                            className="text-primary"
                            rowSpan="1"
                          >
                            Row Count 20
                          </td>
                          <td
                            className="text-right font-weight-bold"
                            rowSpan="1"
                            colSpan="1"
                          >
                            ₹ 517.00
                          </td>
                          <td rowSpan="1" colSpan="1"></td>
                          <td
                            className="text-right font-weight-bold text-success"
                            rowSpan="1"
                            colSpan="1"
                          >
                            ₹ 4.34
                          </td>
                          <td
                            className="text-right font-weight-bold text-danger"
                            rowSpan="1"
                            colSpan="1"
                          >
                            ₹ 0.30
                          </td>
                          <td colSpan="3" rowSpan="1"></td>
                        </tr>
                      </tfoot>
                    </table>

                    <div
                      className="dataTables_info"
                      id="example1_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing 1 to 20 of 20 entries
                    </div>

                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example1_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className="paginate_button page-item previous disabled"
                          id="example1_previous"
                        >
                          <a
                            aria-controls="example1"
                            data-dt-idx="0"
                            tabIndex="0"
                            className="page-link"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="paginate_button page-item active">
                          <a
                            aria-controls="example1"
                            data-dt-idx="1"
                            tabIndex="0"
                            className="page-link"
                          >
                            1
                          </a>
                        </li>
                        <li
                          className="paginate_button page-item next disabled"
                          id="example1_next"
                        >
                          <a
                            aria-controls="example1"
                            data-dt-idx="2"
                            tabIndex="0"
                            className="page-link"
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <footer className="main-footer">
        <strong>
          Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
        </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 4.5.0
        </div>
      </footer>
    </>
  );
}

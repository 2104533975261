import React from 'react';
import Navbar from './Navbar';

export default function Viewemp() {

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '532.667px' }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">View Employee</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="/admin">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="#">HR Management</a>
                                    </li>
                                    <li className="breadcrumb-item">Employee</li>
                                    <li className="breadcrumb-item">View Employee</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <label className="text-primary">Official Information</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label>Employee Type :</label>
                                            <label>ADMIN</label>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label>Join Date :</label>
                                            <label>2022-06-23</label>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label>Salary :</label>
                                            <label>200.0000</label>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label>Status :</label>
                                            <label>
                                                <p className="text-info">Active</p>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label className="text-primary">Personal Information</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label>Employee Name :</label>
                                            <label>Sharad</label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label>Mobile Number :</label>
                                            <label>8600436163</label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label>Email :</label>
                                            <label>sharad@gmail.com</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label>Address :</label>
                                            <label>Hanuman Takali</label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label>Village :</label>
                                            <label>Nagar</label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label>Pin Code :</label>
                                            <label>414002</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label>Taluka :</label>
                                            <label>Ahmednagar</label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label>District :</label>
                                            <label>Ahmed Nagar</label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label>State :</label>
                                            <label>Maharashtra</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                            <label className="text-primary">Account Information</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <a href="www.softmintdigital.com">SOFTMINT</a>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import RestService from "../http";
import { FaFileExcel } from "react-icons/fa";
import formatDate from "./utilityComponents/FormatDate";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Pagination2 from './utilityComponents/Pagination2';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';


export default function FetchUserTxnActivity() {
    const service = new RestService();

    const [selectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();
    const [userTxnActivity, setUserTxnActivity] = useState([]);
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [serviceId, setServiceId] = useState("");
    const [serviceList, setServiceList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);


    const getServiceList = () => {
        service.post("service/getAllService", null).then((result) => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                setServiceList(result.report);
            } else {
                setServiceList([]);
            }
        }).catch(error => {
            console.log(error);
        })
    };

    useEffect(() => {
        getServiceList();
    }, []);

    const handleUserActivity = () => {
        var raw = {
            "fromDate": formatDate(date),
            "toDate": formatDate(date1),
            "serviceId": serviceId
        };

        setLoading(true);

        service.post("report/fetchUserTxnActivity", raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                setUserTxnActivity(result.data);
                setCurrentPage(1);
            } else {
                showToast("DANGER", result.message);
                setUserTxnActivity([]);
            }
        }).catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        handleUserActivity();
    }, []);


    const [filteredUserTxn, setFilteredUserTxn] = useState([]);
    const [searchInput, setSearchInput] = useState('');


    useEffect(() => {
        const filteredList = userTxnActivity.filter(item =>
            item.transactionCount.toString().includes(searchInput.toLowerCase()) ||
            item.serviceId.toString().includes(searchInput.toLowerCase()) ||
            item.serviceName.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.mobile.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.userId.toLowerCase().includes(searchInput.toLowerCase())

        );
        setFilteredUserTxn(filteredList);
    }, [userTxnActivity, searchInput]);

    

    const filteredData = filteredUserTxn.filter(item => {
        if (selectedStatus === '') {
            return true;
        } else {
            return item.transactionCount === 0 ? selectedStatus === 'INACTIVE' : selectedStatus === 'ACTIVE';
        }
    });

    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const downloadExcel = () => {
        // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
        // writeFile(wb, 'recharge_report.xlsx');

        const ws = XLSX.utils.json_to_sheet(userTxnActivity);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'user txn activity');

        XLSX.writeFile(wb, 'user_txn_activity.xlsx');
    };


    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">User Transaction Activity</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Report</Link>
                                    </li>
                                    <li className="breadcrumb-item active">User Transaction Activity</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label for="rec_to_load"> Start Date</label><br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label for="rec_to_load"> End Date</label>
                                                <br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    disabledDayAriaLabelPrefix="#$"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date1}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate1(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label for="service_id">
                                                    Service Type:{" "}
                                                    <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    onChange={(e) => setServiceId(e.target.value)}
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                >
                                                    <option value={"ALL"}>All</option>
                                                    {serviceList.map((type) => (
                                                        type.status ? (
                                                            <option value={type.serviceType}>
                                                                {type.serviceName}
                                                            </option>
                                                        ) : null
                                                    ))}
                                                </select>
                                            </div>


                                            <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />

                                            <div className="col-md-12" style={{ textAlign: 'center' }}>
                                                <button
                                                    onClick={handleUserActivity}
                                                    className="btn btn-primary btn-block text-center"
                                                    style={{ marginTop: '37px', color: 'white', fontWeight: 600 }}
                                                    fdprocessedid="qnf2"
                                                >
                                                    Search
                                                </button>
                                                &nbsp;&nbsp;

                                                <button type="button" name="" className="btn btn-success" style={{ marginTop: "3%" }} onClick={downloadExcel} fdprocessedid="9svl7o">
                                                    <FaFileExcel className="fa fa-file-excel-o" />
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                <center>
                                    <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                        <div>

                                            <div className="col-md-3" style={{ float: "left" }}>
                                                <div>
                                                    <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                    <input
                                                        className="form-control text-box"
                                                        type="text"
                                                        placeholder="SEARCH..."
                                                        value={searchInput}
                                                        onChange={(e) => setSearchInput(e.target.value)}

                                                    />
                                                </div>
                                            </div><br />

                                            <Pagination2
                                                totalPages={Math.ceil(userTxnActivity.length / itemsPerPage)}
                                                currentPage={currentPage}
                                                onChange={handlePageChange}
                                            />

                                            <div className="col-md-3" style={{ float: "right", marginTop: "-7%" }}>
                                                <div>
                                                    <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                    <select
                                                        className="form-control select"
                                                        type="text"
                                                        value={selectedStatus}
                                                        onChange={(e) => setSelectedStatus(e.target.value)}
                                                    >
                                                        <option value=""></option>
                                                        <option value="ACTIVE">ACTIVE</option>
                                                        <option value="INACTIVE">INACTIVE</option>
                                                    </select>
                                                </div>
                                            </div><br />

                                        </div>
                                    </div>
                                </center>

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="px-1">SL.NO</th>
                                                <th>User Id</th>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                                <th>Service Name</th>
                                                <th>Service Id</th>
                                                <th>Transaction Count</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!paginatedData || paginatedData.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="8">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                paginatedData.map((item, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.userId}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{item.serviceName}</td>
                                                        <td>{item.serviceId}</td>
                                                        <td>{item.transactionCount}</td>
                                                        <td style={{ color: item.transactionCount === 0 ? 'red' : 'green' }}>
                                                            {item.transactionCount === 0 ? 'INACTIVE' : 'ACTIVE'}
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <footer className="main-footer" >
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';


export default function Viewdeactivemem() {

    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState(location.state);
    console.log(data)

    const stateData = location.state;


    useEffect(() => {
        
        setData(stateData);
    }, [setData]);

    const [uplineUser, setUplineUser] = useState({});
    const [newStatus, setNewStatus] = useState(data.status == 0);
    

    let memoizedData;

    const fetchData = async () => {
        if (memoizedData) {
            return memoizedData;
        }

        var token = '';
        if (sessionStorage && sessionStorage.token) {
            token = 'Bearer ' + sessionStorage.token;
        } else {
            navigate({
                pathname: "/"
            });
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("user");
            return null; // Return null or some other default value if the token is not available
        }

        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            const response = await fetch("https://uat.softmintdigital.com/softmintadminsoftware/user/" + stateData.userId, requestOptions);
            const result = await response.json();
            console.log(result.user);
            setUplineUser(result.user);

            // Store the result in memoizedData
            memoizedData = result.List;
        } catch (error) {
            console.log('error', error);
            return null; // Return null or some other default value if an error occurs
        }
    }

    

    useEffect(() => {
        fetchData();
    }, []);

    const handleEditClick = () => {
        // Add logic for handling the edit button click
    };

    const handleChangeCommissionTypeClick = () => {
        // Add logic for handling the change commission type button click
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleKYCClick = () => {
        // Add logic for handling the KYC button click
    };


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '238.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">View Member</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Member Master</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="manageMember">Manage Member</Link>
                                    </li>
                                    <li className="breadcrumb-item active">View</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <p className="font-weight-bold text-primary m-0">
                                    Business Information
                                </p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">Customer Type : {data.roleId}</label>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">Business Name  : {data.name}</label>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">GST Number</label>
                                    </div>
                                </div>
                                <hr />

                                <p className="font-weight-bold text-primary m-0">Personal Information</p>

                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">First Name : {data.name}</label>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">Last Name  : NA</label>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">Date of Birth  : NA</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">Address : {data.address}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">Village : NA</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">Pincode  : {data.pincode}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">Taluka  : NA</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">District : {data.district}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">State  : {data.state}</label>
                                    </div>
                                </div>
                                <hr />

                                <p className="font-weight-bold text-primary m-0">Contact Information</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="font-weight-normal">Email : {data.email}</label>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="font-weight-normal">Mobile  : {data.mobile}</label>
                                    </div>
                                </div>
                                <hr />

                                <p className="font-weight-bold text-primary m-0">Balances</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">
                                            Recharge &amp; Utility: <strong> ₹ {data.balance}</strong>
                                        </label>
                                    </div>
                                </div>
                                <hr />

                                <p className="font-weight-bold text-primary m-0">KYC Information</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        KYC :
                                        <span className="text-warning">
                                            Not Verified
                                        </span>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">Aadhaar Number  : {data.aadhaar}</label>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">Pan Number  : {data.pan}</label>
                                    </div>
                                </div>
                                <hr />

                                <p className="font-weight-bold text-primary m-0">Account Information</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <input type="hidden" value="1" id="pass_view_val" />
                                        <label className="font-weight-normal">Password  : NA</label>
                                        <button
                                            className="btn btn-sm btn-light"
                                            onClick={handleShowPassword}
                                            fdprocessedid="gaa6x"
                                        >
                                            <i id="eye" className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                                        </button>
                                        {showPassword && (
                                            <div id="form">
                                                <form action="/viewdeactivemem" method="post" accept-charset="utf-8">
                                                    <input type="hidden" name="securityToken" value="6b48c37cc62023d6fbcd288601e45b4b" />
                                                    <div className="row">
                                                        <div className="col-9">
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                name="pass"
                                                                placeholder="Enter admin password"
                                                            />
                                                        </div>
                                                        <div className="col-3">
                                                            <button className="btn btn-sm btn-danger mt-1">Check</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">Pin  : NA</label>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">Upline  :  {uplineUser.name}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">Package  : MASTER DISTRIBUTOR</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">Recharge Wallet Lock  : 0.00</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">Fund Request Lock : 0.00</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">Money Transfer Lock : 0.00</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">Package Limit : 2</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">Commission Type: MINUS</label>
                                    </div>
                                </div>
                                <hr />

                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="font-weight-normal"> Status : {newStatus? <kbd className="bg-danger">Deactive</kbd> : <kbd className="bg-success">Active</kbd>}</label>
                                    </div>
                                </div>
                                <hr/>


                                <div className="text-center">
                                    <button
                                        className="btn btn-sm btn-primary"
                                        onClick={handleEditClick}
                                    >
                                        <i className="fa fa-edit"></i> Edit
                                    </button>&nbsp;

                                    <button
                                        className="btn btn-sm btn-info"
                                        data-target="#myModal"
                                        data-toggle="modal"
                                        data-backdrop="static"
                                        data-keyboard="false"
                                        onClick={handleChangeCommissionTypeClick}
                                    >
                                        <i className="fas fa-money"></i> Change Commission Type
                                    </button>&nbsp;

                                    <a
                                        className="btn btn-sm btn-warning"
                                        href="/memberkyc"
                                        onClick={handleKYCClick}
                                    >
                                        <i className="fas fa-file"></i> KYC
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

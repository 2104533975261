import React from 'react';
import Navbar from './Navbar';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import RestService from "../http";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import showToast from './utilityComponents/APPToast';
import { FaFileExcel } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import Pagination2 from './utilityComponents/Pagination2';


export default function AllMovetobankUserReport() {

    const [selectedAction, setSelectedAction] = useState(null);

    const [userData, setUserData] = useState([]);
    const service = new RestService();
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredAllMoveToBankReport, setFilteredAllMoveToBankReport] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    // const [loading, setLoading] = useState(false);

    const handleBankUserReport = () => {

        service.post("user/getallmovetobankuser", null).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                // showToast("SUCCESS", result.message);
                setUserData(result.data);

            } else {
                showToast("DANGER", result.message)
                setUserData([])
            }
        }).catch((error) => {
            console.log(error);
        })
    };

    useEffect(() => {
        handleBankUserReport();
    }, []);


    const updateStatus = (beneId, userId) => {

        var raw = {
            "beneId": beneId,
            "userId": userId
        };

        service.post("user/updatestatusmovetobank", raw).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                handleBankUserReport();
                showToast("SUCCESS", result.message);

            } else {
                showToast("FAILED", result.message);
            }
        }).catch(error => {
            console.log(error);
        })
    }


    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);

    const downloadExcel = () => {
        // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
        // writeFile(wb, 'recharge_report.xlsx');

        const ws = XLSX.utils.json_to_sheet(userData);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'MoveToBank User Report');

        XLSX.writeFile(wb, 'bankuser_report.xlsx');
    };


    useEffect(() => {
        const filteredList = userData.filter(item =>
            item.status.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.remMobile.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.remName.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.beneIFSC.toString().includes(searchInput.toLowerCase()) ||
            item.beneAccNo.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.beneId.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.userId.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.validateName.toString().includes(searchInput.toLowerCase()) 
        );
        setFilteredAllMoveToBankReport(filteredList);
    }, [userData, searchInput]);

    const paginatedMoveToBankReport = filteredAllMoveToBankReport.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };


    return (
        <>
            <Navbar />
            <ToastContainer />

            <div className="content-wrapper" style={{ minHeight: "620.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">MoveToBank User Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">User</Link>
                                    </li>
                                    <li className="breadcrumb-item active">MoveToBank User Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>


                <section className="content">
                    <div className="container-fluid">
                        <div className="card">

                            <center>
                                <div className="box-content" style={{ padding: "0", marginTop: "2%" }}>
                                    <div>

                                        <div className="col-md-3" style={{ float: "left" }}>
                                            <div>
                                                <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                <input
                                                    className="form-control text-box"
                                                    type="text"
                                                    placeholder="SEARCH..."
                                                    value={searchInput}
                                                    onChange={(e) => setSearchInput(e.target.value)}

                                                />
                                            </div>
                                        </div><br />

                                        <Pagination2
                                            totalPages={Math.ceil(userData.length / itemsPerPage)}
                                            currentPage={currentPage}
                                            onChange={handlePageChange}

                                        />
                                    </div>
                                </div>
                            </center>

                            <div className='col-md-12 text-right'>
                                <button type="button" name="" className="btn btn-success" onClick={downloadExcel} fdprocessedid="9svl7o">
                                    <FaFileExcel className="fa fa-file-excel-o" />
                                </button>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr className='text-center'>
                                                <th>INDEX</th>
                                                <th>Validate Name</th>
                                                <th>User Id</th>
                                                <th>Bene Id</th>
                                                <th>Acc. No</th>
                                                <th>IFSC</th>
                                                <th>Remeter Name</th>
                                                <th>Mobile</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {(!paginatedMoveToBankReport || paginatedMoveToBankReport.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="10">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                paginatedMoveToBankReport.map((option, index) => (
                                                    <tr className='text-center'>
                                                        <td>{index + 1}</td>
                                                        <td>{option.validateName}</td>
                                                        <td>{option.userId}</td>
                                                        <td>{option.beneId}</td>
                                                        <td className="font-weight-bold">{option.beneAccNo}</td>
                                                        <td>{option.beneIFSC}</td>
                                                        <td>{option.remName}</td>
                                                        <td>{option.remMobile}</td>
                                                        <td>
                                                            {option.status === "1" ? (
                                                                <span
                                                                    onClick={() =>
                                                                        updateStatus(
                                                                            option.beneId,
                                                                            option.userId
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge badge-success"

                                                                >
                                                                    ACTIVE
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    onClick={() =>
                                                                        updateStatus(
                                                                            option.beneId,
                                                                            option.userId
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge badge-danger"
                                                                >
                                                                    INACTIVE
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <Link to={'/updatemovetobankuser'} state={option} className="btn btn-sm btn-primary btn-block">
                                                                EDIT
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}

                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <td
                                                    colSpan="4"
                                                    style={{ fontWeight: "bold" }}
                                                    className="text-primary"
                                                    rowSpan="1"
                                                >
                                                    Row Count {userData.length}
                                                </td>
                                                <td
                                                    className="text-right font-weight-bold text-success"

                                                >
                                                </td>
                                                
                                            </tr>
                                        </tfoot>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

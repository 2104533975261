import React from 'react';
import Navbar from './Navbar';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import RestService from '../http';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';


export default function DetailedPendingViewTickets() {
    const rest = new RestService();
    const location = useLocation();
    const [option, setOption] = useState({});
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [remark, setRemark] = useState("");
    const stateData = location.state;
    useEffect(() => {

        setData(stateData);
    }, [setData]);



    useEffect(() => {
        setOption(data);
    }, [setOption]);


    const handleProcess = (ticketId) => {

        var raw = {
            "ticketId": ticketId,
            "actionKey": 1,
            "transferTo": "",
            "reason": remark
        }
                
        rest.post("emp/processTicketRequest", raw).then(result => {
            console.log(result);
            if(result.status === rest.SUCCESS) {
                showToast("SUCCESS", result.message);

                setTimeout(() => {
                   window.location.href ='/viewpendingtickets';
                }, 2000)

            } else {
                showToast("DANGER", result.message);
            }
        })
    }

    return (
        <>
            <Navbar />
            <ToastContainer/>

            <div className="content-wrapper" style={{ minHeight: '553.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Detailed View Ticket</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Member Master</Link></li>
                                    <li className="breadcrumb-item"><Link to="/managemember">View Ticket</Link></li>
                                    <li className="breadcrumb-item active">View</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}> Ticket Id :</span> {data.ticketId}</label>
                                    </div>

                                    <div className="col-md-4">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Opening Date :</span>{data.openingDate}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Closing Date :</span> {data.closingDate}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Transaction Id :</span> {data.refNo}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>User Id :</span> {data.userId}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>User Message :</span> {data.message}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Employee Id :</span> {data.ticketAssignee} </label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Is Priority :</span> {data.isPriority}</label>
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Service :</span> {data.service}</label>
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Service Category :</span> {data.serviceCategory}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Status :</span> {data.status}</label>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-6 mt-4'>
                                        <label>
                                            Remark: <span className="text-danger"> *</span>
                                        </label>
                                        <input
                                            placeholder="Add Remark..."
                                            className="form-control select2 "
                                            id="service_id"
                                            data-select2-id="service_id"
                                            aria-hidden="true"
                                            onChange={(e) => setRemark(e.target.value)}
                                        />

                                    </div>

                                </div>


                                <div className='mt-2 text-center' style={{ float: "right" }}>
                                    <Link className='btn bg-blue' to='/viewpendingtickets'>Back</Link>&nbsp;&nbsp;
                                    <Link className='btn bg-green' onClick={(e) => handleProcess (data.ticketId)}>Accept</Link>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React, { useEffect } from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import RestService from '../http';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';
import Select from 'react-select';
import { Link } from 'react-router-dom';


export default function AddUserMap() {

    const service = new RestService();
    const [roles, setRoles] = useState([]);
    const [userId, setUserId] = useState('');
    const [userList, setUserList] = useState([]);
    const [apiCode, setApiCode] = useState("");
    const [agentCode, setAgentCode] = useState("");
    const [device, setDevice] = useState("");
    const [bankSwitch, setBankSwitch] = useState("");
    const [loading, setLoading] = useState(false);


    const handleAddUserMap = () => {

        var raw = {
            "userId": userId,
            // "apiCode": apiCode,
            "agentcode": agentCode,
            "status": "",
            "kyc": "",
            "device": device,
            // "bankSwitch": bankSwitch
        }

        setLoading(true);

        service.post("package/addusermapped", raw).then(result => {
            setLoading(false);

            console.log(result);

            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);

            } else {
                showToast("DANGER", result.message);

            }
        }).catch((error) => {
            console.log(error);
        })
    }


    const handleCustomerType = (e) => {
        service
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setUserList(result.data);
                } else {
                    showToast("DANGER", result.message)
                    setUserList([]);
                }
            })
            .catch((error) => {
                console.error(error);
                setUserList([]);

            });
    };


    useEffect(() => {
        service.get("user/roles").then(result => {
            console.log(result);
            if (result.role === undefined) {
                setRoles([])
            } else {
                setRoles(result.role);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);


    const [selectedUserId, setSelectedUserId] = useState(null);

    const options = [
        { value: '', label: 'Select an option' },
        ...userList.map((item) => ({
            value: item.userId,
            label: `${item.name} || ${item.mobile}`,
        }))
    ];

    const handleSelectChange = (selectedUserId) => {
        setSelectedUserId(selectedUserId);
        setUserId(selectedUserId.value);
    };

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: '532.667px' }}>

                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add User Map</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Settings</Link></li>
                                    <li className="breadcrumb-item"><Link to="/operatormast">UserMap</Link></li>
                                    <li className="breadcrumb-item active"> Add UserMap</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='content'>
                    <div className='container-fluid'>
                        <div className='card' id='addpack'>
                            <div className="card-header" >
                                Add UserMap
                            </div>

                            <div className='card-body'>
                                <div className="row">
                                    <div className="col-md-3" data-select2-id="4">
                                        <label for="assign_commission">
                                            User Type: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={handleCustomerType}
                                        >
                                            <option>Select User Type</option>
                                            {roles.map((item) => (
                                                item.roleId !== 0 ? <option value={item.roleId}>{item.roleName}</option> : null
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-md-3" data-select2-id="4">
                                        <label for="service_id">
                                            User Id: <span className="text-danger"> *</span>
                                        </label>
                                        {/* <select
                                            className="form-control select2 "
                                            name="service_id"
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={(e) => setUserId(e.target.value)}
                                        >
                                            <option value="">Select Id...</option>
                                            {userList.map((item) => (
                                                <option value={item.userId}>{item.name}</option>
                                            ))}
                                        </select> */}

                                        <Select
                                            className="select-box width100p"
                                            options={options}
                                            isSearchable
                                            value={selectedUserId}
                                            onChange={handleSelectChange}
                                        />
                                    </div>

                                    {/* <div className="col-md-3">
                                        <label className="font-weight-normal">
                                            Api Code: <span className="text-danger"> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="Api_Code"
                                            className="form-control"
                                            onChange={(e) => setApiCode(e.target.value)}
                                        />
                                    </div> */}

                                    <div className="col-md-3">
                                        <label className="font-weight-normal">
                                            Agent Code: <span className="text-danger"> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="Agent_Code"
                                            className="form-control"
                                            onChange={(e) => setAgentCode(e.target.value)}
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label className="font-weight-normal">
                                            Device: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            name="Device"
                                            className="form-control"
                                            onChange={(e) => setDevice(e.target.value)}
                                        >
                                            <option value="">Select...</option>
                                            <option value={"Biometric"}>BIOMETRIC</option>
                                            <option value={"Iris"}>IRIS</option>
                                        </select>
                                    </div>

                                    {/* <div className="col-md-3 mt-2">
                                        <label className="font-weight-normal">
                                            Bank Switch: <span className="text-danger"> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="Bank_switch"
                                            className="form-control"
                                            onChange={(e) => setBankSwitch(e.target.value)}
                                        />
                                    </div> */}

                                </div>

                                <div className='raw mt-3 text-center'>
                                    <div className='col-md-12'>
                                        <button className='btn bg-green' onClick={handleAddUserMap}>Add UserMap</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from "react";
import Navbar from "./Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import RestService from "../http";
import showToast from "./utilityComponents/APPToast";

export default function Updatemovetobankuser() {

    const navigate = useNavigate();
    const location = useLocation();
    const [userData, setUserData] = useState(location.state);
    const service = new RestService();

    const [option, setOption] = useState({});
    const [validateName, setValidateName] = useState(userData.validateName);
    const [userId, setuserId] = useState(userData.userId);
    const [beneId, setBeneId] = useState(userData.beneId);
    const [accNo, setAccNo] = useState(userData.beneAccNo);
    const [ifsc, setIfsc] = useState(userData.beneIFSC);
    const [remeName, setRemeName] = useState(userData.remName);
    const [mobile, setMobile] = useState(userData.remMobile);
    const [status, setStatus] = useState(userData.status);

    let memoizedData;


    useEffect(() => {
        setOption(userData);
    }, [setOption]);


    const fetchEditData = () => {

        var raw = {
            "bankName": validateName,
            "beneAccNo": accNo,
            "beneIFSC": ifsc,
            "remName": remeName,
            "remMobile": mobile,
            "userId": userData.userId

        };

        service.post("user/updatemovetobankuser", raw).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);

                setTimeout(() => {
                    window.location.href = '/allmovetobankuserreport';
                }, 2000);

            } else {
                showToast("DANGER", result.message);

                // setTimeout(() => {
                //     window.location.reload();
                // }, 3000);
            }
        })
    };


    const handleAccNo = (e) => {
        const { value } = e.target;
        setAccNo(value);
    };

    const handleIfsc = (e) => {
        const { value } = e.target;
        setIfsc(value);
    };

    const handleRemeName = (e) => {
        const { value } = e.target;
        setRemeName(value);
    };

    const handleMobile = (e) => {
        const { value } = e.target;
        setMobile(value);
    };


    return (
        <>
            <Navbar />
            <ToastContainer />

            <div className="content-wrapper" style={{ minHeight: "203.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Edit MoveToBank User</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">User Master</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/managemember">Manage User</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Edit</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div method="POST" encType="multipart/form-data">

                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="font-weight-normal">
                                                Name <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                // onChange={handleName}
                                                name="first_name"
                                                type="text"
                                                className="form-control"
                                                value={validateName}
                                                required=""
                                                placeholder="ENTER VALIDATE NAME"
                                                fdprocessedid="prhwrd"
                                            />
                                        </div>

                                        <div className="col-md-4">
                                            <label className="font-weight-normal">
                                                User Id <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                name="userId"
                                                type="text"
                                                className="form-control"
                                                value={userId}
                                                required=""
                                                placeholder="ENTER ADDRESS"
                                                fdprocessedid="z5je8i"
                                            />
                                        </div>

                                        <div className="col-md-4">
                                            <label className="font-weight-normal">
                                                Benefissiary Id <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                name="Beneid"
                                                type="text"
                                                className="form-control"
                                                value={beneId}
                                                // maxLength={10}
                                                placeholder="ENTER BENE ID"
                                                fdprocessedid="z5je8i"
                                            />
                                        </div>

                                        <div className="col-md-4 ">
                                            <label className="font-weight-normal">
                                                Account Number <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                onChange={handleAccNo}
                                                name="AccNo"
                                                type="text"
                                                className="form-control"
                                                value={accNo}
                                                required=""
                                                placeholder="Enter Account Number"
                                                fdprocessedid="n4v9e7"
                                            />
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">
                                                IFSC <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                onChange={handleIfsc}
                                                name="ifsc"
                                                type="text"
                                                className="form-control"
                                                value={ifsc}
                                                placeholder="Enter Ifsc"
                                                required=""
                                                pattern="[0-9]{6}"
                                                fdprocessedid="ccwsf3"
                                            />
                                        </div>

                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">
                                                Remeter Name <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                onChange={handleRemeName}
                                                name="remename"
                                                type="text"
                                                className="form-control"
                                                value={remeName}
                                                placeholder="Enter Reme Name"
                                                required=""
                                            />
                                        </div>

                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">
                                                Mobile <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                onChange={handleMobile}
                                                name="mobile"
                                                id="district"
                                                type="text"
                                                className="form-control"
                                                value={mobile}
                                                required=""
                                                placeholder="Enter Mobile"
                                                fdprocessedid="ieubp"
                                            />
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <label className="font-weight-normal">
                                                Status <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                // onChange={handleState}
                                                name="status"
                                                id="state"
                                                type="text"
                                                className="form-control"
                                                value={status}
                                                required=""
                                                fdprocessedid="742tr"
                                            />
                                        </div>
                                    </div>
                                    <hr />

                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <button
                                                className="btn btn-primary float-right"
                                                onClick={fetchEditData}
                                            >
                                                Update
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-danger float-right mr-3"
                                            >
                                                <Link to="/member" style={{ color: "white" }}>
                                                    Cancel
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    );
}

import React from 'react';
import Navbar from './Navbar';
import { FaFileExcel, FaFilePdf, FaCopy } from "react-icons/fa";
import { Link } from 'react-router-dom';


export default function Logindetail() {

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '203.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Member Login Details</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Member Master</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Manage Login Details</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="">
                                    <form
                                        action="#"
                                        className="form-inline"
                                        method="post"
                                        acceptCharset="utf-8"
                                    >
                                        <input
                                            type="hidden"
                                            name="securityToken"
                                            value="55b9bc60523c0753e329ec7da5c677c6"
                                        />
                                        <div className="form-group mb-2">
                                            <label htmlFor="rec_to_load">Show Records</label>
                                            <select
                                                className="form-control ml-3 mr-3"
                                                name="rec_to_load"
                                                id="rec_to_load"
                                                required="required"
                                                onChange="loadRecords(this.value)"
                                                fdprocessedid="r2far6"
                                            >
                                                <option value="">Select Option</option>
                                                <option value="250">250 Records</option>
                                                <option value="500">500 Records</option>
                                                <option value="1000">1000 Records</option>
                                                <option value="2500">2500 Records</option>
                                                <option value="5000">5000 Records</option>
                                                <option value="7500">7500 Records</option>
                                                <option value="10000">10000 Records</option>
                                            </select>
                                            <input
                                                type="submit"
                                                className="btn btn-primary text-white"
                                                value="Show"
                                                fdprocessedid="fvfah8"
                                            />
                                            <a
                                                href="/logindetail"
                                                className="btn btn-warning text-white ml-3"
                                            >
                                                Reset Filter
                                            </a>
                                        </div>
                                    </form>
                                </div>
                                <div className="table-responsive" style={{ marginTop: '20px' }}>
                                    <div
                                        id="example1_wrapper"
                                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                                    >
                                        <div
                                            className="dataTables_length"
                                            id="example1_length"
                                        >
                                            <label>
                                                Show{' '}
                                                <select
                                                    name="example1_length"
                                                    aria-controls="example1"
                                                    className="custom-select custom-select-sm form-control form-control-sm"
                                                    fdprocessedid="ihk39l"
                                                >
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="All">All</option>
                                                </select>{' '}
                                                entries
                                            </label>
                                        </div>
                                        <div className="dt-buttons">
                                            <a
                                                className="dt-button buttons-copy buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="Copy"
                                            >
                                                <span>
                                                    <FaCopy className="fa fa-files-o text-info font-weight-bold" />
                                                </span>
                                            </a>
                                            <a
                                                className="dt-button buttons-excel buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="Excel"
                                            >
                                                <span>
                                                    <FaFileExcel className="fa fa-file-excel-o text-success font-weight-bold" />
                                                </span>
                                            </a>
                                            <a
                                                className="dt-button buttons-pdf buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="PDF"
                                            >
                                                <span>
                                                    <FaFilePdf className="fa fa-file-pdf-o text-danger font-weight-bold" />
                                                </span>
                                            </a>
                                        </div>
                                        <table
                                            id="example1"
                                            className="table m-0 table-striped table-bordered dataTable no-footer"
                                            role="grid"
                                            aria-describedby="example1_info"
                                        >
                                            <thead>
                                                <tr role="row">
                                                    <th
                                                        className="sorting_asc"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        ariaSort="ascending"
                                                        aria-label="#: activate to sort column descending"
                                                        style={{ width: '8.45833px' }}
                                                    >
                                                        #
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Member Name: activate to sort column ascending"
                                                        style={{ width: '250.177px' }}
                                                    >
                                                        Member Name
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Number: activate to sort column ascending"
                                                        style={{ width: '57.2708px' }}
                                                    >
                                                        Number
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="IP Address: activate to sort column ascending"
                                                        style={{ width: '73.7292px' }}
                                                    >
                                                        IP Address
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Last Login Time: activate to sort column ascending"
                                                        style={{ width: '118.198px' }}
                                                    >
                                                        Last Login Time
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Login Type: activate to sort column ascending"
                                                        style={{ width: '76.5625px' }}
                                                    >
                                                        Login Type
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Device Name: activate to sort column ascending"
                                                        style={{ width: '178.375px' }}
                                                    >
                                                        Device Name
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Action: activate to sort column ascending"
                                                        style={{ width: '45.0625px' }}
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody id="result">
                                                <tr role="row" className="odd">
                                                    <td className="sorting_1">1</td>
                                                    <td>Sharad Bandarkar [MA1000] [8600436163]</td>
                                                    <td>8600436163</td>
                                                    <td>122.161.48.146</td>
                                                    <td>12 Jul 20233:35:06 PM</td>
                                                    <td>WEB</td>
                                                    <td>Windows 10|Chrome(114.0.0.0)</td>
                                                    <td>
                                                        {' '}
                                                        <Link to="/memberlogindetail">
                                                            <i className="fa fa-eye" title="view" />
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="even">
                                                    <td className="sorting_1">2</td>
                                                    <td>AJOY MONDAL [MD1003] [9609236025]</td>
                                                    <td>9609236025</td>
                                                    <td>103.101.213.22</td>
                                                    <td>11 Jul 20237:08:54 PM</td>
                                                    <td>WEB</td>
                                                    <td>Windows 10|Chrome(114.0.0.0)</td>
                                                    <td>
                                                        {' '}
                                                        <Link to="/memberlogindetail">
                                                            <i className="fa fa-eye" title="view" />
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="odd">
                                                    <td className="sorting_1">3</td>
                                                    <td>NAVNATH SASE [RT1001] [8600707581]</td>
                                                    <td>8600707581</td>
                                                    <td>122.161.51.183</td>
                                                    <td>07 Jul 20233:54:21 PM</td>
                                                    <td>WEB</td>
                                                    <td>Windows 10|Chrome(114.0.0.0)</td>
                                                    <td>
                                                        {' '}
                                                        <Link to="/memberlogindetail">
                                                            <i className="fa fa-eye" title="view" />
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr role="row" className="even">
                                                    <td className="sorting_1">4</td>
                                                    <td>ARUL DOSS [MD1002] [7904634883]</td>
                                                    <td>7904634883</td>
                                                    <td>122.161.51.183</td>
                                                    <td>07 Jul 20233:52:51 PM</td>
                                                    <td>WEB</td>
                                                    <td>Windows 10|Chrome(114.0.0.0)</td>
                                                    <td>
                                                        {' '}
                                                        <Link to="/memberlogindetail">
                                                            <i className="fa fa-eye" title="view" />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div
                                            className="dataTables_info"
                                            id="example1_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            Showing 1 to 4 of 4 entries
                                        </div>
                                        <div
                                            className="dataTables_paginate paging_simple_numbers"
                                            id="example1_paginate"
                                        >
                                            <ul className="pagination">
                                                <li
                                                    className="paginate_button page-item previous disabled"
                                                    id="example1_previous"
                                                >
                                                    <a
                                                        aria-controls="example1"
                                                        data-dt-idx="0"
                                                        tabIndex="0"
                                                        className="page-link"
                                                    >
                                                        Previous
                                                    </a>
                                                </li>
                                                <li
                                                    className="paginate_button page-item active"
                                                    aria-current="page"
                                                >
                                                    <a
                                                        aria-controls="example1"
                                                        data-dt-idx="1"
                                                        tabIndex="0"
                                                        className="page-link"
                                                    >
                                                        1
                                                    </a>
                                                </li>
                                                <li
                                                    className="paginate_button page-item next disabled"
                                                    id="example1_next"
                                                >
                                                    <a
                                                        aria-controls="example1"
                                                        data-dt-idx="2"
                                                        tabIndex="0"
                                                        className="page-link"
                                                    >
                                                        Next
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

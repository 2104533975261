import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';


export default function Viewapi() {

    const options = [
        { value: '', label: 'Select Package' },
        { value: '1', label: 'DEFAULT' },
        { value: '2', label: 'RETAILER' },
        { value: '3', label: 'CUSTOMER' },
        { value: '4', label: 'DISTRIBUTOR' },
        { value: '5', label: 'MASTER DISTRIBUTOR' },
    ];

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const dropdownRef = useRef();

    //   const toggleDropdown = () => {
    //     setDropdownOpen((prevState) => !prevState);
    //   };

    const handleOptionSelect = (option) => {
        setSelectedOption(option.value);
        setDropdownOpen(false);
        console.log('Selected Value:', option.value);

        if (option.value === '1') {
            setShowCard(true);
        } else {
            setShowCard(false);
        }
    };

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    // Add an event listener to handle clicks outside the dropdown
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);



    const tabsData = [
        {
            id: 'tab_1',
            imgSrc: './image/postpaid.png',
            alt: 'PREPAID',
        },
        {
            id: 'tab_2',
            imgSrc: './image/postpaid.png',
            alt: 'POSTPAID',
        },
        {
            id: 'tab_4',
            imgSrc: './image/dth.png',
            alt: 'DTH',
        },
        {
            id: 'tab_5',
            imgSrc: './image/landline.png',
            alt: 'LANDLINE',
        },
        {
            id: 'tab_6',
            imgSrc: './image/electricity.png',
            alt: 'ELECTRICITY',
        },
        {
            id: 'tab_7',
            imgSrc: './image/GasCylinder.png',
            alt: 'GAS',
        },
        {
            id: 'tab_8',
            imgSrc: './image/insurance.png',
            alt: 'INSURANCE',
        },
        {
            id: 'tab_10',
            imgSrc: './image/Broadband.png',
            alt: 'BROADBAND',
        },
        {
            id: 'tab_12',
            imgSrc: './image/LoanEMI.png',
            alt: 'LOAN EMI',
        },
        {
            id: 'tab_14',
            imgSrc: './image/Water.png',
            alt: 'WATER',
        },
        {
            id: 'tab_17',
            imgSrc: './image/407973.png',
            alt: 'BOOKING GAS',
        },
        {
            id: 'tab_18',
            imgSrc: './image/fastag.png',
            alt: 'FASTAG',
        },
        {
            id: 'tab_19',
            imgSrc: './image/58359.png',
            alt: 'SUBSCRIPTION (OTT)',
        },
        {
            id: 'tab_24',
            imgSrc: './image/play.png',
            alt: 'GOOGLE PLAY',
        },
    ];

    const [activeTab, setActiveTab] = useState(tabsData[0].id);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const validateInput = () => {
        // Add validation logic here if needed
    };

    const [showCard, setShowCard] = useState(true);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedOption === '1') {
            setShowCard(true);
        }
    };


    useEffect(() => {
        setShowCard(selectedOption !== '');
    }, [selectedOption]);

    // Function to get APIs when clicking on "View" button
    const get_apis = (apiId) => {
        // Implement your logic here
        console.log(`Getting APIs for ID: ${apiId}`);
    };

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '528.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">View API: OFFLINE</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Settings</Link></li>
                                    <li className="breadcrumb-item"><Link to="/apicommison">API And Commission</Link></li>
                                    <li className="breadcrumb-item active">View API : OFFLINE</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className='container-fluid'>
                        <div className='card' >
                            
                                <div className="bg-light card-header" style={{ borderRadius: '4px', boxShadow: '0px 0px 5px 4px #767679' }}>
                                    <ul className="nav nav-tabs" id="custom-tabs-one-tab" role="tablist" style={{ overflowY: 'auto', overflowX: 'hidden', height: '80px' }}>
                                        {tabsData.map((tab) => (
                                            <li className="nav-item" style={{ display: 'inline-block' }} key={tab.id}>
                                                <a className={`nav-link color2 ${activeTab === tab.id ? 'active' : ''}`} id={tab.id} data-toggle="pill" href={`#${tab.id}`} role="tab" aria-controls={tab.id} aria-selected="true" onClick={() => handleTabClick(tab.id)}>
                                                    <div className="text-center">
                                                        <img src={tab.imgSrc} alt={tab.alt} style={{ width: '45px' }} />
                                                    </div>
                                                    {tab.alt}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <div className='tab-content mt-3' id='custom-tabs-one-tabContent'>

                                    {/* Prepaid */}

                                    < div className={`tab-pane fade ${activeTab === 'tab_1' ? 'show active' : ''}`} id="tab_1" role="tabpanel" aria-labelledby="ref_1">
                                        <div className="table-responsive">
                                            <form action="#" method="post" onSubmit={validateInput} acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="4148aa44b74f44fabc8550f138f34cb1" />
                                                <table className="table table-bordered table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Operator Name</th>
                                                            <th>API</th>
                                                            <th>Given Commission</th>
                                                            <th>Commission Type</th>
                                                            <th>Is Flat</th>
                                                            <th>Active / Deactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Add PREPAID tab rows here */}
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Airtel</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('160')} fdprocessedid="aqm2ob">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[1]" value="3.00" fdprocessedid="iv502g" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[1]" fdprocessedid="si8qpf">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[1]" fdprocessedid="w8r1au">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[1]" fdprocessedid="zp5vud">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>VI  -   Vodafone &amp; Idea</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('161')} fdprocessedid="1n4fxm">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[2]" value="1.00" fdprocessedid="e5uchr" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[2]" fdprocessedid="nidfdq">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[2]" fdprocessedid="ygolmx">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[2]" fdprocessedid="4hvbu8">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>BSNL Special (STV)</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('163')} fdprocessedid="e3b7yc">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[6]" value="3.00" fdprocessedid="syrlul" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[6]" fdprocessedid="s0g1s4">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[6]" fdprocessedid="a23ncu">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[6]" fdprocessedid="w4b6e4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>BSNL</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('796')} fdprocessedid="q0rqvj">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[178]" value="1.00" fdprocessedid="rpfjfu" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[178]" fdprocessedid="iyuoks">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[178]" fdprocessedid="jnfufc">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[178]" fdprocessedid="mq11y4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Jio</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('800')} fdprocessedid="9pbzg">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[179]" value="1.00" fdprocessedid="fnc8r" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[179]" fdprocessedid="p6sf5">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[179]" fdprocessedid="577w9g">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[179]" fdprocessedid="1zot9">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="m-auto text-center">
                                                    <input type="submit" value="SAVE" className="btn btn-primary" fdprocessedid="6m9zz" />
                                                </div>
                                                <br/>
                                            </form>
                                        </div>
                                    </div>


                                    {/* POSTPAID Tab Content */}

                                    < div className={`tab-pane fade ${activeTab === 'tab_2' ? 'show active' : ''}`} id="tab_2" role="tabpanel" aria-labelledby="ref_2">
                                        <div className="table-responsive">
                                            <form action="#" method="post" onSubmit={validateInput} acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="4148aa44b74f44fabc8550f138f34cb1" />
                                                <table className="table table-bordered table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Operator Name</th>
                                                            <th>API</th>
                                                            <th>Given Commission</th>
                                                            <th>Commission Type</th>
                                                            <th>Is Flat</th>
                                                            <th>Active / Deactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Add POSTPAID tab rows here */}
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Airtel</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('160')} fdprocessedid="aqm2ob">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[1]" value="5.00" fdprocessedid="iv502g" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[1]" fdprocessedid="si8qpf">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[1]" fdprocessedid="w8r1au">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[1]" fdprocessedid="zp5vud">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>VI  -   Vodafone &amp; Idea</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('161')} fdprocessedid="1n4fxm">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[2]" value="2.00" fdprocessedid="e5uchr" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[2]" fdprocessedid="nidfdq">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[2]" fdprocessedid="ygolmx">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[2]" fdprocessedid="4hvbu8">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>BSNL Special (STV)</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('163')} fdprocessedid="e3b7yc">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[6]" value="3.00" fdprocessedid="syrlul" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[6]" fdprocessedid="s0g1s4">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[6]" fdprocessedid="a23ncu">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[6]" fdprocessedid="w4b6e4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>BSNL</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('796')} fdprocessedid="q0rqvj">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[178]" value="1.00" fdprocessedid="rpfjfu" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[178]" fdprocessedid="iyuoks">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[178]" fdprocessedid="jnfufc">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[178]" fdprocessedid="mq11y4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Jio</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('800')} fdprocessedid="9pbzg">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[179]" value="1.00" fdprocessedid="fnc8r" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[179]" fdprocessedid="p6sf5">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[179]" fdprocessedid="577w9g">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[179]" fdprocessedid="1zot9">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="m-auto text-center">
                                                    <input type="submit" value="SAVE" className="btn btn-primary" fdprocessedid="6m9zz" />
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    {/* {DTH} */}
                                    <div className={`tab-pane fade ${activeTab === 'tab_4' ? 'show active' : ''}`} id="tab_4" role="tabpanel" aria-labelledby="ref_4">
                                        <div className="table-responsive">
                                            <form action="#" method="post" onSubmit={validateInput} acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="4148aa44b74f44fabc8550f138f34cb1" />
                                                <table className="table table-bordered table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Operator Name</th>
                                                            <th>API</th>
                                                            <th>Given Commission</th>
                                                            <th>Commission Type</th>
                                                            <th>Is Flat</th>
                                                            <th>Active / Deactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Add PREPAID tab rows here */}
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Airtel</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('160')} fdprocessedid="aqm2ob">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[1]" value="7.00" fdprocessedid="iv502g" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[1]" fdprocessedid="si8qpf">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[1]" fdprocessedid="w8r1au">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[1]" fdprocessedid="zp5vud">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>VI  -   Vodafone &amp; Idea</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('161')} fdprocessedid="1n4fxm">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[2]" value="6.00" fdprocessedid="e5uchr" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[2]" fdprocessedid="nidfdq">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[2]" fdprocessedid="ygolmx">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[2]" fdprocessedid="4hvbu8">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>BSNL Special (STV)</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('163')} fdprocessedid="e3b7yc">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[6]" value="3.00" fdprocessedid="syrlul" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[6]" fdprocessedid="s0g1s4">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[6]" fdprocessedid="a23ncu">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[6]" fdprocessedid="w4b6e4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>BSNL</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('796')} fdprocessedid="q0rqvj">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[178]" value="1.00" fdprocessedid="rpfjfu" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[178]" fdprocessedid="iyuoks">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[178]" fdprocessedid="jnfufc">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[178]" fdprocessedid="mq11y4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Jio</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('800')} fdprocessedid="9pbzg">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[179]" value="1.00" fdprocessedid="fnc8r" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[179]" fdprocessedid="p6sf5">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[179]" fdprocessedid="577w9g">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[179]" fdprocessedid="1zot9">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="m-auto text-center">
                                                    <input type="submit" value="SAVE" className="btn btn-primary" fdprocessedid="6m9zz" />
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    {/* Landline */}
                                    <div className={`tab-pane fade ${activeTab === 'tab_5' ? 'show active' : ''}`} id="tab_5" role="tabpanel" aria-labelledby="ref_5">
                                        <div className="table-responsive">
                                            <form action="#" method="post" onSubmit={validateInput} acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="4148aa44b74f44fabc8550f138f34cb1" />
                                                <table className="table table-bordered table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Operator Name</th>
                                                            <th>API</th>
                                                            <th>Given Commission</th>
                                                            <th>Commission Type</th>
                                                            <th>Is Flat</th>
                                                            <th>Active / Deactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Add LANDLINE tab rows here */}
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Airtel</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('160')} fdprocessedid="aqm2ob">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[1]" value="8.00" fdprocessedid="iv502g" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[1]" fdprocessedid="si8qpf">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[1]" fdprocessedid="w8r1au">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[1]" fdprocessedid="zp5vud">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>VI  -   Vodafone &amp; Idea</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('161')} fdprocessedid="1n4fxm">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[2]" value="6.00" fdprocessedid="e5uchr" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[2]" fdprocessedid="nidfdq">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[2]" fdprocessedid="ygolmx">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[2]" fdprocessedid="4hvbu8">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>BSNL Special (STV)</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('163')} fdprocessedid="e3b7yc">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[6]" value="3.00" fdprocessedid="syrlul" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[6]" fdprocessedid="s0g1s4">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[6]" fdprocessedid="a23ncu">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[6]" fdprocessedid="w4b6e4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>BSNL</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('796')} fdprocessedid="q0rqvj">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[178]" value="1.00" fdprocessedid="rpfjfu" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[178]" fdprocessedid="iyuoks">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[178]" fdprocessedid="jnfufc">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[178]" fdprocessedid="mq11y4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Jio</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('800')} fdprocessedid="9pbzg">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[179]" value="1.00" fdprocessedid="fnc8r" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[179]" fdprocessedid="p6sf5">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[179]" fdprocessedid="577w9g">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[179]" fdprocessedid="1zot9">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="m-auto text-center">
                                                    <input type="submit" value="SAVE" className="btn btn-primary" fdprocessedid="6m9zz" />
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    {/* Electricity */}
                                    <div className={`tab-pane fade ${activeTab === 'tab_6' ? 'show active' : ''}`} id="tab_6" role="tabpanel" aria-labelledby="ref_6">
                                        <div className="table-responsive">
                                            <form action="#" method="post" onSubmit={validateInput} acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="4148aa44b74f44fabc8550f138f34cb1" />
                                                <table className="table table-bordered table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Operator Name</th>
                                                            <th>API</th>
                                                            <th>Given Commission</th>
                                                            <th>Commission Type</th>
                                                            <th>Is Flat</th>
                                                            <th>Active / Deactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Add ELECTRICITY tab rows here */}
                                                        <tr>
                                                            <td>1</td>
                                                            <td>North Bihar Power Distribution Company Ltd.</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('160')} fdprocessedid="aqm2ob">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[1]" value="10.00" fdprocessedid="iv502g" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[1]" fdprocessedid="si8qpf">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[1]" fdprocessedid="w8r1au">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[1]" fdprocessedid="zp5vud">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>South Bihar Power Distribution Company Ltd.</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('161')} fdprocessedid="1n4fxm">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[2]" value="6.00" fdprocessedid="e5uchr" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[2]" fdprocessedid="nidfdq">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[2]" fdprocessedid="ygolmx">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[2]" fdprocessedid="4hvbu8">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>Best Electricity - Mumbai</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('163')} fdprocessedid="e3b7yc">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[6]" value="3.00" fdprocessedid="syrlul" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[6]" fdprocessedid="s0g1s4">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[6]" fdprocessedid="a23ncu">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[6]" fdprocessedid="w4b6e4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>Maharashtra State Electricity Distribution Company Ltd.</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('796')} fdprocessedid="q0rqvj">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[178]" value="1.00" fdprocessedid="rpfjfu" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[178]" fdprocessedid="iyuoks">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[178]" fdprocessedid="jnfufc">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[178]" fdprocessedid="mq11y4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>NORTH DELHI POWER LIMITED</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('800')} fdprocessedid="9pbzg">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[179]" value="1.00" fdprocessedid="fnc8r" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[179]" fdprocessedid="p6sf5">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[179]" fdprocessedid="577w9g">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[179]" fdprocessedid="1zot9">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="m-auto text-center">
                                                    <input type="submit" value="SAVE" className="btn btn-primary" fdprocessedid="6m9zz" />
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    {/* GAS */}
                                    <div className={`tab-pane fade ${activeTab === 'tab_7' ? 'show active' : ''}`} id="tab_7" role="tabpanel" aria-labelledby="ref_7">
                                        <div className="table-responsive">
                                            <form action="#" method="post" onSubmit={validateInput} acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="4148aa44b74f44fabc8550f138f34cb1" />
                                                <table className="table table-bordered table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Operator Name</th>
                                                            <th>API</th>
                                                            <th>Given Commission</th>
                                                            <th>Commission Type</th>
                                                            <th>Is Flat</th>
                                                            <th>Active / Deactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Add GAS tab rows here */}
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Adani Gas - GUJARAT</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('160')} fdprocessedid="aqm2ob">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[1]" value="12.00" fdprocessedid="iv502g" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[1]" fdprocessedid="si8qpf">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[1]" fdprocessedid="w8r1au">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[1]" fdprocessedid="zp5vud">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Adani Gas - HARYANA</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('161')} fdprocessedid="1n4fxm">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[2]" value="6.00" fdprocessedid="e5uchr" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[2]" fdprocessedid="nidfdq">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[2]" fdprocessedid="ygolmx">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[2]" fdprocessedid="4hvbu8">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>Sabarmati Gas</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('163')} fdprocessedid="e3b7yc">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[6]" value="3.00" fdprocessedid="syrlul" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[6]" fdprocessedid="s0g1s4">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[6]" fdprocessedid="a23ncu">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[6]" fdprocessedid="w4b6e4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>Maharashtra Natural Gas</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('796')} fdprocessedid="q0rqvj">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[178]" value="1.00" fdprocessedid="rpfjfu" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[178]" fdprocessedid="iyuoks">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[178]" fdprocessedid="jnfufc">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[178]" fdprocessedid="mq11y4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Tripura Natural Gas</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('800')} fdprocessedid="9pbzg">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[179]" value="1.00" fdprocessedid="fnc8r" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[179]" fdprocessedid="p6sf5">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[179]" fdprocessedid="577w9g">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[179]" fdprocessedid="1zot9">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="m-auto text-center">
                                                    <input type="submit" value="SAVE" className="btn btn-primary" fdprocessedid="6m9zz" />
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    {/* INSURANCE */}
                                    <div className={`tab-pane fade ${activeTab === 'tab_8' ? 'show active' : ''}`} id="tab_8" role="tabpanel" aria-labelledby="ref_8">
                                        <div className="table-responsive">
                                            <form action="#" method="post" onSubmit={validateInput} acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="4148aa44b74f44fabc8550f138f34cb1" />
                                                <table className="table table-bordered table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Operator Name</th>
                                                            <th>API</th>
                                                            <th>Given Commission</th>
                                                            <th>Commission Type</th>
                                                            <th>Is Flat</th>
                                                            <th>Active / Deactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Add INSURANCE tab rows here */}
                                                        <tr>
                                                            <td>1</td>
                                                            <td>ICICI Prudential Life</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('160')} fdprocessedid="aqm2ob">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[1]" value="13.00" fdprocessedid="iv502g" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[1]" fdprocessedid="si8qpf">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[1]" fdprocessedid="w8r1au">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[1]" fdprocessedid="zp5vud">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Tata AIA Life Insurance</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('161')} fdprocessedid="1n4fxm">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[2]" value="6.00" fdprocessedid="e5uchr" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[2]" fdprocessedid="nidfdq">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[2]" fdprocessedid="ygolmx">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[2]" fdprocessedid="4hvbu8">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>Tata AIG General Insuranc</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('163')} fdprocessedid="e3b7yc">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[6]" value="3.00" fdprocessedid="syrlul" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[6]" fdprocessedid="s0g1s4">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[6]" fdprocessedid="a23ncu">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[6]" fdprocessedid="w4b6e4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>LIC Insurance</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('796')} fdprocessedid="q0rqvj">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[178]" value="1.00" fdprocessedid="rpfjfu" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[178]" fdprocessedid="iyuoks">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[178]" fdprocessedid="jnfufc">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[178]" fdprocessedid="mq11y4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Bharti AXA Life Insurance</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('800')} fdprocessedid="9pbzg">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[179]" value="1.00" fdprocessedid="fnc8r" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[179]" fdprocessedid="p6sf5">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[179]" fdprocessedid="577w9g">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[179]" fdprocessedid="1zot9">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="m-auto text-center">
                                                    <input type="submit" value="SAVE" className="btn btn-primary" fdprocessedid="6m9zz" />
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    {/* BROADBAND */}
                                    <div className={`tab-pane fade ${activeTab === 'tab_10' ? 'show active' : ''}`} id="tab_10" role="tabpanel" aria-labelledby="ref_10">
                                        <div className="table-responsive">
                                            <form action="#" method="post" onSubmit={validateInput} acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="4148aa44b74f44fabc8550f138f34cb1" />
                                                <table className="table table-bordered table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Operator Name</th>
                                                            <th>API</th>
                                                            <th>Given Commission</th>
                                                            <th>Commission Type</th>
                                                            <th>Is Flat</th>
                                                            <th>Active / Deactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Add GAS tab rows here */}
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Airtel Broadband</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('160')} fdprocessedid="aqm2ob">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[1]" value="13.00" fdprocessedid="iv502g" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[1]" fdprocessedid="si8qpf">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[1]" fdprocessedid="w8r1au">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[1]" fdprocessedid="zp5vud">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Act Fibernet Broadband</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('161')} fdprocessedid="1n4fxm">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[2]" value="6.00" fdprocessedid="e5uchr" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[2]" fdprocessedid="nidfdq">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[2]" fdprocessedid="ygolmx">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[2]" fdprocessedid="4hvbu8">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>AirJaldi- Rural Broadband</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('163')} fdprocessedid="e3b7yc">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[6]" value="3.00" fdprocessedid="syrlul" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[6]" fdprocessedid="s0g1s4">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[6]" fdprocessedid="a23ncu">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[6]" fdprocessedid="w4b6e4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>Airtel Broadband (Fetch and Pay )</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('796')} fdprocessedid="q0rqvj">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[178]" value="1.00" fdprocessedid="rpfjfu" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[178]" fdprocessedid="iyuoks">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[178]" fdprocessedid="jnfufc">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[178]" fdprocessedid="mq11y4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Alliance Broadband Services Pvt.Ltd.</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('800')} fdprocessedid="9pbzg">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[179]" value="1.00" fdprocessedid="fnc8r" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[179]" fdprocessedid="p6sf5">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[179]" fdprocessedid="577w9g">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[179]" fdprocessedid="1zot9">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="m-auto text-center">
                                                    <input type="submit" value="SAVE" className="btn btn-primary" fdprocessedid="6m9zz" />
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    {/* Loan EMI */}
                                    <div className={`tab-pane fade ${activeTab === 'tab_12' ? 'show active' : ''}`} id="tab_10" role="tabpanel" aria-labelledby="ref_12">
                                        <div className="table-responsive">
                                            <form action="#" method="post" onSubmit={validateInput} acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="4148aa44b74f44fabc8550f138f34cb1" />
                                                <table className="table table-bordered table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Operator Name</th>
                                                            <th>API</th>
                                                            <th>Given Commission</th>
                                                            <th>Commission Type</th>
                                                            <th>Is Flat</th>
                                                            <th>Active / Deactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Add GAS tab rows here */}
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Bajaj Finance</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('160')} fdprocessedid="aqm2ob">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[1]" value="14.00" fdprocessedid="iv502g" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[1]" fdprocessedid="si8qpf">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[1]" fdprocessedid="w8r1au">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[1]" fdprocessedid="zp5vud">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Aadhar Housing Finance Limited</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('161')} fdprocessedid="1n4fxm">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[2]" value="6.00" fdprocessedid="e5uchr" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[2]" fdprocessedid="nidfdq">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[2]" fdprocessedid="ygolmx">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[2]" fdprocessedid="4hvbu8">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>AAVAS FINANCIERS LIMITED</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('163')} fdprocessedid="e3b7yc">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[6]" value="3.00" fdprocessedid="syrlul" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[6]" fdprocessedid="s0g1s4">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[6]" fdprocessedid="a23ncu">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[6]" fdprocessedid="w4b6e4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>Adani Capital Pvt Ltd</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('796')} fdprocessedid="q0rqvj">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[178]" value="1.00" fdprocessedid="rpfjfu" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[178]" fdprocessedid="iyuoks">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[178]" fdprocessedid="jnfufc">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[178]" fdprocessedid="mq11y4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Adani Housing Finance</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('800')} fdprocessedid="9pbzg">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[179]" value="1.00" fdprocessedid="fnc8r" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[179]" fdprocessedid="p6sf5">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[179]" fdprocessedid="577w9g">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[179]" fdprocessedid="1zot9">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="m-auto text-center">
                                                    <input type="submit" value="SAVE" className="btn btn-primary" fdprocessedid="6m9zz" />
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    {/* Water */}
                                    <div className={`tab-pane fade ${activeTab === 'tab_14' ? 'show active' : ''}`} id="tab_14" role="tabpanel" aria-labelledby="ref_14">
                                        <div className="table-responsive">
                                            <form action="#" method="post" onSubmit={validateInput} acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="4148aa44b74f44fabc8550f138f34cb1" />
                                                <table className="table table-bordered table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Operator Name</th>
                                                            <th>API</th>
                                                            <th>Given Commission</th>
                                                            <th>Commission Type</th>
                                                            <th>Is Flat</th>
                                                            <th>Active / Deactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Add Water tab rows here */}
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Ahmedabad Municipal Corporation</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('160')} fdprocessedid="aqm2ob">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[1]" value="15.00" fdprocessedid="iv502g" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[1]" fdprocessedid="si8qpf">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[1]" fdprocessedid="w8r1au">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[1]" fdprocessedid="zp5vud">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Bangalore Water Supply and Sewarage Board</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('161')} fdprocessedid="1n4fxm">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[2]" value="6.00" fdprocessedid="e5uchr" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[2]" fdprocessedid="nidfdq">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[2]" fdprocessedid="ygolmx">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[2]" fdprocessedid="4hvbu8">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>Bhopal Municipal Corporation</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('163')} fdprocessedid="e3b7yc">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[6]" value="3.00" fdprocessedid="syrlul" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[6]" fdprocessedid="s0g1s4">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[6]" fdprocessedid="a23ncu">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[6]" fdprocessedid="w4b6e4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>Delhi Development Authority</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('796')} fdprocessedid="q0rqvj">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[178]" value="1.00" fdprocessedid="rpfjfu" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[178]" fdprocessedid="iyuoks">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[178]" fdprocessedid="jnfufc">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[178]" fdprocessedid="mq11y4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Delhi Jal Board</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('800')} fdprocessedid="9pbzg">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[179]" value="1.00" fdprocessedid="fnc8r" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[179]" fdprocessedid="p6sf5">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[179]" fdprocessedid="577w9g">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[179]" fdprocessedid="1zot9">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="m-auto text-center">
                                                    <input type="submit" value="SAVE" className="btn btn-primary" fdprocessedid="6m9zz" />
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    {/* Bokking Gas */}
                                    <div className={`tab-pane fade ${activeTab === 'tab_17' ? 'show active' : ''}`} id="tab_17" role="tabpanel" aria-labelledby="ref_17">
                                        <div className="table-responsive">
                                            <form action="#" method="post" onSubmit={validateInput} acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="4148aa44b74f44fabc8550f138f34cb1" />
                                                <table className="table table-bordered table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Operator Name</th>
                                                            <th>API</th>
                                                            <th>Given Commission</th>
                                                            <th>Commission Type</th>
                                                            <th>Is Flat</th>
                                                            <th>Active / Deactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Add Water tab rows here */}
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Bharat Gas</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('160')} fdprocessedid="aqm2ob">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[1]" value="17.00" fdprocessedid="iv502g" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[1]" fdprocessedid="si8qpf">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[1]" fdprocessedid="w8r1au">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[1]" fdprocessedid="zp5vud">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>HP Gas (HPCL)</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('161')} fdprocessedid="1n4fxm">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[2]" value="6.00" fdprocessedid="e5uchr" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[2]" fdprocessedid="nidfdq">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[2]" fdprocessedid="ygolmx">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[2]" fdprocessedid="4hvbu8">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>Indane Gas (Indian Oil)	</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('163')} fdprocessedid="e3b7yc">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[6]" value="3.00" fdprocessedid="syrlul" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[6]" fdprocessedid="s0g1s4">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[6]" fdprocessedid="a23ncu">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[6]" fdprocessedid="w4b6e4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                <div className="m-auto text-center">
                                                    <input type="submit" value="SAVE" className="btn btn-primary" fdprocessedid="6m9zz" />
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    {/* Fastag */}
                                    <div className={`tab-pane fade ${activeTab === 'tab_18' ? 'show active' : ''}`} id="tab_18" role="tabpanel" aria-labelledby="ref_18">
                                        <div className="table-responsive">
                                            <form action="#" method="post" onSubmit={validateInput} acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="4148aa44b74f44fabc8550f138f34cb1" />
                                                <table className="table table-bordered table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Operator Name</th>
                                                            <th>API</th>
                                                            <th>Given Commission</th>
                                                            <th>Commission Type</th>
                                                            <th>Is Flat</th>
                                                            <th>Active / Deactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Add Water tab rows here */}
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Indian Highways Management Company Ltd FASTag		</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('160')} fdprocessedid="aqm2ob">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[1]" value="11.00" fdprocessedid="iv502g" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[1]" fdprocessedid="si8qpf">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[1]" fdprocessedid="w8r1au">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[1]" fdprocessedid="zp5vud">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Axis Bank Fastag</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('161')} fdprocessedid="1n4fxm">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[2]" value="6.00" fdprocessedid="e5uchr" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[2]" fdprocessedid="nidfdq">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[2]" fdprocessedid="ygolmx">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[2]" fdprocessedid="4hvbu8">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>Bank Of Baroda - Fastag</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('163')} fdprocessedid="e3b7yc">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[6]" value="3.00" fdprocessedid="syrlul" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[6]" fdprocessedid="s0g1s4">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[6]" fdprocessedid="a23ncu">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[6]" fdprocessedid="w4b6e4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>Equitas Fastag Recharge</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('796')} fdprocessedid="q0rqvj">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[178]" value="1.00" fdprocessedid="rpfjfu" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[178]" fdprocessedid="iyuoks">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[178]" fdprocessedid="jnfufc">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[178]" fdprocessedid="mq11y4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Federal Bank - Fastag</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('800')} fdprocessedid="9pbzg">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[179]" value="1.00" fdprocessedid="fnc8r" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[179]" fdprocessedid="p6sf5">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[179]" fdprocessedid="577w9g">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[179]" fdprocessedid="1zot9">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="m-auto text-center">
                                                    <input type="submit" value="SAVE" className="btn btn-primary" fdprocessedid="6m9zz" />
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    {/* Subscription */}
                                    <div className={`tab-pane fade ${activeTab === 'tab_19' ? 'show active' : ''}`} id="tab_19" role="tabpanel" aria-labelledby="ref_19">
                                        <div className="table-responsive">
                                            <form action="#" method="post" onSubmit={validateInput} acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="4148aa44b74f44fabc8550f138f34cb1" />
                                                <table className="table table-bordered table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Operator Name</th>
                                                            <th>API</th>
                                                            <th>Given Commission</th>
                                                            <th>Commission Type</th>
                                                            <th>Is Flat</th>
                                                            <th>Active / Deactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Add Water tab rows here */}
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Ashok Book Centre		</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('160')} fdprocessedid="aqm2ob">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[1]" value="16.00" fdprocessedid="iv502g" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[1]" fdprocessedid="si8qpf">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[1]" fdprocessedid="w8r1au">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[1]" fdprocessedid="zp5vud">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Bajaj Finserv Health Limited</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('161')} fdprocessedid="1n4fxm">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[2]" value="6.00" fdprocessedid="e5uchr" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[2]" fdprocessedid="nidfdq">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[2]" fdprocessedid="ygolmx">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[2]" fdprocessedid="4hvbu8">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>FITPASS</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('163')} fdprocessedid="e3b7yc">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[6]" value="3.00" fdprocessedid="syrlul" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[6]" fdprocessedid="s0g1s4">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[6]" fdprocessedid="a23ncu">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[6]" fdprocessedid="w4b6e4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>Furlenco</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('796')} fdprocessedid="q0rqvj">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[178]" value="1.00" fdprocessedid="rpfjfu" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[178]" fdprocessedid="iyuoks">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[178]" fdprocessedid="jnfufc">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[178]" fdprocessedid="mq11y4">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>Hindustan Times Newspaper</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('800')} fdprocessedid="9pbzg">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[179]" value="1.00" fdprocessedid="fnc8r" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[179]" fdprocessedid="p6sf5">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[179]" fdprocessedid="577w9g">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[179]" fdprocessedid="1zot9">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="m-auto text-center">
                                                    <input type="submit" value="SAVE" className="btn btn-primary" fdprocessedid="6m9zz" />
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    {/* Goggle Play */}
                                    <div className={`tab-pane fade ${activeTab === 'tab_24' ? 'show active' : ''}`} id="tab_24" role="tabpanel" aria-labelledby="ref_24">
                                        <div className="table-responsive">
                                            <form action="#" method="post" onSubmit={validateInput} acceptCharset="utf-8">
                                                <input type="hidden" name="securityToken" value="4148aa44b74f44fabc8550f138f34cb1" />
                                                <table className="table table-bordered table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Operator Name</th>
                                                            <th>API</th>
                                                            <th>Given Commission</th>
                                                            <th>Commission Type</th>
                                                            <th>Is Flat</th>
                                                            <th>Active / Deactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Add Water tab rows here */}
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Google Play Recharge		</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => get_apis('160')} fdprocessedid="aqm2ob">
                                                                    View
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control commission_surcharge" placeholder="Given Commission" name="commission_surcharge[1]" value="00.00" fdprocessedid="iv502g" />
                                                            </td>
                                                            <td>
                                                                <select className="form-control type" style={{ minWidth: '100px' }} name="type[1]" fdprocessedid="si8qpf">
                                                                    <option value="1" selected>Commission</option>
                                                                    <option value="2">Surcharge</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control is_flat_percent" style={{ minWidth: '100px' }} name="is_flat_percent[1]" fdprocessedid="w8r1au">
                                                                    <option value="1" selected>No</option>
                                                                    <option value="2">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className="form-control status" style={{ minWidth: '100px' }} name="status[1]" fdprocessedid="zp5vud">
                                                                    <option value="1" selected>Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                <div className="m-auto text-center">
                                                    <input type="submit" value="SAVE" className="btn btn-primary" fdprocessedid="6m9zz" />
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                </div>
                          
                        </div>
                    </div>

                </section >
            </div >

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>

        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

export default function Paymentsetting() {

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '232.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">Payment Settings</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Settings</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Payment Settings</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <form
                            action="/paymentsetting"
                            method="post"
                            acceptCharset="utf-8"
                        >
                            <input type="hidden" name="securityToken" value="bed1260933bb1ce5f2853ff164b25d88" />
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <label>UPI Intent</label>
                                            <div className="form-group clearfix">
                                                <div className="icheck-primary d-inline">
                                                    <input type="radio" id="radioPrimary3" name="upi" value="1" checked />
                                                    <label htmlFor="radioPrimary3">Active</label>
                                                </div>&nbsp;&nbsp;
                                                <div className="icheck-danger d-inline">
                                                    <input type="radio" id="radioPrimary4" name="upi" value="0" />
                                                    <label htmlFor="radioPrimary4">Deactive</label>
                                                </div>
                                            </div>
                                            <label htmlFor="upi_msg">UPI Intent Message</label>
                                            <input
                                                type="text"
                                                name="upi_msg"
                                                id="upi_msg"
                                                className="form-control"
                                                placeholder="Enter upi msg"
                                                value="Instant Payment Using UPI Apps"
                                                fdprocessedid="6ia25"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <label>UPI Apps</label>:
                                            <div className="form-group clearfix">
                                                <div className="icheck-primary d-inline">
                                                    <input type="radio" id="radioPrimary5" name="upi_apps" value="1" checked />
                                                    <label htmlFor="radioPrimary5">Active</label>
                                                </div>&nbsp;&nbsp;
                                                <div className="icheck-danger d-inline">
                                                    <input type="radio" id="radioPrimary6" name="upi_apps" value="0" />
                                                    <label htmlFor="radioPrimary6">Deactive</label>
                                                </div>
                                            </div>
                                            <label htmlFor="upi_app_msg">UPI App Message</label>
                                            <input
                                                type="text"
                                                name="upi_app_msg"
                                                id="upi_app_msg"
                                                className="form-control"
                                                placeholder="Enter upi msg"
                                                value="Payment using selected UPI Apps"
                                                fdprocessedid="plt1dr"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-primary" type="submit" fdprocessedid="73i6fo">
                                    SAVE
                                </button>
                            </div>
                        </form>
                        <hr />
                        <div className="card">
                            <div className="card-body">
                                <form
                                    action="#"
                                    method="post"
                                    acceptCharset="utf-8"
                                >
                                    <input
                                        type="hidden"
                                        name="securityToken"
                                        value="bed1260933bb1ce5f2853ff164b25d88"
                                    />
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Payment Mode</th>
                                                <th>Minimum Amount</th>
                                                <th>Maximum Amount</th>
                                                <th>Charges</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <input type="hidden" name="id[1]" value="1" />
                                                <td>NETBANKING</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="min_amount[1]"
                                                        value="1.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="vm3avo"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="max_amount[1]"
                                                        value="10000.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="oxskf"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="charges[1]"
                                                        value="1.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="p2mhyq"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>2</td>
                                                <input type="hidden" name="id[2]" value="2" />
                                                <td>WALLET</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="min_amount[2]"
                                                        value="1.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="u1dntf"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="max_amount[2]"
                                                        value="10000.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="u34w9l"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="charges[2]"
                                                        value="0.50"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="gv8f1l"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>3</td>
                                                <input type="hidden" name="id[3]" value="3" />
                                                <td>UPI</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="min_amount[3]"
                                                        value="1.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="y0bvuv"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="max_amount[3]"
                                                        value="10000.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="a6jhme"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="charges[3]"
                                                        value="0.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="kaam98"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>4</td>
                                                <input type="hidden" name="id[4]" value="4" />
                                                <td>CREDIT_CARD</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="min_amount[4]"
                                                        value="1.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="q6w7if"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="max_amount[4]"
                                                        value="10000.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="19rnnp"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="charges[4]"
                                                        value="2.99"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="hrg6hs"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>5</td>
                                                <input type="hidden" name="id[5]" value="5" />
                                                <td>DEBIT_CARD</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="min_amount[5]"
                                                        value="1.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="uop2es"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="max_amount[5]"
                                                        value="10000.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="sjszak"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="charges[5]"
                                                        value="2.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="hv00rd"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>6</td>
                                                <input type="hidden" name="id[6]" value="6" />
                                                <td>UPI_OPEN</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="min_amount[6]"
                                                        value="1.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="1a7mon"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="max_amount[6]"
                                                        value="10000.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="r9n87"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="charges[6]"
                                                        value="0.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="ra8y7"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>7</td>
                                                <input type="hidden" name="id[7]" value="7" />
                                                <td>UPI_APP</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="min_amount[7]"
                                                        value="1.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="lncwm"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="max_amount[7]"
                                                        value="10000.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="bnuz4f"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="charges[7]"
                                                        value="0.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="r27olr"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>8</td>
                                                <input type="hidden" name="id[11]" value="11" />
                                                <td>MANUAL_FUND_REQUEST</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="min_amount[11]"
                                                        value="1.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="e7l33g"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="max_amount[11]"
                                                        value="10000.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="qdmc8"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="charges[11]"
                                                        value="0.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="6zdc0n"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>9</td>
                                                <input type="hidden" name="id[12]" value="12" />
                                                <td>VPA/QR</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="min_amount[12]"
                                                        value="0.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="lg8o5"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="max_amount[12]"
                                                        value="10000.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="qdepnt"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="charges[12]"
                                                        value="0.00"
                                                        className="form-control"
                                                        required
                                                        fdprocessedid="b2b3xc"
                                                    />
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <div className="text-center mt-2">
                                        <button className="btn btn-primary" type="submit" fdprocessedid="8xp2vw">
                                            SAVE
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from "react";
import { useState } from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBContainer, MDBTable, MDBTableBody
} from "mdb-react-ui-kit";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import RestService from "../../http";
import showToast from "./APPToast";
import { ToastContainer } from "react-toastify";
import Loader from "../Loader";
import BbpsFinalReciptData from "./BbpsFinalReciptData";


const BbpsSlip = ({ data, onClose, operatorId, cn, ad1DDDataValue }) => {

    const service = new RestService();
    const [open, setOpen] = React.useState(false);
    const [billAmount, setBillAmount] = useState(data.billAmount);
    const [paymentAd1, setPaymentAd1] = useState('');
    const [paymentAd2, setPaymentAd2] = useState('');
    const [paymentAd3, setPaymentAd3] = useState('');
    const [paymentAd4, setPaymentAd4] = useState('');
    const [ad1, setAd1] = useState(false);
    const [ad2, setAd2] = useState(false);
    const [ad3, setAd3] = useState(false);
    const [ad4, setAd4] = useState(false);
    const [ad1TextHint, setAd1TextHint] = useState('');
    const [ad2TextHint, setAd2TextHint] = useState('');
    const [ad3TextHint, setAd3TextHint] = useState('');
    const [ad4TextHint, setAd4TextHint] = useState('');
    const [loading, setLoading] = useState(false);
    const [isBbpsSlipModalOpen, setIsBbpsSlipModalOpen] = useState(false);
    const [bbpsFinalReciptData, setBbpsFinalReciptData] = useState({});



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'green',
        boxShadow: 24,
        borderRadius: "10px",
        p: 4,
    };

    const handleClose = () => setOpen(false);

    // const handleOpen = () => setOpen(true);

    const handleOpen = () => {

        console.log(operatorId);

        var raw = {
            "id": operatorId
        }

        service.post("bbps/fetchrechargefield", raw).then(result => {
            console.log(result);

            if (result.message === "ADDITIONAL REQUIRED" || result.status === 14) {
                setOpen(true);

                if (result.data.field === 20) {
                    result.data.paymentAd1 = "ad1DDataValue";
                    setPaymentAd1(ad1DDDataValue);

                } else {
                    if (result.data.field === 21) {
                        if (data.ad1 !== null) {
                            setAd1(true);
                            setAd1TextHint(data.ad1);
                        } else {
                            setAd1(false);
                        }

                        if (data.ad2 !== null) {
                            setAd2(true);
                            setAd2TextHint(data.ad2);
                        } else {
                            setAd2(false);
                        }

                        if (data.ad3 !== null) {
                            setAd3(true);
                            setAd3TextHint(data.ad3);
                        } else {
                            setAd3(false);
                        }

                        if (data.ad4 !== null) {
                            setAd4(true);
                            setAd4TextHint(data.ad4);
                        } else {
                            setAd4(false);
                        }
                    } else {
                        showToast("DANGER", result.message);
                        setAd1(false);
                        setAd2(false);
                        setAd3(false);
                        setAd4(false);
                    }
                }

            }

            // if (result.status === service.BBPS_RECHARGE_ADD_PARAM) {
            //     setOpen(true);

            //     const data = result.data;

            //     if (data.ad1 !== null) {
            //         setAd1(true);

            //     } else {
            //         setAd1(false);
            //     }

            //     if (data.ad2 !== null) {
            //         setAd2(true);

            //     } else {
            //         setAd2(false);
            //     }

            //     if (data.ad3 !== null) {
            //         setAd3(true);

            //     } else {
            //         setAd3(false);
            //     }


            //     if (data.ad4 !== null) {
            //         setAd4(true);

            //     } else {
            //         setAd4(false);
            //     }

            // } else {
            //     setAd1(false);
            //     setAd2(false);
            //     setAd3(false);
            //     setAd4(false);
            // }

        }).catch((error) => {
            console.log(error);
        })
    }

    const handleChange = (e) => {
        setBillAmount(e.target.value);

    };

    const handleBbpsRecharge = () => {
        setLoading(true);

        var raw = {
            "operatorInCode": "42",
            "bbpsOperatorId": operatorId,
            "cn": cn,
            "amount": billAmount,
            "paymentAd1": paymentAd1,
            "paymentAd2": paymentAd2,
            "paymentAd3": paymentAd3,
            "paymentAd4": paymentAd4

        }

        service.post("warehouse/bbpsrecharge", raw).then(result => {
            setLoading(false);

            console.log(result);

            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                setBbpsFinalReciptData(result.Recharge);
                setIsBbpsSlipModalOpen(true);

            } else {
                showToast("DANGER", result.message);
            }
        })
    }

    const modalStyle = {
        display: 'block',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        marginTop: '15%',
        // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    };

    const modalContentStyle = {
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#f7f7f7',
        // padding: '20px',
    };

    const closeButtonStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        fontSize: '20px',
        cursor: 'pointer',
    };

    return (
        <>
            <ToastContainer />
            <MDBContainer style={{ width: "25%", height: "100vh", marginTop: "30%", background: "cover" }}>
                <MDBCard>
                    <MDBCardBody className="mx-4" style={{ flex: "1" }}>
                        <MDBContainer>
                            {/* Bank Logo */}
                            <div className="logo-container" style={{ background: "lightblue", backgroundSize: 'cover', width: '132%', height: '150px', marginLeft: "-16%", marginTop: "-6%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src="./image/bbps_logo.png" alt="Bbps Logo" style={{ width: '100px', display: "flex", justifyContent: "center" }} />
                                {/* <div className="amount-text" style={{ marginTop: "25%", zIndex: '2', color: 'white', fontSize: '18px', display:"flex", justifyContent:"center", marginLeft:"-16%"}}>
                                    ₹{data.remainingbal}
                                </div> */}
                            </div>

                            <div style={{ display: 'inline-block', position: 'relative', display: "flex", justifyContent: "center" }}>
                                <div className="dashed-line" style={{ position: 'absolute', width: "23vw", top: '60%', left: '-41px', transform: 'translateY(-50%)', borderTop: '2px dashed', color: 'skyblue', zIndex: '0' }}></div>

                                <button className="btn mt-3"
                                    style={{ borderRadius: "15px", position: 'relative', zIndex: '1', justifyContent: "center", display: "flex", color: "white", backgroundColor: "darkcyan" }}
                                >
                                    {data.billAmount}
                                </button>
                            </div><br />

                            <p className="text-center" style={{ fontWeight: "bold" }}>{data.type}</p>

                            <MDBTable style={{ tableLayout: 'fixed', width: '100%', overflowY: "scroll" }}>
                                <MDBTableBody>
                                    <React.Fragment>
                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>User Name</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: "10px" }}>{data.userName}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Bill Amt.</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.billAmount}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Bill Net Amt.</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.billnetamount}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Due Date</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.dueDate}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Bill Date</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.billdate}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Min. Bill Amt.</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.minBillAmount}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Part Pay</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.acceptPartPay}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Payment</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.acceptPayment}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Number</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.cellNumber}</td>
                                        </tr>

                                    </React.Fragment>

                                </MDBTableBody>
                            </MDBTable>

                            <div style={{ float: "right" }}>
                                <button className="btn bg-blue" onClick={onClose}>Back</button>&nbsp;
                                <button className="btn bg-green" onClick={() => handleOpen(data)} >Pay</button>
                            </div>

                        </MDBContainer>
                    </MDBCardBody>

                    <Modal
                        open={open}
                        // onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >

                        <Box sx={style}>
                            {loading && <Loader />}

                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-box">
                                            <label className="control-label" style={{ color: "white" }}>CN Number</label>
                                            <input
                                                type="text"
                                                name="eleconsumerid"
                                                className="form-control text-box"
                                                id="eleconsumerid"
                                                tabindex="4"
                                                autocomplete="off"
                                                onkeyup="consumeridchange()"
                                                onkeypress="return blockAddressChar(event)"
                                                fdprocessedid="yzm4j1"
                                                value={cn}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <div className="input-box">
                                            <label className="control-label" style={{ color: "white" }}>Biller Name</label>
                                            <input
                                                type="text"
                                                name="eleconsumerid"
                                                className="form-control text-box"
                                                id="eleconsumerid"
                                                tabindex="4"
                                                autocomplete="off"
                                                onkeyup="consumeridchange()"
                                                onkeypress="return blockAddressChar(event)"
                                                fdprocessedid="yzm4j1"
                                                value={data.userName}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <div className="input-box">
                                            <label className="control-label" style={{ color: "white" }}>Bill Amount</label>
                                            <input
                                                type="text"
                                                name="eleconsumerid"
                                                className="form-control text-box"
                                                id="eleconsumerid"
                                                tabindex="4"
                                                autocomplete="off"
                                                onkeyup="consumeridchange()"
                                                onkeypress="return blockAddressChar(event)"
                                                fdprocessedid="yzm4j1"
                                                value={billAmount}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    {ad1 ?
                                        <div className="col-md-12 mt-2">
                                            <div className="input-box">
                                                <label className="control-label">{ad1TextHint}</label>
                                                <input
                                                    type="text"
                                                    name="eleconsumerid"
                                                    className="form-control text-box"
                                                    id="eleconsumerid"
                                                    tabindex="4"
                                                    placeholder={ad1TextHint}
                                                    autocomplete="off"
                                                    onkeyup="consumeridchange()"
                                                    onkeypress="return blockAddressChar(event)"
                                                    fdprocessedid="yzm4j1"
                                                    onChange={(e) => setPaymentAd1(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        : null}

                                    {ad2 ?
                                        <div className="col-md-12 mt-2">
                                            <div className="input-box">
                                                <label className="control-label">{ad2TextHint}</label>
                                                <input
                                                    type="text"
                                                    name="eleconsumerid"
                                                    className="form-control text-box"
                                                    id="eleconsumerid"
                                                    tabindex="4"
                                                    placeholder={ad2TextHint}
                                                    autocomplete="off"
                                                    onkeyup="consumeridchange()"
                                                    onkeypress="return blockAddressChar(event)"
                                                    fdprocessedid="yzm4j1"
                                                    onChange={(e) => setPaymentAd2(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        : null}

                                    {ad3 ?
                                        <div className="col-md-12 mt-2">
                                            <div className="input-box">
                                                <label className="control-label">{ad3TextHint}</label>
                                                <input
                                                    type="text"
                                                    name="eleconsumerid"
                                                    className="form-control text-box"
                                                    id="eleconsumerid"
                                                    tabindex="4"
                                                    placeholder={ad3TextHint}
                                                    autocomplete="off"
                                                    onkeyup="consumeridchange()"
                                                    onkeypress="return blockAddressChar(event)"
                                                    fdprocessedid="yzm4j1"
                                                    onChange={(e) => setPaymentAd3(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        : null}

                                    {ad4 ?
                                        <div className="col-md-12 mt-2">
                                            <div className="input-box">
                                                <label className="control-label">{ad4TextHint}</label>
                                                <input
                                                    type="text"
                                                    name="eleconsumerid"
                                                    className="form-control text-box"
                                                    id="eleconsumerid"
                                                    tabindex="4"
                                                    placeholder={ad4TextHint}
                                                    autocomplete="off"
                                                    onkeyup="consumeridchange()"
                                                    onkeypress="return blockAddressChar(event)"
                                                    fdprocessedid="yzm4j1"
                                                    onChange={(e) => setPaymentAd4(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        : null}

                                </div><br />

                                <div style={{ float: "right" }}>
                                    <button className="btn bg-blue" onClick={handleClose}>Back</button>&nbsp;
                                    <button className="btn bg-red" onClick={handleBbpsRecharge}>Pay</button>
                                </div>
                            </Typography>
                        </Box>
                    </Modal>
                    {/* // )} */}
                </MDBCard>
            </MDBContainer>

            {isBbpsSlipModalOpen && (
                <div className="modal" style={modalStyle}>
                    <div className="modal-content" style={modalContentStyle}>
                        <span className="close" style={closeButtonStyle} onClick={() => setIsBbpsSlipModalOpen(false)}>
                            &times;
                        </span>
                        <div>
                            <BbpsFinalReciptData onClose={() => setIsBbpsSlipModalOpen(false)} receiptdata={bbpsFinalReciptData} />
                        </div>

                    </div>
                </div>
            )}


        </>
    );

};

export default BbpsSlip;

import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { ToastContainer } from "react-toastify";
import RestService from "../http";
import MinistatementWalletRecord from "./utilityComponents/MinistatementWalletRecords";
import showToast from "./utilityComponents/APPToast";
import { Link } from "react-router-dom";

export default function ManageMiniWallet() {
  const rest = new RestService();

  const [showAddMiniWallet, setShowAddMiniWallet] = useState(true);
  const [userList, setUserList] = useState([]);
  const [userId, setUserId] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const [balance, setBalance] = useState(0.0);
  const [walletList, setWalletList] = useState([]);
  const [editBalanceModel, setEditBalanceModel] = useState(false);
  const [name, setName] = useState("");

  const fetchUserList = () => {
    rest
      .post(`user/getUpline?roleId=5`, null)
      .then((result) => {
        if (result.status === rest.SUCCESS) {
          setUserList(result.data);
        } else {
          setUserList([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setUserList([]);
      });
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const handleOpenModel = () => {
    setShowAddMiniWallet(!showAddMiniWallet);
  };

  const fetchMinistatementWallet = () => {
    rest
      .post(`commission/fetchAllMiniReservWallet`, null)
      .then((result) => {
        if (result.status === rest.SUCCESS) {
          setWalletList(result.data);
        } else if (result.status === rest.EXCEPTIONAL_ERROR) {
          console.log(result);
          setWalletList([]);
          showToast("DANGER", result.message);
        }
      })
      .catch((err) => {
        console.error(err);
        setWalletList([]);
      });
  };

  useEffect(() => {
    fetchMinistatementWallet();
  }, []);

  const addMinistatementWallet = () => {
    if (userId === undefined || userId === null || userId === "") {
      showToast("WARN", "PLEASE CHOOSE A USER FIRST!!");
      return;
    }

    let request = {
      userId: userId,
    };

    rest
      .post(`commission/addMiniReservWallet`, request)
      .then((result) => {
        if (result.status === rest.SUCCESS) {
          showToast("SUCCESS", result.message);
          fetchMinistatementWallet();
        } else if (result.status === rest.EXCEPTIONAL_ERROR) {
          console.log(result);
          showToast("DANGER", result.message);
        } else {
          showToast("DANGER", result.message);
        }
      })
      .catch((err) => {
        console.error(err);
        showToast("DANGER", "SOMETHING WENT WRONG, PLEASE CONTACT TO ADMIN!!");
      });
  };

  const updateStatus = (userId, status, name) => {
    let request = {
      userId: userId,
      balance: 0,
      isLocked: !status,
      name: name,
    };

    rest
      .post(`commission/updateMiniReservWallet`, request)
      .then((response) => {
        if (response.status === rest.SUCCESS) {
          showToast("SUCCESS", response.message);
          fetchMinistatementWallet();
        } else {
          showToast("DANGER", response.message);
        }
      })
      .catch((err) => {
        console.error(err);
        showToast("DANGER", "SOMETHING WENT WRONG, PLEASE CONTACT TO ADMIN!!");
      });
  };

  const handleWalletBalanceUpdate = () => {
    let request = {
      userId: userId,
      balance: balance,
      isLocked: isLocked,
      name: name,
    };

    rest.post(`commission/updateMiniReservWallet`, request).then(response => {
        if(response.status === rest.SUCCESS) {
            showToast("SUCCESS", response.message);
            setEditBalanceModel(false);
            fetchMinistatementWallet();
        }else if(response.status === rest.EXCEPTIONAL_ERROR){
            console.log(response);
            showToast("DANGER", response.message);
            setEditBalanceModel(false);
        } else {
            showToast("DANGER", response.message);
            setEditBalanceModel(false);
        }
    }).catch(err => {
        console.log(err);
        showToast("DANGER", "SOMETHING WENT WRONG, PLEASE CONTACT TO ADMIN!!");
    });

  };

  const updateBalance = (userId, balance, status, name) => {
    console.log(userId, balance, status);
    setIsLocked(status);
    setBalance(balance);
    setUserId(userId);
    setName(name);
    setEditBalanceModel(true);
  };

  const closeEditModel = () => {
    setEditBalanceModel(false);
  };

  return (
    <>
      <Navbar />
      <ToastContainer />

      <div className="content-wrapper" style={{ minHeight: "570.667px" }}>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Ministatement Wallet</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Settings</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Ministatement Wallet
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <br />

          <div className="content">
            <div className="container-fluid">
              {showAddMiniWallet && (
                <div className="card" id="addpack">
                  <div
                    className="card-header text-primary"
                    style={{ fontWeight: "bold" }}
                  >
                    Add Ministatement Wallet:
                  </div>

                  <div className="card-body">
                    <div
                      method="post"
                      accept-charset="utf-8"
                      data-select2-id="6"
                    >
                      <div className="row" data-select2-id="5">
                        <div className="col-md-3">
                          <label for="operator_name">
                            User Name: <span className="text-danger"> *</span>
                          </label>
                          <select
                            onChange={(e) => setUserId(e.target.value)}
                            autofocus=""
                            required=""
                            className="form-control"
                          >
                            <option value={""}>Select User</option>
                            {userList.map((user) => (
                              <option key={user.userId} value={user.userId}>
                                {user.name} | {user.userId} | {user.mobile}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div
                        className="text-center"
                        style={{ marginTop: "30px" }}
                      >
                        <input
                          type="button"
                          onClick={addMinistatementWallet}
                          className="btn btn-primary btn-hover text-white"
                          value="Add Wallet"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {editBalanceModel && (
                <div
                  className="modal fade-in show"
                  id="serviceUpdate"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="serviceUpdateTitle"
                  style={{ display: "block" }}
                  aria-modal="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div>
                        <ToastContainer />
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalLongTitle"
                          >
                            DEDUCT BALANCE
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={closeEditModel}
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="mt-2 col-md-4">
                              <label>User Id</label>
                              <input
                                type="text"
                                name="service"
                                value={userId}
                                className="form-control clear"
                                required
                              />
                            </div>
                            <div className="mt-2 col-md-4">
                              <label>BALANCE</label>
                              <input
                                type="text"
                                name="service"
                                value={balance}
                                onChange={(e) => setBalance(e.target.value)}
                                className="form-control clear"
                                required
                              />
                            </div>
                            <div className="mt-2 col-md-4">
                              <label>STATUS</label>
                              <input
                                type="text"
                                name="service"
                                value={isLocked ? "LOCKED" : "UNLOCKED"}
                                className="form-control clear"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            fdprocessedid="7wkuky"
                            onClick={closeEditModel}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            fdprocessedid="w927w4"
                            onClick={handleWalletBalanceUpdate}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="card">
                <div className="card-header">
                  <h1 className="m-0 text-dark float-right">
                    <span
                      className="btn btn-sm btn-primary"
                      onClick={handleOpenModel}
                    >
                      <i id="sign" className="fa fa-plus"></i>{" "}
                      <span id="new">NEW WALLET</span>
                    </span>
                  </h1>
                </div>
                <MinistatementWalletRecord
                  data={walletList}
                  updateStatus={updateStatus}
                  updateBalance={updateBalance}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="main-footer">
        <strong>
          Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
        </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 4.5.0
        </div>
      </footer>
    </>
  );
}

import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import RestService from '../http';
import { ToastContainer } from 'react-toastify';
import EmployeeNavbar from './EmployeeNavbar';
import showToast from '../components/utilityComponents/APPToast';
import Loader from '../components/Loader';

export default function AddEmployee() {

    const service = new RestService();
    const [address, setAddress] = useState('');
    const [mobile, setMobile] = useState('');
    const [empName, setEmpName] = useState('');
    const [level, setLevel] = useState('');
    const [designation, setDesignation] = useState('');
    const [upline, setUpline] = useState('');
    const [category, setcategory] = useState('Marketing');
    const [loading, setLoading] = useState(false);
    const [designationList, setDesignationList] = useState([]);
    const [uplineList, setUplineList] = useState([]) 

    const handleAddEmp = () => {
        var raw = {
            "empName": empName,
            "mobile": mobile,
            "address": address,
            "level": level,
            "category": category,
            "uplineId": upline,
            "empRole": designation

        }

        setLoading(true);

        service.post("emp/addMktEmployee", raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);

                setTimeout(() => {
                    window.location.href = "/fetchemp";
                }, 2000);

            } else {
                showToast("DANGER", result.message);

            }
        }).catch((error) => {
            console.log(error);
        })


    }


    const handleDesignation = (e) => {

        setDesignation(e.target.value);

        service.post(`emp/viewEmpUpline/${e.target.value}`, null).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setUplineList(result.data);

            } else {
                setUplineList([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    }


    useEffect(() => {
        service.post("emp/viewDesignation", null).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                setDesignationList(result.data)
            } else {
                setDesignationList([]);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);


    return (
        <>
            <EmployeeNavbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: '600.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Marketing Employee</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right" style={{ marginLeft: '-20px' }}>
                                    <li className="breadcrumb-item"><a href="/admin">Dashboard</a></li>
                                    <li className="breadcrumb-item"><a href="#">HR Management</a></li>
                                    <li className="breadcrumb-item">employee</li>
                                    <li className="breadcrumb-item active" style={{ marginLeft: 'auto' }}>employee Registration</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <label className="text-primary">Personal Information</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Employee Name</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    name="employee_name"
                                                    className="form-control"
                                                    placeholder="Enter Name"
                                                    required=""
                                                    onChange={(e) => setEmpName(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Mobile</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    name="mobile"
                                                    minLength="10"
                                                    maxLength="10"
                                                    className="form-control"
                                                    placeholder="Enter Mobile Number"
                                                    required=""
                                                    onChange={(e) => setMobile(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Level</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter Level"
                                                    required=""
                                                    onChange={(e) => setLevel(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Address</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    onChange={(e) => setAddress(e.target.value)}
                                                    className="form-control"
                                                    placeholder="Enter Address"
                                                    required
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-2" data-select2-id="4">
                                                <label for="service_id">
                                                    Employee Role: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleDesignation}
                                                >
                                                    <option value="">Select...</option>
                                                    {designationList.map((item) => (
                                                        <option value={item.id}>{item.designation}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label for="service_id">
                                                    Upline: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setUpline(e.target.value)}
                                                >
                                                    <option value="">Select...</option>
                                                    {uplineList.map((item) => (
                                                        <option value={item.empId}>{item.empName}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-2" style={{ marginTop: '10px' }}>
                                                <label>Category</label><span className="text-danger"> *</span>
                                                <input
                                                    type="text"
                                                    // onChange={(e) => setcategory(e.target.value)}
                                                    className="form-control"
                                                    placeholder="Enter Category"
                                                    required
                                                    value={category}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12" style={{ marginTop: '20px', textAlign: 'center' }}>
                                                <input onClick={handleAddEmp} value="Add Employee" className="btn btn-primary" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <a href="www.softmintdigital.com">SOFTMINT</a>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

function Cards() {
    return (
        <Card style={{ width: '18rem', margin: "5%", boxShadow: "0px 15px 35px rgba(0, 0, 0, 0.1)", borderRadius: "10px" }}>
            <Card.Img variant="top" src="./image/banner.png" style={{ borderRadius: "10px" }} />
            <Card.Body>
                <Card.Title>User Commission</Card.Title>
                <Card.Text>
                    Some quick example text to build on the card title and make up the
                    bulk of the card's content.
                </Card.Text>

                <div className='row'>
                    <div className='col-md-6'>
                        <label>Slabs :</label><br />
                        <input
                            style={{ color: "black", border: "none", borderBottom: "2px solid grey", width: "80px" }}
                            value="(100-999)"
                        />
                    </div>

                    <div className='col-md-6'>
                        <label>Commission :</label><br />
                        <input
                            style={{ color: "black", border: "none", borderBottom: "2px solid grey", width: "80px" }}
                            value="2"
                        />
                    </div>
                </div>
            </Card.Body>

            <Card.Body>
                <button style={{border:"none", color:"white", background:"brown", fontWeight:"bold",borderRadius:"4px", padding:"5px",float:"right"}}>
                    Update
                    </button>
            </Card.Body>
        </Card>
    );
}

export default Cards;
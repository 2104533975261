import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const TableBody = ({ data, setAmount }) => {
    return (
        <>
            {
                data.map((plan, index) => (
                    <tr key={index}>
                        <td>
                            <button className='btn-primary' onClick={(e) => setAmount(plan.price)}
                                style={{ borderRadius: "10px", width: "5rem" }}>{plan.price}
                            </button>
                        </td>
                        <td>{plan.description}</td>
                        <td>{plan.Talktime}</td>
                        <td>{plan.Validity}</td>
                    </tr>
                ))
            }
        </>

    );
}

function RechargePlanView({ show, setShow, topup, stv, data, frc, roming, sms, jioPhone, fultt, setAmount }) {


    // const [TOPUP, setTopup] = useState([]);
    // const [STV, setStv] = useState([]);
    // const [DATA, setData] = useState([]);
    // const [FRC, setFrc] = useState([]);
    // const [Romaing, setRoming] = useState([]);
    // const [SMS, setSms] = useState([]);
    // const [JioPhone, setJioPhone] = useState([]);
    // const [FULLTT, setFulLtt] = useState([]);

    const offcanvasStyles = {
        width: '50%',
        marginTop: '5rem',
        overflow: 'scroll',
    };

    const offCanvasTabStyle = {
        color: '#000000',
        backgroundColor: '#ffffff'
    };

    const offcanvasScrollingStyle = {
        
    }

    // const handlePlanDetails = () => {
    //     console.log('PLAN DETAILS', planDetails);
    //     const topupData = planDetails && planDetails.planDetails.TOPUP ? planDetails.planDetails.TOPUP : [];
    //     const stvData = planDetails && planDetails.planDetails.STV ? planDetails.planDetails.STV : [];
    //     const dataData = planDetails && planDetails.planDetails.DATA ? planDetails.DATA : [];
    //     const frcData = planDetails && planDetails.planDetails.FRC ? planDetails.planDetails.FRC : [];
    //     const roamingData = planDetails && planDetails.planDetails.Romaing ? planDetails.planDetails.Romaing : [];
    //     const smsData = planDetails && planDetails.planDetails.SMS ? planDetails.planDetails.SMS : [];
    //     const jioPhoneData = planDetails && planDetails.planDetails.JioPhone ? planDetails.planDetails.JioPhone : [];
    //     const fullTTData = planDetails && planDetails.planDetails.FULLTT ? planDetails.planDetails.FULLTT : [];
    
    //     setTopup(topupData);
    //     setStv(stvData);
    //     setData(dataData);
    //     setFrc(frcData);
    //     setRoming(roamingData);
    //     setSms(smsData);
    //     setJioPhone(jioPhoneData);
    //     setFulLtt(fullTTData);
    // }

    // useEffect(() => {
    //     handlePlanDetails();
    // }, []);

    return (
        <>
            <Offcanvas show={show} onHide={setShow} placement='end' style={offcanvasStyles}>
                <Offcanvas.Header closeButton>

                </Offcanvas.Header>

                <Tabs
                    defaultActiveKey="topup"
                    transition={true}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="topup" title="TOPUP">
                        <Offcanvas.Body>
                            {topup ? (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Price</th>
                                            <th>Description</th>
                                            <th>Talktime</th>
                                            <th>Validity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <TableBody data={topup} setAmount={setAmount} />
                                    </tbody>
                                </table>
                            ) : (
                                <p>No mobile recharge plans available.</p>
                            )}
                        </Offcanvas.Body>
                    </Tab>

                    <Tab eventKey="STV" title="STV">
                        <Offcanvas.Body>
                            {stv ? (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Price</th>
                                            <th>Description</th>
                                            <th>Talktime</th>
                                            <th>Validity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <TableBody data={stv} setAmount={setAmount}/>
                                    </tbody>
                                </table>
                            ) : (
                                <p>No mobile recharge plans available.</p>
                            )}
                        </Offcanvas.Body>
                    </Tab>

                    <Tab eventKey="data" title="DATA">
                        <Offcanvas.Body>
                            {data ? (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Price</th>
                                            <th>Description</th>
                                            <th>Talktime</th>
                                            <th>Validity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <TableBody data={data} setAmount={setAmount} />
                                    </tbody>
                                </table>
                            ) : (
                                <p>No mobile recharge plans available.</p>
                            )}
                        </Offcanvas.Body>
                    </Tab>

                    <Tab eventKey="frc" title="FRC">
                        <Offcanvas.Body>
                            {frc ? (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Price</th>
                                            <th>Description</th>
                                            <th>Talktime</th>
                                            <th>Validity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <TableBody data={frc} setAmount={setAmount} />
                                    </tbody>
                                </table>
                            ) : (
                                <p>No mobile recharge plans available.</p>
                            )}
                        </Offcanvas.Body>
                    </Tab>

                    <Tab eventKey="roaming" title="ROAMING">
                        <Offcanvas.Body>
                            {roming ? (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Price</th>
                                            <th>Description</th>
                                            <th>Talktime</th>
                                            <th>Validity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <TableBody data={roming} setAmount={setAmount} />
                                    </tbody>
                                </table>
                            ) : (
                                <p>No mobile recharge plans available.</p>
                            )}
                        </Offcanvas.Body>
                    </Tab>

                    <Tab eventKey="sms" title="SMS">
                        <Offcanvas.Body>
                            {sms ? (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Price</th>
                                            <th>Description</th>
                                            <th>Talktime</th>
                                            <th>Validity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <TableBody data={sms} setAmount={setAmount} />
                                    </tbody>
                                </table>
                            ) : (
                                <p>No mobile recharge plans available.</p>
                            )}
                        </Offcanvas.Body>
                    </Tab>

                    <Tab eventKey="jiophone" title="JIO PHONE">
                        <Offcanvas.Body>
                            {jioPhone ? (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Price</th>
                                            <th>Description</th>
                                            <th>Talktime</th>
                                            <th>Validity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <TableBody data={jioPhone} setAmount={setAmount} />
                                    </tbody>
                                </table>
                            ) : (
                                <p>No mobile recharge plans available.</p>
                            )}
                        </Offcanvas.Body>
                    </Tab>

                    <Tab eventKey="full tt" title="FULL TALKTIME">
                        <Offcanvas.Body>
                            {fultt ? (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Price</th>
                                            <th>Description</th>
                                            <th>Talktime</th>
                                            <th>Validity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <TableBody data={fultt} setAmount={setAmount} />
                                    </tbody>
                                </table>
                            ) : (
                                <p>No mobile recharge plans available.</p>
                            )}
                        </Offcanvas.Body>
                    </Tab>

                </Tabs>

            </Offcanvas>
        </>
    );
}


export default RechargePlanView;
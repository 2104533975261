import React, { useEffect } from 'react';
import { CiBellOn } from 'react-icons/ci';
import { AiOutlineFolderOpen, AiFillPrinter } from 'react-icons/ai';
import { MdLogout } from 'react-icons/md';
import { FaUserAlt, FaFileExport, FaWeixin, FaTimes } from 'react-icons/fa';
import { BiUserCircle, BiHomeAlt, BiChevronDown } from 'react-icons/bi';
import { HiOutlineChat } from 'react-icons/hi';
import { RiShoppingBasketLine } from 'react-icons/ri';
import { BsGrid, BsFingerprint } from 'react-icons/bs';
import { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import RestService from "../http";
import { ToastContainer } from "react-toastify";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logout from '../components/utilityComponents/Logout';
import formatDate from '../components/utilityComponents/FormatDate';
import Loader from '../components/Loader';
import Pagination2 from '../components/utilityComponents/Pagination2';
import showToast from '../components/utilityComponents/APPToast';
import Select from 'react-select';


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function RetailerInsuranceReport() {

    const service = new RestService(4);
    const [openDropdowns, setOpenDropdowns] = useState([]);
    const [status, setStatus] = useState('');
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [balance, setBalance] = useState({});
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [name] = useState(sessionStorage.getItem("name"));
    const [roleName] = useState(sessionStorage.getItem("role"));
    const [insuranceReport, setInsuranceReport] = useState([]);
    const [userList, setUserList] = useState([]);
    const [userId, setUserId] = useState("");
    const [roles, setRoles] = useState([]);


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const [searchInput, setSearchInput] = useState('');
    const [filteredInsuranceReport, setFilteredInsuranceReport] = useState([]);


    useEffect(() => {
        const filteredList = insuranceReport.filter(item =>
            item.date.toString().includes(searchInput.toLowerCase()) ||
            item.policyNumber.toString().includes(searchInput.toLowerCase()) ||
            item.policyStatus.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.insurer.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.orderId.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.agentId.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.mobile.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.userId.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.name.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredInsuranceReport(filteredList);
    }, [insuranceReport, searchInput]);

    const paginatedInsuranceReport = filteredInsuranceReport.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleSearch = () => {
        var raw = {
            "fromDate": formatDate(date),
            "toDate": formatDate(date1),
            "userId": userId
        };

        setLoading(true);

        service.post("report/fetchInsuranceReport", raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                setInsuranceReport(result.data);
            } else {
                showToast("DANGER", result.message);
                setInsuranceReport([]);
            }
        }).catch((err) => {
            console.error(err);
        });
    }

    useEffect(() => {
        handleSearch();
    }, []);

    const handleClick = (index) => {
        const updatedOpenDropdowns = [...openDropdowns];

        if (updatedOpenDropdowns.includes(index)) {
            updatedOpenDropdowns.splice(updatedOpenDropdowns.indexOf(index), 1);
        } else {
            updatedOpenDropdowns.push(index);
        }

        setOpenDropdowns(updatedOpenDropdowns);
    };


    const handleStatus = (e) => {
        console.log(e.target.value);
        setStatus(e.target.value);
    }

    const modalRef = useRef(null);

    const openForm = (event) => {
        event.preventDefault();
        modalRef.current.style.display = 'block';
    };

    const closeForm = () => {
        modalRef.current.style.display = 'none';
    };

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        service.fetchUserBalance().then(result => result.json()).then(balres => {
            console.log(balres);
            setBalance(balres);

        }).catch((error) => {
            console.log(error);
        })

    }, []);


    useEffect(() => {
        service.get("user/roles").then(result => {
            console.log(result);
            setRoles(result.role);

        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const handleCustomerType = (e) => {
        console.log(e.target.value);
        service
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setUserList(result.data);
                } else {
                    setUserList([]);
                }
            })
            .catch((err) => {
                console.error(err);
                setUserList([]);
            });
    };


    const [selectedUserId, setSelectedUserId] = useState(null);

    const options = [
        { value: '', label: 'Select an option' },
        ...userList.map((item) => ({
            value: item.userId,
            label: `${item.name} || ${item.mobile}`,
        }))
    ];

    const handleSelectChange = (selectedUserId) => {
        setSelectedUserId(selectedUserId);
        setUserId(selectedUserId.value);
    };

    return (
        <>
            <ToastContainer />
            {loading && <Loader />}

            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" className='bg-light' open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className="pull-right" style={{ marginLeft: "79%" }}>
                            <div className="btn-group main-balance" style={{ borderRadius: "10px", backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)', color: "white", marginTop: "2px" }}>
                                <button type="button" data-toggle="dropdown" style={{ color: "white", fontWeight: "500", background: "none", border: "none" }} >
                                    <img src="image/wallet.png" className="wallet-icon" />
                                    <span className="mybal-text-white">My Balance</span><br />
                                    <span className="badge" id="wallet" style={{ fontSize: "14px" }}>&#8377;
                                        {balance === undefined
                                            ? 0.0
                                            : balance.data}
                                    </span>
                                </button>
                            </div>&nbsp;&nbsp;

                            <div className="btn-group btn-group-list btn-group-notification notification">
                                <button style={{ background: "none", border: "none", color: "black", position: "relative", }} type="button" data-toggle="dropdown">
                                    <CiBellOn style={{ fontSize: "30px" }} />
                                    <span className="badge"
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            background: "red", // Change the background color as needed
                                            borderRadius: "50%",
                                            padding: "5px",
                                            fontSize: "12px",
                                            color: "white",
                                        }}
                                    >0</span></button>

                            </div>&nbsp;&nbsp;

                            <div className="btn-group btn-group-option">
                                <button style={{ background: "none", border: "none" }} type="button" data-toggle="dropdown"><span
                                    className='user-img'>
                                    <img className='rounded-circle img-circle' src='image/profile.png' width='40' alt='' /></span></button>
                                <ul className="dropdown-menu pull-right" role="menu">
                                    <li><a href="/editprofile"><FaUserAlt className='glyphicon' /> My Profile</a>
                                    </li>
                                    <li className="divider"></li>
                                    <li>
                                        <a onClick={() => Logout("your-url")}>
                                            <MdLogout className='glyphicon' />Sign Out
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            <img style={{ width: "150px" }} src='./image/softmintlogo.png' />
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>

                    </DrawerHeader>
                    <Divider />
                    <List>

                        <div className="leftpanel" style={{ backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)' }}>
                            <div className="media profile-left">
                                <a className="pull-left profile-thumb" href="/editprofile">
                                    <img
                                        src="./image/profile.png"
                                        alt="user"
                                        className="img-circle"
                                    /> </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                <div className="media-body">
                                    <h5 className="media-heading">{name}</h5>
                                    <small className="text-white">{service.roles[new Number(roleName)]}</small>
                                </div>
                            </div>
                            <ul className="nav nav-pills nav-stacked">
                                <li className="active"><a href="/side"><BiHomeAlt className='house' /> <span>
                                    Dashboard</span></a></li>

                                <li className="parent" ><a href="#" onClick={() => handleClick(0)} ><BiUserCircle className='house' />
                                    <span> My Account</span>

                                    <BiChevronDown style={{ marginLeft: "75px" }} />
                                </a>

                                    <ul className={`children ${openDropdowns.includes(0) ? 'show' : ''}`}>
                                        <li className="" ><a href="/editprofile"> Edit Profile</a></li>
                                        <li className=""><a href="/kyc"> KYC Verification</a></li>
                                        <li className=""><a href="/kycmicroatm"> KYC Microatm</a></li>
                                        <li className=""><a href="/changepasswrd"> Change Password</a></li>
                                        <li className=""><a href="/commison"> My Commission</a></li>
                                        <li className=""><a href="print/certificate-print.php" target="_blank"> Print
                                            Certificate</a></li>
                                    </ul>

                                </li>
                                <li className="parent" >
                                    <a href="#" onClick={() => handleClick(1)}>
                                        <RiShoppingBasketLine className='house' /> <span>My Wallet</span>

                                        <BiChevronDown style={{ marginLeft: "85px" }} />
                                    </a>
                                    <ul className={`children ${openDropdowns.includes(1) ? 'show' : ''}`}>
                                        <li className=""><a href="/accountinfo"> Account Info</a></li>
                                        <li className=""><a href="/bankaccount"> Bank Account Add</a></li>
                                        <li className=""><a href="/sendmoney"> Send Money</a></li>
                                        {/* <li className=""><a href="/addmoney"> Add Money</a></li> */}
                                        {/* <li className=""><a href="/fundrequest"> Fund Request</a></li> */}
                                        {/* <li className=""><a href="/wallettrans"> Wallet Transfer</a></li> */}
                                        {/* <li className=""><a href="/mpin"> Change Mpin</a></li> */}
                                    </ul>
                                </li>

                                <li className="">
                                    <a href="/retailerservice"><BsGrid className='house' /> <span>
                                        Services</span>
                                        <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                    </a>
                                </li>

                                <li className="parent"><a href="#" onClick={() => handleClick(2)}><AiOutlineFolderOpen className='house' /> <span> Reports</span>

                                    <BiChevronDown style={{ marginLeft: "97px" }} />
                                </a>
                                    <ul className={`children ${openDropdowns.includes(2) ? 'show' : ''}`}>
                                        <li className=""><a href="/rechargereportretailer"> Recharge Report</a></li>
                                        <li className=""><a href="/aepsreport"> AEPS Report </a></li>
                                        <li className=""><a href="/aadharpayreport"> AadharPay Report </a></li>
                                        <li className=""><a href="/retailertxnreport"> Transaction Report </a></li>
                                        <li className=""><a href="/payout"> Payout Report</a></li>
                                        <li className=""><a href="/nsdlreport"> NSDL Report</a></li>
                                        <li className=""><a href="/retailerinsurancereport"> Insurance Report</a></li>                                        <li className=""><a href="/retailerearningreport"> Earning Report</a></li>
                                                                              <li className=""><a href="/bbpsreport"> BBPS Report</a></li>

                                         
                                        <li className=""><a href="/matmreport"> MATM Report</a></li>
                                        <li className=""><a href="/retailercouponreport"> Coupon Req. Report</a></li>
                                        <li className=""><a href="/retailermatmlogreport"> MATM Log Report</a></li>
                                        <li className=""><a href="/retailermovetobankuserreport"> MoveToBankUser Report</a></li>
                                        <li className=""><a href="/dmtreport"> DMT Report</a></li>

                                    </ul>
                                </li>
                                <li className=""><a href="/feedback"><HiOutlineChat className='house' /> <span>
                                    Feedback</span>
                                    <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                </a></li>
                                <li className=""><a href="/kyc"><BsFingerprint className='house' /> <span>
                                    AEPS E-Kyc</span>
                                    <BiChevronDown style={{ marginLeft: "70px", visibility: "hidden" }} />
                                </a></li>
                            </ul>

                            <div className="menu-devide">&nbsp;</div>
                            <div style={{ display: open ? 'block' : 'none' }}>
                                <div className="customer-support-sec">
                                    <h3>Account Details</h3>
                                    <p>ICICI BANK</p>
                                    <p style={{ fontSize: "11px" }}>(SOFTMINT)</p>
                                    <p>A/C No - 260705000861</p>
                                    <p><span>IFSC -</span> ICIC0002607</p>
                                </div>
                                <div className="menu-devide">&nbsp;</div>
                                <div className="customer-support-sec" style={{ display: open ? 'block' : 'none' }}>
                                    <h3>Quick Support</h3>
                                    <p>SOFTMINT</p>
                                    <p>Phone : 9999726418</p>
                                    <p>What's App : 8809912400</p>
                                    <p>Email : <a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                        data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}>info@softmintdigital.com</a></p>
                                </div>
                            </div>
                            <div style={{
                                display: open ? 'none' : 'block',
                                height: '100vh'
                            }}>
                                <div>
                                    <h3></h3>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p><span></span></p>
                                </div>
                                <div className="menu-devide">&nbsp;</div>
                                <div className="">
                                    <h3></h3>
                                    <p></p>
                                    <p></p>
                                    <p><a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                        data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}></a></p>
                                </div>
                            </div>

                        </div><br />
                    </List>
                </Drawer>

                <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                    <DrawerHeader />
                    <div className="pageheader">
                        <div className="media">
                            <div className="media-body">
                                <ul className="breadcrumb">
                                    <li><a href="javascript:void(0);"><BiHomeAlt style={{ color: '#999' }} /></a></li>&nbsp;
                                    <li>Reports</li>
                                </ul>
                                <h4>Insurance Report</h4>
                            </div>
                        </div>
                    </div>

                    <div className="card" style={{ padding: "5px", margin: "1%" }}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="service-form">
                                    <h1 className="service-title">Insurance Report</h1>
                                    <div className="pull-right" style={{ marginTop: "-33px", marginRight: "15px", float: "right" }}>
                                        {/* <a title="" data-toggle="tooltip" className="tooltips mr5" href="javascript:void(0);" data-original-title="Search" id="filterdt" onClick={showSearchPanel}><FaSearch className="glyphicon glyphicon-print" /> Search</a>&nbsp;&nbsp; */}
                                        <a title="" data-toggle="tooltip" className="tooltips mr5" href="javascript:void(0);" onClick="printme()" data-original-title="Print"><AiFillPrinter className="glyphicon glyphicon-print" /> Print</a>&nbsp;&nbsp;
                                        <a title="" data-toggle="tooltip" className="tooltips mr5" href="javascript:void(0);" onClick="exportdata()" data-original-title="Export"><FaFileExport className="fa" /> Export</a>
                                    </div>

                                    <div>
                                        <section className='card' style={{ padding: "10px" }}>
                                            <div className="row">

                                                <div className="col-md-3">
                                                    <div className="input-box">
                                                        <label for="rec_to_load"> Start Date</label><br />
                                                        <DatePicker
                                                            className="custom-date-picker"
                                                            name="string"
                                                            fdprocessedid="9n5i4u"
                                                            selected={date}
                                                            dateFormat={"yyyy-MM-dd"}
                                                            onChange={(date) => setDate(date)}
                                                            showYearDropdown
                                                            showMonthDropdown
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="input-box">
                                                        <label for="rec_to_load"> End Date</label>
                                                        <br />
                                                        <DatePicker
                                                            className="custom-date-picker"
                                                            name="string"
                                                            disabledDayAriaLabelPrefix="#$"
                                                            fdprocessedid="9n5i4u"
                                                            selected={date1}
                                                            dateFormat={"yyyy-MM-dd"}
                                                            onChange={(date) => setDate1(date)}
                                                            showYearDropdown
                                                            showMonthDropdown
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <label for="assign_commission">
                                                        User Type: <span className="text-danger"> *</span>
                                                    </label>
                                                    <select
                                                        className="form-control select2 "
                                                        required=""
                                                        id="service_id"
                                                        data-select2-id="service_id"
                                                        tabindex="-1"
                                                        aria-hidden="true"
                                                        onChange={handleCustomerType}
                                                    >
                                                        <option>Select User Type</option>
                                                        {roles.map((item) => (
                                                            item.roleId !== 0 ? <option value={item.roleId}>{item.roleName}</option> : null
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="col-md-3" data-select2-id="4">
                                                    <label for="service_id">
                                                        User Id: <span className="text-danger"> *</span>
                                                    </label>
                                                    {/* <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setUserId(e.target.value)}
                                                >
                                                    <option value="">Select Id...</option>
                                                    {userList.map((item) => (
                                                        <option value={item.userId}>{item.name}</option>
                                                    ))}
                                                </select> */}

                                                    <Select
                                                        className="select-box width100p"
                                                        options={options}
                                                        isSearchable
                                                        value={selectedUserId}
                                                        onChange={handleSelectChange}
                                                    />
                                                </div>

                                                <div className="col-md-12 text-center" style={{ marginTop: "2%", marginLeft: "1%" }}>
                                                    <button
                                                        className="btn btn-primary service-btn but-save"
                                                        onClick={handleSearch}
                                                    >
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                        </section>

                                        <center>
                                            <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                                <div>
                                                    {/* <ul className="pagination pagination-sm pagination-colory" style={{ justifyContent: "center" }}>
                                                                <li className="disabled"><a onClick={handlePreviousPage}><AiOutlineDoubleLeft className='fa' /></a></li>
                                                                <li className="disabled"><a onClick={handleNextPage}><AiOutlineDoubleRight className='fa' /></a></li>
                                                            </ul> */}

                                                    {/* <div className="col-md-2 form-control">

                                                                <input
                                                                    className=' text-box'
                                                                    type="text"
                                                                    placeholder="Search by Transaction Type"
                                                                    value={searchInput}
                                                                    onChange={(e) => setSearchInput(e.target.value)}
                                                                />
                                                            </div> */}

                                                    <div className="col-md-3" style={{ float: "left" }}>
                                                        <div>
                                                            <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                            <input
                                                                className="form-control text-box"
                                                                type="text"
                                                                placeholder="SEARCH..."
                                                                value={searchInput}
                                                                onChange={(e) => setSearchInput(e.target.value)}

                                                            />
                                                        </div>
                                                    </div><br />

                                                    <Pagination2
                                                        totalPages={Math.ceil(insuranceReport.length / itemsPerPage)}
                                                        currentPage={currentPage}
                                                        onChange={handlePageChange}

                                                    />
                                                </div>
                                            </div>
                                        </center>

                                        <div className="table-responsive" style={{ overflowX: 'scroll', width: "100%" }}>
                                            <table className="table table-bordered mb30">
                                                <thead>
                                                    <tr className="table-new">
                                                        <th className="px-1">SL.NO</th>
                                                        <th>NAME</th>
                                                        <th>USER ID</th>
                                                        <th>MOBILE</th>
                                                        <th>AGENT ID</th>
                                                        <th>COMMISSION POINTS</th>
                                                        <th>ORDER ID</th>
                                                        <th>INSURER</th>
                                                        <th>POLICY STATUS</th>
                                                        <th>TOTAL PREMIUM</th>
                                                        <th>POLICY NO.</th>
                                                        <th>DATE</th>
                                                        <th>POLICY ISSUE DATE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(!paginatedInsuranceReport || paginatedInsuranceReport.length === 0) ? (
                                                        <tr className='text-center'>
                                                            <td colSpan="13">NO DATA AVAILABLE</td>
                                                        </tr>
                                                    ) : (
                                                        paginatedInsuranceReport.map((item, index) => (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{item.name}</td>
                                                                <td>{item.userId}</td>
                                                                <td>{item.mobile}</td>
                                                                <td>{item.agentId}</td>
                                                                <td>{item.commissionPoints}</td>
                                                                <td>{item.orderId}</td>
                                                                <td>{item.insurer}</td>
                                                                <td>{item.policyStatus}</td>
                                                                <td>{item.totalPrem}</td>
                                                                <td>{item.policyNumber}</td>
                                                                <td>{item.date}</td>
                                                                <td>{item.policyIssueDate}</td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>

                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Box>
            </Box>

            <a href="https://api.whatsapp.com/send/?phone=8809912400&text&app_absent=0" target="_blank"
                className="whatsapp-but" title="">
                <img src="image/whatsapp-icon.png" alt="Whatapps" />
            </a>

            <a
                href="#"
                target="_blank"
                className="helpdesk-but"
                title="Request Callback !"
                onClick={openForm}
            >
                <img src="image/help-icon.png" alt="Help" />
            </a>

            <div className="chat-popup" ref={modalRef} id="myForm">
                <form action="#" className="helpdesk-container" onSubmit={(e) => e.preventDefault()}>
                    <h1>
                        <div className="callbackheader-icon">
                            <FaWeixin aria-hidden="true" style={{ fontSize: '35px', paddingTop: '7px' }} />
                        </div>
                        CallBack Request
                    </h1>
                    <label htmlFor="callback_name"><b>Full Name</b></label>
                    <input
                        type="text"
                        id="callback_name"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="Name"
                        required
                    />
                    <label htmlFor="callback_phone"><b>Contact Number</b></label>
                    <input
                        type="text"
                        id="callback_phone"
                        maxLength="10"
                        name=""
                        className="form-control textbox"
                        placeholder="+91 9000 0000 00"
                        required
                    />
                    <label htmlFor="callback_email"><b>Email ID</b></label>
                    <input
                        type="text"
                        id="callback_email"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="info@softmintdigital.com"
                        required
                    />
                    <label htmlFor="callback_msg"><b>Message (250 Character Only)</b></label>
                    <textarea
                        id="callback_msg"
                        maxLength="250"
                        placeholder="Type message.."
                        name="msg"
                        className="form-controlx"
                    ></textarea>
                    <button type="submit" className="btn" id="reqcallbut">
                        Send Now
                    </button>
                    <a href="#" className="close-but" title="" onClick={closeForm}>
                        <FaTimes aria-hidden="true" />
                    </a>
                </form>
            </div>

            <div className="modal fade recharge-model success-model" id="model-succ-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="model-succ-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button success-button" onClick="errordisplay()" data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model error-model" id="model-err-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className=""><img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Error Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-err-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button error-button" onClick="errordisplay()" data-dismiss="modal">Dismiss</button>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model process-model" id="model-process-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Process Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-process-body">
                            <p className="mb-0"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model " id="model-yn-save" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-body-pdf">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="getinvno" />
                        <button type="button" className="btn btn-success model-button success-button" onClick="succok()" data-dismiss="modal" id="modal-no-save">OK</button>
                    </div>
                </div>
            </div>


            );
        </>
    );
}

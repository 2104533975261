import React from "react";
import Navbar from "./Navbar";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestService from "../http";
import Loader from "./Loader";

export default function Addmember() {

  const rest = new RestService();
  const [customerType, setCustomerType] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [pin, setPin] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [firmName, setFirmName] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [empType, setEmpType] = useState("");
  const [officialInfo, setOfficialInfo] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [aadhaar, setAadhaar] = useState("");
  const [pan, setPan] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [roles, setRoles] = useState([]);
  const [uplineType, setUplineType] = useState("");
  const [packageId, setPackageId] = useState("");
  const [userList, setUserList] = useState([]);
  const [rechargeWalletLock, setRechargeWalletLock] = useState("0.00");
  const [fundRequestLock, setFundRequestLock] = useState("0.00");
  const [loading, setLoading] = useState(false);


  const navigate = useNavigate();


  const showToast = (status, message) => {
    if (status === "SUCCESS") {
      toast.success(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (status === "DANGER") {
      toast.error(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.warn(message,
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const handleAddMember = () => {

    if (customerType === undefined || customerType === "" || customerType === null) {
      showToast('WARN', 'PLEASE CHOOSE CUSTOMER TYPE!!');
      return false;
    }

    if (name === undefined || name === "" || name === null) {
      showToast('WARN', 'PLEASE FILL CUSTOMER NAME!!');
      return false;
    }

    if (address === undefined || address === "" || address === null) {
      showToast('WARN', 'PLEASE FILL CUSTOMER ADDRESS!!');
      return false;
    }

    if (pinCode === undefined || pinCode === "" || pinCode === null) {
      showToast('WARN', 'PLEASE FILL AREA PINCODE!!');
      return false;
    }

    if (district === undefined || district === "" || district === null) {
      showToast('WARN', 'PLEASE FILL DISTRICT!!');
      return false;
    }

    if (state === undefined || state === "" || state === null) {
      showToast('WARN', 'PLEASE FILL STATE!!');
      return false;
    }

    if (firmName === undefined || firmName === "" || firmName === null) {
      showToast('WARN', 'PLEASE FILL YOUR FIRM NAME!!');
      return false;
    }

    if (city === undefined || city === "" || city === null) {
      showToast('WARN', 'PLEASE FILL CITY NAME!!');
      return false;
    }

    if (country === undefined || country === "" || country === null) {
      showToast('WARN', 'PLEASE FILL COUNTRY NAME!!');
      return false;
    }

    if (email === undefined || email === "" || email === null) {
      showToast('WARN', 'PLEASE FILL VALID EMAIL!!');
      return false;
    }

    if (mobile === undefined || mobile === "" || mobile === null) {
      showToast('WARN', 'PLEASE FILL VALID MOBILE NUMBER!!');
      return false;
    }

    if (aadhaar === undefined || aadhaar === "" || aadhaar === null) {
      showToast('WARN', 'PLEASE FILL VALID AADHAAR!!');
      return false;
    }

    if (pan === undefined || pan === "" || pan === null) {
      showToast('WARN', 'PLEASE FILL VALID PAN NUMBER!!');
      return false;
    }

    var raw = {
      roleId: customerType,
      name: name,
      firmName: firmName,
      address: address,
      city: city,
      pinCode: pinCode,
      state: state,
      country: country,
      mobile: mobile,
      email: email,
      pan: pan,
      aadhaar: aadhaar,
      district: district,
      officialInfo: officialInfo,
      empType: empType,
      assignedTo: "",
      uplineId: uplineType,
    };

    setLoading(true);

    rest.post(`user/add`, raw).then(result => {
      setLoading(false);

      console.log(result);
      if (result.status === rest.SUCCESS) {
        showToast('SUCCESS', result.message);
        setTimeout(() => {
          navigate("/member");
        }, 2000);

      } else {
        showToast("DANGER", result.message);
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  const handleCustomerType = (e) => {
    var roleId = e.target.value;
    var userRole = e.target.value;
    setCustomerType(roleId);
    if (roleId === 1 || roleId === 2) {
      userRole = 0;
    } else {
      userRole = roleId - 1;
    }

    rest.post(`user/getUpline/${userRole}`, null).then(result => {
      console.log(result);

      if (result.status === rest.SUCCESS) {
        setUserList(result.data);

      } else {
        setUserList([]);
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    rest.get("user/roles").then(result => {
      console.log(result);
      setRoles(result.role);

    }).catch((error) => {
      console.log(error);
    });
  }, []);


  const handleBusinessNameChange = (event) => {
    setBusinessName(event.target.value.toUpperCase());
  };

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value.toUpperCase());
  };


  const handlePinCodeChange = (event) => {
    setPinCode(event.target.value);
    // Add the get_pincode_data logic here if needed
  };

  const handleDistrictChange = (event) => {
    setDistrict(event.target.value.toUpperCase());
  };

  const handleStateChange = (event) => {
    setState(event.target.value.toUpperCase());
  };

  const handleFirmName = (event) => {
    setFirmName(event.target.value.toUpperCase());
  };

  const handleCity = (event) => {
    setCity(event.target.value.toUpperCase());
  };

  const handleCountry = (event) => {
    setCountry(event.target.value.toUpperCase());
  };


  const handleOfficialInfo = (event) => {
    setOfficialInfo(event.target.value.toUpperCase());
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleMobileChange = (event) => {
    const regex = /^[0-9]{0,10}$/; 
    if (regex.test(event.target.value)) {
      setMobile(event.target.value);
    }
  };

  const handleAadhaarNumberChange = (event) => {
    const regex = /^[0-9]{0,12}$/;
    if (regex.test(event.target.value)) {
      setAadhaar(event.target.value);
    }
  };

  const handlePanNumberChange = (event) => {
    const regex = /^[A-Z0-9]{0,10}$/;
    if (regex.test(event.target.value)) {
      setPan(event.target.value.toUpperCase());
    }
  };


  const handlePinChange = (event) => {
    const regex = /^[0-9]{0,4}$/;
    if (regex.test(event.target.value)) {
      setPin(event.target.value);
    }
  };

  const handlePackageChange = (event) => {
    setPackageId(event.target.value);
  };

  const handleRechargeWalletLockChange = (event) => {
    setRechargeWalletLock(event.target.value);
  };

  const handleFundRequestLockChange = (event) => {
    setFundRequestLock(event.target.value);
  };

  return (
    <>
      <Navbar />
      <ToastContainer />
      {loading && <Loader />}


      <div className="content-wrapper" style={{ minHeight: "238.667px" }}>
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">New Member Registration</h1>
              </div>
              {/* ... */}
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div>
                  {/* Business Information */}
                  <p className="font-weight-bold text-primary m-0">
                    Business Information
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Customer Type <span className="text-danger"> *</span></label>

                      <select
                        className="form-control"
                        name="customer_type"
                        id="customer_type"
                        fdprocessedid="g30y5e"
                        onChange={handleCustomerType}
                      >

                        <option value="">SELECT CUSTOMER TYPE</option>
                        {roles.map((role) => (
                          <option key={role.roleId} value={role.roleId}>
                            {role.roleName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-6">
                      <label className="font-weight-normal">
                        Business Name{" "}
                        <small className="text-primary">
                          (Mandatory for business account.)
                        </small>
                      </label>
                      <input
                        onChange={handleBusinessNameChange}
                        name="business_name"
                        type="text"
                        className="form-control"
                        value={businessName}
                        placeholder="ENTER BUSINESS NAME"
                      />
                    </div>
                  </div>
                  <hr />
                  {/* Personal Information */}
                  <p className="font-weight-bold text-primary m-0">
                    Personal Information
                  </p>
                  <div className="row">
                    <div className="col-md-4">
                      <label className="font-weight-normal">
                        {" "}
                        Name <span className="text-danger"> *</span>
                      </label>
                      <input
                        name="first_name"
                        type="text"
                        className="form-control"
                        value={name}
                        placeholder="Enter name"
                        onChange={handleName}
                      />
                    </div>

                    <div className="col-md-4 ">
                      <label className="font-weight-normal">
                        Address <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleAddressChange}
                        name="address"
                        type="text"
                        maxLength="35"
                        className="form-control"
                        value={address}
                        placeholder="Enter address"
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="font-weight-normal">
                        Pincode <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handlePinCodeChange}
                        name="pincode"
                        type="text"
                        className="form-control"
                        value={pinCode}
                        placeholder="Enter pincode"
                      />
                    </div>

                    <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        District <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleDistrictChange}
                        name="district"
                        type="text"
                        className="form-control"
                        value={district}
                        placeholder="Enter district"
                      />
                    </div>

                    <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        State <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleStateChange}
                        name="state"
                        type="text"
                        className="form-control"
                        value={state}
                        placeholder="Enter state"
                      />
                    </div>

                    <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        Firm Name <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleFirmName}
                        name="state"
                        type="text"
                        className="form-control"
                        value={firmName}
                        placeholder="Enter state"
                      />
                    </div>

                    <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        City <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleCity}
                        name="state"
                        type="text"
                        className="form-control"
                        value={city}
                        placeholder="Enter state"
                      />
                    </div>

                    <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        Country <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleCountry}
                        name="state"
                        type="text"
                        className="form-control"
                        value={country}
                        placeholder="Enter state"
                      />
                    </div>

                    <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        Official Info <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleOfficialInfo}
                        name="state"
                        type="text"
                        className="form-control"
                        value={officialInfo}
                        placeholder="Enter state"
                      />
                    </div>
                  </div>
                  <hr />
                  {/* Contact Information */}
                  <p className="font-weight-bold text-primary m-0">
                    Contact Information
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="font-weight-normal">
                        Email <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleEmail}
                        name="email"
                        type="email"
                        className="form-control"
                        value={email}
                        placeholder="Enter email"
                      />
                    </div>

                    <div className="col-md-6">
                      <label className="font-weight-normal">
                        Mobile <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleMobileChange}
                        name="mobile"
                        type="text"
                        className="form-control"
                        value={mobile}
                        placeholder="Enter mobile"
                        maxLength="10"
                      />
                    </div>
                  </div>
                  <hr />
                  {/* KYC Information */}
                  <p className="font-weight-bold text-primary m-0">
                    KYC Information{" "}
                    <small>Image max size should be 500kb</small>
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="font-weight-normal">
                        Aadhaar Number <span className="text-danger"> *</span>{" "}
                        <small className="text-primary">
                          (Download PDF file Only){" "}
                          <a
                            href="https://eaadhaar.uidai.gov.in/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <u>Download Link!</u>
                          </a>
                        </small>
                      </label>
                      <input
                        onChange={handleAadhaarNumberChange}
                        name="aadhaar_number"
                        type="text"
                        className="form-control"
                        value={aadhaar}
                        placeholder="Enter aadhaar number"
                        maxLength="12"
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="font-weight-normal">
                        Pan Number <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handlePanNumberChange}
                        name="pan_number"
                        type="text"
                        className="form-control"
                        value={pan}
                        placeholder="Enter pan number"
                        maxLength="10"
                      />
                    </div>
                  </div>
                  <hr />
                  {/* Account Information */}
                  <p className="font-weight-bold text-primary m-0">
                    Account Information
                  </p>
                  <div className="row">
                    
                    <div className="col-md-4">
                      <label className="font-weight-normal">
                        Pin <span className="text-danger"> *</span>
                        <small className="text-primary">
                          (4 Character Number Only)
                        </small>
                      </label>
                      <input
                        onChange={handlePinChange}
                        name="pin"
                        type="password"
                        maxLength="4"
                        minLength="4"
                        className="form-control"
                        placeholder="Enter secure 4 digit pin"
                        value={pin}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="font-weight-normal">
                        Upline <span className="text-danger"> *</span>
                      </label>
                      <select
                        onChange={(e) => setUplineType(e.target.value)}
                        className="form-control select2 "
                        name="upline_id"
                        id="upline_id"
                      >
                        <option>Select Upline</option>
                        {userList.map((item) => (
                          <option key={item.userId} value={item.userId}>
                            {item.name} | {item.mobile}|
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-4">
                      <label className="font-weight-normal">
                        Package <span className="text-danger"> *</span>
                      </label>
                      <select
                        onChange={handlePackageChange}
                        className="form-control"
                        name="package_id"
                        id="package_id"
                        value={packageId}
                      >
                        <option value="">Select Package</option>
                      </select>
                    </div>
                    <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        Recharge Wallet Lock{" "}
                        <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleRechargeWalletLockChange}
                        name="recharge_wallet_lock"
                        type="text"
                        className="form-control"
                        value={rechargeWalletLock}
                        placeholder="Enter recharge wallet lock"
                      />
                    </div>
                    <div className="col-md-4 mt-2">
                      <label className="font-weight-normal">
                        Fund Request Lock{" "}
                        <span className="text-danger"> *</span>
                      </label>
                      <input
                        onChange={handleFundRequestLockChange}
                        name="fund_request_lock"
                        type="text"
                        className="form-control"
                        value={fundRequestLock}
                        placeholder="Enter fund request lock"
                      />
                    </div>
                    <input
                      type="hidden"
                      name="securityToken"
                      value="5ff53b14089896e4190c46b2ad082571"
                    />
                  </div>
                  <hr />
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary"
                      onClick={handleAddMember}
                    >
                      <i className="fa fa-save"></i>
                      Register Member
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <footer className="main-footer">
        <strong>
          Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
        </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 4.5.0
        </div>
      </footer>
    </>
  );
}

import React, { useEffect } from 'react';
import { CiBellOn } from 'react-icons/ci';
import { AiOutlineFolderOpen } from 'react-icons/ai';
import { MdLogout } from 'react-icons/md';
import { FaUserAlt, FaWeixin, FaTimes } from 'react-icons/fa';
import { BiUserCircle, BiHomeAlt, BiChevronDown } from 'react-icons/bi';
import { HiOutlineChat } from 'react-icons/hi';
import { RiShoppingBasketLine } from 'react-icons/ri';
import { BsGrid, BsFingerprint } from 'react-icons/bs';
import { useState, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RestService from '../http';
import showToast from '../components/utilityComponents/APPToast';
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Logout from '../components/utilityComponents/Logout';
import Loader from '../components/Loader';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalFooter,
  MDBModalBody,
} from "mdb-react-ui-kit";


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function RetailerService() {

  const service = new RestService();
  const navigate = useNavigate();
  const [openDropdowns, setOpenDropdowns] = useState([]);
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [balance, setBalance] = useState({});
  // const [insuranceUrl, setInsuranceUrl] = useState(null);
  const [name] = useState(sessionStorage.getItem("name"));
  const [roleName] = useState(sessionStorage.getItem("role"));
  const [loading, setLoading] = useState(false);
  const [basicModal, setBasicModal] = useState(false);


  const handleClick = (index) => {
    const updatedOpenDropdowns = [...openDropdowns];

    if (updatedOpenDropdowns.includes(index)) {
      updatedOpenDropdowns.splice(updatedOpenDropdowns.indexOf(index), 1);
    } else {
      updatedOpenDropdowns.push(index);
    }

    console.log(updatedOpenDropdowns);

    setOpenDropdowns(updatedOpenDropdowns);
  };


  const modalRef = useRef(null);

  const openForm = (event) => {
    event.preventDefault();
    modalRef.current.style.display = 'block';
  };

  const closeForm = () => {
    modalRef.current.style.display = 'none';
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const handleAepsStatus = () => {
    var raw = {};

    service.post("nsdlaeps/aepsstatuscheck", raw).then(result => {
      console.log(result);
      if (result.status === 1) {
        navigate("/aepsservice");
        showToast(result.message);

        setTimeout(() => {
          window.location.reload();
        }, 500);

      } else if (result.status === 2) {
        navigate("/aepslogin");
        showToast(result.message);

        setTimeout(() => {
          window.location.reload();
        }, 500);

      } else if (result.status === 3) {
        navigate("/registeraeps");
        showToast(result.message);

      } else if (result.status === 6) {
        navigate("/kyc");
        showToast(result.message);

      } else if (result.status === service.FAILED) {
        showToast(result.message, "SOMETHING WENT WRONG..PLEASE TRY AFTER SOME TIME!!!");

      } else {
        showToast("DANGER", result.message);
      }
    })
  }

  const handleAadharStatus = () => {

    setLoading(true);

    service.post("matm/aadharpay2FaStatusCheck", null).then(result => {
      setLoading(false);

      console.log(result);

      if (result.status === service.SUCCESS) {
        navigate("/aadharpayservice");
        showToast(result.message);

        setTimeout(() => {
          window.location.reload();
        }, 500);

      } else if (result.status === service.FAILED) {
        navigate("/aadhar2falogin");
        showToast(result.message);

        setTimeout(() => {
          window.location.reload();
        }, 500);

      } else {
        showToast("DANGER", result.message)
      }
    }).catch((error) => {
      console.log(error);
    })

  }


  useEffect(() => {
    service.fetchUserBalance().then(result => result.json()).then(balres => {
      console.log(balres);
      setBalance(balres);

    }).catch((error) => {
      console.log(error);
    })

  }, []);


  const handleInsurance = () => {

    setLoading(true);

    service.post("insurance/loginInsurance/UAT", null).then(result => {
      setLoading(false);

      console.log(result);

      // const dataUrl = "https://fpaffiliate.tapits.in/fpaffiliateui/#/user-verification?clientdata=NmkvRGk5R3lxM2VHc1lKVkdIWDEveGVZR044NnM0dVVYd2xWQXRTUkFLWStka0YwUGNWK24vZjREcEFiRWJjb01Md3lvZ2YxZmhXaUVhUnFhT3cvWk5sMW84V3VURkNiNDRTa1hUOUQybHR2cDljUHJ3eEoxdFN0RkEzM1UrMmg=&secretkey=ZmluZ3BheWFmZmlsaWF0ZQ=="

      // setInsuranceUrl(dataUrl);
      // window.open(dataUrl, '_blank');

      // console.log(dataUrl);

      if (result.status === service.SUCCESS) {
        showToast("SUCCESS", result.message);

        window.open(result.data, '_blank');

      } else {
        showToast("DANGER", result.message);
      }
    })
  }

  const handleNsdlPan = async () => {
    const result = await service.post("nsdl/fetchNsdlAgent");

    console.log(result);

    if (result.status === service.SUCCESS) {
      navigate("/nsdlpan");

    } else if (result.status === service.AGENT_NOT_FOUND) {
      // showToast("WARNING", result.message);
      setBasicModal(true);

    } else {
      showToast("DANGER", result.message);
    }
  }

  const handleCloseModal = () => {
    setBasicModal(false);
  }


  const handleCreateAgent = async () => {

    setLoading(true);

    const result = await service.post("nsdl/createNsdlAgent/NSDLPANAGENT", null);
    setLoading(false);

    console.log(result);

    if (result.status === service.SUCCESS || result.status === service.DONE_ALREADY) {
      showToast("SUCCESS", result.message);
      navigate("/nsdlpan");
    } else if (result.status === service.NOT_PERMITTED) {
      showToast("WARNING", result.message);
    } else if (result.status === service.EXCEPTIONAL_ERROR) {
      showToast("DANGER", "SOMETHING WENT WRONG PLEASE KINDLY CONTACT TO ADMIN!!")
    } else {
      showToast("DANGER", result.message);
    }
  }

  return (
    <>
      <ToastContainer />
      {loading && <Loader />}


      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" className='bg-light' open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <div className="pull-right" style={{ marginLeft: "79%" }}>
              <div className="btn-group main-balance" style={{ borderRadius: "10px", backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)', color: "white", marginTop: "2px" }}>
                <button type="button" data-toggle="dropdown" style={{ color: "white", fontWeight: "500", background: "none", border: "none" }} >
                  <img src="image/wallet.png" className="wallet-icon" />
                  <span className="mybal-text-white">My Balance</span><br />
                  <span className="badge" id="wallet" style={{ fontSize: "14px" }}>&#8377;
                    {balance === undefined
                      ? 0.0
                      : balance.data}
                  </span>
                </button>
              </div>&nbsp;&nbsp;

              <div className="btn-group btn-group-list btn-group-notification notification">
                <button style={{ background: "none", border: "none", color: "black", position: "relative", }} type="button" data-toggle="dropdown">
                  <CiBellOn style={{ fontSize: "30px" }} />
                  <span className="badge"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      background: "red", // Change the background color as needed
                      borderRadius: "50%",
                      padding: "5px",
                      fontSize: "12px",
                      color: "white",
                    }}
                  >0</span></button>

              </div>&nbsp;&nbsp;

              <div className="btn-group btn-group-option">
                <button style={{ background: "none", border: "none" }} type="button" data-toggle="dropdown"><span
                  className='user-img'>
                  <img className='rounded-circle img-circle' src='image/profile.png' width='40' alt='' /></span></button>

                <ul className="dropdown-menu pull-right" role="menu">
                  <li><a href="/editprofile"><FaUserAlt className='glyphicon' /> My Profile</a>
                  </li>

                  <li className="divider"></li>
                  <li>
                    <a onClick={() => Logout("your-url")}>
                      <MdLogout className='glyphicon' />Sign Out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              <img style={{ width: "150px" }} src='./image/softmintlogo.png' />
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>

          </DrawerHeader>
          <Divider />
          <List>

            <div className="leftpanel" style={{ backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)' }}>
              <div className="media profile-left">
                <a className="pull-left profile-thumb" href="/editprofile">
                  <img
                    src="./image/profile.png"
                    alt="user"
                    className="img-circle"
                  /> </a>&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="media-body">
                  <h5 className="media-heading">{name}</h5>
                  <small className="text-white">{service.roles[new Number(roleName)]}</small>
                </div>
              </div>

              <ul className="nav nav-pills nav-stacked">
                <li className="active"><a href="/side"><BiHomeAlt className='house' /> <span>
                  Dashboard</span></a></li>

                <li className="parent" ><a onClick={() => handleClick(0)} ><BiUserCircle className='house' />
                  <span> My Account</span>

                  <BiChevronDown style={{ marginLeft: "75px" }} />
                </a>

                  <ul className={`children ${openDropdowns.includes(0) ? 'show' : ''}`}>
                    <li><a href="/editprofile"> Edit Profile</a></li>
                    <li><a href="/kyc"> KYC Verification</a></li>
                    <li><a href="/kycmicroatm"> KYC Microatm</a></li>
                    <li><a href="/changepasswrd"> Change Password</a></li>
                    <li><a href="/commison"> My Commission</a></li>
                    <li><a href="print/certificate-print.php" target="_blank"> Print
                      Certificate</a></li>
                  </ul>

                </li>
                <li className="parent" ><a onClick={() => handleClick(1)}>
                  <RiShoppingBasketLine className='house' /> <span>My Wallet</span>

                  <BiChevronDown style={{ marginLeft: "85px" }} />
                </a>
                  <ul className={`children ${openDropdowns.includes(1) ? 'show' : ''}`}>
                    <li className=""><a href="/accountinfo"> Account Info</a></li>
                    <li className=""><a href="/bankaccount"> Bank Account Add</a></li>
                    <li className=""><a href="/sendmoney"> Send Money</a></li>
                    {/* <li className=""><a href="/addmoney"> Add Money</a></li> */}
                    {/* <li className=""><a href="/fundrequest"> Fund Request</a></li> */}
                    {/* <li className=""><a href="/wallettrans"> Wallet Transfer</a></li> */}
                    {/* <li className=""><a href="/mpin"> Change Mpin</a></li> */}
                  </ul>
                </li>

                <li className="">
                  <a href="/retailerservice"><BsGrid className='house' /> <span>
                    Services</span>
                    <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                  </a>
                </li>

                <li className="parent">
                  <a onClick={() => handleClick(2)}><AiOutlineFolderOpen className='house' /> <span> Reports</span>

                    <BiChevronDown style={{ marginLeft: "97px" }} />
                  </a>
                  <ul className={`children ${openDropdowns.includes(2) ? 'show' : ''}`}>
                    <li><a href="/rechargereportretailer"> Recharge Report</a></li>
                    <li><a href="/aepsreport"> AEPS Report </a></li>
                    <li className=""><a href="/aadharpayreport"> AadharPay Report</a></li>
                    <li><a href="/retailertxnreport"> Transaction Report </a></li>
                    <li><a href="/payout"> Payout Report</a></li>
                    <li className=""><a href="/nsdlreport"> NSDL Report</a></li>
                    <li><a href="/retailerearningreport"> Earning Report</a></li>
                    <li className=""><a href="/bbpsreport"> BBPS Report</a></li>
                    <li><a href="/pancard"> Pancard Report</a></li>
                    <li><a href="/ledger"> Ledger Report</a></li>
                    <li className=""><a href="/matmreport"> MATM Report</a></li>
                    <li className=""><a href="/retailercouponreport"> Coupon Req. Report</a></li>
                    <li className=""><a href="/retailermatmlogreport"> MATM Log Report</a></li>
                    <li className=""><a href="/retailermovetobankuserreport"> MoveToBankUser Report</a></li>
                    <li className=""><a href="/dmtreport"> DMT Report</a></li>

                  </ul>
                </li>

                <li className="">
                  <a href="/feedback"><HiOutlineChat className='house' /> <span>
                    Feedback</span>
                    <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                  </a></li>

                <li className="">
                  <a href="/kyc"><BsFingerprint className='house' /> <span>
                    AEPS E-Kyc</span>
                    <BiChevronDown style={{ marginLeft: "70px", visibility: "hidden" }} />
                  </a></li>
              </ul>

              <div className="menu-devide">&nbsp;</div>
              <div style={{ display: open ? 'block' : 'none' }}>
                <div className="customer-support-sec">
                  <h3>Account Details</h3>
                  <p>ICICI BANK</p>
                  <p style={{ fontSize: "11px" }}>(SOFTMINT)</p>
                  <p>A/C No - 260705000861</p>
                  <p><span>IFSC -</span> ICIC0002607</p>
                </div>
                <div className="menu-devide">&nbsp;</div>
                <div className="customer-support-sec" style={{ display: open ? 'block' : 'none' }}>
                  <h3>Quick Support</h3>
                  <p>SOFTMINT</p>
                  <p>Phone : 9999726418</p>
                  <p>What's App : 8809912400</p>
                  <p>Email : <a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                    data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}>info@softmintdigital.com</a></p>
                </div>
              </div>
              <div style={{
                display: open ? 'none' : 'block',
                height: '100vh'
              }}>
                <div>
                  <h3></h3>
                  <p></p>
                  <p></p>
                  <p></p>
                  <p><span></span></p>
                </div>
                <div className="menu-devide">&nbsp;</div>
                <div className="">
                  <h3></h3>
                  <p></p>
                  <p></p>
                  <p><a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                    data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}></a></p>
                </div>
              </div>

            </div><br />
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <DrawerHeader />
          <div className="pageheader">
            <div className="media">
              <div className="media-body">
                <ul className="breadcrumb">
                  <li>
                    <a href="#"><BiHomeAlt style={{ color: '#999' }} /></a>&nbsp;
                  </li>
                  <li>Services</li>
                </ul>
                <h4 >My Services</h4>
                {/* <div className="services-search-box">
                  <input type="text" id="srvname" onkeyup="dashboard_display_all_services(1)"
                    placeholder="Search..." className="services-search" onkeypress="return blockAddressChar(event)" fdprocessedid="48c6yb" />
                </div> */}
              </div>
            </div>
          </div>

          <div className="contentpanel" style={{ background: "#f5f5f5" }}>
            <div id="displaytext">
              <div className="services-box red-skinx">
                <div className="row">
                  <div className="col-md-2 col-sm-3 col-xs-12">
                    <a title="AEPS"></a>
                    <div  >
                      <a title="AEPS">
                        <div className="services-icon-box">
                          <img src="./image/1.png" alt="AEPS" />
                        </div>

                        <h3 className="services-name">AEPS</h3>
                      </a>
                      <a className="service-but text-white" onClick={handleAepsStatus}>PROCEED</a>
                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12">
                    <a title="AADHAR PAY"></a>
                    <div  >
                      <a title="AADHARPAY">
                        <div className="services-icon-box">
                          <img src="./image/14.png" alt="AEPS" />
                        </div>

                        <h3 className="services-name">AADHAR PAY</h3>
                      </a>
                      <a className="service-but text-white" onClick={handleAadharStatus}>PROCEED</a>
                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12">
                    <a title="Money Transfer"></a>
                    <div>
                      <a title="Money Transfer">
                        <div className="services-icon-box">
                          <img src="./image/2.png" alt="Money Transfer" />
                        </div>
                        <h3 className="services-name">DMT (1)</h3>
                      </a>
                      <a href="/dmt" className="service-but text-white">PROCEED</a>
                      {/* <a href="#" className="service-but">PROCEED</a> */}

                    </div>
                  </div>


                  <div className="col-md-2 col-sm-3 col-xs-12">
                    <a title="Mobile Recharge"></a>
                    <div>
                      <a title="Mobile Recharge">
                        <div className="services-icon-box">
                          <img src="./image/3.png" alt="Mobile Recharge" />
                        </div>
                        <h3 className="services-name">Mobile Recharge</h3>
                      </a>
                      <a href="/mobilerecharge" className="service-but">PROCEED</a>
                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12">
                    <a title="DTH Recharge"></a>
                    <div>
                      <a title="DTH Recharge">
                        <div className="services-icon-box">
                          <img src="./image/4.png" alt="DTH Recharge" />
                        </div>
                        <h3 className="services-name">DTH Recharge</h3>
                      </a>
                      <a href="/dth" className="service-but">PROCEED</a>
                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12">
                    <a href="/electricity" title="Electricity">
                    </a>
                    <div>
                      <a title="Electricity">
                        <div className="services-icon-box">
                          <img src="./image/5.png" alt="Electricity" />
                        </div>
                        <h3 className="services-name">Electricity</h3>
                      </a>
                      <a className="service-but text-white" href='/electricity' >PROCEED</a>
                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="cable Recharge"></a>
                    <div>
                      <a title="Cable Recharge">
                        <div className="services-icon-box">
                          <img src="./image/4.png" alt="cable Recharge" />
                        </div>
                        <h3 className="services-name">Cable Recharge</h3>
                      </a>
                      <a href="/cable" className="service-but">PROCEED</a>
                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="cable TV Recharge"></a>
                    <div>
                      <a title="Cable TV Recharge">
                        <div className="services-icon-box">
                          <img src="./image/4.png" alt="cable TV Recharge" />
                        </div>
                        <h3 className="services-name">Cable TV Recharge</h3>
                      </a>
                      <a href="/cabletv" className="service-but">PROCEED</a>
                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 mt-5 col-xs-12 ">
                    <a title="LANDLINE">
                    </a>
                    <div>
                      <a title="LANDLINE">
                        <div className="services-icon-box">
                          <img src="./image/6.png" alt="LANDLINE" />
                        </div>
                        <h3 className="services-name">LANDLINE</h3>
                      </a>
                      <a href="/landline" className="service-but">PROCEED</a>
                      {/* <a href="#" className="service-but">PROCEED</a> */}

                    </div>
                  </div>


                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="Fastag Recharge"></a>
                    <div>
                      <a title="Fastag Recharge">
                        <div className="services-icon-box">
                          <img src="./image/7.png" alt="Fastag Recharge" />
                        </div>
                        <h3 className="services-name">Fastag Recharge</h3>
                      </a>
                      <a href="/fastag" className="service-but">PROCEED</a>
                      {/* <a href="#" className="service-but">PROCEED</a> */}

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="broadband Recharge"></a>
                    <div>
                      <a title="broadband Recharge">
                        <div className="services-icon-box">
                          <img src="./image/Broadband.png" alt="Broadband Recharge" />
                        </div>
                        <h3 className="services-name">Broadband</h3>
                      </a>
                      <a href="/broadband" className="service-but">PROCEED</a>
                      {/* <a href="#" className="service-but">PROCEED</a> */}

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="broadband Postpaid"></a>
                    <div>
                      <a title="broadband Postpaid">
                        <div className="services-icon-box">
                          <img src="./image/Broadband.png" alt="Broadband Postpaid" />
                        </div>
                        <h3 className="services-name">Broadband Postpaid</h3>
                      </a>
                      <a href="/broadbandpostpaid" className="service-but">PROCEED</a>
                      {/* <a href="#" className="service-but">PROCEED</a> */}

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="MOBILE POSTPAID"></a>
                    <div>
                      <a title="MOBILE POSTPAID">
                        <div className="services-icon-box">
                          <img src="./image/8.png" alt="MOBILE POSTPAID" />
                        </div>
                        <h3 className="services-name">MOBILE POSTPAID</h3>
                      </a>
                      <a href="/mobilepostpaid" className="service-but">PROCEED</a>

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="Data card PREPAID"></a>
                    <div>
                      <a title="Data card PREPAID">
                        <div className="services-icon-box">
                          <img src="./image/8.png" alt="Data card PREPAID" />
                        </div>
                        <h3 className="services-name">Data Card PREPAID</h3>
                      </a>
                      <a href="/datacardprepaid" className="service-but">PROCEED</a>
                      {/* <a href="#" className="service-but">PROCEED</a> */}

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="LIC"></a>
                    <div>
                      <a title="LIC">
                        <div className="services-icon-box">
                          <img src="./image/9.png" alt="LIC" />
                        </div>
                        <h3 className="services-name">LIC</h3>
                      </a>
                      {/* <a href="/lic" className="service-but">PROCEED</a> */}
                      <a href="#" className="service-but">PROCEED</a>

                    </div>
                  </div>


                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="LPG Cylinder Booking">
                    </a>
                    <div>
                      <a title="LPG Cylinder Booking">
                        <div className="services-icon-box">
                          <img src="./image/10.png" alt="LPG Cylinder Booking" />
                        </div>
                        <h3 className="services-name">LPG Booking</h3>
                      </a>
                      <a href="/lpg" className="service-but">PROCEED</a>

                    </div>
                  </div>


                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="Water">
                    </a>
                    <div>
                      <a title="Water">
                        <div className="services-icon-box">
                          <img src="./image/11.png" alt="Water" />
                        </div>
                        <h3 className="services-name">Water</h3>
                      </a>
                      <a href="/water" className="service-but">PROCEED</a>

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="Municipility"></a>
                    <div>
                      <a title="Municipility">
                        <div className="services-icon-box">
                          <img src="./image/12.png" alt="Municipility" />
                        </div>
                        <h3 className="services-name">Municipality</h3>
                      </a>
                      <a href="/muncipility" className="service-but">PROCEED</a>

                    </div>
                  </div>


                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="Gas Booking"></a>
                    <div>
                      <a title="Gas Booking">
                        <div className="services-icon-box">
                          <img src="./image/13.png" alt="Gas Booking" />
                        </div>
                        <h3 className="services-name">Gas Booking</h3>
                      </a>
                      <a href="/gasbooking" className="service-but">PROCEED</a>

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="Traffic challan">
                    </a>
                    <div>
                      <a title="Traffic challan">
                        <div className="services-icon-box">
                          <img src="./image/17.png" alt="Traffic challan" />
                        </div>
                        <h3 className="services-name">TRAFFIC CHALLAN</h3>
                      </a>
                      <a href="/trafficchallan" className="service-but">PROCEED</a>
                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="PAN CARD">
                    </a>
                    <div>
                      <a title="PAN CARD">
                        <div className="services-icon-box">
                          <img src="./image/14.png" alt="PAN CARD" />
                        </div>
                        <h3 className="services-name">NSDL PAN</h3>
                      </a>
                      <a onClick={handleNsdlPan} className="service-but text-white">PROCEED</a>

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="UTI PAN">
                    </a>
                    <div>
                      <a title="UTI PAN">
                        <div className="services-icon-box">
                          <img src="./image/14.png" alt="PAN CARD" />
                        </div>
                        <h3 className="services-name">UTI PAN</h3>
                      </a>
                      <a href="/utipan" className="service-but">PROCEED</a>

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="INSURANCE"></a>
                    <div>
                      <a title="INSURANCE">
                        <div className="services-icon-box">
                          <img src="./image/15.png" alt="INSURANCE" />
                        </div>
                        <h3 className="services-name">INSURANCE</h3>
                      </a>
                      {/* <a href="/insurance" className="service-but">PROCEED</a> */}
                      <a onClick={handleInsurance} className="service-but text-white">PROCEED</a>

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="BBPS INSURANCE"></a>
                    <div>
                      <a title="BBPS INSURANCE">
                        <div className="services-icon-box">
                          <img src="./image/15.png" alt="BBPS INSURANCE" />
                        </div>
                        <h3 className="services-name">BBPS INSURANCE</h3>
                      </a>
                      <a href="/insurance" className="service-but">PROCEED</a>

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="Credit card payment"></a>
                    <div>
                      <a title="Credit card payment">
                        <div className="services-icon-box">
                          <img src="./image/16.png" alt="Credit card payment" />
                        </div>
                        <h3 className="services-name">CREDIT CARD PAYMENT</h3>
                      </a>
                      <a href="/cashdeposit" className="service-but">PROCEED</a>

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="Emi payment"></a>
                    <div>
                      <a title="Emi payment">
                        <div className="services-icon-box">
                          <img src="./image/LoanEMI.png" alt="Emi payment" />
                        </div>
                        <h3 className="services-name">EMI PAYMENT</h3>
                      </a>
                      <a href="/emipayment" className="service-but">PROCEED</a>
                      {/* <a href="#" className="service-but">PROCEED</a> */}

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="Emi"></a>
                    <div>
                      <a title="Emi">
                        <div className="services-icon-box">
                          <img src="./image/LoanEMI.png" alt="Emi" />
                        </div>
                        <h3 className="services-name">EMI</h3>
                      </a>
                      <a href="/emi" className="service-but">PROCEED</a>
                      {/* <a href="#" className="service-but">PROCEED</a> */}

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="CMS">
                    </a>
                    <div>
                      <a title="CMS">
                        <div className="services-icon-box">
                          <img src="./image/17.png" alt="CMS" />
                        </div>
                        <h3 className="services-name">CMS</h3>
                      </a>
                      <a href="/cms" className="service-but text-white">PROCEED</a>

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="ADD COMPLAIN">
                    </a>
                    <div>
                      <a title="ADD COMPLAINT">
                        <div className="services-icon-box">
                          <img src="./image/17.png" alt="COMPLAIN" />
                        </div>
                        <h3 className="services-name">ADD COMPLAINT</h3>
                      </a>
                      <a href="/ticketraise" className="service-but">PROCEED</a>
                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="Fee payment"></a>
                    <div>
                      <a title="Fee payment">
                        <div className="services-icon-box">
                          <img src="./image/16.png" alt="Fee payment" />
                        </div>
                        <h3 className="services-name">FEE PAYMENT</h3>
                      </a>
                      <a href="/feepayment" className="service-but">PROCEED</a>
                      {/* <a href="#" className="service-but">PROCEED</a> */}

                    </div>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-12 mt-5">
                    <a title="Loan Repayment"></a>
                    <div>
                      <a title="Loan Repayment">
                        <div className="services-icon-box">
                          <img src="./image/12.png" alt="Loan Repayment" />
                        </div>
                        <h3 className="services-name">LOAN REPAYMENT</h3>
                      </a>
                      <a href="/loanrepayment" className="service-but">PROCEED</a>

                    </div>
                  </div>

                </div>

                {/* <div className='row'>

                  {insuranceUrl && (
                    <iframe title="InsuranceIframe" src={insuranceUrl} width="100%" height="500px"></iframe>
                  )}
                </div> */}
              </div>
            </div>
          </div>

          <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
            <MDBModalDialog>
              <MDBModalContent style={{ marginLeft: "-100%", marginTop: "30%" }}>
                <MDBBtn
                  className="btn-close mt-3"
                  color="none"
                  onClick={handleCloseModal}
                >
                </MDBBtn>
                <div className='text-center'>
                  <h5 style={{ fontWeight: "bold", color: "brown" }}>NSDL agent activation confirmation</h5>
                </div>
                <MDBModalHeader>
                  <MDBModalTitle style={{ fontSize: "10px" }}>NSDL Agent doesn't exist, Please active your agent first!!</MDBModalTitle>

                </MDBModalHeader>

                <MDBModalBody>
                  <div className='container' style={{ borderRadius: "10px", padding: "2%", background: "cornsilk" }}>
                    <div className='row'>
                      <div style={{ fontWeight: "bold" }}>
                        THIS SERVICE IS CHARGEBLE. DO YOU WANT TO PROCEED ?
                      </div>
                    </div>
                  </div>
                </MDBModalBody>

                <MDBModalFooter>
                  <button className='btn bg-red' color="secondary" onClick={handleCloseModal}>
                    NO
                  </button>
                  <button className='btn bg-blue' onClick={handleCreateAgent}>Yes</button>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>

        </Box>
      </Box>

      <a href="https://api.whatsapp.com/send/?phone=8809912400&text&app_absent=0" target="_blank"
        className="whatsapp-but" title="">
        <img src="image/whatsapp-icon.png" alt="Whatapps" />
      </a>

      <a
        href="#"
        target="_blank"
        className="helpdesk-but"
        title="Request Callback !"
        onClick={openForm}
      >
        <img src="image/help-icon.png" alt="Help" />
      </a>

      <div className="chat-popup" ref={modalRef} id="myForm">
        <form action="#" className="helpdesk-container" onSubmit={(e) => e.preventDefault()}>
          <h1>
            <div className="callbackheader-icon">
              <FaWeixin style={{ fontSize: '35px', paddingTop: '7px', color: "black" }} />
            </div>
            CallBack Request
          </h1>
          <label htmlFor="callback_name"><b>Full Name</b></label>
          <input
            type="text"
            id="callback_name"
            maxLength="35"
            name=""
            className="form-control textbox"
            placeholder="Name"
            required
          />
          <label htmlFor="callback_phone"><b>Contact Number</b></label>
          <input
            type="text"
            id="callback_phone"
            maxLength="10"
            name=""
            className="form-control textbox"
            placeholder="+91 9000 0000 00"
            required
          />
          <label htmlFor="callback_email"><b>Email ID</b></label>
          <input
            type="text"
            id="callback_email"
            maxLength="35"
            name=""
            className="form-control textbox"
            placeholder="info@softmintdigital.com"
            required
          />
          <label htmlFor="callback_msg"><b>Message (250 Character Only)</b></label>
          <textarea
            id="callback_msg"
            maxLength="250"
            placeholder="Type message.."
            name="msg"
            className="form-controlx"
          ></textarea>
          <button type="submit" className="btn" id="reqcallbut">
            Send Now
          </button>
          <a href="#" className="close-but" title="" onClick={closeForm}>
            <FaTimes aria-hidden="true" />
          </a>
        </form>
      </div>

      <div className="modal fade recharge-model success-model" id="model-succ-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="">
              <img src="images/success-icon.png" alt="" title="" className="error-icon" />
              <h4 id="successLabel" className="heading-text">Success Message!</h4>
            </div>
            <div className="modal-body" id="model-succ-body">
              <p className="mb-0"></p>
            </div>
            <input type="hidden" id="focusid" />
            <button type="button" className="btn btn-success model-button success-button" onClick="errordisplay()" data-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
      <div className="modal fade recharge-model error-model" id="model-err-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className=""><img src="images/error-icon.png" alt="" title="" className="error-icon" />
              <h4 id="errorLabel" className="heading-text">Error Message!</h4>
            </div>
            <div className="modal-body" id="modal-err-body">
              <p className="mb-0"></p>
            </div>
            <input type="hidden" id="focusid" />
            <button type="button" className="btn btn-success model-button error-button" onClick="errordisplay()" data-dismiss="modal">Dismiss</button>
          </div>
        </div>
      </div>
      <div className="modal fade recharge-model process-model" id="model-process-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="">
              <img src="images/error-icon.png" alt="" title="" className="error-icon" />
              <h4 id="errorLabel" className="heading-text">Process Message!</h4>
            </div>
            <div className="modal-body" id="modal-process-body">
              <p className="mb-0"></p>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade recharge-model " id="model-yn-save" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="">
              <img src="images/success-icon.png" alt="" title="" className="error-icon" />
              <h4 id="successLabel" className="heading-text">Success Message!</h4>
            </div>
            <div className="modal-body" id="modal-body-pdf">
              <p className="mb-0"></p>
            </div>
            <input type="hidden" id="getinvno" />
            <button type="button" className="btn btn-success model-button success-button" onClick="succok()" data-dismiss="modal" id="modal-no-save">OK</button>
          </div>
        </div>
      </div>


      );
    </>
  );
}

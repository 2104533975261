import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import RestService from "../http";
import swal from 'sweetalert';
import { FaFileExcel } from "react-icons/fa";
import formatDate from "./utilityComponents/FormatDate";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import showToast from './utilityComponents/APPToast';
import AepsRecords from './utilityComponents/AepsRecords';
import UtilityBbpsReport from './utilityComponents/UtilityBbpsReport';
import { ToastContainer } from 'react-toastify';
import UtilityRechargeReport from './utilityComponents/UtilityRechargeReport';
import AdminPayout from './utilityComponents/AdminPayout';
import UtilityNsdlReport from './utilityComponents/UtilityNsdlReport';
import UtilityD2hRecharge from './utilityComponents/UtilityD2hRecharge';


export default function ViewAllReports() {

    const navigate = useNavigate();

    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();
    const [allReport, setAllReport] = useState([]);
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());
    const [userList, setUserList] = useState([]);
    const [userId, setUserId] = useState("");
    const [roles, setRoles] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [serviceType, setServiceType] = useState("");
    const [keyWord, setKeyWord] = useState("");
    const [aepsCurrentRecord, setAepsCurrentRecord] = useState([]);
    const [aepsReport, setAepsReport] = useState([]);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [nPages, setNPages] = useState(0);
    const [status, setStatus] = useState("");


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);


    const service = new RestService();


    const handleGetAllReport = () => {
        var raw = {
            "startDate": formatDate(date),
            "endDate": formatDate(date1),
            "uplineId": userId,
            "downlineRoleId": "",
            "type": "",
            "keyword": keyWord,
            "serviceId": serviceType

        };

        console.log(raw);

        service.post("user/getReports?pageNumber=0&pageSize=", raw).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setAllReport(result.data);
                showToast("SUCCESS", result.message);

            } else {
                showToast("DANGER", result.message);
            }

        }).catch((err) => {
            console.error(err);
            setUserList([]);
        });
    };

    useEffect(() => {
        service.get("user/roles").then(result => {
            console.log(result);
            setRoles(result.role);

        }).catch((error) => {
            console.log(error);
        });
    }, []);


    // console.log(allReport);

    const handleCustomerType = (e) => {
        console.log(e.target.value);
        service
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setUserList(result.data);
                } else {
                    setUserList([]);
                }
            })
            .catch((err) => {
                console.error(err);
                setUserList([]);
            });
    };


    const handleServiceType = () => {

        service.post("service/getAllService", null).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setServiceList(result.report);

                const pages = Math.ceil(result.data.length / recordsPerPage);
                setNPages(pages);
                const lastIndex = currentPage * recordsPerPage;
                const firstIndex = lastIndex - recordsPerPage;
                const currentRecords = result.data.slice(firstIndex, lastIndex);
                setAepsCurrentRecord(currentRecords);
                console.log("CURRENT RECORD", currentRecords);

            } else {
                setServiceList([]);
                showToast("DANGER", result.message);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        handleServiceType();
    }, []);


    return (
        <>
            <Navbar />
            <ToastContainer />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">View All Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Recharge</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Recharge Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label for="rec_to_load"> Start Date <span className="text-danger"> *</span>
                                                </label><br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label for="rec_to_load"> End Date <span className="text-danger"> *</span></label>
                                                <br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    name="string"
                                                    disabledDayAriaLabelPrefix="#$"
                                                    fdprocessedid="9n5i4u"
                                                    selected={date1}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setDate1(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label for="assign_commission">
                                                    User Type: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleCustomerType}
                                                >
                                                    <option>Select User Type</option>
                                                    {roles.map((item) => (
                                                        item.roleId === 5 ? <option value={item.roleId}>{item.roleName}</option> : null
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="service_id">
                                                    User Id: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setUserId(e.target.value)}
                                                >
                                                    <option value="">Select Id...</option>
                                                    {userList.map((item) => (
                                                        <option value={item.userId}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3 mt-2">
                                                <label>
                                                    Service <span className="text-danger"> *</span>
                                                </label>

                                                <select
                                                    className="custom-date-picker"
                                                    name="customer_type"
                                                    id="customer_type"
                                                    fdprocessedid="g30y5e"
                                                    onChange={(e) => setServiceType(e.target.value)}
                                                >
                                                    <option value={""}>SELECT SERVICE</option>
                                                    {serviceList.map((item) => (
                                                        <option value={item.serviceType}>{item.serviceName}</option>
                                                    ))}
                                                </select>
                                            </div>


                                            <div className="col-md-3 mt-2">
                                                <label>
                                                    Keyword
                                                </label>

                                                <input
                                                    className="custom-date-picker"
                                                    name="customer_type"
                                                    id="customer_type"
                                                    fdprocessedid="g30y5e"
                                                    placeholder='Enter Keyword'
                                                    onChange={(e) => setKeyWord(e.target.value)}
                                                />

                                            </div>

                                            <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                                            <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ textAlign: 'center' }}>
                                                <button
                                                    onClick={handleGetAllReport}
                                                    className="btn btn-primary btn-block"
                                                    style={{ marginTop: '40px', color: 'white', fontWeight: 600 }}
                                                    fdprocessedid="qnf2"
                                                >
                                                    Search
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    {allReport.map((data) => (
                                        <div>
                                            {serviceType === "2" && (
                                                <AepsRecords
                                                    data={data}
                                                    allRecord={data}
                                                />
                                            )}
                                            {serviceType === "6" && (
                                                <UtilityBbpsReport data={data} />
                                            )}
                                            {serviceType === "1" && (
                                                <UtilityRechargeReport data={data} />
                                            )}
                                            {serviceType === "3" && (
                                                <AdminPayout
                                                    data={data}
                                                    allRecord={data}
                                                />
                                            )}
                                            {serviceType === "4" && (
                                                <UtilityNsdlReport data={data} />
                                            )}
                                            {serviceType === "5" && (
                                                <UtilityD2hRecharge data={data} />
                                            )}
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer" >
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Sliderimage() {

    const [showAddPack, setShowAddPack] = useState(false);

    const handleNewClick = () => {
        setShowAddPack(!showAddPack);
    };

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '532.667px' }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">App Slider</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Utility</Link>
                                    </li>
                                    <li className="breadcrumb-item active">App Slider</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        {showAddPack && (
                            <div className="card" id="addpack">
                                <div className="card-body">
                                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                        Mobile Application Slider Image Size Should Be 1200*400px Max Size 120kb.
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="alert"
                                            aria-label="Close"
                                            onClick={() => setShowAddPack(false)}
                                        >
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <form action="/sliderimage" method="post" enctype="multipart/form-data">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>App Slider Image</label>
                                                <input type="file" name="slider_image" className="form-control" placeholder="Select File" required />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Slider Type</label>
                                                <select name="type" className="form-control" required>
                                                    <option value="">Select Slider Type</option>
                                                    <option value="1">Recharge</option>
                                                    <option value="2">DMT</option>
                                                </select>
                                            </div>
                                            <input type="hidden" name="securityToken" value="fb30010b54f1ddcaec64b95f099ad2ce" />
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ marginTop: '40px' }}>
                                                <input type="submit" value="Add Slider" className="btn btn-primary" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}

                        <div className="card">
                            <div className="card-body">
                                <h1 className="m-0 text-dark float-right">
                                    <span className="btn btn-sm btn-primary" onClick={handleNewClick}>
                                        {showAddPack ? <i className="fa fa-minus" /> : <i className="fa fa-plus" />}{' '}
                                        <span id="new">{showAddPack ? 'CANCEL' : 'NEW'}</span>
                                    </span>
                                </h1>
                                <div className="clearfix" />
                                <div className="table-responsive">
                                    <table className="table m-0">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Slider Image</th>
                                                <th>Slider Type</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ paddingTop: '100px' }}>1</td>
                                                <td>
                                                    <img src="./image/855186.jpg" height="200px" width="600" alt="Slider" />
                                                </td>
                                                <td>RECHARGE</td>
                                                <td style={{ paddingTop: '100px' }}>
                                                    <Link to="/editslider">
                                                        <i className="fa fa-edit text-primary" aria-hidden="true" style={{ paddingRight: '20px' }} />
                                                    </Link>{' '}
                                                    <Link >
                                                        <i className="fa fa-trash text-danger" aria-hidden="true" />
                                                    </Link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Sendemail() {

    const [selectedValue, setSelectedValue] = useState('1');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropDownRef = useRef();

    const options = [
        { value: '', label: 'AJOY MONDAL [MD1003][9609236025]' },
        { value: '1', label: 'ARUL DOSS [MD1002][7904634883]' },
        { value: '2', label: 'NAVNATH SASE [RT1001][8600707581]' },
        { value: '3', label: 'Sharad Bandarkar [MA1000][8600436163]' },

    ];

    const handleOptionSelect = (option) => {
        setSelectedOption(option.value);
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">Send Email</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Utility</Link></li>
                                    <li className="breadcrumb-item active">Send Email</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section class="content">
                    <div className='container-fluid'>
                        <div class="card col-8 m-auto">
                            <div class="card-body">
                                <form action="/sendemail" method="post" accept-charset="utf-8">
                                    <input type="hidden" name="securityToken" value="3a57dd61812290cf4269b7af078fa7e1" />
                                    <div class="row">
                                        <div className="col-12">
                                            <div className="icheck-primary d-inline">
                                                <input
                                                    type="radio"
                                                    id="radioPrimary3"
                                                    name="type"
                                                    value="1"
                                                    checked={selectedValue === '1'}
                                                    onChange={handleChange}
                                                />
                                                <label htmlFor="radioPrimary3">Type wise</label>
                                            </div>
                                            <div className="icheck-primary d-inline ml-2">
                                                <input
                                                    type="radio"
                                                    id="radioPrimary4"
                                                    name="type"
                                                    value="2"
                                                    checked={selectedValue === '2'}
                                                    onChange={handleChange}
                                                />
                                                <label htmlFor="radioPrimary4">Customer wise</label>
                                            </div>
                                        </div>


                                        <div className={selectedValue === '1' ? 'col-12 mt-3' : 'col-12 mt-3 d-none'} id="type_div">
                                            <label>Select Customer Type</label>
                                            <span className="text-danger"> *</span>
                                            <select name="customer_type" className="form-control " data-placeholder="To" data-select2-id="1" tabindex="-1" aria-hidden="true" >
                                                <option value="" data-select2-id="3">Select Member</option>
                                                <option value="1">MASTER ADMIN</option>
                                                <option value="3">MASTER DISTRIBUTOR</option>
                                                <option value="4">DISTRIBUTOR</option>
                                                <option value="5">RETAILER</option>
                                                <option value="6">CUSTOMER</option>
                                                <option value="7">API USER</option>
                                            </select>

                                        </div>



                                        <div className={selectedValue === '2' ? 'col-12 mt-3' : 'col-12 mt-3 d-none'} id="customer_div">
                                            <label>Select Customer</label>
                                            <span className="text-danger"> *</span>
                                            <select name="customer_id[]" className="form-control select2 select2-hidden-accessible" multiple="" id="customer_list" data-placeholder="To" tabindex="-1" aria-hidden="true" data-select2-id="customer_list">
                                                <option value="" data-select2-id="7">Select Customer</option>
                                            </select>
                                            <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="6" style={{ width: 'auto' }}>

                                                <div
                                                    ref={dropDownRef}
                                                    className={`select2 select2-container select2-container--default ${isDropdownOpen ? 'select2-container--open' : ''
                                                        }`}
                                                    dir="ltr"
                                                    data-select2-id="2"
                                                    style={{ width: '585.625px' }}
                                                    onClick={toggleDropdown}
                                                >
                                                    <span className="selection">
                                                        <span
                                                            className={`select2-selection select2-selection--single ${isDropdownOpen ? 'select2-selection--active' : ''
                                                                }`}

                                                            role="combobox"
                                                            aria-haspopup="true"
                                                            aria-expanded={isDropdownOpen}
                                                            tabIndex="0"
                                                            aria-disabled="false"
                                                            aria-labelledby="select2-package_id-24-container"
                                                        >
                                                            <span
                                                                className="select2-selection__rendered"
                                                                id="select2-package_id-24-container"
                                                                role="textbox"
                                                                aria-readonly="true"
                                                                title={selectedOption ? options.find((option) => option.value === selectedOption).label : 'To'}
                                                            >
                                                                {selectedOption ? options.find((option) => option.value === selectedOption).label : 'To'}
                                                            </span>
                                                        </span>
                                                    </span>

                                                    {isDropdownOpen && (
                                                        <span className="dropdown-wrapper" style={{ position: 'absolute' }}>
                                                            <span className="select2-dropdown select2-dropdown--below" dir="ltr" style={{ width: '280.625px' }}>
                                                                <span className="select2-search select2-search--dropdown">
                                                                    <input
                                                                        className="select2-search__field"
                                                                        type="search"
                                                                        tabIndex="0"
                                                                        autoComplete="off"
                                                                        autoCorrect="off"
                                                                        autoCapitalize="none"
                                                                        spellCheck="false"
                                                                        role="searchbox"
                                                                        aria-autocomplete="list"
                                                                        aria-controls="select2-package_id-ci-results"
                                                                    />
                                                                </span>
                                                                <span className="select2-results">
                                                                    <ul className="select2-results__options" role="listbox" id="select2-package_id-ci-results" aria-expanded="true" aria-hidden="false">
                                                                        {options.map((option) => (
                                                                            <li
                                                                                key={option.value}
                                                                                className={`select2-results__option ${option.value === selectedOption ? 'select2-results__option--highlighted' : ''}`}
                                                                                role="option"
                                                                                aria-selected={option.value === selectedOption}
                                                                                data-select2-id={`select2-package_id-f9-result-${option.value}`}
                                                                                onClick={() => handleOptionSelect(option)}
                                                                            >
                                                                                {option.label}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    )}

                                                </div>

                                            </span>
                                        </div>


                                        <div className="col-12" style={{ marginTop: '10px' }}>
                                            <input
                                                type="text"
                                                required
                                                name="subject"
                                                placeholder="Subject"
                                                className="form-control"
                                                style={{ width: '610.333px' }}
                                            />
                                        </div>

                                        {/* <div className="col-12" style={{ marginTop: '10px' }}>
                                            <textarea
                                                name="message"
                                                rows="5"
                                                placeholder="Enter Your Message"
                                                value=""
                                                className="form-control"
                                                style={{ visibility: 'hidden', display: 'none' }}>
                                            </textarea>
                                            <div id="cke_message" className="cke_1 cke cke_reset cke_chrome cke_editor_message cke_ltr cke_browser_webkit" dir="ltr" lang="en" role="application" aria-labelledby="cke_message_arialbl">
                                                <span id="cke_message_arialbl" className="cke_voice_label">Rich Text Editor, message</span>
                                                <div className="cke_inner cke_reset" role="presentation">
                                                    <span id="cke_1_top" className="cke_top cke_reset_all" role="presentation" style={{ height: 'auto', userSelect: 'none' }}>
                                                        <span id="cke_11" className="cke_voice_label">Editor toolbars</span>
                                                        <span id="cke_1_toolbox" className="cke_toolbox" role="group" aria-labelledby="cke_11" onmousedown="return false;">
                                                            <span id="cke_16" className="cke_toolbar" aria-labelledby="cke_16_label" role="toolbar">
                                                                <span id="cke_16_label" className="cke_voice_label">Document</span>
                                                                <span className="cke_toolbar_start"></span>
                                                                <span className="cke_toolgroup" role="presentation">
                                                                    <a id="cke_17"
                                                                        className="cke_button cke_button__source cke_button_on"
                                                                        href="javascript:void('Source')"
                                                                        title="Source" tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_17_label"
                                                                        aria-describedby="cke_17_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="false"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(2,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(3,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(4,this);return false;"
                                                                        aria-pressed="true">
                                                                        <span
                                                                            className="cke_button_icon cke_button__source_icon"
                                                                            style={{ backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')", backgroundPosition: '0 -2184px', backgroundSize: 'auto' }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_17_label" className="cke_button_label cke_button__source_label" aria-hidden="false">Source</span>
                                                                        <span id="cke_17_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <span className="cke_toolbar_separator" role="separator">
                                                                    </span>
                                                                    <a id="cke_18"
                                                                        className="cke_button cke_button__save cke_button_off"
                                                                        href="javascript:void('Save')"
                                                                        title="Save"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_18_label"
                                                                        aria-describedby="cke_18_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="false"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(5,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(6,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(7,this);return false;">
                                                                        <span
                                                                            className="cke_button_icon cke_button__save_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -2016px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_18_label"
                                                                            className="cke_button_label cke_button__save_label"
                                                                            aria-hidden="false">
                                                                            Save
                                                                        </span>
                                                                        <span id="cke_18_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_19"
                                                                        className="cke_button cke_button__newpage cke_button_off"
                                                                        href="javascript:void('New Page')"
                                                                        title="New Page"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_19_label"
                                                                        aria-describedby="cke_19_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="false"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(8,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(9,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(10,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__newpage_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1728px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_19_label"
                                                                            className="cke_button_label cke_button__newpage_label"
                                                                            aria-hidden="false">
                                                                            New Page
                                                                        </span>
                                                                        <span
                                                                            id="cke_19_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_20"
                                                                        className="cke_button cke_button__preview cke_button_disabled"
                                                                        href="javascript:void('Preview')"
                                                                        title="Preview"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_20_label"
                                                                        aria-describedby="cke_20_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(11,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(12,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(13,this);return false;">
                                                                        <span
                                                                            className="cke_button_icon cke_button__preview_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1944px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_20_label"
                                                                            className="cke_button_label cke_button__preview_label"
                                                                            aria-hidden="false">
                                                                            Preview
                                                                        </span>
                                                                        <span
                                                                            id="cke_20_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_21"
                                                                        className="cke_button cke_button__print cke_button_disabled"
                                                                        href="javascript:void('Print')"
                                                                        title="Print"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_21_label"
                                                                        aria-describedby="cke_21_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(14,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(15,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(16,this);return false;">

                                                                        <span
                                                                            className="cke_button_icon cke_button__print_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1968px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>

                                                                        <span
                                                                            id="cke_21_label"
                                                                            className="cke_button_label cke_button__print_label"
                                                                            aria-hidden="false">
                                                                            Print
                                                                        </span>

                                                                        <span
                                                                            id="cke_21_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                        </span>
                                                                    </a>

                                                                    <span className="cke_toolbar_separator" role="separator"></span>
                                                                    <a
                                                                        id="cke_22"
                                                                        className="cke_button cke_button__templates cke_button_disabled"
                                                                        href="javascript:void('Templates')"
                                                                        title="Templates" tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_22_label"
                                                                        aria-describedby="cke_22_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(17,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(18,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(19,this);return false;">

                                                                        <span className="cke_button_icon cke_button__templates_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -2328px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_22_label"
                                                                            className="cke_button_label cke_button__templates_label"
                                                                            aria-hidden="false">
                                                                            Templates
                                                                        </span>

                                                                        <span
                                                                            id="cke_22_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                </span>
                                                                <span
                                                                    className="cke_toolbar_end">
                                                                </span>
                                                            </span>
                                                            <span
                                                                id="cke_23"
                                                                className="cke_toolbar"
                                                                aria-labelledby="cke_23_label"
                                                                role="toolbar">
                                                                <span
                                                                    id="cke_23_label"
                                                                    className="cke_voice_label">
                                                                    Clipboard/Undo
                                                                </span>
                                                                <span
                                                                    className="cke_toolbar_start">
                                                                </span>
                                                                <span
                                                                    className="cke_toolgroup"
                                                                    role="presentation">
                                                                    <a id="cke_24"
                                                                        className="cke_button cke_button__cut cke_button_disabled "
                                                                        href="javascript:void('Cut')"
                                                                        title="Cut (Ctrl+X)"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_24_label"
                                                                        aria-describedby="cke_24_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(20,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(21,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(22,this);return false;">

                                                                        <span
                                                                            className="cke_button_icon cke_button__cut_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -312px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_24_label"
                                                                            className="cke_button_label cke_button__cut_label"
                                                                            aria-hidden="false">
                                                                            Cut
                                                                        </span>
                                                                        <span
                                                                            id="cke_24_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                            Keyboard shortcut Ctrl+X
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_25"
                                                                        className="cke_button cke_button__copy cke_button_disabled "
                                                                        href="javascript:void('Copy')"
                                                                        title="Copy (Ctrl+C)"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_25_label"
                                                                        aria-describedby="cke_25_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(23,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(24,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(25,this);return false;">
                                                                        <span
                                                                            className="cke_button_icon cke_button__copy_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -264px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_25_label"
                                                                            className="cke_button_label cke_button__copy_label"
                                                                            aria-hidden="false">
                                                                            Copy
                                                                        </span>
                                                                        <span
                                                                            id="cke_25_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                            Keyboard shortcut Ctrl+C
                                                                        </span>
                                                                    </a>
                                                                    <a id="cke_26"
                                                                        className="cke_button cke_button__paste cke_button_disabled"
                                                                        href="javascript:void('Paste')"
                                                                        title="Paste (Ctrl+V)"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_26_label"
                                                                        aria-describedby="cke_26_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(26,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(27,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(28,this);return false;">
                                                                        <span
                                                                            className="cke_button_icon cke_button__paste_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -360px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_26_label"
                                                                            className="cke_button_label 
                                           cke_button__paste_label"
                                                                            aria-hidden="false">
                                                                            Paste
                                                                        </span>
                                                                        <span
                                                                            id="cke_26_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                            Keyboard shortcut Ctrl+V
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_27"
                                                                        className="cke_button cke_button__pastetext cke_button_disabled"
                                                                        href="javascript:void('Paste as plain text')"
                                                                        title="Paste as plain text (Ctrl+Shift+V)"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_27_label"
                                                                        aria-describedby="cke_27_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(29,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(30,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(31,this);return false;">
                                                                        <span
                                                                            className="cke_button_icon cke_button__pastetext_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1872px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_27_label"
                                                                            className="cke_button_label cke_button__pastetext_label"
                                                                            aria-hidden="false">
                                                                            Paste as plain text
                                                                        </span>
                                                                        <span
                                                                            id="cke_27_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                            Keyboard shortcut Ctrl+Shift+V
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_28"
                                                                        className="cke_button cke_button__pastefromword cke_button_disabled"
                                                                        href="javascript:void('Paste from Word')"
                                                                        title="Paste from Word" tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_28_label"
                                                                        aria-describedby="cke_28_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(32,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(33,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(34,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__pastefromword_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1824px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_28_label"
                                                                            className="cke_button_label cke_button__pastefromword_label"
                                                                            aria-hidden="false">
                                                                            Paste from Word
                                                                        </span>
                                                                        <span
                                                                            id="cke_28_description"
                                                                            className="cke_button_label" aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <span className="cke_toolbar_separator" role="separator"></span>
                                                                    <a
                                                                        id="cke_29"
                                                                        className="cke_button cke_button__undo cke_button_disabled "
                                                                        href="javascript:void('Undo')"
                                                                        title="Undo (Ctrl+Z)"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_29_label"
                                                                        aria-describedby="cke_29_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(35,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(36,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(37,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__undo_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -2448px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_29_label"
                                                                            className="cke_button_label cke_button__undo_label"
                                                                            aria-hidden="false">
                                                                            Undo
                                                                        </span>
                                                                        <span
                                                                            id="cke_29_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                            Keyboard shortcut Ctrl+Z
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_30"
                                                                        className="cke_button cke_button__redo cke_button_disabled "
                                                                        href="javascript:void('Redo')"
                                                                        title="Redo (Ctrl+Y)"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_30_label"
                                                                        aria-describedby="cke_30_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(38,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(39,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(40,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__redo_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -2400px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_30_label"
                                                                            className="cke_button_label cke_button__redo_label"
                                                                            aria-hidden="false">
                                                                            Redo
                                                                        </span>
                                                                        <span
                                                                            id="cke_30_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                            Keyboard shortcut Ctrl+Y
                                                                        </span>
                                                                    </a>
                                                                </span>
                                                                <span className="cke_toolbar_end"></span>
                                                            </span>
                                                            <span
                                                                id="cke_31"
                                                                className="cke_toolbar"
                                                                aria-labelledby="cke_31_label"
                                                                role="toolbar"
                                                            >
                                                                <span
                                                                    id="cke_31_label"
                                                                    className="cke_voice_label">
                                                                    Editing
                                                                </span>
                                                                <span
                                                                    className="cke_toolbar_start">
                                                                </span>
                                                                <span
                                                                    className="cke_toolgroup"
                                                                    role="presentation">
                                                                    <a id="cke_32"
                                                                        className="cke_button cke_button__find cke_button_disabled"
                                                                        href="javascript:void('Find')"
                                                                        title="Find"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_32_label"
                                                                        aria-describedby="cke_32_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(41,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(42,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(43,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__find_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -816px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_32_label"
                                                                            className="cke_button_label cke_button__find_label"
                                                                            aria-hidden="false">
                                                                            Find
                                                                        </span>
                                                                        <span
                                                                            id="cke_32_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_33"
                                                                        className="cke_button cke_button__replace cke_button_disabled"
                                                                        href="javascript:void('Replace')"
                                                                        title="Replace"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_33_label"
                                                                        aria-describedby="cke_33_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(44,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(45,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(46,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__replace_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -840px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_33_label"
                                                                            className="cke_button_label cke_button__replace_label"
                                                                            aria-hidden="false">
                                                                            Replace
                                                                        </span>
                                                                        <span
                                                                            id="cke_33_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <span
                                                                        className="cke_toolbar_separator"
                                                                        role="separator">
                                                                    </span>
                                                                    <a
                                                                        id="cke_34"
                                                                        className="cke_button cke_button__selectall cke_button_off"
                                                                        href="javascript:void('Select All')"
                                                                        title="Select All"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_34_label"
                                                                        aria-describedby="cke_34_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="false"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(47,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(48,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(49,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__selectall_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -2064px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_34_label"
                                                                            className="cke_button_label cke_button__selectall_label"
                                                                            aria-hidden="false">
                                                                            Select All
                                                                        </span>
                                                                        <span
                                                                            id="cke_34_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <span
                                                                        className="cke_toolbar_separator"
                                                                        role="separator">
                                                                    </span>
                                                                    <a
                                                                        id="cke_35"
                                                                        className="cke_button cke_button__scayt cke_button_expandable cke_button_disabled"
                                                                        href="javascript:void('Spell Checker')"
                                                                        title="Spell Checker"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_35_label"
                                                                        aria-describedby="cke_35_description"
                                                                        aria-haspopup="menu"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(50,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(51,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(52,this);return false;"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__scayt_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -2040px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_35_label"
                                                                            className="cke_button_label cke_button__scayt_label"
                                                                            aria-hidden="false">
                                                                            Spell Check As You Type
                                                                        </span>
                                                                        <span id="cke_35_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                        </span>
                                                                        <span
                                                                            className="cke_button_arrow">
                                                                        </span>
                                                                    </a>
                                                                </span>
                                                                <span
                                                                    className="cke_toolbar_end">
                                                                </span>
                                                            </span>
                                                            <span
                                                                id="cke_36"
                                                                className="cke_toolbar 
                                           cke_toolbar_last"
                                                                aria-labelledby="cke_36_label"
                                                                role="toolbar"
                                                            >
                                                                <span
                                                                    id="cke_36_label"
                                                                    className="cke_voice_label">
                                                                    Forms
                                                                </span>
                                                                <span
                                                                    className="cke_toolbar_start">
                                                                </span>
                                                                <span
                                                                    className="cke_toolgroup"
                                                                    role="presentation">
                                                                    <a
                                                                        id="cke_37"
                                                                        className="cke_button cke_button__form cke_button_disabled"
                                                                        href="javascript:void('Form')"
                                                                        title="Form"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_37_label"
                                                                        aria-describedby="cke_37_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(53,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(54,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(55,this);return false;"
                                                                    >
                                                                        <span className="cke_button_icon cke_button__form_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -936px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_37_label"
                                                                            className="cke_button_label cke_button__form_label"
                                                                            aria-hidden="false">
                                                                            Form
                                                                        </span>
                                                                        <span
                                                                            id="cke_37_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false"
                                                                        >
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_38"
                                                                        className="cke_button cke_button__checkbox cke_button_disabled"
                                                                        href="javascript:void('Checkbox')"
                                                                        title="Checkbox"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_38_label"
                                                                        aria-describedby="cke_38_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(56,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(57,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(58,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__checkbox_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -912px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_38_label"
                                                                            className="cke_button_label cke_button__checkbox_label"
                                                                            aria-hidden="false">
                                                                            Checkbox
                                                                        </span>
                                                                        <span
                                                                            id="cke_38_description"
                                                                            className="cke_button_label" aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_39"
                                                                        className="cke_button cke_button__radio cke_button_disabled"
                                                                        href="javascript:void('Radio Button')"
                                                                        title="Radio Button" tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_39_label"
                                                                        aria-describedby="cke_39_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(59,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(60,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(61,this);return false;"
                                                                    >
                                                                        <span className="cke_button_icon cke_button__radio_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1008px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_39_label"
                                                                            className="cke_button_label cke_button__radio_label"
                                                                            aria-hidden="false">
                                                                            Radio Button
                                                                        </span>
                                                                        <span
                                                                            id="cke_39_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_40"
                                                                        className="cke_button cke_button__textfield cke_button_disabled"
                                                                        href="javascript:void('Text Field')"
                                                                        title="Text Field"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_40_label"
                                                                        aria-describedby="cke_40_description"
                                                                        aria-haspopup="false" aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(62,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(63,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(64,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__textfield_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1152px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_40_label"
                                                                            className="cke_button_label cke_button__textfield_label"
                                                                            aria-hidden="false">
                                                                            Text Field
                                                                        </span>
                                                                        <span
                                                                            id="cke_40_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false"
                                                                        >
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_41"
                                                                        className="cke_button cke_button__textarea cke_button_disabled"
                                                                        href="javascript:void('Textarea')"
                                                                        title="Textarea"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_41_label"
                                                                        aria-describedby="cke_41_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(65,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(66,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(67,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__textarea_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1104px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_41_label"
                                                                            className="cke_button_label cke_button__textarea_label"
                                                                            aria-hidden="false">
                                                                            Textarea
                                                                        </span>
                                                                        <span
                                                                            id="cke_41_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_42"
                                                                        className="cke_button cke_button__select cke_button_disabled"
                                                                        href="javascript:void('Selection Field')"
                                                                        title="Selection Field"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_42_label"
                                                                        aria-describedby="cke_42_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(68,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(69,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(70,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__select_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1056px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_42_label"
                                                                            className="cke_button_label cke_button__select_label"
                                                                            aria-hidden="false">
                                                                            Selection Field
                                                                        </span>
                                                                        <span
                                                                            id="cke_42_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_43"
                                                                        className="cke_button cke_button__button cke_button_disabled"
                                                                        href="javascript:void('Button')"
                                                                        title="Button"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_43_label"
                                                                        aria-describedby="cke_43_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(71,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(72,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(73,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__button_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -888px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_43_label"
                                                                            className="cke_button_label cke_button__button_label"
                                                                            aria-hidden="false">
                                                                            Button
                                                                        </span>
                                                                        <span
                                                                            id="cke_43_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_44"
                                                                        className="cke_button cke_button__imagebutton cke_button_disabled"
                                                                        href="javascript:void('Image Button')"
                                                                        title="Image Button" tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_44_label"
                                                                        aria-describedby="cke_44_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(74,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(75,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(76,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__imagebutton_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -984px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_44_label"
                                                                            className="cke_button_label cke_button__imagebutton_label"
                                                                            aria-hidden="false">
                                                                            Image Button
                                                                        </span>
                                                                        <span
                                                                            id="cke_44_description"
                                                                            className="cke_button_label" aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_45"
                                                                        className="cke_button cke_button__hiddenfield cke_button_disabled"
                                                                        href="javascript:void('Hidden Field')"
                                                                        title="Hidden Field"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_45_label"
                                                                        aria-describedby="cke_45_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(77,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(78,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(79,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__hiddenfield_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -960px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_45_label"
                                                                            className="cke_button_label cke_button__hiddenfield_label"
                                                                            aria-hidden="false">
                                                                            Hidden Field
                                                                        </span>
                                                                        <span
                                                                            id="cke_45_description"
                                                                            className="cke_button_label" aria-hidden="false"
                                                                        >
                                                                        </span>
                                                                    </a>
                                                                </span>
                                                                <span
                                                                    className="cke_toolbar_end">
                                                                </span>
                                                            </span>
                                                            <span
                                                                className="cke_toolbar_break">
                                                            </span>
                                                            <span
                                                                id="cke_46"
                                                                className="cke_toolbar"
                                                                aria-labelledby="cke_46_label"
                                                                role="toolbar">
                                                                <span
                                                                    id="cke_46_label"
                                                                    className="cke_voice_label">
                                                                    Basic Styles
                                                                </span>
                                                                <span
                                                                    className="cke_toolbar_start">
                                                                </span>
                                                                <span
                                                                    className="cke_toolgroup"
                                                                    role="presentation">
                                                                    <a
                                                                        id="cke_47"
                                                                        className="cke_button cke_button__bold cke_button_disabled"
                                                                        href="javascript:void('Bold')"
                                                                        title="Bold (Ctrl+B)"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_47_label"
                                                                        aria-describedby="cke_47_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(80,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(81,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(82,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__bold_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -24px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_47_label"
                                                                            className="cke_button_label cke_button__bold_label"
                                                                            aria-hidden="false">
                                                                            Bold
                                                                        </span>
                                                                        <span
                                                                            id="cke_47_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                            Keyboard shortcut Ctrl+B
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_48"
                                                                        className="cke_button cke_button__italic cke_button_disabled"
                                                                        href="javascript:void('Italic')"
                                                                        title="Italic (Ctrl+I)"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_48_label"
                                                                        aria-describedby="cke_48_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(83,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(84,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(85,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__italic_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -48px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_48_label"
                                                                            className="cke_button_label cke_button__italic_label"
                                                                            aria-hidden="false">
                                                                            Italic
                                                                        </span>
                                                                        <span
                                                                            id="cke_48_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                            Keyboard shortcut Ctrl+I
                                                                        </span>
                                                                    </a>
                                                                    <a id="cke_49"
                                                                        className="cke_button cke_button__underline cke_button_disabled"
                                                                        href="javascript:void('Underline')"
                                                                        title="Underline (Ctrl+U)"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_49_label"
                                                                        aria-describedby="cke_49_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(86,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(87,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(88,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__underline_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -144px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_49_label"
                                                                            className="cke_button_label cke_button__underline_label"
                                                                            aria-hidden="false">
                                                                            Underline
                                                                        </span>
                                                                        <span
                                                                            id="cke_49_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                            Keyboard shortcut Ctrl+U
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_50"
                                                                        className="cke_button cke_button__strike cke_button_disabled"
                                                                        href="javascript:void('Strikethrough')"
                                                                        title="Strikethrough"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_50_label"
                                                                        aria-describedby="cke_50_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(89,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(90,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(91,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__strike_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -72px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_50_label"
                                                                            className="cke_button_label cke_button__strike_label"
                                                                            aria-hidden="false">
                                                                            Strikethrough
                                                                        </span>
                                                                        <span
                                                                            id="cke_50_description"
                                                                            className="cke_button_label" aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_51"
                                                                        class="cke_button cke_button__subscript cke_button_disabled"
                                                                        href="javascript:void('Subscript')"
                                                                        title="Subscript"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_51_label"
                                                                        aria-describedby="cke_51_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(92,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(93,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(94,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__subscript_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -96px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_51_label"
                                                                            className="cke_button_label cke_button__subscript_label"
                                                                            aria-hidden="false">
                                                                            Subscript
                                                                        </span>
                                                                        <span
                                                                            id="cke_51_description"
                                                                            className="cke_button_label" aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_52"
                                                                        className="cke_button cke_button__superscript cke_button_disabled"
                                                                        href="javascript:void('Superscript')"
                                                                        title="Superscript"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_52_label"
                                                                        aria-describedby="cke_52_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(95,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(96,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(97,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__superscript_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -120px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_52_label"
                                                                            className="cke_button_label cke_button__superscript_label"
                                                                            aria-hidden="false">
                                                                            Superscript
                                                                        </span>
                                                                        <span
                                                                            id="cke_52_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false"
                                                                        >
                                                                        </span>
                                                                    </a>
                                                                    <span
                                                                        className="cke_toolbar_separator"
                                                                        role="separator">
                                                                    </span>
                                                                    <a
                                                                        id="cke_53"
                                                                        className="cke_button cke_button__copyformatting cke_button_disabled"
                                                                        href="javascript:void('Copy Formatting')"
                                                                        title="Copy Formatting (Ctrl+Shift+C)"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_53_label"
                                                                        aria-describedby="cke_53_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(98,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(99,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(100,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__copyformatting_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -456px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_53_label"
                                                                            className="cke_button_label cke_button__copyformatting_label"
                                                                            aria-hidden="false">
                                                                            Copy Formatting
                                                                        </span>
                                                                        <span
                                                                            id="cke_53_description"
                                                                            className="cke_button_label"
                                                                            aria-hidden="false">
                                                                            Keyboard shortcut Ctrl+Shift+C
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_54"
                                                                        className="cke_button cke_button__removeformat cke_button_disabled"
                                                                        href="javascript:void('Remove Format')"
                                                                        title="Remove Format"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_54_label"
                                                                        aria-describedby="cke_54_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(101,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(102,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(103,this);return false;"
                                                                    >
                                                                        <span
                                                                            class="cke_button_icon cke_button__removeformat_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1992px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_54_label"
                                                                            className="cke_button_label cke_button__removeformat_label"
                                                                            aria-hidden="false"
                                                                        >
                                                                            Remove Format
                                                                        </span>
                                                                        <span id="cke_54_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                </span>
                                                                <span className="cke_toolbar_end"></span>
                                                            </span>
                                                            <span
                                                                id="cke_55"
                                                                className="cke_toolbar"
                                                                aria-labelledby="cke_55_label"
                                                                role="toolbar"
                                                            >
                                                                <span id="cke_55_label" className="cke_voice_label">Paragraph</span>
                                                                <span className="cke_toolbar_start"></span>
                                                                <span className="cke_toolgroup" role="presentation">
                                                                    <a
                                                                        id="cke_56"
                                                                        className="cke_button cke_button__numberedlist cke_button_disabled"
                                                                        href="javascript:void('Insert/Remove Numbered List')"
                                                                        title="Insert/Remove Numbered List"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_56_label"
                                                                        aria-describedby="cke_56_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(104,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(105,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(106,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__numberedlist_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1632px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_56_label"
                                                                            className="cke_button_label cke_button__numberedlist_label"
                                                                            aria-hidden="false">
                                                                            Insert/Remove Numbered List
                                                                        </span>
                                                                        <span id="cke_56_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_57"
                                                                        className="cke_button cke_button__bulletedlist cke_button_disabled"
                                                                        href="javascript:void('Insert/Remove Bulleted List')"
                                                                        title="Insert/Remove Bulleted List"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_57_label"
                                                                        aria-describedby="cke_57_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(107,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(108,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(109,this);
                                            return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__bulletedlist_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1584px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_57_label" className="cke_button_label cke_button__bulletedlist_label" aria-hidden="false">
                                                                            Insert/Remove Bulleted List
                                                                        </span>

                                                                        <span id="cke_57_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <span className="cke_toolbar_separator" role="separator"></span>
                                                                    <a
                                                                        id="cke_58"
                                                                        className="cke_button cke_button__outdent cke_button_disabled "
                                                                        href="javascript:void('Decrease Indent')"
                                                                        title="Decrease Indent"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_58_label"
                                                                        aria-describedby="cke_58_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(110,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(111,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(112,this);return false;"
                                                                    >
                                                                        <span className="cke_button_icon cke_button__outdent_icon"
                                                                            style={{ backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')", backgroundPosition: '0 -1320px', backgroundSize: 'auto' }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_58_label" className="cke_button_label cke_button__outdent_label" aria-hidden="false">Decrease Indent</span>
                                                                        <span id="cke_58_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_59"
                                                                        className="cke_button cke_button__indent cke_button_disabled"
                                                                        href="javascript:void('Increase Indent')"
                                                                        title="Increase Indent"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_59_label"
                                                                        aria-describedby="cke_59_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(113,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(114,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(115,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__indent_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1272px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_59_label"
                                                                            className="cke_button_label cke_button__indent_label"
                                                                            aria-hidden="false"
                                                                        >
                                                                            Increase Indent
                                                                        </span>
                                                                        <span id="cke_59_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <span className="cke_toolbar_separator" role="separator"></span>
                                                                    <a
                                                                        id="cke_60"
                                                                        className="cke_button cke_button__blockquote cke_button_disabled"
                                                                        href="javascript:void('Block Quote')"
                                                                        title="Block Quote"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_60_label"
                                                                        aria-describedby="cke_60_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(116,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(117,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(118,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__blockquote_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -216px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_60_label" className="cke_button_label cke_button__blockquote_label" aria-hidden="false">
                                                                            Block Quote
                                                                        </span>
                                                                        <span id="cke_60_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_61"
                                                                        className="cke_button cke_button__creatediv cke_button_disabled"
                                                                        href="javascript:void('Create Div Container')"
                                                                        title="Create Div Container"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_61_label"
                                                                        aria-describedby="cke_61_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(119,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(120,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(121,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__creatediv_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -480px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_61_label" className="cke_button_label cke_button__creatediv_label" aria-hidden="false">
                                                                            Create Div Container
                                                                        </span>
                                                                        <span id="cke_61_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <span className="cke_toolbar_separator" role="separator"></span>
                                                                    <a
                                                                        id="cke_62"
                                                                        className="cke_button cke_button__justifyleft cke_button_disabled"
                                                                        href="javascript:void('Align Left')"
                                                                        title="Align Left"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_62_label"
                                                                        aria-describedby="cke_62_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(122,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(123,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(124,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__justifyleft_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1392px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_62_label" className="cke_button_label cke_button__justifyleft_label" aria-hidden="false">
                                                                            Align Left
                                                                        </span>
                                                                        <span id="cke_62_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_63"
                                                                        className="cke_button cke_button__justifycenter cke_button_disabled"
                                                                        href="javascript:void('Center')"
                                                                        title="Center"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_63_label"
                                                                        aria-describedby="cke_63_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(125,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(126,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(127,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__justifycenter_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1368px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_63_label" className="cke_button_label cke_button__justifycenter_label" aria-hidden="false">
                                                                            Center
                                                                        </span>
                                                                        <span id="cke_63_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_64"
                                                                        className="cke_button cke_button__justifyright cke_button_disabled"
                                                                        href="javascript:void('Align Right')"
                                                                        title="Align Right" tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_64_label"
                                                                        aria-describedby="cke_64_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(128,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(129,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(130,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__justifyright_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1416px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_64_label" className="cke_button_label cke_button__justifyright_label" aria-hidden="false">
                                                                            Align Right
                                                                        </span>
                                                                        <span id="cke_64_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_65"
                                                                        className="cke_button cke_button__justifyblock cke_button_disabled"
                                                                        href="javascript:void('Justify')"
                                                                        title="Justify"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_65_label"
                                                                        aria-describedby="cke_65_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(131,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(132,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(133,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__justifyblock_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1344px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_65_label" className="cke_button_label cke_button__justifyblock_label" aria-hidden="false">
                                                                            Justify
                                                                        </span>
                                                                        <span id="cke_65_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <span className="cke_toolbar_separator" role="separator"></span>
                                                                    <a
                                                                        id="cke_66"
                                                                        className="cke_button cke_button__bidiltr cke_button_disabled"
                                                                        href="javascript:void('Text direction from left to right')"
                                                                        title="Text direction from left to right"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_66_label"
                                                                        aria-describedby="cke_66_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(134,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(135,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(136,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__bidiltr_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -168px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_66_label"
                                                                            className="cke_button_label cke_button__bidiltr_label"
                                                                            aria-hidden="false">
                                                                            Text direction from left to right
                                                                        </span>
                                                                        <span id="cke_66_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_67"
                                                                        className="cke_button cke_button__bidirtl cke_button_disabled"
                                                                        href="javascript:void('Text direction from right to left')"
                                                                        title="Text direction from right to left"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_67_label"
                                                                        aria-describedby="cke_67_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(137,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(138,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(139,this);return false;"
                                                                    >
                                                                        <span
                                                                            class="cke_button_icon cke_button__bidirtl_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -192px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_67_label" className="cke_button_label cke_button__bidirtl_label" aria-hidden="false">
                                                                            Text direction from right to left
                                                                        </span>
                                                                        <span id="cke_67_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_68"
                                                                        className="cke_button cke_button__language cke_button_expandable cke_button_disabled"
                                                                        href="javascript:void('Set language')"
                                                                        title="Set language"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_68_label"
                                                                        aria-describedby="cke_68_description"
                                                                        aria-haspopup="menu"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(140,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(141,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(142,this);return false;"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__language_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1440px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span
                                                                            id="cke_68_label"
                                                                            className="cke_button_label cke_button__language_label"
                                                                            aria-hidden="false">
                                                                            Set language
                                                                        </span>
                                                                        <span id="cke_68_description" className="cke_button_label" aria-hidden="false"></span>
                                                                        <span className="cke_button_arrow"></span>
                                                                    </a>
                                                                </span>
                                                                <span className="cke_toolbar_end"></span>
                                                            </span>
                                                            <span id="cke_69" className="cke_toolbar" aria-labelledby="cke_69_label" role="toolbar">
                                                                <span id="cke_69_label" className="cke_voice_label">Links</span>
                                                                <span className="cke_toolbar_start"></span>
                                                                <span className="cke_toolgroup" role="presentation">
                                                                    <a
                                                                        id="cke_70"
                                                                        className="cke_button cke_button__link cke_button_disabled"
                                                                        href="javascript:void('Link')"
                                                                        title="Link (Ctrl+K)"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_70_label"
                                                                        aria-describedby="cke_70_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(143,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(144,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(145,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__link_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1512px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_70_label" className="cke_button_label cke_button__link_label" aria-hidden="false">Link</span>
                                                                        <span id="cke_70_description" className="cke_button_label" aria-hidden="false">Keyboard shortcut Ctrl+K</span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_71"
                                                                        className="cke_button cke_button__unlink cke_button_disabled "
                                                                        href="javascript:void('Unlink')"
                                                                        title="Unlink"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_71_label"
                                                                        aria-describedby="cke_71_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(146,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(147,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(148,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__unlink_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1536px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_71_label" className="cke_button_label cke_button__unlink_label" aria-hidden="false">Unlink</span>
                                                                        <span id="cke_71_description" className="cke_button_label" aria-hidden="false">
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_72"
                                                                        className="cke_button cke_button__anchor cke_button_disabled"
                                                                        href="javascript:void('Anchor')"
                                                                        title="Anchor"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_72_label"
                                                                        aria-describedby="cke_72_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(149,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(150,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(151,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__anchor_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1488px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_72_label" className="cke_button_label cke_button__anchor_label" aria-hidden="false">Anchor</span>
                                                                        <span id="cke_72_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                </span>
                                                                <span className="cke_toolbar_end"></span>
                                                            </span>
                                                            <span id="cke_73" className="cke_toolbar cke_toolbar_last" aria-labelledby="cke_73_label" role="toolbar">
                                                                <span id="cke_73_label" className="cke_voice_label">Insert</span>
                                                                <span className="cke_toolbar_start"></span>
                                                                <span className="cke_toolgroup" role="presentation">
                                                                    <a
                                                                        id="cke_74"
                                                                        className="cke_button cke_button__image cke_button_disabled"
                                                                        href="javascript:void('Image')"
                                                                        title="Image"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_74_label"
                                                                        aria-describedby="cke_74_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(152,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(153,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(154,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__image_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1224px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_74_label" className="cke_button_label cke_button__image_label" aria-hidden="false">Image</span>
                                                                        <span id="cke_74_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_75"
                                                                        className="cke_button cke_button__flash cke_button_disabled"
                                                                        href="javascript:void('Flash')"
                                                                        title="Flash"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_75_label"
                                                                        aria-describedby="cke_75_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(155,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(156,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(157,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__flash_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -864px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_75_label" className="cke_button_label cke_button__flash_label" aria-hidden="false">Flash</span>
                                                                        <span id="cke_75_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_76"
                                                                        className="cke_button cke_button__table cke_button_disabled"
                                                                        href="javascript:void('Table')"
                                                                        title="Table"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_76_label"
                                                                        aria-describedby="cke_76_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(158,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(159,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(160,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__table_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -2280px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_76_label" className="cke_button_label cke_button__table_label" aria-hidden="false">Table</span>
                                                                        <span id="cke_76_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a
                                                                        id="cke_77"
                                                                        className="cke_button cke_button__horizontalrule cke_button_disabled"
                                                                        href="javascript:void('Insert Horizontal Line')"
                                                                        title="Insert Horizontal Line"
                                                                        tabindex="-1"
                                                                        hidefocus="true"
                                                                        role="button"
                                                                        aria-labelledby="cke_77_label"
                                                                        aria-describedby="cke_77_description"
                                                                        aria-haspopup="false"
                                                                        aria-disabled="true"
                                                                        onkeydown="return CKEDITOR.tools.callFunction(161,event);"
                                                                        onfocus="return CKEDITOR.tools.callFunction(162,event);"
                                                                        onClick="CKEDITOR.tools.callFunction(163,this);return false;"
                                                                    >
                                                                        <span
                                                                            className="cke_button_icon cke_button__horizontalrule_icon"
                                                                            style={{
                                                                                backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')",
                                                                                backgroundPosition: '0 -1176px',
                                                                                backgroundSize: 'auto'
                                                                            }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_77_label" className="cke_button_label cke_button__horizontalrule_label" aria-hidden="false">Insert Horizontal Line</span>
                                                                        <span id="cke_77_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a id="cke_78" className="cke_button cke_button__smiley cke_button_disabled" href="javascript:void('Smiley')" title="Smiley" tabindex="-1" hidefocus="true" role="button" aria-labelledby="cke_78_label" aria-describedby="cke_78_description" aria-haspopup="false" aria-disabled="true" onkeydown="return CKEDITOR.tools.callFunction(164,event);" onfocus="return CKEDITOR.tools.callFunction(165,event);" onClick="CKEDITOR.tools.callFunction(166,this);return false;">
                                                                        <span className="cke_button_icon cke_button__smiley_icon" style={{ backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')", backgroundPosition: '0 -2136px', backgroundSize: 'auto' }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_78_label" className="cke_button_label cke_button__smiley_label" aria-hidden="false">Smiley</span>
                                                                        <span id="cke_78_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a id="cke_79" className="cke_button cke_button__specialchar cke_button_disabled" href="javascript:void('Insert Special Character')" title="Insert Special Character" tabindex="-1" hidefocus="true" role="button" aria-labelledby="cke_79_label" aria-describedby="cke_79_description" aria-haspopup="false" aria-disabled="true" onkeydown="return CKEDITOR.tools.callFunction(167,event);" onfocus="return CKEDITOR.tools.callFunction(168,event);" onClick="CKEDITOR.tools.callFunction(169,this);return false;">
                                                                        <span className="cke_button_icon cke_button__specialchar_icon" style={{ backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')", backgroundPosition: '0 -2256px', backgroundSize: 'auto' }}>&nbsp;</span>
                                                                        <span id="cke_79_label" className="cke_button_label cke_button__specialchar_label" aria-hidden="false">Insert Special Character</span>
                                                                        <span id="cke_79_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a id="cke_80" className="cke_button cke_button__pagebreak cke_button_disabled" href="javascript:void('Insert Page Break for Printing')" title="Insert Page Break for Printing" tabindex="-1" hidefocus="true" role="button" aria-labelledby="cke_80_label" aria-describedby="cke_80_description" aria-haspopup="false" aria-disabled="true" onkeydown="return CKEDITOR.tools.callFunction(170,event);" onfocus="return CKEDITOR.tools.callFunction(171,event);" onClick="CKEDITOR.tools.callFunction(172,this);return false;">
                                                                        <span className="cke_button_icon cke_button__pagebreak_icon" style={{ backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')", backgroundPosition: '0 -1776px', backgroundSize: 'auto' }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_80_label" className="cke_button_label cke_button__pagebreak_label" aria-hidden="false">Insert Page Break for Printing</span>
                                                                        <span id="cke_80_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a id="cke_81" className="cke_button cke_button__iframe cke_button_disabled" href="javascript:void('IFrame')" title="IFrame" tabindex="-1" hidefocus="true" role="button" aria-labelledby="cke_81_label" aria-describedby="cke_81_description" aria-haspopup="false" aria-disabled="true" onkeydown="return CKEDITOR.tools.callFunction(173,event);" onfocus="return CKEDITOR.tools.callFunction(174,event);" onClick="CKEDITOR.tools.callFunction(175,this);return false;">
                                                                        <span className="cke_button_icon cke_button__iframe_icon" style={{ backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')", backgroundPosition: '0 -1200px', backgroundSize: 'auto' }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_81_label" className="cke_button_label cke_button__iframe_label" aria-hidden="false">IFrame</span>
                                                                        <span id="cke_81_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                </span>
                                                                <span className="cke_toolbar_end"></span>
                                                            </span>
                                                            <span className="cke_toolbar_break"></span>
                                                            <span id="cke_82" className="cke_toolbar" aria-labelledby="cke_82_label" role="toolbar">
                                                                <span id="cke_82_label" className="cke_voice_label">Styles</span>
                                                                <span className="cke_toolbar_start"></span>
                                                                <span id="cke_12" className="cke_combo cke_combo__styles cke_combo_disabled" role="presentation" aria-disabled="true">
                                                                    <span id="cke_12_label" class="cke_combo_label">Styles</span>
                                                                    <a className="cke_combo_button" title="Formatting Styles" tabindex="-1" href="javascript:void('Formatting Styles')" hidefocus="true" role="button" aria-labelledby="cke_12_label" aria-haspopup="listbox" onkeydown="return CKEDITOR.tools.callFunction(177,event,this);" onfocus="return CKEDITOR.tools.callFunction(178,event);" onClick="CKEDITOR.tools.callFunction(176,this);return false;">
                                                                        <span id="cke_12_text" className="cke_combo_text cke_combo_inlinelabel">Styles</span>
                                                                        <span className="cke_combo_open">
                                                                            <span className="cke_combo_arrow">
                                                                            </span>
                                                                        </span>
                                                                    </a>
                                                                </span>
                                                                <span id="cke_13" className="cke_combo cke_combo__format cke_combo_disabled" role="presentation" aria-disabled="true">
                                                                    <span id="cke_13_label" className="cke_combo_label">Format</span>
                                                                    <a className="cke_combo_button" title="Paragraph Format" tabindex="-1" href="javascript:void('Paragraph Format')" hidefocus="true" role="button" aria-labelledby="cke_13_label" aria-haspopup="listbox" onkeydown="return CKEDITOR.tools.callFunction(180,event,this);" onfocus="return CKEDITOR.tools.callFunction(181,event);" onClick="CKEDITOR.tools.callFunction(179,this);return false;">
                                                                        <span id="cke_13_text" className="cke_combo_text cke_combo_inlinelabel">Format</span>
                                                                        <span className="cke_combo_open">
                                                                            <span className="cke_combo_arrow"></span>
                                                                        </span>
                                                                    </a>
                                                                </span>
                                                                <span id="cke_14" className="cke_combo cke_combo__font cke_combo_disabled" role="presentation" aria-disabled="true">
                                                                    <span id="cke_14_label" className="cke_combo_label">Font</span>
                                                                    <a className="cke_combo_button" title="Font Name" tabindex="-1" href="javascript:void('Font Name')" hidefocus="true" role="button" aria-labelledby="cke_14_label" aria-haspopup="listbox" onkeydown="return CKEDITOR.tools.callFunction(183,event,this);" onfocus="return CKEDITOR.tools.callFunction(184,event);" onClick="CKEDITOR.tools.callFunction(182,this);return false;">
                                                                        <span id="cke_14_text" className="cke_combo_text cke_combo_inlinelabel">Font</span>
                                                                        <span className="cke_combo_open">
                                                                            <span className="cke_combo_arrow"></span>
                                                                        </span>
                                                                    </a>
                                                                </span>
                                                                <span id="cke_15" className="cke_combo cke_combo__fontsize cke_combo_disabled" role="presentation" aria-disabled="true">
                                                                    <span id="cke_15_label" className="cke_combo_label">Size</span>
                                                                    <a className="cke_combo_button" title="Font Size" tabindex="-1" href="javascript:void('Font Size')" hidefocus="true" role="button" aria-labelledby="cke_15_label" aria-haspopup="listbox" onkeydown="return CKEDITOR.tools.callFunction(186,event,this);" onfocus="return CKEDITOR.tools.callFunction(187,event);" onClick="CKEDITOR.tools.callFunction(185,this);return false;">
                                                                        <span id="cke_15_text" className="cke_combo_text cke_combo_inlinelabel">Size</span>
                                                                        <span className="cke_combo_open">
                                                                            <span className="cke_combo_arrow"></span>
                                                                        </span>
                                                                    </a>
                                                                </span>
                                                                <span className="cke_toolbar_end"></span>
                                                            </span>
                                                            <span id="cke_83" className="cke_toolbar" aria-labelledby="cke_83_label" role="toolbar">
                                                                <span id="cke_83_label" className="cke_voice_label">Colors</span>
                                                                <span className="cke_toolbar_start">
                                                                </span>
                                                                <span className="cke_toolgroup" role="presentation">
                                                                    <a id="cke_84" className="cke_button cke_button__textcolor cke_button_expandable cke_button_disabled" href="javascript:void('Text Color')" title="Text Color" tabindex="-1" hidefocus="true" role="button" aria-labelledby="cke_84_label" aria-describedby="cke_84_description" aria-haspopup="listbox" aria-disabled="true" onkeydown="return CKEDITOR.tools.callFunction(188,event);" onfocus="return CKEDITOR.tools.callFunction(189,event);" onClick="CKEDITOR.tools.callFunction(190,this);return false;" aria-expanded="false">
                                                                        <span className="cke_button_icon cke_button__textcolor_icon" style={{ backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')", backgroundPosition: '0 -432px', backgroundSize: 'auto' }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_84_label" className="cke_button_label cke_button__textcolor_label" aria-hidden="false">Text Color</span>
                                                                        <span id="cke_84_description" className="cke_button_label" aria-hidden="false"></span>
                                                                        <span className="cke_button_arrow"></span>
                                                                    </a>
                                                                    <a id="cke_85" className="cke_button cke_button__bgcolor cke_button_expandable cke_button_disabled" href="javascript:void('Background Color')" title="Background Color" tabindex="-1" hidefocus="true" role="button" aria-labelledby="cke_85_label" aria-describedby="cke_85_description" aria-haspopup="listbox" aria-disabled="true" onkeydown="return CKEDITOR.tools.callFunction(191,event);" onfocus="return CKEDITOR.tools.callFunction(192,event);" onClick="CKEDITOR.tools.callFunction(193,this);return false;" aria-expanded="false">
                                                                        <span className="cke_button_icon cke_button__bgcolor_icon" style={{ backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')", backgroundPosition: '0 -408px', backgroundSize: 'auto' }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_85_label" className="cke_button_label cke_button__bgcolor_label" aria-hidden="false">Background Color</span>
                                                                        <span id="cke_85_description" className="cke_button_label" aria-hidden="false"></span>
                                                                        <span className="cke_button_arrow"></span>
                                                                    </a>
                                                                </span>
                                                                <span className="cke_toolbar_end"></span>
                                                            </span>
                                                            <span id="cke_86" className="cke_toolbar" aria-labelledby="cke_86_label" role="toolbar">
                                                                <span id="cke_86_label" className="cke_voice_label">Tools</span>
                                                                <span className="cke_toolbar_start"></span>
                                                                <span className="cke_toolgroup" role="presentation">
                                                                    <a id="cke_87" className="cke_button cke_button__maximize cke_button_off" href="javascript:void('Maximize')" title="Maximize" tabindex="-1" hidefocus="true" role="button" aria-labelledby="cke_87_label" aria-describedby="cke_87_description" aria-haspopup="false" aria-disabled="false" onkeydown="return CKEDITOR.tools.callFunction(194,event);" onfocus="return CKEDITOR.tools.callFunction(195,event);" onClick="CKEDITOR.tools.callFunction(196,this);return false;">
                                                                        <span className="cke_button_icon cke_button__maximize_icon" style={{ backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')", backgroundPosition: '0 -1680px', backgroundSize: 'auto' }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_87_label" className="cke_button_label cke_button__maximize_label" aria-hidden="false">Maximize</span>
                                                                        <span id="cke_87_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                    <a id="cke_88" className="cke_button cke_button__showblocks cke_button_disabled" href="javascript:void('Show Blocks')" title="Show Blocks" tabindex="-1" hidefocus="true" role="button" aria-labelledby="cke_88_label" aria-describedby="cke_88_description" aria-haspopup="false" aria-disabled="true" onkeydown="return CKEDITOR.tools.callFunction(197,event);" onfocus="return CKEDITOR.tools.callFunction(198,event);" onClick="CKEDITOR.tools.callFunction(199,this);return false;">
                                                                        <span className="cke_button_icon cke_button__showblocks_icon" style={{ backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')", backgroundPosition: '0 -2112px', backgroundSize: 'auto' }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_88_label" className="cke_button_label cke_button__showblocks_label" aria-hidden="false">Show Blocks</span>
                                                                        <span id="cke_88_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                </span>
                                                                <span className="cke_toolbar_end"></span>
                                                            </span>
                                                            <span id="cke_89" className="cke_toolbar cke_toolbar_last" aria-labelledby="cke_89_label" role="toolbar">
                                                                <span id="cke_89_label" className="cke_voice_label">about</span>
                                                                <span className="cke_toolbar_start"></span>
                                                                <span className="cke_toolgroup" role="presentation">
                                                                    <a id="cke_90" className="cke_button cke_button__about cke_button_off" href="javascript:void('About CKEditor 4')" title="About CKEditor 4" tabindex="-1" hidefocus="true" role="button" aria-labelledby="cke_90_label" aria-describedby="cke_90_description" aria-haspopup="false" aria-disabled="false" onkeydown="return CKEDITOR.tools.callFunction(200,event);" onfocus="return CKEDITOR.tools.callFunction(201,event);" onClick="CKEDITOR.tools.callFunction(202,this);return false;">
                                                                        <span className="cke_button_icon cke_button__about_icon" style={{ backgroundImage: "url('https://cdn.ckeditor.com/4.14.0/full-all/plugins/icons.png?t=K24B')", backgroundPosition: '0 0px', backgroundSize: 'auto' }}>&nbsp;
                                                                        </span>
                                                                        <span id="cke_90_label" className="cke_button_label cke_button__about_label" aria-hidden="false">About CKEditor 4</span>
                                                                        <span id="cke_90_description" className="cke_button_label" aria-hidden="false"></span>
                                                                    </a>
                                                                </span>
                                                                <span className="cke_toolbar_end"></span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <div id="cke_1_contents" className="cke_contents cke_reset" role="presentation" style={{ height: '200px' }}>
                                                        <textarea dir="ltr" className="cke_source cke_reset cke_enable_context_menu cke_editable cke_editable_themed cke_contents_ltr" tabindex="0" style={{ width: '100%', height: '100%', resize: 'none', outline: 'none', textAlign: 'left', tabSize: '4' }} role="textbox" aria-multiline="true" aria-label="Rich Text Editor, message" title="Rich Text Editor, message" aria-describedby="cke_118">
                                                        </textarea>
                                                        <span id="cke_118" className="cke_voice_label">Press ALT 0 for help</span>
                                                    </div>
                                                    <span id="cke_1_bottom" className="cke_bottom cke_reset_all" role="presentation" style={{ userSelect: 'none' }}>
                                                        <span id="cke_1_resizer" className="cke_resizer cke_resizer_vertical cke_resizer_ltr" title="Resize" onmousedown="CKEDITOR.tools.callFunction(1, event)">◢</span>
                                                        <span id="cke_1_path_label" className="cke_voice_label">Elements path</span>
                                                        <span id="cke_1_path" className="cke_path" role="group" aria-labelledby="cke_1_path_label">
                                                            <span className="cke_path_empty">&nbsp;</span
                                                            ></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="col-12" style={{ marginTop: '10px' }}>
                                            <textarea
                                                name="message"
                                                rows="5"
                                                placeholder="Enter Your Message"
                                                value="" class="form-control"
                                                style={{ visibility: 'hidden', display: 'none' }}
                                            >
                                            </textarea>
                                        </div>

                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </section>

            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

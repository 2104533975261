import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import DatePicker from "react-datepicker";
import RestService from '../http';
import formatDate from './utilityComponents/FormatDate';
import { ToastContainer } from 'react-toastify';
import showToast from './utilityComponents/APPToast';
import Loader from './Loader';
import { Link } from 'react-router-dom';


export default function Adminprofitreport() {

    const rest = new RestService();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [adminProfit, setAdminProfit] = useState({});
    const [loading, setLoading] = useState(false);


    const handleAdminProfit = () => {

        var raw = {
            "startDate": formatDate(startDate),
            "endDate": formatDate(endDate)
        }

        setLoading(true);

        rest.post("report/fetchAdminProfit", raw).then(result => {
            setLoading(false);

            console.log(result);

            if (result.status === rest.SUCCESS) {
                const profitData = result.data;
                showToast("SUCCESS", result.message)
                setAdminProfit(profitData);

            } else {
                showToast("DANGER", result.message);
                setAdminProfit(undefined);

            }

        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        handleAdminProfit();
    }, [])

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Admin Profit Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Report</Link></li>
                                    <li className="breadcrumb-item active">Admin Profit</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div action="#" method="post">

                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                <label>From Date</label><br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    dropdownMode="select"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={startDate}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setStartDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                <label>To Date</label>
                                                <br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    dropdownMode="select"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={endDate}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setEndDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    style={{ marginTop: "11%" }}
                                                    onClick={handleAdminProfit}
                                                >
                                                    <i className="fa fa-search"></i> Search
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className='card' style={{ padding: "20px" }}>
                                            <h3 className='text-center' style={{ fontWeight: "bold", color: "#ff5c00", fontStyle: "italic" }}>TOTAL EARNING</h3>
                                            <p className='text-center' style={{ fontWeight: "800", alignItems: "center", color:"darkgreen" }}>
                                                {adminProfit === undefined ? 0 : adminProfit.totalEarning}
                                            </p>
                                        </div>
                                    </div>

                                    <div className='col-md-3'>
                                        <div className='card' style={{ padding: "20px" }}>
                                            <h3 className='text-center' style={{ fontWeight: "bold", color: "#ff5c00", fontStyle: "italic", fontSize: "22px" }}>TOTAL DISTRIBUTED</h3>
                                            <p className='text-center' style={{ fontWeight: "800", alignItems: "center", color:"darkgreen" }}>
                                                {adminProfit === undefined ? 0 : adminProfit.totalDistributed}
                                            </p>
                                        </div>
                                    </div>

                                    <div className='col-md-3'>
                                        <div className='card' style={{ padding: "20px" }}>
                                            <h3 className='text-center' style={{ fontWeight: "bold", color: "#ff5c00", fontStyle: "italic" }}>TOTAL EXPENSE</h3>
                                            <p className='text-center' style={{ fontWeight: "800", alignItems: "center", color:"darkgreen" }}>
                                                {adminProfit === undefined ? 0 : adminProfit.totalExpanse}
                                            </p>
                                        </div>
                                    </div>

                                    <div className='col-md-3'>
                                        <div className='card' style={{ padding: "20px" }}>
                                            <h3 className='text-center' style={{ fontWeight: "bold", color: "#ff5c00", fontStyle: "italic" }}>TOTAL PROFIT</h3>
                                            <p className='text-center' style={{ fontWeight: "800", alignItems: "center", color:"darkgreen" }}>
                                                {adminProfit === undefined ? 0 : adminProfit.totalProfit}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React, { useEffect } from 'react';
import { CiBellOn } from 'react-icons/ci';
import { AiOutlineFolderOpen } from 'react-icons/ai';
import { MdLogout } from 'react-icons/md';
import { FaUserAlt, FaWeixin, FaTimes, FaCheckCircle } from 'react-icons/fa';
import { BiUserCircle, BiHomeAlt, BiChevronDown } from 'react-icons/bi';
import { HiOutlineChat } from 'react-icons/hi';
import { RiShoppingBasketLine } from 'react-icons/ri';
import { BsGrid, BsFingerprint } from 'react-icons/bs';
import { useState, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logout from '../components/utilityComponents/Logout';
import RestService from '../http';
import showToast from '../components/utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from "mdb-react-ui-kit";
import Loader from '../components/Loader';


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function Sendmoney() {

    const service = new RestService();
    const [openDropdowns, setOpenDropdowns] = useState([]);
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [balance, setBalance] = useState({});
    const [bankList, setBankList] = useState([]);
    const [beneId, setBeneId] = useState("");
    const [amount, setAmount] = useState(0);
    // const [tranRefNo, setTranRefNo] = useState("");
    // const [bankName, setBankName] = useState([]);
    const [payoutMode, setPayoutMode] = useState("");
    const [basicModal, setBasicModal] = useState(false);
    const [commCharge, setCommCharge] = useState(0);
    // const [parsedValue, setParsedValue] = useState({});
    const [beneAccNo, setBeneAccNo] = useState('');
    const [bankName, setBankName] = useState('');
    const [loading, setLoading] = useState(false);
    const [addedBankList, setAddedBankList] = useState([]);
    // const [totalAmount, setTotalAmount] = useState(0);
    const [name] = useState(sessionStorage.getItem("name"));
    const [roleName] = useState(sessionStorage.getItem("role"));
    const totalAmount = parseFloat(amount) + parseFloat(commCharge);


    // const toggleShow = () => setBasicModal(!basicModal);

    const handleClick = (index) => {
        const updatedOpenDropdowns = [...openDropdowns];

        if (updatedOpenDropdowns.includes(index)) {
            updatedOpenDropdowns.splice(updatedOpenDropdowns.indexOf(index), 1);
        } else {
            updatedOpenDropdowns.push(index);
        }

        setOpenDropdowns(updatedOpenDropdowns);
    };


    const modalRef = useRef(null);

    const openForm = (event) => {
        event.preventDefault();
        modalRef.current.style.display = 'block';
    };

    const closeForm = () => {
        modalRef.current.style.display = 'none';
    };

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        service.fetchUserBalance().then(result => result.json()).then(balres => {
            console.log(balres);
            setBalance(balres);

        }).catch((error) => {
            console.log(error);
        })

    }, []);


    const handlePayoutDetail = (e, item) => {

        service.post("user/getmovetobankuser", null).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setBankList(result.data);
                setBeneId(item.beneId);
                setBeneAccNo(item.beneAccNo);
                setBankName(item.bankName);

            } else {
                setBankList([]);
            }
        }).catch((error) => {
            console.error("API request error:", error);
        })

    }

    useEffect(() => {
        handlePayoutDetail();
    }, []);


    // const handleStatus = () => {

    //     var raw = {
    //         "operatorIncode": "YPS",
    //         "TranRefNo": tranRefNo
    //     }

    //     service.post("yespayout/fundstatus", raw).then(result => {
    //         console.log(result);
    //         if (result.status === service.SUCCESS) {
    //             showToast("SUCCESS", result.message);

    //         } else {
    //             showToast("DANGER", result.message);
    //         }
    //     }).catch((error) => {
    //         console.log(error);
    //     })
    // }

    const handleModal = () => {

        if (amount === undefined || amount === "" || amount === null) {
            showToast('WARN', 'PLEASE FILL AMOUNT!!');
            return false;
        }

        if (payoutMode === undefined || payoutMode === "" || payoutMode === null) {
            showToast('WARN', 'PLEASE SELECT PAYOUT MODE!!');
            return false;
        }

        var raw = {
            "amount": amount,
            "mode": payoutMode,
            "operatorCode": "YPN"
        }

        setLoading(true);

        service.post("commission/fetchChargeByAmount", raw).then(result => {
            setLoading(false);
            console.log(result);

            if (result.status == service.SUCCESS) {

                // const apiAmount = result.data.amount;
                const apiCharge = result.data.charge;

                setCommCharge(apiCharge);

                setBasicModal(true);


            } else {
                setCommCharge(0.0)
            }

        })
    }

    const handlePayout = () => {

        var raw = {
            "operatorIncode": "YPN",
            "amount": amount,
            "beneId": beneId,
            "mode": payoutMode,
            "charge": commCharge
        }

        setLoading(true);

        service.post("warehouse/payoutyes", raw).then(result => {
            console.log(result);
            setLoading(false);

            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);

               
            } else {
                setBasicModal(false);
                showToast("DANGER", result.message)
            }
        }).catch((error) => {
            console.log(error);
        })
    }


    const handleCloseModal = () => {
        setBasicModal(false);
    }

    // const handleBeneId = (e) => {
    //     const jsonValue = e.target.value;

    //     try {
    //         const parsedValue = JSON.parse(jsonValue);
    //         console.log(parsedValue);
    //         setParsedValue(parsedValue);

    //     } catch (error) {
    //         console.error('Error parsing JSON:', error);
    //     }
    // };


    return (
        <>
            <ToastContainer />
            {loading && <Loader />}

            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" className='bg-light' open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className="pull-right" style={{ marginLeft: "79%" }}>
                            <div className="btn-group main-balance" style={{ borderRadius: "10px", backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)', color: "white", marginTop: "2px" }}>
                                <button type="button" data-toggle="dropdown" style={{ color: "white", fontWeight: "500", background: "none", border: "none" }} >
                                    <img src="image/wallet.png" className="wallet-icon" />
                                    <span className="mybal-text-white">My Balance</span><br />
                                    <span className="badge" id="wallet" style={{ fontSize: "14px" }}>&#8377;
                                        {balance === undefined
                                            ? 0.0
                                            : balance.data}
                                    </span>
                                </button>
                            </div>&nbsp;&nbsp;

                            <div className="btn-group btn-group-list btn-group-notification notification">
                                <button style={{ background: "none", border: "none", color: "black", position: "relative", }} type="button" data-toggle="dropdown">
                                    <CiBellOn style={{ fontSize: "30px" }} />
                                    <span className="badge"
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            background: "red", // Change the background color as needed
                                            borderRadius: "50%",
                                            padding: "5px",
                                            fontSize: "12px",
                                            color: "white",
                                        }}
                                    >0</span></button>

                            </div>&nbsp;&nbsp;

                            <div className="btn-group btn-group-option">
                                <button style={{ background: "none", border: "none" }} type="button" data-toggle="dropdown"><span
                                    className='user-img'>
                                    <img className='rounded-circle img-circle' src='image/profile.png' width='40' alt='' /></span></button>
                                <ul className="dropdown-menu pull-right" role="menu">
                                    <li><a href="/editprofile"><FaUserAlt className='glyphicon' /> My Profile</a>
                                    </li>

                                    <li className="divider"></li>
                                    <li>
                                        <a onClick={() => Logout("your-url")}>
                                            <MdLogout className='glyphicon' />Sign Out
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            <img style={{ width: "150px" }} src='./image/softmintlogo.png' />
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>

                    </DrawerHeader>
                    <Divider />
                    <List>

                        <div className="leftpanel" style={{ backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)' }}>
                            <div className="media profile-left">
                                <a className="pull-left profile-thumb" href="/editprofile">
                                    <img
                                        src="./image/profile.png"
                                        alt="user"
                                        className="img-circle"
                                    /> </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                <div className="media-body">
                                    <h5 className="media-heading">{name}</h5>
                                    <small className="text-white">{service.roles[new Number(roleName)]}</small>
                                </div>
                            </div>
                            <ul className="nav nav-pills nav-stacked">
                                <li className="active">
                                    <a href="/side"><BiHomeAlt className='house' /> <span>
                                        Dashboard</span>
                                    </a></li>

                                <li className="parent" ><a href="#" onClick={() => handleClick(0)} ><BiUserCircle className='house' />
                                    <span> My Account</span>

                                    <BiChevronDown style={{ marginLeft: "75px" }} />
                                </a>

                                    <ul className={`children ${openDropdowns.includes(0) ? 'show' : ''}`}>
                                        <li className="" ><a href="/editprofile"> Edit Profile</a></li>
                                        <li className=""><a href="/kyc"> KYC Verification</a></li>
                                        <li className=""><a href="/kycmicroatm"> KYC Microatm</a></li>
                                        <li className=""><a href="/changepasswrd"> Change Password</a></li>
                                        <li className=""><a href="/commison"> My Commission</a></li>
                                        <li className=""><a href="print/certificate-print.php" target="_blank"> Print
                                            Certificate</a></li>
                                    </ul>

                                </li>
                                <li className="parent" ><a href="#" onClick={() => handleClick(1)}>
                                    <RiShoppingBasketLine className='house' /> <span>My Wallet</span>

                                    <BiChevronDown style={{ marginLeft: "85px" }} />
                                </a>
                                    <ul className={`children ${openDropdowns.includes(1) ? 'show' : ''}`}>
                                        <li className=""><a href="/accountinfo"> Account Info</a></li>
                                        <li className=""><a href="/bankaccount"> Bank Account Add</a></li>
                                        <li className=""><a href="/sendmoney"> Send Money</a></li>
                                        {/* <li className=""><a href="/addmoney"> Add Money</a></li> */}
                                        {/* <li className=""><a href="/fundrequest"> Fund Request</a></li> */}
                                        {/* <li className=""><a href="/wallettrans"> Wallet Transfer</a></li> */}
                                        {/* <li className=""><a href="/mpin"> Change Mpin</a></li> */}
                                    </ul>
                                </li>

                                <li className="">
                                    <a href="/retailerservice"><BsGrid className='house' /> <span>
                                        Services</span>
                                        <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                    </a>
                                </li>

                                <li className="parent"><a href="#" onClick={() => handleClick(2)}><AiOutlineFolderOpen className='house' /> <span> Reports</span>

                                    <BiChevronDown style={{ marginLeft: "97px" }} />
                                </a>
                                    <ul className={`children ${openDropdowns.includes(2) ? 'show' : ''}`}>
                                        <li className=""><a href="/rechargereportretailer"> Recharge Report</a></li>
                                        <li className=""><a href="/aepsreport"> AEPS Report </a></li>
                                        <li className=""><a href="/aadharpayreport"> AadharPay Report </a></li>
                                        <li className=""><a href="/retailertxnreport"> Transaction Report </a></li>
                                        <li className=""><a href="/payout"> Payout Report</a></li>
                                        <li className=""><a href="/nsdlreport"> NSDL Report</a></li>
                                        <li className=""><a href="/retailerinsurancereport"> Insurance Report</a></li>                                        <li className=""><a href="/retailerearningreport"> Earning Report</a></li>
                                                                              <li className=""><a href="/bbpsreport"> BBPS Report</a></li>

                                         
                                        <li className=""><a href="/matmreport"> MATM Report</a></li>
                                        <li className=""><a href="/retailercouponreport"> Coupon Req. Report</a></li>
                                        <li className=""><a href="/retailermatmlogreport"> MATM Log Report</a></li>
                                        <li className=""><a href="/retailermovetobankuserreport"> MoveToBankUser Report</a></li>
                                        <li className=""><a href="/dmtreport"> DMT Report</a></li>
                                       
                                    </ul>
                                </li>
                                <li className="">
                                    <a href="/feedback"><HiOutlineChat className='house' /> <span>
                                        Feedback</span>
                                        <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                    </a></li>

                                <li className="">
                                    <a href="/kyc"><BsFingerprint className='house' /> <span>
                                        AEPS E-Kyc</span>
                                        <BiChevronDown style={{ marginLeft: "70px", visibility: "hidden" }} />
                                    </a></li>
                            </ul>

                            <div className="menu-devide">&nbsp;</div>
                            <div style={{ display: open ? 'block' : 'none' }}>
                                <div className="customer-support-sec">
                                    <h3>Account Details</h3>
                                    <p>ICICI BANK</p>
                                    <p style={{ fontSize: "11px" }}>(SOFTMINT)</p>
                                    <p>A/C No - 260705000861</p>
                                    <p><span>IFSC -</span> ICIC0002607</p>
                                </div>
                                <div className="menu-devide">&nbsp;</div>
                                <div className="customer-support-sec" style={{ display: open ? 'block' : 'none' }}>
                                    <h3>Quick Support</h3>
                                    <p>SOFTMINT</p>
                                    <p>Phone : 9999726418</p>
                                    <p>What's App : 8809912400</p>
                                    <p>Email : <a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                        data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}>info@softmintdigital.com</a></p>
                                </div>
                            </div>
                            <div style={{
                                display: open ? 'none' : 'block',
                                height: '100vh'
                            }}>
                                <div>
                                    <h3></h3>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p><span></span></p>
                                </div>
                                <div className="menu-devide">&nbsp;</div>
                                <div className="">
                                    <h3></h3>
                                    <p></p>
                                    <p></p>
                                    <p><a className="__cf_email__"
                                        data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}>
                                    </a>
                                    </p>
                                </div>
                            </div>

                        </div><br />
                    </List>
                </Drawer>

                <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                    <DrawerHeader />
                    <div className="pageheader">
                        <div className="media">
                            <div className="media-body">
                                <ul className="breadcrumb">
                                    <li><a href="javascript:void(0);"><BiHomeAlt style={{ color: '#999' }} /></a></li>
                                    <li>My Wallet</li>
                                </ul>
                                <h4>Send Money</h4>
                            </div>
                        </div>
                    </div>

                    <div className="contentpanel">
                        <div className="row">
                            <div className="col-md-4">
                                <div name="rechargeform" className="service-form" id="rechargeform">
                                    <div className="service-panel" style={{ overflow: "scroll" }}>
                                        <h1 className="service-title">Send Money</h1>
                                        <div className="panel-body">
                                            <div className="col-md-12">
                                                <div className="step1">
                                                    <div className="form-group">
                                                        <div className="col-md-12">
                                                            <div className="input-box">
                                                                <label className="control-label">Payout To Account</label>
                                                                <input
                                                                    type="text"
                                                                    name="payto"
                                                                    id="payto"
                                                                    className="form-control text-box"
                                                                    placeholder="Enter bank name"
                                                                    // onChange={handleBeneId}
                                                                    value={beneAccNo}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <div className="col-md-12">
                                                            <div className="input-box">
                                                                <label className="control-label">Payout Amount </label>
                                                                <input
                                                                    type="text"
                                                                    id="payamt"
                                                                    name="payamt"
                                                                    className="form-control text-box"
                                                                    maxlength="5"
                                                                    tabindex="2"
                                                                    onkeypress="return isNumberKey(event)"
                                                                    fdprocessedid="ffbph9"
                                                                    onChange={(e) => setAmount(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="form-group">
                                                        <div className="col-md-12">
                                                            <div className="input-box">
                                                                <label className="control-label">Charge </label>
                                                                <input
                                                                    type="text"
                                                                    id="payamt"
                                                                    name="charge"
                                                                    className="form-control text-box"
                                                                    maxlength="5"
                                                                    tabindex="2"
                                                                    onkeypress="return isNumberKey(event)"
                                                                    fdprocessedid="ffbph9"
                                                                    onChange={(e) => setCharge(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    {/* <div className="row form-group">
                                                        <div className="col-md-8 ml-2">
                                                            <div className="input-box">
                                                                <label className="control-label">Enter Txn Ref. No </label>
                                                                <input type="text" onChange={(e) => setTranRefNo(e.target.value)} id="payamt" name="payamt" className="form-control text-box" tabindex="2" fdprocessedid="ffbph9" />
                                                            </div>

                                                        </div>

                                                        <div className="col-md-3 mt-5">
                                                            <button className='btn bg-blue' onClick={handleStatus} style={{ padding: "1px" }}>View Status</button>
                                                        </div>
                                                    </div> */}

                                                    <div className="form-group">
                                                        <div className="col-md-12">
                                                            <div className="input-box">
                                                                <label className="control-label">Select Payout Mode </label>
                                                                <select
                                                                    className="form-control select-box width100p"
                                                                    onChange={(e) => setPayoutMode(e.target.value)}
                                                                    tabindex="3"
                                                                    name="paytype"
                                                                    id="paytype"
                                                                    fdprocessedid="pvo5dp"
                                                                >
                                                                    <option value="">SELECT PAYMENT MODE</option>
                                                                    <option value={"IMPS"}>IMPS</option>
                                                                    <option value={"NEFT"}>NEFT</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="form-group">
                                                        <div className="col-md-12">
                                                            <div className="input-box">
                                                                <label className="control-label">Benefissiary Id </label>
                                                                <input
                                                                    type="text"
                                                                    id="payamt"
                                                                    name="beneid"
                                                                    className="form-control text-box"
                                                                    maxlength="25"
                                                                    tabindex="2"
                                                                    fdprocessedid="ffbph9"
                                                                    onChange={(e) => setBenefissId(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="form-group" style={{ marginBottom: "0px" }}>
                                                        <div className="col-md-12">
                                                            <button className="btn btn-primary service-btn" id="butproceedsend" tabindex="4" fdprocessedid="fjzvw" onClick={(e) => handleModal(e, amount, payoutMode)}> PAYOUT</button>
                                                            <button className="btn btn-default service-btn" id="but-clear" tabindex="5" fdprocessedid="1k9ljk"> CLEAR</button>
                                                        </div>
                                                    </div>
                                                </div>

                                              
                                                <div className="step3 confirmation-sec" id="display_result" style={{ display: "none" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-8">
                                <div className="service-panel report-panel">
                                    <h4 className="service-title">List of Bank Account</h4>
                                    <div className="panel-body" style={{ overflow: "scroll" }}>
                                        <div style={{ padding: "0px 8px 8px" }}>
                                            <div id="displaytransaction" style={{ marginTop: "0px" }}>
                                                <div className="report-box">
                                                    <div className="row">
                                                        <div className="table-responsive" style={{ padding: "1%" }}>
                                                            <table className="table table-bordered mb30">
                                                                <thead>
                                                                    <tr className="table-new">
                                                                        <th className="px-1">SL.NO</th>
                                                                        <th>Action</th>
                                                                        <th>Name</th>
                                                                        <th>Acc. No</th>
                                                                        <th className="px-1">IFSC</th>
                                                                        <th>Bank</th>
                                                                        <th>Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(!bankList || bankList.length === 0) ? (
                                                                        <tr className='text-center'>
                                                                            <td colSpan="7">NO BANK DETAILS AVAILABLE</td>
                                                                        </tr>
                                                                    ) : (
                                                                        bankList.map((item, index) => (
                                                                            <tr>
                                                                                <td>{index + 1}</td>
                                                                                <td>
                                                                                    <button className='btn bg-blue' onClick={(e) => handlePayoutDetail(e, item)}>
                                                                                        Pay
                                                                                    </button>
                                                                                </td>
                                                                                <td>{item.remName}</td>
                                                                                <td>{item.beneAccNo}</td>
                                                                                <td>{item.beneIFSC}</td>
                                                                                <td>{item.bankName}</td>
                                                                                <td style={{ color: item.status === "1" ? 'green' : 'red' }}>
                                                                                    {item.status === "1" ? 'ACTIVE' : 'INACTIVE'}
                                                                                </td>

                                                                            </tr>
                                                                        ))
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>

            <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
                <MDBModalDialog>
                    <MDBModalContent style={{ marginLeft: "-100%", marginTop: "30%" }}>
                        <MDBModalHeader>
                            <MDBModalTitle>MOVE TO INSTANT SETTLEMENT</MDBModalTitle>
                            <MDBBtn
                                className="btn-close"
                                color="none"
                                onClick={handleCloseModal}
                            >
                            </MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className='container bg-green' style={{ borderRadius: "10px", padding: "2%" }}>
                                <div className='row'>
                                    <div className='col-md-3' style={{ marginTop: "4%" }}>
                                        <FaCheckCircle style={{ fontSize: "40px" }} />
                                    </div>

                                    <div className='col-md-9'>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th style={{ fontWeight: "bold" }}>Amount :</th>
                                                    <td style={{ color: "white", fontWeight: "300" }}> {amount}/- </td>
                                                </tr>

                                                <tr>
                                                    <th style={{ fontWeight: "bold" }}>Charges :</th>
                                                    <td style={{ color: "white", fontWeight: "300" }}> {commCharge}/- </td>
                                                </tr>

                                                <tr>
                                                    <th style={{ fontWeight: "bold" }}>Total Amount :</th>
                                                    <td style={{ color: "white", fontWeight: "300" }}>{totalAmount} /- </td>
                                                </tr>

                                                <tr>
                                                    <th style={{ fontWeight: "bold" }}>Account Number :</th>
                                                    <td style={{ color: "white", fontWeight: "300" }}> {beneAccNo}/- </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                            </div>
                        </MDBModalBody>

                        <MDBModalFooter>
                            <button className='btn bg-red' color="secondary" onClick={handleCloseModal}>
                                Close
                            </button>
                            <button className='btn bg-blue' onClick={handlePayout}>Proceed</button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <a href="https://api.whatsapp.com/send/?phone=8809912400&text&app_absent=0" target="_blank"
                className="whatsapp-but" title="">
                <img src="image/whatsapp-icon.png" alt="Whatapps" />
            </a>

            <a
                href="#"
                target="_blank"
                className="helpdesk-but"
                title="Request Callback !"
                onClick={openForm}
            >
                <img src="image/help-icon.png" alt="Help" />
            </a>

            <div className="chat-popup" ref={modalRef} id="myForm">
                <form action="#" className="helpdesk-container" onSubmit={(e) => e.preventDefault()}>
                    <h1>
                        <div className="callbackheader-icon">
                            <FaWeixin aria-hidden="true" style={{ fontSize: '35px', paddingTop: '7px' }} />
                        </div>
                        CallBack Request
                    </h1>
                    <label htmlFor="callback_name"><b>Full Name</b></label>
                    <input
                        type="text"
                        id="callback_name"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="Name"
                        required
                    />
                    <label htmlFor="callback_phone"><b>Contact Number</b></label>
                    <input
                        type="text"
                        id="callback_phone"
                        maxLength="10"
                        name=""
                        className="form-control textbox"
                        placeholder="+91 9000 0000 00"
                        required
                    />
                    <label htmlFor="callback_email"><b>Email ID</b></label>
                    <input
                        type="text"
                        id="callback_email"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="info@softmintdigital.com"
                        required
                    />
                    <label htmlFor="callback_msg"><b>Message (250 Character Only)</b></label>
                    <textarea
                        id="callback_msg"
                        maxLength="250"
                        placeholder="Type message.."
                        name="msg"
                        className="form-controlx"
                    ></textarea>
                    <button type="submit" className="btn" id="reqcallbut">
                        Send Now
                    </button>
                    <a href="#" className="close-but" title="" onClick={closeForm}>
                        <FaTimes aria-hidden="true" />
                    </a>
                </form>
            </div>

            <div className="modal fade recharge-model success-model" id="model-succ-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="model-succ-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button success-button" onClick="errordisplay()" data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model error-model" id="model-err-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className=""><img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Error Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-err-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button error-button" onClick="errordisplay()" data-dismiss="modal">Dismiss</button>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model process-model" id="model-process-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Process Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-process-body">
                            <p className="mb-0"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model " id="model-yn-save" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-body-pdf">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="getinvno" />
                        <button type="button" className="btn btn-success model-button success-button" onClick="succok()" data-dismiss="modal" id="modal-no-save">OK</button>
                    </div>
                </div>
            </div>


            );
        </>
    );
}

import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';


export default function Sendnotification() {

    const handleFormSubmit = (event) => {
        event.preventDefault();
    };

    const showDiv = (cond) => {
        if (cond === 1) {
            document.getElementById('type_div').style.display = 'block';
            document.getElementById('customer_div').style.display = 'none';
        } else if (cond === 2) {
            document.getElementById('type_div').style.display = 'none';
            document.getElementById('customer_div').style.display = 'block';
        }
    };


    const [showAlert, setShowAlert] = useState(true);

    const handleAlertClose = () => {
        setShowAlert(false);
    };


    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropDownRef = useRef();

    const options = [
        { value: '', label: 'SELECT MEMBER' },
        { value: '1', label: 'MASTER ADMIN' },
        { value: '2', label: 'MASTER DISTRIBUTOR' },
        { value: '3', label: 'DISTRIBUTOR' },
        { value: '4', label: 'RETAILER' },
        { value: '5', label: 'CUSTOMER' },
        { value: '6', label: 'API USER' },

    ];

    const handleOptionSelect = (option) => {
        setSelectedOption(option.value);
        setIsDropdownOpen(false); 
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false); 
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">Send App Notification</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Utility</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Send App Notification</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card col-md-6 m-auto" id="addpack">
                            <div className="card-body">
                                {showAlert && (
                                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                        Notification image allowed Types jpg,png,gif
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" fdprocessedid="ot0uhh" onClick={handleAlertClose}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                )}
                                <form
                                    action="/sendnotification"
                                    encType="multipart/form-data"
                                    method="post"
                                    acceptCharset="utf-8"
                                    onSubmit={handleFormSubmit}
                                >
                                    <input type="hidden" name="securityToken" value="fb30010b54f1ddcaec64b95f099ad2ce" />
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="icheck-primary d-inline">
                                                <input type="radio" id="radioPrimary3" name="type" value="1" checked onClick={() => showDiv(1)} />
                                                <label htmlFor="radioPrimary3">Type wise</label>
                                            </div>
                                            <div className="icheck-primary d-inline ml-2">
                                                <input type="radio" id="radioPrimary4" name="type" value="2" onClick={() => showDiv(2)} />
                                                <label htmlFor="radioPrimary4">Customer wise</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3" id="type_div">
                                        <label>Select Customer Type</label><span className="text-danger"> *</span>
                                        <select name="customer_type" className="form-control select2 select2-hidden-accessible" data-select2-id="1" tabIndex="-1" aria-hidden="true">

                                        </select>

                                        <div
                                            ref={dropDownRef}
                                            className={`select2 select2-container select2-container--default ${isDropdownOpen ? 'select2-container--open' : ''
                                                }`}
                                            dir="ltr"
                                            data-select2-id="2"
                                            style={{ width: '420.625px' }}
                                            onClick={toggleDropdown}
                                        >
                                            <span className="selection">
                                                <span
                                                    className={`select2-selection select2-selection--single ${isDropdownOpen ? 'select2-selection--active' : ''
                                                        }`}

                                                    role="combobox"
                                                    aria-haspopup="true"
                                                    aria-expanded={isDropdownOpen}
                                                    tabIndex="0"
                                                    aria-disabled="false"
                                                    aria-labelledby="select2-package_id-24-container"
                                                >
                                                    <span
                                                        className="select2-selection__rendered"
                                                        id="select2-package_id-24-container"
                                                        role="textbox"
                                                        aria-readonly="true"
                                                        title={selectedOption ? options.find((option) => option.value === selectedOption).label : 'To'}
                                                    >
                                                        {selectedOption ? options.find((option) => option.value === selectedOption).label : 'To'}
                                                    </span>

                                                    <span className="select2-selection__arrow" role="presentation">
                                                        <b role="presentation"></b>
                                                    </span>
                                                </span>
                                            </span>

                                            {isDropdownOpen && (
                                                <span className="dropdown-wrapper" style={{ position: 'absolute' }}>
                                                    <span className="select2-dropdown select2-dropdown--below" dir="ltr" style={{ width: '420.625px' }}>
                                                        <span className="select2-search select2-search--dropdown">
                                                            <input
                                                                className="select2-search__field"
                                                                type="search"
                                                                tabIndex="0"
                                                                autoComplete="off"
                                                                autoCorrect="off"
                                                                autoCapitalize="none"
                                                                spellCheck="false"
                                                                role="searchbox"
                                                                aria-autocomplete="list"
                                                                aria-controls="select2-package_id-ci-results"
                                                            />
                                                        </span>
                                                        <span className="select2-results">
                                                            <ul className="select2-results__options" role="listbox" id="select2-package_id-ci-results" aria-expanded="true" aria-hidden="false">
                                                                {options.map((option) => (
                                                                    <li
                                                                        key={option.value}
                                                                        className={`select2-results__option ${option.value === selectedOption ? 'select2-results__option--highlighted' : ''}`}
                                                                        role="option"
                                                                        aria-selected={option.value === selectedOption}
                                                                        data-select2-id={`select2-package_id-f9-result-${option.value}`}
                                                                        onClick={() => handleOptionSelect(option)}
                                                                    >
                                                                        {option.label}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </span>
                                                    </span>
                                                </span>
                                            )}

                                        </div>

                                    </div>
                                    <div className="col-12 mt-3 d-none" id="customer_div">
                                        <label>Select Customer</label><span className="text-danger"> *</span>
                                        <select name="customer_id[]" className="form-control select2 select2-hidden-accessible" multiple id="customer_list" data-placeholder="Select Customer" tabIndex="-1" aria-hidden="true" data-select2-id="customer_list">
                                            <option value="" data-select2-id="10">Select Customer</option>
                                            <option value="6" data-select2-id="11"> AJOY MONDAL [MD1003] [9609236025]</option>
                                            <option value="2" data-select2-id="12"> NAVNATH SASE [RT1001] [8600707581]</option>
                                            <option value="1" data-select2-id="13"> Sharad Bandarkar [MA1000] [8600436163]</option>
                                        </select>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label>Title</label><span className="text-danger"> *</span>
                                        <input type="text" name="title" placeholder="Enter Notification Title" value="" className="form-control" required fdprocessedid="29g46s" />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label>Notification Image: </label>
                                        <div className="custom-file">
                                            <input name="image_name" type="file" className="custom-file-input" />
                                            <label className="custom-file-label" htmlFor="49_doc">Notification Img</label>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label>Message</label>
                                        <textarea name="message" placeholder="Enter Message" className="form-control" rows="5" />
                                    </div>
                                    <div className="col-12 text-center">
                                        <input type="submit" className="btn btn-primary" style={{ marginTop: '30px' }} fdprocessedid="38uqbd" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">App Notification Details</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card" id="addpack">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div
                                        id="example1_wrapper"
                                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                                    >
                                        <div className="dataTables_length" id="example1_length">
                                            <label>
                                                Show{' '}
                                                <select
                                                    name="example1_length"
                                                    aria-controls="example1"
                                                    className="custom-select custom-select-sm form-control form-control-sm"
                                                    fdprocessedid="9sjp3"
                                                >
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="All">All</option>
                                                </select>{' '}
                                                entries
                                            </label>
                                        </div>
                                        <div className="dt-buttons">
                                            <a
                                                className="dt-button buttons-copy buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="Copy"
                                            >
                                                <span>
                                                    <i className="fa fa-files-o text-info font-weight-bold"></i>
                                                </span>
                                            </a>
                                            <a
                                                className="dt-button buttons-excel buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="Excel"
                                            >
                                                <span>
                                                    <i className="fa fa-file-excel-o text-success font-weight-bold"></i>
                                                </span>
                                            </a>
                                            <a
                                                className="dt-button buttons-pdf buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="PDF"
                                            >
                                                <span>
                                                    <i className="fa fa-file-pdf-o text-danger font-weight-bold"></i>
                                                </span>
                                            </a>
                                        </div>
                                        <table
                                            id="example1"
                                            className="table m-0 dataTable no-footer"
                                            role="grid"
                                            aria-describedby="example1_info"
                                        >
                                            <thead>
                                                <tr role="row">
                                                    <th
                                                        className="sorting_asc"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-sort="ascending"
                                                        aria-label="#: activate to sort column descending"
                                                        style={{ width: '39.0312px' }}
                                                    >
                                                        #
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Title: activate to sort column ascending"
                                                        style={{ width: '75.8021px' }}
                                                    >
                                                        Title
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Notification Image: activate to sort column ascending"
                                                        style={{ width: '226.781px' }}
                                                    >
                                                        Notification Image
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Message: activate to sort column ascending"
                                                        style={{ width: '117.917px' }}
                                                    >
                                                        Message
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Date: activate to sort column ascending"
                                                        style={{ width: '75.9896px' }}
                                                    >
                                                        Date
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Action: activate to sort column ascending"
                                                        style={{ width: '95.4792px' }}
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="odd">
                                                    <td valign="top" colSpan="6" className="dataTables_empty">
                                                        No data available in the table
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div
                                            className="dataTables_info"
                                            id="example1_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            Showing 0 to 0 of 0 entries
                                        </div>
                                        <div
                                            className="dataTables_paginate paging_simple_numbers"
                                            id="example1_paginate"
                                        >
                                            <ul className="pagination">
                                                <li
                                                    className="paginate_button page-item previous disabled"
                                                    id="example1_previous"
                                                >
                                                    <a
                                                        aria-controls="example1"
                                                        data-dt-idx="0"
                                                        tabIndex="0"
                                                        className="page-link"
                                                    >
                                                        Previous
                                                    </a>
                                                </li>
                                                <li
                                                    className="paginate_button page-item next disabled"
                                                    id="example1_next"
                                                >
                                                    <a
                                                        aria-controls="example1"
                                                        data-dt-idx="1"
                                                        tabIndex="0"
                                                        className="page-link"
                                                    >
                                                        Next
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from "react";
import { useState } from "react";


const MinistatementTable = ({ data }) => {

    return (
        <>
            <table className="table table-bordered table-striped" style={{ tableLayout: 'fixed', width: '100%', overflowY: "scroll" }}>
                <thead>
                    <tr>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Narration</th>
                    </tr>
                </thead>
                <tbody>
                    {data !== null ? data.map((option) => (
                        <tr>
                            <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{option.amount}</td>
                            <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{option.txntype}</td>
                            <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{option.date}</td>
                            <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{option.narration}</td>

                        </tr>
                    )) : null}

                </tbody>
            </table>
        </>
    );

};

export default MinistatementTable;

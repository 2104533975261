import React, { useEffect } from 'react';
import { CiBellOn } from 'react-icons/ci';
import { AiOutlineFolderOpen, AiFillPrinter } from 'react-icons/ai';
import { MdLogout } from 'react-icons/md';
import { FaUserAlt, FaWeixin, FaTimes, FaSearch } from 'react-icons/fa';
import { BiUserCircle, BiHomeAlt, BiChevronDown } from 'react-icons/bi';
import { HiOutlineChat } from 'react-icons/hi';
import { RiShoppingBasketLine } from 'react-icons/ri';
import { BsGrid, BsFingerprint } from 'react-icons/bs';
import { useState, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logout from '../components/utilityComponents/Logout';
import RestService from '../http';


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function Commison() {

    const service = new RestService();
    const [openDropdowns, setOpenDropdowns] = useState([]);
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [serviceList, setServiceList] = useState([]);
    const [name, setName] = useState(sessionStorage.getItem("name"));
    const [roleName, setRoleName] = useState(sessionStorage.getItem("role"));


    const handleClick = (index) => {
        const updatedOpenDropdowns = [...openDropdowns];

        if (updatedOpenDropdowns.includes(index)) {
            updatedOpenDropdowns.splice(updatedOpenDropdowns.indexOf(index), 1);
        } else {
            updatedOpenDropdowns.push(index);
        }

        setOpenDropdowns(updatedOpenDropdowns);
    };

    const modalRef = useRef(null);

    const openForm = (event) => {
        event.preventDefault();
        modalRef.current.style.display = 'block';
    };

    const closeForm = () => {
        modalRef.current.style.display = 'none';
    };

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };



    const handleUserCommission = () => {

        var raw = {
            "packageId": "",
            "roleId": "",
            "planId": ""
        }

        service.post("commission/fetchUserCommission", raw).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setServiceList(result.data);

            } else {
                setServiceList([]);
            }
        })
    }

    useEffect(() => {
        handleUserCommission();
    }, [])

    return (
        <> <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" className='bg-light' open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className="pull-right" style={{ marginLeft: "79%" }}>
                        <div className="btn-group main-balance" style={{ borderRadius: "10px", backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)', color: "white", marginTop: "2px" }}>
                            <button type="button" data-toggle="dropdown" style={{ color: "white", fontWeight: "500", background: "none", border: "none" }} >
                                <img src="image/wallet.png" className="wallet-icon" />
                                <span className="mybal-text-white">My Balance</span><br />
                                <span className="badge" id="wallet" style={{ fontSize: "14px" }}>&#8377; 0.00</span></button>

                        </div>&nbsp;&nbsp;

                        <div className="btn-group btn-group-list btn-group-notification notification">
                            <button style={{ background: "none", border: "none", color: "black", position: "relative", }} type="button" data-toggle="dropdown">
                                <CiBellOn style={{ fontSize: "30px" }} />
                                <span className="badge"
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        background: "red", // Change the background color as needed
                                        borderRadius: "50%",
                                        padding: "5px",
                                        fontSize: "12px",
                                        color: "white",
                                    }}
                                >0</span></button>

                        </div>&nbsp;&nbsp;

                        <div className="btn-group btn-group-option">
                            <button style={{ background: "none", border: "none" }} type="button" data-toggle="dropdown"><span
                                className='user-img'>
                                <img className='rounded-circle img-circle' src='image/profile.png' width='40' alt='' /></span></button>
                            <ul className="dropdown-menu pull-right" role="menu">
                                <li><a href="/editprofile"><FaUserAlt className='glyphicon' /> My Profile</a>
                                </li>

                                <li className="divider"></li>
                                <li>
                                    <a onClick={() => Logout("your-url")}>
                                        <MdLogout className='glyphicon' />Sign Out
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        <img style={{ width: "150px" }} src='./image/softmintlogo.png' />
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>

                </DrawerHeader>
                <Divider />
                <List>

                    <div className="leftpanel" style={{ backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)' }}>
                        <div className="media profile-left">
                            <a className="pull-left profile-thumb" href="/editprofile">
                                <img
                                    src="./image/profile.png"
                                    alt="user"
                                    className="img-circle"
                                /> </a>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="media-body">
                                <h5 className="media-heading">{name}</h5>
                                <small className="text-white">{service.roles[new Number(roleName)]}</small>
                            </div>
                        </div>
                        <ul className="nav nav-pills nav-stacked">
                            <li className="active"><a href="/side"><BiHomeAlt className='house' /> <span>
                                Dashboard</span></a></li>

                            <li className="parent" ><a href="#" onClick={() => handleClick(0)} ><BiUserCircle className='house' />
                                <span> My Account</span>

                                <BiChevronDown style={{ marginLeft: "75px" }} />
                            </a>

                                <ul className={`children ${openDropdowns.includes(0) ? 'show' : ''}`}>
                                    <li className="" ><a href="/editprofile"> Edit Profile</a></li>
                                    <li className=""><a href="/kyc"> KYC Verification</a></li>
                                    <li className=""><a href="/kycmicroatm"> KYC Microatm</a></li>
                                    <li className=""><a href="/changepasswrd"> Change Password</a></li>
                                    <li className=""><a href="/commison"> My Commission</a></li>
                                    <li className=""><a href="print/certificate-print.php" target="_blank"> Print
                                        Certificate</a></li>
                                </ul>

                            </li>
                            <li className="parent" ><a href="#" onClick={() => handleClick(1)}>
                                <RiShoppingBasketLine className='house' /> <span>My Wallet</span>

                                <BiChevronDown style={{ marginLeft: "85px" }} />
                            </a>
                                <ul className={`children ${openDropdowns.includes(1) ? 'show' : ''}`}>
                                    <li className=""><a href="/accountinfo"> Account Info</a></li>
                                    <li className=""><a href="/bankaccount"> Bank Account Add</a></li>
                                    <li className=""><a href="/sendmoney"> Send Money</a></li>
                                    {/* <li className=""><a href="/addmoney"> Add Money</a></li> */}
                                    {/* <li className=""><a href="/fundrequest"> Fund Request</a></li> */}
                                    {/* <li className=""><a href="/wallettrans"> Wallet Transfer</a></li> */}
                                    {/* <li className=""><a href="/mpin"> Change Mpin</a></li> */}
                                </ul>
                            </li>

                            <li className="">
                                <a href="/retailerservice"><BsGrid className='house' /> <span>
                                    Services</span>
                                    <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                </a>
                            </li>

                            <li className="parent"><a href="#" onClick={() => handleClick(2)}><AiOutlineFolderOpen className='house' /> <span> Reports</span>

                                <BiChevronDown style={{ marginLeft: "97px" }} />
                            </a>
                                <ul className={`children ${openDropdowns.includes(2) ? 'show' : ''}`}>
                                    <li className=""><a href="/rechargereportretailer"> Recharge Report</a></li>
                                    <li className=""><a href="/aepsreport"> AEPS Report </a></li>
                                    <li className=""><a href="/aadharpayreport"> AadharPay Report </a></li>
                                    <li className=""><a href="/retailertxnreport"> Transaction Report </a></li>
                                    <li className=""><a href="/payout"> Payout Report</a></li>
                                    <li className=""><a href="/nsdlreport"> NSDL Report</a></li>
                                    <li className=""><a href="/retailerearningreport"> Earning Report</a></li>
                                    <li className=""><a href="/bbpsreport"> BBPS Report</a></li>
                                    <li className=""><a href="/pancard"> Pancard Report</a></li>
                                    <li className=""><a href="/ledger"> Ledger Report</a></li>
                                    <li className=""><a href="/matmreport"> MATM Report</a></li>
                                    <li className=""><a href="/retailercouponreport"> Coupon Req. Report</a></li>
                                    <li className=""><a href="/retailermatmlogreport"> MATM Log Report</a></li>
                                    <li className=""><a href="/retailermovetobankuserreport"> MoveToBankUser Report</a></li>
                                    <li className=""><a href="/dmtreport"> DMT Report</a></li>

                                </ul>
                            </li>
                            <li className=""><a href="/feedback"><HiOutlineChat className='house' /> <span>
                                Feedback</span>
                                <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                            </a></li>

                            <li className=""><a href="/kyc"><BsFingerprint className='house' /> <span>
                                AEPS E-Kyc</span>
                                <BiChevronDown style={{ marginLeft: "70px", visibility: "hidden" }} />
                            </a></li>
                        </ul>

                        <div className="menu-devide">&nbsp;</div>
                        <div style={{ display: open ? 'block' : 'none' }}>
                            <div className="customer-support-sec">
                                <h3>Account Details</h3>
                                <p>ICICI BANK</p>
                                <p style={{ fontSize: "11px" }}>(SOFTMINT)</p>
                                <p>A/C No - 260705000861</p>
                                <p><span>IFSC -</span> ICIC0002607</p>
                            </div>
                            <div className="menu-devide">&nbsp;</div>
                            <div className="customer-support-sec" style={{ display: open ? 'block' : 'none' }}>
                                <h3>Quick Support</h3>
                                <p>SOFTMINT</p>
                                <p>Phone : 9999726418</p>
                                <p>What's App : 8809912400</p>
                                <p>Email : <a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                    data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}>info@softmintdigital.com</a></p>
                            </div>
                        </div>
                        <div style={{
                            display: open ? 'none' : 'block',
                            height: '100vh'
                        }}>
                            <div>
                                <h3></h3>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p><span></span></p>
                            </div>
                            <div className="menu-devide">&nbsp;</div>
                            <div className="">
                                <h3></h3>
                                <p></p>
                                <p></p>
                                <p><a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                    data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}></a></p>
                            </div>
                        </div>

                    </div><br />
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                <DrawerHeader />
                <div className="pageheader">
                    <div className="media">
                        <div className="media-body">
                            <ul className="breadcrumb">
                                <li><a href="javascript:void(0);"><BiHomeAlt style={{ color: '#999' }} /></a></li>
                                <li>My Account</li>
                            </ul>
                            <h4>My Commission</h4>
                        </div>
                    </div>
                </div>

                <div className="contentpanel">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="service-panel" style={{ overflow: "scroll" }}>
                                <h1 className="service-title">Prepaid Service</h1>
                                <div className="pull-right" style={{ marginTop: "-33px", marginRight: "15px", float: "right" }}>
                                    <a title="" data-toggle="tooltip" className="tooltips mr5" href="javascript:void(0);" data-original-title="Search" id="filterdt" style={{ color: 'black' }}>
                                        <FaSearch className="glyphicon glyphicon-search" style={{ color: 'black' }} /> Search</a>&nbsp;&nbsp;
                                    <a title="" data-toggle="tooltip" className="tooltips mr5" href="javascript:void(0);" onClick="printme()" data-original-title="Print" style={{ color: 'black' }}>
                                        <AiFillPrinter className="glyphicon glyphicon-print" style={{ color: 'black' }} /> Print</a>
                                </div>
                                <div className="col-md-12 pl-15 pr-15">
                                    <div className="panel panel-default cardx" style={{ display: "none", marginTop: "10px" }}>
                                        <div className="form-horizontal form-bordered no-padding service-form">
                                            <div className="form-group no-padding">
                                                <div className="col-md-5">
                                                    <div className="input-box">
                                                        <label for="textfield4" className="control-label">OPERATOR NAME</label>
                                                        <input type="text" tabindex="1" maxlength="80" id="optname" onkeyup="fill_record(1)" className="form-control text-box" onkeypress="return blockAddressChar(event)" />
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="input-box">
                                                        <label for="textfield4" className="control-label">OPERATOR TYPE</label>
                                                        <input type="text" tabindex="2" maxlength="80" id="opttype" onkeyup="fill_record(2)" className="form-control text-box" onkeypress="return blockAddressChar(event)" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-2 col-lg-2 controls">
                                                    <button type="button" className="btn btn-primary btnall" onClick="fill_record(3)">ALL</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-body pt-0">
                                    <div className="row">
                                        <div className="commission-setup">
                                            <div id="displaytext">
                                                <h1 className="default-heading">CMS</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>ADANI FINANCE CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>AGORA MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>AGORA MFI CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">4</div>
                                                    <h4>AGORA MFI INDIVIDUAL </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">5</div>
                                                    <h4>ALTURA[CENTRUM] </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">6</div>
                                                    <h4>ANNAPURNA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">7</div>
                                                    <h4>ANNAPURNA MFI CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">8</div>
                                                    <h4>ANNAPURNA MFI GROUP </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">9</div>
                                                    <h4>ARMAN MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">10</div>
                                                    <h4>ARMAN MFI CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">11</div>
                                                    <h4>AROHAN FINANCE AGENT </h4> <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">12</div>
                                                    <h4>ARTH FINANCE MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">13</div>
                                                    <h4>ASAI MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">14</div>
                                                    <h4>ASIRVAD MICROFINANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">15</div>
                                                    <h4>AU SFB EMPLOYEE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">16</div>
                                                    <h4>AVANTI FINANCE LOANS CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">17</div>
                                                    <h4>AVANTI MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">18</div>
                                                    <h4>AYE FINANCE CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">19</div>
                                                    <h4>AYE FINANCE LOANS AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">20</div>
                                                    <h4>BAJAJ AUTO </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">21</div>
                                                    <h4>BAJAJ FINSERV AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">22</div>
                                                    <h4>BAJAJ FINSERV CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">23</div>
                                                    <h4>BELSTAR MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">24</div>
                                                    <h4>BELSTAR MFI CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">25</div>
                                                    <h4>BIG_BASKET_AGENT (IRCPL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">26</div>
                                                    <h4>BIG_BASKET_B2B_AGENT(SGSPL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">27</div>
                                                    <h4>BPCL EV </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">28</div>
                                                    <h4>CEEJAY MFI EMPLOYEE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">29</div>
                                                    <h4>CENTRUM </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">30</div>
                                                    <h4>CHAITANYA MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">31</div>
                                                    <h4>DEALSHARE AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">32</div>
                                                    <h4>DEHAAT LOAN AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">33</div>
                                                    <h4>DMI FINANACE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">34</div>
                                                    <h4>DVARA E-REGISTRY MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">35</div>
                                                    <h4>ECOM EXPRESS </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">36</div>
                                                    <h4>EQUITAS SFB MFI </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">37</div>
                                                    <h4>EQUITAS SFB RD </h4> <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">38</div>
                                                    <h4>ESAVARI VEHICLE LOAN-CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">39</div>
                                                    <h4>EUREKA FORBES AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">40</div>
                                                    <h4>FFPL DEPOSIT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">41</div>
                                                    <h4>FULLERTON MFI CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">42</div>
                                                    <h4>FULLERTON MFI GROUP </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">43</div>
                                                    <h4>FUSION MICROFINANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">44</div>
                                                    <h4>GOMASSIVE </h4> <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">45</div>
                                                    <h4>HERO FINCORP CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">46</div>
                                                    <h4>HFFC CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">47</div>
                                                    <h4>HOMECREDIT - CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">48</div>
                                                    <h4>HUMANA </h4> <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">49</div>
                                                    <h4>ICICI CUSTOMER-RETAIL LOAN </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">50</div>
                                                    <h4>IG SOLAR AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">51</div>
                                                    <h4>INDIA SHELTER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">52</div>
                                                    <h4>INDITRADE MICROFINANCE LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">53</div>
                                                    <h4>ITC CHOUPAL SAGAR AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">54</div>
                                                    <h4>JANA SFB AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">55</div>
                                                    <h4>KAMAL FINCAPAGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">56</div>
                                                    <h4>LNT - MICROFINANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">57</div>
                                                    <h4>LOAN2WHEELS - CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">58</div>
                                                    <h4>LOK SUVIDHA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">59</div>
                                                    <h4>LOK SUVIDHA CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">60</div>
                                                    <h4>MAGMA FINCORP CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">61</div>
                                                    <h4>MAHINDRA AND MAHINDRA FINANCIAL </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">62</div>
                                                    <h4>MAHINDRA RURAL HOUSING FINANCE LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">63</div>
                                                    <h4>MANAPPURAM TW AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">64</div>
                                                    <h4>MANAPPURAM TW CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">65</div>
                                                    <h4>MIDLAND </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">66</div>
                                                    <h4>MITRATA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">67</div>
                                                    <h4>MOTILAL OSWAL CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">68</div>
                                                    <h4>MUTHOOT MIROFIN LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">69</div>
                                                    <h4>NOCPL BC AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">70</div>
                                                    <h4>OLA CAB </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">71</div>
                                                    <h4>OXYZEN EXPRESS AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">72</div>
                                                    <h4>RELIANCE RETAIL </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">73</div>
                                                    <h4>SAMASTA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">74</div>
                                                    <h4>SATIN </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">75</div>
                                                    <h4>SERVICES LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">76</div>
                                                    <h4>SEWA GRIH RIN-CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">77</div>
                                                    <h4>SHADOWFAX AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">78</div>
                                                    <h4>SHIKSHA FINANCE -AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">79</div>
                                                    <h4>SHRIRAM CITY CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">80</div>
                                                    <h4>SHRIRAM TRANSPORT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">81</div>
                                                    <h4>SHUBHAM HFC AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">82</div>
                                                    <h4>SONATA MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">83</div>
                                                    <h4>STREENIDHI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">84</div>
                                                    <h4>SUNDARAM FINANCE AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">85</div>
                                                    <h4>SVATANTRA MFI </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">86</div>
                                                    <h4>SVATANTRA MFI CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">87</div>
                                                    <h4>SWADHAAR </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">88</div>
                                                    <h4>TARAASHNA FINANCIAL SERVICES LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">89</div>
                                                    <h4>UBER DRIVER DEPOSIT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">90</div>
                                                    <h4>UDAAN CASH COLLECTION </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">91</div>
                                                    <h4>UDAAN COLLECTION AGENT(ITPL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">92</div>
                                                    <h4>UNNATI MICROFINANCE LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">93</div>
                                                    <h4>V2 RETAIL AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">94</div>
                                                    <h4>VASTU HOUSING AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">95</div>
                                                    <h4>VASTU HOUSING CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">MOBILE PREPAID</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>AIRTEL </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">2.9 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>BSNL SPECIAL </h4> <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">3 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>BSNL TOPUP </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">3 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">4</div>
                                                    <h4>JIO POSLITE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">2.5 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">5</div>
                                                    <h4>RELIANCE  JIO </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">2.7 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">6</div>
                                                    <h4>VI </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">3 %</div>
                                                </div>
                                                <h1 className="default-heading">MOBILE POSTPAID</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>AIRTEL POSTPAID </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>BSNL POSTPAID </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>IDEA POSTPAID </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">4</div>
                                                    <h4>JIO POSTPAID </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">5</div>
                                                    <h4>TATA DOCOMO CDMA POSTPAID </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">6</div>
                                                    <h4>TATA DOCOMO GSM POSTPAID </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">7</div>
                                                    <h4>VODAFONE IDEA POSTPAID </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">8</div>
                                                    <h4>VODAFONE POSTPAID </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">DTH RECHARGE</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>AIRTEL DIGITAL TV </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">3 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>DISH TV </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">3 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>SUN DIRECT TV </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">3 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">4</div>
                                                    <h4>TATA SKY </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">3 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">5</div>
                                                    <h4>VIDEOCON D2H </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">3 %</div>
                                                </div>
                                                <h1 className="default-heading">DATACARD RECHARGE OPERATOR</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>RELIANCE NETCONNECT 1X </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">LANDLINE RECHARGE OPERATOR</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>AIRTEL LANDLINE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>BSNL LANDLINE - CORPORATE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>BSNL LANDLINE - INDIVIDUAL </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">4</div>
                                                    <h4>MTNL MUMBAI </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">5</div>
                                                    <h4>TATA TELESERVICES (CDMA) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">6</div>
                                                    <h4>TIKONA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">GAS BILL PAYMENT OPERATOR</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>AAVANTIKA GAS LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>ADANI GAS </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>ASSAM GAS COMPANY LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">4</div>
                                                    <h4>BHAGYANAGAR GAS LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">5</div>
                                                    <h4>CENTRAL U.P. GAS LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">6</div>
                                                    <h4>CHAROTAR GAS SAHAKARI MANDALI LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">7</div>
                                                    <h4>GAIL GAS LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">8</div>
                                                    <h4>GREEN GAS LIMITED(GGL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">9</div>
                                                    <h4>GUJARAT GAS COMPANY LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">10</div>
                                                    <h4>HARYANA CITY GAS </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">11</div>
                                                    <h4>INDANE GAS </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">12</div>
                                                    <h4>INDIAN OIL-ADANI GAS PRIVATE LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">13</div>
                                                    <h4>INDRAPRASTHA GAS </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">14</div>
                                                    <h4>IRM ENERGY PRIVATE LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">15</div>
                                                    <h4>MAHANAGAR GAS LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">16</div>
                                                    <h4>MAHARASHTRA NATURAL GAS LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">17</div>
                                                    <h4>MEGHA GAS </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">18</div>
                                                    <h4>SABARMATI GAS LIMITED (SGL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">19</div>
                                                    <h4>SANWARIYA GAS LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">20</div>
                                                    <h4>SITI ENERGY </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">21</div>
                                                    <h4>TORRENT GAS </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">22</div>
                                                    <h4>TRIPURA NATURAL GAS </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">23</div>
                                                    <h4>UNIQUE CENTRAL PIPED GASES PVT LTD (UCPGPL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">24</div>
                                                    <h4>VADODARA GAS LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">INSURANCE OPERATOR</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>AEGON LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>AVIVA LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>BAJAJ ALLIANZ GENERAL INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">4</div>
                                                    <h4>BAJAJ ALLIANZ LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">5</div>
                                                    <h4>BHARTI AXA LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">6</div>
                                                    <h4>CANARA HSBC ORIENTAL BANK OF COMMERCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">7</div>
                                                    <h4>DHFL PRAMERICA LIFE INSURANCE CO. LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">8</div>
                                                    <h4>EDELWEISS TOKIO LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">9</div>
                                                    <h4>EXIDE LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">10</div>
                                                    <h4>FUTURE GENERALI INDIA LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">11</div>
                                                    <h4>HDFC LIFE INSURANCE CO. LTD. </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">12</div>
                                                    <h4>ICICI PRUDENTIAL LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">13</div>
                                                    <h4>IDBI FEDERAL LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">14</div>
                                                    <h4>INDIA FIRST LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">15</div>
                                                    <h4>MAGMA HDI - HEALTH INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">16</div>
                                                    <h4>MAGMA HDI - LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">17</div>
                                                    <h4>MAGMA HDI - MOTOR INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">18</div>
                                                    <h4>MAX BUPA HEALTH INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">19</div>
                                                    <h4>MAX LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">20</div>
                                                    <h4>PNB METLIFE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">21</div>
                                                    <h4>PRAMERICA LIFE INSURANCE LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">22</div>
                                                    <h4>RELIANCE GENERAL INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">23</div>
                                                    <h4>RELIANCE NIPPON LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">24</div>
                                                    <h4>RELIGARE HEALTH INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">25</div>
                                                    <h4>ROYAL SUNDARAM GENERAL INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">26</div>
                                                    <h4>SBI LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">27</div>
                                                    <h4>SBIG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">28</div>
                                                    <h4>SHRIRAM GENERAL INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">29</div>
                                                    <h4>SHRIRAM LIFE INSURANCE CO LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">30</div>
                                                    <h4>STAR UNION DAI ICHI LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">31</div>
                                                    <h4>TATA AIA LIFE INSURANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">EMI PAYMENT OPERATOR</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>AEON CREDIT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">BROADBAND BILL PAYMENT OPERATOR</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>ACT FIBERNET </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>AIRTEL BROADBAND </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>AIRTEL BROADBAND (FETCH &amp; PAY)  </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">4</div>
                                                    <h4>ASIANET BROADBAND </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">5</div>
                                                    <h4>COMWAY BROADBAND </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">6</div>
                                                    <h4>CONNECT BROADBAND </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">7</div>
                                                    <h4>FUSIONNET WEB SERVICES PRIVATE LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">8</div>
                                                    <h4>HATHWAY BROADBAND </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">9</div>
                                                    <h4>INSTALINKS </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">10</div>
                                                    <h4>ION </h4> <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">11</div>
                                                    <h4>NEXTRA BROADBAND </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">12</div>
                                                    <h4>SPECTRA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">13</div>
                                                    <h4>TIKONA INFINET PVT LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">14</div>
                                                    <h4>TTN BROADBAND </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">CABLE TV RECHARGE OPERATOR</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>HATHWAY </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">CREDIT CARD OPERATOR</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>MASTER CARD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>VISA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">WATER OPERATOR</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>AHMEDABAD MUNICIPAL CORPORATION </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>BANGALORE WATER SUPPLY AND SEWERAGE BOARD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>BHOPAL MUNICIPAL CORPORATION </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">4</div>
                                                    <h4>DELHI DEVELOPMENT AUTHORITY (DDA)  </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">5</div>
                                                    <h4>DELHI JAL BOARD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">6</div>
                                                    <h4>DEPARTMENT OF PUBLIC HEALTH ENGINEERING - MIZORAM </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">7</div>
                                                    <h4>GREATER WARANGAL MUNICIPAL CORPORATION </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">8</div>
                                                    <h4>GREATER WARANGAL MUNICIPAL CORPORATION WATER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">9</div>
                                                    <h4>GWALIOR MUNICIPAL CORPORATION </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">10</div>
                                                    <h4>HARYANA URBAN DEVELOPMENT AUTHORITY </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">11</div>
                                                    <h4>HYDERABAD METROPOLITAN WATER SUPPLY AND SEWERAGE BOARD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">12</div>
                                                    <h4>INDORE MUNICIPAL CORPORATION </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">13</div>
                                                    <h4>JABALPUR MUNICIPAL CORPORATION </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">14</div>
                                                    <h4>JALKAL VIBHAG NAGAR NIGAM PRAYAGRAJ </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">15</div>
                                                    <h4>KALYAN DOMBIVALI MUNICIPAL CORPORATION </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">16</div>
                                                    <h4>KERALA WATER AUTHORITY (KWA) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">17</div>
                                                    <h4>MADHYA PRADESH URBAN </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">18</div>
                                                    <h4>MUNICIPAL CORPORATION CHANDIGARH </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">19</div>
                                                    <h4>MUNICIPAL CORPORATION JALANDHAR </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">20</div>
                                                    <h4>MUNICIPAL CORPORATION LUDHIANA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">21</div>
                                                    <h4>MUNICIPAL CORPORATION LUDHIANA WATER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">22</div>
                                                    <h4>MUNICIPAL CORPORATION OF AMRITSAR </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">23</div>
                                                    <h4>MUNICIPAL CORPORATION OF GURUGRAM </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">24</div>
                                                    <h4>MYSURU CITI CORPORATION </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">25</div>
                                                    <h4>NEW DELHI MUNICIPAL COUNCIL (NDMC) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">26</div>
                                                    <h4>PHED - RAJASTHAN </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">27</div>
                                                    <h4>PUNE MUNICIPAL CORPORATION </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">28</div>
                                                    <h4>PUNJAB MUNICIPAL CORPORATIONS/COUNCILS </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">29</div>
                                                    <h4>RANCHI MUNICIPAL CORPORATION </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">30</div>
                                                    <h4>SILVASSA MUNICIPAL COUNCIL </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">31</div>
                                                    <h4>SURAT MUNICIPAL CORPORATION </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">32</div>
                                                    <h4>UJJAIN NAGAR NIGAM - PHED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">33</div>
                                                    <h4>URBAN IMPROVEMENT TRUST (UIT) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">34</div>
                                                    <h4>URBAN IMPROVEMENT TRUST (UIT) -  BHIWADI </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">35</div>
                                                    <h4>UTTARAKHAND JAL SANSTHAN  </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">ELECTRICITY OPERATOR</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>ADANI ELECTRICITY MUMBAI LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>ADANI ELECTRICITY MUMBAI LIMITED - OLD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>AJMER VIDYUT VITRAN NIGAM LIMITED (AVVNL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">4</div>
                                                    <h4>APEPDCL-EASTERN POWER DISTRIBUTION CO AP LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">5</div>
                                                    <h4>APSPDCL-SOUTHERN POWER DISTRIBUTION CO AP LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">6</div>
                                                    <h4>ASSAM POWER DISTRIBUTION COMPANY LTD (NON-RAPDR) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">7</div>
                                                    <h4>ASSAM POWER DISTRIBUTION COMPANY LTD (RAPDR) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">8</div>
                                                    <h4>B.E.S.T MUMBAI </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">9</div>
                                                    <h4>BANGALORE ELECTRICITY SUPPLY CO . LTD (BESCOM) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">10</div>
                                                    <h4>BHARATPUR ELECTRICITY SERVICES LTD. (BESL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">11</div>
                                                    <h4>BHARATPUR ELECTRICITY SERVICES LTD. (BESL) - OLD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">12</div>
                                                    <h4>BIKANER ELECTRICITY SUPPLY LIMITED (BKESL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">13</div>
                                                    <h4>BIKANER ELECTRICITY SUPPLY LIMITED (BKESL) - OLD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">14</div>
                                                    <h4>BSES RAJDHANI POWER LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">15</div>
                                                    <h4>BSES YAMUNA POWER LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">16</div>
                                                    <h4>CALCUTTA ELECTRIC SUPPLY CORPORATION (CESC) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">17</div>
                                                    <h4>CESU, ODISHA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">18</div>
                                                    <h4>CHAMUNDESHWARI ELECTRICITY SUPPLY CORP LTD (CESCOM) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">19</div>
                                                    <h4>CHANDIGARH ELECTRICITY DEPARTMENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">20</div>
                                                    <h4>CHHATTISGARH STATE POWER DISTRIBUTION CO. LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">21</div>
                                                    <h4>DAKSHIN GUJARAT VIJ COMPANY LIM ITED (DGVCL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">22</div>
                                                    <h4>DAKSHIN HARYANA BIJLI VITRAN NIGAM (DHBVN) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">23</div>
                                                    <h4>DAMAN AND DIU ELECTRICITY </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">24</div>
                                                    <h4>DEPARTMENT OF POWER, GOVERNMENT OF ARUNACHAL PRADESH </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">25</div>
                                                    <h4>DEPARTMENT OF POWER, NAGALAND </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">26</div>
                                                    <h4>DNH POWER DISTRIBUTION COMPANY LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">27</div>
                                                    <h4>GOA ELECTRICITY DEPARTMENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">28</div>
                                                    <h4>GOVERNMENT OF PUDUCHERRY ELECTRICITY DEPARTMENT  </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">29</div>
                                                    <h4>GULBARGA ELECTRICITY SUPPLY COMPANY LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">30</div>
                                                    <h4>HIMACHAL PRADESH STATE ELECTRICITY BOARD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">31</div>
                                                    <h4>HIMACHAL PRADESH STATE ELECTRICITY BOARD - OLD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">32</div>
                                                    <h4>HUBLI ELECTRICITY SUPPLY COMPANY LTD (HESCOM) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">33</div>
                                                    <h4>JAIPUR VIDYUT VITRAN NIGAM (JVVNL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">34</div>
                                                    <h4>JAMMU AND KASHMIR POWER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">35</div>
                                                    <h4>JAMSHEDPUR UTILITIES </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">36</div>
                                                    <h4>JHARKHAND BIJLI VITRAN NIGAM LIMITED (JBVNL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">37</div>
                                                    <h4>JODHPUR VIDYUT VITRAN NIGAM LIMITED (JDVVNL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">38</div>
                                                    <h4>KANPUR ELECTRICITY SUPPLY COMPANY </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">39</div>
                                                    <h4>KERALA STATE ELECTRICITY BOARD LTD. (KSEBL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">40</div>
                                                    <h4>KOTA ELECTRICITY DISTRIBUTION LIMITED (KEDL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">41</div>
                                                    <h4>KOTA ELECTRICITY DISTRIBUTION LIMITED (KEDL) - OLD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">42</div>
                                                    <h4>M.P. MADHYA KSHETRA VIDYUT VITARAN - RURAL </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">43</div>
                                                    <h4>M.P. MADHYA KSHETRA VIDYUT VITARAN - URBAN </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">44</div>
                                                    <h4>M.P. PASCHIM KSHETRA VIDYUT VITARAN </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">45</div>
                                                    <h4>M.P. POORV KSHETRA VIDYUT VITARAN - RURAL </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">46</div>
                                                    <h4>M.P. POORV KSHETRA VIDYUT VITARAN - URBAN </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">47</div>
                                                    <h4>M.P. POORVA KSHETRA VIDYUT VITARAN CO. LTD JABALPUR </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">48</div>
                                                    <h4>MADHYA GUJARAT VIJ COMPANY LIMITED (MGVCL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">49</div>
                                                    <h4>MAHARASHTRA STATE ELECTRICITY DISTBN CO LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">50</div>
                                                    <h4>MANGALORE ELECTRICITY SUPPLY CO . LTD (MESCOM) - RAPDR </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">51</div>
                                                    <h4>MEGHALAYA POWER DIST CORP LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">52</div>
                                                    <h4>NESCO, ODISHA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">53</div>
                                                    <h4>NEW DELHI MUNICIPAL COUNCIL (ND MC) - ELECTRICITY </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">54</div>
                                                    <h4>NOIDA POWER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">55</div>
                                                    <h4>NORTH BIHAR POWER DISTRIBUTION COMPANY LTD. </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">56</div>
                                                    <h4>PASCHIM GUJARAT VIJ COMPANY LIMITED (PGVCL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">57</div>
                                                    <h4>POWER &amp; ELECTRICITY DEPARTMENT - MIZORAM </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">58</div>
                                                    <h4>PUNJAB STATE POWER CORPORATION LTD (PSPCL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">59</div>
                                                    <h4>SIKKIM POWER - RURAL </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">60</div>
                                                    <h4>SIKKIM POWER - URBAN </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">61</div>
                                                    <h4>SNDL NAGPUR </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">62</div>
                                                    <h4>SOUTH BIHAR POWER DISTRIBUTION COMPANY LTD. </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">63</div>
                                                    <h4>SOUTHCO, ODISHA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">64</div>
                                                    <h4>TAMIL NADU ELECTRICITY BOARD (T NEB) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">65</div>
                                                    <h4>TATA POWER - DELHI </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">66</div>
                                                    <h4>TATA POWER - MUMBAI </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">67</div>
                                                    <h4>TORRENT POWER - AGRA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">68</div>
                                                    <h4>TORRENT POWER - AHMEDABAD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">69</div>
                                                    <h4>TORRENT POWER - BHIWANDI </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">70</div>
                                                    <h4>TORRENT POWER - SURAT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">71</div>
                                                    <h4>TP AJMER DISTRIBUTION LTD (TPADL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">72</div>
                                                    <h4>TP AJMER DISTRIBUTION LTD (TPADL) - OLD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">73</div>
                                                    <h4>TRIPURA ELECTRICITY CORP LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">74</div>
                                                    <h4>UTTAR GUJARAT VIJ COMPANY LIMITED (UGVCL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">75</div>
                                                    <h4>UTTAR HARYANA BIJLI VITRAN NIGAM (UHBVN) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">76</div>
                                                    <h4>UTTAR PRADESH POWER CORP LTD (UPPCL) - RURAL </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">77</div>
                                                    <h4>UTTAR PRADESH POWER CORP LTD (UPPCL) - URBAN </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">78</div>
                                                    <h4>UTTARAKHAND POWER CORPORATION LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">79</div>
                                                    <h4>UTTARAKHAND POWER CORPORATION LIMITED (OLD) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">80</div>
                                                    <h4>WESCO UTILITY </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">81</div>
                                                    <h4>WEST BENGAL STATE ELECTRICITY  (WBSEDCL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">R-OFFER OPERATOR</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>AIRMOBILE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>BSNL </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>IDEA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">4</div>
                                                    <h4>JIO </h4> <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">5</div>
                                                    <h4>TATA DOCOMO </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">6</div>
                                                    <h4>VODAFONE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">FASTAG OPERATOR</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>AXIS BANK FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>BANK OF BARODA FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>EQUITAS FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">4</div>
                                                    <h4>FEDERAL BANK FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">5</div>
                                                    <h4>HDFC  BANK FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">6</div>
                                                    <h4>ICICI BANK FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">7</div>
                                                    <h4>IDBI BANK FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">8</div>
                                                    <h4>IDFC FIRST BANK FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">9</div>
                                                    <h4>INDIAN HIGHWAYS FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">10</div>
                                                    <h4>INDUSIND BANK FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">11</div>
                                                    <h4>IOB FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">12</div>
                                                    <h4>JAMMU AND KASHMIR BANK FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">13</div>
                                                    <h4>KARNATAKA BANK FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">14</div>
                                                    <h4>KOTAK MAHINDRA BANK FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">15</div>
                                                    <h4>PAUL MERCHANTS FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">16</div>
                                                    <h4>PAYTM PAYMENTS BANK FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">17</div>
                                                    <h4>STATE ICICI BANK FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">18</div>
                                                    <h4>TRANSACTION ANALYST FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">19</div>
                                                    <h4>TRANSCORP INTERNATIONAL FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">20</div>
                                                    <h4>UCO BANK FASTAG </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">LOAN OPERATOR</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>ADANI FINANCE CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>AGORA MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>AGORA MFI CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">4</div>
                                                    <h4>AGORA MFI INDIVIDUAL </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">5</div>
                                                    <h4>ALTURA[CENTRUM] </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">6</div>
                                                    <h4>ANNAPURNA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">7</div>
                                                    <h4>ANNAPURNA MFI CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">8</div>
                                                    <h4>ANNAPURNA MFI GROUP </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">9</div>
                                                    <h4>ARMAN MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">10</div>
                                                    <h4>ARMAN MFI CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">11</div>
                                                    <h4>AROHAN FINANCE AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">12</div>
                                                    <h4>ARTH FINANCE MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">13</div>
                                                    <h4>ASAI MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">14</div>
                                                    <h4>ASIRVAD MICROFINANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">15</div>
                                                    <h4>AU SFB EMPLOYEE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">16</div>
                                                    <h4>AVANTI FINANCE LOANS CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">17</div>
                                                    <h4>AVANTI MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">18</div>
                                                    <h4>AYE FINANCE CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">19</div>
                                                    <h4>AYE FINANCE LOANS AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">20</div>
                                                    <h4>BAJAJ AUTO </h4> <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">21</div>
                                                    <h4>BAJAJ FINSERV AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">22</div>
                                                    <h4>BAJAJ FINSERV CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">23</div>
                                                    <h4>BELSTAR MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">24</div>
                                                    <h4>BELSTAR MFI CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">25</div>
                                                    <h4>BIG_BASKET_AGENT (IRCPL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">26</div>
                                                    <h4>BIG_BASKET_B2B_AGENT(SGSPL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">27</div>
                                                    <h4>BPCL EV </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">28</div>
                                                    <h4>CEEJAY MFI EMPLOYEE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">29</div>
                                                    <h4>CENTRUM </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">30</div>
                                                    <h4>CHAITANYA MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">31</div>
                                                    <h4>DEALSHARE AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">32</div>
                                                    <h4>DEHAAT LOAN AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">33</div>
                                                    <h4>DMI FINANACE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">34</div>
                                                    <h4>DVARA E-REGISTRY MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">35</div>
                                                    <h4>ECOM EXPRESS </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">36</div>
                                                    <h4>EQUITAS SFB MFI </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">37</div>
                                                    <h4>EQUITAS SFB RD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">38</div>
                                                    <h4>ESAVARI VEHICLE LOAN-CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">39</div>
                                                    <h4>EUREKA FORBES AGENT </h4> <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">40</div>
                                                    <h4>FFPL DEPOSIT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">41</div>
                                                    <h4>FULLERTON MFI CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">42</div>
                                                    <h4>FULLERTON MFI GROUP </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">43</div>
                                                    <h4>FUSION MICROFINANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">44</div>
                                                    <h4>GOMASSIVE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">45</div>
                                                    <h4>HERO FINCORP CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">46</div>
                                                    <h4>HFFC CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">47</div>
                                                    <h4>HOMECREDIT - CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">48</div>
                                                    <h4>HUMANA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">49</div>
                                                    <h4>ICICI CUSTOMER-RETAIL LOAN </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">50</div>
                                                    <h4>IG SOLAR AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">51</div>
                                                    <h4>INDIA SHELTER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">52</div>
                                                    <h4>INDITRADE MICROFINANCE LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">53</div>
                                                    <h4>ITC CHOUPAL SAGAR AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">54</div>
                                                    <h4>JANA SFB AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">55</div>
                                                    <h4>KAMAL FINCAPAGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">56</div>
                                                    <h4>LNT - MICROFINANCE </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">57</div>
                                                    <h4>LOAN2WHEELS - CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">58</div>
                                                    <h4>LOK SUVIDHA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">59</div>
                                                    <h4>LOK SUVIDHA CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">60</div>
                                                    <h4>MAGMA FINCORP CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">61</div>
                                                    <h4>MAHINDRA AND MAHINDRA FINANCIAL </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">62</div>
                                                    <h4>MAHINDRA RURAL HOUSING FINANCE LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">63</div>
                                                    <h4>MANAPPURAM TW AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">64</div
                                                    ><h4>MANAPPURAM TW CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">65</div>
                                                    <h4>MIDLAND </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">66</div>
                                                    <h4>MITRATA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">67</div>
                                                    <h4>MOTILAL OSWAL CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">68</div>
                                                    <h4>MUTHOOT MIROFIN LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">69</div>
                                                    <h4>NOCPL BC AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">70</div>
                                                    <h4>OLA CAB </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">71</div>
                                                    <h4>OXYZEN EXPRESS AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">72</div>
                                                    <h4>RELIANCE RETAIL </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">73</div>
                                                    <h4>SAMASTA </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">74</div>
                                                    <h4>SATIN </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">75</div>
                                                    <h4>SERVICES LIMITED </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">76</div>
                                                    <h4>SEWA GRIH RIN-CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">77</div>
                                                    <h4>SHADOWFAX AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">78</div>
                                                    <h4>SHIKSHA FINANCE -AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">79</div>
                                                    <h4>SHRIRAM CITY CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">80</div>
                                                    <h4>SHRIRAM TRANSPORT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">81</div>
                                                    <h4>SHUBHAM HFC AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">82</div>
                                                    <h4>SONATA MFI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">83</div>
                                                    <h4>STREENIDHI AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">84</div>
                                                    <h4>SUNDARAM FINANCE AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">85</div>
                                                    <h4>SVATANTRA MFI </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">86</div>
                                                    <h4>SVATANTRA MFI CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">87</div>
                                                    <h4>SWADHAAR </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">88</div>
                                                    <h4>TARAASHNA FINANCIAL SERVICES LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">89</div>
                                                    <h4>UBER DRIVER DEPOSIT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">90</div>
                                                    <h4>UDAAN CASH COLLECTION </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">91</div>
                                                    <h4>UDAAN COLLECTION AGENT(ITPL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">92</div>
                                                    <h4>UNNATI MICROFINANCE LTD </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">93</div>
                                                    <h4>V2 RETAIL AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">94</div>
                                                    <h4>VASTU HOUSING AGENT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">95</div>
                                                    <h4>VASTU HOUSING CUSTOMER </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">LIC</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>LIC </h4> <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">MUNICIPALITY</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>KALYAN DOMBIVALI MUNICIPAL CORPORATION </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>MADHYA PRADESH URBAN (E-NAGARPALIKA) - PROPERTY </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>PORT BLAIR MUNICIPAL COUNCIL </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">4</div>
                                                    <h4>PRAYAGRAJ NAGAR NIGAM - PROPERTY </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">LPG</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>BHARAT PETROLEUM CORPORATION LIMITED (BPCL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>HINDUSTAN PETROLEUM CORPORATION LTD (HPCL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>INDANE GAS </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">GOOGLE PLAY</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div><h4>BANK ACCOUNT </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>GOOGLE PLAY </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <h1 className="default-heading">PANCARD</h1>
                                                <div className="commission-box">
                                                    <div className="slno">1</div>
                                                    <h4>NSDL NEW PANCARD (ELECTRONIC) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">2</div>
                                                    <h4>NSDL NEW PANCARD (PHYSICAL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">3</div>
                                                    <h4>NSDL PANCARD CORRECTION (ELECTRONIC) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                                <div className="commission-box">
                                                    <div className="slno">4</div>
                                                    <h4>NSDL PANCARD CORRECTION (PHYSICAL) </h4>
                                                    <p className="status active">&nbsp;</p>
                                                    <div className="commission-amount">0 %</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="service-panel status-sec">
                                <div>
                                    <ul>
                                        <li><p className="status active">&nbsp;</p> Active Operator</li>
                                        <li><p className="status inactive">&nbsp;</p> Inactive Operator</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="service-panel" style={{ overflow: "scroll" }}>
                                <h1 className="service-title">Banking Service</h1>
                                <div className="pull-right" style={{ marginTop: "-33px", marginRight: "15px", float: "right" }}>
                                    <a title="" data-toggle="tooltip" className="tooltips mr5" href="javascript:void(0);" data-original-title="Search" id="" style={{ color: 'black' }}>
                                        <FaSearch className="glyphicon glyphicon-search" style={{ color: 'black' }} /> Search</a>&nbsp;&nbsp;
                                    <a title="" data-toggle="tooltip" className="tooltips mr5" href="javascript:void(0);" data-original-title="Print" style={{ color: 'black' }}>
                                        <AiFillPrinter className="glyphicon glyphicon-print" style={{ color: 'black' }} /> Print</a>
                                </div>
                                <div className="commission-setup">
                                    {serviceList.map((item) => (
                                        <div>
                                            <h1 class="default-heading" style={{ fontWeight: "bold" }}>{item.serviceName}</h1>

                                            {item.data.length > 0 ? (
                                                item.data.map((item1, index) => (
                                                    <div class="commission-box" key={index}>
                                                        <div class="slno">{index + 1}</div>
                                                        <h4>{item1.operatorName} ({item1.slab1}-{item1.slab2})</h4>
                                                        <p class="status active">&nbsp;</p>
                                                        <div class="commission-amount">₹    {item1.commission}</div>
                                                    </div>
                                                ))
                                            ) : (
                                                <p>No Commission Available</p>
                                            )}
                                            <hr />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </Box>


            <a href="https://api.whatsapp.com/send/?phone=8809912400&text&app_absent=0" target="_blank"
                className="whatsapp-but" title="">
                <img src="image/whatsapp-icon.png" alt="Whatapps" />
            </a>

            <a
                href="#"
                target="_blank"
                className="helpdesk-but"
                title="Request Callback !"
                onClick={openForm}
            >
                <img src="image/help-icon.png" alt="Help" />
            </a>

            <div className="chat-popup" ref={modalRef} id="myForm">
                <form action="#" className="helpdesk-container" onSubmit={(e) => e.preventDefault()}>
                    <h1>
                        <div className="callbackheader-icon">
                            <FaWeixin aria-hidden="true" style={{ fontSize: '35px', paddingTop: '7px' }} />
                        </div>
                        CallBack Request
                    </h1>
                    <label htmlFor="callback_name"><b>Full Name</b></label>
                    <input
                        type="text"
                        id="callback_name"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="Name"
                        required
                    />
                    <label htmlFor="callback_phone"><b>Contact Number</b></label>
                    <input
                        type="text"
                        id="callback_phone"
                        maxLength="10"
                        name=""
                        className="form-control textbox"
                        placeholder="+91 9000 0000 00"
                        required
                    />
                    <label htmlFor="callback_email"><b>Email ID</b></label>
                    <input
                        type="text"
                        id="callback_email"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="info@softmintdigital.com"
                        required
                    />
                    <label htmlFor="callback_msg"><b>Message (250 Character Only)</b></label>
                    <textarea
                        id="callback_msg"
                        maxLength="250"
                        placeholder="Type message.."
                        name="msg"
                        className="form-controlx"
                    ></textarea>
                    <button type="submit" className="btn" id="reqcallbut">
                        Send Now
                    </button>
                    <a href="#" className="close-but" title="" onClick={closeForm}>
                        <FaTimes aria-hidden="true" />
                    </a>
                </form>
            </div>

            <div className="modal fade recharge-model success-model" id="model-succ-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="model-succ-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button success-button" onClick="errordisplay()" data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model error-model" id="model-err-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className=""><img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Error Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-err-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button error-button" onClick="errordisplay()" data-dismiss="modal">Dismiss</button>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model process-model" id="model-process-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Process Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-process-body">
                            <p className="mb-0"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model " id="model-yn-save" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-body-pdf">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="getinvno" />
                        <button type="button" className="btn btn-success model-button success-button" onClick="succok()" data-dismiss="modal" id="modal-no-save">OK</button>
                    </div>
                </div>
            </div>


            );
        </ >
    );
}

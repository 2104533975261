import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer, MDBTable, MDBTableHead, MDBTableBody
} from "mdb-react-ui-kit";
import { FaFilePdf } from "react-icons/fa6";


const  RechargeSlip = ({ data, onClose }) => {

    // const [receiptData, setReceiptData] = useState(
    //     {
    //         "id": 284,
    //         "userId": "SNNQ8G",
    //         "mobile": "7282808497",
    //         "operatorId": "RJP",
    //         "openBal": 7571,
    //         "amount": 19,
    //         "charge": 0,
    //         "comm": 0,
    //         "closeBal": 7552,
    //         "tid": "NA",
    //         "status": "SUCCESS",
    //         "source": "WEB",
    //         "apiId": 3,
    //         "roleId": 5,
    //         "wlId": "admin",
    //         "date": "2024-02-02",
    //         "time": "03:20:36 pm",
    //         "ip": "NA",
    //         "requestLog": "{\"amount\":19,\"mobile\":\"7282808497\",\"operatorCode\":\"28\"}",
    //         "responseLog": "{\"data\":{\"date\":\"2024-02-02\",\"dateText\":\"03:20:36 pm\",\"tds\":\"2024-02-02\",\"opId\":\"Bearer M6WAYGiej4hFcyzlkDKJRss/T2Ddp5aGCTw79eJe1R8TeKXHgE27BWSoYHNBdUj7rare7il3shGP7jo55YLjhfh9EHN8x0XouEMzA5kei7X60X76z/djF4b7MvzSNWQpBQNKKRyKu6KzbO3eoB6p6zrRq2goMeB5jXS3qxMWF2ypV5WCiXAmFfqlm2N8o/EkDqasYh3NawaxftO15kyHZrDmrqUi8DwQBiWgVQK1BxiVViRIJXdVHDlsp3KaqxLy9zcE8tDQn+c+PJyCHhDULIMj0Gh9ATUE+Jd4XrQG+vqf7rjQEBjplbpeDaY6h+t5CXy+boaE1ggO7DjcPjAs3g==\",\"txnCode\":\"TXN\",\"totalCharge\":1000,\"txnStatus\":\"RECH521547323\",\"totalCcf\":\"3a9ecf5f66926b795b100add9ab74f1b\",\"chargedAmt\":\"{\\\"username\\\":\\\"66cda3292ee2151e4623b9a7eb7ac11a\\\",\\\"password\\\":\\\"3a9ecf5f66926b795b100add9ab74f1b\\\",\\\"requestid\\\":\\\"RECH521547323\\\",\\\"operator\\\":\\\"RJP\\\",\\\"circleid\\\":\\\"21\\\",\\\"post_mobno\\\":\\\"7282808497\\\",\\\"amount\\\":\\\"19.0\\\",\\\"request_type\\\":\\\"TRANSACT\\\"}\",\"transAmt\":\"{\\\"username\\\":\\\"66cda3292ee2151e4623b9a7eb7ac11a\\\",\\\"password\\\":\\\"3a9ecf5f66926b795b100add9ab74f1b\\\",\\\"requestid\\\":\\\"RECH432453460\\\"}\",\"commission\":\"66cda3292ee2151e4623b9a7eb7ac11a\",\"txnDesc\":\"\",\"txnId\":\"RECH521547323\"},\"respCode\":\"RCS\"}",
    //         "orderId": "RECH521547323"

    //     }
    // )

    useEffect(() => {
        console.log(data);
    }, []);

    const handlePrint = () => {
        window.print();
    };

    

    return (
        <>
            <MDBContainer style={{ width: "25%", height: "100vh", marginTop: "30%", background: "cover" }}>
                <MDBCard>
                    <MDBCardBody className="mx-4" style={{ flex: "1" }}>
                        <MDBContainer>
                            <div style={{ display: 'inline-block', position: 'relative', display: "flex", justifyContent: "center" }}>

                                <button className="btn mt-3"
                                    style={{ borderRadius: "15px", position: 'relative', zIndex: '1', justifyContent: "center", display: "flex", color: "white", backgroundColor: data.status === 'SUCCESS' ? 'green' : 'red' }}
                                >
                                    {data.status}
                                </button>
                            </div><br />

                            <p className="text-center" style={{ fontWeight: "bold" }}>{data.type}</p>

                            <MDBTable style={{ tableLayout: 'fixed', width: '100%', overflowY: "scroll" }}>
                                <MDBTableBody>
                                    <React.Fragment>
                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Operator</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.operatorName}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Mobile</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.mobile}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Amount</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.amount}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Commission</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.comm}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Status</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.status}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Date</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.date}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Time</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.time}</td>
                                        </tr>

                                    </React.Fragment>

                                    {/* <td style={{ fontWeight: "bold", maxWidth: "100px" }}>Status:</td>
                                    <td className="text-end" style={{ color: data.status === 'SUCCESS' ? 'green' : 'red' }}>{data.errormessage}</td> */}

                                </MDBTableBody>
                            </MDBTable>

                            <div style={{ float: "right" }}>
                                <button className="btn bg-blue" onClick={onClose}>Back</button>&nbsp;
                                <button className="btn bg-red" onClick={handlePrint}><FaFilePdf /></button>
                            </div>

                        </MDBContainer>
                    </MDBCardBody>

                </MDBCard>
            </MDBContainer>

        </>
    );

};

export default RechargeSlip;

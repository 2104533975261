import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Newspanel() {

    const [showContent, setShowContent] = useState(false);

    const handleNewButtonClick = () => {
        setShowContent(!showContent);
    };

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">News Panel</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Utility</Link>
                                    </li>
                                    <li className="breadcrumb-item active">News Panel</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                {showContent && (
                    <div className="card" id="addpack" >
                        <div className="card-body">
                            <form action="/newspanel" method="post">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <label>News Active/Deactive</label>
                                        <span className="text-danger"> *</span><br />
                                        <input type="radio" name="status" value="1" required /> Active&nbsp;&nbsp;
                                        <input type="radio" name="status" value="0" required /> Deactive
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <label>Notification Type</label>
                                        <span className="text-danger"> *</span><br />
                                        <input type="radio" name="notification_type" value="1" required /> App&nbsp;&nbsp;
                                        <input type="radio" name="notification_type" value="0" required /> Web
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <label>News</label>
                                        <span className="text-danger"> *</span>
                                        <input type="hidden" name="securityToken" value="fb30010b54f1ddcaec64b95f099ad2ce" />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                        <input type="submit" value="Add News" className="btn btn-primary" style={{ marginTop: '30px' }} fdprocessedid="0dlutf" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                <div className="card">
                    <div className="card-body">
                        <h1 className="m-0 text-dark float-right">
                            <span className={`btn btn-sm btn-primary ${showContent ? 'd-none' : ''}`} onClick={handleNewButtonClick}>
                                <i id="sign" className="fa fa-plus"></i> <span id="new">NEW</span>
                            </span>
                            <span className={`btn btn-sm btn-primary ${showContent ? '' : 'd-none'}`} onClick={handleNewButtonClick}>
                                <span id="new">CANCEL</span>
                            </span>

                        </h1>
                        <div className="clearfix"></div>
                        <div className="table-responsive">
                            <div
                                id="example1_wrapper"
                                className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                                <div className="dataTables_length" id="example1_length">
                                    <label>
                                        Show{' '}
                                        <select
                                            name="example1_length"
                                            aria-controls="example1"
                                            className="custom-select custom-select-sm form-control form-control-sm"
                                            fdprocessedid="4sdkam"
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="All">All</option>
                                        </select>{' '}
                                        entries
                                    </label>
                                </div>
                                <div className="dt-buttons">
                                    <a
                                        className="dt-button buttons-copy buttons-html5"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        title="Copy"
                                    >
                                        <span>
                                            <i className="fa fa-files-o text-info font-weight-bold"></i>
                                        </span>
                                    </a>
                                    <a
                                        className="dt-button buttons-excel buttons-html5"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        title="Excel"
                                    >
                                        <span>
                                            <i className="fa fa-file-excel-o text-success font-weight-bold"></i>
                                        </span>
                                    </a>
                                    <a
                                        className="dt-button buttons-pdf buttons-html5"
                                        tabIndex="0"
                                        aria-controls="example1"
                                        title="PDF"
                                    >
                                        <span>
                                            <i className="fa fa-file-pdf-o text-danger font-weight-bold"></i>
                                        </span>
                                    </a>
                                </div>
                                <table
                                    id="example1"
                                    className="table m-0 dataTable no-footer"
                                    role="grid"
                                    aria-describedby="example1_info"
                                >
                                    <thead>
                                        <tr role="row">
                                            <th
                                                className="sorting_desc"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                rowSpan="1"
                                                colSpan="1"
                                                aria-label="#: activate to sort column ascending"
                                                style={{ width: '26.4583px' }}
                                                aria-sort="descending"
                                            >
                                                #
                                            </th>
                                            <th
                                                className="sorting"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                rowSpan="1"
                                                colSpan="1"
                                                aria-label="News: activate to sort column ascending"
                                                style={{ width: '242.333px' }}
                                            >
                                                News
                                            </th>
                                            <th
                                                className="sorting"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                rowSpan="1"
                                                colSpan="1"
                                                aria-label="Active/Deactive: activate to sort column ascending"
                                                style={{ width: '160.479px' }}
                                            >
                                                Active/Deactive
                                            </th>
                                            <th
                                                className="sorting"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                rowSpan="1"
                                                colSpan="1"
                                                aria-label="Notification Type: activate to sort column ascending"
                                                style={{ width: '174.969px' }}
                                            >
                                                Notification Type
                                            </th>
                                            <th
                                                className="sorting"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                rowSpan="1"
                                                colSpan="1"
                                                aria-label="Action: activate to sort column ascending"
                                                style={{ width: '74.7604px' }}
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr role="row" className="odd">
                                            <td className="sorting_1">2</td>
                                            <td>
                                                <p>
                                                    <span style={{ color: '#e74c3c' }}>
                                                        <strong>Welcome to Star Pay</strong>
                                                    </span>
                                                </p>
                                            </td>
                                            <td>
                                                <button className="btn btn-success btn-sm" fdprocessedid="04fxi">
                                                    Active
                                                </button>
                                            </td>
                                            <td>Web</td>
                                            <td>
                                                <Link to="/editnews">
                                                    <i className="fa fa-edit text-primary"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                        <tr role="row" className="even">
                                            <td className="sorting_1">1</td>
                                            <td>
                                                <p>
                                                    <span style={{ color: '#4e5f70' }}>
                                                        <span style={{ fontFamily: 'monospace' }}>
                                                            <span style={{ fontSize: '26px' }}>Welcome to SOFTMINT</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </td>
                                            <td>
                                                <button className="btn btn-success btn-sm" fdprocessedid="qfz6w5">
                                                    Active
                                                </button>
                                            </td>
                                            <td>App</td>
                                            <td>
                                                <Link to="/editnews">
                                                    <i className="fa fa-edit text-primary"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div
                                    className="dataTables_info"
                                    id="example1_info"
                                    role="status"
                                    aria-live="polite"
                                >
                                    Showing 1 to 2 of 2 entries
                                </div>
                                <div className="dataTables_paginate paging_simple_numbers" id="example1_paginate">
                                    <ul className="pagination">
                                        <li className="paginate_button page-item previous disabled" id="example1_previous">
                                            <a aria-controls="example1" data-dt-idx="0" tabIndex="0" className="page-link">
                                                Previous
                                            </a>
                                        </li>
                                        <li className="paginate_button page-item active">
                                            <a aria-controls="example1" data-dt-idx="1" tabIndex="0" className="page-link">
                                                1
                                            </a>
                                        </li>
                                        <li className="paginate_button page-item next disabled" id="example1_next">
                                            <a aria-controls="example1" data-dt-idx="2" tabIndex="0" className="page-link">
                                                Next
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

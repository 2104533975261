import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

export default function Creddebtreport() {

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Credit-Debit Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Report</Link></li>
                                    <li className="breadcrumb-item active">Credit Debit Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <form action="/creddebtreport" method="post">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label>From Date</label>
                                                <input type="date" name="from_date" defaultValue="2023-07-25" className="form-control" required />
                                            </div>
                                            <div className="col-md-3">
                                                <label>To Date</label>
                                                <input type="date" name="to_date" defaultValue="2023-07-25" className="form-control" required />
                                            </div>
                                            <input type="hidden" name="securityToken" value="59afc0da08caf8c6912f78e7db060d17" />
                                            <div className="col-md-3">
                                                <input type="submit" value="Search" className="btn btn-primary" style={{ marginTop: '30px' }} fdprocessedid="n6vrha" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div id='example1_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                                        <div className="dataTables_length" id="example1_length">
                                            <label>
                                                Show{' '}
                                                <select
                                                    name="example1_length"
                                                    aria-controls="example1"
                                                    className="custom-select custom-select-sm form-control form-control-sm"
                                                    fdprocessedid="zlp2sf"
                                                >
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="All">All</option>
                                                </select>{' '}
                                                entries
                                            </label>
                                        </div>
                                        <div className="dt-buttons">
                                            <Link className="dt-button buttons-copy buttons-html5" tabIndex="0" aria-controls="example1" title="Copy">
                                                <span>
                                                    <i className="fa fa-files-o text-info font-weight-bold"></i>
                                                </span>
                                            </Link>
                                            <Link className="dt-button buttons-excel buttons-html5" tabIndex="0" aria-controls="example1" title="Excel">
                                                <span>
                                                    <i className="fa fa-file-excel-o text-success font-weight-bold"></i>
                                                </span>
                                            </Link>
                                            <Link className="dt-button buttons-pdf buttons-html5" tabIndex="0" aria-controls="example1" title="PDF">
                                                <span>
                                                    <i className="fa fa-file-pdf-o text-danger font-weight-bold"></i>
                                                </span>
                                            </Link>
                                        </div>

                                        <table id="example1" className="table m-0 table-striped table-bordered dataTable" style={{ width: '100%' }} role="grid" aria-describedby="example1_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting_asc" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="#: activate to sort column descending" style={{ width: '8.45833px' }}>#</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Transfer From: activate to sort column ascending" style={{ width: '98.8333px' }}>Transfer From</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Transfer To: activate to sort column ascending" style={{ width: '78.5312px' }}>Transfer To</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Amount: activate to sort column ascending" style={{ width: '56.1354px' }}>Amount</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="CR DR: activate to sort column ascending" style={{ width: '42.2917px' }}>CR DR</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Narration: activate to sort column ascending" style={{ width: '68.0833px' }}>Narration</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Date: activate to sort column ascending" style={{ width: '32.4375px' }}>Date</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Is Advance: activate to sort column ascending" style={{ width: '74.3854px' }}>Is Advance</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Status: activate to sort column ascending" style={{ width: '44.7083px' }}>Status</th>
                                                    <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1" colSpan="1" aria-label="Action: activate to sort column ascending" style={{ width: '45.0625px' }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="odd">
                                                    <td valign="top" colSpan="10" className="dataTables_empty">No data available in table</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan="9" style={{ fontWeight: 'bold' }} className="text-primary" rowSpan="1">Row Count 0</td>
                                                </tr>
                                            </tfoot>
                                        </table>

                                        <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                            Showing 0 to 0 of 0 entries
                                        </div>

                                        <div className="dataTables_paginate paging_simple_numbers" id="example1_paginate">
                                            <ul className="pagination">
                                                <li className="paginate_button page-item previous disabled" id="example1_previous">
                                                    <Link aria-controls="example1" data-dt-idx="0" tabIndex="0" className="page-link">Previous</Link>
                                                </li>
                                                <li className="paginate_button page-item next disabled" id="example1_next">
                                                    <Link aria-controls="example1" data-dt-idx="1" tabIndex="0" className="page-link">Next</Link>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

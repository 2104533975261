import React, { useEffect, useState } from "react";
import RestService from "../http";
import Navbar from "./Navbar";
import { FaFileExcel, FaSearch } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Pagination from "./utilityComponents/Pagination";
import formatDate from "./utilityComponents/FormatDate";
import Loader from "./Loader";
import AdminPayout from "./utilityComponents/AdminPayout";
import { ToastContainer } from "react-toastify";
import showToast from "./utilityComponents/APPToast";
import * as XLSX from 'xlsx';
import Select from 'react-select';
import { Link } from "react-router-dom";


export default function AdminPayoutReport() {
    const rest = new RestService();

    const [userId, setUserId] = useState("");
    const [status, setStatus] = useState("");
    const [roles, setRoles] = useState([]);
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());
    const [payoutReport, setPayoutReport] = useState([]);
    const [aepsCurrentRecord, setAepsCurrentRecord] = useState([]);
    const [userList, setUserList] = useState([]);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [nPages, setNPages] = useState(0);
    const [loading, setLoading] = useState(false);


    const handlePayoutReport = () => {
        let raw = {
            userId: userId,
            status: status,
            startDate: formatDate(date),
            endDate: formatDate(date1),
        };

        setLoading(true);

        rest
            .post("yespayout/payoutreport", raw)
            .then((result) => {

                setLoading(false);

                console.log(result);
                if (result.status === rest.SUCCESS) {
                    showToast("SUCCESS", result.message);
                    setPayoutReport(result.data);

                    const pages = Math.ceil(result.data.length / recordsPerPage);
                    setNPages(pages);
                    const lastIndex = currentPage * recordsPerPage;
                    const firstIndex = lastIndex - recordsPerPage;
                    const currentRecords = result.data.slice(firstIndex, lastIndex);
                    setAepsCurrentRecord(currentRecords);
                    console.log("CURRENT RECORD", currentRecords);
                } else {
                    showToast("DANGER", result.message);
                    setPayoutReport([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        handlePayoutReport();
    }, []);

    const handleCustomerType = (e) => {
        console.log(e.target.value);
        rest
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === rest.SUCCESS) {
                    setUserList(result.data);
                } else {
                    showToast("DANGER", result.message);
                    setUserList([]);
                }
            })
            .catch((err) => {
                console.error(err);
                setUserList([]);
            });
    };

    useEffect(() => {
        rest.get("user/roles").then(result => {
            console.log(result);
            if (result.role === undefined) {
                setRoles([])
            } else {
                setRoles(result.role);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const handleCurrentPage = (index) => {
        setCurrentPage(index);
        const lastIndex = index * recordsPerPage;
        const firstIndex = lastIndex - recordsPerPage;
        const currentRecords = payoutReport.slice(firstIndex, lastIndex);
        setAepsCurrentRecord(currentRecords);
        const pages = Math.ceil(payoutReport.length / recordsPerPage);
        setNPages(pages);
    };

    const handleRecordsPerPage = (size, index) => {
        setRecordsPerPage(size);
        setCurrentPage(index);
        const lastIndex = index * recordsPerPage;
        const firstIndex = lastIndex - recordsPerPage;
        const currentRecords = payoutReport.slice(firstIndex, lastIndex);
        setAepsCurrentRecord(currentRecords);
        const pages = Math.ceil(payoutReport.length / recordsPerPage);
        setNPages(pages);
        console.log("CURRENT RECORD", currentRecords);
    };

    const handleDataFilter = (text) => {
        const currentData = payoutReport.filter((record) =>
            record.mode.toLowerCase().includes(text.toLowerCase()) ||
            record.remMobile.toLowerCase().includes(text.toLowerCase()) ||
            record.bankRrn.toLowerCase().includes(text.toLowerCase()) ||
            record.date.toLowerCase().includes(text.toLowerCase()) ||
            record.remName.toLowerCase().includes(text.toLowerCase()) ||
            record.accountNo.toLowerCase().includes(text.toLowerCase()) ||
            record.tranRefNo.toLowerCase().includes(text.toLowerCase()) ||
            record.bankName.toLowerCase().includes(text.toLowerCase()) ||
            record.beneId.toLowerCase().includes(text.toLowerCase())
        );

        const lastIndex = currentPage * recordsPerPage;
        const firstIndex = lastIndex - recordsPerPage;
        const currentRecords = currentData.slice(firstIndex, lastIndex);
        setAepsCurrentRecord(currentRecords);
    };

    const downloadExcel = () => {
        // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
        // writeFile(wb, 'recharge_report.xlsx');

        const ws = XLSX.utils.json_to_sheet(payoutReport);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Payout Report');

        XLSX.writeFile(wb, 'payout_report.xlsx');
    };


    const [selectedUserId, setSelectedUserId] = useState(null);

    const options = userList.map((item) => ({
        value: item.userId,
        label: item.name,
    }));

    const handleSelectChange = (selectedUserId) => {
        setSelectedUserId(selectedUserId);
        setUserId(selectedUserId.value);
    };


    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "570.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">
                                    Payout Report &nbsp;

                                </h1>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Payout</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Payout Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label for="rec_to_load"> Start Date</label><br />
                                            <DatePicker
                                                className="custom-date-picker"
                                                name="string"
                                                fdprocessedid="9n5i4u"
                                                selected={date}
                                                dateFormat={"yyyy-MM-dd"}
                                                onChange={(date) => setDate(date)}
                                                showYearDropdown
                                                showMonthDropdown
                                            />
                                        </div>

                                        <div className="col-md-3">
                                            <label for="rec_to_load"> End Date</label>
                                            <br />
                                            <DatePicker
                                                className="custom-date-picker"
                                                name="string"
                                                disabledDayAriaLabelPrefix="#$"
                                                fdprocessedid="9n5i4u"
                                                selected={date1}
                                                dateFormat={"yyyy-MM-dd"}
                                                onChange={(date) => setDate1(date)}
                                                showYearDropdown
                                                showMonthDropdown
                                            />
                                        </div>

                                        <div className="col-md-3">
                                            <label>
                                                Customer Type <span className="text-danger"> *</span>
                                            </label>

                                            <select
                                                className="custom-date-picker"
                                                name="customer_type"
                                                id="customer_type"
                                                fdprocessedid="g30y5e"
                                                onChange={handleCustomerType}
                                            >
                                                <option value="">SELECT CUSTOMER TYPE</option>
                                                {roles.map((role) => (
                                                    <option key={role.roleId} value={role.roleId}>
                                                        {role.roleName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="col-md-3">
                                            <label>
                                                User <span className="text-danger"> *</span>
                                            </label>

                                            {/* <select
                                                className="custom-date-picker"
                                                name="customer_type"
                                                id="customer_type"
                                                fdprocessedid="g30y5e"
                                                onChange={(e) => setUserId(e.target.value)}
                                            >
                                                <option value={""}>SELECT USER</option>
                                                {userList.map((user) => (
                                                    <option key={user.userId} value={user.userId}>
                                                        {user.name} | {user.mobile}| {user.userId}
                                                    </option>
                                                ))}
                                            </select> */}

                                            <Select
                                                className="select-box width100p"
                                                options={options}
                                                isSearchable
                                                value={selectedUserId}
                                                onChange={handleSelectChange}
                                            />
                                        </div>

                                        <div className="col-md-3 mt-2">
                                            <label>
                                                Status <span className="text-danger"> *</span>
                                            </label>

                                            <select
                                                className="custom-date-picker"
                                                name="customer_type"
                                                id="customer_type"
                                                fdprocessedid="g30y5e"
                                                onChange={(e) => setStatus(e.target.value)}
                                            >
                                                <option value={""}>SELECT STATUS</option>
                                                <option value={"SUCCESS"}>SUCCESS</option>
                                                <option value={"FAILED"}>FAILED</option>
                                                <option value={"PENDING"}>PENDING</option>
                                            </select>
                                        </div>

                                        <div className="col-md-3" style={{ marginTop: "40px" }}>
                                            <button
                                                className="btn btn-primary "
                                                fdprocessedid="fl0v7"
                                                onClick={handlePayoutReport}
                                            >
                                                <FaSearch className="fas fa-search" /> Search
                                            </button>
                                            &nbsp;
                                            <button type="button" name="" className="btn btn-success" onClick={downloadExcel} fdprocessedid="9svl7o">
                                                <FaFileExcel className="fa fa-file-excel-o" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                <div className="table-responsive">
                                    <Pagination
                                        nPages={nPages}
                                        currentPage={currentPage}
                                        setCurrentPage={handleCurrentPage}
                                        setPageSize={handleRecordsPerPage}
                                        setFilterData={handleDataFilter}
                                    />
                                    <AdminPayout
                                        data={aepsCurrentRecord}
                                        allRecord={payoutReport}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    );
}

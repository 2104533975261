import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

export default function Contactinfo() {


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '203.667px' }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Contact Information</h1>
                            </div>
                            {/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Support</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Contact Information</li>
                                </ol>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <h1 className="clearfix">
                                    <Link to="/editcontact" className="text-dark btn float-right btn-sm btn-primary text-white">
                                        <i className="fa fa-edit"> Edit</i>
                                    </Link>
                                </h1>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <p className="text-primary"><b>Contact Support</b></p>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <p style={{ fontSize: '18px' }}>
                                                <i className="fa fa-mobile text-danger" aria-hidden="true"></i> Customer Support : 91922457845
                                            </p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <p style={{ fontSize: '18px' }}>
                                                <i className="fa fa-mobile text-danger" aria-hidden="true"></i> Sales Support : 91922457845
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <p className="text-primary"><b>Whatsapp Support</b></p>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <p style={{ fontSize: '18px' }}>
                                                <i className="fab fa-whatsapp text-success"></i> Whats app Support : 91922457845
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <p className="text-primary"><b>Email Support</b></p>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <p style={{ fontSize: '18px' }}>
                                                <i className="fa fa-envelope text-warning" aria-hidden="true"></i> Support Email : softmint@star.pay
                                            </p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <p style={{ fontSize: '18px' }}>
                                                <i className="fa fa-envelope text-warning" aria-hidden="true"></i> Sales Email : softmint@gmail.com
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <p className="text-primary"><b>Company Address</b></p>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <p style={{ fontSize: '18px' }}>
                                                <i className="fa fa-map-marker text-success" aria-hidden="true"></i> Address : Ahmednagar, MH 414505
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import RestService from "../http";
import APPConstant from '../constants'
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from './Loader';
import DatePicker from "react-datepicker";
import formatDate from './utilityComponents/FormatDate';
import showToast from './utilityComponents/APPToast';
import { Link } from 'react-router-dom';


export default function Expance() {

    const service = new RestService();
    const constant = new APPConstant();

    const [showModal2, setShowModal2] = useState(false);
    const [data, setData] = useState('');
    const [serviceId, setServiceId] = useState('');
    const [status, setStatus] = useState(false);
    const [serviceList, setServiceList] = useState([])
    const navigate = useNavigate();
    const [showAddService, setShowAddService] = useState(false);
    const [showAddProvider, setShowAddProvider] = useState(false);
    const [expense, setExpense] = useState("");
    const [loading, setLoading] = useState(false);
    const [expenseType, setExpenseType] = useState("");
    const [ExpenseTypeList, setExpenseTypeList] = useState([]);
    const [amount, setAmount] = useState("");
    const [description, setDescription] = useState("");
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());
    const [expenseReport, setExpenseReport] = useState([]);



    const handleInputChange2 = (event) => {
        setData(event.target.value);
    };

    const handleServiceType = (event) => {
        setServiceId(event.target.value);
    };

    const handleServiceStatus = (event) => {
        setStatus(event.target.value);
    };

    const handleCloseModal2 = () => {
        setShowModal2(false);
    };


    const editService = () => {
        var raw = {
            "serviceId": serviceId,
            // "serviceName": data,
            "status": status
        };

        service.post("service/updateService", raw).then(result => {
            // console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);

                setTimeout(() => {
                    window.location.reload();
                }, 500);

            } else {
                showToast("FAILED", result.message);

            }
        })
    }

    const handleNewServiceClick = () => {
        setShowAddService(true);
        setShowAddProvider(false);
    };

    const handleNewProvider = () => {
        setShowAddProvider(true);
        setShowAddService(false);
    };


    const handleAddExpense = () => {

        service.post(`expance/addExapnce/${expense}`, null).then(result => {
            console.log(result);

            if (result === undefined) {
                showToast("DANGER", result.message);

            } else {
                if (result.status === constant.FAILED || result.status === constant.SOMETHING_WENT_WRONG) {
                    showToast("DANGER", result.message);

                } else {
                    showToast("SUCCESS", result.message);
                    handleExpenseReport();
                }
            }
        }).catch(error => {
            console.log(error);
            showToast("DANGER", error);
        })
    };


    const handleAddProvider = () => {
        var raw = {
            "expanceType": expenseType,
            "amount": amount,
            "description": description
        }

        service.post("expance/addExpanceReport", raw).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                showToast("DANGER", result.message);

            }
        }).catch(error => {
            console.log(error);
            showToast("DANGER", error);
        })
    }

    useEffect(() => {
        service.post("expance/fetchAllExpance", null).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                setExpenseTypeList(result.data)
            } else {
                setExpenseTypeList([])
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [])


    const handleExpenseReport = () => {

        var raw = {
            "fromDate": formatDate(date),
            "toDate": formatDate(date1)
        }

        service.post("expance/fetchExpanceReport", raw).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                setExpenseReport(result.data);
            } else {
                showToast("DANGER", result.message)
                setExpenseReport([]);
            }
        })
    }

    useEffect(() => {
        handleExpenseReport()
    }, [])

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}


            <div className="content-wrapper" style={{ minHeight: '532.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">
                                    Services&nbsp;

                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Settings</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Service</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <style>
                    {`
          tr, td {
            vertical-align: middle !important;
          }
        `}
                </style>
                <section className="content">
                    <div className="container-fluid">

                        {showAddService && (
                            <div className="card" id="addservice">
                                <div
                                    className="card-header text-primary"
                                    style={{ fontWeight: "bold" }}
                                >
                                    Add Expense:
                                </div>
                                <div className="card-body">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label htmlFor="service">
                                                    Service Name:{" "}
                                                    <span className="text-danger"> *</span>
                                                </label>
                                            </div>

                                            <div className="col-md-3">
                                                <input
                                                    type="text"
                                                    name="expense"
                                                    onChange={(e) => setExpense(e.target.value)}
                                                    maxLength="100"
                                                    autoFocus
                                                    required
                                                    className="form-control"
                                                    id="service"
                                                    placeholder="Expense Name"
                                                />
                                                <div id="err"></div>
                                            </div>

                                            <div className="col-md-3">
                                                <button
                                                    className="btn btn-primary btn-hover text-white"
                                                    onClick={handleAddExpense}
                                                >
                                                    ADD EXPENSE
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                        {showAddProvider && (
                            <div className="card" id="addservice">
                                <div
                                    className="card-header text-primary"
                                    style={{ fontWeight: "bold" }}
                                >
                                    Add Expense:
                                </div>
                                <div className="card-body">
                                    <div>
                                        <div className="row">

                                            <div className="col-md-3">
                                                <label>
                                                    Expense Type <span className="text-danger"> *</span>
                                                </label>

                                                <select
                                                    className="form-control"
                                                    name="customer_type"
                                                    id="customer_type"
                                                    fdprocessedid="g30y5e"
                                                    onChange={(e) => setExpenseType(e.target.value)}
                                                >
                                                    <option value="">SELECT EXPENSE TYPE</option>
                                                    {ExpenseTypeList.map((type) => (
                                                        <option value={type.expance}>{type.expance}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3">
                                                <label>
                                                    Amount <span className="text-danger"> *</span>
                                                </label>

                                                <input
                                                    type="text"
                                                    name="service"
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    maxLength="5"
                                                    autoFocus
                                                    required
                                                    className="form-control"
                                                    id="service"
                                                    placeholder="Service Name"
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label>
                                                    Description <span className="text-danger"> *</span>
                                                </label>

                                                <input
                                                    type="text"
                                                    name="service"
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    autoFocus
                                                    required
                                                    className="form-control"
                                                    id="service"
                                                    placeholder="Service Name"
                                                />
                                            </div>

                                            <div className="col-md-3" style={{ marginTop: "28px" }}>
                                                <button
                                                    className="btn btn-primary btn-hover text-white"
                                                    onClick={handleAddProvider}
                                                >
                                                    Add Report
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="card">

                            <h1 className="m-0 text-dark float-right">
                                <span
                                    className="btn mr-2 btn-sm btn-primary float-right mt-2"
                                    onClick={handleNewServiceClick}
                                >
                                    <i id="sign2" className="fa fa-plus"></i>{" "}
                                    <span id="new2">ADD EXPENSE</span>
                                </span>
                            </h1>

                            <h1 className="m-0 text-dark float-right">
                                <span
                                    className="btn mr-2 btn-sm btn-primary float-right mt-2"
                                    onClick={handleNewProvider}
                                >
                                    <i id="sign2" className="fa fa-plus"></i>{" "}
                                    <span id="new2">ADD EXPENSE REPORT</span>
                                </span>
                            </h1>


                            <section className="content mt-4">
                                <div className="container-fluid">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <label for="rec_to_load"> Start Date</label><br />
                                                            <DatePicker
                                                                className="custom-date-picker"
                                                                name="string"
                                                                fdprocessedid="9n5i4u"
                                                                selected={date}
                                                                dateFormat={"yyyy-MM-dd"}
                                                                onChange={(date) => setDate(date)}
                                                                showYearDropdown
                                                                showMonthDropdown
                                                            />
                                                        </div>

                                                        <div className="col-md-3">
                                                            <label for="rec_to_load"> End Date</label>
                                                            <br />
                                                            <DatePicker
                                                                className="custom-date-picker"
                                                                name="string"
                                                                disabledDayAriaLabelPrefix="#$"
                                                                fdprocessedid="9n5i4u"
                                                                selected={date1}
                                                                dateFormat={"yyyy-MM-dd"}
                                                                onChange={(date) => setDate1(date)}
                                                                showYearDropdown
                                                                showMonthDropdown
                                                            />
                                                        </div>

                                                        <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />
                                                        <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ marginTop: '10px', textAlign: 'center' }}>
                                                            <button
                                                                className="btn btn-primary btn-block"
                                                                style={{ marginTop: '20px' }}
                                                                onClick={handleExpenseReport}
                                                            >
                                                                SEARCH
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <div className="card-body">
                                <div className="table-responsive">
                                    <table id="" className="table table-bordered table-striped">
                                        <thead style={{background:"blue"}}>
                                            <tr className='text-center'>
                                                <th className='text-white'>ID</th>
                                                <th className='text-white'>Expense Type</th>
                                                <th className='text-white'>Amount</th>
                                                <th className='text-white'>Date</th>
                                                <th className='text-white'>Time</th>
                                                <th className='text-white'>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {expenseReport.length > 0 ? expenseReport.map((service, index) => (
                                                <tr className='text-center'>
                                                    <td>{index + 1}</td>
                                                    <td>{service.expanceType}</td>
                                                    <td className="text-center">{service.amount}</td>
                                                    <td className="text-center">{service.date}</td>
                                                    <td className="text-center">{service.time}</td>
                                                    <td>{service.description}</td>
                                                </tr>
                                            )) :
                                                <td colSpan={6} className='text-center'>
                                                    NO DATA AVAILABLE
                                                </td>}
                                        </tbody>

                                        <tfoot>
                                            <tr className='text-center'>
                                                <td colSpan={2} style={{color:"green", fontWeight:"bold"}}>Total Amt.</td>
                                                <td className='text-center' style={{color:"brown", fontWeight:"bold"}}>
                                                   {expenseReport.length > 0 ? expenseReport.reduce((total, service) => total + parseFloat(service.amount), 0).toFixed(2) : '0.00'}
                                                </td>
                                                <td colSpan={3}></td>
                                            </tr>
                                        </tfoot>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {showModal2 && (
                    <div className="modal fade show" id="serviceUpdate" tabIndex="-1" role="dialog" aria-labelledby="serviceUpdateTitle" style={{ display: 'block' }} aria-modal="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div>
                                    <ToastContainer />
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLongTitle">Edit Service</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal2}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <input type="hidden" name="action_type" id="action_type" value="1" />

                                            <div className="mt-2 col-md-4">
                                                <label>Service Name</label>
                                                <input
                                                    type="text"
                                                    value={data}
                                                    name="service"
                                                    onChange={handleInputChange2}
                                                    className="form-control clear"
                                                    required
                                                />
                                            </div>

                                            <div className="mt-2 col-md-4">
                                                <label>Service Id</label>
                                                <input
                                                    type="text"
                                                    value={serviceId}
                                                    name="service"
                                                    onChange={handleServiceType}
                                                    className="form-control clear"
                                                    required
                                                />
                                            </div>

                                            <div className="mt-2 col-md-4">
                                                <label>Status</label>
                                                <select
                                                    value={status}
                                                    name="status"
                                                    onChange={handleServiceStatus}
                                                    className="form-control"
                                                    required
                                                >
                                                    <option value={true}>ACTIVE</option>
                                                    <option value={false}>INACTIVE</option>
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" fdprocessedid="7wkuky" onClick={handleCloseModal2}>Close</button>
                                        <button type="submit" className="btn btn-primary" fdprocessedid="w927w4" onClick={editService}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}; 

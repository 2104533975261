import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import RestService from "../http";
import { FaFileExcel } from "react-icons/fa";
import formatDate from "./utilityComponents/FormatDate";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Pagination2 from './utilityComponents/Pagination2';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';
import * as XLSX from 'xlsx';
import Select from 'react-select';
import { Link } from 'react-router-dom';


export default function SuspisiousFroudReport() {

  const [selectedOption] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const dropDownRef = useRef();
  const [fraudReport, setFraudReport] = useState([]);
  const [date, setDate] = useState(new Date());
  const [date1, setDate1] = useState(new Date());
  const [userList, setUserList] = useState([]);
  const [userId, setUserId] = useState("");
  const [roles, setRoles] = useState([]);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [selectedOption]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);


  useEffect(() => {
    if (selectedAction) {
      console.log(`Selected Action: ${selectedAction}`);
      setSelectedAction(null);
    }
  }, [selectedAction]);


  const service = new RestService();

  const handleFraudReport = () => {
    var raw = {
      "startDate": formatDate(date),
      "endDate": formatDate(date1),
      "userId": userId
    };

    setLoading(true);

    service.post("yes/suspeousfraudreport", raw).then(result => {
      setLoading(false);

      console.log(result);
      if (result.status === service.SUCCESS) {
        setFraudReport(result.data);
      } else {
        showToast("DANGER", result.message);
        setFraudReport([]);
      }
    }).catch((err) => {
      console.error(err);
    });
  };

  useEffect(() => {
    handleFraudReport();
  }, []);

  useEffect(() => {
    service.get("user/roles").then(result => {
      console.log(result);
      setRoles(result.role);

    }).catch((error) => {
      console.log(error);
    });
  }, []);

  const handleCustomerType = (e) => {
    console.log(e.target.value);
    service
      .post(`user/getUpline/${e.target.value}`, null)
      .then((result) => {
        console.log(result);
        if (result.status === service.SUCCESS) {
          setUserList(result.data);
        } else {
          setUserList([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setUserList([]);
      });
  };


  const [filteredFraudReport, setFilteredFraudReport] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  const totalTransactionAmount = filteredFraudReport
    .filter(item => item.status === 'SUCCESS')
    .reduce((total, item) => total + parseFloat(item.transactionAmount || 0), 0);


  useEffect(() => {
    const filteredList = fraudReport.filter(item =>
      item.custmobile.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.transactionAmount.toString().includes(searchInput.toLowerCase()) ||
      item.narration.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.date.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.userId.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.iin.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.aadhar.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.agentcode.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.bankRRN.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.transactionType.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.terminalId.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.merchantTxnId.toLowerCase().includes(searchInput.toLowerCase())

    );
    setFilteredFraudReport(filteredList);
  }, [fraudReport, searchInput]);

  const paginatedFraudReport = filteredFraudReport.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );


  const downloadExcel = () => {
    // const wb = XLSXUtils.table_to_book(document.querySelector('.table-responsive table'), {sheet: "Sheet JS"});
    // writeFile(wb, 'recharge_report.xlsx');

    const ws = XLSX.utils.json_to_sheet(fraudReport);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Fraud Report');

    XLSX.writeFile(wb, 'fraud_report.xlsx');
  };

  const [selectedUserId, setSelectedUserId] = useState(null);

  const options = [
    { value: '', label: 'Select an option' },
    ...userList.map((item) => ({
      value: item.userId,
      label: `${item.name} || ${item.mobile}`,
    }))
  ];

  const handleSelectChange = (selectedUserId) => {
    setSelectedUserId(selectedUserId);
    setUserId(selectedUserId.value);
  };


  return (
    <>
      <Navbar />
      <ToastContainer />
      {loading && <Loader />}

      <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Suspicious Fraud Report</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Report</Link>
                  </li>
                  <li className="breadcrumb-item active">Suspiciouis Fraud Report</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div>
                    <div className="row">
                      <div className="col-md-3">
                        <label for="rec_to_load"> Start Date</label><br />
                        <DatePicker
                          className="custom-date-picker"
                          name="string"
                          fdprocessedid="9n5i4u"
                          selected={date}
                          dateFormat={"yyyy-MM-dd"}
                          onChange={(date) => setDate(date)}
                          showYearDropdown
                          showMonthDropdown
                        />
                      </div>

                      <div className="col-md-3">
                        <label for="rec_to_load"> End Date</label>
                        <br />
                        <DatePicker
                          className="custom-date-picker"
                          name="string"
                          disabledDayAriaLabelPrefix="#$"
                          fdprocessedid="9n5i4u"
                          selected={date1}
                          dateFormat={"yyyy-MM-dd"}
                          onChange={(date) => setDate1(date)}
                          showYearDropdown
                          showMonthDropdown
                        />
                      </div>

                      <div className="col-md-3">
                        <label for="assign_commission">
                          User Type: <span className="text-danger"> *</span>
                        </label>
                        <select
                          className="form-control select2 "
                          required=""
                          id="service_id"
                          data-select2-id="service_id"
                          tabindex="-1"
                          aria-hidden="true"
                          onChange={handleCustomerType}
                        >
                          <option>Select User Type</option>
                          {roles.map((item) => (
                            item.roleId !== 0 ? <option value={item.roleId}>{item.roleName}</option> : null
                          ))}
                        </select>
                      </div>

                      <div className="col-md-3" data-select2-id="4">
                        <label for="service_id">
                          User Id: <span className="text-danger"> *</span>
                        </label>
                        {/* <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setUserId(e.target.value)}
                                                >
                                                    <option value="">Select Id...</option>
                                                    {userList.map((item) => (
                                                        <option value={item.userId}>{item.name}</option>
                                                    ))}
                                                </select> */}

                        <Select
                          className="select-box width100p"
                          options={options}
                          isSearchable
                          value={selectedUserId}
                          onChange={handleSelectChange}
                        />
                      </div>

                      <input type="hidden" name="securityToken" value="89ed35503c7785288ca5ca589db062c8" />

                      <div className="col-md-12" style={{ textAlign: 'center' }}>
                        <button
                          onClick={handleFraudReport}
                          className="btn btn-primary btn-block text-center"
                          style={{ marginTop: '37px', color: 'white', fontWeight: 600 }}
                          fdprocessedid="qnf2"
                        >
                          Search
                        </button>
                        &nbsp;&nbsp;

                        <button type="button" name="" className="btn btn-success" style={{ marginTop: "3%" }} onClick={downloadExcel} fdprocessedid="9svl7o">
                          <FaFileExcel className="fa fa-file-excel-o" />
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">

                <center>
                  <div className="box-content" style={{ padding: "0", margin: "0" }}>
                    <div>

                      <div className="col-md-3" style={{ float: "left" }}>
                        <div>
                          <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                          <input
                            className="form-control text-box"
                            type="text"
                            placeholder="SEARCH..."
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}

                          />
                        </div>
                      </div><br />

                      <Pagination2
                        totalPages={Math.ceil(fraudReport.length / itemsPerPage)}
                        currentPage={currentPage}
                        onChange={handlePageChange}

                      />
                    </div>
                  </div>
                </center>

                <div className="table-responsive">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr className="wrap-text text-center">
                        <th className="px-1">SL.NO</th>
                        {/* <th>USER NAME</th> */}
                        <th>TERMINAL ID</th>
                        <th>MOBILE</th>
                        <th>AGENT CODE</th>
                        <th>TRANSACTION TYPE</th>
                        <th>TRANSACTION AMT.</th>
                        <th>BALANCE AMT.</th>
                        <th>MERCHANT TXN. ID</th>
                        <th>BANK RRN</th>
                        <th>AGENT CODE</th>
                        <th>AADHAR</th>
                        <th>IIN</th>
                        <th>USER ID</th>
                        <th>DATE</th>
                        <th>TIME</th>
                        <th>NARRATION</th>
                        <th>RESPONSE</th>
                        <th>STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(!paginatedFraudReport || paginatedFraudReport.length === 0) ? (
                        <tr className='text-center'>
                          <td colSpan="18">NO DATA AVAILABLE</td>
                        </tr>
                      ) : (
                        paginatedFraudReport.map((item, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            {/* <td>{item.}</td> */}
                            <td>{item.terminalId}</td>
                            <td>{item.custmobile}</td>
                            <td>{item.agentcode}</td>
                            <td>{item.transactionType}</td>
                            <td>{item.transactionAmount}</td>
                            <td>{item.balanceAmount}</td>
                            <td>{item.merchantTxnId}</td>
                            <td>{item.bankRRN}</td>
                            <td>{item.agentcode}</td>
                            <td>{item.aadhar}</td>
                            <td>{item.iin}</td>
                            <td>{item.userId}</td>
                            <td>{item.date}</td>
                            <td>{item.time}</td>
                            <td>{item.narration}</td>
                            <td>{item.responseCode}</td>
                            <td style={{ color: item.status === "SUCCESS" ? 'green' : (item.status === "PENDING" ? 'orange' : 'red'), fontWeight: "bold" }}>{item.status}</td>
                          </tr>
                        ))
                      )}
                    </tbody>

                    <tfoot>
                      <tr>
                        <td colSpan="23" style={{ textAlign: "center", color: "green", fontWeight: "bold" }}>
                          Total Transaction Amount: {totalTransactionAmount}
                        </td>
                      </tr>
                    </tfoot>

                  </table>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>


      <footer className="main-footer" >
        <strong>
          Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
        </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 4.5.0
        </div>
      </footer>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

function NsdlForm() {

    const [data, setData] = useState();
    const [url, setUrl] = useState();

    //   useEffect(() => {
    //     const urlParams = new URLSearchParams(window.location.search);
    //     const encodedData = urlParams.get('data');
    //     const decodedData = decodeURIComponent(encodedData || '');
    //     const base64Decoded = atob(decodedData);
    //     console.log(JSON.parse(base64Decoded));
    //   }, []);


    useEffect(() => {
       const storage = sessionStorage.getItem("formData");
       const requestUrl = sessionStorage.getItem("url");

       console.log(storage);
       setData(storage);
       setUrl(requestUrl);
    }, []);

    return (

        <div className='card-body'>
            <center>
                <form
                    id="panForm"
                    name="panForm"
                    action= {url}
                    method="post"
                >
                    <input
                        type="hidden"
                        name="req"
                        id="req"
                        value={`${data}`}
                    />

                    <div className='container bg-pink mt-3' style={{ borderRadius: "10px", padding: "2%" }}>
                        <h1>Confirmation Message</h1>

                        <div className='container bg-light' style={{ borderRadius: "10px", padding: "5%" }}>
                            <h4 style={{ display: "flex", justifyContent: "center" }}>NSDL SERVICE IS CHARGEBLE</h4>
                            <h4>ARE YOU SURE TO APPLY PAN IN NSDL?</h4>

                            {/* <h3><font color="red">{{ errorMessgae }}</font></h3> */}

                            <div className='mt-3' style={{ display: "flex", justifyContent: "center" }}>
                                <button type="submit" className="btn btn-primary">YES</button>&nbsp;
                                <Link to={'/nsdlpan'} className="btn btn-danger">NO</Link>
                            </div>
                        </div>
                    </div>

                </form>
            </center>
        </div>
    )
}

export default NsdlForm
import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router';
import { FaFileExcel, FaFilePdf, FaCopy } from "react-icons/fa";
import { Link } from 'react-router-dom';


export default function Fundtrans() {

    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const dropDownRef = useRef();
    const [data, setData] = useState([]);

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };


    const handleToDateChange = (date) => {
        setToDate(date);
    };

    const options = [
        { value: '', label: 'AJOY MONDAL [MD1003][9609236025]' },
        { value: '1', label: 'ARUL DOSS [MD1002][7904634883]' },
        { value: '2', label: 'NAVNATH SASE [RT1001][8600707581]' },
        { value: '3', label: 'Sharad Bandarkar [MA1000][8600436163]' },

    ];

    let memoizedData;

    const fetchData = async () => {
        // If memoizedData is already set, return it immediately
        if (memoizedData) {
            return memoizedData;
        }

        var token = '';
        if (sessionStorage && sessionStorage.token) {
            token = 'Bearer ' + sessionStorage.token;
        } else {
            navigate({
                pathname: "/"
            });
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("user");
            return null; // Return null or some other default value if the token is not available
        }

        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            const response = await fetch("https://m.softmintindia.com/user/getallUser", requestOptions);
            const result = await response.json();
            console.log(result.List);
            setData(result.List);

            // Store the result in memoizedData
            memoizedData = result.List;

            return result.List;
        } catch (error) {
            console.log('error', error);
            return null; // Return null or some other default value if an error occurs
        }
    };


    // Call the fetchData function
    useEffect(() => {
        fetchData();
    }, []);


    const handleOptionSelect = (option) => {
        setSelectedOption(option.mobile);
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "630.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">
                                    Fund Transfer{' '}
                                    <span>
                                        <Link to="/transfered" className="btn btn-sm btn-primary">
                                            <i className="fa fa-paper-plane" aria-hidden="true"></i> Transfer
                                        </Link>
                                    </span>
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Wallet</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Fund Transfer</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <form action="/fundtrans" method="post">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>Select Member</label>
                                                <select
                                                    name="customer_id"
                                                    className="form-control select2 select2-hidden-accessible"
                                                    id="customer_list"
                                                    tabIndex="-1"
                                                    aria-hidden="true"
                                                >
                                                    <option value="">Select Member</option>
                                                </select>
                                                <div
                                                    className="select2 select2-container select2-container--default"
                                                    dir="ltr"
                                                    data-select2-id="3"
                                                    style={{ width: '200.75px' }}
                                                >
                                                    <div
                                                        ref={dropDownRef}
                                                        className={`select2 select2-container select2-container--default ${isDropdownOpen ? 'select2-container--open' : ''
                                                            }`}
                                                        dir="ltr"
                                                        data-select2-id="2"
                                                        style={{ width: '220.625px' }}
                                                        onClick={toggleDropdown}
                                                    >
                                                        <span className="selection">
                                                            <span
                                                                className={`select2-selection select2-selection--single ${isDropdownOpen ? 'select2-selection--active' : ''
                                                                    }`}

                                                                role="combobox"
                                                                aria-haspopup="true"
                                                                aria-expanded={isDropdownOpen}
                                                                tabIndex="0"
                                                                aria-disabled="false"
                                                                aria-labelledby="select2-package_id-24-container"
                                                            >
                                                                <span
                                                                    className="select2-selection__rendered"
                                                                    id="select2-package_id-24-container"
                                                                    role="textbox"
                                                                    aria-readonly="true"
                                                                    title={selectedOption ? data.find((option) => option.mobile === selectedOption).mobile : 'Select Member'}
                                                                >
                                                                    {selectedOption ? data.find((option) => option.mobile === selectedOption).mobile : 'Select Member'}
                                                                </span>

                                                                <span className="select2-selection__arrow" role="presentation">
                                                                    <b role="presentation"></b>
                                                                </span>
                                                            </span>
                                                        </span>

                                                        {isDropdownOpen && (
                                                            <span className="dropdown-wrapper" style={{ position: 'absolute' }}>
                                                                <span className="select2-dropdown select2-dropdown--below" dir="ltr" style={{ width: '220.625px' }}>
                                                                    <span className="select2-search select2-search--dropdown">
                                                                        <input
                                                                            className="select2-search__field"
                                                                            type="search"
                                                                            tabIndex="0"
                                                                            autoComplete="off"
                                                                            autoCorrect="off"
                                                                            autoCapitalize="none"
                                                                            spellCheck="false"
                                                                            role="searchbox"
                                                                            aria-autocomplete="list"
                                                                            aria-controls="select2-package_id-ci-results"
                                                                        />
                                                                    </span>
                                                                    <span className="select2-results">
                                                                        <ul className="select2-results__options" role="listbox" id="select2-package_id-ci-results" aria-expanded="true" aria-hidden="false">
                                                                            {data.map((option) => (
                                                                                <li
                                                                                    key={option.mobile}
                                                                                    className={`select2-results__option ${option.value === selectedOption ? 'select2-results__option--highlighted' : ''}`}
                                                                                    role="option"
                                                                                    aria-selected={option.mobile === selectedOption}
                                                                                    data-select2-id={`select2-package_id-f9-result-${option.value}`}
                                                                                    onClick={() => handleOptionSelect(option)}
                                                                                >
                                                                                    {option.name + '[' + option.userName + '][' + option.mobile + ']'}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        )}

                                                    </div>

                                                </div>

                                            </div>

                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>From Date</label><br />
                                                <DatePicker
                                                    selected={selectedDate}
                                                    onChange={handleDateChange}
                                                    dateFormat="yyyy-MM-dd" //m Specify the desired format
                                                    className="form-control"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>
                                            
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12" style={{ marginTop: '10px' }}>
                                                <label>To Date</label><br />
                                                <DatePicker
                                                    selected={toDate}
                                                    onChange={handleToDateChange}
                                                    dateFormat="yyyy-MM-dd"
                                                    className="form-control"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>
                                            <input type="hidden" name="securityToken" value="09c6e12d98fcd1f3605e14c4d6ddcfa7" />
                                            <div className="col-lg-2 col-md-3 col-sm-12 col-12" style={{ marginTop: '10px', textAlign: 'center' }}>
                                                <input
                                                    type="submit"
                                                    name=""
                                                    value="Search"
                                                    className="btn btn-primary"
                                                    style={{ marginTop: '30px' }}
                                                    fdprocessedid="07ksbn"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 table-responsive">
                                    <div
                                        id="example1_wrapper"
                                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                                    >
                                        <div className="dataTables_length" id="example1_length">
                                            <label>
                                                Show{' '}
                                                <select
                                                    name="example1_length"
                                                    aria-controls="example1"
                                                    className="custom-select custom-select-sm form-control form-control-sm"
                                                    fdprocessedid="s98bu"
                                                >
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="All">All</option>
                                                </select>{' '}
                                                entries
                                            </label>
                                        </div>
                                        <div className="dt-buttons">
                                            <a
                                                className="dt-button buttons-copy buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="Copy"
                                            >
                                                 <span>
                                                    <FaCopy className="fa fa-files-o text-info font-weight-bold"/>
                                                </span>
                                            </a>
                                            <a
                                                className="dt-button buttons-excel buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="Excel"
                                            >
                                                <span>
                                                    <FaFileExcel className="fa fa-file-excel-o text-success font-weight-bold" />
                                                </span>
                                            </a>
                                            <a
                                                className="dt-button buttons-pdf buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="PDF"
                                            >
                                                <span>
                                                    <FaFilePdf className="fa fa-file-pdf-o text-danger font-weight-bold"/>
                                                </span>
                                            </a>
                                        </div>
                                        <table
                                            id="example1"
                                            className="table m-0 table-striped table-bordered dataTable no-footer"
                                            role="grid"
                                            aria-describedby="example1_info"
                                        >
                                            <thead>
                                                <tr role="row">
                                                    <th
                                                        className="sorting_asc"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-sort="ascending"
                                                        aria-label="#: activate to sort column descending"
                                                        style={{ width: '8.45833px' }}
                                                    >
                                                        #
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Transfer From: activate to sort column ascending"
                                                        style={{ width: '98.8333px' }}
                                                    >
                                                        Transfer From
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Transfer To: activate to sort column ascending"
                                                        style={{ width: '78.5312px' }}
                                                    >
                                                        Transfer To
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Amount: activate to sort column ascending"
                                                        style={{ width: '56.1354px' }}
                                                    >
                                                        Amount
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Debit/Credit: activate to sort column ascending"
                                                        style={{ width: '86.8854px' }}
                                                    >
                                                        Debit/Credit
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Narration: activate to sort column ascending"
                                                        style={{ width: '68.0833px' }}
                                                    >
                                                        Narration
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Transfer Date: activate to sort column ascending"
                                                        style={{ width: '94.4062px' }}
                                                    >
                                                        Transfer Date
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Advance Payment: activate to sort column ascending"
                                                        style={{ width: '125.521px' }}
                                                    >
                                                        Advance Payment
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Payment Status: activate to sort column ascending"
                                                        style={{ width: '110.948px' }}
                                                    >
                                                        Payment Status
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="odd">
                                                    <td valign="top" colSpan="9" className="dataTables_empty">
                                                        No data available in table
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                            Showing 0 to 0 of 0 entries
                                        </div>
                                        <div className="dataTables_paginate paging_simple_numbers" id="example1_paginate">
                                            <ul className="pagination">
                                                <li className="paginate_button page-item previous disabled" id="example1_previous">
                                                    <Link aria-controls="example1" data-dt-idx="0" tabIndex="0" className="page-link">
                                                        Previous
                                                    </Link>
                                                </li>
                                                <li className="paginate_button page-item next disabled" id="example1_next">
                                                    <Link aria-controls="example1" data-dt-idx="1" tabIndex="0" className="page-link">
                                                        Next
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

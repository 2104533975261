import React from 'react';
import { FaEnvelope, FaFacebookF, FaLinkedinIn, FaPinterest, FaTumblr, FaYoutube } from 'react-icons/fa';
import { BsTelephoneFill } from 'react-icons/bs';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Softmintlog() {

    const userLogin = (e) => {
        // Implement your user login logic here
        e.preventDefault(); // Prevent form submission
    }

    return (
        <>
            <div>
                {/* <header>
                    <div className="header" id="header" style={{ backgroundColor: "#f1f1f1", height: "8vh" }}>
                        <a href="#default" className="logo">
                            <img
                                src="./image/softmintlogo.png"
                                alt="Logo"
                                className="img-responsive"
                                id="logo"
                                style={{ width: '14rem', marginLeft: '3rem' }}
                            />
                        </a>
                        <div className="header-right" style={{ marginTop: '1rem', float: "right", position: "sticky" }}>
                            <a href="#" style={{ marginRight: '5rem', color: "black", fontSize: "15px" }} className="ng-binding">
                                <BsTelephoneFill style={{ fontSize: "20px" }} /> Helpline Numbers: <span style={{ color: "red", fontWeight: "600", fontSize: "15px" }}>9999726418</span>
                            </a>
                            <a href="#contact" style={{ marginRight: '16rem', color: "black", fontSize: "15px" }} className="ng-binding">
                                <FaEnvelope style={{ fontSize: "20px" }} /> Email : <span style={{ color: "red", fontWeight: "600", fontSize: "15px" }}>support@softmintindia.com</span>
                            </a>
                        </div>
                    </div>
                </header> */}

                <Navbar bg="light" expand="lg">
                    <Container>
                        <Navbar.Brand href="#home"> <img
                            src="./image/softmintlogo.png"
                            alt="Logo"
                            className="img-responsive"
                            id="logo"
                            style={{ width: '14rem', }}
                        /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                <div className="header-right" style={{ marginTop: '5rem' }}>
                                    <Link to="#" style={{ marginRight: '5rem', color: "black", fontSize: "15px" }} className="ng-binding">
                                        <BsTelephoneFill style={{ fontSize: "20px" }} /> Helpline Numbers: <span style={{ color: "red", fontWeight: "600", fontSize: "15px" }}>9999726418</span>
                                    </Link>
                                    <Link to="#contact" style={{ marginRight: '16rem', color: "black", fontSize: "15px" }} className="ng-binding">
                                        <FaEnvelope style={{ fontSize: "20px" }} /> Email : <span style={{ color: "red", fontWeight: "600", fontSize: "15px" }}>support@softmintindia.com</span>
                                    </Link>
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <section className="login-block" style={{ background: "linear-gradient(to bottom, #42e713, #1f4eb6)", width: "100%", height: "100%", whiteSpace: "", padding: "70px 0", float: "left" }}>
                    <div className="container" style={{ height: "maxContent", backgroundColor: "white", borderRadius: "10px" }}>
                        <div className="row">
                            <div className="col-12 col-md-4 col-sm-6 col-xs-12 mt-10 login-sec">
                                <h2 className="text-center" style={{ marginBottom: "30px", fontWeight: "800", fontSize: "30px" }}>Login Now</h2>
                                <form className="login-form" onSubmit={userLogin}>
                                    <div className="form-group" style={{ marginBottom: "1rem" }}>
                                        <label htmlFor="UserName" className="text-uppercase">Username</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="UserName"
                                            placeholder="Username"
                                        />
                                    </div>

                                    <div className="form-group" style={{ marginBottom: "1rem" }}>
                                        <label htmlFor="pass" className="text-uppercase">Password</label>
                                        <input
                                            className="form-control"
                                            type="password"
                                            name="pass"
                                            placeholder="Password"
                                        />
                                    </div>

                                    <div className="form-check" style={{ display: "block", paddingLeft: "1.25rem", marginTop: "3rem", }}>

                                        <a href="#" style={{ color: "#007bff" }}>
                                            Forget Password?
                                        </a>

                                        <button type="submit" className="btn btn-login float-right"
                                            style={{
                                                padding: "10px 16px",
                                                // margin: "5px",
                                                fontSize: "18px",
                                                lineHeight: "1.3333333",
                                                borderRadius: "6px",
                                                textAlign: "center",
                                                whiteSpace: "nowrap",
                                                verticalAlign: "middle",
                                                touchAction: "manipulation",
                                                border: "1px solid transparent",
                                                fontWeight: "500",
                                                display: "inline-block",
                                                cursor: "pointer",
                                                background: "linear-gradient(to bottom, #42e713, #1f4eb6)"
                                            }}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div className="col-12 col-md-8 col-sm-6 col-xs-12">
                                <Carousel slide={false}>
                                    <Carousel.Item>
                                        {/* <ExampleCarouselImage text="First slide" /> */}
                                        <img src='./image/softmint1image.jpg' style={{ width: "100%", height: "auto" }} />

                                    </Carousel.Item>
                                    <Carousel.Item>
                                        {/* <ExampleCarouselImage text="Second slide" /> */}
                                        <img src='./image/softmint1image.jpg' style={{ width: "100%", height: "auto" }} />

                                    </Carousel.Item>
                                    <Carousel.Item>
                                        {/* <ExampleCarouselImage text="Third slide" /> */}
                                        <img src='./image/softmint1image.jpg' style={{ width: "100%", height: "auto" }} />

                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>

                <footer className="text-dark" style={{ backgroundColor: "#f1f1f1" }}>
                    <Container>
                        <Row>
                            <Col md={6}>
                                <div className='col mt-4'>
                                    <Link className="btn btn-google" to="https://play.google.com/store/apps/details?id=com.finance.softmint&amp;pli=1" title="Google Play">Google Play</Link>
                                    <p className="left-text">
                                        © 2019 Created By <b><b>Softmint Digital Services Pvt. Ltd.</b ></b> All
                                        Rights Reserved.
                                    </p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="right-col" style={{ float: "right" }}>
                                    <h1 style={{ fontSize: "18px", fontWeight: "600" }}>Our Social Media</h1>
                                    <div className="border"
                                        style={{
                                            border: "1px solid #dee2e6!important",
                                            width: "100%",
                                            height: "4px",
                                            background: "#0308f8"
                                        }}>
                                    </div>
                                    <div id="social" style={{ margin: "10px 10px", textAlign: "center" }}>
                                        <Link className="facebookBtn smGlobalBtn" to="#"><FaFacebookF style={{ fontSize: "20px" }} /></Link>
                                        <Link className="linkedinBtn smGlobalBtn" to="#"><FaLinkedinIn style={{ fontSize: "20px" }} /></Link>
                                        <Link className="pinterestBtn smGlobalBtn" to="#"><FaPinterest style={{ fontSize: "20px" }} /></Link>
                                        <Link className="tumblrBtn smGlobalBtn" to="#"><FaTumblr style={{ fontSize: "20px" }} /></Link>
                                        <Link className="youtubeBtn smGlobalBtn" to="https://www.youtube.com/@softmintindiaofficial1973"><FaYoutube style={{ fontSize: "20px" }} /></Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </div>
        </>
    )
}

import React, { useState } from "react";
import { Button } from "react-bootstrap";

const MinistatementWalletRecord = ({ data, updateStatus, updateBalance }) => {
  return (
    <div className="table-responsive">
      <table id="" className="table m-0 table-bordered table-striped">
        <thead>
          <tr align="center">
            <th style={{ fontWeight: "bold" }}>#</th>
            <th style={{ fontWeight: "bold" }}>USER ID</th>
            <th style={{ fontWeight: "bold" }}>NAME</th>
            <th style={{ fontWeight: "bold" }}>BALANCE</th>
            <th style={{ fontWeight: "bold" }}>STATUS</th>
            <th style={{ fontWeight: "bold" }}>ACTION</th>
          </tr>
        </thead>
        <tbody>
          {data.map((option, index) => (
            <tr align="center">
              <td>{index + 1}</td>
              <td>{option.userId}</td>
              <td>{option.name}</td>
              <td>{option.balance}</td>
              <td>
                {option.isLocked ? (
                  <span
                    onClick={() =>
                      updateStatus(
                        option.userId,
                        option.isLocked
                      )
                    }
                    style={{ cursor: "pointer" }}
                    className="badge badge-danger"
                  >
                    LOCKED
                  </span>
                ) : (
                  <span
                    onClick={() =>
                      updateStatus(
                        option.userId,
                        option.isLocked,
                        option.name
                      )
                    }
                    style={{ cursor: "pointer" }}
                    className="badge badge-success"
                  >
                    UNLOCKED
                  </span>
                )}
              </td>
              <td>
              <Button
                    variant="outlined"
                    onClick={() => updateBalance(option.userId, option.balance, option.isLocked, option.name)}
                  >
                    DEDUCT BALANCE
                  </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MinistatementWalletRecord;

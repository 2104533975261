import React from 'react';
import Navbar from './Navbar';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import RestService from '../http';
import showToast from './utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from './Loader';


export default function DetailAssignTicket() {
    const rest = new RestService();
    const location = useLocation();
    const [option, setOption] = useState({});
    const [data, setData] = useState({});
    const [remark, setRemark] = useState("");
    const stateData = location.state;
    const [showModal, setShowModal] = useState(false);
    const [level, setLevel] = useState("");
    const [emp, setEmp] = useState("");
    const [empList, setEmpList] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {

        setData(stateData);
    }, [setData]);


    useEffect(() => {
        setOption(data);
    }, [setOption]);


    const handleProcess = (e, ticketId, actionKey) => {

        var raw = {
            "ticketId": ticketId,
            "actionKey": actionKey,
            "transferTo": emp,
            "reason": remark
        }

        setLoading(true);

        rest.post("emp/processTicketRequest", raw).then(result => {
            setLoading(false);
            
            console.log(result);
            if (result.status === rest.SUCCESS) {
                showToast("SUCCESS", result.message);

                setTimeout(() => {
                    window.location.href='/assignticket';
                }, 2000)

            } else {

                showToast("DANGER", result.message);
               setShowModal(false);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleShowModal = () => {
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const handleLevel = (level) => {

        setLevel(level)

        rest.post(`emp/getEmpByLevel/${level}`, null).then(result => {
            console.log(result);
            if (result.status === rest.SUCCESS) {
                setEmpList(result.data);

            } else {
                setEmpList([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    }


    const handleCloseTicket = (e, ticketId) => {

        var raw = {
            "ticketId": ticketId,
            "empRemark": remark
        }

        rest.post("emp/closeTicket", raw).then(result => {
            console.log(result);

            if(result.status === rest.SUCCESS) {
                showToast("SUCCESS", result.message);

                setTimeout(() => {
                    window.location.href='/assignticket';
                }, 2000)

            } else {
                showToast("DANGER", result.message);
            }
        })
        
    }

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: '553.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Detailed View Ticket</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Member Master</Link></li>
                                    <li className="breadcrumb-item"><Link to="/managemember">View Ticket</Link></li>
                                    <li className="breadcrumb-item active">View</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}> Ticket Id :</span> {data.ticketId}</label>
                                    </div>

                                    <div className="col-md-4">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Opening Date :</span>{data.openingDate}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Closing Date :</span> {data.closingDate}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Transaction Id :</span> {data.refNo}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>User Id :</span> {data.userId}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>User Message :</span> {data.message}</label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Employee Id :</span> {data.ticketAssignee} </label>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Is Priority :</span> {data.isPriority}</label>
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Service :</span> {data.service}</label>
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Service Category :</span> {data.serviceCategory}</label>
                                    </div>

                                    <div className="col-md-4 mt-2">
                                        <label className="font-weight-normal">
                                            <span style={{ fontWeight: '600', color: "green" }}>Status :</span> {data.status}</label>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-6 mt-4'>
                                        <label>
                                            Remark: <span className="text-danger"> *</span>
                                        </label>
                                        <input
                                            placeholder="Add Remark..."
                                            className="form-control select2 "
                                            id="service_id"
                                            data-select2-id="service_id"
                                            aria-hidden="true"
                                            onChange={(e) => setRemark(e.target.value)}
                                        />

                                    </div>

                                </div>


                                <div className='mt-2 text-center' style={{ float: "right" }}>
                                    <Link className='btn bg-blue' to='/assignticket'>Back</Link>&nbsp;&nbsp;
                                    <Link className='btn bg-success' onClick={handleShowModal}>Transfer</Link>&nbsp;&nbsp;
                                    <Link className='btn bg-warning' onClick={(e) =>handleCloseTicket(e, data.ticketId)}>Close</Link>&nbsp;&nbsp;
                                    <Link className='btn bg-danger' onClick={(e) => handleProcess(e, data.ticketId, 0)}>Reject</Link>&nbsp;&nbsp;

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>


            {showModal && (
                <div
                    className="modal fade show"
                    role="dialog"
                    aria-labelledby="serviceUpdateTitle"
                    style={{ display: "block" }}
                    aria-modal="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div
                            className="modal-content"
                            style={{ width: "150%", marginLeft: "-40rem", padding: "5px" }}
                        >
                            <div>
                                <div className="modal-header">
                                    <h4>Update User</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-md-6'>
                                        <label>Level</label><span className="text-danger"> *</span>
                                        <select
                                            name="ac_type"
                                            className="form-control"
                                            onChange={(e) => handleLevel(e.target.value)}
                                            required
                                        >
                                            <option value="">Select Level</option>
                                            <option value="1">Level 1</option>
                                            <option value="2">Level 2</option>
                                            <option value="3">Level 3</option>
                                        </select>
                                    </div>

                                    <div className='col-md-6'>
                                        <label>Employee</label><span className="text-danger"> *</span>
                                        <select
                                            name="ac_type"
                                            className="form-control"
                                            onChange={(e) => setEmp(e.target.value)}
                                            required
                                        >
                                            <option value="">Select Employee</option>
                                            {empList.map((item) => (
                                                <option value={item.empId}>{item.empName} || {item.mobile}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>


                                <div className="modal-footer mt-4">

                                    <button
                                        type="button"
                                        className="btn btn-secondary bg-success"
                                        data-dismiss="modal"
                                        fdprocessedid="7wkuky"
                                        onClick={(e) => handleProcess(e, data.ticketId, 2)}
                                    >
                                        Transfer
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                        fdprocessedid="7wkuky"
                                        onClick={handleCloseModal}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';
import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';


export default function Sendsms() {

    const [type, setType] = useState(1);

    const handleRadioChange = (event) => {
        setType(Number(event.target.value));
    };


    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropDownRef = useRef();

    const options = [
        { value: '', label: 'AJOY MONDAL [MD1003][9609236025]' },
        { value: '1', label: 'ARUL DOSS [MD1002][7904634883]' },
        { value: '2', label: 'NAVNATH SASE [RT1001][8600707581]' },
        { value: '3', label: 'SHARAD BHADNAGAR [MA1000][8600436163]' },

    ];

    const handleOptionSelect = (option) => {
        setSelectedOption(option.value);
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '532.667px' }}>
                <div className="content-header">
                    {/* Content header code */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card col-5 m-auto" id="addpack">
                            <div className="card-body">
                                <form action="/sendsms" method="post" accept-charset="utf-8">
                                    <input type="hidden" name="securityToken" value="3a57dd61812290cf4269b7af078fa7e1" />
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="icheck-primary d-inline">
                                                <input
                                                    type="radio"
                                                    id="radioPrimary3"
                                                    name="type"
                                                    value="1"
                                                    checked={type === 1}
                                                    onChange={handleRadioChange}
                                                />
                                                <label htmlFor="radioPrimary3">Type wise</label>
                                            </div>
                                            <div className="icheck-primary d-inline ml-2">
                                                <input
                                                    type="radio"
                                                    id="radioPrimary4"
                                                    name="type"
                                                    value="2"
                                                    checked={type === 2}
                                                    onChange={handleRadioChange}
                                                />
                                                <label htmlFor="radioPrimary4">Customer wise</label>
                                            </div>
                                        </div>
                                        <div className={`col-12 mt-3 ${type === 1 ? '' : 'd-none'}`} id="type_div">
                                            <label>Select Customer Type</label><span className="text-danger"> *</span>
                                            <select name="customer_type" class="form-control select2 " data-placeholder="To" data-select2-id="1" tabindex="-1" aria-hidden="true">
                                                
                                            </select>
                                        </div>

                                        <div className={`col-12 mt-3 ${type === 2 ? '' : 'd-none'}`} id="customer_div">
                                            <label>Select Customer</label><span className="text-danger"> *</span>
                                            <select name="customer_id[]" className="form-control select2 select2-hidden-accessible" multiple>

                                            </select>

                                            <span className="select2 select2-container select2-container--default select2-container--focus select2-container--above" dir="ltr" data-select2-id="6" style={{ width: 'auto' }}>
                                                <div
                                                    ref={dropDownRef}
                                                    className={`select2 select2-container select2-container--default ${isDropdownOpen ? 'select2-container--open' : ''
                                                        }`}
                                                    dir="ltr"
                                                    data-select2-id="2"
                                                    style={{ width: '352.625px' }}
                                                    onClick={toggleDropdown}
                                                >
                                                    <span className="selection">
                                                        <span
                                                            className={`select2-selection select2-selection--single ${isDropdownOpen ? 'select2-selection--active' : ''
                                                                }`}

                                                            role="combobox"
                                                            aria-haspopup="true"
                                                            aria-expanded={isDropdownOpen}
                                                            tabIndex="0"
                                                            aria-disabled="false"
                                                            aria-labelledby="select2-package_id-24-container"
                                                        >
                                                            <span
                                                                className="select2-selection__rendered"
                                                                id="select2-package_id-24-container"
                                                                role="textbox"
                                                                aria-readonly="true"
                                                                title={selectedOption ? options.find((option) => option.value === selectedOption).label : 'To'}
                                                            >
                                                                {selectedOption ? options.find((option) => option.value === selectedOption).label : 'To'}
                                                            </span>

                                                            <span className="select2-selection__arrow" role="presentation">
                                                                <b role="presentation"></b>
                                                            </span>
                                                        </span>
                                                    </span>

                                                    {isDropdownOpen && (
                                                        <span className="dropdown-wrapper" style={{ position: 'absolute' }}>
                                                            <span className="select2-dropdown select2-dropdown--below" dir="ltr" style={{ width: '352.625px' }}>
                                                                <span className="select2-search select2-search--dropdown">
                                                                    <input
                                                                        className="select2-search__field"
                                                                        type="search"
                                                                        tabIndex="0"
                                                                        autoComplete="off"
                                                                        autoCorrect="off"
                                                                        autoCapitalize="none"
                                                                        spellCheck="false"
                                                                        role="searchbox"
                                                                        aria-autocomplete="list"
                                                                        aria-controls="select2-package_id-ci-results"
                                                                    />
                                                                </span>
                                                                <span className="select2-results">
                                                                    <ul className="select2-results__options" role="listbox" id="select2-package_id-ci-results" aria-expanded="true" aria-hidden="false">
                                                                        {options.map((option) => (
                                                                            <li
                                                                                key={option.value}
                                                                                className={`select2-results__option ${option.value === selectedOption ? 'select2-results__option--highlighted' : ''}`}
                                                                                role="option"
                                                                                aria-selected={option.value === selectedOption}
                                                                                data-select2-id={`select2-package_id-f9-result-${option.value}`}
                                                                                onClick={() => handleOptionSelect(option)}
                                                                            >
                                                                                {option.label}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    )}

                                                </div>
                                            </span>

                                        </div>
                                        <div className="col-12 mt-3">
                                            <label>Template ID</label>
                                            <input type="text" name="template_id" placeholder="Enter template ID" className="form-control" />
                                        </div>
                                        <div className="col-12 mt-3">
                                            <label>Enter Your Message</label><span className="text-danger"> *</span>
                                            <textarea name="message" rows="5" placeholder="Enter Your Message" className="form-control" required />
                                        </div>
                                        <div className="col-12  mt-3">
                                            <div className="icheck-primary d-inline">
                                                <input type="radio" id="radioPrimary5" name="sms_type" value="1" checked />
                                                <label htmlFor="radioPrimary5">Text SMS</label>
                                            </div>
                                            <div className="icheck-success d-inline ml-2">
                                                <input type="radio" id="radioPrimary6" name="sms_type" value="2" />
                                                <label htmlFor="radioPrimary6">WhatsApp SMS</label>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <input type="submit" className="btn btn-primary" style={{ marginTop: '30px' }} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>    

            <footer className="main-footer" >
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

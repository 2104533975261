import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { CiBellOn } from 'react-icons/ci';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AiOutlineFolderOpen } from 'react-icons/ai';
import { MdLogout } from 'react-icons/md';
import { FaUserAlt } from 'react-icons/fa';
import { BiUserCircle, BiHomeAlt, BiChevronDown } from 'react-icons/bi';
import { HiOutlineChat } from 'react-icons/hi';
import { RiShoppingBasketLine } from 'react-icons/ri';
import { BsGrid, BsFingerprint } from 'react-icons/bs';
import { Navigate, useNavigate } from 'react-router-dom';
import Logout from '../components/utilityComponents/Logout';
import RestService from '../http';


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function Sidebar() {

    const service = new RestService();
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [openDropdowns, setOpenDropdowns] = useState([]);
    const navigate = useNavigate();
    const [balance, setBalance] = useState({});
    const [name] = useState(sessionStorage.getItem("name"));
    const [roleName] = useState(sessionStorage.getItem("role"));
    const [memberDropdownOpen, setMemberDropdownOpen] = useState(false);


    const toggleMemberDropdown = () => {
        setMemberDropdownOpen(!memberDropdownOpen);
        // setTransactionDropdownOpen(false);
        // setWalletDropdownOpen(false);
      };

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    // const handleClick = (index) => {
    //     console.log('Clicked', index);
    //     const updatedOpenDropdowns = [...openDropdowns];

    //     if (updatedOpenDropdowns.includes(index)) {
    //         updatedOpenDropdowns.splice(updatedOpenDropdowns.indexOf(index), 1);
    //     } else {
    //         updatedOpenDropdowns.push(index);
    //     }

    //     console.log('Updated openDropdowns:', updatedOpenDropdowns);

    //     setOpenDropdowns(updatedOpenDropdowns);
    // };

    const handleClick = (index) => {
        setOpenDropdowns((prevOpenDropdowns) => {
            const isOpen = prevOpenDropdowns.includes(index);
            return isOpen
                ? prevOpenDropdowns.filter((item) => item !== index)
                : [...prevOpenDropdowns, index];
        });
    };



    const handlePageRoute = (path) => {
        navigate({ pathname: path });
    };


    useEffect(() => {
        service.fetchUserBalance().then(result => result.json()).then(balres => {
            console.log(balres);
            setBalance(balres);

        }).catch((error) => {
            console.log(error);
        })

    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" className='bg-light' open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className="pull-right" style={{ marginLeft: "75%" }}>
                        <div className="btn-group main-balance" style={{ borderRadius: "10px", backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)', color: "white", marginTop: "2px" }}>
                            <button type="button" data-toggle="dropdown" style={{ color: "white", fontWeight: "500", background: "none", border: "none" }} >
                                <img src="image/wallet.png" className="wallet-icon" />
                                <span className="mybal-text-white">My Balance</span><br />
                                <span className="badge" id="wallet" style={{ fontSize: "14px" }}>&#8377;
                                    {balance === undefined
                                        ? 0.0
                                        : balance.data}
                                </span>
                            </button>

                        </div>&nbsp;&nbsp;

                        <div className="btn-group main-balance" style={{ borderRadius: "10px", backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)', color: "white", height: "15%" }}>
                            <button className="primary addmoney-but" onClick="window.location.href='b-d-add-money.php';" style={{ color: "white", background: "none", border: "none" }}>
                                Add<br /> Money
                            </button>
                        </div>&nbsp;&nbsp;

                        <div className="btn-group btn-group-list btn-group-notification notification">
                            <button style={{ background: "none", border: "none", color: "black", position: "relative", }} type="button" data-toggle="dropdown">
                                <CiBellOn style={{ fontSize: "30px" }} />
                                <span className="badge"
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        background: "red", // Change the background color as needed
                                        borderRadius: "50%",
                                        padding: "5px",
                                        fontSize: "12px",
                                        color: "white",
                                    }}
                                >0</span></button>

                        </div>&nbsp;&nbsp;

                        <div className="btn-group btn-group-option">
                            <button style={{ background: "none", border: "none" }} type="button" data-toggle="dropdown"><span
                                className='user-img'>
                                <img className='rounded-circle img-circle' src='image/profile.png' width='40' alt='' /></span></button>
                            <ul className="dropdown-menu pull-right" role="menu">
                                <li><a href="/editprofile"><FaUserAlt className='glyphicon' /> My Profile</a>
                                </li>

                                <li className="divider"></li>
                                <li>
                                    <a onClick={() => Logout("your-url")}>
                                        <MdLogout className='glyphicon' />Sign Out
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        <img style={{ width: "150px" }} src='./image/softmintlogo.png' />
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>

                </DrawerHeader>
                <Divider />
                <List>

                    <div className="leftpanel" style={{ backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)' }}>
                        <div className="media profile-left">
                            <a className="pull-left profile-thumb" href="/editprofile">
                                <img
                                    src="./image/profile.png"
                                    alt="user"
                                    className="img-circle"
                                /> </a>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="media-body">
                                <h5 className="media-heading">{name}</h5>
                                <small className="text-white">{service.roles[new Number(roleName)]}</small>
                            </div>
                        </div>
                        <ul
                            className="nav nav-pills nav-sidebar flex-column"
                            data-widget="treeview"
                            role="menu"
                            data-accordion="false"
                        >
                            <li className="active">
                                <a href="/side"><BiHomeAlt className='house' /> <span>
                                    Dashboard</span>
                                </a></li>

                            <li className={`nav-item has-treeview ${memberDropdownOpen ? "menu-open" : ""
                                }`}><a href="#" className="nav-link"
                                onClick={toggleMemberDropdown} ><BiUserCircle className='house' />
                                    <span> My Account</span>

                                    <BiChevronDown style={{ marginLeft: "75px" }} />
                                </a>

                                <ul className={`nav nav-treeview ${memberDropdownOpen ? "open" : ""
                      }`}>
                                    <li className="" ><a href="/editprofile" className="nav-link"> Edit Profile</a></li>
                                    <li className=""><a href="/kyc" className="nav-link"> KYC Verification</a></li>
                                    <li className=""><a href="/kycmicroatm" className="nav-link"> KYC Microatm</a></li>
                                    <li className=""><a href="/changepasswrd" className="nav-link"> Change Password</a></li>
                                    <li className=""><a href="/commison" className="nav-link"> My Commission</a></li>
                                    <li className=""><a href="print/certificate-print.php" className="nav-link" target="_blank"> Print
                                        Certificate</a></li>
                                </ul>

                            </li>
                            <li className="parent" ><a href="#" onClick={() => handleClick(1)}>
                                <RiShoppingBasketLine className='house' /> <span>My Wallet</span>

                                <BiChevronDown style={{ marginLeft: "85px" }} />
                            </a>
                                <ul className={`children ${openDropdowns.includes(1) ? 'show' : ''}`}>
                                    <li className=""><a href="/accountinfo"> Account Info</a></li>
                                    <li className=""><a href="/bankaccount"> Bank Account Add</a></li>
                                    <li className=""><a href="/sendmoney"> Send Money</a></li>
                                    {/* <li className=""><a href="/addmoney"> Add Money</a></li> */}
                                    {/* <li className=""><a href="/fundrequest"> Fund Request</a></li> */}
                                    {/* <li className=""><a href="/wallettrans"> Wallet Transfer</a></li> */}
                                    {/* <li className=""><a href="/mpin"> Change Mpin</a></li> */}
                                </ul>
                            </li>

                            <li className="">
                                <a href="/retailerservice"><BsGrid className='house' /> <span>
                                    Services</span>
                                    <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                </a>
                            </li>

                            <li className="parent"><a href="#" onClick={() => handleClick(2)}><AiOutlineFolderOpen className='house' /> <span> Reports</span>

                                <BiChevronDown style={{ marginLeft: "97px" }} />
                            </a>
                                <ul className={`children ${openDropdowns.includes(2) ? 'show' : ''}`}>
                                    <li className=""><a href="/rechargereportretailer"> Recharge Report</a></li>
                                    <li className=""><a href="/aepsreport"> AEPS Report </a></li>
                                    <li className=""><a href="/aadharpayreport"> AadharPay Report </a></li>
                                    <li className=""><a href="/retailertxnreport"> Transaction Report </a></li>
                                    <li className=""><a href="/payout"> Payout Report</a></li>
                                    <li className=""><a href="/nsdlreport"> NSDL Report</a></li>
                                    <li className=""><a href="/retailerinsurancereport"> Insurance Report</a></li>                                        <li className=""><a href="/retailerearningreport"> Earning Report</a></li>
                                    <li className=""><a href="/bbpsreport"> BBPS Report</a></li>

                                    <li className=""><a href="/pancard"> Pancard Report</a></li>
                                    <li className=""><a href="/ledger"> Ledger Report</a></li>
                                    <li className=""><a href="/matmreport"> MATM Report</a></li>
                                    <li className=""><a href="/retailercouponreport"> Coupon Req. Report</a></li>
                                    <li className=""><a href="/retailermatmlogreport"> MATM Log Report</a></li>
                                    <li className=""><a href="/retailermovetobankuserreport"> MoveToBankUser Report</a></li>
                                    <li className=""><a href="/dmtreport"> DMT Report</a></li>

                                </ul>
                            </li>
                            <li className="">
                                <a href="/feedback"><HiOutlineChat className='house' /> <span>
                                    Feedback</span>
                                    <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                </a></li>

                            <li className="">
                                <a href="/kyc"><BsFingerprint className='house' /> <span>
                                    AEPS E-Kyc</span>
                                    <BiChevronDown style={{ marginLeft: "70px", visibility: "hidden" }} />
                                </a></li>
                        </ul>

                        <div className="menu-devide">&nbsp;</div>
                        <div style={{ display: open ? 'block' : 'none' }}>
                            <div className="customer-support-sec">
                                <h3>Account Details</h3>
                                <p>ICICI BANK</p>
                                <p style={{ fontSize: "11px" }}>(SOFTMINT)</p>
                                <p>A/C No - 260705000861</p>
                                <p><span>IFSC -</span> ICIC0002607</p>
                            </div>
                            <div className="menu-devide">&nbsp;</div>
                            <div className="customer-support-sec" style={{ display: open ? 'block' : 'none' }}>
                                <h3>Quick Support</h3>
                                <p>SOFTMINT</p>
                                <p>Phone : 9999726418</p>
                                <p>What's App : 8809912400</p>
                                <p>Email : <a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                    data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}>info@softmintdigital.com</a></p>
                            </div>
                        </div>
                        <div style={{
                            display: open ? 'none' : 'block',
                            height: '100vh'
                        }}>
                            <div>
                                <h3></h3>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p><span></span></p>
                            </div>
                            <div className="menu-devide">&nbsp;</div>
                            <div className="">
                                <h3></h3>
                                <p></p>
                                <p></p>
                                <p><a className="__cf_email__"
                                    data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}>
                                </a>
                                </p>
                            </div>
                        </div>

                    </div><br />
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                <DrawerHeader />

            </Box>
        </Box>
    );
};

export default Sidebar;

import React, { useEffect } from 'react';
import { CiBellOn } from 'react-icons/ci';
import { AiOutlineFolderOpen } from 'react-icons/ai';
import { MdLogout } from 'react-icons/md';
import { FaUserAlt, FaHome, FaUser, FaWeixin, FaTimes } from 'react-icons/fa';
import { BiUserCircle, BiHomeAlt, BiChevronDown } from 'react-icons/bi';
import { HiOutlineChat } from 'react-icons/hi';
import { RiShoppingBasketLine } from 'react-icons/ri';
import { BsGrid, BsFingerprint } from 'react-icons/bs';
import { useState, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logout from '../components/utilityComponents/Logout';
import RestService from '../http';
import showToast from '../components/utilityComponents/APPToast';
import { ToastContainer } from 'react-toastify';
import Loader from '../components/Loader';
import Select from 'react-select';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function Cms() {

    const service = new RestService();
    const [openDropdowns, setOpenDropdowns] = useState([]);
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [balance, setBalance] = useState({});
    const [name] = useState(sessionStorage.getItem("name"));
    const [loading, setLoading] = useState(false);
    const [roleName] = useState(sessionStorage.getItem("role"));
    const [clientNameList, setClientNameList] = useState([]);
    const [clientName, setClientName] = useState("");
    const [clientField, setClientField] = useState(false);
    const [uniqueCode, setUniqueCode] = useState("");
    const [ClientResponseVerify, setClientResponseVerify] = useState({});
    const [FieldDetail, setFieldDetail] = useState({});
    const [isFetched, setIsFetched] = useState(false);
    const [otpAuth, setOtpAuth] = useState(false);
    const [referenceId, setReferenceId] = useState("");
    const [otpRefId, setOtpRefId] = useState("");
    const [amount, setAmount] = useState("");
    const [clientId, setClientId] = useState("");
    const [otp, setOtp] = useState("");
    const [param1, setParam1] = useState(false);
    const [param2, setParam2] = useState(false);
    const [param3, setParam3] = useState(false);
    const [param4, setParam4] = useState(false);
    const [param5, setParam5] = useState(false);
    const [param6, setParam6] = useState(false);
    const [param7, setParam7] = useState(false);
    const [param8, setParam8] = useState(false);
    const [param1Label, setParam1Label] = useState("");
    const [param2Label, setParam2Label] = useState("");
    const [param3Label, setParam3Label] = useState("");
    const [param4Label, setParam4Label] = useState("");
    const [param5Label, setParam5Label] = useState("");
    const [param6Label, setParam6Label] = useState("");
    const [param7Label, setParam7Label] = useState("");
    const [param8Label, setParam8Label] = useState("");
    const [param1DataValue, setParam1DataValue] = useState("");
    const [param2DataValue, setParam2DataValue] = useState("");
    const [param3DataValue, setParam3DataValue] = useState("");
    const [param4DataValue, setParam4DataValue] = useState("");
    const [param5DataValue, setParam5DataValue] = useState("");
    const [param6DataValue, setParam6DataValue] = useState("");
    const [param7DataValue, setParam7DataValue] = useState("");
    const [param8DataValue, setParam8DataValue] = useState("");
    const [otpSubmitBtn, setOtpSubmitBtn] = useState(false);

    const handleClick = (index) => {
        const updatedOpenDropdowns = [...openDropdowns];

        if (updatedOpenDropdowns.includes(index)) {
            updatedOpenDropdowns.splice(updatedOpenDropdowns.indexOf(index), 1);
        } else {
            updatedOpenDropdowns.push(index);
        }

        setOpenDropdowns(updatedOpenDropdowns);
    };

    const modalRef = useRef(null);

    const openForm = (event) => {
        event.preventDefault();
        modalRef.current.style.display = 'block';
    };

    const closeForm = () => {
        modalRef.current.style.display = 'none';
    };

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        service.fetchUserBalance().then(result => result.json()).then(balres => {
            console.log(balres);
            setBalance(balres);

        }).catch((error) => {
            console.log(error);
        })

    }, []);

    const handleClientMaster = () => {
        setLoading(true);

        service.get("cms/getClientMaster").then(result => {
            setLoading(false);
            console.log(result);

            // const clientName = result.ClientMaster.clientName;
            // const otpAuth = result.ClientMaster.oTPAuthRequired;

            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                setClientField(true);
                setClientNameList(result.ClientMaster);
                setClientName(clientName);
                setOtpAuth(otpAuth);

            } else {
                showToast("DANGER", result.message);
                setClientNameList([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const getCollectionVerification = async () => {
        var raw = {
            "clientId": clientId,
            "uniqueCode": uniqueCode
        }
        setLoading(true);

        const result = await service.post("cms/getCashCollectionVerification", raw).then(result => {
            setLoading(false);
            console.log(result);

            const referenceId = result.VerifyReferenceID;

            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                setClientResponseVerify(result.ClientResponseVerify);
                setFieldDetail(result.FieldDetail);
                setIsFetched(true);
                setReferenceId(referenceId);
                setOtpRefId(result.otpRefId);
                setOtp(result.otp);

                setParam1(false);
                setParam2(false);
                setParam3(false);
                setParam4(false);
                setParam5(false);
                setParam6(false);
                setParam7(false);
                setParam8(false);

            } else {
                showToast("DANGER", result.message);
                setClientResponseVerify({});
                setFieldDetail({});
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleInputChange = (key, value) => {

        Object.keys(ClientResponseVerify).map(keys => {
            if (keys === key) {
                setClientResponseVerify(prevState => ({
                    ...prevState,
                    [keys]: value
                }));
            }
        });

        if (key === "AMOUNT *") {
            setAmount(value);
        }

        // setClientResponseVerify(prevState => ({
        //     ...prevState,
        //     [key]: value
        // }));

    };


    const handleSubmit = () => {
        console.log(otpAuth);

        if (otpAuth === true) {
            var raw = {
                "clientId": clientId
            }

            setLoading(true);

            service.post("cms/getOtpField", raw).then(result => {
                setLoading(false);
                console.log(result);
                setOtpSubmitBtn(true);

                const data = result.OtpField;

                if (result.status === service.SUCCESS) {
                    showToast("SUCCESS", result.message);
                    setIsFetched(false);

                    if (data.oTPParam1 !== null) {
                        setParam1(true);
                        setParam1Label(data.oTPParam1);
                    } else {
                        setParam1(false);
                    }
                    if (data.oTPParam2 !== null) {
                        setParam2(true);
                        setParam2Label(data.oTPParam2);
                    } else {
                        setParam2(false);
                    }
                    if (data.oTPParam3 !== null) {
                        setParam3(true);
                        setParam3Label(data.oTPParam3);
                    } else {
                        setParam3(false);
                    }
                    if (data.oTPParam4 !== null) {
                        setParam4(true);
                        setParam4Label(data.oTPParam4);
                    } else {
                        setParam4(false);
                    }
                    if (data.oTPParam1 !== null) {
                        setParam5(true);
                        setParam5Label(data.oTPParam5);
                    } else {
                        setParam5(false);
                    }
                    if (data.oTPParam6 !== null) {
                        setParam6(true);
                        setParam6Label(data.oTPParam6);
                    } else {
                        setParam6(false);
                    }
                    if (data.oTPParam7 !== null) {
                        setParam7(true);
                        setParam7Label(data.oTPParam7);
                    } else {
                        setParam7(false);
                    }
                    if (data.oTPParam8 !== null) {
                        setParam8(true);
                        setParam8Label(data.oTPParam8);
                    } else {
                        setParam8(false);
                    }

                } else {
                    showToast("DANGER", result.message);
                    setParam1(false);
                    setParam2(false);
                    setParam3(false);
                    setParam4(false);
                    setParam5(false);
                    setParam6(false);
                    setParam7(false);
                    setParam8(false);
                }
            }).catch((err) => {
                console.log(err);
            })

        } else {
            const raw = {
                "clientId": clientId,
                "clientName": clientName,
                "verifyRefId": referenceId,
                "otpRefId": "",
                "otp": "",
                "amount": amount,
                FieldDetail,
                ClientResponseVerify
            }

            console.log(raw);
            setLoading(true);

            service.post("cms/cmsTransaction", raw).then(result => {
                setLoading(false);
                console.log(result);
                if (result.status === service.SUCCESS) {
                    showToast("SUCCESS", result.message);

                } else {
                    showToast("DANGER", result.message);
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    const handleCustomerData = (data) => {
        console.log(data);
        setClientId(data.clientID);
        setOtpAuth(data.oTPAuthRequired);
    }


    const handleGetOtp = () => {
        var raw = {
            "clientId": clientId,
            "oTPParam3": param3DataValue,
            "oTPParam2": param2DataValue,
            "oTPParam4": param4DataValue,
            "oTPParam8": param8DataValue,
            "oTPParam1": param1DataValue,
            "oTPParam5": param5DataValue,
            "oTPParam6": param6DataValue,
            "oTPParam7": param7DataValue,
        }

        console.log(raw);

        service.post("cms/getOTP", raw).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
            } else {
                showToast("DANGER", result.message);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <ToastContainer />
                {loading && <Loader />}

                <AppBar position="fixed" className='bg-light' open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className="pull-right" style={{ marginLeft: "79%" }}>
                            <div className="btn-group main-balance" style={{ borderRadius: "10px", backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)', color: "white", marginTop: "2px" }}>
                                <button type="button" data-toggle="dropdown" style={{ color: "white", fontWeight: "500", background: "none", border: "none" }} >
                                    <img src="image/wallet.png" className="wallet-icon" />
                                    <span className="mybal-text-white">My Balance</span><br />
                                    <span className="badge" id="wallet" style={{ fontSize: "14px" }}>&#8377;
                                        {balance === undefined
                                            ? 0.0
                                            : balance.data}
                                    </span>
                                </button>
                            </div>&nbsp;&nbsp;

                            <div className="btn-group btn-group-list btn-group-notification notification">
                                <button style={{ background: "none", border: "none", color: "black", position: "relative", }} type="button" data-toggle="dropdown">
                                    <CiBellOn style={{ fontSize: "30px" }} />
                                    <span className="badge"
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            background: "red", // Change the background color as needed
                                            borderRadius: "50%",
                                            padding: "5px",
                                            fontSize: "12px",
                                            color: "white",
                                        }}
                                    >0</span></button>

                            </div>&nbsp;&nbsp;

                            <div className="btn-group btn-group-option">
                                <button style={{ background: "none", border: "none" }} type="button" data-toggle="dropdown"><span
                                    className='user-img'>
                                    <img className='rounded-circle img-circle' src='image/profile.png' width='40' alt='' /></span></button>
                                <ul className="dropdown-menu pull-right" role="menu">
                                    <li><a href="/editprofile"><FaUserAlt className='glyphicon' /> My Profile</a>
                                    </li>

                                    <li className="divider"></li>
                                    <li>
                                        <a onClick={() => Logout("your-url")}>
                                            <MdLogout className='glyphicon' />Sign Out
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            <img style={{ width: "150px" }} src='./image/softmintlogo.png' />
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>

                    </DrawerHeader>
                    <Divider />
                    <List>

                        <div className="leftpanel" style={{ backgroundImage: 'linear-gradient(188deg, rgb(13 179 145), rgb(157, 92, 229) 65%)' }}>
                            <div className="media profile-left">
                                <a className="pull-left profile-thumb" href="/editprofile">
                                    <img
                                        src="./image/profile.png"
                                        alt="user"
                                        className="img-circle"
                                    /> </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                <div className="media-body">
                                    <h5 className="media-heading">{name}</h5>
                                    <small className="text-white">{service.roles[new Number(roleName)]}</small>
                                </div>
                            </div>
                            <ul className="nav nav-pills nav-stacked">
                                <li className="active"><a href="/side"><BiHomeAlt className='house' /> <span>
                                    Dashboard</span></a></li>

                                <li className="parent" ><a href="#" onClick={() => handleClick(0)} ><BiUserCircle className='house' />
                                    <span> My Account</span>

                                    <BiChevronDown style={{ marginLeft: "75px" }} />
                                </a>

                                    <ul className={`children ${openDropdowns.includes(0) ? 'show' : ''}`}>
                                        <li className="" ><a href="/editprofile"> Edit Profile</a></li>
                                        <li className=""><a href="/kyc"> KYC Verification</a></li>
                                        <li className=""><a href="/kycmicroatm"> KYC Microatm</a></li>
                                        <li className=""><a href="/changepasswrd"> Change Password</a></li>
                                        <li className=""><a href="/commison"> My Commission</a></li>
                                        <li className=""><a href="print/certificate-print.php" target="_blank"> Print
                                            Certificate</a></li>
                                    </ul>

                                </li>
                                <li className="parent" ><a href="#" onClick={() => handleClick(1)}>
                                    <RiShoppingBasketLine className='house' /> <span>My Wallet</span>

                                    <BiChevronDown style={{ marginLeft: "85px" }} />
                                </a>
                                    <ul className={`children ${openDropdowns.includes(1) ? 'show' : ''}`}>
                                        <li className=""><a href="/accountinfo"> Account Info</a></li>
                                        <li className=""><a href="/bankaccount"> Bank Account Add</a></li>
                                        <li className=""><a href="/sendmoney"> Send Money</a></li>
                                        {/* <li className=""><a href="/addmoney"> Add Money</a></li> */}
                                        {/* <li className=""><a href="/fundrequest"> Fund Request</a></li> */}
                                        {/* <li className=""><a href="/wallettrans"> Wallet Transfer</a></li> */}
                                        {/* <li className=""><a href="/mpin"> Change Mpin</a></li> */}
                                    </ul>
                                </li>

                                <li className="">
                                    <a href="/retailerservice"><BsGrid className='house' /> <span>
                                        Services</span>
                                        <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                    </a>
                                </li>

                                <li className="parent"><a href="#" onClick={() => handleClick(2)}><AiOutlineFolderOpen className='house' /> <span> Reports</span>

                                    <BiChevronDown style={{ marginLeft: "97px" }} />
                                </a>
                                    <ul className={`children ${openDropdowns.includes(2) ? 'show' : ''}`}>
                                        <li className=""><a href="/rechargereportretailer"> Recharge Report</a></li>
                                        <li className=""><a href="/aepsreport"> AEPS Report </a></li>
                                        <li className=""><a href="/aadharpayreport"> AadharPay Report </a></li>
                                        <li className=""><a href="/retailertxnreport"> Transaction Report </a></li>
                                        <li className=""><a href="/payout"> Payout Report</a></li>
                                        <li className=""><a href="/nsdlreport"> NSDL Report</a></li>
                                        <li className=""><a href="/retailerinsurancereport"> Insurance Report</a></li>                                        <li className=""><a href="/retailerearningreport"> Earning Report</a></li>
                                        <li className=""><a href="/bbpsreport"> BBPS Report</a></li>
                                        <li className=""><a href="/matmreport"> MATM Report</a></li>
                                        <li className=""><a href="/retailercouponreport"> Coupon Req. Report</a></li>
                                        <li className=""><a href="/retailermatmlogreport"> MATM Log Report</a></li>
                                        <li className=""><a href="/retailermovetobankuserreport"> MoveToBankUser Report</a></li>
                                        <li className=""><a href="/dmtreport"> DMT Report</a></li>

                                    </ul>
                                </li>
                                <li className=""><a href="/feedback"><HiOutlineChat className='house' /> <span>
                                    Feedback</span>
                                    <BiChevronDown style={{ marginLeft: "85px", visibility: "hidden" }} />
                                </a></li>

                                <li className=""><a href="/kyc"><BsFingerprint className='house' /> <span>
                                    AEPS E-Kyc</span>
                                    <BiChevronDown style={{ marginLeft: "70px", visibility: "hidden" }} />
                                </a></li>
                            </ul>

                            <div className="menu-devide">&nbsp;</div>
                            <div style={{ display: open ? 'block' : 'none' }}>
                                <div className="customer-support-sec">
                                    <h3>Account Details</h3>
                                    <p>ICICI BANK</p>
                                    <p style={{ fontSize: "11px" }}>(SOFTMINT)</p>
                                    <p>A/C No - 260705000861</p>
                                    <p><span>IFSC -</span> ICIC0002607</p>
                                </div>
                                <div className="menu-devide">&nbsp;</div>
                                <div className="customer-support-sec" style={{ display: open ? 'block' : 'none' }}>
                                    <h3>Quick Support</h3>
                                    <p>SOFTMINT</p>
                                    <p>Phone : 9999726418</p>
                                    <p>What's App : 8809912400</p>
                                    <p>Email : <a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                        data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}>info@softmintdigital.com</a></p>
                                </div>
                            </div>
                            <div style={{
                                display: open ? 'none' : 'block',
                                height: '100vh'
                            }}>
                                <div>
                                    <h3></h3>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p><span></span></p>
                                </div>
                                <div className="menu-devide">&nbsp;</div>
                                <div className="">
                                    <h3></h3>
                                    <p></p>
                                    <p></p>
                                    <p><a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                        data-cfemail="076e69616847737577667e296e69" style={{ color: '#fff' }}></a></p>
                                </div>
                            </div>

                        </div><br />
                    </List>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                    <DrawerHeader />
                    <div className="pageheader">
                        <div className="media">
                            <div className="pageicon pull-left">
                                < FaHome />
                            </div>&nbsp;
                            <div className="media-body">
                                <ul className="breadcrumb">
                                    <li>
                                        <a href="#">
                                            < FaUser style={{ color: '#999' }} />
                                        </a>
                                    </li>&nbsp;
                                    <li>Services</li>
                                </ul>
                                <h4>Cash Management</h4>
                            </div>
                        </div>
                    </div>

                    <div className="contentpanel">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="form-horizontalx service-form">
                                    <div className="service-panel">
                                        <h4 className="service-title">Cash Management</h4>
                                        <div className="panel-body">
                                            <div className="form-group" style={{ marginBottom: '0px' }}>
                                                <div className="col-md-12">
                                                    <button
                                                        className="btn btn-primary"
                                                        fdprocessedid="jan85q"
                                                        onClick={handleClientMaster}
                                                    >
                                                        Get Client Master
                                                    </button>
                                                </div>
                                            </div>

                                            {clientField ?
                                                <div className="form-group mt-2">
                                                    <div className="col-md-12">
                                                        <div>
                                                            <label className="control-label">Select</label>
                                                            {/* <select
                                                                className="form-control select-box width100p"
                                                                onChange={(e) => setClientName(e.target.value)}
                                                            >
                                                                <option value="">Select...</option>
                                                                {clientNameList.map((item, index) => (
                                                                    <option value={item.clientName}>{item.clientName}</option>
                                                                ))}

                                                            </select> */}
                                                            <Select
                                                                className="select-box"
                                                                options={clientNameList.map(item => ({
                                                                    value: item,
                                                                    label: item.clientName
                                                                }))}
                                                                onChange={(selectedOption) => handleCustomerData(selectedOption.value)}
                                                                placeholder="Select..."
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 mt-2">
                                                        <div>
                                                            <label className="control-label">Unique Code</label>
                                                            <input
                                                                type="text"
                                                                className="form-control text-box"
                                                                id="eleconsumerid"
                                                                tabindex="4"
                                                                onChange={(e) => setUniqueCode(e.target.value)}
                                                                placeholder='Enter Unique Code'
                                                                autocomplete="off"
                                                                fdprocessedid="yzm4j1"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='col-md-12 mt-4 text-center'>
                                                        <button className='btn bg-primary'
                                                            onClick={getCollectionVerification}
                                                        >
                                                            SUBMIT
                                                        </button>
                                                    </div>

                                                </div> : null
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-7">
                                <div className="form-horizontalx service-form">
                                    <div className="service-panel" style={{ padding: "10px" }}>
                                        {/* {clientResponse && (
                                            <div className='card' style={{ padding: "10px" }}>

                                                <h3 style={{fontWeight:"bold"}}>Client Response Verify</h3><br />

                                                {Object.entries(clientResponse).map(([key, value]) => (
                                                    <p key={key}><strong>{key}:</strong> {value}</p>
                                                ))}
                                            </div>
                                        )} */}

                                        {isFetched && (
                                            <div>
                                                <h3>Client Response Verify</h3>
                                                {Object.entries(ClientResponseVerify).map(([key, value]) => (
                                                    <div key={key}>
                                                        <label style={{ display: "flex" }}>
                                                            <strong>{key}:</strong> &nbsp;&nbsp;
                                                            <input
                                                                style={{ width: "70%", float: "right" }}
                                                                className='form-control'
                                                                type="text"
                                                                value={value}
                                                                onChange={(e) => handleInputChange(key, e.target.value)}
                                                            />
                                                        </label>
                                                    </div>
                                                ))}

                                                <button className='btn bg-success mt-4' type="submit" onClick={handleSubmit}>Submit</button>
                                            </div>
                                        )}

                                        {param1 ? param1Label === param1Label ? (<div className="form-group">
                                            <div className="col-md-12">
                                                <div className="input-box">
                                                    <label className="control-label">{param1Label}</label>
                                                    <input
                                                        type="text"
                                                        name="eleconsumerid"
                                                        className="form-control text-box"
                                                        id="eleconsumerid"
                                                        tabindex="4"
                                                        onChange={(e) => setParam1DataValue(e.target.value)}
                                                        placeholder={param1Label}
                                                        autocomplete="off"
                                                        onkeyup="consumeridchange()"
                                                        onkeypress="return blockAddressChar(event)"
                                                        fdprocessedid="yzm4j1"
                                                    />
                                                </div>
                                            </div>
                                        </div>) : null : null}

                                        {param2 ? param2Label === param2Label ? (<div className="form-group mt-2">
                                            <div className="col-md-12">
                                                <div className="input-box">
                                                    <label className="control-label">{param2Label}</label>
                                                    <input
                                                        type="text"
                                                        name="eleconsumerid"
                                                        className="form-control text-box"
                                                        id="eleconsumerid"
                                                        tabindex="4"
                                                        onChange={(e) => setParam2DataValue(e.target.value)}
                                                        placeholder={param2Label}
                                                        autocomplete="off"
                                                        onkeyup="consumeridchange()"
                                                        onkeypress="return blockAddressChar(event)"
                                                        fdprocessedid="yzm4j1"
                                                    />
                                                </div>
                                            </div>
                                        </div>) : null : null}

                                        {param3 ? param3Label === param3Label ? (<div className="form-group mt-2">
                                            <div className="col-md-12">
                                                <div className="input-box">
                                                    <label className="control-label">{param3Label}</label>
                                                    <input
                                                        type="text"
                                                        name="eleconsumerid"
                                                        className="form-control text-box"
                                                        id="eleconsumerid"
                                                        tabindex="4"
                                                        onChange={(e) => setParam3DataValue(e.target.value)}
                                                        placeholder={param3Label}
                                                        autocomplete="off"
                                                        onkeyup="consumeridchange()"
                                                        onkeypress="return blockAddressChar(event)"
                                                        fdprocessedid="yzm4j1"
                                                    />
                                                </div>
                                            </div>
                                        </div>) : null : null}


                                        {param4 ? param4Label === param4Label ? (<div className="form-group mt-2">
                                            <div className="col-md-12">
                                                <div className="input-box">
                                                    <label className="control-label">{param4Label}</label>
                                                    <input
                                                        type="text"
                                                        name="eleconsumerid"
                                                        className="form-control text-box"
                                                        id="eleconsumerid"
                                                        tabindex="4"
                                                        onChange={(e) => setParam4DataValue(e.target.value)}
                                                        placeholder={param4Label}
                                                        autocomplete="off"
                                                        onkeyup="consumeridchange()"
                                                        onkeypress="return blockAddressChar(event)"
                                                        fdprocessedid="yzm4j1"
                                                    />
                                                </div>
                                            </div>
                                        </div>) : null : null}

                                        {param5 ? param5Label === param5Label ? (<div className="form-group mt-2">
                                            <div className="col-md-12">
                                                <div className="input-box">
                                                    <label className="control-label">{param5Label}</label>
                                                    <input
                                                        type="text"
                                                        name="eleconsumerid"
                                                        className="form-control text-box"
                                                        id="eleconsumerid"
                                                        tabindex="4"
                                                        onChange={(e) => setParam5DataValue(e.target.value)}
                                                        placeholder={param5Label}
                                                        autocomplete="off"
                                                        onkeyup="consumeridchange()"
                                                        onkeypress="return blockAddressChar(event)"
                                                        fdprocessedid="yzm4j1"
                                                    />
                                                </div>
                                            </div>
                                        </div>) : null : null}

                                        {param6 ? param6Label === param6Label ? (<div className="form-group mt-2">
                                            <div className="col-md-12">
                                                <div className="input-box">
                                                    <label className="control-label">{param6Label}</label>
                                                    <input
                                                        type="text"
                                                        name="eleconsumerid"
                                                        className="form-control text-box"
                                                        id="eleconsumerid"
                                                        tabindex="4"
                                                        onChange={(e) => setParam6DataValue(e.target.value)}
                                                        placeholder={param6Label}
                                                        autocomplete="off"
                                                        onkeyup="consumeridchange()"
                                                        onkeypress="return blockAddressChar(event)"
                                                        fdprocessedid="yzm4j1"
                                                    />
                                                </div>
                                            </div>
                                        </div>) : null : null}

                                        {param7 ? param7Label === param7Label ? (<div className="form-group mt-2">
                                            <div className="col-md-12">
                                                <div className="input-box">
                                                    <label className="control-label">{param7Label}</label>
                                                    <input
                                                        type="text"
                                                        name="eleconsumerid"
                                                        className="form-control text-box"
                                                        id="eleconsumerid"
                                                        tabindex="4"
                                                        onChange={(e) => setParam7DataValue(e.target.value)}
                                                        placeholder={param7Label}
                                                        autocomplete="off"
                                                        onkeyup="consumeridchange()"
                                                        onkeypress="return blockAddressChar(event)"
                                                        fdprocessedid="yzm4j1"
                                                    />
                                                </div>
                                            </div>
                                        </div>) : null : null}

                                        {param8 ? param8Label === param8Label ? (<div className="form-group mt-2">
                                            <div className="col-md-12">
                                                <div className="input-box">
                                                    <label className="control-label">{param8Label}</label>
                                                    <input
                                                        type="text"
                                                        name="eleconsumerid"
                                                        className="form-control text-box"
                                                        id="eleconsumerid"
                                                        tabindex="4"
                                                        onChange={(e) => setParam8DataValue(e.target.value)}
                                                        placeholder={param8Label}
                                                        autocomplete="off"
                                                        onkeyup="consumeridchange()"
                                                        onkeypress="return blockAddressChar(event)"
                                                        fdprocessedid="yzm4j1"
                                                    />
                                                </div>
                                            </div>
                                        </div>) : null : null}

                                        {otpSubmitBtn ?
                                            <div className="col-md-12 mt-2 text-center">
                                                <button className='btn bg-primary px-4' onClick={handleGetOtp}>
                                                    SUBMIT
                                                </button>
                                            </div> : null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Box>
            </Box>

            <a href="https://api.whatsapp.com/send/?phone=8809912400&text&app_absent=0" target="_blank"
                className="whatsapp-but" title="">
                <img src="image/whatsapp-icon.png" alt="Whatapps" />
            </a>

            <a
                href="#"
                target="_blank"
                className="helpdesk-but"
                title="Request Callback !"
                onClick={openForm}
            >
                <img src="image/help-icon.png" alt="Help" />
            </a>

            <div className="chat-popup" ref={modalRef} id="myForm">
                <form action="#" className="helpdesk-container" onSubmit={(e) => e.preventDefault()}>
                    <h1>
                        <div className="callbackheader-icon">
                            <FaWeixin aria-hidden="true" style={{ fontSize: '35px', paddingTop: '7px' }} />
                        </div>
                        CallBack Request
                    </h1>
                    <label htmlFor="callback_name"><b>Full Name</b></label>
                    <input
                        type="text"
                        id="callback_name"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="Name"
                        required
                    />
                    <label htmlFor="callback_phone"><b>Contact Number</b></label>
                    <input
                        type="text"
                        id="callback_phone"
                        maxLength="10"
                        name=""
                        className="form-control textbox"
                        placeholder="+91 9000 0000 00"
                        required
                    />
                    <label htmlFor="callback_email"><b>Email ID</b></label>
                    <input
                        type="text"
                        id="callback_email"
                        maxLength="35"
                        name=""
                        className="form-control textbox"
                        placeholder="info@softmintdigital.com"
                        required
                    />
                    <label htmlFor="callback_msg"><b>Message (250 Character Only)</b></label>
                    <textarea
                        id="callback_msg"
                        maxLength="250"
                        placeholder="Type message.."
                        name="msg"
                        className="form-controlx"
                    ></textarea>
                    <button type="submit" className="btn" id="reqcallbut">
                        Send Now
                    </button>
                    <a href="#" className="close-but" title="" onClick={closeForm}>
                        <FaTimes aria-hidden="true" />
                    </a>
                </form>
            </div>

            <div className="modal fade recharge-model success-model" id="model-succ-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="model-succ-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button success-button" onClick="errordisplay()" data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model error-model" id="model-err-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className=""><img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Error Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-err-body">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="focusid" />
                        <button type="button" className="btn btn-success model-button error-button" onClick="errordisplay()" data-dismiss="modal">Dismiss</button>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model process-model" id="model-process-msg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/error-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="errorLabel" className="heading-text">Process Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-process-body">
                            <p className="mb-0"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade recharge-model " id="model-yn-save" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <img src="images/success-icon.png" alt="" title="" className="error-icon" />
                            <h4 id="successLabel" className="heading-text">Success Message!</h4>
                        </div>
                        <div className="modal-body" id="modal-body-pdf">
                            <p className="mb-0"></p>
                        </div>
                        <input type="hidden" id="getinvno" />
                        <button type="button" className="btn btn-success model-button success-button" onClick="succok()" data-dismiss="modal" id="modal-no-save">OK</button>
                    </div>
                </div>
            </div>


            );
        </>
    );
}


import React from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBContainer, MDBTable, MDBTableBody
} from "mdb-react-ui-kit";


const BbpsFinalReciptData = ({ receiptdata }) => {

    const handleClose = () => {
        window.location.reload();
    }
 
    return (
        <>
            <MDBContainer style={{ width: "25%", height: "100vh", marginTop: "30%", background: "cover" }}>
                <MDBCard>
                    <MDBCardBody className="mx-4" style={{ flex: "1" }}>
                        <MDBContainer>
                            {/* Bank Logo */}
                            <div className="logo-container" style={{ background: "lightblue", backgroundSize: 'cover', width: '130%', height: '150px', marginLeft: "-15%", marginTop: "-4%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src="./image/softmintlogo.png" alt="Bbps Logo" style={{ width: '160px', display: "flex", justifyContent: "center" }} />
                                {/* <div className="amount-text" style={{ marginTop: "25%", zIndex: '2', color: 'white', fontSize: '18px', display:"flex", justifyContent:"center", marginLeft:"-16%"}}>
                                    ₹{data.remainingbal}
                                </div> */}
                            </div>

                            <div style={{ display: 'inline-block', position: 'relative', display: "flex", justifyContent: "center" }}>
                                <div className="dashed-line" style={{ position: 'absolute', width: "23vw", top: '60%', left: '-41px', transform: 'translateY(-50%)', borderTop: '2px dashed', color: 'skyblue', zIndex: '0' }}></div>

                                <button className="btn mt-3"
                                    style={{ borderRadius: "15px", position: 'relative', zIndex: '1', justifyContent: "center", display: "flex", color: "white", backgroundColor: "darkcyan" }}
                                >
                                    {receiptdata.amount}
                                </button>
                            </div><br />


                            <MDBTable style={{ tableLayout: 'fixed', width: '100%', overflowY: "scroll" }}>
                                <MDBTableBody>
                                    <React.Fragment>
                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Txn. Amount</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: "10px" }}>{receiptdata.amount}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Bank Txn. Id</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{receiptdata.bankTxnId}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Date</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{receiptdata.date}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Txn. Id</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{receiptdata.txnId}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>Status</td>
                                            <td className="text-end" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', color: receiptdata.status === 'SUCCESS' ? 'green' : 'red' }}>{receiptdata.status}</td>
                                        </tr>

                                    </React.Fragment>

                                </MDBTableBody>
                            </MDBTable>

                            <div style={{ float: "right" }}>
                                <button className="btn bg-blue" onClick={handleClose}>Back</button>&nbsp;
                                <button className="btn bg-red">Print</button>
                            </div>

                        </MDBContainer>
                    </MDBCardBody>

                </MDBCard>
            </MDBContainer>

        </>
    );

};

export default BbpsFinalReciptData;

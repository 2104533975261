import React from "react";

const Pagination2 = ({ totalPages, currentPage, onChange }) => {
    let startPage = 1;

    let endPage = Math.min(totalPages, 5);
    if (currentPage > 3) {
        startPage = currentPage - 2;
        endPage = Math.min(currentPage + 2, totalPages);
    }

    const pageNumbers = [...Array(endPage - startPage + 1).keys()].map(i => startPage + i);

    const goToNextPage = () => {
        if (currentPage !== totalPages) onChange(currentPage + 1);
    };

    const goToPrevPage = () => {
        if (currentPage !== 1) onChange(currentPage - 1);
    };

    const goToFirstPage = () => {
        onChange(1);
    };

    const goToLastPage = () => {
        onChange(totalPages);
    };

    return (
        <>
            <div className="container">
                <div className="row">
                    <div
                        className="col-md-6"
                        style={{
                            paddingTop: "1rem",
                        }}
                    >
                        <nav>
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a className="page-link" onClick={goToFirstPage} href="#">
                                        First
                                    </a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" onClick={goToPrevPage} href="#">
                                        Previous
                                    </a>
                                </li>
                                {pageNumbers.map((pgNumber) => (
                                    <li
                                        key={pgNumber}
                                        className={`page-item ${currentPage === pgNumber ? "active" : ""
                                            } `}
                                    >
                                        <a
                                            onClick={() => onChange(pgNumber)}
                                            className="page-link"
                                            href="#"
                                        >
                                            {pgNumber}
                                        </a>
                                    </li>
                                ))}
                                {totalPages > 5 && currentPage < totalPages - 1 && (
                                    <li className="page-item">
                                        <a className="page-link" onClick={goToLastPage} href="#">
                                            End..
                                        </a>
                                    </li>
                                )}
                                <li className="page-item">
                                    <a className="page-link" onClick={goToNextPage} href="#">
                                        Next
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>
                <br />
            </div>
        </>
    );
};

export default Pagination2;

import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
// import swal from 'sweetalert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router';
import { FaFileExcel, FaFilePdf, FaCopy } from "react-icons/fa";
import { Link } from 'react-router-dom';


export default function Memberkyc() {

    const isSessionExpired = false;
    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const dropDownRef = useRef();
    const [data, setData] = useState([])
    const [kycData, setKycData] = useState([])
    const navigate = useNavigate();


    const [selectedDate, setSelectedDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };


    const handleToDateChange = (date) => {
        setToDate(date);
    };

    // const handleSelectChange = (event) => {
    //     const { value } = event.target;
    //     // Call the first function
    //     setPageSize(value);

    //     setTimeout(() => {
    //         fetchKycData();
    //     }, 500);

    // };


    // const fetchKycData = async (e) => {
    //     var pgsize = 10;
    //     if (e !== undefined) {
    //         pgsize = e.target.value;
    //     }

    //     // If memoizedData is already set, return it immediately
    //     if (memoizedData) {
    //         return memoizedData;
    //     }

    //     var token = '';

    //     if (sessionStorage && sessionStorage.token) {
    //         token = 'Bearer ' + sessionStorage.token;
    //     } else {
    //         navigate({
    //             pathname: "/"
    //         });
    //         sessionStorage.removeItem("token");
    //         sessionStorage.removeItem("user");
    //         return null; // Return null or some other default value if the token is not available
    //     }

    //     try {
    //         const myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/json");
    //         myHeaders.append("Authorization", token);

    //         var raw = JSON.stringify({
    //             "startDate": "2022-08-29",
    //             "endDate": "2022-08-29",
    //             "userType": ""
    //         });

    //         var requestOptions = {
    //             method: 'POST',
    //             body: raw,
    //             headers: myHeaders,
    //             redirect: 'follow'
    //         };

    //         const response = await fetch("https://uat.softmintdigital.com/softmintadminsoftware/kyc/getKycReport?pageNo=0&pageSize=" + pgsize, requestOptions);
    //         const result = await response.json();
    //         setKycData(result.report);

    //         // Store the result in memoizedData
    //         memoizedData = result.report;

    //         return result.report;
    //     } catch (error) {
    //         console.log('error', error);
    //         return null; // Return null or some other default value if an error occurs
    //     }
    // };

    // useEffect(() => {
    //     fetchData();
    // }, []);

    // useEffect(() => {
    //     fetchKycData();
    // }, [])


    let memoizedData;

    // const fetchData = async () => {
    //     // If memoizedData is already set, return it immediately
    //     if (memoizedData) {
    //         return memoizedData;
    //     }

    //     var token = '';
    //     if (sessionStorage && sessionStorage.token) {
    //         token = 'Bearer ' + sessionStorage.token;
    //     } else {
    //         navigate({
    //             pathname: "/"
    //         });
    //         sessionStorage.removeItem("token");
    //         sessionStorage.removeItem("user");
    //         return null; // Return null or some other default value if the token is not available
    //     }

    //     try {
    //         const myHeaders = new Headers();
    //         myHeaders.append("Authorization", token);
    //         myHeaders.append("Content-Type", "application/json");

    //         var raw = JSON.stringify({
    //             "startDate": "2022-08-29",
    //             "endDate": "2022-08-29",
    //             "userType": "",
    //             "status": "",
    //             "mobile": "20108121022"
    //         });

    //         var requestOptions = {
    //             method: 'POST',
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: 'follow'
    //         };

    //         const response = await fetch("https://uat.softmintdigital.com/softmintadminsoftware/kyc/getKycReport?pageNo=0&pageSize=1000", requestOptions);
    //         const result = await response.json();
    //         if (result.status === 0) {
    //             setData([])
    //         } else {
    //             setData(result.List);
    //         }


    //         // Store the result in memoizedData
    //         memoizedData = result.List;

    //         return result.List;

    //     } catch (error) {
    //         console.log('error', error);
    //         return null; // Return null or some other default value if an error occurs
    //     }
    // };


    // Call the fetchData function
    // useEffect(() => {
    //     fetchData();
    // }, []);


    // const fetchMemberData = async () => {
    //     // If memoizedData is already set, return it immediately
    //     if (memoizedData) {
    //         return memoizedData;
    //     }

    //     var token = '';
    //     if (sessionStorage && sessionStorage.token) {
    //         token = 'Bearer ' + sessionStorage.token;
    //     } else {
    //         navigate({
    //             pathname: "/"
    //         });
    //         sessionStorage.removeItem("token");
    //         sessionStorage.removeItem("user");
    //         return null; // Return null or some other default value if the token is not available
    //     }

    //     try {
    //         const myHeaders = new Headers();
    //         myHeaders.append("Authorization", token);
    //         var requestOptions = {
    //             method: 'GET',
    //             headers: myHeaders,
    //             redirect: 'follow'
    //         };

    //         const response = await fetch("https://uat.softmintdigital.com/softmintadminsoftware/user/getAllUser", requestOptions);
    //         const result = await response.json();
    //         console.log(result.List);
    //         setData(result.List);

    //         // Store the result in memoizedData
    //         memoizedData = result.List;

    //         return result.List;

    //     } catch (error) {
    //         console.log('error', error);
    //         return null; // Return null or some other default value if an error occurs
    //     }
    // };

    // // Call the fetchData function
    // useEffect(() => {
    //     fetchMemberData();
    // }, []);



    const handleOptionSelect = (option) => {
        setSelectedOption(option.mobile);
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "620.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">KYC Status <span>
                                    <Link to="/addkyc" className="btn btn-sm btn-primary">
                                        <i className="fa fa-plus"></i> NEW</Link>
                                </span>&nbsp;
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Profile</Link>
                                    </li>
                                    <li className="breadcrumb-item active">KYC Status</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <form
                                    action="#"
                                    method="post"
                                    acceptCharset="utf-8"
                                >
                                    <input
                                        type="hidden"
                                        name="securityToken"
                                        value="55b9bc60523c0753e329ec7da5c677c6"
                                    />
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label>Member</label><br/>
                                            <select
                                                className="select2 form-control select2-hidden-accessible"
                                                id="customer_list"
                                                name="customer_id"
                                                tabIndex="-1"
                                                aria-hidden="true"
                                                data-select2-id="customer_list"
                                            >
                                                <option value="" data-select2-id="4">
                                                    SELECT CUSTOMER
                                                </option>
                                            </select>

                                            <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="12" style={{ width: '214.75px' }}>

                                                <div
                                                    ref={dropDownRef}
                                                    className={`select2 select2-container select2-container--default ${isDropdownOpen ? 'select2-container--open' : ''
                                                        }`}
                                                    dir="ltr"
                                                    data-select2-id="2"
                                                    style={{ width: '280.625px' }}
                                                    onClick={toggleDropdown}
                                                >
                                                    <span className="selection">
                                                        <span
                                                            className={`select2-selection select2-selection--single ${isDropdownOpen ? 'select2-selection--active' : ''
                                                                }`}

                                                            role="combobox"
                                                            aria-haspopup="true"
                                                            aria-expanded={isDropdownOpen}
                                                            tabIndex="0"
                                                            aria-disabled="false"
                                                            aria-labelledby="select2-package_id-24-container"
                                                        >
                                                            <span
                                                                className="select2-selection__rendered"
                                                                id="select2-package_id-24-container"
                                                                role="textbox"
                                                                aria-readonly="true"
                                                                title={selectedOption ? data.find((option) => option.mobile === selectedOption).mobile : 'Select Member'}
                                                            >
                                                                {selectedOption ? data.find((option) => option.mobile === selectedOption).mobile : 'Select Member'}
                                                            </span>

                                                            <span className="select2-selection__arrow" role="presentation">
                                                                <b role="presentation"></b>
                                                            </span>
                                                        </span>
                                                    </span>

                                                    {isDropdownOpen && (
                                                        <span className="dropdown-wrapper" style={{ position: 'absolute' }}>
                                                            <span className="select2-dropdown select2-dropdown--below" dir="ltr" style={{ width: '280.625px' }}>
                                                                <span className="select2-search select2-search--dropdown">
                                                                    <input
                                                                        className="select2-search__field"
                                                                        type="search"
                                                                        tabIndex="0"
                                                                        autoComplete="off"
                                                                        autoCorrect="off"
                                                                        autoCapitalize="none"
                                                                        spellCheck="false"
                                                                        role="searchbox"
                                                                        aria-autocomplete="list"
                                                                        aria-controls="select2-package_id-ci-results"
                                                                    />
                                                                </span>
                                                                <span className="select2-results">
                                                                    <ul className="select2-results__options" role="listbox" id="select2-package_id-ci-results" aria-expanded="true" aria-hidden="false">
                                                                        {data.map((option) => (
                                                                            <li
                                                                                key={option.mobile}
                                                                                className={`select2-results__option ${option.value === selectedOption ? 'select2-results__option--highlighted' : ''}`}
                                                                                role="option"
                                                                                aria-selected={option.mobile === selectedOption}
                                                                                data-select2-id={`select2-package_id-f9-result-${option.value}`}
                                                                                onClick={() => handleOptionSelect(option)}
                                                                            >
                                                                                {option.name + '[' + option.userName + '][' + option.mobile + ']'}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    )}

                                                </div>

                                            </span>
                                        </div>

                                        <div className="col-md-3">
                                            <label>From Date</label><br />
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={handleDateChange}
                                                dateFormat="yyyy-MM-dd" //m Specify the desired format
                                                className="form-control custom-datepicker-input"
                                                showYearDropdown
                                                showMonthDropdown
                                                
                                            />
                                        </div>

                                        <div className="col-md-3">
                                            <label>To Date</label><br />
                                            <DatePicker
                                                selected={toDate}
                                                onChange={handleToDateChange}
                                                dateFormat="yyyy-MM-dd"
                                                className="form-control custom-datepicker-input"
                                                showYearDropdown
                                                showMonthDropdown
                                            />
                                        </div>

                                        <div className="col-md-3 mt-4">
                                            <button
                                                className="btn btn-sm btn-info  mt-3"
                                                fdprocessedid="133zua"
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <hr />
                                <div className="table-responsive">
                                    <div
                                        id="example1_wrapper"
                                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                                    >
                                        <div
                                            className="dataTables_length"
                                            id="example1_length"
                                        >
                                            <label>
                                                Show{' '}
                                                <select
                                                    name="example1_length"
                                                    aria-controls="example1"
                                                    className="custom-select custom-select-sm form-control form-control-sm"
                                                    fdprocessedid="6lzt2"
                                                    onSelect={pageSize}
                                                    // onChange={fetchKycData}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={25}>25</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                    <option value={10000}>All</option>
                                                </select>{' '}
                                                entries
                                            </label>
                                        </div>
                                        <div className="dt-buttons">
                                            <a
                                                className="dt-button buttons-copy buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="Copy"
                                            >
                                                <span>
                                                    <FaCopy className="fa fa-files-o text-info font-weight-bold"/>
                                                </span>
                                            </a>
                                            <a
                                                className="dt-button buttons-excel buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="Excel"
                                            >
                                                <span>
                                                    <FaFileExcel className="fa fa-file-excel-o text-success font-weight-bold" />
                                                </span>
                                            </a>
                                            <a
                                                className="dt-button buttons-pdf buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="PDF"
                                            >
                                                <span>
                                                    <FaFilePdf className="fa fa-file-pdf-o text-danger font-weight-bold"/>
                                                </span>
                                            </a>
                                        </div>
                                        <table
                                            id="example1"
                                            className="table table-striped table-bordered dataTable no-footer"
                                            role="grid"
                                            aria-describedby="example1_info"
                                        >
                                            <thead>
                                                <tr role="row">
                                                    <th
                                                        className="sorting_asc"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        ariaSort="ascending"
                                                        aria-label="#: activate to sort column descending"
                                                        style={{ width: '12.5521px' }}
                                                    >
                                                        #
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Customer Name: activate to sort column ascending"
                                                        style={{ width: '123.927px' }}
                                                    >
                                                        Customer Name
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Aadhar Number: activate to sort column ascending"
                                                        style={{ width: '122.875px' }}
                                                    >
                                                        Aadhar Number
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Pan Number: activate to sort column ascending"
                                                        style={{ width: '96.7708px' }}
                                                    >
                                                        Pan Number
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Photo: activate to sort column ascending"
                                                        style={{ width: '48.3542px' }}
                                                    >
                                                        Photo
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Status: activate to sort column ascending"
                                                        style={{ width: '51.4062px' }}
                                                    >
                                                        Status
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Remark: activate to sort column ascending"
                                                        style={{ width: '62.6667px' }}
                                                    >
                                                        Remark
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Action: activate to sort column ascending"
                                                        style={{ width: '51.7812px' }}
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {kycData && kycData.length > 0 ? (

                                                    kycData.map((option, index) => (
                                                        <tr className="odd" key={index}>

                                                            <td>{index + 1}</td>
                                                            <td>{option.name}</td>
                                                            <td>NA</td>
                                                            <td>NA</td>
                                                            <td>{option.photoimagename}</td>
                                                            <td>{option.status}</td>
                                                            <td>{option.remarks}</td>
                                                            <td>NA</td>
                                                        </tr>
                                                    ))

                                                ) : (
                                                    <tr>
                                                        <td colSpan="8">No data available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        <div
                                            className="dataTables_info"
                                            id="example1_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            Showing 0 to 0 of 0 entries
                                        </div>
                                        <div
                                            className="dataTables_paginate paging_simple_numbers"
                                            id="example1_paginate"
                                        >
                                            <ul className="pagination">
                                                <li
                                                    className="paginate_button page-item previous disabled"
                                                    id="example1_previous"
                                                >
                                                    <a
                                                        aria-controls="example1"
                                                        data-dt-idx="0"
                                                        tabIndex="0"
                                                        className="page-link"
                                                    >
                                                        Previous
                                                    </a>
                                                </li>
                                                <li
                                                    className="paginate_button page-item next disabled"
                                                    id="example1_next"
                                                >
                                                    <a
                                                        aria-controls="example1"
                                                        data-dt-idx="1"
                                                        tabIndex="0"
                                                        className="page-link"
                                                    >
                                                        Next
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}


import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

export default function Memberlogindetail() {

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">
                                    Member Login Details: Sharad Bandarkar <span></span>
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Member Master</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Manage Login Details</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="">
                                    <form
                                        action="#"
                                        className="form-inline"
                                        method="post"
                                        acceptCharset="utf-8"
                                    >
                                        <input
                                            type="hidden"
                                            name="securityToken"
                                            value="42bdbc95cdc725ac7523c42c2612ebba"
                                        />
                                        <div className="form-group mb-2">
                                            <label htmlFor="rec_to_load">Show Records</label>
                                            <select
                                                className="form-control ml-3 mr-3"
                                                name="rec_to_load"
                                                id="rec_to_load"
                                                required="required"
                                                onChange="loadRecords(this.value)"
                                                fdprocessedid="qsnfe"
                                            >
                                                <option value="">Select Option</option>
                                                <option value="250">250 Records</option>
                                                <option value="500">500 Records</option>
                                                <option value="1000">1000 Records</option>
                                                <option value="2500">2500 Records</option>
                                                <option value="5000">5000 Records</option>
                                                <option value="7500">7500 Records</option>
                                                <option value="10000">10000 Records</option>
                                            </select>
                                            <input
                                                type="submit"
                                                className="btn btn-primary text-white"
                                                value="Show"
                                                fdprocessedid="zd4dae"
                                            />
                                            <a
                                                className="btn btn-warning text-white ml-3"
                                            >
                                                Reset Filter
                                            </a>
                                        </div>
                                    </form>
                                </div>

                                <div className="table-responsive" style={{ marginTop: '20px' }}>
                                    <div
                                        id="example1_wrapper"
                                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                                    >
                                        <div
                                            className="dataTables_length"
                                            id="example1_length"
                                        >
                                            <label>
                                                Show{' '}
                                                <select
                                                    name="example1_length"
                                                    aria-controls="example1"
                                                    className="custom-select custom-select-sm form-control form-control-sm"
                                                    fdprocessedid="igiacw"
                                                >
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="All">All</option>
                                                </select>{' '}
                                                entries
                                            </label>
                                        </div>
                                        <div className="dt-buttons">
                                            <a
                                                className="dt-button buttons-copy buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="Copy"
                                            >
                                                <span>
                                                    <i className="fa fa-files-o text-info font-weight-bold"></i>
                                                </span>
                                            </a>
                                            <a
                                                className="dt-button buttons-excel buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="Excel"
                                            >
                                                <span>
                                                    <i className="fa fa-file-excel-o text-success font-weight-bold"></i>
                                                </span>
                                            </a>
                                            <a
                                                className="dt-button buttons-pdf buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="PDF"
                                            >
                                                <span>
                                                    <i className="fa fa-file-pdf-o text-danger font-weight-bold"></i>
                                                </span>
                                            </a>
                                        </div>
                                        <table
                                            id="example1"
                                            className="table m-0 table-striped table-bordered dataTable no-footer"
                                            role="grid"
                                            aria-describedby="example1_info"
                                        >
                                            <thead>
                                                <tr role="row">
                                                    <th
                                                        className="sorting_asc"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colspan="1"
                                                        aria-sort="ascending"
                                                        aria-label="#: activate to sort column descending"
                                                        style={{ width: '8.45833px' }}
                                                    >
                                                        #
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colspan="1"
                                                        aria-label="User Id: activate to sort column ascending"
                                                        style={{ width: '49.4479px' }}
                                                    >
                                                        User Id
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colspan="1"
                                                        aria-label="IP Address: activate to sort column ascending"
                                                        style={{ width: '79.3854px' }}
                                                    >
                                                        IP Address
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colspan="1"
                                                        aria-label="Login Type: activate to sort column ascending"
                                                        style={{ width: '76.5625px' }}
                                                    >
                                                        Login Type
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colspan="1"
                                                        aria-label="Login Time: activate to sort column ascending"
                                                        style={{ width: '151.635px' }}
                                                    >
                                                        Login Time
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colspan="1"
                                                        aria-label="Location: activate to sort column ascending"
                                                        style={{ width: '249.573px' }}
                                                    >
                                                        Location
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowspan="1"
                                                        colspan="1"
                                                        aria-label="Device Name: activate to sort column ascending"
                                                        style={{ width: '178.375px' }}
                                                    >
                                                        Device Name
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td className="sorting_1">1</td>
                                                    <td>MA1000</td>
                                                    <td>122.161.51.65</td>
                                                    <td>WEB</td>
                                                    <td>13 Jul, 2023 :- 11:52:35 AM</td>
                                                    <td>28.626157, 77.3727425</td>
                                                    <td>Android|Chrome(114.0.0.0)</td>
                                                </tr>
                                                <tr role="row" className="even">
                                                    <td className="sorting_1">2</td>
                                                    <td>MA1000</td>
                                                    <td>122.161.51.65</td>
                                                    <td>WEB</td>
                                                    <td>13 Jul, 2023 :- 11:07:43 AM</td>
                                                    <td>28.6262591, 77.3728249</td>
                                                    <td>Android|Chrome(114.0.0.0)</td>
                                                </tr>
                                                {/* Add more table rows here */}
                                            </tbody>
                                        </table>
                                        <div
                                            className="dataTables_info"
                                            id="example1_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            Showing 1 to 25 of 100 entries
                                        </div>
                                        <div
                                            className="dataTables_paginate paging_simple_numbers"
                                            id="example1_paginate"
                                        >
                                            <ul className="pagination">
                                                <li
                                                    className="paginate_button page-item previous disabled"
                                                    id="example1_previous"
                                                >
                                                    <a
                                                        aria-controls="example1"
                                                        data-dt-idx="0"
                                                        tabIndex="0"
                                                        className="page-link"
                                                    >
                                                        Previous
                                                    </a>
                                                </li>
                                                <li className="paginate_button page-item active">
                                                    <a
                                                        aria-controls="example1"
                                                        data-dt-idx="1"
                                                        tabIndex="0"
                                                        className="page-link"
                                                    >
                                                        1
                                                    </a>
                                                </li>
                                                <li className="paginate_button page-item">
                                                    <a
                                                        aria-controls="example1"
                                                        data-dt-idx="2"
                                                        tabIndex="0"
                                                        className="page-link"
                                                    >
                                                        2
                                                    </a>
                                                </li>
                                                <li className="paginate_button page-item">
                                                    <a
                                                        aria-controls="example1"
                                                        data-dt-idx="3"
                                                        tabIndex="0"
                                                        className="page-link"
                                                    >
                                                        3
                                                    </a>
                                                </li>
                                                <li className="paginate_button page-item">
                                                    <a
                                                        aria-controls="example1"
                                                        data-dt-idx="4"
                                                        tabIndex="0"
                                                        className="page-link"
                                                    >
                                                        4
                                                    </a>
                                                </li>
                                                <li
                                                    className="paginate_button page-item next"
                                                    id="example1_next"
                                                >
                                                    <a
                                                        aria-controls="example1"
                                                        data-dt-idx="5"
                                                        tabIndex="0"
                                                        className="page-link"
                                                    >
                                                        Next
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

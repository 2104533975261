import React from 'react';
import Navbar from './Navbar';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa'
import { useState } from 'react';
import { Link } from 'react-router-dom';


export default function Upiapps() {

    const upiAppsData = [
        {
            id: 1,
            appName: 'BHIM',
            logo: './image/bhim1.png',
            package: 'in.org.npci.upiapp',
            upi: 'pay2newfin@icici',
            status: 'ACTIVE',
        },
        // Add more upi apps data here if needed
    ];


    const [showModal, setShowModal] = useState(false);
    const [selectedApp, setSelectedApp] = useState(null);

    const handleEditClick = (appId) => {

        const clickedApp = upiAppsData.find((app) => app.id === appId);
        setSelectedApp(clickedApp);
        setShowModal(true)
    };

    const handleNewClick = () => {
        setSelectedApp(null); // Set selectedApp to null for showing blank data
        setShowModal(true); // Show the modal when clicking the new button
    };


    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '532.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">
                                    UPI Apps{' '}
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        data-toggle="modal"
                                        data-target="#app_modal"
                                        fdprocessedid="7t0g6s"
                                        onClick={handleNewClick}
                                    >
                                        <FaPlus className="fas fa-plus" /> NEW
                                    </button>
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Settings</Link>
                                    </li>
                                    <li className="breadcrumb-item active">UPI Apps</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>App Name</th>
                                                <th>Logo</th>
                                                <th>Package</th>
                                                <th>UPI</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {upiAppsData.map((app) => (
                                                <tr key={app.id}>
                                                    <td>{app.id}</td>
                                                    <td>{app.appName}</td>
                                                    <td className="text-center">
                                                        <img src={app.logo} alt={app.appName} />
                                                    </td>
                                                    <td>{app.package}</td>
                                                    <td>{app.upi}</td>
                                                    <td>
                                                        <span className="badge bg-success">{app.status}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            <button
                                                                type="button"
                                                                className="btn btn-default btn-sm"
                                                                data-toggle="modal"
                                                                data-target="#app_modal"
                                                                onClick={() => handleEditClick(app.id)}
                                                                fdprocessedid="qy9zsh"
                                                            >
                                                                <FaEdit className="fas fa-edit" />
                                                            </button>
                                                            <a
                                                                href={"#"}
                                                                className="btn btn-default btn-sm"
                                                            >
                                                                <FaTrash className="fas fa-trash" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {showModal && (
                <div className="modal fade show" id="app_modal" tabIndex="-1" role="dialog" aria-labelledby="app_modalLabel" style={{ display: 'block' }} aria-modal="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content" style={{ marginTop: '-10%' }}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="app_modalLabel">UPI APP</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" fdprocessedid="hnzlw">
                                    <span aria-hidden="true" onClick={handleCloseModal}>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="/upiapps" encType="multipart/form-data" method="post" acceptCharset="utf-8">
                                    <input type="hidden" name="securityToken" value="bed1260933bb1ce5f2853ff164b25d88" />

                                    <div className="row">
                                        <input type="hidden" id="token" name="token" value="" />
                                        <div className="col-12">
                                            <label>App Name : </label>
                                            <input
                                                type="text"
                                                id="app_name"
                                                name="app_name"
                                                className="form-control"
                                                value={selectedApp ? selectedApp.appName : ''}
                                                required
                                                placeholder="Enter app name"
                                                fdprocessedid="xkp2y"
                                            />
                                        </div>
                                        <div className="col-12 mt-3">
                                            <label htmlFor="exampleInputFile4">Logo <span className="text-danger"> *</span></label>
                                            <div className="custom-file">
                                                <input
                                                    name="app_logo"
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="exampleInputFile4"
                                                    placeholder="Select File" />
                                                <label className="custom-file-label" id="app_logo" htmlFor="exampleInputFile4">Choose file</label>
                                            </div>
                                            <span className="text-info">Image size should be 40 * 40</span>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <label>Package : </label>
                                            <input
                                                type="text"
                                                name="app_package_name"
                                                id="app_package_name"
                                                className="form-control"
                                                required placeholder="Enter package name"
                                                value={selectedApp ? selectedApp.package : ''}
                                                fdprocessedid="qnjpf"
                                            />
                                        </div>
                                        <div className="col-12 mt-3">
                                            <label>UPI Id : </label>
                                            <input
                                                type="text"
                                                name="upi_id"
                                                className="form-control"
                                                id="upi_id"
                                                required placeholder="Enter UPI ID"
                                                value={selectedApp ? selectedApp.upi : ''}
                                                fdprocessedid="z4glf"
                                            />
                                        </div>
                                        <div className="col-12 mt-3">
                                            <label>Admin Accounts : </label>
                                            <select className="form-control" name="customer_bank_id" required id="customer_bank_id" fdprocessedid="6hezhf">
                                                <option value="">Select Account</option>
                                                <option value="1">123456798901 - ICICI Bank </option>
                                            </select>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="form-group clearfix">
                                                <label>Status <span className="text-danger"> * </span></label>&nbsp;&nbsp;
                                                <div className="icheck-primary d-inline">
                                                    <input type="radio" id="radioPrimary1" name="status" value={selectedApp ? selectedApp.status : ''} />
                                                    <label htmlFor="radioPrimary1">ACTIVE</label>&nbsp;&nbsp;
                                                </div>
                                                <div className="icheck-danger d-inline">
                                                    <input type="radio" id="radioPrimary2" name="status" value={selectedApp ? selectedApp.status : ''} />
                                                    <label htmlFor="radioPrimary2">DEACTIVE </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" fdprocessedid="suo8q" onClick={handleCloseModal}>Close</button>
                                        <button type="submit" className="btn btn-primary" fdprocessedid="ydubb">SAVE</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

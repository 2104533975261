import React, { useEffect, useState } from 'react'
import { BiHomeAlt } from 'react-icons/bi';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { FaMoneyBillAlt } from 'react-icons/fa';
import Mychart from './Mychart';
import { useRef } from 'react';
import RestService from '../http';
import swal from 'sweetalert';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Main() {

    const service = new RestService();
    const [nsdlAeps, setNsdlAeps] = useState({});
    const [earningReport, setEarningReport] = useState({});
    const [totalCommission, setTotalCommission] = useState({});
    const [totalRecharge, setTotalRecharge] = useState({});
    const [isSessionExpired, setIsSessionExpired] = useState(false);


    const sessionExpiretionPopup = () => {
        if (isSessionExpired) {
          swal({
            title: "Session Expired",
            text: "Your login session has expired. Please log in again.",
            icon: "warning",
            closeOnClickOutside: false,
            buttons: {
              confirm: {
                text: "OK",
                className: "btn btn-primary",
              },
            },
            dangerMode: true,
          }).then((confirmed) => {
            if (confirmed) {
              window.location.href = "/";
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("user");
            }
          });
        }
      };
      useEffect(() => {
        sessionExpiretionPopup();
      }, [sessionExpiretionPopup]);


    const marqueeRef = useRef(null);

    const handleMouseOver = () => {
        marqueeRef.current.stop();
    };

    const handleMouseOut = () => {
        marqueeRef.current.start();
    };


    const handleDashboard = () => {

        service.fetchDashboardData().then(result => result.json()).then(dashdata => {
            console.log(dashdata.data);
            if (dashdata.status === service.SUCCESS) {
                const dashboardData = dashdata.data;

                setNsdlAeps(dashboardData[0]);
                setEarningReport(dashboardData[0]);
                setTotalCommission(dashboardData[1]);
                setTotalRecharge(dashboardData[2]);

            } else {
                setNsdlAeps(undefined);
                setEarningReport(undefined);
                setTotalCommission(undefined);
                setTotalRecharge(undefined);
                // setIsSessionExpired(true);
            }
        }).catch((error) => {
            console.log(error);
            setNsdlAeps(undefined);
            setEarningReport(undefined);
            setTotalCommission(undefined);
            setTotalRecharge(undefined);

        });
    };


    useEffect(() => {

        // const handleDashboard = async () => {

        //     const headers = {
        //         "Authorization": `Bearer ${sessionStorage.getItem("token")}`
        //     }

        //     // var myHeaders = new Headers();
        //     // myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        //     var requestOptions = {
        //         method: 'POST',
        //         headers: headers,
        //         redirect: 'follow'
        //     };
        //     const url = `http://192.168.1.18:8765/user/getDashboardDetails`;
        //     console.log(url, requestOptions);
        //     fetch(url, requestOptions)
        //         .then(response => response.json())
        //         .then(result => {
        //             console.log(result);
        //             if (result.status === service.SUCCESS) {
        //                 const dashboardData = result.data;

        //                 setNsdlAeps(dashboardData[0]);
        //                 setEarningReport(dashboardData[1]);
        //                 setTotalCommission(dashboardData[2]);
        //                 setTotalRecharge(dashboardData[3]);

        //             } else {
        //                 setNsdlAeps(undefined);
        //                 setEarningReport(undefined);
        //                 setTotalCommission(undefined);
        //                 setTotalRecharge(undefined);
        //             }
        //         }).catch((error) => {
        //             console.log(error);
        //         });
        // };

        handleDashboard();
    }, []);


    return (
        <>
            {/* <div className="main-container"> */}
            {/* <div className='content'> */}

            <Typography style={{ fontSize: '15px', color: 'grey' }}> <BiHomeAlt />/Main</Typography>
            <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>Dashboard</Typography>

            <Divider />
            <Box style={{ height: "12px" }} />

            <div className="contentpanel" >
                <div className="important-notice">
                    <div className="tag-area-top" data-toggle="modal" href="#onload_modal">
                        Notice
                    </div>
                    <marquee
                        direction="left"
                        scrollamount="4"
                        scrolldelay="4"
                        ref={marqueeRef}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                    >
                        Marchent dhayan de SOFTMINT ke <span style={{ color: '#A52A2A' }}>TJSB</span>{' '}
                        <span style={{ color: '#FF8C00' }}>Bank</span> me fund transfer kare our{' '}
                        <span style={{ color: '#FF0000' }}>fund</span> <span style={{ color: '#00FFFF' }}>request</span> lagaye aapne
                        SOFTMINT ke Portal me our 2<span style={{ color: '#FFA500' }}>minit</span> me aapke wallet me amount credit ( MONEY
                        TRANSFER CHARGES -1000 -9999- charges <span style={{ color: '#A52A2A' }}>0.30</span> our 10000-25000 tak charges{' '}
                        <span style={{ color: '#FF8C00' }}>0.25</span> - super fast money transfer.SOFT-<span style={{ color: '#000080' }}>
                            MINT
                        </span>
                        <strong></strong>
                    </marquee>
                </div>
            </div>

            <Box sx={{ width: '100%' }} >

                <div class="row">
                    <div class="col-md-3">
                        <div class="panel panel-primary noborder balance-box">
                            <div class="panel-heading noborder">
                                <div class="panel-icon"><FaMoneyBillAlt className='money' /></div>
                                <div class="media-body">
                                    <h5 class="md-title nomargin">AEPS This Month</h5>
                                    <h1 class="mt5">&#8377;
                                        {nsdlAeps === undefined
                                            ? 0
                                            : nsdlAeps.totalNsdlAeps}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="panel panel-dark noborder balance-box">
                            <div class="panel-heading noborder">
                                <div class="panel-icon"><FaMoneyBillAlt className='money' /></div>
                                <div class="media-body">
                                    <h5 class="md-title nomargin">Earning This Month</h5>
                                    <h1 class="mt5">&#8377;
                                        {earningReport === undefined
                                            ? 0
                                            : earningReport.totalEarning}
                                    </h1>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="panel panel-danger-alt noborder balance-box">
                            <div class="panel-heading noborder">
                                <div class="panel-icon"><FaMoneyBillAlt className='money' /></div>
                                <div class="media-body">
                                    <h5 class="md-title nomargin">Commission Earnings</h5>
                                    <h1 class="mt5">&#8377;
                                        {totalCommission === undefined
                                            ? 0
                                            : totalCommission.totalCommission}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="panel panel-success-alt noborder balance-box">
                            <div className="panel-heading noborder">
                                <div className="panel-icon"><FaMoneyBillAlt className='money' /></div>
                                <div className="media-body">
                                    <h5 className="md-title nomargin">Recharge This Month</h5>
                                    <h1 className="mt5">&#8377;
                                        {totalRecharge === undefined
                                            ? 0
                                            : totalRecharge.totalrecharge}

                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Box>

            <Mychart />

            {/* </div> */}
            {/* </div> */}
        </>
    )
}

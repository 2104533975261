import React from 'react';
import Navbar from './Navbar';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import swal from 'sweetalert';
import RestService from "../http";
import APPConstant from '../constants'
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEdit } from "react-icons/fa";
import { Link } from 'react-router-dom';

export default function Servicemast() {

    const service = new RestService();
    const constant = new APPConstant();

    const [showModal2, setShowModal2] = useState(false);
    const [data, setData] = useState('');
    const [serviceId, setServiceId] = useState('');
    const [status, setStatus] = useState(false);
    const [serviceList, setServiceList] = useState([])
    const [isSessionExpired, setIsSessionExpired] = useState(false);
    const [showAddService, setShowAddService] = useState(false);
    const [showAddProvider, setShowAddProvider] = useState(false);
    const [serviceName, setSericeName] = useState();
    const [serviceProvider, setServiceProvider] = useState("");


    const sessionExpiretionPopup = () => {
        if (isSessionExpired) {
            swal({
                title: 'Session Expired',
                text: 'Your login session has expired. Please log in again.',
                icon: 'warning',
                closeOnClickOutside: false,
                buttons: {
                    confirm: {
                        text: 'OK',
                        className: 'btn btn-primary',
                    },
                },
                dangerMode: true,
            }).then((confirmed) => {
                if (confirmed) {
                    window.location.href = '/';
                    sessionStorage.removeItem("token");
                    sessionStorage.removeItem("user");
                }
            });
        }
    }
    useEffect(() => {
        sessionExpiretionPopup();
    }, [sessionExpiretionPopup]);


    const showToast = (status, message) => {
        if (status === "SUCCESS") {
            toast.success(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else if (status === "DANGER") {
            toast.error(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.warn(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };


    const getServiceList = () => {

        service.post("service/getAllService", null).then(result => {
            // setServiceList(result.report != undefined ? result.report : [])

            if (result.report === undefined) {
                setIsSessionExpired(true);
                setServiceList([]);
            } else {
                console.log(result);
                setServiceList(result.report);
            }

        }).catch(error => {
            console.log(error);
        })
    }
    useEffect(() => {
        getServiceList()
    }, [])


    const handleInputChange2 = (event) => {
        setData(event.target.value);
    };

    const handleServiceType = (event) => {
        setServiceId(event.target.value);
    };

    const handleServiceStatus = (event) => {
        setStatus(event.target.value);
    };

    const handleCloseModal2 = () => {
        setShowModal2(false);
    };


    const handleEditService = (modal, data, serviceId, status) => {
        setShowModal2(modal);
        setData(data);
        setServiceId(serviceId);
        setStatus(status);
    }


    const editService = () => {
        var raw = {
            "serviceId": serviceId,
            // "serviceName": data,
            "status": status
        };

        service.post("service/updateService", raw).then(result => {
            // console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);

            } else {
                showToast("FAILED", result.message);

            }
        })
    }

    const handleNewServiceClick = () => {
        setShowAddService(true);
        setShowAddProvider(false);
    };

    const handleNewProvider = () => {
        setShowAddProvider(true);
        setShowAddService(false);
    };

    const handleSetService = (e) => {
        setSericeName(e.target.value);
    }

    const handleAddService = () => {

        var raw = {
            "serviceName": serviceName,
        };

        service.post("service/addService", raw).then(result => {
            console.log(result);

            if (result === undefined) {
                showToast("DANGER", result.message);


            } else {
                if (result.status === constant.FAILED || result.status === constant.SOMETHING_WENT_WRONG) {
                    showToast("DANGER", result.message);

                } else {
                    showToast("SUCCESS", result.message);
                  
                }
            }
        }).catch(error => {
            console.log(error);
            showToast("DANGER", error);
        })
    };


    const handleAddProvider = () => {
        var raw = {
            "serviceProvider": serviceProvider,
        };

        service.post("service/addServiceProvider", raw).then(result => {
            console.log(result);
            // setServiceProvider(result);

            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                
            } else {
                showToast("DANGER", result.message);

            }
        }).catch(error => {
            console.log(error);
            showToast("DANGER", error);

        })
    }

    const handleSetProvider = (e) => {
        setServiceProvider(e.target.value);
    }

    const updateStatus = (serviceId, status) => {
        let request = {
            serviceId: serviceId,
            status: !status,
        };

        service.post("service/updateService", request).then(result => {
            // console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                getServiceList();
                // setTimeout(() => {
                //     window.location.reload();
                // }, 500);

            } else {
                showToast("FAILED", result.message);
            }
        })
    }

    return (
        <>
            <Navbar />

            <ToastContainer />

            <div className="content-wrapper" style={{ minHeight: '532.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">
                                    Services&nbsp;

                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Settings</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Service</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <style>
                    {`
          tr, td {
            vertical-align: middle !important;
          }
        `}
                </style>
                <section className="content">
                    <div className="container-fluid">

                        {showAddService && (
                            <div className="card" id="addservice">
                                <div
                                    className="card-header text-primary"
                                    style={{ fontWeight: "bold" }}
                                >
                                    Add Service:
                                </div>
                                <div className="card-body">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label htmlFor="service">
                                                    Service Name:{" "}
                                                    <span className="text-danger"> *</span>
                                                </label>
                                            </div>

                                            <div className="col-md-3">
                                                <input
                                                    type="text"
                                                    name="service"
                                                    value={serviceName}
                                                    onChange={handleSetService}
                                                    maxLength="100"
                                                    autoFocus
                                                    required
                                                    className="form-control"
                                                    id="service"
                                                    placeholder="Service Name"
                                                />
                                                <div id="err"></div>
                                            </div>

                                            <div className="col-md-3">
                                                <button
                                                    className="btn btn-primary btn-hover text-white"
                                                    onClick={handleAddService}
                                                >
                                                    Add Service
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                        {showAddProvider && (
                            <div className="card" id="addservice">
                                <div
                                    className="card-header text-primary"
                                    style={{ fontWeight: "bold" }}
                                >
                                    Add Service:
                                </div>
                                <div className="card-body">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label htmlFor="service">
                                                    Service Provider:{" "}
                                                    <span className="text-danger"> *</span>
                                                </label>
                                            </div>

                                            <div className="col-md-3">
                                                <input
                                                    type="text"
                                                    name="service"
                                                    value={serviceProvider}
                                                    onChange={handleSetProvider}
                                                    maxLength="100"
                                                    autoFocus
                                                    required
                                                    className="form-control"
                                                    id="service"
                                                    placeholder="Service Name"
                                                />
                                                <div id="err"></div>
                                            </div>

                                            <div className="col-md-3">
                                                <button
                                                    className="btn btn-primary btn-hover text-white"
                                                    onClick={handleAddProvider}
                                                >
                                                    Add Provider
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="card">

                            <h1 className="m-0 text-dark float-right">
                                <span
                                    className="btn mr-2 btn-sm btn-primary float-right mt-2"
                                    onClick={handleNewServiceClick}
                                >
                                    <i id="sign2" className="fa fa-plus"></i>{" "}
                                    <span id="new2">ADD SERVICE</span>
                                </span>
                            </h1>

                            <h1 className="m-0 text-dark float-right">
                                <span
                                    className="btn mr-2 btn-sm btn-primary float-right mt-2"
                                    onClick={handleNewProvider}
                                >
                                    <i id="sign2" className="fa fa-plus"></i>{" "}
                                    <span id="new2">ADD PROVIDER</span>
                                </span>
                            </h1>


                            <div className="card-body">
                                <div className="table-responsive">
                                    <table id="" className="table table-bordered table-striped">
                                        <thead>
                                            <tr className='text-center'>
                                                <th>ID</th>
                                                <th>Service</th>
                                                <th>Type</th>
                                                <th>Status</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {serviceList.length > 0 ? serviceList.map((service, index) => (
                                                <tr key={index} className='text-center'>
                                                    <td>{index + 1}</td>
                                                    <td>{service.serviceName}</td>
                                                    <td className="text-center">{service.serviceType}</td>
                                                    <td>
                                                        {service.status ? (
                                                            <span
                                                                onClick={() =>
                                                                    updateStatus(
                                                                        service.serviceType,
                                                                        service.status,
                                                                    )
                                                                }
                                                                style={{ cursor: "pointer" }}
                                                                className="badge badge-success"
                                                            >
                                                                ACTIVE
                                                            </span>
                                                        ) : (
                                                            <span
                                                                onClick={() =>
                                                                    updateStatus(
                                                                        service.serviceType,
                                                                        service.status,
                                                                    )
                                                                }
                                                                style={{ cursor: "pointer" }}
                                                                className="badge badge-danger"
                                                            >
                                                                INACTIVE
                                                            </span>
                                                        )}
                                                    </td>
                                                    {/* <td className="text-center">
                                                        <button
                                                            className="btn btn-sm btn-info"
                                                            onClick={() => handleEditService(true, service.serviceName, service.serviceType, service.status)}
                                                        >
                                                            <FaEdit className="fa fa-edit" />
                                                        </button>
                                                    </td> */}
                                                </tr>
                                            )) :
                                                <td colSpan={8}>
                                                    NO DATA AVAILABLE
                                                </td>}
                                        </tbody>
                                    </table>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {showModal2 && (
                    <div className="modal fade show" id="serviceUpdate" tabIndex="-1" role="dialog" aria-labelledby="serviceUpdateTitle" style={{ display: 'block' }} aria-modal="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div>
                                    <ToastContainer />
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLongTitle">Edit Service</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal2}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <input type="hidden" name="action_type" id="action_type" value="1" />

                                            <div className="mt-2 col-md-4">
                                                <label>Service Name</label>
                                                <input
                                                    type="text"
                                                    value={data}
                                                    name="service"
                                                    onChange={handleInputChange2}
                                                    className="form-control clear"
                                                    required
                                                />
                                            </div>

                                            <div className="mt-2 col-md-4">
                                                <label>Service Id</label>
                                                <input
                                                    type="text"
                                                    value={serviceId}
                                                    name="service"
                                                    onChange={handleServiceType}
                                                    className="form-control clear"
                                                    required
                                                />
                                            </div>

                                            <div className="mt-2 col-md-4">
                                                <label>Status</label>
                                                <select
                                                    value={status}
                                                    name="status"
                                                    onChange={handleServiceStatus}
                                                    className="form-control"
                                                    required
                                                >
                                                    <option value={true}>ACTIVE</option>
                                                    <option value={false}>INACTIVE</option>
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" fdprocessedid="7wkuky" onClick={handleCloseModal2}>Close</button>
                                        <button type="submit" className="btn btn-primary" fdprocessedid="w927w4" onClick={editService}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}; 

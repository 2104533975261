import React, { useState } from "react";

const Day = ({ day }) => {
    return <div className="day">{day.toString().padStart(2, "0")}</div>;
};

const MonthYearSelector = ({ month, year, onMonthChange, onYearChange }) => {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const handleMonthChange = (event) => {
        onMonthChange(event.target.value);
    };

    const handleYearChange = (event) => {
        onYearChange(parseInt(event.target.value, 10));
    };

    return (
        <div className="month-year-selector">
            <div className="month">
                <select
                    className="month-select"
                    value={month}
                    onChange={handleMonthChange}
                >
                    {months.map((m) => (
                        <option key={m} value={m}>
                            {m}
                        </option>
                    ))}
                </select>
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/c8ca3f6ec4f920251ec426e21d8707d382f7000ac33eb2d8471401bb0a91163e?apiKey=db794817dd5146eab819adb9dc045301&"
                    className="arrow-icon"
                    alt="Month selector arrow"
                />
            </div>
            <div className="year">
                <select
                    className="year-select"
                    value={year}
                    onChange={handleYearChange}
                >
                    {[...Array(10)].map((_, index) => (
                        <option key={2020 + index} value={2020 + index}>
                            {2020 + index}
                        </option>
                    ))}
                </select>
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/2170dde453728a306b177cfc97df10f3839a626b8dae237808cc440e015866c3?apiKey=db794817dd5146eab819adb9dc045301&"
                    className="arrow-icon"
                    alt="Year selector arrow"
                />
            </div>
        </div>
    );
};

const FigmaDatepicker = () => {
    const [selectedMonth, setSelectedMonth] = useState("January");
    const [selectedYear, setSelectedYear] = useState(2024);

    const daysInMonth = (month, year) => {
        return new Date(year, months.indexOf(month) + 1, 0).getDate();
    };

    const firstDayOfMonth = (month, year) => {
        return new Date(year, months.indexOf(month), 1).getDay();
    };

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const days = Array.from(
        {
            length: daysInMonth(selectedMonth, selectedYear),
        },
        (_, i) => i + 1
    );

    const emptyDays = Array.from(
        {
            length: firstDayOfMonth(selectedMonth, selectedYear),
        },
        () => null
    );

    const allDays = [...emptyDays, ...days];

    const weeks = Array.from(
        {
            length: Math.ceil(allDays.length / 7),
        },
        (_, i) => allDays.slice(i * 7, i * 7 + 7)
    );

    return (
        <>
            <div className="calendar">
                <MonthYearSelector
                    month={selectedMonth}
                    year={selectedYear}
                    onMonthChange={setSelectedMonth}
                    onYearChange={setSelectedYear}
                />
                {weeks.map((week, rowIndex) => (
                    <div key={rowIndex} className="week">
                        {week.map((day, dayIndex) => (
                            <Day key={dayIndex} day={day ?? 0} />
                        ))}
                    </div>
                ))}
            </div>
            <style jsx>{`
        .calendar {
          display: flex;
          flex-direction: column;
          max-width: 328px;
          border-radius: 10px;
          padding: 8px;
          background-color: #fff;
          font-size: 16px;
          white-space: nowrap;
          justify-content: center;
        }

        .month-year-selector {
          display: flex;
          justify-content: space-between;
          border: 2px solid rgba(251, 234, 216, 1);
          border-radius: 4px;
          gap: 0;
        }

        .month {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          padding: 8px 8px 8px 16px;
          gap: 10px;
          background-color: #ee8276;
          color: #fbead8;
          font-weight: 500;
        }

        .month-select,
        .year-select {
          appearance: none;
          background-color: transparent;
          border: none;
          color: inherit;
          font-family: inherit;
          font-size: inherit;
          font-weight: inherit;
          padding: 4px 0;
          margin: 0;
          flex: 1;
          text-align: center;
          text-align-last: center;
        }

        .year {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1;
          padding: 8px 8px 8px 16px;
          gap: 10px;
          color: #ee8276;
          font-weight: 700;
        }

        .arrow-icon {
          width: 16px;
          margin: auto 0;
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
        }

        .week {
          display: flex;
          gap: 10px;
          color: #ee8276;
          font-weight: 400;
          margin-top: 10px;
        }

        .day {
          font-family: JetBrains Mono, -apple-system, Roboto, Helvetica,
            sans-serif;
          justify-content: center;
          padding: 4px 8px;
          border-radius: 4px;
          background-color: #fbead8;
        }
      `}</style>
        </>
    );
};

export default FigmaDatepicker;

import React, { useEffect } from "react";
import Navbar from "./Navbar";
import { useState } from "react";
import RestService from "../http";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MasterCommTable from "./utilityComponents/MasterCommTable";
import showToast from "./utilityComponents/APPToast";
import Loader from "./Loader";
import { Link } from "react-router-dom";


export default function BbpsUserComm() {
    const service = new RestService();

    const [showForm2, setShowForm2] = useState(false);
    const [userList, setUserList] = useState([]);
    const [planList, setPlanList] = useState([]);
    const [planId, setPlanId] = useState('');
    const [assignUserList, setAssignUserList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [incode, setIncode] = useState("");
    const [outCode, setOutCode] = useState("");
    const [commission, setCommission] = useState("");
    const [commissionType, setCommissionType] = useState('');
    const [operatorList, setOperatorList] = useState([]);
    const [packageId, setPackageId] = useState('');
    const [slabList, setSlabList] = useState([]);
    const [serviceCode, setServiceCode] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [commissionData, setCommissionData] = useState(null);
    const [roles, setRoles] = useState([]);
    const [customerType, setCustomerType] = useState();
    const [slab1, setSlab1] = useState('');
    const [slab2, setSlab2] = useState('');
    const [commUserList, setCommUserList] = useState([]);
    const [charge, setCharge] = useState("");
    const [userType, setUserType] = useState('');
    const [operatorName, setOperatorName] = useState("");
    const [bbpsOperator, setBbpsOperator] = useState("");
    const [bbpsOperatorList, setBbpsOperatorList] = useState([]);
    const [loading, setLoading] = useState(false);


    const handlePlanId = (e) => {
        setPlanId(e.target.value);
    }

    const toggleFormVisibility2 = () => {
        setShowForm2((prevShowForm) => !prevShowForm);
    };

    const handleUserType = (e) => {
        service.post(`user/getUpline/${e.target.value}`, null).then(result => {
            setUserList(result.report === undefined ? [] : result.report);
        }).catch((error) => {
            console.log(error);
        });
    }


    const handlePlanList = () => {
        service.post("plans/getAllPlans", null).then(result => {
            if (result.status === service.SUCCESS) {
                setPlanList(result.report);
            } else {
                setPlanList([]);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        handlePlanList();
    }, []);

    const handleServiceId = (e) => {
        setServiceCode(e.target.value);

        service.post(`operator/fetchOperatorByService/${e.target.value}`, null).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                setOperatorList(result.report);
            } else {
                setOperatorList([]);
            }
        })
            .catch((error) => {
                console.log(error);
            });

    }

    const handleBbpsOperator = (bbpsOperatorId, operatorName) => {
        setBbpsOperator(bbpsOperatorId);

        var raw = {
            "category": operatorName
        };

        service.post("bbps/fetchoperator", raw)
            .then(result => {
                console.log(result);
                if (result.status === service.SUCCESS) {
                    setBbpsOperatorList(result.data);
                } else {
                    showToast("DANGER", result.message);
                    setBbpsOperatorList([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const handleAssignUserType = (e) => {
        setUserType(e.target.value);

        service.post(`package/fetchUserGroup?userType=${e.target.value}`, null).then(result => {
            console.log(result);

            if (result.status === service.SUCCESS) {
                setAssignUserList(result.data);
            } else {
                setAssignUserList([]);
            }
        }).catch((error) => {
            console.log(error);
        });
    }


    const handleServiceCode = () => {

        service.post("service/getAllService", null).then((result) => {
            if (result.report === undefined) {
                setServiceList([]);
            } else {
                console.log(result);
                setServiceList(result.report);

            }
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        handleServiceCode();
    }, []);

    const handleIncode = (rawData) => {
        const data = JSON.parse(rawData);
        console.log(data);

        setIncode(data.operatorIncode);
        setOutCode(data.operatorOutcode);
        setOperatorName(data.operatorName);
        // fetchOperatorSlab(data.serviceType);
    }


    const handleSlabs = (slabObj) => {
        // setSlabList(e.target.value);

        const data = JSON.parse(slabObj);
        setSlab1(data.slab1);
        setSlab2(data.slab2);
    }

    const handleCommission = (e) => {
        setCommission(e.target.value);
    }

    const handleCommissionType = (e) => {
        setCommissionType(e.target.value);
    }

    const fetchOperatorSlab = () => {

        service.post(`commission/fetchSlabByService/6`, null).then(result => {
            console.log(result);
            if (result.status === service.SUCCESS) {
                setSlabList(result.data);
            } else {
                setSlabList([]);
            }

        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        fetchOperatorSlab();
    }, [])


    const handleUpdate = (data) => {
        setCommissionData(data);
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setCommissionData(null);
        setShowModal(false);
    };


    const handleCustomerType = (e) => {
        console.log(e.target.value);
        setCustomerType(e.target.value)
    }

    const handleCustomerTypeChange = () => {
        service.get("user/roles", null).then(result => {
            if (result.role === undefined) {
                setRoles([]);
            } else {
                const filteredRoles = result.role.filter((role) => [3, 4, 5].includes(role.roleId));
                setRoles(filteredRoles);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        handleCustomerTypeChange()
    }, [])


    const handleCommissionUpdate = () => {

        var raw = {
            "userType": userType,
            "serviceCode": serviceCode,
            "operatorCode": operatorName,
            "planId": planId,
            "slab1": slab1,
            "slab2": slab2,
            "incode": bbpsOperator,
            "commission": commission,
            "type": commissionType,
            "isCharge": charge

        };

        console.log(raw);

        setLoading(true);

        service.post("commission/addBBPSUserCommission", raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                showToast("SUCCESS", result.message);
                // setTimeout(() => {
                //     window.location.reload();
                // }, 2000);

            } else {
                showToast("DANGER", result.message);
            }
        }).catch((error) => {
            console.log(error);
        });
    }


    const handleBbpsAdminCommReport = () => {
        var raw = {
            "packageId": packageId,
            "planId": planId
        };
        console.log(raw);

        setLoading(true);

        service.post("commission/fetchMasterApiCommission", raw).then(result => {
            setLoading(false);

            console.log(result);
            if (result.status === service.SUCCESS) {
                setCommUserList(result.data);
                showToast("SUCCESS", result.message);
                // setTimeout(() => {
                //     window.location.reload();
                // }, 1000);
            } else {
                showToast("DANGER", result.message);
            }
        }).catch((error) => {
            console.log(error);
        });
    }


    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}


            <div className="content-wrapper" style={{ minHeight: "600.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 float-right">
                                <h1 className="m-0 text-dark">BBPS USER COMMISSION</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Settings</Link>
                                    </li>
                                    <li className="breadcrumb-item active"> COMMISSION</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">

                    <div className="container-fluid">

                        {showForm2 && (
                            <div className="card" id="addpack">
                                <div className="card-body">
                                    <div>

                                        <div className="row" data-select2-id="4">

                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="assign_commission">
                                                    User Type: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleAssignUserType}
                                                >
                                                    <option>Select User Type</option>
                                                    {roles.map((role) => (
                                                        <option value={role.roleId}>{role.roleName}</option>
                                                    ))}
                                                </select>
                                            </div>


                                            {/* <div className="col-md-3" data-select2-id="4">
                                                <label for="assign_commission">
                                                    User List: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleAssignUserList}
                                                >
                                                    <option>Select User...</option>
                                                    {assignUserList.map((item) => (
                                                        <option value={item.packageId}>{item.name}</option>
                                                    ))}

                                                </select>
                                            </div> */}

                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="service_id">
                                                    Plan Id: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handlePlanId}
                                                >
                                                    <option>Select Plan Id</option>
                                                    {planList.map((item) => (
                                                        <option key={item.planId} value={item.planId}>
                                                            {item.planName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="service_id">
                                                    Service Type: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleServiceId}
                                                >
                                                    <option>Select Id</option>
                                                    <option value={6}>BBPS</option>

                                                </select>
                                            </div>


                                            <div className="col-md-3" data-select2-id="4">
                                                <label for="service_id">
                                                    Operator: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setOperatorName(e.target.value)}
                                                >
                                                    <option value="">SELECT OPERATOR</option>
                                                    {operatorList.length > 0 ?
                                                        operatorList.map((item) => (
                                                            <option value={item.operatorId}>{item.operatorName}</option>
                                                        )) : <></>}

                                                </select>
                                            </div>

                                            <div className="col-md-3 mt-2" data-select2-id="4">
                                                <label for="service_id">
                                                    Operator Code: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onClick={(e) => {
                                                        setBbpsOperator(e.target.value);
                                                        handleBbpsOperator(e.target.value, operatorName);
                                                    }}
                                                >
                                                    <option value="">SELECT OPERATOR</option>
                                                    {bbpsOperatorList.map((item) => (
                                                        <option value={item.id}>
                                                            {item.operatorName}
                                                        </option>
                                                    ))}

                                                </select>
                                            </div>

                                            <div className="col-md-3 mt-2" data-select2-id="4">
                                                <label for="service_id">
                                                    Slabs: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => handleSlabs(e.target.value)}
                                                >
                                                    <option>Slabs</option>
                                                    {slabList.map((item) => (
                                                        <option value={JSON.stringify(item)}>{`(${item.slab1}-${item.slab2})`}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-3 mt-2" data-select2-id="4">
                                                <label for="service_id">
                                                    Commission: <span className="text-danger"> *</span>
                                                </label>
                                                <input
                                                    placeholder="commission"
                                                    className="form-control select2 "
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    aria-hidden="true"
                                                    onChange={handleCommission}
                                                />
                                            </div>

                                            <div className="col-md-3 mt-2" data-select2-id="4">
                                                <label for="service_id">
                                                    Commission Type: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={handleCommissionType}
                                                >
                                                    <option>select commission type</option>
                                                    <option value={"FLAT"}>FLAT</option>
                                                    <option value={"PERC"}>PERCENTAGE</option>

                                                </select>
                                            </div>

                                            <div className="col-md-3 mt-2" data-select2-id="4">
                                                <label for="service_id">
                                                    Is Charge?: <span className="text-danger"> *</span>
                                                </label>
                                                <select
                                                    className="form-control select2 "
                                                    name="service_id"
                                                    required=""
                                                    id="service_id"
                                                    data-select2-id="service_id"
                                                    tabindex="-1"
                                                    aria-hidden="true"
                                                    onChange={(e) => setCharge(e.target.value)}
                                                >
                                                    <option value="">select</option>
                                                    <option value={true}>YES</option>
                                                    <option value={false}>NO</option>

                                                </select>
                                            </div>
                                      
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mb-2">
                                    <input
                                        type="submit"
                                        className="btn btn-primary btn-hover text-white"
                                        value="Assign Commission"
                                        onClick={handleCommissionUpdate}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="card">
                            <div className="card-body">
                                <div className="row">

                                    <div className="col-md-3">
                                        <label for="assign_commission">
                                            User Type: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={handleAssignUserType}
                                        >
                                            <option>Select User Type</option>
                                            {roles.map((role) => (
                                                <option value={role.roleId}>{role.roleName}</option>
                                            ))}

                                        </select>
                                    </div>

                                    <div className="col-md-3">
                                        <label for="assign_commission">
                                            Select Package: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={(e) => setPackageId(e.target.value)}
                                        >
                                            <option>Select User...</option>
                                            {assignUserList.map((item) => (
                                                <option value={item.packageId}>{item.name}</option>
                                            ))}

                                        </select>
                                    </div>

                                    {/* <div className="col-md-3">
                                        <label for="service_id">
                                            Service Type: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={handleServiceId}
                                        >
                                            <option>Select Id</option>
                                            {serviceList.map((item) => (
                                                <option key={item.serviceType} value={item.serviceType}>{item.serviceName}</option>
                                            ))}

                                        </select>
                                    </div> */}

                                    {/* <div className="col-md-3">
                                        <label for="service_id">
                                            Operator Code: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={(e) => handleIncode(JSON.stringify(e.target.value))}
                                        >
                                            <option value={JSON.stringify("{}")}>SELECT OPERATOR</option>
                                            {operatorList.length > 0 ?
                                                operatorList.map((item) => (
                                                    <option value={item}>{item.operatorName}</option>
                                                )) : <></>}

                                        </select>
                                    </div> */}

                                    <div className="col-md-3">
                                        <label for="service_id">
                                            Plan Id: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={handlePlanId}
                                        >
                                            <option>Select Plan Id</option>
                                            {planList.map((item) => (
                                                <option key={item.planId} value={item.planId}>
                                                    {item.planName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                </div>
                                <h1 className="m-0 text-dark float-right">

                                    <span className="btn btn-sm btn-primary" onClick={handleBbpsAdminCommReport}>
                                        <i id="sign" className="fa fa-plus"></i> SEARCH
                                    </span>&nbsp;

                                    <span
                                        className="btn btn-sm btn-primary"
                                        onClick={toggleFormVisibility2}
                                    >
                                        {/* Render only the "CANCEL" text if showForm is true */}
                                        {showForm2 ? (
                                            "CANCEL"
                                        ) : (
                                            <>
                                                <i id="sign" className="fa fa-plus"></i> ASSIGN COMMISSION
                                            </>
                                        )}
                                    </span>&nbsp;

                                </h1>

                                <div className="clearfix"></div><br />
                                <div className="table-responsive">
                                    {commUserList.map((item) => (
                                        <MasterCommTable data={item.data} serviceName={item.serviceName} handleUpdate={handleUpdate} />
                                    ))}

                                    {showModal && (
                                        <div
                                            className="modal fade show"
                                            role="dialog"
                                            aria-labelledby="serviceUpdateTitle"
                                            style={{ display: "block" }}
                                            aria-modal="true"
                                        >
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div
                                                    className="modal-content"
                                                    style={{ width: "max-content" }}
                                                >
                                                    <div>

                                                        <div className="modal-header">
                                                            <h4>Update User</h4>
                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                                                                <span aria-hidden="true">×</span>
                                                            </button>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>Customer Type <span className="text-danger"> *</span></label>

                                                            <select
                                                                className="form-control"
                                                                name="customer_type"
                                                                id="customer_type"
                                                                fdprocessedid="g30y5e"
                                                                onChange={handleCustomerType}
                                                                style={{ height: "10%" }}
                                                            >

                                                                <option value="">SELECT CUSTOMER TYPE</option>
                                                                {roles.map((role) => (
                                                                    <option key={role.roleId} value={role.roleId}>
                                                                        {role.roleName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div><br />


                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                data-dismiss="modal"
                                                                fdprocessedid="7wkuky"
                                                                onClick={handleCloseModal}
                                                            >
                                                                Close
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                                {/* <Cards /> */}
                            </div>
                        </div>
                    </div>
                </section >
            </div >

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    );
}

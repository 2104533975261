import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';


export default function Operatorwisereport() {

    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropDownRef = useRef();

    const options = [
        { value: '', label: 'AJOY MONDAL [MD1003][9609236025]' },
        { value: '1', label: 'ARUL DOSS [MD1002][7904634883]' },
        { value: '2', label: 'NAVNATH SASE [RT1001][8600707581]' },
        { value: '3', label: 'Sharad Bandarkar [MA1000][8600436163]' },

    ];

    const handleOptionSelect = (option) => {
        setSelectedOption(option.value);
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "532.667px" }} data-select2-id="11">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Operatorwise Report</h1>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Report</Link></li>
                                    <li className="breadcrumb-item active">Operatorwise Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div data-select2-id="10">
                                    <form action="/operatorwisereport" method="post" acceptCharset="utf-8" data-select2-id="9">
                                        <input type="hidden" name="securityToken" value="2339912789ba77ea9526ef13f63da28b" />
                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                <label>From Date</label>
                                                <input type="date" name="from_date" className="form-control" value="2023-07-25" />
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                <label>To Date</label>
                                                <input type="date" name="to_date" className="form-control" value="2023-07-25" />
                                            </div>

                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12" data-select2-id="16">
                                                <label>Customer</label>
                                                <select name="customer_id" className="form-control select2 select2-hidden-accessible" id="customer_list" tabindex="-1" aria-hidden="true" data-select2-id="customer_list">
                                                    <option value="" data-select2-id="7">Select Customer</option>
                                                </select>
                                                <span className="select2 select2-container select2-container--default select2-container--below" dir="ltr" data-select2-id="6" style={{ width: '228.5px' }}>

                                                    <div
                                                        ref={dropDownRef}
                                                        className={`select2 select2-container select2-container--default ${isDropdownOpen ? 'select2-container--open' : ''
                                                            }`}
                                                        dir="ltr"
                                                        data-select2-id="2"
                                                        style={{ width: '220.625px' }}
                                                        onClick={toggleDropdown}
                                                    >
                                                        <span className="selection">
                                                            <span
                                                                className={`select2-selection select2-selection--single ${isDropdownOpen ? 'select2-selection--active' : ''
                                                                    }`}

                                                                role="combobox"
                                                                aria-haspopup="true"
                                                                aria-expanded={isDropdownOpen}
                                                                tabIndex="0"
                                                                aria-disabled="false"
                                                                aria-labelledby="select2-package_id-24-container"
                                                            >
                                                                <span
                                                                    className="select2-selection__rendered"
                                                                    id="select2-package_id-24-container"
                                                                    role="textbox"
                                                                    aria-readonly="true"
                                                                    title={selectedOption ? options.find((option) => option.value === selectedOption).label : 'Select Customer'}
                                                                >
                                                                    {selectedOption ? options.find((option) => option.value === selectedOption).label : 'Select Customer'}
                                                                </span>

                                                                <span className="select2-selection__arrow" role="presentation">
                                                                    <b role="presentation"></b>
                                                                </span>
                                                            </span>
                                                        </span>

                                                        {isDropdownOpen && (
                                                            <span className="dropdown-wrapper" style={{ position: 'absolute' }}>
                                                                <span className="select2-dropdown select2-dropdown--below" dir="ltr" style={{ width: '280.625px' }}>
                                                                    <span className="select2-search select2-search--dropdown">
                                                                        <input
                                                                            className="select2-search__field"
                                                                            type="search"
                                                                            tabIndex="0"
                                                                            autoComplete="off"
                                                                            autoCorrect="off"
                                                                            autoCapitalize="none"
                                                                            spellCheck="false"
                                                                            role="searchbox"
                                                                            aria-autocomplete="list"
                                                                            aria-controls="select2-package_id-ci-results"
                                                                        />
                                                                    </span>
                                                                    <span className="select2-results">
                                                                        <ul className="select2-results__options" role="listbox" id="select2-package_id-ci-results" aria-expanded="true" aria-hidden="false">
                                                                            {options.map((option) => (
                                                                                <li
                                                                                    key={option.value}
                                                                                    className={`select2-results__option ${option.value === selectedOption ? 'select2-results__option--highlighted' : ''}`}
                                                                                    role="option"
                                                                                    aria-selected={option.value === selectedOption}
                                                                                    data-select2-id={`select2-package_id-f9-result-${option.value}`}
                                                                                    onClick={() => handleOptionSelect(option)}
                                                                                >
                                                                                    {option.label}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        )}

                                                    </div>

                                                </span>
                                            </div>

                                            <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                                                <label>API</label>
                                                <select name="api_id" style={{ width: '143.667px', height: '35px' }} >
                                                    <option value="" data-select2-id="5">Select API</option>
                                                    <option value="1">OFFLINE</option>
                                                    <option value="2">PLAN API</option>
                                                </select>

                                            </div>

                                            <div className="col-lg-1 col-md-1 col-sm-12 col-12" style={{ marginTop: "30px" }}>
                                                <input type="submit" className="form-control btn btn-primary" value="Search" fdprocessedid="v14p7i" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="table-responsive" style={{ marginTop: "30px" }}>
                                    <table className="table m-0 table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Sr.No</th>
                                                <th>Operator</th>
                                                <th>Count</th>
                                                <th>Amount</th>
                                                <th>Given Commission</th>
                                                <th>Surcharge</th>
                                                <th>Admin Commission</th>
                                                <th>ROffer</th>
                                                <th>API Commission</th>
                                                <th>API Surcharge</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* You can add dynamic content here */}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="2" style={{ fontWeight: "bold" }} className="text-primary">
                                                    Row Count : 0 | Profit : 0 | Roundoff Difference : 0.00
                                                </td>
                                                <td>0</td>
                                                <td className="text-center font-weight-bold">₹ 0.00</td>
                                                <td className="text-center font-weight-bold text-success">₹ 0.00</td>
                                                <td className="text-center font-weight-bold text-danger">₹ 0.00</td>
                                                <td className="text-center font-weight-bold text-success">₹ 0.00</td>
                                                <td className="text-center font-weight-bold text-success">₹ 0.00</td>
                                                <td className="text-center font-weight-bold text-success">₹ 0.00</td>
                                                <td className="text-center font-weight-bold text-danger">₹ 0.00</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import DatePicker from "react-datepicker";
import RestService from '../http';
import formatDate from './utilityComponents/FormatDate';
import { ToastContainer } from 'react-toastify';
import showToast from './utilityComponents/APPToast';
import Loader from './Loader';
import { Link } from 'react-router-dom';


export default function ServiceWiseTxnReport() {

    const rest = new RestService();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [payout, setPayout] = useState({});
    const [aeps, setAeps] = useState({});
    const [aadharpay, setAadharpay] = useState({});
    const [microatm, setMicroatm] = useState({});
    const [loading, setLoading] = useState(false);


    const handleServiceTxn = async () => {

        var raw = {
            "fromDate": formatDate(startDate),
            "toDate": formatDate(endDate)
        }

        setLoading(true);

        const result = await rest.post("report/fetchServiceWiseTxnAmount", raw)
        setLoading(false);

        console.log(result);

        if (result.status === rest.SUCCESS) {
            const payoutData = result.data.payout;
            const aepsData = result.data.aeps;
            const aadharpayData = result.data.aadhaarPay;
            const microatmData = result.data.microAtm;

            showToast("SUCCESS", result.message)
            setPayout(payoutData);
            setAeps(aepsData);
            setAadharpay(aadharpayData);
            setMicroatm(microatmData);

        } else {
            showToast("DANGER", result.message);
            setPayout(undefined);
            setAeps(undefined);
            setAadharpay(undefined);
            setMicroatm(undefined);
        }
    }

    useEffect(() => {
        handleServiceTxn();
    }, [])

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: "532.667px" }}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Service Wise Txn. Amount</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Report</Link></li>
                                    <li className="breadcrumb-item active">Service Wise Txn. Amount</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div action="#" method="post">

                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                <label>From Date</label><br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    dropdownMode="select"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={startDate}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setStartDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                <label>To Date</label>
                                                <br />
                                                <DatePicker
                                                    className="custom-date-picker"
                                                    dropdownMode="select"
                                                    name="string"
                                                    fdprocessedid="9n5i4u"
                                                    selected={endDate}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onChange={(date) => setEndDate(date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>

                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    style={{ marginTop: "11%" }}
                                                    onClick={handleServiceTxn}
                                                >
                                                    <i className="fa fa-search"></i> Search
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="serviceTxnCard">
                                            <div className="txnHeading">PAYOUT</div>
                                            <div className="txnStatus">
                                                <div className="txnStatus2">
                                                    <div className="txnStatus3">Success:</div>
                                                    <div className="txnStatus4">{payout.success}</div>
                                                </div>
                                                <div className="txnStatus5">
                                                    <div className="txnStatus3">Pending:</div>
                                                    <div className="txnStatus4">{payout.pending}</div>
                                                </div>
                                                <div className="txnStatus8">
                                                    <div className="txnStatus3">Failed:</div>
                                                    <div className="txnStatus4">{payout.failed}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-3'>
                                        <div className="serviceTxnCard">
                                            <div className="txnHeading" >AEPS</div>
                                            <div className="txnStatus">
                                                <div className="txnStatus2">
                                                    <div className="txnStatus3">Success:</div>
                                                    <div className="txnStatus4">{aeps.success}</div>
                                                </div>

                                                <div className="txnStatus5">
                                                    <div className="txnStatus3">Pending:</div>
                                                    <div className="txnStatus4">0</div>
                                                </div>
                                                <div className="txnStatus8">
                                                    <div className="txnStatus3">Failed:</div>
                                                    <div className="txnStatus4">0</div>
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-3'>
                                        <div className="serviceTxnCard">
                                            <div className="txnHeading">AADHARPAY</div>
                                            <div className="txnStatus">
                                                <div className="txnStatus2">
                                                    <div className="txnStatus3">Success:</div>
                                                    <div className="txnStatus4">{aadharpay.success}</div>
                                                </div>

                                                <div className="txnStatus5">
                                                    <div className="txnStatus3">Pending:</div>
                                                    <div className="txnStatus4">0</div>
                                                </div>
                                                <div className="txnStatus8">
                                                    <div className="txnStatus3">Failed:</div>
                                                    <div className="txnStatus4">0</div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-3'>
                                        <div className="serviceTxnCard">
                                            <div className="txnHeading">MICRO ATM</div>
                                            <div className="txnStatus">
                                                <div className="txnStatus2">
                                                    <div className="txnStatus3">Success:</div>
                                                    <div className="txnStatus4">{microatm.success}</div>
                                                </div>

                                                <div className="txnStatus5">
                                                    <div className="txnStatus3">Pending:</div>
                                                    <div className="txnStatus4">0</div>
                                                </div>
                                                <div className="txnStatus8">
                                                    <div className="txnStatus3">Failed:</div>
                                                    <div className="txnStatus4">0</div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="www.softmintdigital.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

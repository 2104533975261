import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Navbar from './Navbar';
import RestService from '../http';
import { ToastContainer } from 'react-toastify';
import Pagination2 from './utilityComponents/Pagination2';
import Loader from './Loader';
import showToast from './utilityComponents/APPToast';
import Select from 'react-select';
import { Link } from 'react-router-dom';


export default function Changesponser() {

    const rest = new RestService();
    const [selectedAction, setSelectedAction] = useState(null);

    const [userList, setUserList] = useState([]);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [roles, setRoles] = useState([]);
    const [customerList, setCustomerList] = useState([]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchClick = () => {

        var raw = {
            "userId": userId
        };

        setLoading(true);

        rest.post("package/usermappedbyUserId", raw).then(response => {
            setLoading(false);

            console.log(response);

            if (response.status === rest.SUCCESS) {
                setCustomerList(response.data)

            } else {
                showToast("Danger", response.message);
                setCustomerList([]);
            }
        }).catch((error) => {
            console.log(error);
        });
    };


    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            // Reset the selected action
            setSelectedAction(null);
        }
    }, [selectedAction]);


    const [selectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropDownRef = useRef();


    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    // const handleDeleteUser = (userId) => {
    //     rest.post(`user/deleteUser/${userId}`, null).then(result => {
    //         if (result.status === rest.SUCCESS) {
    //             showToast("SUCCESSS", result.message);
    //             handleSearchClick();
    //             window.location.reload();
    //         } else {
    //             showToast("DANGER", result.message)
    //         }
    //     }).catch((error) => {
    //         console.log(error);
    //     })
    // }


    const [filteredUserReport, setFilteredUserReport] = useState([]);
    const [searchInput, setSearchInput] = useState('');


    useEffect(() => {
        const filteredList = customerList.filter(item =>
            item.device.toLowerCase().includes(searchInput.toLowerCase())
        );

        setFilteredUserReport(filteredList);

    }, [customerList, searchInput]);

    const paginatedCustomerReport = filteredUserReport.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    const updateStatus = (userId, status) => {
        let request = {
            userId: userId,
            status: status === "0" ? "1" : "0",
        };

        rest.post("package/updateusermap", request).then(result => {
            console.log(result);
            if (result.status === rest.SUCCESS) {
                showToast("SUCCESS", result.message);
                handleSearchClick();
            } else {
                showToast("FAILED", result.message);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const handleCustomerType = (e) => {
        rest
            .post(`user/getUpline/${e.target.value}`, null)
            .then((result) => {
                console.log(result);
                if (result.status === rest.SUCCESS) {
                    setUserList(result.data);
                } else {
                    showToast("DANGER", result.message)
                    setUserList([]);
                }
            })
            .catch((error) => {
                console.error(error);
                setUserList([]);

            });
    };


    useEffect(() => {
        rest.get("user/roles").then(result => {
            console.log(result);
            if (result.role === undefined) {
                setRoles([])
            } else {
                setRoles(result.role);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);


    const selectDevice = (userId, device) => {
        let request = {
            userId: userId,
            device: device === "Iris" ? "Biometric" : "Both",
        };

        rest.post("package/updateusermapdevice", request).then(result => {
            console.log(result);
            if (result.status === rest.SUCCESS) {
                showToast("SUCCESS", result.message);
                handleSearchClick();
            } else {
                showToast("FAILED", result.message);
            }
        }).catch(error => {
            console.log(error);
        })
    }


    const selectDmt = (userId, dmt) => {
        let request = {
            userId: userId,
            dmt: dmt === "0" ? "1" : "0",
        };

        rest.post("package/updateuserdmt", request).then(result => {
            console.log(result);
            if (result.status === rest.SUCCESS) {
                showToast("SUCCESS", result.message);
                handleSearchClick();
            } else {
                showToast("FAILED", result.message);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const switchBank = (userId, bankSwitch) => {
        let request = {
            userId: userId,
            bankSwitch: bankSwitch === "NSDLBANK" ? "YESBANK" : "NSDLBANK",
        };

        rest.post("package/updatebankaepsuser", request).then(result => {
            console.log(result);
            if (result.status === rest.SUCCESS) {
                showToast("SUCCESS", result.message);
                handleSearchClick();
            } else {
                showToast("FAILED", result.message);
            }
        }).catch(error => {
            console.log(error);
        })
    }


    const [selectedUserId, setSelectedUserId] = useState(null);

    const options = [
        { value: '', label: 'Select an option' },
        ...userList.map((item) => ({
            value: item.userId,
            label: `${item.name} || ${item.mobile}`,
        }))
    ];

    const handleSelectChange = (selectedUserId) => {
        setSelectedUserId(selectedUserId);
        setUserId(selectedUserId.value);
    };

    return (
        <>
            <Navbar />
            <ToastContainer />
            {loading && <Loader />}

            <div className="content-wrapper" style={{ minHeight: '570.667px' }}>
                {/* Content Header (Page header)  */}

                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">User Map <span>
                                    <Link to="/addusermap" className="btn btn-sm btn-primary">
                                        <i className="fa fa-plus"></i> NEW</Link></span>&nbsp;
                                    {/* <span>
                                        <Link to="memberExcelFile" className="btn btn-sm btn-success text-white">
                                            <i className="btn-hover fa fa-file-excel-o "> Export</i></Link></span> */}
                                </h1>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">User Map</Link></li>
                                    <li className="breadcrumb-item active">User Map</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label for="assign_commission">
                                            User Type: <span className="text-danger"> *</span>
                                        </label>
                                        <select
                                            className="form-control select2 "
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={handleCustomerType}
                                        >
                                            <option>Select User Type</option>
                                            {roles.map((item) => (
                                                item.roleId !== 0 ? <option value={item.roleId}>{item.roleName}</option> : null
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-md-3">
                                        <label for="service_id">
                                            User Id: <span className="text-danger"> *</span>
                                        </label>
                                        {/* <select
                                            className="form-control select2 "
                                            name="service_id"
                                            required=""
                                            id="service_id"
                                            data-select2-id="service_id"
                                            tabindex="-1"
                                            aria-hidden="true"
                                            onChange={(e) => setUserId(e.target.value)}
                                        >
                                            <option value="">Select Id...</option>
                                            {userList.map((item) => (
                                                <option value={item.userId}>{item.name}</option>
                                            ))}
                                        </select> */}

                                        <Select
                                            className="select-box width100p"
                                            options={options}
                                            isSearchable
                                            value={selectedUserId}
                                            onChange={handleSelectChange}
                                        />

                                    </div>

                                    <div className='col-md-3' style={{ marginTop: "2.5%" }}>
                                        <button className='btn btn-primary' onClick={handleSearchClick}>Search</button>
                                    </div>

                                </div>

                                <center>
                                    <div className="box-content" style={{ padding: "0", margin: "0" }}>
                                        <div>

                                            <div className="col-md-3" style={{ float: "left", visibility: "hidden" }}>
                                                <div>
                                                    <label style={{ fontSize: "13px", float: "left" }}>SEARCH</label>
                                                    <input
                                                        className="form-control text-box"
                                                        type="text"
                                                        placeholder="SEARCH BY DEVICE ID"
                                                        value={searchInput}
                                                        onChange={(e) => setSearchInput(e.target.value)}

                                                    />
                                                </div>
                                            </div><br />

                                            <Pagination2
                                                totalPages={Math.ceil(userList.length / itemsPerPage)}
                                                currentPage={currentPage}
                                                onChange={handlePageChange}

                                            />
                                        </div>
                                    </div>
                                </center>

                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>User Id</th>
                                                <th>Agent Code</th>
                                                <th>Api Code</th>
                                                <th>Bank Switch</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Device</th>
                                                <th>Status</th>
                                                <th>Id</th>
                                                <th>Kyc</th>
                                                <th>DMT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {data !== undefined ? data.map((option, index) => ( */}
                                            {(!paginatedCustomerReport || paginatedCustomerReport.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="12">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                paginatedCustomerReport.map((option, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{option.userId}</td>
                                                        <td>{option.agentcode}</td>
                                                        <td>{option.apiCode}</td>
                                                        <td>
                                                            {option.bankSwitch === "YESBANK" ? (
                                                                <span
                                                                    onClick={() =>
                                                                        switchBank(
                                                                            option.userId,
                                                                            option.bankSwitch,
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge bg-blue"
                                                                >
                                                                    YESBANK
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    onClick={() =>
                                                                        switchBank(
                                                                            option.userId,
                                                                            option.bankSwitch,
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge bg-yellow"
                                                                >
                                                                    NSDLBANK
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>{option.date}</td>
                                                        <td>{option.time}</td>
                                                        <td>
                                                            {option.device === "Iris" ? (
                                                                <span
                                                                    onClick={() =>
                                                                        selectDevice(
                                                                            option.userId,
                                                                            option.device
                                                                        )}
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge bg-blue"
                                                                >
                                                                    IRIS
                                                                </span>
                                                            ) : option.device === "Biometric" ? (
                                                                <span
                                                                    onClick={() =>
                                                                        selectDevice(
                                                                            option.userId,
                                                                            option.device
                                                                        )}
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge bg-yellow"
                                                                >
                                                                    BIOMETRIC
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    onClick={() =>
                                                                        selectDevice(
                                                                            option.userId,
                                                                            option.device
                                                                        )}
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge bg-green"
                                                                >
                                                                    BOTH
                                                                </span>
                                                            )}
                                                        </td>

                                                        <td>
                                                            {option.status == "1" ? (
                                                                <span
                                                                    onClick={() =>
                                                                        updateStatus(
                                                                            option.userId,
                                                                            option.status,
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge badge-success"
                                                                >
                                                                    ACTIVE
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    onClick={() =>
                                                                        updateStatus(
                                                                            option.userId,
                                                                            option.status,
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge badge-danger"
                                                                >
                                                                    INACTIVE
                                                                </span>
                                                            )}
                                                        </td>

                                                        <td>{option.id}</td>
                                                        <td>{option.kyc}</td>
                                                        <td>
                                                            {option.dmt == "1" ? (
                                                                <span
                                                                    onClick={() =>
                                                                        selectDmt(
                                                                            option.userId,
                                                                            option.dmt,
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge badge-success"
                                                                >
                                                                    ACTIVE
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    onClick={() =>
                                                                        selectDmt(
                                                                            option.userId,
                                                                            option.dmt,
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    className="badge badge-danger"
                                                                >
                                                                    INACTIVE
                                                                </span>
                                                            )}
                                                        </td>

                                                    </tr>

                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>

        </>

    )
}


import React from 'react';
import Navbar from './Navbar';
import { FaFileExcel, FaFilePdf, FaCopy } from "react-icons/fa";
import { Link } from 'react-router-dom';


export default function Pendingtrans() {

    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: '528.667px' }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Pending Transaction History</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Transaction</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Pending Transaction</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12" style={{ textAlign: 'right' }}>
                                        <a className="btn btn-info" >
                                            Check Status
                                        </a>
                                    </div>
                                </div>
                                <div className="table-responsive" style={{ marginTop: '10px' }}>
                                    <div
                                        id="example1_wrapper"
                                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                                    >
                                        <div className="dataTables_length" id="example1_length">
                                            <label>
                                                Show
                                                <select
                                                    name="example1_length"
                                                    aria-controls="example1"
                                                    className="custom-select custom-select-sm form-control form-control-sm"
                                                    fdprocessedid="cxkrds"
                                                >
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="All">All</option>
                                                </select>{' '}
                                                entries
                                            </label>
                                        </div>
                                        <div className="dt-buttons">
                                            <a
                                                className="dt-button buttons-copy buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="Copy"
                                            >
                                                 <span>
                                                    <FaCopy className="fa fa-files-o text-info font-weight-bold"/>
                                                </span>
                                            </a>
                                            <a
                                                className="dt-button buttons-excel buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="Excel"
                                            >
                                               <span>
                                                    <FaFileExcel className="fa fa-file-excel-o text-success font-weight-bold" />
                                                </span>
                                            </a>
                                            <a
                                                className="dt-button buttons-pdf buttons-html5"
                                                tabIndex="0"
                                                aria-controls="example1"
                                                title="PDF"
                                            >
                                               <span>
                                                    <FaFilePdf className="fa fa-file-pdf-o text-danger font-weight-bold"/>
                                                </span>
                                            </a>
                                        </div>
                                        <table
                                            id="example1"
                                            className="table m-0 table-striped table-bordered dataTable no-footer"
                                            role="grid"
                                            aria-describedby="example1_info"
                                        >
                                            <thead>
                                                <tr role="row">
                                                    <th
                                                        className="sorting_asc"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-sort="ascending"
                                                        aria-label="#: activate to sort column descending"
                                                        style={{ width: '8.45833px' }}
                                                    >
                                                        #
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Source: activate to sort column ascending"
                                                        style={{ width: '48.3854px' }}
                                                    >
                                                        Source
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Customer Name: activate to sort column ascending"
                                                        style={{ width: '135.844px' }}
                                                    >
                                                        Customer Name
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Number: activate to sort column ascending"
                                                        style={{ width: '57.2708px' }}
                                                    >
                                                        Number
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Amount: activate to sort column ascending"
                                                        style={{ width: '56.1354px' }}
                                                    >
                                                        Amount
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Operator: activate to sort column ascending"
                                                        style={{ width: '63.5521px' }}
                                                    >
                                                        Operator
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Type: activate to sort column ascending"
                                                        style={{ width: '34.3125px' }}
                                                    >
                                                        Type
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Status: activate to sort column ascending"
                                                        style={{ width: '44.7083px' }}
                                                    >
                                                        Status
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Txn ID: activate to sort column ascending"
                                                        style={{ width: '79.4792px' }}
                                                    >
                                                        Txn ID
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="API: activate to sort column ascending"
                                                        style={{ width: '29.2083px' }}
                                                    >
                                                        API
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="API Txn ID: activate to sort column ascending"
                                                        style={{ width: '83.3333px' }}
                                                    >
                                                        API Txn ID
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Op Ref: activate to sort column ascending"
                                                        style={{ width: '76.9688px' }}
                                                    >
                                                        Op Ref
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Message: activate to sort column ascending"
                                                        style={{ width: '108.698px' }}
                                                    >
                                                        Message
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Date: activate to sort column ascending"
                                                        style={{ width: '54.4167px' }}
                                                    >
                                                        Date
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex="0"
                                                        aria-controls="example1"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        aria-label="Action: activate to sort column ascending"
                                                        style={{ width: '45.0625px' }}
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td className="sorting_1">1</td>
                                                    <td>WEB</td>
                                                    <td>NAVNATH SASE [RT1001]</td>
                                                    <td>8888888888</td>
                                                    <td>12.00</td>
                                                    <td>
                                                        <img
                                                            className="img-responsive pr-2"
                                                            src="./image/airtel.png"
                                                            width="38px"
                                                            alt="Airtel"
                                                        />
                                                        Airtel
                                                    </td>
                                                    <td>PREPAID</td>
                                                    <td>
                                                        <kbd className="bg-warning text-white">PENDING</kbd>
                                                    </td>
                                                    <td>PNS0000022609</td>
                                                    <td>OFFLINE</td>
                                                    <td>20221226050835</td>
                                                    <td>REF1672054715</td>
                                                    <td>Transaction Pending</td>
                                                    <td>26-Dec-2022<br />05:08:35 PM</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            data-toggle="modal"
                                                            data-target="#exampleModalCenter"
                                                            title="Update Recharge"
                                                            className="btn btn-sm btn-primary"
                                                            fdprocessedid="v896b7j"
                                                        >
                                                            <i className="fa fa-save"></i>
                                                        </button>&nbsp;
                                                        <a
                                                            className="btn btn-sm btn-danger"
                                                            href="#"
                                                            data-toggle="modal"
                                                            data-target=".bd-example-modal-sm"
                                                            title="Resubmit Recharge"
                                                        >
                                                            <i className="fa fa-reply"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div
                                            className="dataTables_info"
                                            id="example1_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            Showing 1 to 1 of 1 entries
                                        </div>
                                        <div className="dataTables_paginate paging_simple_numbers" id="example1_paginate">
                                            <ul className="pagination">
                                                <li className="paginate_button page-item previous disabled" id="example1_previous">
                                                    <a aria-controls="example1" data-dt-idx="0" tabIndex="0" className="page-link">
                                                        Previous
                                                    </a>
                                                </li>
                                                <li className="paginate_button page-item active">
                                                    <a aria-controls="example1" data-dt-idx="1" tabIndex="0" className="page-link">
                                                        1
                                                    </a>
                                                </li>
                                                <li className="paginate_button page-item next disabled" id="example1_next">
                                                    <a aria-controls="example1" data-dt-idx="2" tabIndex="0" className="page-link">
                                                        Next
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

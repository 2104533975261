import React from 'react';
import Navbar from './Navbar';
import { useState, useRef, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import RestService from "../http";
import swal from 'sweetalert';
import { FaFileExcel } from "react-icons/fa";
import formatDate from './utilityComponents/FormatDate';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Link, useLocation } from 'react-router-dom';


export default function Transactionhis() {

    const service = new RestService();
    const [data, setData] = useState([]);
    const [txnData, setTxnData] = useState([])
    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const dropDownRef = useRef();
    const [userId, setUserId] = useState('');
    const [narration, setNarration] = useState('');
    const [mobile, setMobile] = useState('');
    const [txnId, setTxnId] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 10;
    const [status, setStatus] = useState('');
    const [api, setApi] = useState('');
    const [operator, setOperator] = useState('');
    const [reportService, setReportService] = useState('');
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());


    const location = useLocation();
    const [reportDetails, setReportDetails] = useState(location.state);
    console.log(reportDetails);


    useEffect(() => {

        var raw = {
            startdate: reportDetails.startDate,
            enddate: reportDetails.endDate,
            userId: "",
            narration: "",
            mobile: reportDetails.mobile,
            txnid: "",
            status: "",
            api: "",
            operator: "",
            service: ""
        };

        // console.log(raw);
        service.post("user/getTxnReport", raw).then(result => {
            console.log(result);
            if (result.TxnReport !== undefined) {
                setTxnData(result.TxnReport)
            } else {
                setTxnData([]);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const fetchData = () => {

        service.get("user/getAllUser", null).then(result => {
            if (result.List === undefined) {
                setData([]);
            } else {
                setData(result.List);
            }
            return result.List;

        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);


    const handleUserId = (e) => {
        console.log(e.target.value);
        setUserId(e.target.value);
    };

    const handleNarration = (e) => {
        console.log(e.target.value);
        setNarration(e.target.value);
    };

    const handleTxnId = (e) => {
        console.log(e.target.value);
        setTxnId(e.target.value);
    };

    const handleApi = (e) => {
        console.log(e.target.value);
        setApi(e.target.value);
    };

    const handleOperator = (e) => {
        console.log(e.target.value);
        setOperator(e.target.value);
    };

    const handleService = (e) => {
        console.log(e.target.value);
        setReportService(e.target.value);
    };


    // const handleReportData = () => {

    // }

    // useEffect(() => {
    //     handleReportData();
    // }, []);

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
            // handleReportData();
        }
    };

    const handleNextPage = () => {
        if (txnData.length >= pageSize) {
            setCurrentPage(currentPage + 1);
            // handleReportData();
        }

    };

    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [selectedOption]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    useEffect(() => {
        if (selectedAction) {
            console.log(`Selected Action: ${selectedAction}`);
            setSelectedAction(null);
        }
    }, [selectedAction]);


    return (
        <>
            <Navbar />

            <div className="content-wrapper" style={{ minHeight: "620.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Transaction History</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="javascript:void(0)">Transaction</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Transaction History</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr className="wrap-text text-center bg-dark">
                                                <th style={{ color: "white", fontWeight: "bold" }}>#</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>User Id</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Mobile</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Order Id</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Op. Bal</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Credit</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Debit</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Closing Bal</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Narration</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Remarks</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Date & Time</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Admin Opening Bal</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Admin Closing Bal</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>White Label Id</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>API</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Operator</th>
                                                <th style={{ color: "white", fontWeight: "bold" }}>Service</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!txnData || txnData.length === 0) ? (
                                                <tr className='text-center'>
                                                    <td colSpan="17">NO DATA AVAILABLE</td>
                                                </tr>
                                            ) : (
                                                txnData.map((transaction, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{transaction.userId}</td>
                                                        <td>{transaction.mobile}</td>
                                                        <td>{transaction.orderId}</td>
                                                        <td>{transaction.openingBal}</td>
                                                        <td>{transaction.credit}</td>
                                                        <td>{transaction.debit}</td>
                                                        <td>{transaction.closingBal}</td>
                                                        <td>{transaction.narration}</td>
                                                        <td>{transaction.remarks}</td>
                                                        <td>{transaction.date} & {transaction.time}</td>
                                                        <td>{transaction.adopbal}</td>
                                                        <td>{transaction.adclbal}</td>
                                                        <td>{transaction.wlId}</td>
                                                        <td>{transaction.api}</td>
                                                        <td>{transaction.operator}</td>
                                                        <td>{transaction.service}</td>

                                                        {/* <td>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend show">
                                                                <DropdownButton
                                                                    title="Action"
                                                                    variant="primary"
                                                                    size="sm"
                                                                    onSelect={(eventKey) => setSelectedAction(eventKey)}
                                                                >
                                                                    <Dropdown.Item eventKey="editMember">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary btn-sm btn-block"
                                                                            title="Raise Complaint"
                                                                            onClick={() => handleDropdownItemClick(transaction.txnid)}
                                                                        >
                                                                            COMPLAINT
                                                                        </button>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item >
                                                                        <Link
                                                                            to='/updatetransreport'
                                                                            state={transaction}
                                                                            title="Update"
                                                                            className="btn btn-sm btn-info btn-block"

                                                                        >
                                                                            UPDATE
                                                                        </Link>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item >
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-warning btn-block btn-sm"
                                                                            onClick={() => openModal({
                                                                                api_trans_id: "20221226050835",
                                                                                status: "3",
                                                                                error_message: "Transaction Pending",
                                                                                operator_ref: "REF1672054715",
                                                                                api_id: "1"
                                                                            })}
                                                                        >
                                                                            LOG
                                                                        </button>
                                                                    </Dropdown.Item>
                                                                </DropdownButton>

                                                            </div>
                                                        </div>
                                                    </td> */}
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>

                                    <div
                                        className="dataTables_paginate paging_simple_numbers"
                                        id="example1_paginate"
                                    >
                                        <ul className="pagination" style={{ float: "right", display: "flex" }}>
                                            <li className={`paginate_button page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button onClick={handlePreviousPage} className="page-link">
                                                    Previous
                                                </button>
                                            </li>
                                            <li className="paginate_button page-item active">
                                                <span className="page-link">{currentPage}</span>
                                            </li>
                                            <li className="paginate_button page-item">
                                                <button onClick={handleNextPage} className="page-link">
                                                    Next
                                                </button>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}

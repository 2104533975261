import React, { useEffect } from 'react';
import KycReportTable from './utilityComponents/KycReportTable';
import Navbar from "./Navbar";
import { useState } from "react";
import RestService from "../http";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import formatDate from './utilityComponents/FormatDate';
import NextstageKycReport from "./utilityComponents/NextstageKycReport";
import ThirdstageKycReport from "./utilityComponents/ThirdstageKycReport";
import Loader from './Loader';
import KycAgentView from './utilityComponents/KycAgentView';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';

export default function Kycreport() {

    const service = new RestService();
    const [kycCurrentRecord, setKycCurrentRecord] = useState([]);
    const [date, setDate] = useState(new Date());
    const [date1, setDate1] = useState(new Date());
    const [currentComponent, setCurrentComponent] = useState('table');
    const [loading, setLoading] = useState(false);
    const [agentId, setAgentId] = useState('');


    const handleKycReport = () => {

        var raw = {
            "startDate": formatDate(date),
            "endDate": formatDate(date1)
        }

        setLoading(true);

        service.post("completekyc/fetchallkyc", raw).then(result => {
            setLoading(false);

            console.log(result);
            setKycCurrentRecord(result.KYCREPORT);
            // if (result.status === service.SUCCESS) {
            //     setKycCurrentRecord(result.KYCREPORT);
            // } else {
            //     showToast("DANGER", result.message);
            //     setKycCurrentRecord([]);
            // }
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        handleKycReport();
    }, [])

    const handleShowNextComponent = (agentId) => {
        setAgentId(agentId);
        if (currentComponent === 'table') {
            setKycCurrentRecord([]);
            setCurrentComponent('nextStage');
        } else if (currentComponent === 'nextStage') {
            setCurrentComponent('secondStage');
        } else if(currentComponent === 'secondStage') {
            setCurrentComponent('thirdStage')
        }
    };

    const handleShowPreviousComponent = () => {
        if (currentComponent === 'nextStage') {
            setCurrentComponent('table');
        } else if (currentComponent === 'secondStage') {
            setCurrentComponent('nextStage');
        } else if (currentComponent === 'thirdStage') {
            setCurrentComponent('secondStage');
        }
    };


    return (
        <>
            <Navbar />
            {loading && <Loader />}
            <ToastContainer/>

            <div className="content-wrapper" style={{ minHeight: "570.667px" }}>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">
                                    Kyc Report &nbsp;
                                </h1>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">KYC</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Kyc Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">

                                <div className="row">
                                    <div className="col-md-3">
                                        <label for="rec_to_load"> Start Date</label><br />
                                        <DatePicker
                                            className="custom-date-picker"
                                            name="string"
                                            fdprocessedid="9n5i4u"
                                            selected={date}
                                            dateFormat={"yyyy-MM-dd"}
                                            onChange={(date) => setDate(date)}
                                            showYearDropdown
                                            showMonthDropdown
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label for="rec_to_load"> End Date</label>
                                        <br />
                                        <DatePicker
                                            className="custom-date-picker"
                                            name="string"
                                            disabledDayAriaLabelPrefix="#$"
                                            fdprocessedid="9n5i4u"
                                            selected={date1}
                                            dateFormat={"yyyy-MM-dd"}
                                            onChange={(date) => setDate1(date)}
                                            showYearDropdown
                                            showMonthDropdown
                                        />
                                    </div>

                                    <div className="col-md-3" style={{ marginTop: "2.5%" }}>
                                        <button
                                            className="btn btn-primary "
                                            fdprocessedid="fl0v7"
                                            onClick={handleKycReport}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>

                                <div className='mt-2'>
                                    {currentComponent === 'table' && (
                                        <div className="table-responsive mt-3">
                                            <KycReportTable data={kycCurrentRecord} showNextComponent={handleShowNextComponent} showPreviousComponent={handleShowPreviousComponent} />
                                        </div>
                                    )}

                                    {currentComponent === 'nextStage' && (
                                            <KycAgentView showNextComponent={handleShowNextComponent} showPreviousComponent={handleShowPreviousComponent} />
                                       
                                    )}

                                    {currentComponent === 'secondStage' && (
                                        <NextstageKycReport showNextComponent={handleShowNextComponent} showPreviousComponent={handleShowPreviousComponent} />
                                    )}

                                    {currentComponent === 'thirdStage' && (
                                        <ThirdstageKycReport showNextComponent={handleShowNextComponent} showPreviousComponent={handleShowPreviousComponent} agentId={agentId} />
                                    )}

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer className="main-footer">
                <strong>
                    Copyright © 2023 <Link to="https://www.softmintindia.com">SOFTMINT</Link>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 4.5.0
                </div>
            </footer>
        </>
    )
}
